<template>
  <div class="rate-sheets-options__customer-preferences-legend">
    <div
      class="rate-sheets-options__customer-preferences-legend__item"
      data-test="promotionLegend">
      <div
        class="rate-sheets-options__customer-preferences-legend__item__color-code
      rate-sheets-options__customer-preferences-legend__item__color-code--promotion_motivated" />

      <div class="rate-sheets-options__customer-preferences-legend__item__text">
        <strong>
          Promotion Motivated Customers
        </strong>

        <p>
          ”I normally pay cash.”
        </p>
      </div>
    </div>

    <div
      class="rate-sheets-options__customer-preferences-legend__item"
      data-test="interestLegend">
      <div
        class="rate-sheets-options__customer-preferences-legend__item__color-code
      rate-sheets-options__customer-preferences-legend__item__color-code--interest_rate_sensitive" />

      <div class="rate-sheets-options__customer-preferences-legend__item__text">
        <strong>
          Interest Rate Sensitive Customers
        </strong>

        <p>
          “I won’t pay over X% interest rate.”
        </p>
      </div>
    </div>

    <div
      class="rate-sheets-options__customer-preferences-legend__item"
      data-test="paymentLegend">
      <div
        class="rate-sheets-options__customer-preferences-legend__item__color-code
      rate-sheets-options__customer-preferences-legend__item__color-code--payment_sensitive" />

      <div class="rate-sheets-options__customer-preferences-legend__item__text">
        <strong>
          Payment Sensitive Customers
        </strong>

        <p>
          “I can only pay $X per month.”
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RateSheetOptionsDataTableLegend',
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/rate-sheet-options/rate-sheet-options-legend";

p {
  color: var(--grayscale-color-1) !important;
}
</style>
