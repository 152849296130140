import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    inputVerify: { type: String, default: '' },
    validateLastFourDigits: { type: [Number, String], default: null },
  },

  methods: {
    inputValidation(value: string | undefined | number, errorMessage: string): string | boolean {
      const { inputVerify, validateLastFourDigits } = this;

      if (inputVerify && inputVerify !== value) return errorMessage;

      /*
      validateLastFourDigits is used for validating last four digits of
      the bank account number when creating a Moov account.
      When a user enters his bank account number used for Repay,
      the method below takes the last four digits from the input,
      and compares it to the saved bank account number used for Repay.
      */
      if (validateLastFourDigits
      && validateLastFourDigits.toString() !== value?.toString().slice(-4)
      ) return errorMessage;

      return true;
    },
  },
});
