import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_merchant_onboarding_wizard_header = _resolveComponent("merchant-onboarding-wizard-header")!
  const _component_standard_input_otp = _resolveComponent("standard-input-otp")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showHeader)
      ? (_openBlock(), _createBlock(_component_merchant_onboarding_wizard_header, {
          key: 0,
          "show-logo": false
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_standard_input_otp, {
      "body-text": "Please enter the code we just sent to your mobile device.",
      "otp-message": "I didn't receive a text message",
      "is-mob": true,
      "invitation-uuid": _ctx.invitationUuid,
      "merchant-user-email": _ctx.merchantUserEmail,
      "on-phone-number-updated": _ctx.onPhoneNumberUpdated,
      "phone-number": _ctx.phoneNumber,
      "session-token": _ctx.sessionToken,
      "single-input": true,
      "verify-code": _ctx.verifyCode,
      "autofocus-enabled": _ctx.autoFocusEnabled,
      "disable-btn": _ctx.disableBtn
    }, null, 8, ["invitation-uuid", "merchant-user-email", "on-phone-number-updated", "phone-number", "session-token", "verify-code", "autofocus-enabled", "disable-btn"])
  ]))
}