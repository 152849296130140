import { isDateValid, selectDateFormat } from '@/helpers/DateTimeHelper';
import moment from 'moment';

interface DateValidationOptions {
  maxDate?: string;
  minDate?: string;
  dob?: boolean;
  minDob?: number;
  maxDob?: number;
}

interface DateValidationResponse {
  isValid: boolean;
  message?: string;
  tooYoung?: boolean;
}

const MIN_AGE = 18;
const MAX_AGE = 120;

function getParsedDate(date: string) {
  return moment(date, selectDateFormat(date));
}

export default (value?: string, opts?: DateValidationOptions): DateValidationResponse => {
  if (!value) return { isValid: false, message: 'Date missing' };

  if (!isDateValid(value)) return { isValid: false, message: 'Invalid date' };

  const parsedValue = getParsedDate(value);
  if (!parsedValue) return { isValid: false, message: 'Invalid date' };

  if (!opts) return { isValid: true };

  if (opts.maxDate && !parsedValue.isSameOrBefore(getParsedDate(opts.maxDate))) {
    return { isValid: false, message: `Date cannot be after ${opts.maxDate}` };
  }

  if (opts.minDate && !parsedValue.isSameOrAfter(getParsedDate(opts.minDate))) {
    return { isValid: false, message: `Date cannot be before ${opts.minDate}` };
  }

  if (opts.dob) {
    const minDob = opts.minDob || MIN_AGE;
    const maxDobDate = moment().subtract(minDob, 'years');

    if (!parsedValue.isSameOrBefore(maxDobDate)) {
      return {
        isValid: false,
        message: `You must be age ${minDob} or older to proceed`,
        tooYoung: true,
      };
    }

    const maxDob = opts.maxDob || MAX_AGE;
    const minDobDate = moment().subtract(maxDob, 'years');

    if (!parsedValue.isSameOrAfter(minDobDate)) {
      return {
        isValid: false,
        message: `You cannot be older than ${maxDob} of age to proceed`,
      };
    }
  }

  return { isValid: true };
};
