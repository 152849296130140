<template>
  <div class="statement-details">
    <v-container fluid class="portal__container">
      <statement-details :statement-id="$route.params.id" />
    </v-container>
  </div>
</template>

<script lang="ts">
import StatementDetails from '@/components/Merchant/Portal/Statements/StatementDetails/index.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PortalInvoices',
  components: {
    StatementDetails,
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/statement-details.scss";

@media not print {
  .statement-details {
    height: 100%;
    background-color: var(--grayscale-color-4);

    .v-container {
      max-width: 90rem;
      margin: auto;
    }
  }
}
</style>
