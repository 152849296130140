import { AxiosResponse } from 'axios';
import {
  AddressLookupRequest,
  AddressLookupResponse,
  EmailValidationResult,
} from '@/interfaces/addressValidation/AddressDataInterface';
import { BASE_PATH, instance, rawClient } from './index';

// eslint-disable-next-line import/prefer-default-export
export async function lookupAddress(
  address: AddressLookupRequest,
): Promise<AddressLookupResponse> {
  const { data } = await instance.post(`${BASE_PATH}/location/validate/`, address);
  return data;
}

export function validateEmail(email: string): Promise<AxiosResponse<EmailValidationResult>> {
  return rawClient.post(`${BASE_PATH}/account/user/email-address-validation/`, { email });
}
