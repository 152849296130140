import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b5f50d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-test"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_select = _resolveComponent("v-select")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createBlock(_component_v_select, _mergeProps({ ref: "select" }, _ctx.$attrs, {
    "data-test": _ctx.mainDataTest,
    disabled: _ctx.disabled,
    autocomplete: "off",
    "menu-icon": _ctx.appendIcon,
    variant: _ctx.variantAttr,
    "menu-props": _ctx.menuProps,
    label: _ctx.label,
    "model-value": _ctx.hasValue ? _ctx.modelValue : undefined,
    "item-value": _ctx.itemValue,
    "onClick:clear": _ctx.onClear
  }), {
    selection: _withCtx(({ item }) => [
      _withDirectives((_openBlock(), _createElementBlock("span", {
        class: "v-select__selection-text",
        "data-test": _ctx.addDataTest ? _ctx.addDataTest : null
      }, [
        _createTextVNode(_toDisplayString(item.props.title ?? ''), 1)
      ], 8, _hoisted_1)), [
        [_directive_private]
      ])
    ]),
    item: _withCtx(({ props }) => [
      _withDirectives(_createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), null, 16), [
        [_directive_private, `Select item in ${_ctx.label}`]
      ])
    ]),
    _: 1
  }, 16, ["data-test", "disabled", "menu-icon", "variant", "menu-props", "label", "model-value", "item-value", "onClick:clear"]))
}