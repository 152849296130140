import CreditEnginePullTypes from '@/enums/CreditEngine/PullTypes';
import { ConsumerInvitationInterface } from '@/interfaces/consumer/ConsumerInvitationInterface';
import { FraudCheckInterface } from '@/interfaces/consumer/FraudCheckInterface';
import { CreditEngineCheckInterface } from '@/interfaces/consumer/loanApply/CreditEngineCheckInterface';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { ConsumerStateInterface } from '@/interfaces/states/ConsumerStateInterface';
import ResetModuleState from '@/utils/store/ResetModuleState';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

const initialState = (): ConsumerStateInterface => ({
  loanApplyWizardData: {},
  fraudCheck: {
    risk_decision: '',
    risk_score: 0,
  } as FraudCheckInterface,
  consumerApplicationId: '',
  consumerHumanReadableApplicationId: '',
  merchantId: '',
  merchantName: '',
  loanAgreement: null,
  selectedLoan: {} as RateSheetData,
  loanOptions: [] as Array<RateSheetData>,
  loanApplyWizardStep: 0,
  loanApplyWizardReEntryStep: 1,
  invitation: {} as ConsumerInvitationInterface,
  lenderName: '',
  lenderPrivacyPolicyUrl: '',
  lenderInfo: {},
  processingCreditEngine: false,
  showDisclaimerPage: false,
  applicationCanceled: {
    canceled: false,
    merchantName: '',
  },
  expiredLink: false,
  fetchingLoans: false,
  fraudCheckError: false,
  borrowerAgreementSpecificStateError: false,
  existingApplications: [] as Array<string>,
  creditEngineCheck: {
    [CreditEnginePullTypes.SOFT]: {},
    [CreditEnginePullTypes.HARD]: {},
  } as CreditEngineCheckInterface,
  loanApplyReEntry: false,
  applicationValid: true,
  areLendersAvailable: true,
  isLoading: false,
  idTypes: [],
  portalOnboarding: {
    hasAccount: false,
    portalUrl: '',
    shouldCreateAccount: false,
  },
  applicationCompleted: false,
  completeLaterClicked: false,
});

export default {
  namespaced: true as true,
  state: initialState() as ConsumerStateInterface,
  getters,
  mutations: {
    ...mutations,
    resetModuleState(state: any) {
      ResetModuleState(state, initialState());
    },
  },
  actions,
};
