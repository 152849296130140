import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createBlock(_component_custom_button, {
    disabled: _ctx.isDisabled,
    "aria-label": "Close",
    variant: "icon",
    onClick: _ctx.cta
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_icon, null, {
        default: _withCtx(() => [
          _createTextVNode("mdi-close-circle")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["disabled", "onClick"]))
}