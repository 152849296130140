<template>
  <v-data-table-server
    v-model:page="page"
    v-model:sort-desc="orderDesc"
    :mobile="isMobile"
    :sort-by="[{ key: 'purchase_window_end_date', order: orderDesc ? 'desc' : 'asc' }]"
    :headers="headers"
    :items="results"
    :items-per-page="5"
    :loading="loading"
    :items-length="count"
    class="fs-unmask minimal-pagination"
    must-sort>
    <template #item.sent_to="{ item }">
      <button
        class="consumer-activity-interactable"
        @click="seeConsumerSummary(item.consumer_application_uuid)">
        {{ item.sent_to }}
      </button>
    </template>

    <template #item.id_number="{ item }">
      <application-summary-popup
        :id-number="item.id_number"
        :invitation-id="item.consumer_invitation_uuid" />
    </template>

    <template #item.available_spend="{ item }">
      <span class="font-weight-bold mr-2">
        {{ CurrencyFormatLong(item.available_spend) }}
      </span>
    </template>

    <template #item.amount_spent="{ item }">
      <span class="mr-2">
        {{ CurrencyFormatLong(item.amount_spent) }}
      </span>
    </template>

    <template #item.purchase_window_end_date="{ item }">
      <span class="mr-2">
        {{ formatDate(item.purchase_window_end_date) }}
      </span>
    </template>

    <template #loading>
      <table-skeleton-loader :rows="5" :cols="colWidths" />
    </template>
  </v-data-table-server>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useConsumerActivityTable } from '@/composables/useConsumerActivityTable';
import store from '@/store';
import { useDisplay } from 'vuetify';
import { getOpportunities } from '@/api/consumerActivity';
import { Opportunity } from '@/interfaces/ConsumerActivity';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import { RequestOptions } from '@/api/types';
import moment from 'moment';
import TableSkeletonLoader from '@/components/Tables/TableSkeletonLoader.vue';
import ApplicationSummaryPopup from './ApplicationSummaryPopup.vue';

export default defineComponent({
  components: { ApplicationSummaryPopup, TableSkeletonLoader },

  setup() {
    const { xs } = useDisplay();
    const isMobile = xs;

    const headers: TableHeaderInterface[] = [
      {
        title: 'Sent To',
        value: 'sent_to',
        sortable: false,
      },
      {
        title: 'ID Number',
        value: 'id_number',
        sortable: false,
        width: '9.5rem',
        align: 'end',
      },
      {
        title: 'Available Spend',
        value: 'available_spend',
        sortable: false,
        width: '8.5rem',
        align: 'end',
      },
      {
        title: 'Amount Spent',
        value: 'amount_spent',
        sortable: false,
        width: '8.5rem',
        align: 'end',
      },
      {
        title: 'Purchase Window End Date',
        value: 'purchase_window_end_date',
        sortable: true,
        width: '13.5rem',
        align: 'end',
      },
    ];
    // Subtracting 1.5rem to account for TH padding.
    const colWidths = ['auto', '8rem', '7rem', '7rem', '11.5rem'];

    const merchantId = store.getters['MerchantPortal/getMerchantUuid'];
    const request = (options: RequestOptions) => getOpportunities(merchantId, options);
    const orderDesc = ref(true);
    const data = useConsumerActivityTable<Opportunity>(request, { orderBy: 'purchase_window_end_date', orderDesc });

    const formatDate = (date: string) => moment(date).format('MM-DD-YYYY');

    return {
      ...data,
      orderDesc,
      headers,
      colWidths,
      CurrencyFormatLong,
      formatDate,
      isMobile,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table-default.scss';
@import '@/assets/scss/consumer-activity-table.scss';
</style>
