<template>
  <div>
    <standard-dialog
      :dialog="showDialog"
      :on-cta="onCta"
      :on-cancel="onCancel"
      button-label="Send Email"
      :persistent="true"
      :show-cancel-cta="false"
      :show-clear-cta="false"
      :show-actions="true"
      button-size="small"
      data-test="idleModal">
      <template #title>
        Please Confirm
      </template>

      <template #body>
        <div>
          <p>Want to complete this later?</p>
          <p>Click below for an email link to pick up where you left off.</p>
        </div>
      </template>

      <template #actions>
        <custom-button
          data-test="sendEmailBtn"
          full-width
          :disabled="disableButton"
          @click="onCta">
          Send Email
        </custom-button>
      </template>
    </standard-dialog>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';

export default defineComponent({
  name: 'CompleteThisLaterDialog',

  components: {
    StandardDialog,
    CustomButton,
  },

  mixins: [
    IdleTimeoutMixin,
  ],

  props: {
    dialog: { type: Boolean, default: false },
    disableButton: { type: Boolean, default: false },
    onCta: { type: Function as PropType<() => void>, default: () => null },
    onCancel: { type: Function as PropType<() => void>, default: () => null },
  },

  data() {
    return {
      showDialog: this.dialog,
    };
  },

  watch: {
    dialog: {
      handler(newVal: boolean) {
        this.showDialog = newVal;
        if (newVal) {
          this.clearIdleTimeout();
        } else {
          this.restartIdleTimeout();
        }
      },
    },
  },
});
</script>

<style lang="scss">
@import "@/assets/scss/components/dialogs";

p {
  font-size: 1rem;
}

.dialog-wrap {
  .dialog-body-wrap {
    > div > p {
      font-size: 1rem;
    }
  }
}
</style>
