export default {
  CAPTURE_ID: 'capture_id',
  LOAN_STACKING: 'use_loan_stacking',
  USE_GUCA: 'use_guca',
  RE_ENTRY: 'merchant_reentry',
  MULTI_LOCATION: 'multi_location',
  HUMAN_FRIENDLY_APP_STATUS: 'human_friendly_app_status',
  SKIP_DOCV: 'skip_document_verification',
  USE_FALLBACK_PRODUCTS: 'use_fallback_products',
  COLLAPSE_OFFERS_COB: 'collapse_offers_on_cob',
  CREDIT_LIMIT_75K: 'credit_limit_75k',
};
