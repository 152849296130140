export default (value: string): boolean => {
  let URL_REGEX: any;

  if (value.includes('//')) {
    const [protocol, domain] = value.split('//');
    if (!['http:', 'https:'].includes(protocol)) return false;

    value = domain;
  }

  if (value.includes('www.')) {
    URL_REGEX = new RegExp(/^www\.[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/);
  } else {
    URL_REGEX = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/);
  }
  return URL_REGEX.test(value);
};
