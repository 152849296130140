<template>
  <div class="merchant-name" :class="{ 'merchant-name--small': small }">
    <h6
      v-if="merchantName"
      class="merchant-name__title"
      :class="{ 'reduced-font': isFontReduced }"
      data-test="merchantName">
      {{ name }}
    </h6>
    <v-skeleton-loader
      v-else
      class="mx-auto skeleton-portal"
      max-width="192"
      type="text" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MerchantName',
  props: {
    merchantName: { type: String, default: '' },
    small: { type: Boolean, default: false },
  },
  computed: {
    name() {
      const regRule = this.small ? /(.{43})..+/ : /(.{37})..+/;

      return this.merchantName.replace(regRule, '$1…');
    },
    isFontReduced(): boolean {
      return this.merchantName ? this.merchantName.length > 26 : false;
    },
  },
});
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/vuetify/elevation.scss";
  @import '@/assets/scss/mixins/media_queries.scss';
  @import "@/assets/scss/variables/font-variables.scss";

  $box-width: 12rem;
  $box-width-small: 12.375rem;
  $box-height: 4rem;
  $box-height-sm: 3rem;
  $padding: 1rem;

  .merchant-name {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    height: $box-height;
    width: $box-width;
    border-radius: 4px;
    padding: $padding;
    background-color: #FFFFFF;
    box-shadow: $v-elevation-4;

    &--small {
      padding: calc($padding / 2);
      margin-top: 0;
      width: $box-width-small;
      height: $box-height-sm;
      text-align: left;

      > .merchant-name__title {
        font-family: $font-family-serif-name;
        inline-size: calc($box-width-small - $padding);
        font-size: 0.75rem;
        font-weight: 600;
        text-align: center;
      }
    }

    &__title {
      margin: 0;
      color: var(--grayscale-color-1);
      inline-size: calc($box-width - ($padding * 2));
      max-height: 2rem;
      overflow-wrap: break-word;
      font-size: 0.875rem;
      line-height: 1rem;
      overflow: hidden;
      text-align: center;

      &.reduced-font {
        font-size: 0.75rem;
      }
    }

    @include mobile {
      margin-right: 1rem;
    }

    @include media-breakpoint-down-md {
      margin-top: 0;
      height: $box-height-sm;
    }

    @include up-to-desktop {
      margin: 0 1rem 0 0;
    }
  }
</style>
