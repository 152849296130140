<template>
  <v-card class="custom-card">
    <v-card-title>
      Statement Summary
    </v-card-title>
    <v-card-subtitle class="space-between">
      <div>
        <span>Total charges</span>
        <span v-private>{{ CurrencyFormatLong(summary.total_charges) }}</span>
      </div>
      <div>
        <span>Refunds</span>
        <span v-private>{{ CurrencyFormatLong(summary.total_refunds) }}</span>
      </div>
      <div>
        <span>Net Purchases</span>
        <span v-private>{{ CurrencyFormatLong(summary.net_purchases) }}</span>
      </div>
      <div>
        <span>Fees</span>
        <span v-private>{{ CurrencyFormatLong(fees) }}</span>
      </div>
      <div>
        <span>Net Merchant Fees</span>
        <span v-private>{{ CurrencyFormatLong(summary.net_merchant_fees) }}</span>
      </div>
      <div>
        <span>Net Merchant Receipt</span>
        <span v-private>{{ CurrencyFormatLong(summary.total_net_merchant_receipt) }}</span>
      </div>
    </v-card-subtitle>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { StatementSummaryInterface } from '@/interfaces/statements/StatementSummaryInterface';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';

export default defineComponent({
  name: 'StatementSummary',
  props: {
    summary: { type: Object as PropType<StatementSummaryInterface>, required: true },
  },
  computed: {
    fees(): string {
      return this.summary.total_merchant_fees || '0.00';
    },
  },
  methods: {
    CurrencyFormatLong,
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';
@import "@/assets/scss/statement-details.scss";

.space-between {
  &.v-card-subtitle {
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
