<template>
  <standard-dialog
    :dialog="show"
    :show-actions="true"
    :on-cancel="closeModal"
    title="Bank Account Confirmation">
    <template #body>
      <custom-alert type="error">
        An admin on your account needs to confirm bank account information in payment settings
        before you can send any new loan applications.
      </custom-alert>
    </template>
    <template #actions>
      <custom-button
        full-width
        @click="onClick">
        {{ isAdmin ? 'Take Me To Payment Settings' : 'Close' }}
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import MerchantPermissionsMixin from '@/mixins/Auth/MerchantPermissionsMixin';

export default defineComponent({
  name: 'PaymentSettingsUpdateModal',

  components: {
    CustomAlert,
    StandardDialog,
    CustomButton,
  },

  mixins: [MerchantPermissionsMixin],

  data() {
    return {
      show: true,
    };
  },

  methods: {
    onClick() {
      this.closeModal();
      if (this.isAdmin) {
        this.$router.push({ name: 'merchant-admin', query: { target: 'payments' } });
      }
    },

    closeModal() {
      this.show = false;
    },
  },
});
</script>

<style lang="scss" scoped>
p {
  font-size: 1rem;
}
</style>
