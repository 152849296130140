import { BASE_PATH, instance } from './index';

// eslint-disable-next-line import/prefer-default-export
export async function completeLaterMob(merchantAppId: string) {
  const postData: object = {
    merchant_application_uuid: merchantAppId,
  };

  return instance.post(`${BASE_PATH}/merchant/re-entry/`, postData);
}
