import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30087b86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "portal-header__user-info__container" }
const _hoisted_2 = { class: "portal-header__user-info__details-wrapper" }
const _hoisted_3 = { class: "portal-header__user-info__details" }
const _hoisted_4 = {
  class: "ml-1 portal-header__user-info__details__name",
  "data-test": "userNameLabel"
}
const _hoisted_5 = { class: "portal-header__user-info__role" }
const _hoisted_6 = {
  "data-test": "roleLabel",
  class: "ml-1"
}
const _hoisted_7 = {
  key: 0,
  class: "portal-header__user-info__location"
}
const _hoisted_8 = { class: "portal-header__user-info__cta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_merchant_location = _resolveComponent("merchant-location")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_reset_user_password_modal = _resolveComponent("reset-user-password-modal")!
  const _component_location_settings_modal = _resolveComponent("location-settings-modal")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["elevation-1 portal-header__user-info", { 'multi-location': _ctx.multiLocationActive }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showSkeleton)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_v_skeleton_loader, {
              width: "100%",
              type: "text"
            }),
            _createVNode(_component_v_skeleton_loader, {
              width: "100%",
              type: "text"
            })
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", null, [
                  _createTextVNode(" Hello "),
                  _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, [
                    _createTextVNode(_toDisplayString(_ctx.userNameLabel) + "! ", 1)
                  ])), [
                    [_directive_private, 'Username']
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", null, [
                  _createTextVNode(" Role: "),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.userRoleLabel), 1)
                ])
              ])
            ]),
            (_ctx.multiLocationActive)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_merchant_location)
                ]))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_v_menu, { location: "bottom" }, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_custom_button, _mergeProps({
            class: "icon-button",
            "data-test": "mpMenuBtn",
            "aria-label": "User menu"
          }, props), {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode(" mdi-dots-vertical ")
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1040)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list, { class: "text-left" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                "data-test": "resetPassBtn",
                onClick: _ctx.showResetPasswordDialog
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, { "aria-label": "Update Password" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Update Password ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_ctx.multiLocationActive)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 0,
                    "data-test": "locationSettingsBtn",
                    onClick: _ctx.showLocationSettings
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, { "aria-label": "Location Settings" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Location Settings ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_list_item, {
                "data-test": "logOutBtn",
                onClick: _ctx.userLogout
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, { "aria-label": "Logout" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Log Out ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_reset_user_password_modal, {
      show: _ctx.isResetPasswordDialogVisible,
      "on-close": _ctx.hideResetPasswordDialog
    }, null, 8, ["show", "on-close"]),
    (_ctx.locationSettingsVisible)
      ? (_openBlock(), _createBlock(_component_location_settings_modal, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.locationSettingsVisible = false))
        }))
      : _createCommentVNode("", true)
  ], 2))
}