import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11d98f50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 2 }
const _hoisted_17 = { key: 3 }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { key: 2 }
const _hoisted_21 = {
  key: 0,
  class: "pending-transaction-button"
}
const _hoisted_22 = {
  key: 1,
  class: "mb-4 mx-4",
  style: {"margin-top":"5rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_charge_header = _resolveComponent("charge-header")!
  const _component_charge_body = _resolveComponent("charge-body")!
  const _component_info_modal = _resolveComponent("info-modal")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["customer-summary-charge-section", [
        _ctx.transactionStatusErrored ? 'errored-transaction' : '',
        _ctx.transactionStatusPending ? 'pending-transaction' : '',
        _ctx.showTransactions ? 'clear-border' : '']])
    }, [
      (_ctx.isInitialCharge && !_ctx.showInitialForm)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!_ctx.canTransactFullAmount)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (!_ctx.paymentConfirmation)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_charge_header, {
                          "initial-charge": _ctx.isInitialCharge,
                          "charge-name": _ctx.chargeName,
                          "max-charge-value": _ctx.chargeMaxAmount,
                          "max-charge-percentage": _ctx.chargeMaxPercentage,
                          "formatted-max-charge": _ctx.formattedMaxAmount,
                          "show-percentages": true,
                          onHelpIcon: _ctx.showHelpModal
                        }, null, 8, ["initial-charge", "charge-name", "max-charge-value", "max-charge-percentage", "formatted-max-charge", "onHelpIcon"]),
                        _createVNode(_component_charge_body, {
                          "initial-charge": _ctx.isInitialCharge,
                          "max-charge-value": _ctx.chargeMaxAmount,
                          onShowConfirmation: _ctx.showSendPaymentRequestConfirmation
                        }, null, 8, ["initial-charge", "max-charge-value", "onShowConfirmation"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.paymentConfirmation)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_charge_header, {
                          "payment-confirmation": _ctx.paymentConfirmation,
                          onGoBack: _ctx.showInitialTransactionAgain
                        }, null, 8, ["payment-confirmation", "onGoBack"]),
                        _createVNode(_component_charge_body, {
                          "payment-confirmation": _ctx.paymentConfirmation,
                          "charge-name": _ctx.chargeName,
                          "charge-value": _ctx.amount,
                          "consumer-name": _ctx.consumerName,
                          "disable-send-button": _ctx.buttonDisabled,
                          onSendPaymentRequest: _ctx.sendPaymentRequest
                        }, null, 8, ["payment-confirmation", "charge-name", "charge-value", "consumer-name", "disable-send-button", "onSendPaymentRequest"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.canTransactFullAmount)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (!_ctx.finalConfirmation)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_charge_header, {
                          "final-charge-question": true,
                          "charge-name": _ctx.chargeName
                        }, null, 8, ["charge-name"]),
                        _createVNode(_component_charge_body, {
                          "final-charge-question": true,
                          onNotFinalCharge: _ctx.notFinalCharge,
                          onYesFinalCharge: _ctx.yesFinalCharge
                        }, null, 8, ["onNotFinalCharge", "onYesFinalCharge"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.finalConfirmation)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_charge_header, { "second-confirmation": true }),
                        _createVNode(_component_charge_body, {
                          "second-confirmation": true,
                          "confirmation-text": _ctx.finalConfirmationText,
                          onCancelTransaction: _ctx.cancelFinalCharge,
                          onProceed: _ctx.proceedWithFinalCharge
                        }, null, 8, ["confirmation-text", "onCancelTransaction", "onProceed"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showInitialForm)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (!_ctx.paymentConfirmation && !_ctx.calculatedFinalCharge && !_ctx.calculatedMiddleToFinal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_charge_header, {
                    "initial-charge": true,
                    "charge-name": _ctx.chargeName,
                    "max-charge-value": _ctx.chargeMaxAmount,
                    "max-charge-percentage": _ctx.chargeMaxPercentage,
                    "formatted-max-charge": _ctx.formattedMaxAmount,
                    onHelpIcon: _ctx.showHelpModal
                  }, null, 8, ["charge-name", "max-charge-value", "max-charge-percentage", "formatted-max-charge", "onHelpIcon"]),
                  _createVNode(_component_charge_body, {
                    "initial-charge": true,
                    "second-charge": true,
                    "max-charge-value": _ctx.chargeMaxAmount,
                    onCancelTransaction: _ctx.cancelFinalCharge,
                    onShowConfirmation: _ctx.showSendPaymentRequestConfirmation,
                    onSendPaymentRequest: _ctx.sendPaymentRequest,
                    onSendFinalCharge: _ctx.middleToFinal
                  }, null, 8, ["max-charge-value", "onCancelTransaction", "onShowConfirmation", "onSendPaymentRequest", "onSendFinalCharge"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.paymentConfirmation && !_ctx.calculatedFinalCharge && !_ctx.calculatedMiddleToFinal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_charge_header, {
                    "payment-confirmation": true,
                    onGoBack: _ctx.showInitialTransactionAgain
                  }, null, 8, ["onGoBack"]),
                  _createVNode(_component_charge_body, {
                    "payment-confirmation": true,
                    "charge-name": _ctx.chargeName,
                    "charge-value": _ctx.amount,
                    "consumer-name": _ctx.consumerName,
                    "disable-send-button": _ctx.buttonDisabled,
                    onSendPaymentRequest: _ctx.sendPaymentRequest
                  }, null, 8, ["charge-name", "charge-value", "consumer-name", "disable-send-button", "onSendPaymentRequest"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.calculatedFinalCharge && !_ctx.calculatedMiddleToFinal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_charge_header, { "second-confirmation": true }),
                  _createVNode(_component_charge_body, {
                    "second-confirmation": true,
                    "confirmation-text": _ctx.manualFinal,
                    "disable-send-button": _ctx.buttonDisabled,
                    onCancelTransaction: _ctx.cancelCalculatedFinalCharge,
                    onProceed: _ctx.sendPaymentRequest
                  }, null, 8, ["confirmation-text", "disable-send-button", "onCancelTransaction", "onProceed"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.calculatedMiddleToFinal && !_ctx.calculatedFinalCharge)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_charge_header, { "second-confirmation": true }),
                  _createVNode(_component_charge_body, {
                    "second-confirmation": true,
                    "confirmation-text": _ctx.finalConfirmationText,
                    onCancelTransaction: _ctx.cancelFinalCharge,
                    onProceed: _ctx.proceedWithFinalCharge
                  }, null, 8, ["confirmation-text", "onCancelTransaction", "onProceed"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.transactionPending)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            (!_ctx.secondConfirmation && !_ctx.showInitialForm && !_ctx.pendingFinalQuestion)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createVNode(_component_charge_header, {
                    "transaction-pending": true,
                    "transaction-status": _ctx.transactionStatus,
                    "transaction-status-errored": _ctx.transactionStatusErrored,
                    onHelpIconExpired: _ctx.showExpiredHelpModal
                  }, null, 8, ["transaction-status", "transaction-status-errored", "onHelpIconExpired"]),
                  _createVNode(_component_charge_body, {
                    "transaction-pending": true,
                    "transaction-status-errored": _ctx.transactionStatusErrored,
                    "transaction-status": _ctx.transactionStatus,
                    "charge-name": _ctx.pendingChargeName,
                    "charge-value": _ctx.pendingChargeAmount,
                    "charge-date": _ctx.pendingChargeDate,
                    "formatted-max-charge": _ctx.formattedAmount,
                    onShowInitialForm: _ctx.resetPendingTransaction,
                    onSendPaymentRequest: _ctx.sendPaymentRequest
                  }, null, 8, ["transaction-status-errored", "transaction-status", "charge-name", "charge-value", "charge-date", "formatted-max-charge", "onShowInitialForm", "onSendPaymentRequest"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.secondConfirmation && !_ctx.showInitialForm && !_ctx.pendingFinalQuestion)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createVNode(_component_charge_header, { "second-confirmation": _ctx.secondConfirmation }, null, 8, ["second-confirmation"]),
                  _createVNode(_component_charge_body, {
                    "second-confirmation": _ctx.secondConfirmation,
                    "confirmation-text": _ctx.secondConfirmationText,
                    onCancelTransaction: _ctx.cancelPendingConfirmation,
                    onProceed: _ctx.showPendingFinal
                  }, null, 8, ["second-confirmation", "confirmation-text", "onCancelTransaction", "onProceed"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.secondConfirmation && _ctx.pendingFinalQuestion && !_ctx.showInitialForm)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createVNode(_component_charge_header, {
                    "final-charge-question": true,
                    "charge-name": _ctx.chargeName
                  }, null, 8, ["charge-name"]),
                  _createVNode(_component_charge_body, {
                    "final-charge-question": true,
                    onNotFinalCharge: _ctx.notPendingFinalCharge,
                    onYesFinalCharge: _ctx.yesPendingFinalCharge
                  }, null, 8, ["onNotFinalCharge", "onYesFinalCharge"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.completedTransaction && !_ctx.customInitialCharge && !_ctx.transactionPending)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            (!_ctx.finalCharge && !_ctx.showInitialForm)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createVNode(_component_charge_header, {
                    "completed-transaction": _ctx.completedTransaction,
                    "show-pending-date": _ctx.showPendingDate,
                    "next-charge-date": _ctx.nextChargeDate
                  }, null, 8, ["completed-transaction", "show-pending-date", "next-charge-date"]),
                  _createVNode(_component_charge_body, {
                    "completed-transaction": _ctx.completedTransaction,
                    "all-completed-transactions": _ctx.allCompletedTransactions
                  }, null, 8, ["completed-transaction", "all-completed-transactions"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.finalCharge && !_ctx.finalConfirmation && !_ctx.showInitialForm)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createVNode(_component_charge_header, {
                    "final-charge-question": true,
                    "charge-name": _ctx.chargeName
                  }, null, 8, ["charge-name"]),
                  _createVNode(_component_charge_body, {
                    "final-charge-question": true,
                    onNotFinalCharge: _ctx.notFinalCharge,
                    onYesFinalCharge: _ctx.yesFinalCharge
                  }, null, 8, ["onNotFinalCharge", "onYesFinalCharge"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.finalCharge && _ctx.finalConfirmation && !_ctx.showInitialForm)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createVNode(_component_charge_header, { "second-confirmation": true }),
                  _createVNode(_component_charge_body, {
                    "second-confirmation": true,
                    "confirmation-text": _ctx.finalConfirmationText,
                    onCancelTransaction: _ctx.cancelFinalCharge,
                    onProceed: _ctx.proceedWithFinalCharge
                  }, null, 8, ["confirmation-text", "onCancelTransaction", "onProceed"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_info_modal, {
        show: _ctx.showInfoModal,
        "modal-type": "info-modal",
        onClose: _ctx.closeInfoModal
      }, null, 8, ["show", "onClose"]),
      _createVNode(_component_info_modal, {
        show: _ctx.expiredHelpModal,
        "modal-type": "expired-modal",
        onClose: _ctx.closeInfoModal
      }, null, 8, ["show", "onClose"])
    ], 2),
    (_ctx.transactionPending && _ctx.transactionStatus === 'Pending'
        && !_ctx.secondConfirmation && !_ctx.showInitialForm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createVNode(_component_custom_button, {
            "full-width": "",
            "data-test": "sendButton",
            size: "small",
            variant: "secondary",
            onClick: _ctx.showResetPendingConfirmation
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Send New Payment Request ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.completedTransaction
        && _ctx.canSendTransaction
        && !_ctx.customInitialCharge
        && !_ctx.finalCharge
        && !_ctx.showInitialForm)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createVNode(_component_custom_button, {
            "full-width": "",
            "data-test": "sendButton",
            onClick: _ctx.finalChargeQuestion
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Send New Payment Request ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}