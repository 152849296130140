<template>
  <div>
    <standard-dialog
      :dialog="show"
      :show-actions="true"
      :on-cancel="closeModal"
      style="z-index: 3000"
      :title="title">
      <template #body>
        <p class="modal-text">
          Currently you are able to view and change your rate
          sheet, add banking information and set up additional users on the platform.
        </p>

        <p class="modal-text">
          We will contact you soon to schedule training certification
          so you can send applications.
        </p>

        <p class="modal-text">
          Feel free to call us at {{ footerData.support_number }}
          to schedule your training certification today!
        </p>
      </template>
      <template #actions>
        <custom-button
          full-width
          class="closeBtn"
          data-test="closeBtn"
          size="small"
          @click="closeModal">
          Close
        </custom-button>
      </template>
    </standard-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import localStorageConstants from '@/constants/LocalStorageConstants';

export default defineComponent({
  name: 'AdminVerificationModal',
  components: {
    StandardDialog,
    CustomButton,
  },
  props: {
    title: { type: String, default: 'Welcome!' },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    merchantData() {
      return this.$store.getters['MerchantPortal/getMerchantData'];
    },
    footerData(): any {
      return this.$store.getters['Ui/getFooterData'] || {};
    },
  },
  watch: {
    merchantData: {
      handler(val: any) {
        if (val === null) return;

        const wasShown = localStorage.getItem(localStorageConstants.SHOW_ADMIN_VERIFY_MODAL);
        if (val.user_type.name === 'merchant-admin'
        && val.merchant.status_string === 'Verification Hold' && wasShown !== 'true') {
          localStorage.setItem(localStorageConstants.SHOW_ADMIN_VERIFY_MODAL, 'true');
          this.show = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    closeModal() {
      this.show = false;
      this.$emit('close', true);
    },
  },
});
</script>

<style lang="scss" scoped>
.modal-text {
  font-size: 1rem;
}
</style>
