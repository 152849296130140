import {
  find, get, isArray, map, toLower,
} from 'lodash';
import { AccountUserDetailPayloadInterface } from '@/interfaces/merchantPortal/AccountUserDetailPayloadInterface';
import { ActionContext } from 'vuex';
import { ContactDataPhoneNumberPayloadInterface } from '@/interfaces/merchantPortal/ContactDataPhoneNumberPayloadInterface';
import { CreateNewMerchantUserPayloadInterface } from '@/interfaces/merchantPortal/CreateNewMerchantUserPayloadInterface';
import { EditMerchantUserPayloadInterface } from '@/interfaces/merchantPortal/EditMerchantUserPayloadInterface';
import instance from '@/Axios';
import { MerchantDetailsInterface } from '@/interfaces/merchant/MerchantDetailsInterface';
import { MerchantOnboardingStateInterface } from '@/interfaces/states/MerchantOnboardingStateInterface';
import { MerchantPortalStateInterface } from '@/interfaces/states/MerchantPortalStateInterface';
import { NewMerchantUserInterface } from '@/interfaces/merchantPortal/NewMerchantUserInterface';
import { NewMerchantUserPayloadInterface } from '@/interfaces/merchantPortal/NewMerchantUserPayloadInterface';
import {
  PatchContactDataPhoneNumberPayloadInterface,
} from '@/interfaces/merchantPortal/PatchContactDataPhoneNumberPayloadInterface';
import { PaymentRequestData } from '@/interfaces/merchantPortal/PaymentRequestData';
import { PhoneNumberSetInterface } from '@/interfaces/PhoneNumberSetInterface';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { RateSheetsPostDataInterface } from '@/interfaces/rates/RateSheetsPostDataInterface';
import { TransactionsDescriptionsInterface } from '@/interfaces/merchantPortal/TransactionsDescriptionsInterface';
import { UpdateMerchantUserPayloadInterface } from '@/interfaces/merchantPortal/UpdateMerchantUserPayloadInterface';
import { UserListPayloadInterface } from '@/interfaces/merchantPortal/UserListPayloadInterface';
import postTokenPayment from '@/api/repay';
import routingNumber from '@/api/checkRouting';
import { getChildLocations, updateMerchantPrimaryLocation } from '@/api/merchant';
import { getAxiosError, setStatusAndErrorStateFromAxios } from '@/api/utils';
import SetAxiosErrorToState from '@/utils/store/SetAxiosErrorToState';
import { MerchantLocation } from '@/interfaces/merchant/MerchantLocation';
import { AxiosError } from 'axios';
import { RequestOptions } from '@/api/types';

type Context = ActionContext<MerchantPortalStateInterface, any>;

export async function dispatchSearchApprovedLoans(context: Context, searchString: string) {
  const merchantUuid = context.getters.getMerchantUuid;

  let searchResults: any = [];
  let response: any = {};
  let responseStatus: number = 0;
  const pageSize = 10;

  try {
    response = await instance.get(
      `/api/v1/merchant/${merchantUuid}/available_transactions/?page_size=${pageSize}&search=${searchString}`,
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 200) {
      searchResults = response.data.results;
      context.commit('setSearchInput', searchString);
      context.commit('setSearchedItems', searchResults);
    }
  }

  return searchResults;
}

export async function dispatchResetSearch(context: Context) {
  context.commit('resetSearchInput');
}

export async function dispatchGetMerchantDetails(
  context: Context,
): Promise<MerchantDetailsInterface> {
  const merchantUuid = context.getters.getMerchantUuid;

  try {
    const response = await instance.get(`/api/v1/merchant/${merchantUuid}/merchant-detail/`);
    if (response.status === 200) {
      return response.data;
    }
    return {} as MerchantDetailsInterface;
  } catch (e) {
    SetAxiosErrorToState(e, context);
    return {} as MerchantDetailsInterface;
  }
}

export async function dispatchFetchNewLoanApplications(
  context: Context,
  payload: { pageNumber: number, searchString: string },
) {
  context.commit('Ui/setErrors', {}, { root: true });

  const merchantUuid = context.getters.getMerchantUuid;
  const path = `/api/v1/merchant/${merchantUuid}/loan-applications/`;
  const params = { page: payload.pageNumber, search: payload.searchString };

  try {
    const { data } = await instance.get(path, { params });
    context.commit('setNewLoanApplications', data);
  } catch (e) {
    SetAxiosErrorToState(e, context);
  }
}

export async function selectLoanApplication(context: Context, payload: string) {
  context.commit('Ui/setErrors', {}, { root: true });

  const merchantUuid = context.getters.getMerchantUuid;
  const consumerAppId = payload;
  const path = `/api/v1/merchant/${merchantUuid}/loan-application/${consumerAppId}`;

  try {
    const { data } = await instance.get(path);
    context.commit('setSelectedLoanApplication', data);
  } catch (e) {
    SetAxiosErrorToState(e, context);
  }
}

export async function deSelectLoanApplication(context: Context) {
  context.commit('deSelectLoanApplicationFromStore');
}

export async function dispatchSendInvitation(context: Context, newLoanApplicationDetails: any) {
  context.commit('Ui/setErrors', {}, { root: true });

  const { email, phone, offerCode } = newLoanApplicationDetails;

  const merchantUuid = context.getters.getMerchantUuid;

  let response: any = {};
  let responseStatus: number = 0;

  const payload: any = {};

  payload.merchant = merchantUuid;

  if (email) {
    payload.email = toLower(email);
  }

  if (phone) {
    payload.phone = phone;
  }

  if (offerCode) {
    payload.offer_code = offerCode;
  }

  try {
    response = await instance.post(`/api/v1/merchant/${merchantUuid}/invitation/`, payload);
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }
  }

  return responseStatus;
}

export async function dispatchGetInvitations(
  context: Context,
  payload: { pageNumber: number, searchString: string },
) {
  context.commit('Ui/setErrors', {}, { root: true });

  const merchantUuid = context.getters.getMerchantUuid;
  const path = `/api/v1/merchant/${merchantUuid}/invitation/`;
  const params = { page: payload.pageNumber, search: payload.searchString };

  try {
    const { data } = await instance.get(path, { params });
    context.commit('setNewLoanInvitations', data);
  } catch (e: unknown) {
    SetAxiosErrorToState(e, context);
  }
}

export async function dispatchGetAvailableTransactions(
  context: Context,
  payload: { pageNumber: number, searchString: string },
) {
  context.commit('Ui/setErrors', {}, { root: true });

  const merchantUuid = context.getters.getMerchantUuid;
  const path = `/api/v1/merchant/${merchantUuid}/available_transactions/`;
  const params = { page: payload.pageNumber, search: payload.searchString };

  try {
    const { data } = await instance.get(path, { params });
    context.commit('setAvailableTransactions', data);
  } catch (e: unknown) {
    SetAxiosErrorToState(e, context);
  }
}

export async function dispatchGetAvailableTransactionsSort(context: Context, payload: any) {
  let header: string = payload.headerSort;
  const order: boolean = payload.isDescending;

  const HEADER_MAP: Record<string, string> = {
    'user.full_name': 'customer_name',
    amount_spent: 'amount_spent_new',
    available_spend: 'available_spend_new',
    purchase_window_end_date: 'purchase_window_end_date_new',
    human_readable_id: 'application__human_readable_id',
  };

  if (HEADER_MAP[header]) header = HEADER_MAP[header];

  const merchantUuid = context.getters.getMerchantUuid;

  try {
    const params = { page: payload.pageNumber, ordering: order ? header : `-${header}` };
    const { data } = await instance.get(`/api/v1/merchant/${merchantUuid}/available_transactions/`, { params });
    context.commit('setAvailableTransactions', data);
  } catch (e: unknown) {
    SetAxiosErrorToState(e, context);
  }
}

export async function dispatchPaymentRequest(
  context: Context,
  paymentRequestData: PaymentRequestData,
) {
  let response: any = {};
  let responseStatus: number = 0;

  const data: any = { ...paymentRequestData };
  const cAppUuid = data.consumer_application_uuid;

  const payload: any = {
    amount: data.amount,
    phone: data.phone,
    transaction_type: data.transaction_type,
  };

  try {
    response = await instance.post(`/api/v1/consumer/${cAppUuid}/payment/`, payload);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;
      if (responseStatus === 406) {
        const supportEmail = context.rootGetters['Ui/getFooterData'].support_email;
        const responseAdapter = {
          status: responseStatus,
          data: {
            detail: `Your Payment Request was unable to be sent at this time.
             Please try again later or contact ${supportEmail} for additional questions`,
          },
        };
        context.commit('Ui/setErrors', responseAdapter, { root: true });
      } else {
        context.commit('Ui/setErrors', axiosError.response, { root: true });
      }
    }
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }
  }

  return responseStatus;
}

export async function dispatchFetchRecentTransactions(context: Context, payload: any) {
  context.commit('Ui/setErrors', {}, { root: true });
  const merchantUuid = context.getters.getMerchantUuid;
  const path = `/api/v1/merchant/${merchantUuid}/all-transactions/`;
  const params = {
    page: payload.pageNumber,
    page_size: payload.pageSize,
    search: payload.searchString,
  };

  try {
    const { data } = await instance.get(path, { params });
    if (payload.action === 'recent-activity') {
      context.commit('setRecentTransactions', data);
    } else if (payload.action === 'all-transactions') {
      context.commit('setPaymentRequests', data);
      context.commit('setRequestsTransactions', { paymentRequests: data });
    }
  } catch (e) {
    SetAxiosErrorToState(e, context);
  }
}

export async function dispatchFetchRecentTransactionsSort(context: Context, payload: any) {
  let header: string = payload.sortHeader;
  const order: boolean = payload.isDesc;

  const HEADER_MAP: Record<string, string> = {
    consumer_first_name: 'consumer_first_name',
    consumer_last_name: 'consumer_last_name',
    request_date: 'created_date',
    requested_by: 'created_by_first_name',
  };

  if (HEADER_MAP[header]) header = HEADER_MAP[header];

  context.commit('Ui/setErrors', {}, { root: true });
  const merchantUuid = context.getters.getMerchantUuid;

  try {
    const params = { page: payload.pageNumber, page_size: payload.pageSize, ordering: order ? header : `-${header}` };
    const { data } = await instance.get(`/api/v1/merchant/${merchantUuid}/all-transactions/`, { params });
    if (payload.action === 'recent-activity') {
      context.commit('setRecentTransactions', data);
    } else if (payload.action === 'all-transactions') {
      context.commit('setPaymentRequests', data);
      context.commit('setRequestsTransactions', { paymentRequests: data });
    }
  } catch (e) {
    SetAxiosErrorToState(e, context);
  }
}

export async function dispatchFetchRateSheet(context: Context) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('setRateSheets', []);

  const merchantUuid = context.getters.getMerchantUuid;

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.get(`/api/v1/merchant/${merchantUuid}/rates/`);
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 200) {
      const rates = isArray(response.data) ? response.data : [];

      rates.forEach((element: RateSheetData) => {
        element.has_ripple = false;
      });

      const hasProducts = rates.some((it: any) => it.is_selected);

      if (!hasProducts) {
        const filledRates = rates.map((obj: any) => {
          if (obj.is_recommended) {
            return {
              ...obj,
              is_selected: true,
            };
          }
          return { ...obj };
        });
        context.commit('setRateSheets', filledRates);
        context.commit('enableRateSheetSave', true);
      } else {
        context.commit('setRateSheets', rates);
        context.commit('enableRateSheetSave', false);
      }
    }
  }

  return responseStatus;
}

export async function dispatchSelectRates(
  context: Context,
  selectedRates: RateSheetsPostDataInterface,
) {
  context.commit('Ui/setErrors', {}, { root: true });

  const merchantUuid = context.getters.getMerchantUuid;

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.post(
      `/api/v1/merchant/${merchantUuid}/select-rates/`,
      selectedRates,
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 201) {
      const ratesResponse = isArray(get(response.data, 'rate_sheet_merchant_product_price_ids'))
        ? response.data.rate_sheet_merchant_product_price_ids
        : [];

      const originalRates: Array<RateSheetData> = context.getters.getRateSheets;

      const allRates = map(originalRates, (rateData: RateSheetData) => {
        const responseRate = find(ratesResponse, [
          'rate_sheet_merchant_product_price_id',
          rateData.rate_sheet_merchant_product_price_id]);

        const is_selected = get(
          responseRate,
          'is_selected',
          rateData.is_selected,
        );

        return {
          ...rateData,
          is_selected,
        };
      });

      context.commit('setRateSheets', allRates);
      context.commit('enableRateSheetSave', false);
    }
  }

  return responseStatus;
}

export async function dispatchFetchTransactionsTypes(context: Context) {
  context.commit('Ui/setErrors', {}, { root: true });

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.get('/api/v1/transactions/type/?code_from=109&code_to=114');
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 200) {
      const {
        data,
      }: {
        data: Array<TransactionsDescriptionsInterface>
      } = response;

      context.commit('setTransactionsDescriptions', data);
    }
  }

  return responseStatus;
}

export async function dispatchFetchMerchantUsers(context: Context, options?: RequestOptions) {
  context.commit('Ui/setErrors', {}, { root: true });

  const merchantUuid = context.getters.getMerchantUuid;

  try {
    const response = await instance.get(`/api/v1/merchant/${merchantUuid}/user/`, {
      params: {
        ...options?.params,
        ...options?.filters,
      },
    });
    context.commit('setMerchantUsers', response.data.results);
    return response;
  } catch (e) {
    const error = e as AxiosError;
    setStatusAndErrorStateFromAxios(error.response!.status, e, context);
    throw error;
  }
}

export async function dispatchCreateNewMerchantUser(
  context: Context,
  payload: CreateNewMerchantUserPayloadInterface,
) {
  let responseStatus: number = 0;

  const {
    first_name,
    last_name,
    email,
    phone_number,
    user_type,
  } = payload;

  const dispatchAccountUserListPayload: UserListPayloadInterface = {
    first_name,
    last_name,
    email,
    user_type,
  };

  const dispatchAccountUserListResponse: number = await context.dispatch(
    'dispatchAccountUserList',
    dispatchAccountUserListPayload,
  );

  if (dispatchAccountUserListResponse === 201) {
    const user: number = context.getters.getNewMerchantUser.id;

    const dispatchContactDataPhoneNumberPayload: ContactDataPhoneNumberPayloadInterface = {
      phone_number,
      user,
    };

    const dispatchContactDataPhoneNumberResponse: number = await context.dispatch(
      'dispatchContactDataPhoneNumber',
      dispatchContactDataPhoneNumberPayload,
    );

    if (dispatchContactDataPhoneNumberResponse === 201) {
      const dispatchNewMerchantUserResponsePayload: NewMerchantUserPayloadInterface = {
        user,
        user_type,
      };

      responseStatus = await context.dispatch(
        'dispatchNewMerchantUser',
        dispatchNewMerchantUserResponsePayload,
      );
    }
  }

  return responseStatus;
}

export async function dispatchAccountUserList(context: Context, payload: UserListPayloadInterface) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('setNewMerchantUserData', {});

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.post('/api/v1/account/user-list/', payload);
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 201) {
      const { data }: { data: NewMerchantUserInterface } = response;
      context.commit('setNewMerchantUser', data);
    }
  }

  return responseStatus;
}

export async function dispatchContactDataPhoneNumber(
  context: Context,
  payload: ContactDataPhoneNumberPayloadInterface,
) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('setNewMerchantUserPhoneNumber', {});

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.post(
      '/api/v1/contact-data/phonenumber/',
      payload,
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 201) {
      const {
        data,
      }: {
        data: PhoneNumberSetInterface
      } = response;

      context.commit('setNewMerchantUserPhoneNumber', data);
    }
  }

  return responseStatus;
}

export async function dispatchNewMerchantUser(
  context: Context,
  payload: NewMerchantUserPayloadInterface,
) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('setNewMerchantUserPhoneNumber', {});

  const merchantUuid = context.getters.getMerchantUuid;

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.post(`/api/v1/merchant/${merchantUuid}/user/`, payload);
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 201) {
      const {
        data,
      }: {
        data: PhoneNumberSetInterface
      } = response;

      context.commit('setNewMerchantUserPhoneNumber', data);
    }
  }

  return responseStatus;
}

export async function dispatchUpdateMerchantUser(
  context: Context,
  payload: UpdateMerchantUserPayloadInterface,
) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('setNewMerchantUserPhoneNumber', {});

  const { userId, data } = payload;

  let response: any = {};
  let responseStatus: number = 0;

  const requestData: any = {
    ...data,
  };

  try {
    response = await instance.patch(
      `/api/v1/account/user-detail/${userId}/`,
      requestData,
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }
  }

  return responseStatus;
}

export async function dispatchPatchContactDataPhoneNumber(
  context: Context,
  payload: PatchContactDataPhoneNumberPayloadInterface,
) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('setNewMerchantUserPhoneNumber', {});

  const { phoneId, phone_number } = payload;

  let response: any = {};
  let responseStatus: number = 0;

  const requestData: any = {
    phone_number,
  };

  try {
    response = await instance.patch(
      `/api/v1/contact-data/phonenumber/${phoneId}/`,
      requestData,
    );
  } catch (e) {
    setStatusAndErrorStateFromAxios(responseStatus, e, context);
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }
  }

  return responseStatus;
}

export async function dispatchEditMerchantUser(
  context: Context,
  payload: EditMerchantUserPayloadInterface,
) {
  let responseStatus: number = 0;
  let updateUserData: any = {};

  const merchantUid = context.getters.getMerchantUuid;

  const {
    userId,
    phoneId,
    merchantUserId,
    first_name,
    last_name,
    email,
    phone_number,
    user_type,
  } = payload;

  const dispatchAccountUserDetailPayload: AccountUserDetailPayloadInterface = {
    userId,
    data: {
      first_name,
      last_name,
      email,
    },
  };

  const dispatchAccountUserDetailResponse: number = await context.dispatch(
    'dispatchUpdateMerchantUser',
    dispatchAccountUserDetailPayload,
  );

  if (dispatchAccountUserDetailResponse === 200) {
    const dispatchUpdateMerchantUserPayload: UpdateMerchantUserPayloadInterface = {
      userId,
      data: {
        user_type,
      },
    };

    // PATCH user with rest of the data
    try {
      updateUserData = await instance.patch(`/api/v1/merchant/${merchantUid}/user/${merchantUserId}/`, {
        user_type,
      });
    } catch (e: unknown) {
      const axiosError = getAxiosError(e);
      if (axiosError) {
        responseStatus = axiosError.response!.status;
      }
    } finally {
      if (updateUserData.status) {
        responseStatus = updateUserData.status;
      }
    }

    const dispatchUpdateMerchantUserResponse: number = user_type ? await context.dispatch(
      'dispatchUpdateMerchantUser',
      dispatchUpdateMerchantUserPayload,
    ) : 200;

    if (dispatchUpdateMerchantUserResponse === 200) {
      const dispatchPatchContactDataPhoneNumberPayload:
        PatchContactDataPhoneNumberPayloadInterface = {
          phone_number,
          phoneId,
        };

      responseStatus = await context.dispatch(
        'dispatchPatchContactDataPhoneNumber',
        dispatchPatchContactDataPhoneNumberPayload,
      );
    }
  }

  return responseStatus;
}

export async function fetchStatementSummary(context: Context) {
  const merchantUuid = context.getters.getMerchantUuid;

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.get(`/api/v1/merchant/${merchantUuid}/statement/`);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;
    }
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 200) {
      context.commit('setStatementSummary', response.data);
    }
  }

  return responseStatus;
}

export async function fetchStatementDetails(context: Context, id: string) {
  const merchantUuid = context.getters.getMerchantUuid;
  const statementDetails = context.rootGetters['MerchantPortal/getStatementDetails'];
  // This is a fairly costly server action so if it is already downloaded and in memory
  // we want to skip re-downloading it because the data will be the same.
  if (statementDetails?.statement_summary.id === Number(id)) return 200;

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await instance.get(`/api/v1/merchant/${merchantUuid}/statement/${id}/`);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;
    }
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 200) {
      context.commit('setStatementDetails', response.data);
    }
  }

  return responseStatus;
}

export async function dispatchGetMerchantData(
  context: ActionContext<MerchantOnboardingStateInterface, any>,
) {
  if (context.rootGetters['Auth/isLoggingOut']) return 0;

  let responseStatus: number = 0;
  let response: any = {};

  const merchantUuid = context.getters.getMerchantUuid;
  if (!merchantUuid) return responseStatus;

  try {
    response = await instance.get(`/api/v1/merchant/${merchantUuid}/data/`);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;
    }
  } finally {
    if (response.status) {
      responseStatus = response.status;
      const { data } = response;

      context.commit('setMerchantData', data);
      // Update data
      // Set User
      context.commit('Auth/setUser', data.user, { root: true });
      // Set group (roles)
      context.commit('Auth/setGroup', data.user_type, { root: true });
      localStorage.setItem('group', JSON.stringify([data.user_type]));
      // Set Merchant
      context.commit('Auth/setMerchant', data.merchant, { root: true });

      if (data.location) {
        context.commit('setMerchantPrimaryLocation', data.primary_location);
        context.commit('setMerchantLocation', data.location);
        context.dispatch('populateMerchantLocations');
      }

      if (data.is_ic1_sponsored) {
        context.commit('setSponsorData', {
          merchantPortalAccess: data.merchant_portal_access,
          isIc1Sponsored: data.is_ic1_sponsored,
        });
      }

      context.commit('setMerchantApplicationUuid', data.merchant_application_uuid);

      context.commit('setRecentlyApprovedConsumerAppId', data.recent_approved_consumer_application);

      context.commit('setShowProductAndPricingDialogJuly', data.show_july_2023_pricing_modal);

      context.commit('setShowProductAndPricingDialogSeptember', data.show_september_2023_pricing_modal);

      context.commit('setShowHealthCareDialogOctober', data.show_october_2023_pricing_modal);

      context.commit('setShowHomeImprovementDialog', data.show_home_improvement_momntplus_2023_modal);

      context.commit('setAprCapComplianceDialog', data.show_apr_cap_compliance_modal);
    }
  }
  return responseStatus;
}

export async function getPaymentProviders(context: Context) {
  let responseStatus = 0;
  let response: any = {};

  const merchantUuid = context.getters.getMerchantUuid;

  try {
    response = await instance.get(`/api/v1/merchant/${merchantUuid}/payment-network-status/`);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;
    }
  } finally {
    if (response.status) {
      responseStatus = response.status;
      context.commit('setPaymentProviders', response.data);
    }
  }

  return responseStatus;
}

export async function dispatchGetRefundTransactions(context: Context, consumerUuid: string) {
  let responseStatus: number = 0;
  let response: any = {};

  const merchantUuid = context.getters.getMerchantUuid;
  context.commit('setRefundableTransactions', []);

  try {
    response = await instance.get(`/api/v1/merchant/${merchantUuid}/refundable-transactions/${consumerUuid}`);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;
    }
  } finally {
    if (response.status) {
      responseStatus = response.status;

      if (responseStatus === 200) {
        context.commit('setRefundableTransactions', response.data);
      }
    }
  }
  return responseStatus;
}

export async function checkTransactionSettled(context: Context, payment_identifier: string) {
  let response: any = {};

  const merchantUuid = context.getters.getMerchantUuid;

  try {
    response = await instance.post(`/api/v1/merchant/${merchantUuid}/consumer-settled-update/`, { payment_identifier });
  } catch (err: unknown) {
    return response;
  }
  return response;
}

export async function dispatchRefund(context: Context, payload: any) {
  let responseStatus: number = 0;
  let response: any = {};

  const merchantUuid = context.getters.getMerchantUuid;

  try {
    response = await instance.post(`/api/v1/merchant/${merchantUuid}/refund/`, payload);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;
    }
  } finally {
    const status = response.status || response.response?.status;
    if (status) responseStatus = status;
  }
  return responseStatus;
}

export async function setRSExpandableCategory(context: Context, payload: number) {
  context.commit('setRateSheetExpandableCategory', payload);
}

export async function setRateSheetsRipple(context: Context, payload: number) {
  context.commit('setRateSheets', payload);
}

export async function showResetPwSuccessMsg(context: Context, payload: boolean) {
  context.commit('showResetPwMsg', payload);
}

export async function storeTokenPayment(
  context: ActionContext<MerchantDetailsInterface, any>,
  payload: any,
) {
  const merchantUuid = context.getters.getMerchantUuid;

  try {
    return await postTokenPayment(merchantUuid, payload);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
      return axiosError.response;
    }
  }
  return null;
}

export async function setShowOfProductAndPricingDialogJuly(context: Context, payload: boolean) {
  context.commit('setShowProductAndPricingDialogJuly', payload);
}

export async function setShowOfProductAndPricingDialogSeptember(
  context: Context,
  payload: boolean,
) {
  context.commit('setShowProductAndPricingDialogSeptember', payload);
}

export async function setShowOfHealthCareDialogOctober(context: Context, payload: boolean) {
  context.commit('setShowHealthCareDialogOctober', payload);
}

export async function setShowOfHomeImprovementDialog(context: Context, payload: boolean) {
  context.commit('setShowHomeImprovementDialog', payload);
}

export async function setAprCapComplianceDialog(context: Context, payload: boolean) {
  context.commit('setAprCapComplianceDialog', payload);
}

export async function checkRoutingNumber(context: Context, payload: any) {
  let responseStatus: number = 0;
  let response: any = {};

  try {
    response = await routingNumber(payload);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;
    }
  } finally {
    const status = response.status || response.response?.status;

    if (status) responseStatus = status;

    context.commit('setBankName', response.data?.bank_account_name);
  }
  return responseStatus;
}

export async function showSuccessAlert(context: Context, payload: any) {
  context.commit('setAlertData', payload);
}

export async function resetRoutingNumber(context: Context) {
  context.commit('setBankName', '');
}

export async function setMerchantLocation(context: Context, payload: MerchantLocation) {
  context.commit('setMerchantLocation', payload);
}

export async function populateMerchantLocations(context: Context) {
  const { data } = await getChildLocations();
  context.commit('setMerchantLocations', data);
  return data;
}

export async function updatePrimaryMerchantLocation(context: Context, newLoc: MerchantLocation) {
  const { status } = await updateMerchantPrimaryLocation(newLoc.merchant_uuid!);
  if (status === 200) {
    context.commit('setMerchantPrimaryLocation', newLoc);
  }
  return status;
}
