import { computed, ref, watch } from 'vue';

interface Filter { label: string; value: string }

export const useFilter = (filters: Record<string, unknown>, key: string) => {
  const all = ref<Filter[]>([]);
  const selected = ref<string[]>([]);
  const selectedNames = computed(() => {
    return selected.value.map(item => all.value.find(it => it.value === item)?.label);
  });
  const onUpdate = (values: string[]) => {
    selected.value = values;
  };

  const removeSelected = (itemLabel?: string) => {
    const item = all.value.find(it => it.label === itemLabel);
    if (!item) return;
    selected.value = selected.value.filter(it => it !== item.value);
  };

  watch(selected, val => {
    filters[key] = val.length ? val.join(',') : undefined;
  });

  return {
    all, selected, selectedNames, onUpdate, removeSelected,
  };
};
