import DATE_CONSTANTS from '@/constants/DateConstants';
import { dateToFormat, dateToFormatIso } from '@/helpers/DateTimeHelper';
import moment from 'moment';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    dateFormat(): string {
      return DATE_CONSTANTS.dateFormat;
    },
    dateFormatIso(): string {
      return DATE_CONSTANTS.dateFormatIso;
    },
    dateToday() {
      return moment().format(this.dateFormat);
    },
  },
  methods: {
    dateToFormat(date: string): string {
      return dateToFormat(date);
    },
    dateToFormatIso(date: string): string {
      return dateToFormatIso(date);
    },
  },
});
