<template>
  <div class="merchant__begin-onboarding">
    <v-container class="merchant__begin-onboarding__container">
      <merchant-intro />
      <main-footer :enable-human-readable-id="false" />
    </v-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainFooter from '@/components/MainFooter.vue';
import MerchantIntro from '@/components/Merchant/Intro.vue';

export default defineComponent({
  name: 'BeginOnboarding',
  components: {
    MainFooter,
    MerchantIntro,
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/media_queries";

.merchant {
  &__begin-onboarding {
    background-color: var(--grayscale-color-5);

    &__container {
      padding-top: 0;
      padding-bottom: 0;
    }

    @include media-breakpoint-down-lg {
      .merchant__begin-onboarding__container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.main-footer {
  margin-top: 0px !important;
  color: var(--grayscale-color-1);
  background: var(--grayscale-color-5);
}
</style>
