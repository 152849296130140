import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_custom_combobox = _resolveComponent("custom-combobox")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createBlock(_component_custom_combobox, {
    modelValue: _ctx.search,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      _ctx.debounceOnModelUpdate
    ],
    class: "portal-header__search",
    dense: "",
    "in-place": "",
    solo: "",
    rounded: "",
    "bg-color": "#fff",
    height: "50px",
    "hide-no-data": "",
    "hide-dropdown-icon": "",
    items: _ctx.searchResults,
    "item-title": "text",
    "item-value": "consumer_application",
    placeholder: "Search by Customer Name",
    "hide-details": "",
    "menu-icon": "",
    "prepend-inner-icon": "mdi-magnify",
    "auto-select-first": _ctx.autoSelectFirst,
    loading: _ctx.isLoading,
    "menu-props": { attach: false }
  }, {
    item: _withCtx(({ item, props }) => [
      _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(_ctx.itemProps(props))), {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createBlock(_component_v_list_item_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1)
            ]),
            _: 2
          }, 1024)), [
            [_directive_private, 'Searched Consumer']
          ])
        ]),
        _: 2
      }, 1040)
    ]),
    _: 1
  }, 8, ["modelValue", "items", "auto-select-first", "loading", "onUpdate:modelValue"]))
}