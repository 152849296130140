<template>
  <div>
    <custom-alert v-if="error" type="error">
      {{ error }}
    </custom-alert>
    <password-input
      v-model="password"
      label="Create Password"
      :password-verify="passwordVerify"
      :focused-by-default="false"
      show-requirements-tooltip />
    <password-input
      v-model="passwordVerify"
      :rules="[passwordValidation]"
      label="Verify Password" />
    <p data-test="createAccountClickLabel">
      By clicking “Create Account” you accept the terms of the
      <a
        :href="MOOV_AGREEMENT_URL"
        target="_blank"
        rel="noopener noreferrer">
        User Terms
      </a>.
    </p>
    <custom-button
      :disabled="!isFormValid"
      full-width
      type="button"
      @click="onSubmit">
      Create Account
    </custom-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PasswordInput from '@/components/Inputs/Password.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { createCPAccount } from '@/api/consumer';
import getTopLevelDomain from '@/helpers/GetTopLevelDomain';
import validatePassword from '@/validators/password';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import { getAxiosError } from '@/api/utils';

export default defineComponent({
  name: 'CreateAccount',
  components: { CustomAlert, CustomButton, PasswordInput },
  data() {
    return {
      password: '',
      passwordVerify: '',
      isValid: false,
      error: '',
      MOOV_AGREEMENT_URL: 'https://moov.io/legal/platform-agreement/',
    };
  },
  computed: {
    isFormValid(): boolean {
      return this.passwordValidation(this.password) === true
        && this.password === this.passwordVerify;
    },
    appId(): string {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },
  },
  methods: {
    async onSubmit() {
      try {
        const { data } = await createCPAccount(this.appId, this.password);
        this.setCookie(data.token);
        this.$emit('created');
      } catch (e: unknown) {
        const axiosError = getAxiosError(e);
        const defaultError = 'There was an error creating your account.';

        if (axiosError) {
          const errorData = axiosError.response?.data || {};

          if (errorData.non_field_errors) {
            this.error = errorData.non_field_errors[0];
          } else if (errorData.password) {
            this.error = errorData.password[0];
          } else {
            this.error = defaultError;
          }
        } else {
          this.error = defaultError;
        }
      }
    },
    passwordValidation(value: string): string | boolean {
      if (!validatePassword(value)) return 'Please correct password format';

      if (this.passwordVerify && this.passwordVerify !== value) return 'Passwords do not match';

      return true;
    },
    setCookie(jwt: string) {
      const domain = getTopLevelDomain(window.location.hostname);
      document.cookie = `jwt=${jwt}; domain=${domain}; max-age=3600; path=/; samesite=strict`;
    },
  },
});
</script>

<style lang="scss" scoped>
p {
  padding: 0.5rem 0;
  font-size: 0.75rem;
}
</style>
