enum LoanApplicationStatusesEnum {
  DECLINED = 'Declined',
  CANCEL = 'Cancel',
  CANCELED = 'Canceled',
  PEND = 'Pend", "Pending',
  APPROVE = 'Approve',
  APPROVED = 'Approved',
  APPROVED_ACCEPTED = 'Approved: Accepted',
  APPROVED_FOR_LESS = 'Approved for Less',
  INCOMPLETE = 'Incomplete',
  INTAKE = 'Intake',
}

export default LoanApplicationStatusesEnum;
