<template>
  <div>
    <standard-progress-indicator v-if="showSpinner" type="circular" />

    <verification-card
      v-if="showVerificationElements"
      :is-disabled="continueBtnDisabled"
      @initiateVerification="startVerification"
    />

    <start-verification-desktop
      v-if="showDesktopVerification"
      :qr-code-image="qrCodeImage"
    />

    <mobile-id-verification-wait v-if="showMobileWait" />

    <complete-later-button v-if="showVerificationElements" />
  </div>
</template>

<script lang="ts">
import VerificationCard from '@/components/Consumer/LoanApplyWizard/IDVerification/IDVerificationCard.vue';
import StartVerificationDesktop from '@/components/Consumer/LoanApplyWizard/IDVerification/StartVerificationDesktop.vue';
import StandardProgressIndicator from '@/components/ProgressIndicator.vue';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import { defineComponent } from 'vue';
import { startDocumentVerification, skipDocumentVerification } from '@/api/consumer';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';
import MobileIdVerificationWait from './IDVerification/MobileIDVerificationWait.vue';

export default defineComponent({
  name: 'StepEight',

  components: {
    VerificationCard,
    StartVerificationDesktop,
    StandardProgressIndicator,
    MobileIdVerificationWait,
    CompleteLaterButton,
  },

  mixins: [NavigatesStepsMixin],

  data() {
    return {
      ownStep: 8,
      hpStep: 8,
      hideCard: false,
      showDesktopVerification: false,
      showMobileWait: false,
      qrCodeImage: '',
      continueBtnDisabled: false,
      showSpinner: false,
    };
  },

  computed: {
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },

    applicationId(): string {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },

    showVerificationElements(): boolean {
      return !this.hideCard && !this.showSpinner;
    },
  },

  async created() {
    this.showSpinner = true;
    const docvSkippedResponse = await skipDocumentVerification(this.applicationId);
    if (docvSkippedResponse.data.skip) {
      this.goToStep(this.ownStep + 1);
    } else {
      this.showSpinner = false;
    }
  },

  methods: {
    async startVerification() {
      this.continueBtnDisabled = true;

      const payload = !this.isMobile ? { redirect_type: 'continue_on_desktop' } : {};

      try {
        const res = await startDocumentVerification(this.applicationId, payload);

        if (this.isMobile) {
          this.showMobileWait = true;
          this.hideCard = true;
          window.location.href = res.data.document_verification_url;
        } else {
          this.hideCard = true;
          this.qrCodeImage = res.data.qr_code;
          this.showDesktopVerification = true;
        }
      } catch (err: any) {
        this.$store.dispatch('Ui/addGlobalAlert', {
          text: 'Something went wrong',
          type: GlobalAlertTypes.ERROR,
          timed: true,
        });
        this.continueBtnDisabled = false;
      }
    },
  },
});
</script>
