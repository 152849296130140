<template>
  <v-select
    ref="select"
    v-bind="$attrs"
    :data-test="mainDataTest"
    :disabled="disabled"
    autocomplete="off"
    :menu-icon="appendIcon"
    :variant="variantAttr"
    :menu-props="menuProps"
    :label="label"
    :model-value="hasValue ? modelValue : undefined"
    :item-value="itemValue"
    @click:clear="onClear">
    <template #selection="{ item }">
      <span
        v-private
        class="v-select__selection-text"
        :data-test="addDataTest ? addDataTest : null">
        {{ item.props.title ?? '' }}
      </span>
    </template>

    <template #item="{ props }">
      <v-list-item v-private="`Select item in ${label}`" v-bind="props" />
    </template>
  </v-select>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SelectInput',

  props: {
    modelValue: { type: [Number, String, Object], default: null },
    itemValue: { type: String, default: null },
    dataTest: { type: String, default: 'selectField' },
    menuProps: { type: Object, default: () => ({ bottom: true, offsetY: true }) },
    hideDropdownIcon: { type: Boolean, default: false },
    inPlace: { type: Boolean, default: false },
    blurOnClear: { type: Boolean, default: false },
    label: { type: String, default: '' },
    isMob: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  computed: {
    variantAttr() {
      if (this.inPlace) {
        return this.disabled ? 'plain' : 'underlined';
      }
      return 'outlined';
    },
    hasValue() {
      if (this.modelValue && typeof this.modelValue === 'object' && this.itemValue) {
        return this.itemValue in this.modelValue;
      }
      return !(this.modelValue === null || this.modelValue === undefined);
    },

    appendIcon(): string {
      return this.hideDropdownIcon ? '' : '$dropdown';
    },

    addDataTest(): string | undefined {
      return (['programSelect', 'subSelect', 'businessInput'].includes(this.dataTest) && this.isMob) ? this.dataTest
        : undefined;
    },

    mainDataTest(): string | undefined {
      return this.addDataTest ? undefined : this.dataTest;
    },
  },

  methods: {
    onClear() {
      this.$emit('click:clear');
      if (this.blurOnClear) (this.$refs.select as any).blur();
    },
  },
});
</script>

<style lang="scss" scoped>
.v-select--selected :deep() .v-field .v-field__input > input {
  opacity: 0;
}

.v-select__content .v-list {
  text-align: left;

  :deep(.v-list-item.primary--text) {
    color: var(--grayscale-color-1) !important;
    caret-color: var(--grayscale-color-1) !important;
  }
}
</style>
