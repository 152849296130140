<template>
  <v-form class="page page--login portal" @submit.prevent>
    <v-container class="portal__header">
      <v-row justify="center">
        <v-col
          lg="5"
          sm="6"
          class="pa-0 text-center">
          <img
            class="standard-logo"
            :src="logoUrl"
            alt="Logo"
            data-test="momntLogoImg">
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-0">
        <v-col
          lg="5"
          sm="6"
          class="pa-0">
          <h4 class="mb-0 mt-0" data-test="mpLoginHeader">
            Merchant Portal
          </h4>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="portal__container">
      <div v-if="showPwSavedMessage" class="portal__container__saved-pw-div">
        <p>Your new password has been saved.</p>
      </div>
      <transition mode="out-in" name="fade">
        <v-row v-if="accountLocked" justify="center">
          <v-col lg="3" sm="5">
            <merchant-login-locked-account />
          </v-col>
        </v-row>

        <div v-else>
          <v-row v-if="!!combinedErrors.length" justify="center">
            <v-col lg="5" sm="6">
              <standard-error-list :custom-errors="customErrorMessage" />
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col lg="5" sm="6">
              <email-input
                v-model="email"
                :is-fs-excluded="true"
                autofocus
                show-icon />
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col lg="5" sm="6">
              <password-input v-model="password" disable-input-validation />
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col lg="5" sm="6">
              <custom-button
                :disabled="!inputCheck || btnDisabled"
                data-test="submitBtn"
                full-width
                type="submit"
                @click="login">
                Submit
              </custom-button>
            </v-col>
          </v-row>

          <merchant-login-request-reset-password-reset-modal />
        </div>
      </transition>
    </v-container>

    <merchant-portal-footer class="portal__footer" />
  </v-form>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import EmailInput from '@/components/Inputs/Email.vue';
import PasswordInput from '@/components/Inputs/Password.vue';
import { mapGetters } from 'vuex';
import MerchantLoginLockedAccount
  from '@/components/Merchant/Login/LockedAccount.vue';
import MerchantLoginRequestResetPasswordResetModal
  from '@/components/Merchant/Login/RequestResetPasswordResetModal.vue';
import MerchantPortalFooter from '@/components/Merchant/Portal/Footer.vue';
import StandardErrorList from '@/components/ErrorList.vue';
import { LoginDataInterface } from '@/interfaces/auth/LoginDataInterface';
import { ErrorsInterface } from '@/interfaces/ui/ErrorsInterface';
import UserLogOutMixin from '@/mixins/Auth/UserLogOutMixin';
import GetErrors from '@/mixins/GetErrors';
import GotoRouteName from '@/mixins/GotoRouteName';
import ValidationTypesMixin from '@/mixins/ValidationTypesMixin';
import emailAddress from '@/validators/email_address';
import { isFunction, toLower } from 'lodash';
import UserIsLoggedInMixin from '@/mixins/Auth/UserIsLoggedInMixin';
import getTopLevelDomain from '@/helpers/GetTopLevelDomain';
import LocalStorageConstants from '@/constants/LocalStorageConstants';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { honeypot } from 'honeypot-run';
import { defineComponent } from 'vue';
import { PageTypesShorthand } from '@/enums/PageTypes';
import SponsorPortalRedirectMixin from '@/mixins/SponsorPortalRedirectMixin';

export default defineComponent({
  name: 'MerchantLogin',
  components: {
    CustomButton,
    EmailInput,
    PasswordInput,
    StandardErrorList,
    MerchantLoginLockedAccount,
    MerchantPortalFooter,
    MerchantLoginRequestResetPasswordResetModal,
  },
  mixins: [
    GotoRouteName,
    GetErrors,
    ValidationTypesMixin,
    UserLogOutMixin,
    UserIsLoggedInMixin,
    HoneypotTrackMixin,
    SponsorPortalRedirectMixin,
  ],
  props: {
    onUserLogin: { type: Function, default: null },
    redirectTo: { type: String, default: '' },
  },
  data() {
    return {
      email: '',
      password: '',
      accountLocked: false,
      btnDisabled: false,
      hpEventName: 'Merchant Login Page Visit',
    };
  },
  computed: {
    ...mapGetters('Ui', {
      logoUrl: 'getBrandingHeaderLogo',
    }),
    combinedErrors(): Array<ErrorsInterface> {
      const errors: any = [];

      const { non_field_errors, detail } = this.errors;

      if (non_field_errors) {
        errors.push(...non_field_errors);
      }

      if (detail) {
        errors.push(detail);
      }

      return errors;
    },
    inputCheck(): boolean {
      return emailAddress(this.email) && this.password.length > 0;
    },
    showPwSavedMessage(): boolean {
      return this.$store.getters['MerchantPortal/getShowResetPwMsg'];
    },
    customErrorMessage(): Array<string> {
      return ['Unable to log in with provided credentials.'];
    },
    merchantUuid(): string {
      return this.$store.getters['Auth/getMerchantUuid'];
    },
  },
  async created() {
    const { hostname } = window.location;
    const domain = getTopLevelDomain(hostname);

    if (['heymanorcredit.com'].includes(domain)) {
      window.location.href = 'https://www.profund.net/';
    }

    const isFromCreateUser = localStorage.getItem('fromCreateUser');

    const emailFromCreateUser = localStorage.getItem(LocalStorageConstants.NEW_USER_EMAIL);

    if (isFromCreateUser && emailFromCreateUser) {
      this.email = emailFromCreateUser;
    }

    if (!isFromCreateUser) {
      await this.userLogout();
    }

    localStorage.setItem('fromCreateUser', 'false');

    localStorage.removeItem(LocalStorageConstants.NEW_USER_EMAIL);
  },
  methods: {
    async login(): Promise<void> {
      const postData: LoginDataInterface = {
        email: toLower(this.email),
        password: this.password,
      };

      this.btnDisabled = true;

      const { error } = await this.$store.dispatch('Auth/userLogin', postData);

      if (error) {
        if (this.shouldRedirectToSponsorPortal(error)) {
          this.redirectToSponsorPortal(error.response.data.details.redirect_url);
          return;
        }

        this.accountLocked = error.response.status === 417;
      } else {
        await this.$store.dispatch('MerchantPortal/getPaymentProviders');
        await this.$store.dispatch('MerchantPortal/dispatchGetMerchantData');
        this.$store.dispatch('MerchantPortal/showResetPwSuccessMsg', false);

        if (window.honeypot?.didInit) {
          honeypot.identify(this.merchantUuid);
        }

        if (isFunction(this.onUserLogin)) {
          this.onUserLogin();
        } else if (this.redirectTo) {
          this.$router.replace(this.redirectTo);
        } else {
          this.gotoRouteName('merchant-activity-center');
        }

        this.trackEvent(this.hpEventName, PageTypesShorthand.MP);
      }

      this.btnDisabled = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";
@import "@/assets/scss/_custom-transitions";

@include portal-page-layout;

h4 {
  text-align: center;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
}

.logo-image {
  max-width: 190px;
  height: auto;
}

.portal {
  &__header {
    padding-bottom: 0;
    margin-bottom: 5rem;
  }
  &__container {
    &__saved-pw-div {
      p {
        font-size: 1rem;
        margin-bottom: 2.75rem;
      }
    }
  }
}
</style>
