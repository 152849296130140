import { BASE_PATH, instance } from './index';

export function getConsentTypes(entity: string, appId: string) {
  return instance.get(`${BASE_PATH}/${entity}/${appId}/consent-types/`);
}

export function postConsentTypes(entity: string, appId: string, payload: any) {
  const path = `${BASE_PATH}/${entity}/${appId}/consent-types/`;
  return instance.post(path, payload);
}

export function patchConsentType(entity: string, appId: string, type: number, payload: any) {
  const path = `${BASE_PATH}/${entity}/${appId}/consent-types/${type}/`;
  return instance.patch(path, payload);
}
