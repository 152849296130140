<template>
  <standard-dialog
    dialog
    width="420"
    :on-cancel="() => $emit('close')"
    @close="$emit('close')">
    <template #body>
      <p>
        An email invite has been sent to <strong>{{ email }}</strong>.
        Once they set up a password and login, they will be able to work in your portal.
      </p>
    </template>

    <template #actions>
      <custom-button full-width @click="$emit('ok')">
        OK
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardDialog from '@/components/Dialogs/index.vue';

export default defineComponent({
  name: 'PostCreateModal',
  components: {
    CustomButton,
    StandardDialog,
  },
  props: {
    email: { type: String, required: true },
  },
});
</script>
