<template>
  <div>
    <div class="info-table">
      <v-data-table
        :headers="headers"
        :items="fetchedApplications"
        :hide-default-footer="true">
        <template v-slot:item.transaction_description="{ item }">
          <td>
            {{ item.transaction_description }}
          </td>
        </template>
        <template v-slot:item.transaction_amount="{ item }">
          <td>
            {{ formatCurrency(item.transaction_amount) }}
          </td>
        </template>
        <template v-slot:item.approved="{ item }">
          <td>
            <v-icon
              color="var(--success-color)">
              mdi-check-circle-outline
            </v-icon>
          </td>
        </template>
        <template v-slot:item.status="{ item }">
          <td>Successful</td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script lang="ts">
import GetProcessing from '@/mixins/GetProcessing';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SendTransaction',
  mixins: [GetProcessing],
  props: {
    allCompletedTransactions: { type: Array, default: () => [] },
  },
  data() {
    const headers: Array<TableHeaderInterface> = [
      {
        title: 'Charge type',
        align: 'start',
        sortable: false,
        value: 'transaction_description',
      },
      {
        title: 'Amount',
        align: 'start',
        sortable: false,
        value: 'transaction_amount',
      },
      {
        title: 'Approved',
        align: 'start',
        sortable: false,
        value: 'approved',
      },
      {
        title: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
      },
    ];
    return {
      fetchedApplications: [],
      headers,
    };
  },
  watch: {
    allCompletedTransactions: {
      handler(newVal: any) {
        this.fetchedApplications = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    formatCurrency(val: any) {
      return CurrencyFormatLong(val);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.info-table {
  margin: 1rem 0;

  :deep(.v-table .v-table__wrapper) {
    border: 1px solid var(--grayscale-color-3);
    border-radius: 8px;
  }
}

:deep(.v-table-header) {
  background-color: var(--grayscale-color-5);
}
</style>
