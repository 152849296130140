import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["merchant-name", { 'merchant-name--small': _ctx.small }])
  }, [
    (_ctx.merchantName)
      ? (_openBlock(), _createElementBlock("h6", {
          key: 0,
          class: _normalizeClass(["merchant-name__title", { 'reduced-font': _ctx.isFontReduced }]),
          "data-test": "merchantName"
        }, _toDisplayString(_ctx.name), 3))
      : (_openBlock(), _createBlock(_component_v_skeleton_loader, {
          key: 1,
          class: "mx-auto skeleton-portal",
          "max-width": "192",
          type: "text"
        }))
  ], 2))
}