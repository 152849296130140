<template>
  <div
    class="fs-unmask standard-wizard__step__alert"
    data-test="processingMsg">
    <slot>
      We are now processing your loan offers. This may take a few moments.<br>
      Please do not press back or close your browser window.
    </slot>
    <standard-progress-indicator :type="progressIndicatorType" />
  </div>
</template>

<script lang="ts">
import StandardProgressIndicator from '@/components/ProgressIndicator.vue';
import PROGRESS_INDICATOR_TYPES from '@/constants/StandardProgressIndicator';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProcessingLoanOffers',
  components: {
    StandardProgressIndicator,
  },
  computed: {
    progressIndicatorType(): string {
      return PROGRESS_INDICATOR_TYPES.circular;
    },
  },
});
</script>

<style lang="scss" scoped>
.standard-wizard__step__alert {
  border: none;
  box-shadow: none;

  .card-body {
    color: var(--grayscale-color-1) !important;
  }
}
</style>
