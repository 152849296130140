<template>
  <v-form @submit.prevent="goToNextSubStep">
    <v-card-text class="fs-unmask text-left">
      <span
        v-if="!inPlaceInput"
        class="title-text"
        data-test="pleaseCheckPaymentLabel">
        Please check the payment types you accept:
      </span>
      <div class="card-text__info-group">
        <div
          v-if="inPlaceInput"
          class="card-text__info-group__title payment-types-title"
          :class="fromBusinessDetails ? 'payment-types-title-two' : ''"
          data-test="paymentText">
          <span :class="{ 'error-text': hasError }">Payment types you accept:</span>
        </div>

        <merchant-onboarding-wizard-payment-types
          v-model="financingOptions.paymentTypesAccepted"
          :from-business-details="fromBusinessDetails"
          :disabled="buttonDisabled"
          @error="setErrorColor" />
      </div>

      <div class="card-text__info-group financing-options">
        <div
          class="card-text__info-group__title financing-options__label"
          data-test="offerFinancingLabel">
          <span>Do You Offer Financing?</span>
        </div>

        <custom-button
          :disabled="buttonDisabled"
          :variant="yesButtonVariant"
          size="small"
          data-test="yesBtn"
          class="financing-btn"
          @click="setFinancing(true)">
          Yes
        </custom-button>

        <custom-button
          :disabled="buttonDisabled"
          :variant="noButtonVariant"
          size="small"
          data-test="noBtn"
          class="financing-btn"
          @click="setFinancing(false)">
          No
        </custom-button>
      </div>

      <div class="card-text__info-group mb-0">
        <currency-input
          v-if="financingOptions.offersFinancing"
          v-model="financingOptions.financingVolume"
          data-test="financingInput"
          :disabled="disabledInputs"
          :error-messages="errors.financing_volume"
          :in-place="inPlaceInput"
          label="Yearly Financing Volume"
          required
          error-capitalized
          :show-icon="disabledInputs"
          :max="maxValue" />
      </div>

      <custom-button
        v-if="showNextButton"
        :disabled="nextSubstepDisabled"
        class="next-btn"
        full-width
        type="submit">
        Next
      </custom-button>
    </v-card-text>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CurrencyInput from '@/components/Inputs/Currency.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import MerchantOnboardingWizardPaymentTypes from '@/components/Merchant/OnboardingWizard/PaymentTypes.vue';
import GetProcessing from '@/mixins/GetProcessing';
import { get } from 'lodash';
import GetErrors from '@/mixins/GetErrors';
import { YEARLY_FINANCING_VOLUME_MAX } from '@/constants/MerchantOnboarding';

export default defineComponent({
  name: 'PaymentsTypesFinancingOptions',

  components: {
    CurrencyInput,
    CustomButton,
    MerchantOnboardingWizardPaymentTypes,
  },

  mixins: [GetProcessing, GetErrors],

  props: {
    onNextSubStep: { type: Function, default: () => () => null },
    showNextButton: { type: Boolean, default: true },
    disabledInputs: { type: Boolean, default: false },
    inPlaceInput: { type: Boolean, default: false },
    fromBusinessDetails: { type: Boolean, default: false },
  },

  data: () => ({
    financingOptions: {
      paymentTypesAccepted: [],
      offersFinancing: null,
      financingVolume: null,
    } as any,
    hasError: false,
    maxValue: YEARLY_FINANCING_VOLUME_MAX,
  }),

  computed: {
    currentStep() {
      return this.$route.query.subStep;
    },

    buttonDisabled(): boolean {
      return this.$props.disabledInputs || this.processing;
    },

    nextSubstepDisabled(): boolean {
      const { offersFinancing, financingVolume, paymentTypesAccepted } = this.financingOptions;

      return (offersFinancing === null || !paymentTypesAccepted.length)
        || (offersFinancing && ['', 0].includes(financingVolume))
        || (offersFinancing && Number(financingVolume) > this.maxValue)
        || this.buttonDisabled;
    },

    yesButtonVariant(): string {
      const { offersFinancing } = this.financingOptions;
      if (offersFinancing === null) return 'secondary';
      return offersFinancing ? 'primary' : 'secondary';
    },

    noButtonVariant(): string {
      const { offersFinancing } = this.financingOptions;
      if (offersFinancing === null) return 'secondary';
      return offersFinancing ? 'secondary' : 'primary';
    },

    reEntryData(): any {
      return this.$store.getters[
        'MerchantOnboarding/getMerchantReEntryData'
      ];
    },

    reEntryDataAllAttributes(): any {
      return get(this.reEntryData, 'all_attributes', {});
    },
  },

  watch: {
    financingOptions: {
      handler() {
        this.$emit('financingOptionsUpdated', this.financingOptions);
      },
      deep: true,
    },
  },

  mounted() {
    const {
      paymentTypesAccepted,
      offersFinancing,
      financingVolume,
    } = this.$store.getters[
      'MerchantOnboarding/getAllAttributes'
    ];

    const reEntryPaymentTypesAccepted = this.reEntryDataAllAttributes.paymentTypesAccepted;
    const reEntryOffersFinancing = this.reEntryDataAllAttributes.offersFinancing;
    const reEntryFinancingVolume = this.reEntryDataAllAttributes.financingVolume;

    this.financingOptions.paymentTypesAccepted = paymentTypesAccepted || reEntryPaymentTypesAccepted || '';

    if (typeof offersFinancing === 'boolean') {
      this.financingOptions.offersFinancing = offersFinancing;
    } else {
      this.financingOptions.offersFinancing = reEntryOffersFinancing || null;
    }

    this.financingOptions.financingVolume = financingVolume || reEntryFinancingVolume || '';
  },

  methods: {
    setFinancing(financing: boolean): void {
      this.financingOptions.offersFinancing = financing;

      if (!financing) {
        this.financingOptions.financingVolume = 0;
      }
    },

    setErrorColor(val: boolean) {
      this.hasError = val;
    },

    async goToNextSubStep() {
      this.$props.onNextSubStep(this.financingOptions);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/financing-options";

:deep(.v-form .v-card-text) {
  text-align: left;
}

.title-text {
  color: var(--grayscale-color-1);
  font-size: 1rem;
}

.card-text__info-group.financing-options {
  .financing-btn.button-small {
    height: 2.5rem;
    width: 3rem;
    min-width: 3rem;

    &.button-primary {
      background-color: var(--grayscale-color-1);
      border-color: var(--grayscale-color-1);
    }

    &.button-secondary {
      color: var(--grayscale-color-1);

      &:hover:not(.disabled) {
        background-color: var(--grayscale-color-2);
        color: #FFFFFF;
        border-color: var(--grayscale-color-2);
      }
    }

    &.disabled:hover {
      cursor: not-allowed;
    }

    + .financing-btn {
      margin-left: 2rem;
    }
  }
}
.financing-options {
  align-items: center;

  &__label {
    font-size: 1rem !important;
  }
}
.payment-types-title {
  text-align: right;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.payment-types-title-two {
  text-align: left;
}

.error-text {
  color: var(--error-color) !important;
}
</style>
