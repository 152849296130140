import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, _mergeProps(_ctx.$attrs, {
    class: ["custom-button", _ctx.classList],
    icon: _ctx.variant === 'icon',
    variant: _ctx.btnVariant,
    "data-test": _ctx.dataTestAttr,
    type: _ctx.type
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
      ], true)
    ]),
    _: 3
  }, 16, ["class", "icon", "variant", "data-test", "type"]))
}