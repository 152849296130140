import { onMounted, onUnmounted } from 'vue';

type Handler = () => void;

const eventHandlers = new Map();

function subscribe(type: string, handler: Handler) {
  const handlers = eventHandlers.get(type);
  if (handlers) {
    handlers.push(handler);
  } else {
    eventHandlers.set(type, [handler]);
  }
}

function unsubscribe(type: string, handler: Handler) {
  const handlers = eventHandlers.get(type);
  if (handlers) {
    const index = handlers.indexOf(handler);
    if (index > -1) {
      handlers.splice(index, 1);
    }

    if (handlers.length === 0) {
      eventHandlers.delete(type);
    }
  }
}

function broadcast(type: string) {
  const handlers = eventHandlers.get(type);
  if (handlers) {
    handlers.forEach((handler: Handler) => handler());
  }
}

// To use inside Vue composable setup
const useEventSubscription = (type: string, handler: Handler) => {
  onMounted(() => {
    subscribe(type, handler);
  });

  onUnmounted(() => {
    unsubscribe(type, handler);
  });
};

export {
  subscribe,
  unsubscribe,
  broadcast,
  useEventSubscription,
};
