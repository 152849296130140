<template>
  <div class="users-table">
    <div class="table-controls" :class="tableClassList">
      <template v-if="multiLocation">
        <div v-if="isCorporateAdmin">
          <v-chip-group v-model="selectedTable" mandatory>
            <v-chip variant="text">
              <span>Users At This Location</span>
            </v-chip>
            <v-chip variant="text">
              <span>All Users</span>
            </v-chip>
          </v-chip-group>
        </div>
      </template>

      <div v-if="multiLocation || isMobile" class="search-wrapper">
        <text-input
          v-model="search"
          append-inner-icon="mdi-magnify"
          variant="underlined"
          dense
          hide-details
          in-place
          placeholder="Search" />
      </div>

      <div class="table-mobile-filters">
        <div>
          <strong>Filter By:</strong>
        </div>
        <multiselect-filter
          label="User Role"
          title="Filter By User Role"
          :items="rolesFilter.all.value"
          :selected="rolesFilter.selected.value"
          @update:selected="rolesFilter.onUpdate" />
        <multiselect-filter
          label="Status"
          title="Filter By Status"
          :items="statusFilter.all.value"
          :selected="statusFilter.selected.value"
          @update:selected="statusFilter.onUpdate" />
        <multiselect-filter
          v-if="tableAllUsersSelected"
          label="Locations"
          title="Filter By Locations"
          searchable
          :items="locationsFilter.all.value"
          :selected="locationsFilter.selected.value"
          @update:selected="locationsFilter.onUpdate" />
      </div>
    </div>

    <div v-show="hasFiltersSelected" class="selected-filters">
      <v-chip
        v-for="role in rolesFilter.selectedNames.value"
        :key="role"
        class="filter-chip"
        closable
        close-icon="mdi-close"
        @click:close="rolesFilter.removeSelected(role)">
        Role: {{ role }}
      </v-chip>
      <v-chip
        v-for="status in statusFilter.selected.value"
        :key="status"
        class="filter-chip"
        closable
        close-icon="mdi-close"
        @click:close="statusFilter.removeSelected(status)">
        Status: {{ status }}
      </v-chip>
      <v-chip
        v-for="location in locationsFilter.selected.value"
        :key="location"
        class="filter-chip"
        closable
        close-icon="mdi-close"
        @click:close="locationsFilter.removeSelected(location)">
        Location: {{ location }}
      </v-chip>
    </div>

    <v-data-table-server
      v-model:page="page"
      :headers="headers"
      :items="mappedUsers"
      :items-length="userCount"
      :items-per-page="pageSize"
      :loading="loading"
      :show-select="false"
      :single-select="false"
      :mobile="isMobile"
      class="standard-data-table"
      data-test="userTable"
      item-key="uid"
      :row-props="getRowProps">
      <template #header.role="{ column }">
        <multiselect-filter
          title="Filter By User Role"
          :label="column.title"
          :items="rolesFilter.all.value"
          :selected="rolesFilter.selected.value"
          @update:selected="rolesFilter.onUpdate" />
      </template>

      <template #header.status="{ column }">
        <multiselect-filter
          title="Filter By Status"
          :label="column.title"
          :items="statusFilter.all.value"
          :selected="statusFilter.selected.value"
          @update:selected="statusFilter.onUpdate" />
      </template>

      <template #header.accessible_locations="{ column }">
        <multiselect-filter
          title="Filter By Locations"
          searchable
          :label="column.title"
          :items="locationsFilter.all.value"
          :selected="locationsFilter.selected.value"
          min-width="350px"
          max-width="350px"
          @update:selected="locationsFilter.onUpdate" />
      </template>

      <template #item.name="{ item }">
        <span v-private>
          {{ item.first_name }} {{ item.last_name }}
        </span>
      </template>
      <template #item.role="{ item }">
        {{ item.role.display_name }}
      </template>
      <template #item.has_access="{ item }">
        <v-icon v-if="item.has_access">
          mdi-check
        </v-icon>
      </template>
      <template #item.email="{ item }">
        <span v-private>
          {{ item.email }}
        </span>
      </template>
      <template #item.phone="{ item }">
        <span v-private v-fs-exclude>
          {{ item.phone_number.phone_number }}
        </span>
      </template>
      <template #item.accessible_locations="{ item }">
        <button
          v-if="item.accessible_locations?.isConcated"
          variant="link"
          class="locations-button"
          @click="dialogLocations = item.accessible_locations?.names">
          {{ item.accessible_locations?.concatedValue }}
        </button>
        <span v-else class="location-names">
          {{ item.accessible_locations?.concatedValue }}
        </span>
      </template>
      <template #item.actions="{ item }">
        <user-table-actions
          :user="item"
          :all-users-table="tableAllUsersSelected"
          :loading
          :sending-reinvite
          :multi-location
          @reinvite="sendReinvite"
          @edit="setEditUserData"
          @deactivate="setDeactivateUserData"
        />
      </template>
    </v-data-table-server>

    <edit-user
      v-if="userEditData && !multiLocation"
      :user="userEditData"
      @save="onUserSave"
      @close="userEditData = null"
      @cancel="userEditData = null" />

    <user-form
      v-if="userEditData && multiLocation"
      v-bind="userEditData"
      :all-users-table="tableAllUsersSelected"
      @save="onUserSave"
      @close="userEditData = null"
      @cancel="userEditData = null" />

    <deactivate-user
      v-if="deactivateUserData"
      v-bind="deactivateUserData"
      @save="onStatusToggle"
      @close="deactivateUserData = null"
      @cancel="deactivateUserData = null" />

    <re-invite-user-dialog
      v-if="reinvitedEmail"
      :email="reinvitedEmail"
      :show-alert="showReinviteAlert"
      @close="reinvitedEmail = ''"
      @close:alert="showReinviteAlert = false" />
  </div>
</template>

<script setup lang="ts">
import {
  ref, computed, inject, onBeforeMount, watch,
} from 'vue';
import DeactivateUser, { DeactivateUserProps } from '@/components/Merchant/Portal/Admin/UserManagement/DeactivateUser.vue';
import EditUser from '@/components/Merchant/Portal/Admin/UserManagement/EditUser.vue';
import UserForm from '@/components/Merchant/Portal/Admin/UserManagement/MultiLocation/UserForm.vue';
import ReInviteUserDialog from '@/components/Merchant/Portal/Admin/UserManagement/ReInviteUserDialog.vue';
import MultiselectFilter from '@/components/Tables/MultiselectFilter.vue';
import TextInput from '@/components/Inputs/Text.vue';
import { useStore } from 'vuex';
import { useDisplay } from 'vuetify';
import { merchantUserManagementKey } from '@/constants/ProvideKeys';
import {
  getAllLocations, getAllRoles, getAllStatuses, resendUserInvite,
} from '@/api/merchant';
import { UserRole, UserAllLocations, UserAtThisLocation } from '@/interfaces/merchantPortal/UserInterface';
import { useMerchantPermissions } from '@/composables/useMerchantPermissions';
import { useFilter } from '@/composables/useFilter';
import useMPAccess from '@/components/Merchant/composables/useMPAccess';
import UserTableActions from './UserTableActions.vue';

type ComposedUser = UserAtThisLocation | UserAllLocations;

const componentProps = defineProps<{ multiLocation: boolean; }>();

// Table related
const {
  refreshUsers, userCount, page, users, selectedTable,
  loading, pageSize, search, tableFilters,
} = inject(merchantUserManagementKey)!;

function getRowProps({ item }: { item: ComposedUser }) {
  if (item.can_be_reinvited) return { class: 'reinvitable' };
  return { class: '' };
}

const tableClassList = computed(() => ({
  'multi-table': isCorporateAdmin.value,
  'd-sm-none': !componentProps.multiLocation,
}));

const { xs: isMobile } = useDisplay();

// Permissions related
const store = useStore();
const { isCorporateAdmin } = useMerchantPermissions();
const { hideUserTableActions } = useMPAccess();

// Re-invite related
const reinvitedEmail = ref('');
const showReinviteAlert = ref(false);
const sendingReinvite = ref(false);

const sendReinvite = async (email: string) => {
  sendingReinvite.value = true;
  window.setTimeout(() => {
    sendingReinvite.value = false;
  }, 5000);

  try {
    const merchantUuid = store.getters['MerchantPortal/getMerchantUuid'];
    await resendUserInvite(merchantUuid, email);
    reinvitedEmail.value = email;
  } catch (err) {
    showReinviteAlert.value = true;
  }
};

// Edit User
const userEditData = ref<ComposedUser | { userId?: number } | null>(null);
const setEditUserData = (user: ComposedUser) => {
  userEditData.value = componentProps.multiLocation
    ? { userId: user.id }
    : user;
};
const onUserSave = () => {
  userEditData.value = null;
  refreshUsers();
};

// Deactivate User
const deactivateUserData = ref<DeactivateUserProps | null>(null);
const setDeactivateUserData = (user: ComposedUser) => {
  deactivateUserData.value = {
    userId: componentProps.multiLocation ? user.id : user.user_id,
    firstName: user.first_name,
    lastName: user.last_name,
    status: user.status,
    multiLocation: componentProps.multiLocation,
    allUsersTable: tableAllUsersSelected.value,
  };
};
const onStatusToggle = () => {
  deactivateUserData.value = null;
  refreshUsers();
};

// Multilocation
const tableUsersAtThisLocationSelected = computed(() => selectedTable.value === 0);
const tableAllUsersSelected = computed(() => selectedTable.value === 1);
watch(selectedTable, () => {
  search.value = '';
  rolesFilter.selected.value = [];
  statusFilter.selected.value = [];
  locationsFilter.selected.value = [];
  updateLocationFilterOptions();
});

// Roles
const rolesFilter = useFilter(tableFilters, 'role');
const fetchRoles = async () => {
  const { data } = await getAllRoles();
  rolesFilter.all.value = data.results.map((role: UserRole) => ({
    label: role.display_name,
    value: role.name,
  }));
  rolesFilter.selected.value = tableFilters.role?.split(',') ?? [];
};

// Statuses
const statusFilter = useFilter(tableFilters, 'status');
const fetchStatuses = async () => {
  const { data } = await getAllStatuses();
  statusFilter.all.value = data.map((status: string) => ({
    label: status,
    value: status,
  }));
  statusFilter.selected.value = tableFilters.status?.split(',') ?? [];
};

// Locations
const locationsFilter = useFilter(tableFilters, 'locations');
const dialogLocations = ref<string[]>([]);

const updateLocationFilterOptions = async () => {
  if (!tableAllUsersSelected.value) return;

  const locationsResponse = await getAllLocations({ page_size: 10000 });
  locationsFilter.all.value = locationsResponse.data.results.map((location: any) => ({
    label: location.name,
    value: location.name,
  }));
  locationsFilter.selected.value = tableFilters.locations?.split(',') ?? [];
};

const LOCATION_NAME_LIMIT = 50;
const getLocationNames = (user: ComposedUser) => {
  const userAllLocations = user as UserAllLocations;
  if (!userAllLocations.accessible_locations?.length) return undefined;
  const names = userAllLocations.accessible_locations.map((it: any) => it.name);
  const concated = names.join(', ');
  return {
    names,
    concatedValue: concated.length > LOCATION_NAME_LIMIT
      ? `${concated.slice(0, LOCATION_NAME_LIMIT)}...`
      : concated,
    isConcated: concated.length > LOCATION_NAME_LIMIT,
  };
};

const hasFiltersSelected = computed(() => {
  return rolesFilter.selectedNames.value.length
  || statusFilter.selected.value.length
  || locationsFilter.selectedNames.value.length;
});

// Template
const headers = computed(() => {
  const name = {
    title: 'Name',
    align: 'start',
    sortable: false,
    value: 'name',
  };
  const role = {
    title: 'User Role',
    align: 'start',
    sortable: false,
    value: 'role',
  };
  const email = {
    title: 'Email',
    align: 'start',
    sortable: false,
    value: 'email',
  };
  const phone = {
    title: 'Phone',
    align: 'start',
    sortable: false,
    value: 'phone',
  };
  const id = {
    title: 'User ID',
    align: 'start',
    sortable: false,
    value: 'user_id',
  };
  const status = {
    title: 'Status',
    sortable: false,
    align: 'left',
    value: 'status',
  };
  const actions = {
    title: 'Actions',
    sortable: false,
    align: 'center',
    value: 'actions',
  };
  const locAccess = {
    title: 'Access this Location',
    sortable: false,
    align: 'center',
    value: 'has_access',
  };
  const locations = {
    title: 'Locations',
    sortable: false,
    align: 'start',
    value: 'accessible_locations',
  };
  let tableHeaders = [];
  if (!componentProps.multiLocation || tableUsersAtThisLocationSelected.value) {
    tableHeaders = [name, role, email, phone, id, status];
  } else {
    tableHeaders = [name, locAccess, role, email, phone, id, status, locations];
  }

  if (!hideUserTableActions.value) {
    tableHeaders.push(actions);
  }

  return tableHeaders;
});

const mappedUsers = computed(() => users.value.map((user: ComposedUser) => {
  const {
    first_name, last_name, role, email, user_id, status, can_be_reinvited, phone_number, id,
  } = user;
  return {
    id,
    first_name,
    last_name,
    has_access: (user as UserAllLocations).has_access ?? undefined,
    role,
    email,
    phone_number,
    user_id,
    status,
    accessible_locations: getLocationNames(user),
    can_be_reinvited,
  };
}));

onBeforeMount(() => {
  refreshUsers();
  fetchRoles();
  fetchStatuses();
  updateLocationFilterOptions();
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import '@/assets/scss/mixins/media_queries';
@import "@/assets/scss/table-default";
@import "@/assets/scss/mixins/mixins";
@import '@/assets/scss/components/buttons';

.custom-button {
  @include user-management-custom-button;
}

.standard-data-table {
  &__is-owner {
    font-size: 1.5rem;
    color: var(--grayscale-color-2);
  }

  &__actions {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    max-width: 15rem;

    @include up-to-large-desktop {
      max-width: fit-content;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  &__actions-dots {
    min-width: auto !important;
    color: var(--grayscale-color-1) !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  // Has to be :deep has it's assigned by the :row-props prop, which is Vuetify internal
  :deep(.reinvitable) {
    background-color: var(--grayscale-color-4) !important;
  }
}

.v-overlay__content {
  .v-list {
    width: 9.875rem !important;
  }
}

.action-buttons {
  font-size: 1rem !important;
  padding-left: 1.125rem;
}

@media screen and (max-width: 599px) {
  :deep() {
    .v-table {
      &__wrapper table tbody tr {
        padding: 0 !important;
      }
    }
  }
}

:deep() {
  .v-table-header tr th span {
    color: var(--grayscale-color-1);
  }

  .v-table__wrapper tbody tr td {
    color: var(--grayscale-color-1);
  }

  .v-table__mobile-table-row .v-table__mobile-row .v-table__mobile-row__cell {
    color: var(--grayscale-color-1);
  }

  .v-data-table-footer__items-per-page {
    display: none;
  }
}

.users-table {
  .table-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem 1rem;
    height: 3rem;
    background-color: var(--grayscale-color-5);
    border-bottom: 1px solid var(--grayscale-color-2);

    &.multi-table {
      justify-content: space-between;
    }

    :deep(.v-chip-group) {
      .v-slide-group__content {
        padding: 0;

        .v-chip {
          box-sizing: border-box;
          margin-top: 0;
          margin-bottom: 0;
          height: 1.875rem;
          font-size: 0.875rem;
          padding: 0.5rem;
          border-radius: 1rem;
          color: var(--primary-color);
          font-weight: 700;
          background-color: transparent;

          &--selected {
            color: var(--grayscale-color-1);
            background-color: #FFFFFF;
            border: 2px solid var(--grayscale-color-1);

            .v-chip__overlay {
              background: transparent;
            }
          }
        }
      }
    }

    .search-wrapper {
      width: 15.375rem;
      max-width: 20rem;
      padding-bottom: 0.5rem;

      :deep(.v-field) {
        .v-field__outline {
          color: var(--grayscale-color-1);
        }

        input::placeholder {
          color: var(--grayscale-color-1);
          opacity: 1;
        }
      }
    }

    .table-mobile-filters {
      display: none;
    }

    @media screen and (max-width: 599px) {
      .table-mobile-filters {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 0.5rem;
      }
    }
  }

  @media screen and (max-width: 599px) {
    .table-controls {
      flex-direction: column;
      align-items: flex-start;
      height: auto;

      > div {
        padding: 0.5rem 0.5rem 0 0;
      }

      .search-wrapper {
        padding-top: 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .standard-data-table {
    &__is-owner {
      font-size: 1.5rem;
      color: var(--grayscale-color-2);
    }

    :deep(.reinvitable) {
      background-color: var(--grayscale-color-4) !important;
    }

    :deep() .v-data-footer {
      height: 3.125rem;
      font-size: 0.9rem;
      color: var(--grayscale-color-1);

      .v-input {
        margin: 0 1rem;

        .v-select__selection {
          font-size: 0.9rem;
          color: var(--grayscale-color-1);
        }
      }
    }
  }

  .selected-filters {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    padding: 0.5rem;

    .filter-chip {
      font-size: 0.875rem;
      border: 1px solid var(--grayscale-color-3);
      color: var(--grayscale-color-1);
      background-color: var(--grayscale-color-5);
      white-space: normal;
      height: fit-content;
      min-height: 2rem;
    }
  }

  .locations-button {
    margin: auto 0;
    width: 10rem;
    padding: 0;
    font-weight: 400;
    text-align: left;
    color: var(--primary-color);
    cursor: pointer;
  }

  .location-names {
    display: inline-block;
    width: 10em;
  }

  .v-select__content {
    :deep(.v-list) {
      width: 9.875rem !important;
    }
  }

  @media screen and (max-width: 599px) {
    :deep() {
      .v-table {
        &__wrapper table tbody tr {
          padding: 0 !important;
        }
      }
    }
  }

  :deep() {
    .v-table-header tr th span {
      color: var(--grayscale-color-1);
    }

    .v-table__wrapper tbody tr td {
      color: var(--grayscale-color-1);
    }

    .v-data-table__tr--mobile .v-table__mobile-row .v-data-table__td-value {
      color: var(--grayscale-color-1);
    }
  }
}
</style>
