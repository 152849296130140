import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-381bcd56"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mobile-title"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "card-text__info-group" }
const _hoisted_4 = { class: "card-text__info-group" }
const _hoisted_5 = { class: "card-text__info-group" }
const _hoisted_6 = { class: "card-text__info-group" }
const _hoisted_7 = { class: "card-text__info-group" }
const _hoisted_8 = {
  key: 0,
  class: "card-text__info-group"
}
const _hoisted_9 = { class: "card-text__info-group" }
const _hoisted_10 = { class: "card-text__info-group" }
const _hoisted_11 = { class: "card-text__info-group" }
const _hoisted_12 = { class: "card-text__info-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_tax_id_input = _resolveComponent("tax-id-input")!
  const _component_ssn_input = _resolveComponent("ssn-input")!
  const _component_url_input = _resolveComponent("url-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_merchant_onboarding_wizard_annual_sales_revenue = _resolveComponent("merchant-onboarding-wizard-annual-sales-revenue")!
  const _component_merchant_onboarding_wizard_average_customer_spend = _resolveComponent("merchant-onboarding-wizard-average-customer-spend")!
  const _component_merchant_onboarding_wizard_payments_types_financing_options = _resolveComponent("merchant-onboarding-wizard-payments-types-financing-options")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "business-details fs-unmask inline-inputs",
    elevation: _ctx.cardElevation
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, {
        class: "py-3",
        "data-test": "businessDetailsSubHeader"
      }, {
        default: _withCtx(() => [
          (_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("h6", _hoisted_1, " Business Details "))
            : (_openBlock(), _createElementBlock("h5", _hoisted_2, " Business Details ")),
          _createVNode(_component_custom_button, {
            class: "edit-save-button",
            disabled: _ctx.saveDisabled,
            variant: "text",
            size: "small",
            onClick: _ctx.toggleEdit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.editing ? 'Save' : 'Edit'), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, { class: "pa-4" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_select, {
              ref: "program",
              "model-value": _ctx.programsData.program,
              disabled: !_ctx.editing || _ctx.isBrandProFund,
              class: "old-v-select",
              variant: _ctx.editing ? 'underlined' : 'plain',
              items: _ctx.programs,
              "data-test": "programSelect",
              label: "Industry",
              "item-title": "name",
              "item-value": "program_uuid",
              "onUpdate:modelValue": _ctx.selectProgram
            }, null, 8, ["model-value", "disabled", "variant", "items", "onUpdate:modelValue"]),
            _createVNode(_component_v_select, {
              ref: "subProgram",
              "model-value": _ctx.programsData.subProgram,
              disabled: !_ctx.editing,
              class: "old-v-select",
              variant: _ctx.editing ? 'underlined' : 'plain',
              items: _ctx.subProgramsList,
              "data-test": "subSelect",
              label: "Select",
              "item-title": "name",
              "item-value": "subprogram_uuid",
              "onUpdate:modelValue": _ctx.selectSubprogram
            }, null, 8, ["model-value", "disabled", "variant", "items", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_text_input, {
              modelValue: _ctx.allAttributes.legalBusinessName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.allAttributes.legalBusinessName) = $event)),
              disabled: !_ctx.editing,
              max: "250",
              "in-place": "",
              required: "",
              "data-test": "legalInput",
              label: "Legal Business Name"
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_text_input, {
              modelValue: _ctx.allAttributes.doingBusinessAs,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.allAttributes.doingBusinessAs) = $event)),
              disabled: !_ctx.editing,
              max: "250",
              "in-place": "",
              "data-test": "doingInput",
              label: "Name Doing Business As"
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_select_input, {
              modelValue: _ctx.allAttributes.businessStructure,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.allAttributes.businessStructure) = $event)),
              class: "old-v-select",
              disabled: !_ctx.editing,
              "in-place": "",
              items: _ctx.optionsBusinessStructure,
              "data-test": "businessInput",
              "is-mob": true,
              label: "Business Structure"
            }, null, 8, ["modelValue", "disabled", "items"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_tax_id_input, {
              modelValue: _ctx.allAttributes.federalTaxId,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.allAttributes.federalTaxId) = $event)),
              disabled: _ctx.formInputsDisabled,
              "in-place": "",
              required: "",
              "data-test": "taxInput"
            }, null, 8, ["modelValue", "disabled"])
          ]),
          (_ctx.showSsnInput)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_ssn_input, {
                  modelValue: _ctx.allAttributes.ssn,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.allAttributes.ssn) = $event)),
                  disabled: _ctx.formInputsDisabled,
                  "in-place": "",
                  required: ""
                }, null, 8, ["modelValue", "disabled"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_url_input, {
              modelValue: _ctx.allAttributes.websiteUrl,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.allAttributes.websiteUrl) = $event)),
              disabled: _ctx.formInputsDisabled,
              "in-place": "",
              "data-test": "websiteInput",
              label: "Website URL"
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_date_input, {
              ref: "datePicker",
              modelValue: _ctx.allAttributes.businessStartDate,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.allAttributes.businessStartDate) = $event)),
              disabled: _ctx.formInputsDisabled,
              "in-place": "",
              max: _ctx.maxDate,
              range: false,
              "is-fs-excluded": false,
              required: "",
              label: "Date Business Started"
            }, null, 8, ["modelValue", "disabled", "max"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_merchant_onboarding_wizard_annual_sales_revenue, {
              modelValue: _ctx.allAttributes.annualSalesRevenue,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.allAttributes.annualSalesRevenue) = $event)),
              class: "bussines-details",
              disabled: _ctx.formInputsDisabled,
              "in-place-input": "",
              "data-test": "annualInput"
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_merchant_onboarding_wizard_average_customer_spend, {
              modelValue: _ctx.allAttributes.averageCustomerSpend,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.allAttributes.averageCustomerSpend) = $event)),
              class: "bussines-details",
              disabled: _ctx.formInputsDisabled,
              "in-place-input": "",
              "data-test": "averageInput"
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _createVNode(_component_merchant_onboarding_wizard_payments_types_financing_options, {
            class: "bussines-details substep-eight-financing-options",
            "disabled-inputs": _ctx.formInputsDisabled,
            "in-place-input": "",
            "from-business-details": "",
            "show-next-button": false,
            onFinancingOptionsUpdated: _ctx.onUpdateFinancingOptions
          }, null, 8, ["disabled-inputs", "onFinancingOptionsUpdated"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["elevation"]))
}