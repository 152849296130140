import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c33e8c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rate-sheet-options-data-table-description-tooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_info_dialog = _resolveComponent("info-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_custom_button, {
      variant: "icon",
      onClick: _ctx.onClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          size: "20",
          color: "var(--grayscale-color-2)"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" mdi-help-circle-outline ")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_info_dialog, {
      dialog: _ctx.show,
      "on-cta": _ctx.onCancel,
      "on-cancel": _ctx.onCancel
    }, null, 8, ["dialog", "on-cta", "on-cancel"])
  ]))
}