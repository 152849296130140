<template>
  <div class="fs-unmask">
    <v-row justify="center">
      <v-col>
        <custom-button
          :disabled="disableResend"
          data-test="resendBtn"
          full-width
          variant="text"
          @click="showModal">
          <span class="otp-message">{{ otpMessage }}</span>
        </custom-button>
      </v-col>
    </v-row>

    <standard-dialog
      :dialog="show"
      :cta-disabled="disableCta"
      :on-cta="isReEntry ? closeModal : resendOtp"
      :on-cancel="closeModal"
      :show-actions="!requestSuccessful"
      :show-cancel-cta="false"
      :show-progress-indicator="!isReEntry ? processing : false"
      :show-title="false"
      :button-label="isReEntry ? 'OK' : 'Send'"
      :consumer-re-entry="isReEntry"
      data-test="resendOtpModal">
      <template #body>
        <p v-if="isReEntry">
          We resent the code to <span v-private>{{ phone_number }}</span>.
        </p>

        <p v-else>
          This is the mobile phone number you entered earlier. If this is correct press send.
          If it is not correct please make any necessary changes and press send.
        </p>

        <phone-input
          v-if="!isReEntry"
          v-model="phone_number"
          :disabled="processing"
          :error-messages="errors.mobile"
          required
          data-test="mobileInput"
          label="Mobile Number" />
      </template>
    </standard-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import OtpMixin from '@/mixins/OtpMixin';
import { OtpRegisterRequestDataInterface } from '@/interfaces/otp/OtpRegisterRequestDataInterface';
import PhoneInput from '@/components/Inputs/Phone.vue';
import SetCustomErrorValueMixin from '@/mixins/SetCustomErrorValueMixin';
import StandardDialog from '@/components/Dialogs/index.vue';

export default defineComponent({
  name: 'ResendOtpModal',

  components: {
    CustomButton,
    PhoneInput,
    StandardDialog,
  },

  mixins: [
    GetErrors,
    GetProcessing,
    SetCustomErrorValueMixin,
    OtpMixin,
  ],

  props: {
    invitationUuid: { type: String, default: '' },
    merchantUserEmail: { type: String, default: '' },
    phoneNumber: { type: String, required: true },
    onPhoneNumberUpdated: { type: Function, required: true },
    consumerOtp: { type: Boolean, default: false },
    isMob: { type: Boolean, default: false },
    otpMessage: { type: String, default: "I didn't receive a text message" },
  },

  data() {
    return {
      show: false,
      phone_number: '',
      requestSuccessful: false,
    };
  },

  computed: {
    isReEntry(): boolean {
      return this.$store.getters['Consumer/getLoanApplyIsReEntry'];
    },

    disableCta(): boolean {
      return !this.phone_number || this.disableResend || this.processing;
    },

    disableResend(): boolean {
      return !this.phoneResendEnabled;
    },
  },

  mounted() {
    this.phone_number = this.phoneNumber;
  },

  methods: {
    async resendOtp() {
      if (this.phoneResendEnabled) {
        if (this.consumerOtp) {
          await this.$store.dispatch('Consumer/otpRegister', this.phone_number);
        } else if (this.isMob) {
          await this.$store.dispatch('MerchantOnboarding/dispatchOtpRegister', this.phone_number);
        } else {
          const payload: OtpRegisterRequestDataInterface = {
            phone_number: this.phone_number,
          };

          if (this.invitationUuid) {
            payload.invitation_uuid = this.invitationUuid;
          }

          if (this.merchantUserEmail) {
            payload.merchant_user_email = this.merchantUserEmail;
          }

          await this.$store.dispatch('Otp/otpRegister', payload);
        }

        this.onPhoneNumberUpdated(this.phone_number);
        if (!this.isReEntry) this.closeModal();
      }
    },

    showModal(): void {
      if (this.isReEntry) {
        this.resendOtp();
      }
      this.requestSuccessful = false;
      this.show = true;
    },

    closeModal(): void {
      this.requestSuccessful = false;
      this.show = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/mixins";
@import "@/assets/scss/components/dialogs";

@include mdi-icon;

button {
  &:hover {
    color: var(--grayscale-color-1);
    .otp-message {
      color: var(--grayscale-color-1);
    }
  }
}
</style>
