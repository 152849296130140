import { defineComponent } from 'vue';

export default defineComponent({
  watch: {
    stepData() {
      this.restartIdleTimeout();
    },
  },

  methods: {
    startIdleTimeout() {
      this.$store.dispatch('Ui/startIdleTimeout');
    },

    restartIdleTimeout() {
      this.$store.dispatch('Ui/restartIdleTimeout');
    },

    clearIdleTimeout() {
      this.$store.dispatch('Ui/clearIdleTimeout');
    },
  },
});
