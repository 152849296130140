import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-491877e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-test"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classList),
    "data-test": _ctx.dataTestAttr
  }, [
    _createVNode(_component_v_card, { flat: _ctx.hideWrapper }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("h6", _hoisted_2, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            (_ctx.message)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.message), 1))
              : _createCommentVNode("", true),
            (_ctx.showBodySlot)
              ? _renderSlot(_ctx.$slots, "body", { key: 2 }, undefined, true)
              : _createCommentVNode("", true)
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["flat"])
  ], 10, _hoisted_1))
}