import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rate_sheet_options_data_table = _resolveComponent("rate-sheet-options-data-table")!
  const _component_simple_card = _resolveComponent("simple-card")!

  return (_openBlock(), _createBlock(_component_simple_card, {
    class: _normalizeClass(_ctx.classList)
  }, {
    body: _withCtx(() => [
      _createVNode(_component_rate_sheet_options_data_table, {
        ref: "rateSheetDataTable",
        modelValue: _ctx.inputVal,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputVal) = $event)),
        "show-header-info-on-mount": false,
        "items-per-page": 10,
        "rate-sheet-options": _ctx.rateSheetOptions,
        "show-header": false,
        "show-legend": false,
        "button-toggle": _ctx.buttonToggle,
        "additional-product-offerings": "",
        "disable-add-btns": _ctx.disableAddBtns,
        "mobile-breakpoint": _ctx.mobileBreakpoint,
        "is-from-offer-codes": _ctx.isFromOfferCodes,
        "table-data-test": _ctx.dataTestAttr,
        "ratesheet-table": true,
        onToggle: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggle', $event)))
      }, null, 8, ["modelValue", "rate-sheet-options", "button-toggle", "disable-add-btns", "mobile-breakpoint", "is-from-offer-codes", "table-data-test"])
    ]),
    _: 1
  }, 8, ["class"]))
}