import { LoanApplicationDataInterface } from '@/interfaces/consumer/loanApply/LoanApplicationDataInterface';
import { LoanApplicationStateInterface } from '@/interfaces/states/LoanApplicationStateInterface';

export function getLoanApplicationWizardStep(state: LoanApplicationStateInterface) {
  return state.loanApplicationWizardStep;
}

export function getLoanApplicationWizardData(
  state: LoanApplicationStateInterface,
): LoanApplicationDataInterface {
  return state.loanApplicationWizardData;
}

export function getMerchantId(state: LoanApplicationStateInterface) {
  return state.merchantId;
}
