import {
  AddressDataInterface,
  ValidatedAddressDataInterface,
} from '@/interfaces/addressValidation/AddressDataInterface';
import { lookupAddress } from '@/api/address';
import { AxiosError } from 'axios';

interface ValidationResult {
  validatedAddress?: ValidatedAddressDataInterface;
  errorMsg?: string;
}

export default async (address: AddressDataInterface): Promise<ValidationResult> => {
  const result: ValidationResult = { validatedAddress: undefined, errorMsg: '' };
  try {
    const lookup = await lookupAddress({
      street: address.address1,
      secondary: address.address2,
      city: address.city,
      state: address.state,
      zipcode: address.zipCode,
      lastLine: `${address.city}, ${address.state}, ${address.zipCode}`,
      candidates: 1,
      match: 'enhanced',
    });

    result.validatedAddress = {
      countyName: lookup.county_name,
      address1: lookup.address_1,
      address2: lookup.address_2,
      state: lookup.state,
      city: lookup.city,
      zipShort: lookup.zip_code.split('-')[0],
      zipCode: lookup.zip_code,
      address_verification_response: lookup.address_verification_response,
    };
  } catch (e) {
    result.errorMsg = (e as AxiosError).response?.data.message;
  }

  return result;
};
