import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97fc5ce4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, _mergeProps({
    modelValue: _ctx.menuOpen,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.menuOpen) = $event))
  }, _ctx.$attrs, {
    "close-on-content-click": false,
    "min-width": _ctx.minWidth,
    "max-width": _ctx.maxWidth
  }), {
    activator: _withCtx(({ props }) => [
      _createElementVNode("div", _mergeProps(props, {
        class: ["filter-activator", { 'filter-activator--filtered': _ctx.selected.length > 0 }]
      }), [
        _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
        _createVNode(_component_v_icon, null, {
          default: _withCtx(() => [
            _createTextVNode("mdi-filter-variant")
          ]),
          _: 1
        })
      ], 16)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "bg-white multiselect-filter" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "filter-title" }, {
            default: _withCtx(() => [
              _createElementVNode("strong", null, _toDisplayString(_ctx.title), 1),
              _createVNode(_component_v_btn, {
                icon: "mdi-close",
                variant: "plain",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menuOpen = false))
              })
            ]),
            _: 1
          }),
          (_ctx.searchable)
            ? (_openBlock(), _createBlock(_component_text_input, {
                key: 0,
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                clearable: "",
                class: "px-2",
                "in-place": "",
                "hide-details": "",
                "append-icon": "mdi-magnify"
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_list, { class: "multiselect-filter__list" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      key: item.value,
                      onClick: ($event: any) => (_ctx.toggleSelected(item.value, $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_action, { start: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_checkbox_btn, {
                              "hide-details": "",
                              ripple: _ctx.ripple,
                              "model-value": _ctx.isSelected(item.value)
                            }, null, 8, ["ripple", "model-value"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_custom_button, {
                "full-width": "",
                class: "action-button",
                size: "small",
                disabled: !_ctx.hasChanges,
                onClick: _ctx.apply
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Apply ")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_custom_button, {
                  "full-width": "",
                  variant: "text",
                  class: "action-button",
                  size: "small",
                  disabled: !_ctx.internalSelected.length,
                  onClick: _ctx.clearSelection
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Clear Selection ")
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16, ["modelValue", "min-width", "max-width"]))
}