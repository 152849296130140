import { OwnerDataInterface } from '@/interfaces/merchantOnboardingWizard/OwnerDataInterface';
import { MerchantOnboardingStateInterface } from '@/interfaces/states/MerchantOnboardingStateInterface';
import {
  get, reduce, toInteger,
} from 'lodash';
import MerchantApplicationStatus from '@/enums/Merchant/ApplicationStatusEnum';

export function getMerchantHumanReadableId(state: MerchantOnboardingStateInterface) {
  return state.onboardingUserData.humanReadableId ?? '';
}

export function getConsent(state: MerchantOnboardingStateInterface) {
  return state.consent;
}

export function getProcessingApplication(state: MerchantOnboardingStateInterface) {
  return state.processingApplication;
}

export function getApplicationApproved(state: MerchantOnboardingStateInterface) {
  return state.applicationApproved;
}

export function getApplicationWaitingApproval(state: MerchantOnboardingStateInterface) {
  return state.applicationWaitingApproval;
}

export function getPrograms(state: MerchantOnboardingStateInterface) {
  return state.programs;
}

export function getSubPrograms(state: MerchantOnboardingStateInterface) {
  return state.subPrograms;
}

export function getSelectedPrograms(state: MerchantOnboardingStateInterface) {
  return state.selectedPrograms;
}

export function getCombinedOwners(state: MerchantOnboardingStateInterface) {
  return state.combinedOwners;
}

export function getRateSheets(state: MerchantOnboardingStateInterface) {
  return state.rateSheets;
}

export function getUserVerifyData(state: MerchantOnboardingStateInterface) {
  return state.onboardingUserData;
}

export function getUserId(state: MerchantOnboardingStateInterface) {
  return get(state.onboardingUserData, 'userId');
}

export function getMerchantUid(state: MerchantOnboardingStateInterface) {
  return get(state.onboardingUserData, 'merchantUid');
}

export function getTotalOwnershipValid(_: any, getters: any) {
  return getters.getTotalOwnershipPercentage >= 51
    && getters.getTotalOwnershipPercentage <= 100;
}

export function getTotalOwnershipPercentage(state: MerchantOnboardingStateInterface) {
  return reduce(
    state.combinedOwners,
    (result, value: OwnerDataInterface) => toInteger(get(value, 'ownerPercentage', 0)) + result,
    0,
  );
}

export function getOnboardingWizardStep(state: MerchantOnboardingStateInterface) {
  return state.merchantOnboardingWizardStep;
}

export function getOnboardingWizardSubStep(state: MerchantOnboardingStateInterface) {
  return state.merchantOnboardingWizardSubStep;
}

export function getAllAttributes(state: MerchantOnboardingStateInterface) {
  return state.allAttributes;
}

export function getMerchantReEntryData(state: MerchantOnboardingStateInterface) {
  return state.merchantReEntryData;
}

export function getVerifyFormsOpen(state: MerchantOnboardingStateInterface) {
  return state.verifyFormsOpen;
}

export function getUserTermsLink(state: MerchantOnboardingStateInterface) {
  return state.moovUserTermsPage;
}

export function getIsOwnerSaved(state: MerchantOnboardingStateInterface) {
  return state.ownerIsSaved;
}

export function getApplicationStatus(state: MerchantOnboardingStateInterface) {
  return state.applicationStatus;
}

export function getRedirectUrl(state: MerchantOnboardingStateInterface) {
  return state.redirectUrl;
}

export function getShowMobStepper(state: MerchantOnboardingStateInterface) {
  return state.showMobStepper;
}

export function getPassedPhoneVerification(state: MerchantOnboardingStateInterface) {
  return state.passedPhoneVerification;
}

export function getShowBackButtonPreventModal(state: MerchantOnboardingStateInterface) {
  return state.showBackButtonPreventModal;
}

export function isApplicationInIntake(_: MerchantOnboardingStateInterface, getters: any) {
  return getters.getApplicationStatus === MerchantApplicationStatus.INTAKE;
}

export function isApplicationInProgress(_: MerchantOnboardingStateInterface, getters: any) {
  return [
    MerchantApplicationStatus.INTAKE,
    MerchantApplicationStatus.PRE_INTAKE,
  ].includes(getters.getApplicationStatus);
}

export function appStatusReason(state: MerchantOnboardingStateInterface) {
  return state.onboardingUserData?.appStatusReason;
}
