import { forOwn } from 'lodash';

export default function ResetModuleState(
  state: any,
  initialState: any,
): void {
  forOwn(state as any, (value: any, key: string) => {
    state[key] = initialState[key];
  });
}
