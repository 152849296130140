import axios from 'axios';
import { BASE_PATH } from '@/api/index';

const requestConfig = {
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
};

export function environmentVariables() {
  return axios.get('/static/config.json', requestConfig);
}

export function featureFlags() {
  return axios.get(`${BASE_PATH}/utils/feature-flag`, requestConfig);
}
