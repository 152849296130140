import AppDisplayTypes from '@/enums/AppDisplayTypes';
import { GlobalAlertIface } from '@/interfaces/GlobalAlertInterface';
import { UiStateInterface } from '@/interfaces/states/UiStateInterface';
import { UiStoreBrandingData } from '@/interfaces/states/UiStoreBrandingData';
import { SystemNotificationInterface } from '@/interfaces/ui/SystemNotificationInterface';
import * as actions from './actions';
import * as getters from './getters';
// eslint-disable-next-line import/no-cycle
import * as mutations from './mutations';

const initialState = (): UiStateInterface => ({
  systemNotifications: [] as Array<SystemNotificationInterface>,
  brandingData: {} as UiStoreBrandingData,
  appDisplayType: AppDisplayTypes.STANDARD,
  errors: {},
  processing: 0,
  showIdleTimeoutModal: false,
  userFriendlyErrorResponse: '',
  globalAlerts: [] as Array<GlobalAlertIface>,
});

export default {
  namespaced: true,
  state: initialState() as UiStateInterface,
  getters,
  mutations,
  actions,
};
