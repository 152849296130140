import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-acf6807a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expansion-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_expansion_panels, _mergeProps({
      modelValue: _ctx.expandedPanel,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expandedPanel) = $event))
    }, _ctx.$attrs), {
      default: _withCtx(() => [
        _createVNode(_component_v_expansion_panel, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_expansion_panel_title, {
              disabled: _ctx.canCollapse,
              "hide-actions": _ctx.canCollapse,
              class: _normalizeClass(_ctx.showOverflow ? 'show-overflow' : '')
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "title", {
                  isExpanded: _ctx.expandedPanel === 0
                }, undefined, true)
              ]),
              _: 3
            }, 8, ["disabled", "hide-actions", "class"]),
            _createVNode(_component_v_expansion_panel_text, null, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "text", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 16, ["modelValue"])
  ]))
}