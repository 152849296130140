import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';

/**
 * Takes in array of TableHeader objects
 * Has default align and sortable props
 * If a header has specificed align, it will persist
*/
export default function headers(
  headersObjs: Array<TableHeaderInterface>,
  defaultAlign: string = 'right',
  defaultSortable: boolean = false,
): Array<TableHeaderInterface> {
  headersObjs.forEach(obj => {
    if (!obj.align) obj.align = defaultAlign;
    if (!obj.sortable) obj.sortable = defaultSortable;
  });
  return headersObjs;
}
