import { defineComponent } from 'vue';
import UsersRolesEnum from '@/enums/UsersRolesEnum';
import { GroupInterface } from '@/interfaces/auth/GroupInterface';
import { highestRole } from '@/utils/MerchantRoleUtils';

export default defineComponent({
  name: 'MerchantPermissionsMixin',
  computed: {
    userGroup(): GroupInterface[] {
      return this.$store.getters['Auth/getGroup'];
    },
    isCorporateAdmin(): boolean {
      return !!this.userGroup.find(it => it.name === UsersRolesEnum.CORPORATE_ADMIN);
    },
    isAdmin(): boolean {
      return !!this.userGroup.find(it => it.name === UsersRolesEnum.ADMIN);
    },
    isAdminOrAbove(): boolean {
      return !!this.userGroup.find(it => {
        return [
          UsersRolesEnum.ADMIN,
          UsersRolesEnum.CORPORATE_ADMIN,
        ].includes(it.name as UsersRolesEnum);
      });
    },
    isSalesManager(): boolean {
      return !!this.userGroup.find(it => it.name === UsersRolesEnum.SALES_MANAGER);
    },
    isSalesRep(): boolean {
      return !!this.userGroup.find(it => it.name === UsersRolesEnum.SALES_REP);
    },
    userRoleLabel(): string {
      if (this.isCorporateAdmin) {
        return 'Corporate Admin';
      }
      if (this.isAdmin) {
        return 'Admin';
      }
      if (this.isSalesManager) {
        return 'Manager';
      }
      if (this.isSalesRep) {
        return 'Representative';
      }
      return 'Salesperson';
    },
    userRoles(): string[] {
      return this.userGroup.map(it => it.name);
    },
    userHighestRole(): UsersRolesEnum {
      return highestRole(this.userRoles as UsersRolesEnum[]);
    },
  },
});
