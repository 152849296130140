<template>
  <div class="verify-deposit">
    <div v-if="loaderActive && showLoader" class="d-flex justify-center pa-8">
      <v-progress-circular
        size="64"
        width="5"
        color="var(--grayscale-color-1)"
        indeterminate />
    </div>

    <div v-else>
      <v-col lg="12" class="verify-deposit__msg">
        <div v-if="!showErrorAlert" class="d-flex">
          <div class="split-payment-text">
            <p><strong>Now Check Your Bank Account!</strong></p>
            <p>It can take up to 24 Hours for these deposits to arrive in your bank account.</p>
            <p>
              Enter the two deposit amounts sent to account ending in
              <strong>...{{ lastFour }}.</strong>
              The deposits will come from ACCTVERIFY and look something like this.
            </p>
          </div>
        </div>

        <custom-alert
          v-if="showErrorAlert"
          class="mt-4"
          type="error">
          Those amounts appear to be incorrect.
          Please check the amounts in your bank account and try again.
          <div class="d-flex justify-end">
            <custom-button variant="text" @click="$emit('activateDialog')">
              Learn more
            </custom-button>
          </div>
        </custom-alert>

        <custom-alert
          v-if="showGenericErrorMessage"
          class="mt-4"
          type="input-error">
          We are currently experiencing technical difficulties. Please try again later.
        </custom-alert>
      </v-col>

      <v-card
        class="col-lg-12 pb-0 pt-0 standard-wizard__step__card verify-deposit__deposit-inputs">
        <v-card-text>
          <verify-deposits-example-table v-if="!showErrorAlert" />

          <div v-if="canSubmitDeposits || showDepositsFormSplitPayment">
            <custom-button
              v-if="!showDepositsForm && !showDepositsFormSplitPayment"
              full-width
              @click="showDepositsForm = true">
              Click Here When You Know Your Deposit Amounts
            </custom-button>
            <v-form
              v-if="showDepositsForm || showDepositsFormSplitPayment"
              ref="verifyDepositForm"
              @submit.prevent>
              <div class="card-text__info-group">
                <cents-input
                  v-model="depositAmounts.deposit1cents"
                  data-test="depositAmount1"
                  label="Deposit Amount"
                  :rules="[validateDeposit]"
                  show-icon
                  required />
              </div>

              <div class="card-text__info-group">
                <cents-input
                  v-model="depositAmounts.deposit2cents"
                  data-test="depositAmount2"
                  label="Deposit Amount"
                  :rules="[validateDeposit]"
                  show-icon
                  required />
              </div>

              <div class="card-text__info-group">
                <custom-button
                  :disabled="disabledBtn"
                  data-test="verifyAmountBtn"
                  full-width
                  type="submit"
                  @click="showConfirmationDialog = true">
                  Verify Amounts
                </custom-button>
              </div>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <deposit-verification-confirmation-dialog
      v-if="showConfirmationDialog"
      :deposit-amounts="depositAmounts"
      @cancel="showConfirmationDialog = false"
      @confirm="verifyAmounts" />
    <standard-dialog
      :dialog="showNoMoreAttemptsAvailableDialog"
      :hide-header="true"
      :show-actions="false"
      :persistent="true"
      width="420">
      <template #body>
        <custom-alert type="error">
          Those amounts appear to be incorrect.
        </custom-alert>
        <custom-button
          full-width
          @click="reinitiateDeposits">
          Continue
        </custom-button>
      </template>
    </standard-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CentsInput from '@/components/Inputs/CentsInput.vue';
import postTokenPayment from '@/api/repay';
import BANK_ACCOUNT_STATUSES from '@/constants/PaymentConstants';
import DepositVerificationConfirmationDialog
  from '@/components/PaymentProviders/DepositVerificationConfirmationDialog.vue';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import VerifyDepositsExampleTable from './VerifyDepositsExampleTable.vue';

export default defineComponent({
  name: 'VerifyDeposit',

  components: {
    DepositVerificationConfirmationDialog,
    CustomButton,
    CustomAlert,
    StandardDialog,
    VerifyDepositsExampleTable,
    CentsInput,
  },

  props: {
    lastFour: { type: String, default: null },
    showDepositsFormSplitPayment: { type: Boolean, default: false },
    showLoader: { type: Boolean, default: false },
  },

  data() {
    const depositAmounts: any = {
      deposit1cents: '',
      deposit2cents: '',
    };
    return {
      depositAmounts,
      showErrorAlert: false,
      showGenericErrorMessage: false,
      showConfirmationDialog: false,
      showNoMoreAttemptsAvailableDialog: false,
      showDepositsForm: false,
      loaderActive: false,
    };
  },

  computed: {
    disabledBtn(): boolean {
      const { deposit1cents, deposit2cents } = this.depositAmounts;
      const depositOneValid = deposit1cents > 0 && deposit1cents <= 49;
      const depositTwoValid = deposit2cents > 0 && deposit2cents <= 49;

      return !depositOneValid || !depositTwoValid;
    },

    merchantUuid(): any {
      return this.$store.getters['Auth/getMerchantUuid'];
    },

    canSubmitDeposits(): boolean {
      return this.paymentProviders?.moov?.eligible_for_next_verification_attempt;
    },

    moovStatus() {
      return this.paymentProviders?.moov?.status;
    },

    paymentProviders(): PaymentProvidersInterface {
      return this.$store.getters['MerchantPortal/getPaymentProviders'];
    },
  },

  methods: {
    async verifyAmounts() {
      this.showConfirmationDialog = false;
      this.showErrorAlert = false;
      this.loaderActive = true;

      const verifyDepositsPayload = {
        ach_account_number: null,
        ach_routing_number: null,
        micro_deposits_amounts: [
          this.depositAmounts.deposit1cents / 100,
          this.depositAmounts.deposit2cents / 100,
        ],
      };

      try {
        const tokenResponse = await postTokenPayment(this.merchantUuid, verifyDepositsPayload);

        if (tokenResponse.status === 200) {
          await this.$store.dispatch('MerchantPortal/getPaymentProviders');

          if (this.moovStatus === BANK_ACCOUNT_STATUSES.CONNECTED) {
            await this.$store.dispatch('MerchantPortal/dispatchGetMerchantData');
            this.$emit('amountsVerified', true);
          } else if (this.moovStatus === BANK_ACCOUNT_STATUSES.ERRORED) {
            this.depositAmounts.deposit1cents = 0;
            this.depositAmounts.deposit2cents = 0;
            this.showErrorAlert = false;
            this.verificationErrored();
          } else if (this.moovStatus === BANK_ACCOUNT_STATUSES.PENDING) {
            this.showErrorAlert = true;
          } else if (this.moovStatus === BANK_ACCOUNT_STATUSES.VERIFICATION_FAILED) {
            this.$store.dispatch('MerchantPortal/getPaymentProviders');
            this.depositAmounts.deposit1cents = 0;
            this.depositAmounts.deposit2cents = 0;
            this.showErrorAlert = false;
          }
        }
      } catch (err: any) {
        if (err.response) {
          this.$store.dispatch('Ui/setErrors', err.response);
        }
        this.depositAmounts.deposit1cents = 0;
        this.depositAmounts.deposit2cents = 0;
        this.showGenericErrorMessage = true;
      }
      this.loaderActive = false;
    },

    reinitiateDeposits() {
      this.showNoMoreAttemptsAvailableDialog = false;
      this.$emit('verificationFailed');
    },

    verificationErrored() {
      this.$emit('amountsVerified', false);
    },

    validateDeposit(value: string) {
      if (!value) return true;

      const numericCentValue = Number(value);

      if (numericCentValue > 49) {
        return 'Amount cannot be greater than $0.49';
      }

      if (numericCentValue < 1) {
        return 'Amount must be greater than $0.00';
      }

      return true;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/mixins";

.verify-deposit {
  &__msg {
    width: 100%;
    padding: 1rem 1rem 0 1rem;

    .split-payment-text p {
      font-size: 0.875rem;
      color: var(--grayscale-color-1);
    }

    p {
      font-size: 1rem;

      &:last-child {
        margin-bottom: 0 !important;
      }

      span {
        color: var(--alert-color);
      }
    }
  }

  &__deposit-inputs {
    box-shadow: none !important;
    padding: 1rem 0;
  }
}
</style>
