import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44efc8fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "payment-type-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_input = _resolveComponent("checkbox-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentTypes, (paymentType, index) => {
      return (_openBlock(), _createBlock(_component_checkbox_input, {
        key: index,
        "data-test": paymentType,
        density: "compact",
        "hide-details": "",
        disabled: _ctx.disabled,
        class: "checkbox--reverse-layout",
        "model-value": _ctx.selected.includes(paymentType),
        "onUpdate:modelValue": ($event: any) => (_ctx.checkboxInput(paymentType, $event))
      }, {
        label: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(paymentType), 1)
        ]),
        _: 2
      }, 1032, ["data-test", "disabled", "model-value", "onUpdate:modelValue"]))
    }), 128)),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, " At least one Payment Type is required. "))
      : _createCommentVNode("", true)
  ]))
}