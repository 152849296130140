import { createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "mb-6",
  "data-test": "unfrozenCreditLabel"
}
const _hoisted_3 = {
  key: 0,
  class: "mb-6",
  "data-test": "clickApplyLabel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_credit_freeze_error = _resolveComponent("credit-freeze-error")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isCreditStillFrozen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, [
            (_ctx.isSoftFreeze)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(" To continue your application, please confirm you have unfrozen your credit. ")
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(" To finish your application, please confirm you have unfrozen your credit. ")
                ], 64))
          ]),
          (!_ctx.isSoftFreeze)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, " By clicking \"Apply\" you are making a credit application and we will pull your credit report and it may impact your credit score. "))
            : _createCommentVNode("", true),
          _createVNode(_component_custom_button, {
            class: "mb-6",
            "full-width": "",
            onClick: _ctx.onConfirm
          }, {
            default: _withCtx(() => [
              (_ctx.isSoftFreeze)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" Yes, view My Offers ")
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" Yes, Apply ")
                  ], 64))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_custom_button, {
            variant: "secondary",
            "full-width": "",
            onClick: _ctx.onCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(" No, I Need To Unfreeze My Credit ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : (_openBlock(), _createBlock(_component_credit_freeze_error, {
          key: 1,
          "expiration-date": _ctx.invitationExpirationDate
        }, null, 8, ["expiration-date"]))
  ]))
}