<template>
  <standard-dialog
    :dialog="show"
    :show-cancel-cta="false"
    :show-actions="false"
    :on-cancel="emitClose"
    :hide-header-close="true">
    <template #body>
      <p>
        On this step, be sure to enter your <strong>Annual Income.</strong>
        This is your total <u>yearly</u> income prior to taxes and deductions.
      </p>

      <div v-if="showReadMore" class="read-more">
        <span>Income sources include:</span>
        <ul>
          <li>Wages</li>
          <li>Investment Income</li>
          <li>Social Security</li>
          <li>Pensions</li>
          <li>Alimony</li>
          <li>Agency Programs</li>
          <li>Rental Income</li>
          <li>Retirement Disbursements (not just interest/dividends)</li>
        </ul>
      </div>

      <div class="actions">
        <custom-button
          v-if="!showReadMore"
          data-test="openReadMoreBtn"
          size="small"
          variant="secondary"
          @click="showReadMore = true">
          Read more
        </custom-button>

        <custom-button
          class="close-btn"
          data-test="inputHelperModalCta"
          size="small"
          :full-width="showReadMore"
          @click="emitClose">
          Close
        </custom-button>
      </div>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InfoDialog',
  components: {
    CustomButton,
    StandardDialog,
  },
  props: {
    show: { default: false, type: Boolean },
  },
  data() {
    return {
      showReadMore: false,
    };
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  button {
    width: 6rem;

    &.close-btn {
      margin-left: auto;
    }
  }
}

.read-more ul {
  padding-left: 1.5rem;
  list-style: disc;
}
</style>
