import { AuthStateInterface } from '@/interfaces/states/AuthStateInterface';
import { NewUserInvitationTokenDataInterface } from '@/interfaces/auth/NewUserInvitationTokenDataInterface';
import { GroupInterface } from '@/interfaces/auth/GroupInterface';

export function setSessionId(state: AuthStateInterface, sessionId: string) {
  state.sessionId = sessionId;
}

export function setUser(state: AuthStateInterface, user: any) {
  state.user = user;
}

export function setUserId(state: AuthStateInterface, payload: any) {
  state.user_id = payload;
}

export function setMerchant(state: AuthStateInterface, merchant: any) {
  state.merchant = merchant;
  state.merchantUuid = merchant.uuid || merchant.merchantUid || merchant.sponsorId;
  if (merchant.uuid) {
    localStorage.setItem('merchantUuid', merchant.uuid || merchant.merchantUid);
  } else if (merchant.sponsorId) {
    localStorage.setItem('merchantUuid', merchant.sponsorId);
  } else {
    localStorage.removeItem('merchantUuid');
  }
}

export function setGroup(state: AuthStateInterface, group: GroupInterface) {
  state.group = [group];
}

export function setNewUserInviteTokenData(
  state: AuthStateInterface,
  payload: NewUserInvitationTokenDataInterface,
) {
  state.newUserInviteTokenData = payload;
}

export function setLoggingOut(state: AuthStateInterface, loggingOut: boolean) {
  state.loggingOut = loggingOut;
}

export function setGucaData(state: AuthStateInterface, payload: any) {
  state.gucaData = payload;
}
