import GetHumanReadableIdLabelMixin from '@/mixins/GetHumanReadableIdLabelMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [GetHumanReadableIdLabelMixin],
  computed: {
    /**
     * Formatting Merchant readable Uuid label,
     * for displaying in template
     */
    consumerHumanReadableApplicationIdLabel(): string {
      return this.getHumanReadableIdLabel(this.consumerHumanReadableApplicationId, false);
    },
    /**
     * Store getter for Consumer Application human readable Uuid
     */
    consumerHumanReadableApplicationId(): any {
      return this.$store.getters['Consumer/getConsumerHumanReadableApplicationId'];
    },
  },
});
