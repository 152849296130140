<template>
  <div class="tab-navigation-content">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, provide, toRef } from 'vue';

export default defineComponent({
  props: {
    value: { type: String, required: true },
  },

  setup(props) {
    const selectedTab = toRef(props, 'value');

    provide('selectedTab', selectedTab);
  },
});
</script>

<style lang="scss" scoped>
.tab-navigation-content {
  background-color: var(--grayscale-color-3);
  padding: 0.5rem;
  height: 100%;

  @media screen and (max-width: 600px) {
    padding: 1rem 0.5rem;
  }
}
</style>
