import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-data-table__td" }
const _hoisted_2 = { class: "v-data-table__td-title" }
const _hoisted_3 = { class: "v-data-table__td-value" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createElementBlock("td", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.header), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.privateValue)
        ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createTextVNode(_toDisplayString(_ctx.value), 1)
          ])), [
            [_directive_private]
          ])
        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.value), 1))
    ])
  ]))
}