import { ActionContext } from 'vuex';
import { OtpRegisterRequestDataInterface } from '@/interfaces/otp/OtpRegisterRequestDataInterface';
import { OtpStateInterface } from '@/interfaces/states/OtpStateInterface';
import { register } from '@/api/otp';
import { StoreActionInterface } from '@/interfaces/StoreActionResponse';
import { getAxiosError } from '@/api/utils';

export async function setOtpEnabled(
  context: ActionContext<OtpStateInterface, any>,
  enabled: boolean,
) {
  context.commit('setOtpEnabled', enabled);
}

export async function setPhoneResendEnabled(
  context: ActionContext<OtpStateInterface, any>,
  enabled: boolean,
) {
  context.commit('setPhoneResendEnabled', enabled);
}

export async function otpRegister(
  context: ActionContext<OtpStateInterface, any>,
  payload: OtpRegisterRequestDataInterface,
): Promise<StoreActionInterface> {
  try {
    const { data } = await register(payload);
    context.commit('setPhoneResendEnabled', true);
    context.commit('setOtpRegisterSessionToken', data.session_token);
    return { data };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
      context.commit('setPhoneResendEnabled', axiosError.response?.status !== 417);
    }
    return { error };
  }
}

export async function setOtpSessionToken(
  context: ActionContext<OtpStateInterface, any>,
  sessionToken: string,
) {
  context.commit('setOtpRegisterSessionToken', sessionToken);
}
