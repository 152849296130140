import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-90caf644"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "simple-card__body-text" }
const _hoisted_2 = { class: "body-text-data" }
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = { class: "font-weight-bold" }
const _hoisted_5 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_standard_dialog = _resolveComponent("standard-dialog")!

  return (_openBlock(), _createBlock(_component_standard_dialog, {
    dialog: _ctx.dialog,
    persistent: true,
    "on-cancel": _ctx.onCancel,
    title: "Please confirm"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, " You would like to delete Owner " + _toDisplayString(_ctx.ownerPosition) + ": ", 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.ownerName) + " - " + _toDisplayString(_ctx.ownerPercentage) + "% Owner ", 1)
        ])
      ])
    ]),
    actions: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_custom_button, {
          "full-width": "",
          "data-test": "confirmBtn",
          disabled: _ctx.disableButtonsInModal,
          onClick: _ctx.onCta
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Delete ")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createVNode(_component_custom_button, {
          "full-width": "",
          variant: "secondary",
          class: "cancel-btn",
          "data-test": "cancelBtn",
          disabled: _ctx.disableButtonsInModal,
          onClick: _ctx.onCancel
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Cancel ")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["dialog", "on-cancel"]))
}