<template>
  <standard-dialog
    dialog
    wrapped-action-buttons
    width="420"
    :on-cancel="() => $emit('close')"
    @close="$emit('close')">
    <template #body>
      <p>
        This user is already a part of your merchant group at another location.
        Would you like to give <strong>{{ email }}</strong> access to this location?
      </p>
    </template>

    <template #actions>
      <custom-button
        full-width
        :disabled="processing"
        @click="$emit('continue')">
        Continue
      </custom-button>
      <custom-button
        variant="secondary"
        full-width
        @click="$emit('close')">
        Cancel
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardDialog from '@/components/Dialogs/index.vue';

export default defineComponent({
  name: 'InviteExistingUserModal',
  components: {
    CustomButton,
    StandardDialog,
  },
  props: {
    email: { type: String, required: true },
    processing: { type: Boolean, default: false },
  },
});
</script>
