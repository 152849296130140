<template>
  <div :class="classListContainer">
    <v-progress-circular
      v-if="showCircular"
      :size="50"
      class="progress-indicator progress-indicator--circular"
      data-test="progressCircular"
      indeterminate />

    <v-progress-linear
      v-else-if="showLinear"
      :active="true"
      :bg-opacity="0.3"
      :buffer-value="100"
      :height="4"
      :indeterminate="true"
      :rounded="false"
      :stream="false"
      :striped="false"
      :model-value="0"
      class="progress-indicator progress-indicator--linear"
      data-test="progressLinear" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PROGRESS_INDICATOR_TYPES from '@/constants/StandardProgressIndicator';

export default defineComponent({
  name: 'ProgressIndicator',

  props: {
    type: { type: String, default: PROGRESS_INDICATOR_TYPES.linear },
  },

  computed: {
    classListContainer() {
      const classList: Array<string> = [
        'progress-indicator-container',
      ];

      if (this.showLinear) {
        classList.push('progress-indicator-container--linear');
      }

      if (this.showCircular) {
        classList.push('progress-indicator-container--circular');
      }

      return classList.join(' ');
    },

    showLinear(): boolean {
      return this.$props.type === PROGRESS_INDICATOR_TYPES.linear;
    },

    showCircular(): boolean {
      return this.$props.type === PROGRESS_INDICATOR_TYPES.circular;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";

.progress-indicator-container {
  &--linear {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    :deep() {
      .v-progress-linear {
        &__background {
          background-color: var(--primary-color) !important;
          border-color: var(--primary-color) !important;
        }

        &__indeterminate {
          .short,
          .long {
            background-color: var(--secondary-color) !important;
            border-color: var(--secondary-color) !important;
          }
        }
      }
    }
  }

  &--circular {
    padding: 3rem 0;
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;
    color: var(--grayscale-color-1) !important;
  }
}

</style>
