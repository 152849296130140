<template>
  <p class="fs-unmask mt-4">
    The Federal Equal Credit Opportunity Act (“ECOA”) prohibits creditors from
    discriminating against credit applicants on the basis of race, color, religion,
    national origin, sex, marital status, age (provided the applicant has the capacity
    to enter into a binding contract); because all or part of the applicant's income derives
    from any public assistance program; or because the applicant has in good faith exercised
    any right under the Consumer Credit Protection Act. The Federal agency that administers
    compliance with ECOA concerning Momnt Technologies, Inc. is the Federal Trade Commission
    (“FTC”). You may contact the FTC at Federal Trade Commission, Consumer Response Center,
    600 Pennsylvania Avenue NW, Washington, DC 20580. The Federal agency that administers
    compliance with ECOA concerning national banks is the Office of the Comptroller of the
    Currency (“OCC”). You may contact the OCC at Customer Assistance Group, P.O. Box 53570,
    Houston, TX 77052. The Federal agency that administers compliance with ECOA concerning
    any state bank that is not a member of the Federal Reserve is the Federal Deposit Insurance
    Corporation (“FDIC”). You may contact the FDIC at Division of Depositor and Consumer
    Protection, National Center for Consumer and Depositor Assistance, Federal Deposit
    Insurance Corporation, 1100 Walnut Street, Box #11, Kansas City, MO 64106. For state
    member banks, branches, and agencies of foreign banks (other than Federal branches,
    Federal agencies, and insured state branches of foreign banks), commercial lending
    companies owned or controlled by foreign banks, and organizations operating under
    section 25 or 25A of the Federal Reserve Act, you may contact the Federal Reserve
    Consumer Help Center, P.O. Box 1200, Minneapolis, MN 55480.
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AANNotice',
});
</script>
