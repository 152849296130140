<template>
  <div>
    <div v-if="initialCharge">
      <v-form class="customer-summary-card-send" @submit.prevent>
        <currency-input
          v-model="amount"
          allow-cents
          data-test="inputAmount"
          class="amount-input"
          :error-messages="customErrorMessage.amount"
          label="Payment Request"
          :max="maxChargeValue"
          :min="0"
          :show-learn-more="showLearnMoreButton"
          show-icon>
          <div v-if="showLearnMoreButton" class="align-start d-flex justify-space-between">
            <div>
              {{ customErrorMessage.amount[0] }}
            </div>
            <custom-button
              variant="text"
              size="small"
              class="align-start px-0"
              style="font-size: 0.75rem;"
              @click="showLearnMore">
              Learn More
            </custom-button>
          </div>
        </currency-input>

        <custom-button
          v-if="!secondCharge"
          class="mb-4"
          data-test="sendButton"
          type="submit"
          :disabled="buttonDisabled"
          @click="emitValueShowConfirmation">
          Next
        </custom-button>

        <div v-else class="form-buttons">
          <custom-button variant="secondary" @click="$emit('cancelTransaction')">
            Cancel
          </custom-button>

          <custom-button :disabled="buttonDisabled" @click="emitValueShowConfirmation">
            Send Payment Request
          </custom-button>
        </div>
      </v-form>
    </div>

    <div v-if="paymentConfirmation" class="confirmation-section">
      <div class="confirmation-section-title">
        Please Confirm:
      </div>

      <div class="confirmation-section-message">
        You are requesting
        {{ chargeName === 'Initial Charge' ? 'an' : 'a' }}
        {{ chargeName }} of {{ formattedChargeValue }} from {{ consumerName }}.
      </div>

      <custom-button
        data-test="sendButton"
        full-width
        :disabled="disableSendButton"
        @click="$emit('sendPaymentRequest')">
        Send Payment Request
      </custom-button>
    </div>

    <div
      v-if="transactionPending"
      :class="[
        transactionStatus === 'Pending' ? 'transaction-pending-message' : '',
        transactionStatusErrored ? 'transaction-errored-message' : '']">
      <div v-if="transactionStatus === 'Pending'">
        {{ chargeName }} of {{ formattedMaxCharge }}, sent {{ formattedChargeDate }}
      </div>

      <div v-else-if="transactionStatus === 'Expired'">
        <div>
          {{ chargeName }} of {{ formattedMaxCharge }}, sent {{ formattedChargeDate }}
        </div>

        <custom-button
          class="mt-5"
          data-test="sendButton"
          size="small"
          full-width
          @click="$emit('showInitialForm')">
          Send New Payment Request
        </custom-button>
      </div>

      <div v-else-if="transactionStatus === 'Errored'" class="errored-section">
        <div class="mb-4">
          This failed due to a technical error. Please try again.
        </div>

        <div class="font-weight-bold mb-6">
          {{ chargeName }} of {{ formattedMaxCharge }}, sent {{ formattedChargeDate }}
        </div>

        <custom-button
          class="mb-4"
          data-test="sendButton"
          size="small"
          full-width
          @click="$emit('showInitialForm')">
          Send New Payment Request
        </custom-button>

        <div>
          <i>Contact
            <span class="support-email-address">
              {{ footerData.support_email }}
            </span>
            if this problem persists.
          </i>
        </div>
      </div>
    </div>

    <div v-if="secondConfirmation">
      <p class="second-confirmation-text">
        {{ confirmationText }}
      </p>

      <div class="second-confirmation-buttons">
        <custom-button variant="secondary" @click="$emit('cancelTransaction')">
          Cancel
        </custom-button>

        <custom-button :disabled="disableSendButton" @click="$emit('proceed')">
          Next
        </custom-button>
      </div>
    </div>

    <div v-if="completedTransaction">
      <completed-transaction :all-completed-transactions="allCompletedTransactions" />
    </div>

    <div
      v-if="finalChargeQuestion"
      class="final-charge-question">
      <div>
        Will this be the final charge?
      </div>

      <div class="final-charge-buttons">
        <custom-button
          variant="text"
          size="small"
          @click="$emit('notFinalCharge')">
          No
        </custom-button>
        <custom-button
          variant="text"
          size="small"
          class="ml-4"
          @click="$emit('yesFinalCharge')">
          Yes
        </custom-button>
      </div>
    </div>

    <learn-more-dialog
      :dialog="showLearnMoreDialog"
      @closeModal="hideLearnMore"
      @sendFinalCharge="sendFinalCharge" />
  </div>
</template>

<script lang="ts">
import CurrencyInput from '@/components/Inputs/Currency.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import { ErrorsInterface } from '@/interfaces/ui/ErrorsInterface';
import GetProcessing from '@/mixins/GetProcessing';
import CompletedTransaction from '@/components/CustomerSummaryCard/CompletedTransaction.vue';
import StagedFundingMixin from '@/mixins/StagedFundingMixin';
import LearnMoreDialog from '@/components/Dialogs/SFLearnMoreDialog.vue';
import DATE_CONSTANTS from '@/constants/DateConstants';
import moment from 'moment';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ChargeBody',
  components: {
    CurrencyInput,
    CustomButton,
    CompletedTransaction,
    LearnMoreDialog,
  },
  mixins: [GetProcessing, StagedFundingMixin],
  props: {
    disableSendButton: { type: Boolean, default: false },
    secondCharge: { type: Boolean, default: false },
    chargeValue: { type: String, default: '' },
    chargeDate: { type: String, default: '' },
    consumerName: { type: String, default: '' },
    confirmationText: { type: String, default: '' },
    formattedMaxCharge: { type: String, default: '' },
    allCompletedTransactions: { type: Array, default: () => [] },
  },
  data() {
    return {
      amount: '',
      showLearnMoreDialog: false,
    };
  },
  computed: {
    selectedLoanApplication() {
      return this.$store.getters['MerchantPortal/getSelectedLoanApplication'];
    },
    customErrorMessage(): ErrorsInterface {
      const errors: ErrorsInterface = {};

      if (this.chargeOverMax) {
        errors.amount = [`Amount cannot exceed ${CurrencyFormatLong(this.maxChargeValue)}`];
      }

      return errors;
    },
    buttonDisabled(): boolean {
      return !this.amount || this.chargeOverMax;
    },
    chargeOverMax(): boolean {
      return (Number(this.amount) > this.maxChargeValue);
    },
    footerData(): any {
      return this.$store.getters['Ui/getFooterData'] || {};
    },
    enteredMaxMiddleCharge(): boolean {
      if (this.chargeName !== 'Initial Charge' && this.chargeName !== 'Final Charge') {
        if ((Number(this.amount) < this.creditBalance) && this.chargeOverMax) {
          return true;
        }
      }
      return false;
    },
    creditBalance(): number {
      return Number(this.selectedLoanApplication.consumer_account.available_spend);
    },
    formattedChargeDate(): string {
      return `${moment(this.chargeDate).format(DATE_CONSTANTS.dateFormatSlashes)} |
      ${moment(this.chargeDate).format(DATE_CONSTANTS.shortTimeFormat)}`;
    },
    showLearnMoreButton(): boolean {
      return this.enteredMaxMiddleCharge;
    },
    formattedChargeValue(): string {
      return CurrencyFormatLong(this.chargeValue);
    },
  },
  methods: {
    sendFinalCharge() {
      this.$emit('sendFinalCharge');
      this.showLearnMoreDialog = false;
    },
    emitValueShowConfirmation() {
      this.$emit('showConfirmation', this.amount);
    },
    showLearnMore() {
      this.showLearnMoreDialog = true;
    },
    hideLearnMore() {
      this.showLearnMoreDialog = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.v-icon:hover {
  cursor: pointer;
}

.customer-summary-card-send {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}

.amount-input {
  margin: 0 !important;

  :deep(.v-text-field__details) {
    margin-bottom: 1.125rem !important;
  }
}

.confirmation-section {
  padding: 0rem 1rem 1rem 1rem;
  text-align: left;
  color: var(--grayscale-color-1);
  font-size: 1rem;

  .confirmation-section-title {
    font-weight: bold;
  }

  .confirmation-section-message {
    margin-bottom: 1.5rem;
    line-height: 1.5rem;
  }
}

.transaction-pending-message {
  text-align: left;
  color: var(--grayscale-color-1);
  font-size: 1rem;
  padding: 0 1rem 5rem 1rem;
}

.transaction-errored-message {
  text-align: left;
  color: var(--grayscale-color-1);
  font-size: 1rem;
  padding: 0 1rem 1rem 1rem;
}

.errored-section {
  font-size: 0.875rem;

  .support-email-address {
    font-weight: bold;
    color: var(--primary-color);
  }
}

.second-confirmation-text {
  text-align: left;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  font-size: 1rem;
}

.second-confirmation-buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem 1rem;

  .custom-button {
    width: 6.25rem;
    max-width: 6.25rem;
  }
}

.final-charge-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
  font-size: 1rem;
}

.final-charge-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep(.custom-button .v-btn__content) {
    color: var(--grayscale-color-1);
    border: 1px solid #C4CBD2;
    border-radius: 4px;
    padding: 0.5rem;
  }
}

:deep(.error--text .v-messages__message) {
  color: var(--error-color);
  padding: 0 !important;
}
</style>
