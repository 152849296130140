<template>
  <v-btn
    v-bind="$attrs"
    class="custom-button"
    :class="classList"
    :icon="variant === 'icon'"
    :variant="btnVariant"
    :data-test="dataTestAttr"
    :type="type">
    <slot>
      <span>{{ label }}</span>
    </slot>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CustomButton',

  props: {
    /**
     * Size of the button. Can be 'large' or 'small'.
     */
    size: {
      type: String,
      default: 'large',
      validator: (value: string) => ['large', 'small'].includes(value),
    },

    /**
     * Variant of the button. Can be 'primary', 'secondary', 'text', 'icon', 'link'.
     */
    variant: {
      type: String,
      default: 'primary',
      validator: (value: string) => ['primary', 'secondary', 'text', 'icon', 'link', 'flat'].includes(value),
    },

    fullWidth: Boolean,
    dataTest: { type: String, default: '' },
    label: { type: String, default: 'OK' },
    type: { type: String, default: 'button' },
    productIdDataTest: { type: String, default: '' },
    customDataTest: { type: String, default: '' },
  },

  computed: {
    btnVariant() {
      switch (this.variant) {
        case 'secondary': return 'outlined';
        case 'text': return 'plain';
        case 'icon': return 'plain';
        default: return 'flat';
      }
    },

    classList() {
      const variantClass = `button-${this.variant}`;
      const sizeClass = `button-${this.size}`;
      const width = this.fullWidth ? 'full-width' : '';
      return [variantClass, sizeClass, width];
    },

    dataTestAttr(): string {
      if (this.dataTest) return this.dataTest;
      if (this.productIdDataTest && this.customDataTest) return `${this.customDataTest}-${this.productIdDataTest}`;
      return `${this.label.split(' ')[0].toLocaleLowerCase()}Btn`;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/font-variables";

$v-elevation-3: 0 3px 3px -2px rgba(0,0,0,.2),
              0 3px 4px 0 rgba(0,0,0,.14),
              0 1px 8px 0 rgba(0,0,0,.12);

.v-theme--light.v-btn.custom-button {
  font-family: $font-family-serif;
  font-weight: bold;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-transform: capitalize;

  &:before {
    background-color: transparent;
  }

  &:hover:not(&.button-text):not(&.button-link):not(.v-btn--icon) {
    box-shadow: $v-elevation-3 !important;
  }

  &:focus {
    outline: none;
  }

  &.v-btn--disabled:not(.v-btn--variant-text):not(.v-btn--variant-outlined):not(.button-link) {
    background-color: var(--grayscale-color-2) !important;
    color: #FFFFFF !important;
  }

  &.v-btn--disabled.v-btn--variant-outlined {
    color: var(--grayscale-color-2) !important;
    opacity: 1;
  }

  &.button-large {
    height: 2.75rem;
    padding: 0 0.875rem;
    font-size: 1rem;

    &:hover:not(&.button-text):not(&.button-link):not(.v-btn--icon) {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    }
  }

  &.button-small {
    height: 1.875rem;
    padding: 0 0.5rem;
    font-size: 0.875rem;
  }

  &.button-primary {
    color: #FFFFFF;
    background-color: var(--primary-color);
  }

  &.button-secondary {
    border: thin solid;
    color: var(--primary-color);
    background-color: #FFFFFF;
  }

  &.button-text {
    color: var(--primary-color);
    transition: all .1s ease-in-out;

    &:hover {
      background-color: var(--grayscale-color-5);
      box-shadow: none;

      &::before {
        opacity: 0;
      }
    }
  }

  &.button-icon {
    height: 2rem;
    width: 2rem;
    font-size: 0.5rem;
  }

  &.full-width {
    width: 100%;
  }

  &.btn-compact {
    padding: 0;
  }

  &.button-link {
    color: var(--primary-color);
    background-color: transparent;

    &.v-btn--active {
      color: var(--grayscale-color-1);
      background-color: var(--grayscale-color-3);

      &:hover {
        background-color: var(--grayscale-color-3);
      }
    }

    &.v-btn--disabled {
      color: var(--grayscale-color-3);
      background-color: transparent !important;
    }

    &:hover {
      text-decoration: none;
      background-color: var(--grayscale-color-4);
    }
  }

  :deep(.v-btn__overlay) {
    background-color: transparent;
  }
}

.v-btn--variant-plain {
  opacity: 1;
}
</style>
