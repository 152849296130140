import isEmpty from 'lodash/isEmpty';
import { LoanApplicationDataInterface } from '@/interfaces/consumer/loanApply/LoanApplicationDataInterface';
import { LoanApplicationStateInterface } from '@/interfaces/states/LoanApplicationStateInterface';

export function setMerchantId(state: LoanApplicationStateInterface, merchantId: any) {
  state.merchantId = merchantId;
}

export function setLoanApplicationWizardStep(state: LoanApplicationStateInterface, step: number) {
  state.loanApplicationWizardStep = step;
}

export function setLoanApplicationWizardData(
  state: LoanApplicationStateInterface,
  payload: LoanApplicationDataInterface,
) {
  if (isEmpty(payload)) {
    state.loanApplicationWizardData = {};
  } else {
    state.loanApplicationWizardData = {
      ...state.loanApplicationWizardData,
      ...payload,
    };
  }
}
