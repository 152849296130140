<template>
  <div
    class="elevation-1 portal-header__user-info"
    :class="{ 'multi-location': multiLocationActive }">
    <div class="portal-header__user-info__container">
      <template v-if="showSkeleton">
        <v-skeleton-loader width="100%" type="text" />
        <v-skeleton-loader width="100%" type="text" />
      </template>

      <template v-else>
        <div class="portal-header__user-info__details-wrapper">
          <div class="portal-header__user-info__details">
            <span>
              Hello
              <span
                v-private="'Username'"
                class="ml-1 portal-header__user-info__details__name"
                data-test="userNameLabel">
                {{ userNameLabel }}!
              </span>
            </span>
          </div>

          <div class="portal-header__user-info__role">
            <span>
              Role:
              <span data-test="roleLabel" class="ml-1">
                {{ userRoleLabel }}
              </span>
            </span>
          </div>
        </div>

        <div v-if="multiLocationActive" class="portal-header__user-info__location">
          <merchant-location />
        </div>
      </template>
    </div>
    <div class="portal-header__user-info__cta">
      <v-menu location="bottom">
        <template v-slot:activator="{ props }">
          <custom-button
            class="icon-button"
            data-test="mpMenuBtn"
            aria-label="User menu"

            v-bind="props">
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </custom-button>
        </template>
        <v-list class="text-left">
          <v-list-item data-test="resetPassBtn" @click="showResetPasswordDialog">
            <v-list-item-title aria-label="Update Password">
              Update Password
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="multiLocationActive"
            data-test="locationSettingsBtn"
            @click="showLocationSettings">
            <v-list-item-title aria-label="Location Settings">
              Location Settings
            </v-list-item-title>
          </v-list-item>
          <v-list-item data-test="logOutBtn" @click="userLogout">
            <v-list-item-title aria-label="Logout">
              Log Out
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <reset-user-password-modal
      :show="isResetPasswordDialogVisible"
      :on-close="hideResetPasswordDialog" />
    <location-settings-modal
      v-if="locationSettingsVisible"
      @close="locationSettingsVisible = false" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ResetUserPasswordModal
  from '@/components/Merchant/Portal/Header/ResetUserPassword/Modal.vue';
import UserLogOutMixin from '@/mixins/Auth/UserLogOutMixin';
import GotoRouteName from '@/mixins/GotoRouteName';
import MerchantLocation from '@/components/Merchant/Portal/Header/MerchantLocation.vue';
import LocationSettingsModal from '@/components/Merchant/Portal/Header/LocationSettingsModal.vue';
import SharedUserInfoMixin from '@/components/Merchant/Portal/Header/SharedUserInfoMixin';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';

export default defineComponent({
  name: 'UserInfo',
  components: {
    CustomButton,
    LocationSettingsModal,
    MerchantLocation,
    ResetUserPasswordModal,
  },
  mixins: [
    FeatureFlagsMixin,
    GotoRouteName,
    UserLogOutMixin,
    SharedUserInfoMixin,
  ],
  data() {
    return {
      showSkeleton: true,
      locationSettingsVisible: false,
      isResetPasswordDialogVisible: false,
    };
  },
  computed: {
    location() {
      return this.$store.getters['MerchantPortal/getMerchantLocation'];
    },
    merchantLocationsLoaded() {
      if (this.location === false) return true;

      return this.location
        ? !!this.$store.getters['MerchantPortal/getMerchantLocations'].length
        : false;
    },
    multiLocationActive(): boolean {
      if (this.isFeatureEnabled(FeatureFlagsConstants.MULTI_LOCATION)) {
        return this.merchantLocationsLoaded;
      }
      return false;
    },
  },
  watch: {
    user: {
      handler(val: any) {
        if (val.first_name && !this.location) {
          this.showSkeleton = false;
        }
      },
      immediate: true,
    },
    merchantLocationsLoaded: {
      handler(val: any) {
        if (val) {
          this.showSkeleton = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    showResetPasswordDialog() {
      this.isResetPasswordDialogVisible = true;
    },
    hideResetPasswordDialog() {
      this.isResetPasswordDialogVisible = false;
    },
    showLocationSettings() {
      this.locationSettingsVisible = true;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/variables/font-variables";

.portal-header__user-info {
  border-radius: 0 0 $border-radius $border-radius;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: left;
  min-width: 16rem;
  color: var(--grayscale-color-1);

  &__container{
    padding: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__details {
    font-weight: 700;

    &__name {
      width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__details,
  &__role {
    display: flex;
  }

  &__role {
    margin-top: 0.5rem;
  }

  &__location {
    height: 1.125rem;
  }

  &__cta {
    .icon-button {
      height: 100% !important;
      min-width: 3rem !important;
      border-radius: 0;
      padding: 0.5rem !important;
    }
  }

  :deep() {
    .v-skeleton-loader {
      display: inline-block;
      flex-grow: 1;
      margin-top: 0.5rem;

      .v-skeleton-loader__bone {
        height: 1.125rem;
        width: 100%;
        margin-bottom: 0;
      }
    }
  }

  &.multi-location {
    .portal-header__user-info {
      &__container {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        min-width: 20rem;
        padding: 0.5rem 1rem;
      }

      &__details {
        width: fit-content;
        height: fit-content;
        padding-right: 0.5rem;
        border-right: 2px solid var(--grayscale-color-2);

        &-wrapper {
          display: flex;
        }

        &__name {
          max-width: 10rem;
          width: auto;
        }
      }

      &__role {
        max-width: 8rem;
        width: auto;
        margin-left: 0.5rem;
        margin-top: 0;
      }

      &__location {
        width: 100%;
      }
    }
  }
}
</style>
