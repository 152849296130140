<template>
  <standard-dialog
    :dialog="true"
    content-class="change-location-modal"
    @cancel="$emit('close')">
    <template #title>
      <span>Change Primary Location</span>
    </template>

    <template #body>
      <p>
        Your Primary Location is the location you log into by default.
        You can change that below.
      </p>
      <autocomplete-input
        ref="locationInput"
        v-model="location"
        :attach="false"
        :items="merchantLocations"
        clearable
        hide-dropdown-icon
        hide-details
        open-on-clear
        return-object
        placeholder="Start typing location"
        :menu-props="{ maxWidth: menuMaxWidth }"
        item-title="name"
        item-value="uuid" />
    </template>

    <template #actions>
      <custom-button size="small" @click="$emit('close')">
        Cancel
      </custom-button>
      <custom-button
        size="small"
        :disabled="saveDisabled"
        @click="saveSettings">
        Save
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AutocompleteInput from '@/components/Inputs/Autocomplete.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { MerchantLocation } from '@/interfaces/merchant/MerchantLocation';

export default defineComponent({
  name: 'LocationSettingsModal',

  components: {
    AutocompleteInput,
    CustomButton,
    StandardDialog,
  },

  data: () => ({
    location: null as MerchantLocation | null,
    saving: false,
    menuMaxWidth: 500,
  }),

  computed: {
    merchantLocations(): MerchantLocation[] {
      return this.$store.getters['MerchantPortal/getMerchantLocations'];
    },
    saveDisabled(): boolean {
      return this.saving
        || !this.location
        || this.location?.merchant_uuid === this.primaryLocation.merchant_uuid;
    },
    primaryLocation(): MerchantLocation {
      return this.$store.getters['MerchantPortal/getMerchantPrimaryLocation'];
    },
  },

  watch: {
    primaryLocation: {
      immediate: true,
      handler() {
        this.location = this.primaryLocation;
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      const locationInputWidth = (
        this.$refs.locationInput as InstanceType<typeof AutocompleteInput>
      )?.$el?.clientWidth;
      if (locationInputWidth) {
        this.menuMaxWidth = locationInputWidth;
      }
    });
  },

  methods: {
    async saveSettings() {
      this.saving = true;
      const status = await this.$store.dispatch(
        'MerchantPortal/updatePrimaryMerchantLocation',
        this.location,
      );
      this.saving = false;
      if (status === 200) {
        this.$store.dispatch('Ui/addGlobalTimedSuccess', 'Your primary location was updated.');
      } else {
        this.$store.dispatch('Ui/addGlobalTimedError', 'Failed to update primary location.');
      }
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
</style>
