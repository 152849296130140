import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    value: { type: [String, Array, Object, Number], default: '' },
    disabled: { type: Boolean, default: false },
    inPlaceInput: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },

  computed: {
    inputVal: {
      get(): any {
        return this.value;
      },
      set(val: any) {
        this.$emit('input', val);
      },
    },
  },
});
