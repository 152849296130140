<template>
  <v-container
    fluid
    class="fs-unmask merchant__rate-sheets portal__page portal__page--card-full-size">
    <merchant-portal-rate-sheets
      :show-save-or-edit="alertUserWhenLeave"
      @closeModal="alertUserWhenLeave = false"
      @saveButtonDisabled="updateUserEditedData" />
  </v-container>
</template>

<script lang="ts">
import MerchantPortalRateSheets from '@/components/Merchant/Portal/RateSheets/index.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RateSheets',
  components: { MerchantPortalRateSheets },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.userEditedData) {
      this.alertUserWhenLeave = true;
    } else {
      next();
    }
  },
  data() {
    return {
      alertUserWhenLeave: false,
      userEditedData: false,
    };
  },
  methods: {
    updateUserEditedData(val: boolean) {
      this.userEditedData = !val;
    },
  },
});
</script>
