<template>
  <v-menu
    v-if="productId"
    v-model="showMenu"
    :close-on-content-click="false"
    :width="width"
    z-index="201">
    <template #activator="{ props }">
      <button class="consumer-activity-interactable" v-bind="props">
        {{ productId }}
      </button>
    </template>
    <v-card class="fs-unmask product-description-popup">
      <v-card-title class="dialog-popup-title">
        <span>{{ productId }}</span>
        <v-icon tag="button" @click="showMenu = false">
          mdi-close
        </v-icon>
      </v-card-title>

      <v-card-text class="pb-0">
        <div v-if="productDescription" class="mt-2 pa-4 text-grayscale-1 text-left">
          <ul>
            <li v-if="productDescription.top_left_content">
              {{ productDescription.top_left_title }}: {{ productDescription.top_left_content }}
            </li>

            <li v-if="productDescription.top_center_content">
              {{ productDescription.top_center_title }}: {{ productDescription.top_center_content }}
            </li>

            <li v-if="productDescription.top_right_content">
              {{ productDescription.top_right_title }}: {{ productDescription.top_right_content }}
            </li>

            <li v-if="productDescription.mid_left_content">
              {{ productDescription.mid_left_title }}: {{ productDescription.mid_left_content }}
            </li>

            <li v-if="productDescription.mid_center_content">
              {{ productDescription.mid_center_title }}: {{ productDescription.mid_center_content }}
            </li>

            <li v-if="productDescription.mid_right_content">
              {{ productDescription.mid_right_title }}: {{ productDescription.mid_right_content }}
            </li>
          </ul>
        </div>
      </v-card-text>

      <v-card-actions class="bg-white pa-4">
        <custom-button
          size="small"
          full-width
          @click="showMenu = false">
          Close
        </custom-button>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useDisplay } from 'vuetify';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  components: { CustomButton },

  props: {
    productId: { type: String, default: null },
    productDescription: { type: Object, default: null },
  },

  setup() {
    const showMenu = ref(false);
    const { xs } = useDisplay();
    const width = computed(() => {
      return xs.value ? '95vw' : '23.875rem';
    });

    return { showMenu, width };
  },
});
</script>

<style lang="scss" scoped>
.product-description-popup {
  width: 23.875rem;

  ul {
    list-style-type: disc;
  }
}
</style>
