import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07bb032d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "statement-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_statement_details = _resolveComponent("statement-details", true)!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_container, {
      fluid: "",
      class: "portal__container"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_statement_details, {
          "statement-id": _ctx.$route.params.id
        }, null, 8, ["statement-id"])
      ]),
      _: 1
    })
  ]))
}