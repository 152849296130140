import DATE_CONSTANTS from '@/constants/DateConstants';
import moment from 'moment';

const {
  dateFormat, dateFormatIso, dateFormatIsoRegex, dateFormatRegex,
} = DATE_CONSTANTS;

const selectDateFormat = (date: string): string => {
  let selectedDateFormat: string = '';

  if (dateFormatIsoRegex.test(date)) {
    selectedDateFormat = dateFormatIso;
  } else if (dateFormatRegex.test(date)) {
    selectedDateFormat = dateFormat;
  }

  return selectedDateFormat;
};

const isDateValid = (date: string): boolean => {
  const selectedDateFormat: string = selectDateFormat(date);

  if (!selectedDateFormat) {
    return false;
  }

  return moment(date, selectedDateFormat).isValid();
};

const dateToFormat = (date: string, toFormat: string = dateFormat): string => {
  if (!isDateValid(date)) return '';

  return moment(date, selectDateFormat(date)).format(toFormat);
};

const dateToFormatIso = (date: string): string => {
  if (!isDateValid(date)) return '';

  return moment(date, selectDateFormat(date)).format(dateFormatIso);
};

const dateNow = (): string => moment().format();

export {
  dateToFormat,
  dateToFormatIso,
  dateNow,
  selectDateFormat,
  isDateValid,
};
