<template>
  <address class="aan-address">
    <strong>{{ brandData.brand_name }}</strong><br>
    <span
      v-for="(address, i) in serviceAddress"
      :key="i"
      class="aan-address__address">
      {{ address }}
    </span><br>
    <a
      v-fs-exclude
      :href="`mailto:${brandData.footer_object.support_email}`"
      class="aan-address__mail">
      {{ brandData.footer_object.support_email }}
    </a><br>
    <p>{{ brandData.footer_object.support_number }}</p>
  </address>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AANAddress',
  props: {
    brandData: { default: null, type: Object },
  },
  data() {
    return {
      serviceAddress: null,
    };
  },
  watch: {
    brandData: {
      handler() {
        this.serviceAddress = this.brandData.servicing_address.split(',');
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/standard-aan";
</style>
