<template>
  <v-data-table-server
    v-model:page="page"
    disable-sort
    class="fs-unmask minimal-pagination"
    :mobile="isMobile"
    :headers="headers"
    :items="results"
    :items-per-page="5"
    :loading="loading"
    :items-length="count">
    <template v-if="!isMobile" #header.display_status>
      <status-filter-popup />
    </template>

    <template #item.sent_to="{ item }">
      <div :class="{ 'fs-exclude': isPhoneNumber(item.sent_to) }">
        <button
          v-if="hasSummary(item)"
          class="consumer-activity-interactable"
          @click="seeConsumerSummary(item.consumer_application_uuid)">
          {{ item.sent_to }}
        </button>
        <span v-else class="pa-2">{{ item.sent_to }}</span>
      </div>
    </template>

    <template #item.id_number="{ item }">
      <application-summary-popup :id-number="item.id_number" :invitation-id="item.consumer_invitation_uuid" />
    </template>

    <template #item.display_status="{ item }">
      <status-chip-with-description v-if="item.display_status" :status="item.display_status" />
    </template>

    <template #item.amount="{ item }">
      <span v-if="item.amount" class="font-weight-bold">
        {{ CurrencyFormatLong(item.amount) }}
      </span>
    </template>

    <template #item.product_id="{ item }">
      <product-description-popup
        :product-id="item.product_id"
        :product-description="item.product_description_parts" />
    </template>

    <template #loading>
      <table-skeleton-loader :rows="5" :cols="colWidths" />
    </template>
  </v-data-table-server>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import store from '@/store';
import { useDisplay } from 'vuetify';
import { useConsumerActivityTable } from '@/composables/useConsumerActivityTable';
import { getRecentApplications } from '@/api/consumerActivity';
import { RecentApplication, DISPLAY_STATUS_LIST } from '@/interfaces/ConsumerActivity';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import { RequestOptions } from '@/api/types';
import TableSkeletonLoader from '@/components/Tables/TableSkeletonLoader.vue';
import isPhoneNumber from '@/validators/phone_number';
import ApplicationSummaryPopup from './ApplicationSummaryPopup.vue';
import ProductDescriptionPopup from './ProductDescriptionPopup.vue';
import StatusChipWithDescription from './StatusChipWithDescription.vue';
import StatusFilterPopup from './StatusFilterPopup.vue';

export default defineComponent({
  components: {
    ApplicationSummaryPopup,
    ProductDescriptionPopup,
    StatusChipWithDescription,
    StatusFilterPopup,
    TableSkeletonLoader,
  },

  setup() {
    const { xs } = useDisplay();
    const isMobile = xs;

    const headers: TableHeaderInterface[] = [
      {
        title: 'Sent To',
        value: 'sent_to',
      },
      {
        title: 'ID Number',
        value: 'id_number',
        width: '9.5rem',
        align: 'end',
      },
      {
        title: 'Status',
        value: 'display_status',
        width: '10rem',
      },
      {
        title: 'Amount',
        value: 'amount',
        width: '6.25rem',
        align: 'end',
      },
      {
        title: 'Product ID',
        value: 'product_id',
        width: '9.75rem',
        align: 'end',
      },
    ];

    // Subtracting 1.5rem to account for TH padding.
    const colWidths = ['auto', '8rem', '8.5rem', '4.75rem', '8.25rem'];

    const merchantId = store.getters['MerchantPortal/getMerchantUuid'];
    const request = (options: RequestOptions) => getRecentApplications(merchantId, options);
    const data = useConsumerActivityTable<RecentApplication>(request);

    const hasSummary = (item: RecentApplication) => {
      switch (item.display_status) {
        case DISPLAY_STATUS_LIST.FINALIZED:
        case DISPLAY_STATUS_LIST.CAN_TRANSACT:
        case DISPLAY_STATUS_LIST.CHARGES_LIMITED:
          return true;
        default:
          return false;
      }
    };

    return {
      ...data,
      headers,
      colWidths,
      hasSummary,
      CurrencyFormatLong,
      isPhoneNumber,
      isMobile,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table-default.scss';
@import '@/assets/scss/consumer-activity-table.scss';

:deep() .v-data-table__tr--mobile .status-chip {
  margin-left: auto;
}
</style>
