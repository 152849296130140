<template>
  <standard-dialog
    :dialog="dialog"
    :cancel-disabled="processing"
    :cta-disabled="processing || disabled"
    :no-header-border="true"
    :on-cta="cancelApplications"
    :show-cancel-cta="false"
    :show-progress-indicator="processing"
    :hide-header="true"
    :on-cancel="onCancel"
    :persistent="true"
    button-label="Proceed">
    <template #body>
      <div class="simple-card__body-text" data-test="existingModal">
        <v-row>
          <v-col>
            <div>
              <span>
                It appears you had another application in progress.
              </span>

              <p class="simple-card__body-text">
                By proceeding you cancel all other application and begin a new one.
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import StandardDialog from '@/components/Dialogs/index.vue';
import GetExistingApplicationsMixin from '@/mixins/Consumer/LoanApply/GetExistingApplicationsMixin';
import GetProcessing from '@/mixins/GetProcessing';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'ExistingApplicationsModal',
  components: {
    StandardDialog,
  },
  mixins: [
    GetProcessing,
    GetExistingApplicationsMixin,
  ],
  props: {
    dialog: { type: Boolean, default: false },
    onCta: { type: Function, default: () => null },
    onCancel: { type: Function as PropType<() => void>, default: () => null },
  },
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    cancelApplication(applicationId: string) {
      return this.$store.dispatch('Consumer/cancelApplication', applicationId);
    },
    async cancelApplications() {
      this.disabled = true;
      const promises = this.existingApplications.map(it => this.cancelApplication(it));
      await Promise.all(promises);

      await this.onCta();
      await this.onCancel();

      this.disabled = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.simple-card__body-text {
  text-align: left;
  color: var(--grayscale-color-1);
  font-size: 1rem;
}
</style>
