import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _directive_mask = _resolveDirective("mask")!

  return _withDirectives((_openBlock(), _createBlock(_component_v_text_field, _mergeProps(_ctx.$attrs, {
    autocomplete: "off",
    inputmode: "numeric",
    "data-test": _ctx.dataTestAttr,
    label: _ctx.labelAttr,
    variant: _ctx.inPlace ? 'plain' : 'outlined',
    required: _ctx.required,
    rules: [_ctx.validatePercentage],
    "validate-on": "blur"
  }), null, 16, ["data-test", "label", "variant", "required", "rules"])), [
    [_directive_mask, _ctx.inputMask]
  ])
}