enum SocureDocVStatuses {
    WAITING_FOR_USER_REDIRECT = 'DocV Waiting For User to Redirect',
    OPENED = 'DocV Opened',
    DOC_FRONT_UPLOADED = 'DocV Doc Front Uploaded',
    DOC_BACK_UPLOADED = 'DocV Doc Back Uploaded',
    SELFIE_UPLOADED = 'DocV Selfie Uploaded',
    DOCS_UPLOADED = 'DocV Docs Uploaded',
    VERIFICATION_COMPLETE = 'DocV Verification Complete',
    SESSION_COMPLETE = 'DocV Session Complete',
    DOCV_SKIPPED = 'DocV Skipped',
    DOCV_EXPIRED = 'DocV Session Expired',
}

export default SocureDocVStatuses;
