import { getConsumerBranding, getMerchantBranding } from '@/api/branding';
import { ActionContext } from 'vuex';
import { GlobalAlertIface } from '@/interfaces/GlobalAlertInterface';
import { StoreActionInterface } from '@/interfaces/StoreActionResponse';
import { UiStateInterface } from '@/interfaces/states/UiStateInterface';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';

let idleTimeout: any;

let globalAlertsCurrentId = 0;

export function setErrors(context: ActionContext<UiStateInterface, any>, errors: any) {
  context.commit('setErrors', errors);
}

export async function startIdleTimeout(
  context: ActionContext<UiStateInterface, any>,
  timeoutDelay = 600000,
) {
  idleTimeout = setTimeout(() => context.commit('setShowIdleTimeoutModal', true), timeoutDelay);
}

export async function clearIdleTimeout(context: ActionContext<UiStateInterface, any>) {
  context.commit('setShowIdleTimeoutModal', false);
  clearTimeout(idleTimeout);
}

export async function restartIdleTimeout(context: ActionContext<UiStateInterface, any>) {
  context.dispatch('clearIdleTimeout');
  context.dispatch('startIdleTimeout');
}

export async function getMerchantBrandingData(
  context: ActionContext<UiStateInterface, any>,
): Promise<StoreActionInterface> {
  return getBrandingData(context, getMerchantBranding);
}

export async function getConsumerBrandingData(
  context: ActionContext<UiStateInterface, any>,
): Promise<StoreActionInterface> {
  return getBrandingData(context, getConsumerBranding);
}

async function getBrandingData(context: ActionContext<UiStateInterface, any>, apiFn: Function) {
  try {
    const { data } = await apiFn();
    context.commit('setBrandingData', data);
    if (data?.sponsor_uuid != null) context.commit('Sponsor/setSponsorId', data.sponsor_uuid, { root: true });
    return { data };
  } catch (error: unknown) {
    return { error };
  }
}

export async function resetErrorState(context: ActionContext<UiStateInterface, any>) {
  context.commit('resetErrors');
}

export function addGlobalAlert(
  context: ActionContext<UiStateInterface, any>,
  alert: GlobalAlertIface,
): GlobalAlertIface {
  const id = globalAlertsCurrentId + 1;
  alert = { ...alert, id };
  context.commit('addGlobalAlert', alert);
  globalAlertsCurrentId = id;
  return alert;
}

export function removeGlobalAlert(
  context: ActionContext<UiStateInterface, any>,
  alertId: number,
) {
  context.commit('removeGlobalAlert', alertId);
}

export function addGlobalTimedSuccess(
  context: ActionContext<UiStateInterface, any>,
  text: string,
) {
  return context.dispatch('addGlobalAlert', {
    text,
    type: GlobalAlertTypes.SUCCESS,
    timed: true,
  });
}

export function addGlobalTimedError(
  context: ActionContext<UiStateInterface, any>,
  text: string = 'An error occurred',
) {
  return context.dispatch('addGlobalAlert', {
    text,
    type: GlobalAlertTypes.ERROR,
    timed: true,
  });
}

export function addGlobalTimedWarning(
  context: ActionContext<UiStateInterface, any>,
  text: string,
) {
  return context.dispatch('addGlobalAlert', {
    text,
    type: GlobalAlertTypes.WARNING,
    timed: true,
  });
}
