import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createBlock(_component_custom_button, {
        key: 0,
        variant: "link",
        onClick: _ctx.onClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(" LearnPro ")
        ]),
        _: 1
      }, 8, ["onClick"]))
    : _createCommentVNode("", true)
}