<template>
  <standard-dialog
    width="600"
    :dialog="showDialog"
    :show-actions="false"
    :persistent="true"
    :hide-header-close="true"
    :update-title-bcg-color="true">
    <template #title>
      <h5 class="dialog-title mb-0 mt-0">
        {{ brandingData.brand_name }}+ is back!
      </h5>
    </template>

    <template #body>
      <div class="dialog-paragraph mt-5">
        <p class="mb-0">
          <span class="brand-name">
            {{ brandingData.brand_name }}+
          </span>
          is a product family that gives you access to
          additional loan products to reach more customers with attractive loan offers.
        </p>
      </div>

      <div class="dialog-paragraph">
        <p class="mb-0">
          It’s a seamless second-look experience that will
          increase your approval rates and help you win more jobs.
        </p>
      </div>

      <div class="dialog-paragraph">
        <p class="mb-0">
          Add
          <span class="brand-name">
            {{ brandingData.brand_name }}+
          </span>
          to your rate sheet today!
        </p>
      </div>

      <custom-button full-width @click="$emit('ratesheetPage')">
        Take Me To My Rate Sheet
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { UiStoreBrandingData } from '@/interfaces/states/UiStoreBrandingData';

export default defineComponent({
  name: 'HomeImprovementDialog',
  components: {
    StandardDialog,
    CustomButton,
  },
  props: {
    showDialog: { type: Boolean, default: false },
  },
  computed: {
    brandingData(): UiStoreBrandingData {
      return this.$store.getters['Ui/getBrandingObject'];
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.dialog-title {
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--grayscale-color-1);
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  background-color: var(--grayscale-color-5) !important;
}

.dialog-paragraph {
  margin-bottom: 2rem;

  .brand-name {
    font-weight: 700;
  }
}
</style>
