<template>
  <standard-wizard-message>
    <template #body>
      We are unable to process your application at this time.
      Please ask your merchant to send a new application and try again.
    </template>
  </standard-wizard-message>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardWizardMessage from '@/components/Wizard/Message.vue';

export default defineComponent({
  name: 'NoCreditInfoError',
  components: { StandardWizardMessage },
});
</script>
