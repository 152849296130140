<template>
  <div class="standard-error-page">
    <v-container>
      <h4>Sorry!</h4>

      <p>Page not found</p>
    </v-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageNotFound',
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-error-pages";

</style>
