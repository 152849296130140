export default {
  CURRENTLY_ONBOARDING: 'currentlyOnboarding',
  ONBOARDING: {
    COB: 'cob',
    MOB: 'mob',
  },
  SHOW_ADMIN_VERIFY_MODAL: 'showAdminVerifyModal',
  COB_INVITATION_UUID: 'cob_invitation_uuid',
  NEW_USER_EMAIL: 'newUserEmail',
  MOB_ONBOARDING_CODE: 'mob_onboarding_code',
  MOB_LAST_ACTIVITY: 'mob_last_activity',
  LOCATION_UUID: 'merchantLocationUuid',
};
