<template>
  <v-autocomplete
    ref="select"
    v-bind="$attrs"
    :menu-props="{ attach }"
    autocomplete="disabled"
    auto-select-first
    :menu-icon="appendIcon"
    :data-test="dataTestAttr"
    :disabled="disabled"
    :items="items"
    :item-title="itemText"
    :item-value="itemValue"
    :label="labelAttr"
    :variant="variantAttr"
    :rules="[isRequired]"
    :search="searchInput"
    validate-on="blur"
    @update:search="updateSearchInput"
    @update:model-value="emitModelValue">
    <template #selection="{ item }">
      <span v-private="'State'" class="v-select__selection">{{ item.title }}</span>
    </template>

    <template #item="{ item, props }">
      <v-list-item v-bind="itemProps(props)">
        <v-list-item-title v-private="`Select item in ${labelAttr}`">
          <autocomplete-item v-bind="highlightSearch(item.title)" />
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import AutocompleteMixin from '@/components/Inputs/AutocompleteMixin';
import AutocompleteItem from '@/components/Inputs/AutoComplete/AutocompleteItem.vue';

export default defineComponent({
  name: 'AutocompleteInput',

  components: { AutocompleteItem },

  mixins: [InputFieldMixin, AutocompleteMixin],

  props: {
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      searchInput: '',
    };
  },

  computed: {
    variantAttr() {
      if (this.inPlace) {
        return this.disabled ? 'plain' : 'underlined';
      }
      return 'outlined';
    },
  },

  methods: {
    highlightSearch(text: string) {
      const searchInput = (this.searchInput || '').toLocaleLowerCase();

      const index = text.toLocaleLowerCase().indexOf(searchInput);

      if (index < 0) return { start: text };

      const start = text.slice(0, index);
      const match = text.slice(index, index + searchInput.length);
      const end = text.slice(index + searchInput.length);

      return {
        start,
        match,
        end,
      };
    },

    updateSearchInput(value: string) {
      this.searchInput = value;
      this.onSearchInputUpdate(value);
    },

    emitModelValue(value: any) {
      this.$emit('update:model-value', value);
    },

    // Omitting the `title` prop from the list item props
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemProps({ title, ...props }: any) {
      return props;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/autocomplete.scss";
</style>
