<template>
  <bubble-card>
    <template #card-title>
      <h5>ID Verification</h5>
    </template>

    <div class="body-wrap">
      <p class="body-wrap__text">
        Next, we will verify your identity with a Government Issued ID.
      </p>

      <div class="mb-4 what-you-need">
        <span>What you will need:</span>
        <div class="mt-6 what-you-need__icons-block">
          <div class="icon-block">
            <v-icon>mdi-account-card-details-outline</v-icon>
            <p>Your Gov’t Issued ID</p>
          </div>
          <div class="icon-block">
            <v-icon>mdi-cellphone-android</v-icon>
            <p>Your Smart Phone</p>
          </div>
        </div>
      </div>

      <p class="body-wrap__text mb-5">
        If you believe your device camera is disabled, please follow the directions below.
      </p>

      <div class="turning-on-links">
        <p class="mb-5">
          <a @click="showDialogIphone = true">
            <strong>Turning on your camera iPhone Devices</strong>
          </a>
        </p>
        <p class="mb-0">
          <a @click="showDialogAndroid = true">
            <strong>Turning on your camera Android Devices</strong>
          </a>
        </p>
      </div>

      <custom-button
        :disabled="isDisabled"
        full-width
        class="mt-6"
        data-test="continueBtn"
        @click="onBtnClick">
        Continue
      </custom-button>
    </div>

    <standard-dialog
      :dialog="showDialogIphone"
      title="Enable Camera on iPhone"
      :show-cancel-cta="false"
      button-label="Close"
      :on-cta="closeIphoneDialog"
      :on-cancel="closeIphoneDialog">
      <template #body>
        <div class="stepsWrap">
          <span>1. Open the Settings app on your phone</span>
          <span>2. Tap Privacy & Security</span>
          <span>3. Tap Camera</span>
          <span>4. Ensure the Camera is enabled for your web browser</span>
        </div>
      </template>
    </standard-dialog>

    <standard-dialog
      title="Enable Camera on Android"
      :dialog="showDialogAndroid"
      :show-cancel-cta="false"
      button-label="Close"
      :on-cta="closeAndroidDialog"
      :on-cancel="closeAndroidDialog">
      <template #body>
        <div class="stepsWrap">
          <span>1. Open the Settings app on your phone</span>
          <span>2. Tap Apps</span>
          <span>3. Find and tap the browser you are using</span>
          <span>4. Tap Permissions</span>
          <span>5. Tap Camera</span>
          <span>6. Tap "Allow only while using the app"</span>
        </div>
      </template>
    </standard-dialog>
  </bubble-card>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VerificationCard',

  components: {
    BubbleCard,
    CustomButton,
    StandardDialog,
  },

  props: {
    isDisabled: { type: Boolean, default: false },
  },

  data() {
    return {
      showDialogIphone: false,
      showDialogAndroid: false,
    };
  },

  methods: {
    onBtnClick() {
      this.$emit('initiateVerification');
    },

    closeIphoneDialog() {
      this.showDialogIphone = false;
    },

    closeAndroidDialog() {
      this.showDialogAndroid = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.body-wrap {
  &__text {
    font-size: 1rem;
  }
}

.what-you-need {
  background-color: var(--grayscale-color-5);
  text-align: center;
  margin: auto -1rem;
  padding: 1rem;

  span {
    color: var(--grayscale-color-1);
    font-size: 1rem;
  }

  &__icons-block {
    display: flex;
    justify-content: center;

    .icon-block {
      padding: 0 3rem;

      i {
        font-size: 3rem !important;
      }

      p {
        margin-top: 0.625rem;
        margin-bottom: 0;
      }
    }
  }
}

.turning-on-links {
  font-size: 0.875rem;

  a {
    cursor: pointer;
  }
}

.stepsWrap {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
}
</style>
