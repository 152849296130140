const BANK_ACCOUNT_STATUSES = {
  /**
   * Awaiting verification of deposits
   */
  PENDING: 'pending',
  /**
   * Merchant has a repay acc setup, needs to update to moov
   */
  NEW: 'new',
  /**
   * Payments are setup
   */
  VERIFIED: 'verified',
  /**
   * Deposits have not been verified after 14 days or failed after 3 attempts
   */
  VERIFICATION_FAILED: 'verificationFailed',
  /**
   * Something went wrong
   */
  ERRORED: 'errored',

  CONNECTED: 'connected',

  DISCONNECTED: 'disconnected',
};

export default BANK_ACCOUNT_STATUSES;
