<template>
  <v-combobox
    ref="select"
    v-bind="$attrs"
    :menu-props="{ attach }"
    autocomplete="off"
    auto-select-first
    :append-inner-icon="appendIcon"
    :data-test="dataTestAttr"
    :items="items"
    :item-title="itemText"
    :item-value="itemValue"
    :label="labelAttr"
    :variant="inPlace ? 'plain' : 'outlined'"
    :required="required"
    :rules="[isRequired]"
    validate-on="blur"
    @update:model-value="returnSelectedUser"
    @update:search="inputUpdate">
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-combobox>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import AutocompleteMixin from '@/components/Inputs/AutocompleteMixin';

export default defineComponent({
  name: 'ComboboxInput',

  mixins: [
    InputFieldMixin,
    AutocompleteMixin,
  ],

  emits: ['update:model-value', 'input'],

  data() {
    return {
      inputChecker: '',
    };
  },

  methods: {
    inputUpdate(value: string) {
      this.inputChecker = value;
      this.onSearchInputUpdate(value);
    },

    returnSelectedUser(event:any) {
      if (event !== this.inputChecker) {
        this.$emit('input', event);
      }
      this.$emit('update:model-value', event);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/autocomplete.scss";

</style>
