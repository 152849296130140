import { defineComponent } from 'vue';
import { PostMessageInterface } from '@/interfaces/widgets/PostMessageInterface';
import PostMessageMixin from '@/mixins/Widgets/PostMessageMixin';

export default defineComponent({
  mixins: [PostMessageMixin],

  data: () => ({
    resizeObserver: null as any,
    widgetContentContainer: null as any,
  }),

  mounted() {
    const { widgetContentContainer } = this.$refs;

    if (widgetContentContainer) {
      this.widgetContentContainer = widgetContentContainer;

      this.resizeObserver = new ResizeObserver(this.postMessageResize).observe(
        this.widgetContentContainer,
      );
    }
  },

  unmounted() {
    if (this.resizeObserver && this.widgetContentContainer) {
      this.resizeObserver.unobserve(this.widgetContentContainer);
    }
  },

  methods: {
    postMessageResize() {
      if (this.widgetContentContainer) {
        const eventData: PostMessageInterface = {
          clientHeight: this.widgetContentContainer.clientHeight,
        };

        this.postMessage(eventData);
      }
    },
  },
});
