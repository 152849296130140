<template>
  <standard-dialog
    width="600"
    :dialog="showDialog"
    :show-actions="false"
    :persistent="true"
    :hide-header-close="true"
    card-class="change-default-bcg-color">
    <template #title>
      <h5 class="dialog-title mb-0 mt-0">
        New Products and Pricing {{ date }}
      </h5>
    </template>

    <template #body>
      <div class="dialog-paragraph">
        <h6 class="paragraph-title">
          New Pricing
        </h6>
        <p class="mb-0">
          We have implemented a price change on merchant fees effective {{ date }}.
          The new prices can be viewed in
          <router-link :to="{ name: 'merchant-rate-sheets' }" class="rate-sheet">
            Rate Sheet
          </router-link>.
        </p>
      </div>

      <div class="dialog-paragraph">
        <h6 class="paragraph-title">
          New Products
        </h6>
        <p class="mb-0">
          We have also introduced new loan products for you to choose from.
          The new products can be viewed in
          <router-link :to="{ name: 'merchant-rate-sheets' }" class="rate-sheet">
            Rate Sheet
          </router-link>.
          Our goal is to help you reach more of your customers with attractive loan offers,
          increasing your approval rates and opportunities.
        </p>
      </div>

      <div class="dialog-paragraph">
        <h6 class="paragraph-title">
          What do I need to do?
        </h6>
        <p class="mb-0">
          Review your rate sheet and make adjustments as you see fit.
        </p>
      </div>

      <custom-button full-width @click="$emit('acknowledgeChanges')">
        I Acknowledge These Changes
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductsAndPricingDialog',
  components: {
    StandardDialog,
    CustomButton,
  },
  props: {
    showDialog: { type: Boolean, default: false },
    date: { type: String, default: '' },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.dialog-paragraph {
  background-color: #fff;
  border-radius: 18px;
  padding: 1rem;
  margin-bottom: 2rem;

  .rate-sheet {
    font-weight: 700;
    color: var(--primary-color);
  }
}

.paragraph-title {
  margin: 0;
  color: var(--grayscale-color-1);
  font-size: 1rem;
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-weight: 700;
}
</style>
