import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78d6d4a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "table-skeleton-loader" }
const _hoisted_3 = {
  key: 1,
  class: "table-skeleton-loader--mobile"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!

  return (!_ctx.isMobile)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
          return (_openBlock(), _createElementBlock("div", {
            key: row,
            style: {"border-bottom":"1px solid var(--grayscale-color-3)"}
          }, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (colWidth, i) => {
                return (_openBlock(), _createBlock(_component_v_skeleton_loader, {
                  key: i,
                  type: "text",
                  width: colWidth || 'auto',
                  style: _normalizeStyle(_ctx.getFlex(colWidth))
                }, null, 8, ["width", "style"]))
              }), 128))
            ])
          ]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (col, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "table-skeleton-loader--mobile__row"
          }, [
            _createVNode(_component_v_skeleton_loader, {
              width: "6rem",
              type: "text"
            }),
            _createVNode(_component_v_skeleton_loader, {
              width: "6rem",
              type: "text"
            })
          ]))
        }), 128))
      ]))
}