import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_alert = _resolveComponent("custom-alert")!

  return (_openBlock(), _createBlock(_component_custom_alert, {
    type: _ctx.type,
    timed: _ctx.timed,
    "total-show-time": _ctx.totalShowTime,
    dismissible: _ctx.dismissible,
    onDismissed: _ctx.close
  }, {
    default: _withCtx(() => [
      (_ctx.htmlText)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            innerHTML: _ctx.text
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1))
    ]),
    _: 1
  }, 8, ["type", "timed", "total-show-time", "dismissible", "onDismissed"]))
}