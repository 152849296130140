import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SocureIDMixin',

  data() {
    return {
      deviceSessionData: null,
    };
  },

  methods: {
    mountDeviceSessionScript() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        SigmaDeviceManager.getSessionToken().then((r) => {
          sessionStorage.setItem('socureDeviceSessionToken', JSON.stringify(r));
        });
      `;
      document.body.appendChild(script);
    },
  },
});
