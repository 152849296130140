<template>
  <standard-dialog
    :dialog="showDialog"
    :show-actions="false"
    :persistent="true"
    :on-cancel="emitClose">
    <template #title>
      <span>Please Confirm</span>
    </template>

    <template #body>
      <div class="main-div">
        <p v-if="depositVerifyText" class="mb-8">
          The new account will be verified with micro-deposits.
        </p>

        <p v-if="!moovConnectedText" class="mb-8">
          While verifying a new bank account, all new payment requests will be blocked,
          but all in-progress transactions will settle. Would you like to continue?
        </p>

        <div v-else class="mb-8">
          <p class="mb-6">
            By replacing this account, new payment requests and new loan invitations will be
            temporarily blocked until your payment settings are re-established.
          </p>
          <p>All in-progress transactions will settle.</p>
        </div>
      </div>

      <custom-button
        full-width
        @click="$emit('proceed')">
        Continue To Replace Bank Account
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'PaymentSettingsDialog',
  components: {
    StandardDialog,
    CustomButton,
  },
  props: {
    showDialog: { type: Boolean, default: false },
    depositVerifyText: { type: Boolean, default: false },
    moovConnectedText: { type: Boolean, default: false },
  },
  methods: {
    emitClose() {
      this.$emit('cancel');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.main-div p {
  line-height: 1.375rem
}
</style>
