const VALIDATION_TYPES = {
  zipCode: 'zipCode',
  zipCodeOpt: 'zipCodeOpt',
  date: 'date',
  emailAddress: 'emailAddress',
  ssnLastFourDigits: 'ssnLastFourDigits',
  phoneNumber: 'phoneNumber',
  taxId: 'taxId',
  ownershipPercentage: 'ownershipPercentage',
  ssn: 'ssn',
  otp: 'otp',
  otpSingleInput: 'otpSingleInput',
  currency: 'currency',
  bankAccountNumber: 'bankAccountNumber',
  routingNumber: 'routingNumber',
};

const VALIDATION_MASKS = {
  zipCode: {
    mask: '#####[-####]',
    greedy: false,
  },
  zipCodeOpt: {
    mask: '#####[-####]',
    greedy: false,
  },
  date: {
    mask: '##-##-####',
  },
  ssn: {
    mask: '###-##-####',
    jitMasking: true,
    autoUnmask: true,
  },
  ssnLastFourDigits: {
    mask: '####',
    jitMasking: true,
  },
  phoneNumber: '+1 (###) ###-####',
  taxId: '##-#######',
  otp: {
    mask: '#',
    autoUnmask: true,
  },
  otpSingleInput: {
    mask: '######',
    autoUnmask: true,
  },
  currency: {
    alias: 'currency',
    prefix: '$ ',
    rightAlign: false,
    autoUnmask: true,
    digits: 2,
  },
  bankAccountNumber: {
    alias: 'bankAccountNumber',
    mask: '####################',
    jitMasking: true,
    autoUnmask: true,
  },
  routingNumber: {
    alias: 'routingNumber',
    mask: '#########',
    jitMasking: true,
  },
};

export {
  VALIDATION_TYPES,
  VALIDATION_MASKS,
};
