<template>
  <v-row class="standard-data-table__actions" no-gutters>
    <v-menu location="left">
      <template v-slot:activator="{ props }">
        <custom-button
          v-if="!hideActionsMenuButton"
          class="standard-data-table__actions-dots"
          :data-test="`uma${user.id}`"
          variant="icon"
          size="small"
          aria-label="Actions"
          :ripple="false"
          v-bind="props">
          <v-icon>mdi-dots-vertical</v-icon>
        </custom-button>
      </template>
      <v-list class="text-left">
        <v-list-item
          v-if="!hideEditButton"
          class="action-buttons"
          :disabled="loading"
          @click="$emit('edit', user)">
          <custom-button
            :disabled="loading"
            class="custom-button"
            label="Edit"
            variant="text"
            :ripple="false" />
        </v-list-item>
        <v-list-item
          v-if="!hideActiveButton"
          class="action-buttons"
          :disabled="loading"
          @click="$emit('deactivate', user)">
          <custom-button
            :disabled="loading"
            class="custom-button"
            :label="user.status === 'Active' ? 'Deactivate' : 'Reactivate'"
            variant="text"
            :ripple="false" />
        </v-list-item>
        <v-list-item v-if="!hideReInviteButton">
          <custom-button
            class="custom-button"
            :disabled="loading || sendingReinvite"
            label="Resend Invite"
            variant="text"
            @click="$emit('reinvite', user.email)" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-row>
</template>

<script setup lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { UserAllLocations, UserAtThisLocation } from '@/interfaces/merchantPortal/UserInterface';
import UsersRolesEnum from '@/enums/UsersRolesEnum';
import { useStore } from 'vuex';
import { useMerchantPermissions } from '@/composables/useMerchantPermissions';
import { computed } from 'vue';

const componentProps = defineProps<{
  user: Partial<UserAllLocations | UserAtThisLocation>;
  loading: boolean;
  sendingReinvite: boolean;
  allUsersTable: boolean;
  multiLocation: boolean;
}>();

const store = useStore();
const { isAdminOrAbove, isSalesManager, isSalesRep } = useMerchantPermissions();

const loggedInUserId = store.getters['Auth/getUserId'];

const isLoggedInUser = computed(() => componentProps.user.user_id === loggedInUserId);

const loggedInUserIsSalesPerson = computed(() => {
  return !isAdminOrAbove.value && !isSalesManager.value && !isSalesRep.value;
});

const userIsActive = computed(() => componentProps.user.status === 'Active');
const userIsMerchantOwner = computed(() => componentProps.user.is_merchant_owner);
const userIsAdmin = computed(() => componentProps.user.role?.name === UsersRolesEnum.ADMIN);
const userIsSalesManager = computed(() => componentProps.user.role?.name === UsersRolesEnum.SALES_MANAGER);
const userIsSalesRep = computed(() => componentProps.user.role?.name === UsersRolesEnum.SALES_REP);
const userIsCorporateAdmin = computed(() => componentProps.user.role?.name === UsersRolesEnum.CORPORATE_ADMIN);

const checkRole = computed(() => {
  return (!componentProps.allUsersTable && isAdminOrAbove.value && userIsMerchantOwner.value)
      || (isSalesManager.value && userIsAdmin.value)
      || (isSalesManager.value && userIsSalesManager.value);
});

const hideActionsMenuButton = computed(() => {
  const nonMultiLocationCheck = isSalesManager.value && (userIsAdmin.value || userIsSalesManager.value);
  // Sales managers can only edit sales reps
  // Nobody can edit corporate admins
  const multiLocationCheck = (isSalesManager.value && !userIsSalesRep.value) || userIsCorporateAdmin.value;
  return componentProps.multiLocation ? multiLocationCheck : nonMultiLocationCheck;
});

const hideActiveButton = computed(() => {
  return loggedInUserIsSalesPerson.value
  || componentProps.user.can_be_reinvited
  || (isLoggedInUser.value)
  || checkRole.value;
});

const hideEditButton = computed(() => {
  return loggedInUserIsSalesPerson.value
  || !userIsActive.value
  || (userIsMerchantOwner.value && isLoggedInUser.value)
  || checkRole.value;
});

const hideReInviteButton = computed(() => {
  return !componentProps.user.can_be_reinvited || isLoggedInUser.value;
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/mixins";
@import '@/assets/scss/mixins/media_queries';

.standard-data-table {
  &__actions {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    max-width: 15rem;

    @include up-to-large-desktop {
      max-width: fit-content;
    }

    @include mobile {
      flex-direction: column;
    }

    &-dots {
      min-width: auto !important;
      color: var(--grayscale-color-1) !important;
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
}

.action-buttons {
  font-size: 1rem !important;
  padding-left: 1.125rem;
}

.custom-button {
  @include user-management-custom-button;
}
</style>
