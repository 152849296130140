import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createBlock(_component_text_input, _mergeProps(_ctx.attrs, {
    "data-test": _ctx.dataTestAttr,
    label: _ctx.labelAttr,
    required: _ctx.required,
    rules: [_ctx.validateUrl]
  }), null, 16, ["data-test", "label", "required", "rules"]))
}