import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_merchant_onboarding_wizard_annual_sales_revenue = _resolveComponent("merchant-onboarding-wizard-annual-sales-revenue")!
  const _component_merchant_onboarding_wizard_average_customer_spend = _resolveComponent("merchant-onboarding-wizard-average-customer-spend")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    class: "fs-unmask",
    "data-test": "MOB-step3-substep6",
    onSubmit: _withModifiers(_ctx.goToNextSubStep, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_merchant_onboarding_wizard_annual_sales_revenue, {
            modelValue: _ctx.stepData.annualSalesRevenue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stepData.annualSalesRevenue) = $event)),
            "max-value": _ctx.annualSalesRevenueMaxValue
          }, null, 8, ["modelValue", "max-value"]),
          _createVNode(_component_merchant_onboarding_wizard_average_customer_spend, {
            modelValue: _ctx.stepData.averageCustomerSpend,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.stepData.averageCustomerSpend) = $event)),
            "max-value": _ctx.consumerSpendMaxValue
          }, null, 8, ["modelValue", "max-value"]),
          _createVNode(_component_custom_button, {
            disabled: _ctx.nextSubstepDisabled,
            "full-width": "",
            "data-test": "nextBtn",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Next ")
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}