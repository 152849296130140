<template>
  <standard-dialog
    :dialog="true"
    :on-cancel="() => emit('close')"
    title="Your Credit Limit Includes a Buffer"
    width="380">
    <template #body>
      <p>
        We've added a buffer to the credit limit that you've requested. This extra amount
        allows you to spend more if you need to without requesting a credit limit increase.
      </p>

      <p>
        You will only be responsible for paying back the amount you spend
        during the purchase window, plus associated interest and fees.
      </p>

      <div class="div-divider" />

      <p class="pt-4">
        If you do not want the extra amount, you can request a credit limit
        decrease after you accept your loan by calling {{ supportPhoneNumber }}.
      </p>
    </template>

    <template #actions>
      <custom-button
        data-test="closeBtn"
        full-width
        @click="emit('close')">
        Close
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

const store = useStore();

const footerData = store.getters['Ui/getFooterData'];
const supportPhoneNumber = footerData ? footerData.support_number : '';
const emit = defineEmits(['close']);
</script>

<style scoped lang="scss">
p {
  margin-bottom: 0 !important;
  padding-bottom: 1rem;
}
</style>
