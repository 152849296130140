import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78c5e69f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "widgetContentContainer",
  class: "standard-wizard standard-wizard--merchant-begin-onboarding",
  "data-test": "wizardModal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_merchant_begin_onboarding_wizard_step_one = _resolveComponent("merchant-begin-onboarding-wizard-step-one")!
  const _component_v_stepper_window_item = _resolveComponent("v-stepper-window-item")!
  const _component_merchant_begin_onboarding_wizard_step_two = _resolveComponent("merchant-begin-onboarding-wizard-step-two")!
  const _component_merchant_begin_onboarding_wizard_step_three = _resolveComponent("merchant-begin-onboarding-wizard-step-three")!
  const _component_v_stepper_window = _resolveComponent("v-stepper-window")!
  const _component_v_stepper = _resolveComponent("v-stepper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_stepper, {
      modelValue: _ctx.wizardStep,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.wizardStep) = $event)),
      flat: "",
      class: _normalizeClass({ 'stepper-class': _ctx.wizardStep === 3 }),
      outlined: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass({ 'close-btn-wrap': true, 'icon-order': _ctx.wizardStep === 3 })
        }, [
          _createVNode(_component_v_btn, {
            class: "icon-button rounded-circle",
            "data-test": "closeBtn",
            "aria-label": "Close",
            icon: "",
            size: "x-small",
            variant: "text",
            onClick: _ctx.onCancelAction
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { size: "1.5rem" }, {
                default: _withCtx(() => [
                  _createTextVNode(" mdi-close ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 2),
        _createVNode(_component_v_stepper_window, {
          "model-value": _ctx.wizardStep,
          class: _normalizeClass({ step3: _ctx.wizardStep === 3 })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_stepper_window_item, { value: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_merchant_begin_onboarding_wizard_step_one, {
                  widget: _ctx.widget,
                  onNextStep: _cache[0] || (_cache[0] = ($event: any) => (_ctx.wizardStep += 1))
                }, null, 8, ["widget"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_stepper_window_item, { value: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_merchant_begin_onboarding_wizard_step_two, {
                  onNextStep: _cache[1] || (_cache[1] = ($event: any) => (_ctx.wizardStep += 1))
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_stepper_window_item, { value: 3 }, {
              default: _withCtx(() => [
                _createVNode(_component_merchant_begin_onboarding_wizard_step_three)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model-value", "class"])
      ]),
      _: 1
    }, 8, ["modelValue", "class"])
  ], 512))
}