import instance from '@/Axios';
import { OwnerDataInterface } from '@/interfaces/merchantOnboardingWizard/OwnerDataInterface';
import { toLower } from 'lodash';
import { dateToFormatIso } from '@/helpers/DateTimeHelper';
import AddressTypes from '@/enums/AddressTypes';

export async function addOrUpdateOwner(payload: OwnerDataInterface, merchantUID: string) {
  const ownerId = payload.id;

  const {
    email,
    phoneNumber,
    firstName,
    middleName,
    lastName,
    address1,
    address2,
    city,
    state,
    zipCode,
    ssn,
    ownerPercentage,
    dob,
    security_pin,
  } = payload;

  const postData: any = {
    email: toLower(email),
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    owner_percentage: ownerPercentage,
    address: {
      address_type: AddressTypes.HOME,
      address_1: address1,
      city,
      state,
      zip_code: zipCode,
    },
    phone_number: {
      phone_number: phoneNumber,
    },
    sensitive_set: {
      ssn,
      dob: dateToFormatIso(dob),
      security_pin,
    },
  };

  if (address2.length) {
    postData.address.address_2 = address2;
  }

  if (ownerId) {
    return instance.patch(`/api/v1/merchant/${merchantUID}/owner/${ownerId}/`, postData);
  }

  return instance.post(`/api/v1/merchant/${merchantUID}/owner/`, postData);
}

export async function deleteOwner(ownerId: number, merchantUID: string) {
  return instance.delete(`/api/v1/merchant/${merchantUID}/owner/${ownerId}/`);
}
