<template>
  <simple-card class="section-card">
    <template #body>
      <transaction-table
        v-model:page="currentPage"
        :only-on-activity-center="true"
        :is-mobile="isMobile"
        :items-per-page="15"
        :items-per-page-options="rowsDisplayed"
        :items-length="transactionsRequestsCount"
        :items="transactionsRequests"
        :refreshing-from-parent="refresh"
        expanded
        @refreshTable="refreshTable"
        @updatePage="sortTable"
        @updateSearchPage="updateSearchPage" />
    </template>
  </simple-card>
</template>

<script lang="ts">
import TransactionTable from '@/components/Merchant/Portal/TransactionTable.vue';
import SimpleCard from '@/components/SimpleCard.vue';
import TransactionsDataFetchingMixin from '@/mixins/Merchant/TransactionsDataFetchingMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AllTransactions',
  components: {
    SimpleCard,
    TransactionTable,
  },
  mixins: [TransactionsDataFetchingMixin],
  data() {
    return {
      rowsDisplayed: [15, 25, 35],
      action: 'all-transactions',
    };
  },
  computed: {
    transactionsRequests() {
      return this.$store.getters['MerchantPortal/getRequestsTransactions'];
    },
    transactionsRequestsCount() {
      return this.$store.getters['MerchantPortal/getRequestsTransactionsCount'];
    },
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
  },
  methods: {
    async refreshTable(val: any) {
      this.currentPage = val.page;
      await this.sortTable(val);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table-default";

.section-card :deep() .v-data-table-footer {
  &__pagination {
    .v-pagination__first,
    .v-pagination__last {
      display: none;
    }
  }
}
</style>
