<template>
  <div
    class="standard-wizard__step standard-wizard__step--max-resend"
    data-test="maxCard">
    <v-card>
      <v-card-text>
        Sorry, we cannot verify your mobile number and you cannot proceed.
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MaxResendAttemptsMsg',
});
</script>
