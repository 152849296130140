<template>
  <v-form
    class="fs-unmask"
    data-test="MOB-step1-substep2"
    @submit.prevent="goToNextSubStep">
    <v-card-text>
      <v-select
        v-if="programs.length"
        ref="program"
        v-model="selectedProgramUuid"
        class="old-v-select"
        variant="outlined"
        :disabled="programDisabled"
        :items="orderedPrograms"
        data-test="programInput"
        label="Industry"
        item-title="name"
        item-value="program_uuid" />

      <v-select
        ref="subProgram"
        v-model="selectedSubprogramUuid"
        class="old-v-select"
        variant="outlined"
        :disabled="disabled"
        :items="orderedSubprograms"
        data-test="subInput"
        label="Subindustry"
        item-title="name"
        item-value="subprogram_uuid" />

      <custom-button
        :disabled="nextSubstepDisabled"
        data-test="nextBtn"
        full-width
        type="submit">
        Next
      </custom-button>
    </v-card-text>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import get from 'lodash/get';
import GetProcessing from '@/mixins/GetProcessing';
import ProfundMixin from '@/mixins/ProfundMixin';
import { ProgramsInterface } from '@/interfaces/merchant/ProgramsInterface';
import sortBy from 'lodash/sortBy';
import { SubProgramsInterface } from '@/interfaces/merchant/SubProgramsInterface';

export default defineComponent({
  name: 'SubstepTwo',

  components: {
    CustomButton,
  },

  mixins: [GetProcessing, ProfundMixin],

  props: {
    onNextSubStep: { type: Function, default: () => () => null },
  },

  data: () => ({
    ownSubStep: 2,
    disableProgramField: false,
    stepData: {
      program: {} as ProgramsInterface,
      subProgram: {} as SubProgramsInterface,
    } as any,
    selectedProgramUuid: null as any,
    selectedSubprogramUuid: null,
    continueButtonDisabled: false,
  }),

  computed: {
    reEntryData(): boolean {
      return this.$store.getters['MerchantOnboarding/getMerchantReEntryData'];
    },

    disabled(): boolean {
      return Object.keys(this.stepData.program).length === 0
      || !this.subPrograms.length
      || this.processing;
    },

    reEntryMerchantSubProgramId(): any {
      return get(this.reEntryData, 'merchant.sub_program', [])[0];
    },

    reEntryMerchantSubProgram(): any {
      return this.subPrograms.find(it => it.id === this.reEntryMerchantSubProgramId);
    },

    nextSubstepDisabled(): boolean {
      return !get(this.stepData.subProgram, 'id') || this.continueButtonDisabled;
    },

    programs(): ProgramsInterface[] {
      return this.$store.getters['MerchantOnboarding/getPrograms'] || [];
    },

    subPrograms(): SubProgramsInterface[] {
      return this.$store.getters['MerchantOnboarding/getSubPrograms'] || [];
    },

    orderedSubprograms(): SubProgramsInterface[] {
      return sortBy(this.subPrograms, ['name']);
    },

    orderedPrograms(): ProgramsInterface[] {
      return sortBy(this.programs, ['name']);
    },

    storedSelectedPrograms(): any {
      return this.$store.getters['MerchantOnboarding/getSelectedPrograms'];
    },

    programDisabled(): boolean {
      return !this.stepData.program
      || this.disableProgramField
        || this.processing;
    },
  },

  watch: {
    selectedProgramUuid: {
      handler(value: string) {
        this.selectedSubprogramUuid = null;
        if (!value) return;

        const program = this.programs.find(it => it.program_uuid === value);
        this.stepData.program = program;
        this.onProgramSelected(value);
      },
      immediate: true,
    },

    selectedSubprogramUuid(value: string) {
      if (!value) return;

      const subProgram = this.orderedSubprograms.find(it => it.subprogram_uuid === value);
      this.stepData.subProgram = subProgram;
    },

    programs: {
      handler(value: any) {
        if (value.length === 1) {
          const brandProgram = this.programs.find(it => {
            return it;
          });

          if (!brandProgram) {
            return;
          }

          this.selectedProgramUuid = brandProgram?.program_uuid;
          this.stepData.program = brandProgram;
          this.onProgramSelected(this.selectedProgramUuid);
          this.disableProgramField = true;
        }
      },
      immediate: true,
    },
  },

  async mounted() {
    await this.$store.dispatch('MerchantOnboarding/dispatchFetchPrograms');
  },

  methods: {
    onProgramSelected(programUuid: any): void {
      this.$store.dispatch('MerchantOnboarding/dispatchFetchSubprograms', programUuid);
      const program = this.programs.find(it => it.program_uuid === programUuid);
      this.stepData.program = program;
      this.stepData.subProgram = {};
    },

    async patchSelectedProgram() {
      const { program, subProgram } = this.stepData;
      return this.$store.dispatch(
        'MerchantOnboarding/patchMerchantSelectedPrograms',
        { program, subProgram },
      );
    },

    async goToNextSubStep() {
      this.continueButtonDisabled = true;

      const responseStatus = await this.patchSelectedProgram();

      await this.$store.dispatch(
        'MerchantOnboarding/dispatchPatchApplication',
        'Post-Industry / Pre-Business Name',
      );

      if (responseStatus === 200) {
        this.onNextSubStep(this.ownSubStep + 1);
      }

      this.continueButtonDisabled = false;
    },
  },
});
</script>
