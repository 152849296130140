<template>
  <v-card
    class="business-name-and-address inline-inputs"
    :elevation="cardElevation">
    <v-card-title class="py-3" data-test="businessNameSubHeader">
      <h6
        v-if="isMobile"
        class="mobile-title">
        Business Name, Address and Contact
      </h6>
      <h5 v-else>
        Business Name, Address and Contact
      </h5>

      <custom-button
        class="edit-save-button"
        :disabled="saveDisabled"
        variant="text"
        size="small"
        @click="toggleEdit">
        {{ editing ? 'Save' : 'Edit' }}
      </custom-button>
    </v-card-title>

    <v-card-text class="pa-4">
      <div class="card-text__info-group">
        <text-input
          v-model="userVerifyData.businessName"
          :disabled="formInputsDisabled"
          in-place
          :required="true"
          data-test="businessInput"
          label="Business Name"
          type="text" />
      </div>

      <div class="card-text__info-group">
        <address-input
          v-model="userAddressData"
          ask-confirmation
          address1-data-test="address1Input"
          address1-label="Business Street Address"
          address2-data-test="address2Input"
          address2-label="Business Suite / Room / Floor"
          confirmation-modal-title="Please Verify Your Business Address"
          city-data-test="cityInput"
          :disabled="formInputsDisabled"
          :is-fs-excluded="false"
          in-place
          required
          state-data-test="stateSelect"
          :show-confirmation="showAddressConfirmation"
          :validated-address="validatedAddress"
          :error-messages="[addressValidationError]"
          @confirmation:accepted="confirmAddress"
          @confirmation:declined="declineAddress" />
      </div>

      <div class="card-text__info-group">
        <phone-input
          v-model="allAttributes.merchant_phone"
          data-test="businessPhoneInput"
          :disabled="formInputsDisabled"
          in-place
          label="Business Phone Number"
          required />
      </div>

      <div class="card-text__info-group">
        <email-input
          v-model="userVerifyData.emailAddress"
          data-test="emailInput"
          :disabled="formInputsDisabled"
          in-place
          label="Business Email Address"
          required />
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AddressInput from '@/components/Inputs/Address/index.vue';
import PhoneInput from '@/components/Inputs/Phone.vue';
import { AddressDataInterface } from '@/interfaces/addressValidation/AddressDataInterface';
import cloneDeep from 'lodash/cloneDeep';
import TextInput from '@/components/Inputs/Text.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { AllAttributes } from '@/interfaces/merchantOnboardingWizard/AllAttributes';
import GetProcessing from '@/mixins/GetProcessing';
import validatePhoneNumber from '@/validators/phone_number';
import validateZipCode from '@/validators/zip_code';
import ValidateAddress from '@/mixins/ValidateAddress';
import EmailInput from '@/components/Inputs/Email.vue';
import { OnboardingUserData } from '@/interfaces/merchantOnboardingWizard/OnboardingUserData';
import { MerchantReEntryDataInterface } from '@/interfaces/merchant/MerchantReEntryDataInterface';

export default defineComponent({
  name: 'VerifyData',

  components: {
    PhoneInput,
    TextInput,
    AddressInput,
    CustomButton,
    EmailInput,
  },

  mixins: [GetProcessing, ValidateAddress],

  data: () => ({
    editing: false,
    allAttributes: {
      merchant_phone: '',
    } as AllAttributes,
    userVerifyData: {
      userId: 0,
      emailAddress: '',
      businessName: '',
      merchantUid: '',
      merchantApplicationId: '',
      userMobile: '',
    },
    userAddressData: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
    } as AddressDataInterface,
  }),

  computed: {
    getUserVerifyData(): OnboardingUserData {
      return this.$store.getters['MerchantOnboarding/getUserVerifyData'];
    },

    reEntryData(): MerchantReEntryDataInterface {
      return this.$store.getters['MerchantOnboarding/getMerchantReEntryData'];
    },

    phoneNumber(): string {
      return this.getUserVerifyData?.userMobile;
    },

    processingApplication(): boolean {
      return this.$store.getters['MerchantOnboarding/getProcessingApplication'];
    },

    applicationApproved(): boolean {
      return this.$store.getters['MerchantOnboarding/getApplicationApproved'];
    },

    applicationWaitingApproval(): boolean {
      return this.$store.getters['MerchantOnboarding/getApplicationWaitingApproval'];
    },

    formInputsDisabled(): boolean {
      return !this.editing
      || this.processing
      || this.processingApplication
      || this.applicationApproved
      || this.applicationWaitingApproval;
    },

    saveDisabled(): boolean {
      return this.editing && !(
        this.userVerifyData.businessName
      && this.userAddressData.address1
      && this.userAddressData.city
      && this.userAddressData.state
      && this.userAddressData.zipCode
      && validateZipCode(this.userAddressData.zipCode)
      && this.allAttributes.merchant_phone
      && validatePhoneNumber(this.allAttributes.merchant_phone)
      );
    },

    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },

    cardElevation() {
      return this.editing ? 10 : undefined;
    },
  },

  async mounted() {
    await this.$store.dispatch('MerchantOnboarding/dispatchFetchReEntryData');

    const phone = this.phoneNumber;
    this.allAttributes.merchant_phone = phone;

    this.userVerifyData = cloneDeep(this.getUserVerifyData);
    this.setUserAddressData();

    this.$el.querySelector('input[type=tel]')?.classList.remove('fs-exclude');
  },

  methods: {
    setUserAddressData() {
      this.userAddressData = {
        address1: this.reEntryData?.merchant.address_set[0].address_1,
        address2: this.reEntryData?.merchant.address_set[0].address_2 || 'N/A',
        city: this.reEntryData?.merchant.address_set[0].city,
        state: this.reEntryData?.merchant.address_set[0].state,
        zipCode: this.reEntryData?.merchant.address_set[0].zip_code,
      };
    },

    declineAddress(): void {
      this.setUserAddressData();
      this.showAddressConfirmation = false;
    },

    async confirmAddress() {
      const merchantData = { ...this.userVerifyData, ...this.userAddressData };
      await this.$store.dispatch('MerchantOnboarding/dispatchUpdateMerchantData', merchantData);
      await this.$store.dispatch('MerchantOnboarding/dispatchAllAttributes', this.allAttributes);
      await this.$store.dispatch('MerchantOnboarding/dispatchFetchReEntryData');
      this.showAddressConfirmation = false;
      this.editing = !this.editing;
      this.$store.dispatch('MerchantOnboarding/setVerifyFormUserDetailsStatus', this.editing);
    },

    async toggleEdit() {
      if (this.editing) {
        const isValid = await this.validateAddress(this.userAddressData);
        if (!isValid) return;

        this.showAddressConfirmation = true;
        this.userAddressData.address2 = this.userAddressData.address2 || 'N/A';
      } else {
        this.editing = !this.editing;
        this.userAddressData.address2 = this.userAddressData.address2 === 'N/A' ? '' : this.userAddressData.address2;
        this.$store.dispatch('MerchantOnboarding/setVerifyFormUserDetailsStatus', this.editing);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/vuetify/elevation.scss";

.business-name-and-address {
  margin-bottom: 2rem !important;
  border: 1px solid var(--grayscale-color-2);
  box-shadow: $v-elevation-4;
  border-radius: 8px;

  .edit-save-button {
    font-size: 0.875rem !important;
    background-color: #fff;
    border-radius: 8px;
    min-width: 2rem;
  }

  :deep() .v-theme--light.v-btn.edit-save-button.button-small:hover {
      box-shadow: none;
      background-color: #fff;
  }

  :deep(.v-card-title) {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    background-color: var(--grayscale-color-5);

    .custom-button span {
      @include mobile {
        font-size: 0.875rem;
      }
    }

    h5 {
      padding-right: 2.5rem;
      word-break: break-word;
      font-size: 1rem;
      color: var(--grayscale-color-1);
      font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    }

    h6 {
      font-size: 1rem;
      color: var(--grayscale-color-1);
      font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    }

    .mobile-title {
      margin-top: 0.75rem;
    }
  }

  :deep(.v-card-text) {
    .card-text__info-group {
      margin-bottom: 1.5rem !important;
    }

    @include mobile {
      .card-text__info-group {
        margin-bottom: 0 !important;

        .v-text-field__slot label, input {
          font-size: 0.875rem;
        }

        .state-zip-row .v-select__slot label {
          font-size: 0.875rem;
        }

        .state-zip-row .v-select__selections span {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.standard-wizard {
  &__step {
    @include standard-wizard-form-inline-split;
  }
}

@media (min-width: 460px) {
  .business-name-and-address {
    .card-title {
      align-items: center;
    }
  }
}
</style>
