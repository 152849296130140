import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00a9e9dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-select__selection" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_autocomplete_item = _resolveComponent("autocomplete-item")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createBlock(_component_v_autocomplete, _mergeProps({ ref: "select" }, _ctx.$attrs, {
    "menu-props": { attach: _ctx.attach },
    autocomplete: "disabled",
    "auto-select-first": "",
    "menu-icon": _ctx.appendIcon,
    "data-test": _ctx.dataTestAttr,
    disabled: _ctx.disabled,
    items: _ctx.items,
    "item-title": _ctx.itemText,
    "item-value": _ctx.itemValue,
    label: _ctx.labelAttr,
    variant: _ctx.variantAttr,
    rules: [_ctx.isRequired],
    search: _ctx.searchInput,
    "validate-on": "blur",
    "onUpdate:search": _ctx.updateSearchInput,
    "onUpdate:modelValue": _ctx.emitModelValue
  }), {
    selection: _withCtx(({ item }) => [
      _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createTextVNode(_toDisplayString(item.title), 1)
      ])), [
        [_directive_private, 'State']
      ])
    ]),
    item: _withCtx(({ item, props }) => [
      _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(_ctx.itemProps(props))), {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createBlock(_component_v_list_item_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_autocomplete_item, _normalizeProps(_guardReactiveProps(_ctx.highlightSearch(item.title))), null, 16)
            ]),
            _: 2
          }, 1024)), [
            [_directive_private, `Select item in ${_ctx.labelAttr}`]
          ])
        ]),
        _: 2
      }, 1040)
    ]),
    _: 1
  }, 16, ["menu-props", "menu-icon", "data-test", "disabled", "items", "item-title", "item-value", "label", "variant", "rules", "search", "onUpdate:search", "onUpdate:modelValue"]))
}