<template>
  <div class="fs-unmask merchant-portal-page merchant-portal-page--admin">
    <v-expansion-panels v-model="panel">
      <merchant-portal-admin-user-management class="mb-6" />

      <merchant-portal-admin-payment-settings v-if="merchantData && !isSalesManager" />
    </v-expansion-panels>
  </div>
</template>

<script setup lang="ts">
import MerchantPortalAdminPaymentSettings
  from '@/components/Merchant/Portal/Admin/PaymentSettings.vue';
import MerchantPortalAdminUserManagement
  from '@/components/Merchant/Portal/Admin/UserManagement/UserManagement.vue';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import BANK_ACCOUNT_STATUSES from '@/constants/PaymentConstants';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const panel = ref<number|null>(null);

const store = useStore();
const route = useRoute();

const merchantData = computed(() => store.getters['MerchantPortal/getMerchantData']);
const isSalesManager = computed(() => {
  return store.getters['MerchantPortal/getMerchantData']?.user_type.name === 'merchant-sales-manager' || false;
});
const paymentProviders = computed<PaymentProvidersInterface>(() => store.getters['MerchantPortal/getPaymentProviders']);
const expandPanelByDefault = computed(() => {
  return paymentProviders.value?.moov?.status !== BANK_ACCOUNT_STATUSES.NEW
      || paymentProviders.value?.moov?.status !== BANK_ACCOUNT_STATUSES.VERIFIED
      || paymentProviders.value?.moov?.status !== BANK_ACCOUNT_STATUSES.CONNECTED
      || paymentProviders.value?.repay?.status === BANK_ACCOUNT_STATUSES.DISCONNECTED;
});

watch(expandPanelByDefault, val => {
  if (val) {
    panel.value = 1;
  }
}, { immediate: true });

onMounted(() => {
  if (route.query.target === 'users') {
    panel.value = 0;
  }

  if (route.query.target === 'payments') {
    panel.value = 1;
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/pages/merchant-portal-page";
@import "@/assets/scss/standard-collapsable-panel";

</style>
