<template>
  <header class="consumer-header">
    <v-container class="consumer-header__container">
      <merchant-name
        v-if="merchantName"
        :merchant-name="merchantName"
        small />

      <div
        class="consumer-header__appid"
        data-test="appId">
        <span v-if="consumerHumanReadableApplicationIdLabel">
          ID: {{ consumerHumanReadableApplicationIdLabel }}
        </span>
      </div>

      <div v-if="cancelButtonEnabled" class="consumer-header__actions">
        <v-divider vertical />
        <custom-button
          data-test="cancelBtn"
          variant="text"
          @click="openCancelApplicationModal">
          Cancel
        </custom-button>
      </div>
    </v-container>

    <consumer-loan-apply-wizard-cancel-application-modal
      v-if="cancelButtonEnabled"
      ref="modal"
      :on-cta="cancelApplication" />
  </header>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ConsumerLoanApplyWizardCancelApplicationModal
  from '@/components/Consumer/LoanApplyWizard/Messages/CancelApplicationModal.vue';
import CreditEngineDecisions from '@/enums/CreditEngine/Decisions';
import CreditEnginePullTypes from '@/enums/CreditEngine/PullTypes';
import ConsumerHumanReadableApplicationId
  from '@/mixins/Consumer/LoanApply/ConsumerHumanReadableApplicationId';
import CreditEngineCheckMixin from '@/mixins/Consumer/LoanApply/CreditEngineCheckMixin';
import { get } from 'lodash';
import MerchantName from '@/components/Merchant/Portal/Header/MerchantName.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VHeader',
  components: {
    CustomButton,
    ConsumerLoanApplyWizardCancelApplicationModal,
    MerchantName,
  },
  mixins: [
    ConsumerHumanReadableApplicationId,
    CreditEngineCheckMixin,
  ],
  computed: {
    applicationId(): any {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },
    nonDecision(): boolean {
      return get(
        this.creditEngineCheck,
        `[${CreditEnginePullTypes.SOFT}].decision`,
        '',
      ) === CreditEngineDecisions.NON_DECISION;
    },
    merchantName(): any {
      return this.$store.getters['Consumer/getMerchantName'];
    },
    loanApplyWizardStep(): number {
      return this.$store.getters['Consumer/getLoanApplyWizardStep'];
    },
    cancelButtonEnabled(): boolean {
      return this.$store.getters['Consumer/getCanApplicationBeCancelled'];
    },
  },
  methods: {
    async openCancelApplicationModal() {
      (this.$refs.modal as any).showModal();
    },
    cancelApplication() {
      return this.$store.dispatch('Consumer/cancelApplication');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/variables/font-variables";
@import '@/assets/scss/mixins/media_queries';

.consumer-header {
  margin-bottom: 1rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  height: 4.625rem;

  &__container {
    grid-template-columns: minmax(min-content, max-content) 1fr minmax(min-content, max-content);
    grid-template-areas: "name appid actions";
    display: grid;
    grid-gap: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    @include mobile {
      :deep() {
        .merchant-name {
          display: inline-flex;
          width: 100%;
          max-width: fit-content;

          &__title {
            width: 100% !important;
          }
        }
      }
    }

    @include mobile {
      grid-gap: 0rem;
    }
  }

  &__appid {
    grid-area: appid;
    justify-content: flex-end;
    font-size: $font-size-standard;

    @include mobile {
      padding-right: 0.5rem;
      font-size: 0.75rem;
      min-width: 3rem;
    }
  }

  &__merchant-name,
  &__appid {
    text-align: center;
    color: var(--grayscale-color-1);
    display: flex;
    align-items: center;
  }

  &__actions {
    grid-area: actions;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: flex-end;

    button {
      height: 1.5rem !important;

      @include mobile {
        padding: 0 !important;
      }
    }
  }
}

img {
  width: 100px;
}
</style>
