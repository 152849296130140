import { defineComponent } from 'vue';
import MerchantDataMixin from '@/mixins/Merchant/MerchantDataMixin';

export default defineComponent({
  mixins: [MerchantDataMixin],

  computed: {
    isTypeIC1(): boolean {
      const type = this.getUserVerifyData?.sponsorType;
      return type === 'IC1';
    },

    sponsorId(): string {
      return this.getUserVerifyData?.sponsorId;
    },

    sponsorName(): string {
      return this.getUserVerifyData?.sponsorName;
    },

    isBrandProFund(): boolean {
      return this.isTypeIC1 && this.sponsorName === 'ProFund';
    },

    hasMPAccess() {
      return this.isTypeIC1 && !!this.getUserVerifyData?.sponsorHasMPAccess;
    },

    isTypeIC1WithoutMPAccess() {
      return !this.hasMPAccess && this.isTypeIC1;
    },

    isTypeIC1WithMPAccess() {
      return this.hasMPAccess && this.isTypeIC1;
    },
  },
});
