<template>
  <div style="display: inline;">
    <v-icon
      tag="button"
      class="clipboard-icon ml-2"
      size="16"
      @click="copyToClipboard">
      mdi-content-copy
    </v-icon>

    <transition name="fade" @after-enter="transitionDuration">
      <div v-if="showClipboardInfo" class="elevation-20 pop-up-info">
        <div class="pop-up-info-content">
          <div class="info-text">
            Copied To Clipboard
          </div>
          <v-icon
            color="var(--success-color)">
            mdi-check-circle-outline
          </v-icon>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CopyToClipboardPopup',
  props: {
    stringToCopy: { type: String, required: true },
  },
  data() {
    return {
      showClipboardInfo: false,
    };
  },
  methods: {
    copyToClipboard(): void {
      window.navigator.clipboard.writeText(this.stringToCopy || '');
      this.showClipboardInfo = true;
    },
    transitionDuration(): void {
      setTimeout(() => {
        this.showClipboardInfo = false;
      }, 1500);
    },
  },
});
</script>

<style  lang="scss" scoped>
  .clipboard-icon {
    font-size: 1rem;
  }

  .clipboard-icon::after {
    background-color: transparent;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .pop-up-info {
    position: absolute;
    width: 11.75rem;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid var(--grayscale-color-2);
    border-radius: 8px;
    background-color: #fff;

    .pop-up-info-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;

      .info-text {
        font-size: 0.875rem;
        color: var(--grayscale-color-1);
      }
    }
  }
</style>
