<template>
  <div class="page page--consumer portal">
    <portal-header class="portal__header" />

    <v-container class="portal__container">
      <v-row justify="center">
        <v-col
          class="button-container"
          lg="5"
          md="6">
          <custom-button
            label="Loan Apply"
            @click="gotoLoanApply" />
        </v-col>
      </v-row>
    </v-container>

    <router-view />

    <main-footer class="portal__footer" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import MainFooter from '@/components/MainFooter.vue';
import PortalHeader from '@/components/Merchant/Portal/Header/index.vue';
import GotoRouteName from '@/mixins/GotoRouteName';

export default defineComponent({
  name: 'ConsumerOnboarding',

  components: {
    CustomButton,
    PortalHeader,
    MainFooter,
  },

  mixins: [
    GotoRouteName,
  ],

  data() {
    return {
      onboardingCode: '' as string | (string | null)[],
    };
  },

  created() {
    this.onboardingCode = this.$route.query.code as string;
  },

  methods: {
    gotoLoanApply(): void {
      this.gotoRouteName('consumer-loan-apply');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";

@include portal-page-layout;

.button-container {
  justify-content: space-evenly;
  margin-bottom: 2rem;
  display: flex;
}
</style>
