import { forEach, get, isFunction } from 'lodash';

export default function ResetState(
  modules: any,
): void {
  forEach(modules, (module: any) => {
    if (isFunction(get(module.mutations, 'resetModuleState'))) {
      const { state, mutations } = module;

      mutations.resetModuleState(state as any);
    }
  });
}
