<template>
  <standard-wizard-message data-test="borrowerMsg">
    <template #body>
      <p>
        Borrower Agreement Specifics State does not exist
      </p>
    </template>
  </standard-wizard-message>
</template>

<script lang="ts">
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BorrowerAgreementSpecificsStateErrorMsg',
  components: {
    StandardWizardMessage,
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/merchant-begin-onboarding-wizard";
</style>
