import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_alert = _resolveComponent("custom-alert")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_standard_dialog = _resolveComponent("standard-dialog")!

  return (_openBlock(), _createBlock(_component_standard_dialog, {
    dialog: _ctx.show,
    "show-actions": true,
    "on-cancel": _ctx.closeModal,
    title: "Bank Account Confirmation"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_custom_alert, { type: "error" }, {
        default: _withCtx(() => [
          _createTextVNode(" An admin on your account needs to confirm bank account information in payment settings before you can send any new loan applications. ")
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_custom_button, {
        "full-width": "",
        onClick: _ctx.onClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.isAdmin ? 'Take Me To Payment Settings' : 'Close'), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["dialog", "on-cancel"]))
}