<template>
  <custom-dialog :dialog="true" @cancel="$emit('close')">
    <template #body>
      <text-input
        v-model="name"
        class="offer-code-name--input"
        label="Enter Offer Code Name"
        hint="We recommend a short name that is easy to share with your sales team."
        persistent-hint
        :rules="[() => isValidName]" />
    </template>
    <template #actions>
      <custom-button
        class="full-width"
        data-test="saveOfferCodeName"
        :disabled="isValidName !== true"
        @click="save">
        {{ editing ? 'Save' : 'Next' }}
      </custom-button>
    </template>
  </custom-dialog>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CustomDialog from '@/components/Dialogs/index.vue';
import TextInput from '@/components/Inputs/Text.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NameDialog',
  components: {
    CustomDialog,
    CustomButton,
    TextInput,
  },
  props: {
    editing: { type: Boolean, default: false },
    existingName: { type: String, default: '' },
  },
  data() {
    return {
      name: '',
    };
  },
  computed: {
    isValidName() {
      const { name } = this;
      // Allow only alphanumeric and space
      const allowedRegex = new RegExp(/^[\dA-Za-z]+[\dA-Za-z\s]*$/);

      if (name && !allowedRegex.test(name)) return 'Name can have only alphanumeric characters and spaces';
      if (name.length < 2) return 'Name should be at least 2 characters long';
      if (name.length > 80) return `Name cannot be longer than 80 characters (currently ${name.length})`;
      return true;
    },
  },
  mounted() {
    if (this.editing) this.name = this.existingName;
  },
  methods: {
    save() {
      if (this.isValidName) this.$emit('save', this.name);
    },
  },
});
</script>

<style lang="scss" scoped>
.offer-code-name--input {
  :deep(.v-text-field__details) {
    padding-left: 0;
  }
}
</style>
