<template>
  <custom-combobox
    v-model="search"
    class="portal-header__search"
    dense
    in-place
    solo
    rounded
    bg-color="#fff"
    height="50px"
    hide-no-data
    hide-dropdown-icon
    :items="searchResults"
    item-title="text"
    item-value="consumer_application"
    placeholder="Search by Customer Name"
    hide-details
    menu-icon=""
    prepend-inner-icon="mdi-magnify"
    :auto-select-first="autoSelectFirst"
    :loading="isLoading"
    :menu-props="{ attach: false }"
    @update:model-value="debounceOnModelUpdate">
    <template #item="{ item, props }">
      <v-list-item v-bind="itemProps(props)">
        <v-list-item-title v-private="'Searched Consumer'">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </custom-combobox>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomCombobox from '@/components/Inputs/Combobox.vue';
import { debounce } from 'lodash';

export default defineComponent({
  name: 'CustomerSearch',

  components: {
    CustomCombobox,
  },

  data() {
    const items: any = [];
    const debounceOnModelUpdate: any = null;
    return {
      // Disabling auto-select-first because when it auto selects, on blur the
      // combobox fires the update:modelValue event with the selected dropdown item.
      autoSelectFirst: false,
      items,
      isLoading: false,
      searchString: '',
      focused: false,
      search: null,
      debounceOnModelUpdate,
    };
  },

  computed: {
    searchResults(): any {
      const items: any[] = this.items.slice(0, 10);
      return items.map(it => {
        return {
          ...it,
          text: [it.user.first_name, it.user.middle_name, it.user.last_name]
            .filter(Boolean)
            .join(' '),
        };
      });
    },
  },

  created() {
    this.debounceOnModelUpdate = debounce(this.onModelUpdate, 400);
  },

  methods: {
    selectLoanAppAndRedirect(value: any): void {
      if (!value) return;

      this.$store.dispatch('MerchantPortal/selectLoanApplication', value);

      if (this.$route.name !== 'merchant-activity-center') {
        this.$router.push({ name: 'merchant-activity-center' });
      }

      this.$nextTick(() => {
        this.searchString = '';
        this.search = null;
        this.$emit('forceUpdate');
      });
    },

    async onModelUpdate(value: any) {
      if (!value || (typeof value === 'string' && value.length < 3)) {
        this.items = [];
        // To be removed once we permanently enable HFS and the new tables in the Consumer Activity
        await this.$store.dispatch('MerchantPortal/dispatchResetSearch');
        return;
      }

      if (typeof value === 'string') {
        this.isLoading = true;

        const searchString = value.trim().toLowerCase();
        const response = await this.$store.dispatch('MerchantPortal/dispatchSearchApprovedLoans', searchString);

        this.items = Array.isArray(response) ? response : [];
        this.isLoading = false;
      }

      // User selected a value from the dropdown, hence we have to use the `return-object` prop
      // to differentiate between a string and an object value.
      if (typeof value === 'object' && value.consumer_application) {
        this.selectLoanAppAndRedirect(value.consumer_application);
      }
    },

    // Omitting the `title` prop from the list item props
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemProps({ title, ...props }: any) {
      return props;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.portal-header {
  &__search {
    margin: 0;

    @include mobile {
      padding: 0.5rem 1rem 0.5rem 1rem;
      background-color: var(--grayscale-color-5);
    }

    :deep(.v-list-item__title) {
      font-size: 0.875rem !important;
    }

    :deep() {
      .v-input__control .v-field {
        padding: 0 1rem;
        box-shadow: inset 0px -1px 0px var(--grayscale-color-3) !important;

        &::before {
          border-color: var(--grayscale-color-2);
        }

        &__input,
        &__prepend-inner {
          align-items: center;
          padding-top: 0;
        }
      }
    }

    :deep(input::placeholder) {
      color: var(--grayscale-color-1) !important;
      opacity: 1;
    }

    :deep(input:focus::placeholder) {
      color: transparent !important;
    }
  }
}

.v-input {
  margin-bottom: 0 !important;

  :deep(.v-input__control) {
    margin-bottom: 0;
    height: 2.75rem;

    .v-select__control label {
      top: 0.4rem;
    }

    .v-label:not(.v-label--active) {
      height: 2rem;
      line-height: 2rem;
    }
  }

  :deep(.v-select__content) {
    left: 0 !important;

    @include mobile {
      left: 0.75rem !important;
    }
  }

  :deep(&__prepend-inner) {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 !important;
  }
}
</style>
