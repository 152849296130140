<template>
  <standard-dialog
    dialog
    :on-cta="saveUser"
    :cta-disabled="buttonDisabled"
    :on-cancel="closeModal"
    button-label="Save">
    <template #body>
      <user-form
        :user="user"
        :form-data="formData!"
        :user_type="user_type"
        :email-error="emailError"
        @updateFormData="formData = $event"
        @updateUserType="updateUserStatus"
        @emailUpdated="disableSaveButton = false" />
    </template>
  </standard-dialog>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import { EditMerchantUserPayloadInterface } from '@/interfaces/merchantPortal/EditMerchantUserPayloadInterface';
import { UserAtThisLocation, UserInterface } from '@/interfaces/merchantPortal/UserInterface';
import UserForm from '@/components/Merchant/Portal/Admin/UserManagement/UserForm.vue';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import { useStore } from 'vuex';

const props = defineProps<{ user: UserAtThisLocation; }>();
const emit = defineEmits(['save', 'close']);

const formData = ref<Partial<UserInterface> | null>(null);
const user_type = ref('');
const disableSaveButton = ref(false);
const processing = ref(false);

const store = useStore();

const buttonDisabled = computed(() => {
  return processing.value || hasErrors.value || disableSaveButton.value;
});

const errors = store.getters['Ui/getErrors'];
const hasErrors = computed(() => !!errors.value?.non_field_errors);
const emailError = computed(() => (errors.email ? errors.email[0] : ''));

const updateUserStatus = (type: string) => {
  user_type.value = type;
};

const saveUser = async () => {
  processing.value = true;
  try {
    if (!formData.value) return;

    const payload: EditMerchantUserPayloadInterface = {
      userId: props.user.user_id,
      merchantUserId: props.user.id,
      first_name: formData.value.first_name!,
      last_name: formData.value.last_name!,
      email: formData.value.email!,
      phoneId: props.user.phone_number.id,
      phone_number: formData.value.phone!,
      user_type: user_type.value,
    };

    const response = await store.dispatch('MerchantPortal/dispatchEditMerchantUser', payload);

    if (response === 200) {
      store.dispatch('Ui/addGlobalAlert', {
        type: GlobalAlertTypes.SUCCESS,
        text: 'Your Changes Have Been Saved',
        timed: true,
      });
      emit('save');
      closeModal();
    }

    if (emailError.value) {
      disableSaveButton.value = true;
    }
  } finally {
    processing.value = false;
  }
};

const closeModal = () => emit('close');

onBeforeMount(() => {
  formData.value = {
    id: props.user.user_id,
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    email: props.user.email,
    phone: props.user.phone_number.phone_number,
  };

  switch (props.user.role.name) {
    case 'merchant-admin':
      user_type.value = 'Admin';
      break;
    case 'merchant-sales-manager':
      user_type.value = 'Salesmanager';
      break;
    case 'merchant-sales-rep':
      user_type.value = 'Salesrep';
      break;
    default:
      user_type.value = 'Salesperson';
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
@import "@/assets/scss/mixins/mixins";

.custom-button {
  @include user-management-custom-button;
}
</style>
