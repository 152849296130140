<template>
  <v-card class="custom-card">
    <v-card-title class="table-title">
      <span class="table-header">{{ title }}</span>
      <span v-if="failedTransactions" class="additional-text">
        - Transactions displayed here have been reversed due to an ACH return.
        An email was sent to notify all account admins for any failed transaction(s).
      </span>
    </v-card-title>
    <v-data-table
      class="statements-table"
      :headers="headers"
      :items="transactions"
      :items-per-page="-1"
      hide-default-footer>
      <!-- eslint-disable vue/valid-v-slot -->
      <template v-slot:item.transaction_date="{ item }">
        {{ date(item.transaction_date) }}
      </template>
      <template v-slot:item.consumer_name_and_loan_num="{ item }">
        <span v-private>{{ item.consumer_name }}</span>
        {{ item.consumer_loan_number }}
      </template>
      <template v-slot:item.total_transaction_amount="{ item }">
        <span v-private>{{ CurrencyFormatLong(item.total_transaction_amount) }}</span>
      </template>
      <template v-slot:item.merchant_fee_amount="{ item }">
        <span v-private>{{ CurrencyFormatLong(item.merchant_fee_amount) }}</span>
      </template>
      <template v-slot:item.net_merchant_receipt="{ item }">
        <span v-private>{{ CurrencyFormatLong(item.net_merchant_receipt) }}</span>
      </template>
      <template v-slot:item.merchant_fee_percentage="{ item }">
        {{ Percentage(item.merchant_fee_percentage) }}
      </template>
      <!-- eslint-enable -->
    </v-data-table>
  </v-card>
</template>

<script lang="ts">
import { TransactionDetailsInterface } from '@/interfaces/statements/StatementDetailsInterface';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import getHeaders from '@/utils/GetTableHeaders';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import Percentage from '@/filters/Percentage';
import DATE_CONSTANTS from '@/constants/DateConstants';
import { dateToFormat } from '@/helpers/DateTimeHelper';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'StatementTransactions',
  props: {
    transactions: { type: Array as PropType<TransactionDetailsInterface[]>, required: true },
    failedTransactions: { type: Boolean, default: false },
  },
  data() {
    const headers: Array<TableHeaderInterface> = getHeaders([
      {
        title: 'Date',
        value: 'transaction_date',
        width: '6.875rem',
      }, {
        title: 'Consumer Name & Loan Number',
        value: 'consumer_name_and_loan_num',
        width: '23%',
      }, {
        title: 'Loan Product',
        value: 'consumer_loan_product',
        width: '12%',
      }, {
        title: 'Transaction Description',
        value: 'transaction_description',
        width: '16%',
      }, {
        title: 'Transaction Amount',
        value: 'total_transaction_amount',
        align: 'end',
        width: '13%',
      }, {
        title: 'Fee %',
        value: 'merchant_fee_percentage',
        align: 'end',
        width: '7%',
      }, {
        title: 'Fee Amount',
        value: 'merchant_fee_amount',
        align: 'end',
        width: '8%',
      }, {
        title: 'Net Merchant Receipt',
        value: 'net_merchant_receipt',
        align: 'end',
        width: '14%',
      },
    ]);
    return {
      headers,
    };
  },
  computed: {
    title(): string {
      return this.failedTransactions ? 'Failed Transactions' : 'Statement Details';
    },
  },
  methods: {
    CurrencyFormatLong,
    Percentage,
    date(txn_date: string): string {
      return dateToFormat(txn_date, DATE_CONSTANTS.dateFormatSlashes);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';
@import "@/assets/scss/statement-details.scss";
@import "@/assets/scss/table-default";

.v-card-title.table-title {
  .additional-text {
    font-weight: normal;
    text-indent: 0.2rem;
  }
}

.statements-table {
  word-break: normal;
}
</style>
