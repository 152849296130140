<template>
  <div class="inline-inputs owners-list owners-list--inline">
    <merchant-onboarding-wizard-owner-card
      v-for="(owner, index) in ownersData"
      :key="index"
      :active-owner="activeOwner"
      :in-place-input="true"
      :index="index"
      :on-save-owner="saveOwner"
      :owner="owner"
      :from-verify-details="fromVerifyDetails"
      :otp-simple-design="otpSimpleDesign"
      :enable-ownership-text="enableOwnershipText"
      :sub-step="true" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MerchantOnboardingWizardOwnerCard
  from '@/components/Merchant/OnboardingWizard/Owner/Card.vue';
import { OwnerDataInterface } from '@/interfaces/merchantOnboardingWizard/OwnerDataInterface';

export default defineComponent({
  name: 'OwnersList',

  components: { MerchantOnboardingWizardOwnerCard },

  props: {
    fromVerifyDetails: { type: Boolean, default: false },
    otpSimpleDesign: { type: Boolean, default: false },
    enableOwnershipText: { type: Boolean, default: true },
  },

  data: () => ({
    ownersData: [] as Array<OwnerDataInterface>,
    activeOwner: -1,
  }),

  computed: {
    storeOwnerData() {
      return this.$store.getters['MerchantOnboarding/getCombinedOwners'];
    },
  },

  watch: {
    storeOwnerData() {
      this.populateOwnerData();
    },
  },

  mounted() {
    this.populateOwnerData();
  },

  methods: {
    populateOwnerData() {
      const ownerIds = this.ownersData.map(owner => owner.id);
      this.storeOwnerData.forEach((owner: OwnerDataInterface) => {
        if (!ownerIds.includes(owner.id)) {
          this.ownersData.push(owner);
          ownerIds.push(owner.id);
        }
      });
    },

    saveOwner(owner: OwnerDataInterface, index: number): void {
      this.ownersData[index] = owner;

      this.$store.commit(
        'MerchantOnboarding/setCombinedOwners',
        this.ownersData,
      );
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import '@/assets/scss/variables/_custom-variables';

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.standard-wizard {
  &__step {
    @include standard-wizard-form-inline-split;
  }
}

.owners-list {
  &--inline {
    :deep(.owners-list__item__title) {
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.5rem;
      font-family: $font-family-sans-serif;
    }
  }

  &__item {
    margin-bottom: 2rem;
  }
}

</style>
