<template>
  <standard-wizard-message
    :message="errorMessage"
    data-test="invalidMsg" />
</template>

<script lang="ts">
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConsumerInvalidLinkMsg',
  components: {
    StandardWizardMessage,
  },
  data() {
    return {
      errorMessage: 'We\'re sorry but it appears you have an invalid link.',
    };
  },
});
</script>
