import { ActionContext } from 'vuex';
import { LoanApplicationStateInterface } from '@/interfaces/states/LoanApplicationStateInterface';
import toLower from 'lodash/toLower';
import axios from 'axios';
import { getAxiosError } from '@/api/utils';

export async function validateMerchant(
  context: ActionContext<LoanApplicationStateInterface, any>,
  merchantId: string,
) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('setMerchantId', merchantId);

  let response: any = {};
  let responseStatus: number = 0;

  try {
    response = await axios.get(`/api/v1/merchant/${merchantId}/validate-merchant/`);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;

      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (responseStatus === 200) {
      context.commit('setLoanApplicationWizardData', { is_valid: true, validated: true });
    } else if (responseStatus === 403) {
      context.commit('setLoanApplicationWizardData', { is_valid: false, validated: true });
    } else if (responseStatus === 404) {
      context.commit('setMerchantId', false);
    }
  }

  return responseStatus;
}

export async function dispatchSetLoanApplicationWizardStep(
  context: ActionContext<LoanApplicationStateInterface, any>,
  step: number,
) {
  context.commit('setLoanApplicationWizardStep', step);
}

export async function dispatchSendInvitation(
  context: ActionContext<LoanApplicationStateInterface, any>,
  newLoanApplicationDetails: any,
) {
  context.commit('Ui/setErrors', {}, { root: true });

  const { email, phone_number } = newLoanApplicationDetails;

  const merchantUuid = context.getters.getMerchantId;

  let response: any = {};
  let responseStatus: number = 0;

  const payload: any = {};

  payload.merchant = merchantUuid;

  if (email) {
    payload.email = toLower(email);
  }

  if (phone_number) {
    payload.phone = phone_number;
  }

  try {
    response = await axios.post('/api/v1/consumer/invitation-widget/', payload);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;

      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }
  }

  return responseStatus;
}
