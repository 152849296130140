<template>
  <standard-wizard-message
    :message="message"
    :title="title"
    :no-border="true"
    :is-error-msg="false"
    data-test="completeLaterMsg" />
</template>

<script lang="ts">
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CompleteApplicationLaterMsg',
  components: {
    StandardWizardMessage,
  },
  props: {
    email: { required: true, type: String },
  },
  data() {
    return {
      title: 'Thanks!',
    };
  },
  computed: {
    message(): string {
      return 'We have securely stored your information, and we have emailed you a link'
        + ` at ${this.email} so you can complete your application later.`;
    },
  },
});
</script>

<style lang="scss" scoped>
.standard-wizard {
  &__message {
    &--no-border {
      :deep(.card) {
        box-shadow: none;
        .card-body {
          .card-title {
            font-size: 1.25rem;
          }
          .card-text {
            color: var(--grayscale-color-1);
            font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
