import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _directive_mask = _resolveDirective("mask")!
  const _directive_fs_exclude = _resolveDirective("fs-exclude")!

  return _withDirectives((_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ ref: "inputField" }, _ctx.$attrs, {
    autocomplete: "off",
    "append-inner-icon": _ctx.show ? 'mdi-eye-off' : 'mdi-eye',
    "data-test": _ctx.dataTestAttr,
    label: _ctx.labelAttr,
    variant: _ctx.inPlace ? '' : 'outlined',
    required: _ctx.required,
    rules: [
      _ctx.isRequired,
      _ctx.inputValidation(_ctx.modelValue, _ctx.errorMessages),
      _ctx.validateLength,
    ],
    type: _ctx.show ? 'text' : 'password',
    "model-value": _ctx.modelValue,
    "validate-on": "blur",
    "onClick:appendInner": _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = !_ctx.show)),
    onCopy: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
  }), null, 16, ["append-inner-icon", "data-test", "label", "variant", "required", "rules", "type", "model-value"])), [
    [_directive_mask, _ctx.inputMask],
    [_directive_fs_exclude]
  ])
}