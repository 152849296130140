import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c7e8f66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "consumer-header" }
const _hoisted_2 = {
  class: "consumer-header__appid",
  "data-test": "appId"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "consumer-header__actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_merchant_name = _resolveComponent("merchant-name")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_consumer_loan_apply_wizard_cancel_application_modal = _resolveComponent("consumer-loan-apply-wizard-cancel-application-modal")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_v_container, { class: "consumer-header__container" }, {
      default: _withCtx(() => [
        (_ctx.merchantName)
          ? (_openBlock(), _createBlock(_component_merchant_name, {
              key: 0,
              "merchant-name": _ctx.merchantName,
              small: ""
            }, null, 8, ["merchant-name"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.consumerHumanReadableApplicationIdLabel)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " ID: " + _toDisplayString(_ctx.consumerHumanReadableApplicationIdLabel), 1))
            : _createCommentVNode("", true)
        ]),
        (_ctx.cancelButtonEnabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_v_divider, { vertical: "" }),
              _createVNode(_component_custom_button, {
                "data-test": "cancelBtn",
                variant: "text",
                onClick: _ctx.openCancelApplicationModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Cancel ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.cancelButtonEnabled)
      ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_cancel_application_modal, {
          key: 0,
          ref: "modal",
          "on-cta": _ctx.cancelApplication
        }, null, 8, ["on-cta"]))
      : _createCommentVNode("", true)
  ]))
}