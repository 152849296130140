<template>
  <standard-dialog
    :dialog="showDialog"
    :hide-header-close="true"
    :show-actions="false"
    :persistent="true">
    <template #body>
      <div>
        <custom-alert type="warning" class="mb-8">
          <p class="mb-5">
            The bank account information you entered doesn't match the
            previously connected account ending in {{ lastFour }}.
          </p>
          <p class="mb-0">
            If you do not wish to replace your currently verified bank account please cancel.
          </p>
        </custom-alert>
      </div>

      <custom-button
        full-width
        class="mb-8"
        @click="$emit('cancel')">
        I Want To Cancel
      </custom-button>

      <custom-button
        full-width
        variant="secondary"
        @click="$emit('proceed')">
        I Want To Replace Account
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';

export default defineComponent({
  name: 'VerifyReplaceBankAccountDialog',
  components: {
    StandardDialog,
    CustomButton,
    CustomAlert,
  },
  props: {
    showDialog: { type: Boolean, default: false },
    lastFour: { type: String, required: true },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
</style>
