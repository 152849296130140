import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f68a409"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page page--consumer portal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portal_header = _resolveComponent("portal-header")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_main_footer = _resolveComponent("main-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_portal_header, { class: "portal__header" }),
    _createVNode(_component_v_container, { class: "portal__container" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { justify: "center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              class: "button-container",
              lg: "5",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_custom_button, {
                  label: "Loan Apply",
                  onClick: _ctx.gotoLoanApply
                }, null, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_router_view),
    _createVNode(_component_main_footer, { class: "portal__footer" })
  ]))
}