<template>
  <standard-dialog
    content-class="selected-loan-product"
    :dialog="dialog"
    :block-buttons="false"
    :cancel-disabled="processing"
    :cta-disabled="loading"
    :on-cta="onCta"
    :on-cancel="onCancel"
    :show-cancel-cta="false"
    :show-title="false"
    :hide-header="showLoader"
    :show-progress-indicator="showLoader"
    :persistent="true"
    button-label="Apply">
    <template #body>
      <div
        class="fs-unmask simple-card__body-text"
        data-test="confirmationModal">
        <v-row>
          <v-col>
            <p class="body-text-large confirm-selection">
              Confirm Your Selection:
            </p>

            <v-card class="elevation-4 short-description-container" :class="{ 'is-loading': loading }">
              <v-progress-circular
                v-if="loading"
                class="progress-indicator"
                :size="40"
                indeterminate />

              <p v-else class="body-text-large short-description">
                {{ rateSheetOption.short_description }}
              </p>
            </v-card>

            <p v-if="!isCaptureIdEnabled" class="body-text-large proceed-text">
              By clicking "Apply" we make a hard pull of credit and it may impact your credit score.
            </p>
          </v-col>
        </v-row>
      </div>
    </template>

    <template #actions>
      <custom-button
        data-test="ctaBtn"
        :disabled="ctaDisabled"
        class="mt-4"
        full-width
        @click="onButtonClick">
        Apply
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import StandardDialog from '@/components/Dialogs/index.vue';
import GetProcessing from '@/mixins/GetProcessing';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'SelectedLoanProductConfirmationModal',
  components: {
    CustomButton,
    StandardDialog,
  },
  mixins: [
    GetProcessing,
    FeatureFlagsMixin,
  ],
  props: {
    dialog: { type: Boolean, default: false },
    onCta: { type: Function as PropType<() => void>, default: () => null },
    onCancel: { type: Function as PropType<() => void>, default: () => null },
    rateSheetOption: { type: Object, required: true },
    showLoader: { type: Boolean, default: false },
  },
  data() {
    return {
      btnDisabled: false,
    };
  },
  computed: {
    ctaDisabled(): boolean {
      return this.btnDisabled || this.processing || this.showLoader;
    },
    loading(): boolean {
      return this.processing || this.showLoader;
    },
    isCaptureIdEnabled(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.CAPTURE_ID);
    },
  },
  methods: {
    onButtonClick() {
      if (!this.ctaDisabled) {
        this.btnDisabled = true;
        this.onCta();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.is-loading {
  text-align: center;

  .progress-indicator {
    margin: 1rem auto;
  }
}

.simple-card__body-text {
  p {
    color: var(--grayscale-color-1);
    text-align: left;
  }

  .confirm-selection {
    font-size: 1.125rem !important;
    font-weight: 700;
  }

  .short-description-container {
    margin: 1rem 0;

    .short-description {
      margin-bottom: 0;
      padding: 1rem;
      font-size: 0.875rem !important;
      font-weight: 400;
    }
  }
}

.proceed-text {
  padding-top: 1rem;
  margin-bottom: 0;
  font-size: 0.875rem !important;
}

.modal-dialog {
  .standard-modal__actions {
    .standard-modal__actions__cta {
      justify-self: end;
    }
  }
}
</style>
