import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_tax_id_input = _resolveComponent("tax-id-input")!
  const _component_ssn_input = _resolveComponent("ssn-input")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    class: "fs-unmask",
    "data-test": "MOB-step3-substep4",
    onSubmit: _withModifiers(_ctx.goToNextSubStep, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_select_input, {
            ref: "businessStructure",
            modelValue: _ctx.stepData.businessStructure,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stepData.businessStructure) = $event)),
            items: _ctx.optionsBusinessStructure,
            "data-test": "businessInput",
            label: "Business Structure"
          }, null, 8, ["modelValue", "items"]),
          _createVNode(_component_tax_id_input, {
            modelValue: _ctx.stepData.federalTaxId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.stepData.federalTaxId) = $event)),
            required: ""
          }, null, 8, ["modelValue"]),
          (_ctx.showSsnInput)
            ? (_openBlock(), _createBlock(_component_ssn_input, {
                key: 0,
                modelValue: _ctx.stepData.ssn,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.stepData.ssn) = $event)),
                required: "",
                label: "Social Security Number"
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_custom_button, {
            disabled: _ctx.nextSubstepDisabled,
            "data-test": "nextBtn",
            "full-width": "",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Next ")
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}