import { defineComponent } from 'vue';
import { honeypot } from 'honeypot-run';
import { PageTypes, PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'HoneypotTrackMixin',

  data() {
    return {
      hpEventName: '',
      hpStep: -1,
    };
  },

  methods: {
    async trackEvent(eventName: string, page: PageTypesShorthand, step: number = -1) {
      let appIdToUse: string | undefined;
      let overallProcessName: string = '';

      if (!window.honeypot?.didInit) return;

      switch (page) {
        case PageTypesShorthand.MOB:
          appIdToUse = this.$store.getters['MerchantOnboarding/getUserVerifyData']?.merchantUid;
          overallProcessName = PageTypes.MOB;
          break;
        case PageTypesShorthand.MP:
          appIdToUse = this.$store.getters['MerchantPortal/getMerchantData'].merchant.uuid;
          overallProcessName = PageTypes.MP;
          break;
        case PageTypesShorthand.COB:
          appIdToUse = this.$store.getters['Consumer/getConsumerApplicationId'];
          overallProcessName = PageTypes.COB;
          break;
        case PageTypesShorthand.BMOB:
          overallProcessName = PageTypes.BMOB;
          break;
        default:
          break;
      }

      const flow = await honeypot.flow(overallProcessName, appIdToUse || '');
      const eventData: any = { page };

      if (step !== -1) {
        eventData.step = step;
      }
      flow.step(eventName).complete(eventData);
    },
  },
});
