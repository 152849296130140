import { defineComponent } from 'vue';
import { VALIDATION_TYPES } from '@/constants/ValidationConstants';

export default defineComponent({
  computed: {
    validationTypes() {
      return VALIDATION_TYPES;
    },
  },
});
