import { useStore } from 'vuex';

export const useFeatureFlag = () => {
  const store = useStore();

  const isFeatureEnabled = (featureName: string): boolean => {
    return store.getters['Config/getFeatureFlags'].includes(featureName);
  };

  return { isFeatureEnabled };
};
