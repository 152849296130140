import UsersRolesEnum from '@/enums/UsersRolesEnum';

const rolesMap = {
  [UsersRolesEnum.CORPORATE_ADMIN]: 'Corporate Admin',
  [UsersRolesEnum.ADMIN]: 'Admin',
  [UsersRolesEnum.SALES_MANAGER]: 'Manager',
  [UsersRolesEnum.SALES_REP]: 'Representative',
  [UsersRolesEnum.SALES]: 'Sales',
};

export default rolesMap;
