import { ConsumerStateInterface } from '@/interfaces/states/ConsumerStateInterface';
import { FraudCheckInterface } from '@/interfaces/consumer/FraudCheckInterface';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import CreditEnginePullTypes from '@/enums/CreditEngine/PullTypes';
import CreditEngineDecisions from '@/enums/CreditEngine/Decisions';

export function getBorrowerAgreementSpecificStateError(state: ConsumerStateInterface) {
  return state.borrowerAgreementSpecificStateError;
}

export function getFraudCheckError(state: ConsumerStateInterface) {
  return state.fraudCheckError;
}

export function getLoanOptions(state: ConsumerStateInterface) {
  return state.loanOptions;
}

export function getShowDisclaimerPage(state: ConsumerStateInterface) {
  return state.showDisclaimerPage;
}

export function getLoanAgreement(state: ConsumerStateInterface) {
  return state.loanAgreement;
}

export function getLoanApplyWizardStep(state: ConsumerStateInterface) {
  return state.loanApplyWizardStep;
}

export function getLoanApplyWizardReEntryStep(state: ConsumerStateInterface) {
  return state.loanApplyWizardReEntryStep;
}

export function getLoanApplyIsReEntry(state: ConsumerStateInterface) {
  return state.loanApplyReEntry;
}

export function getFetchingLoans(state: ConsumerStateInterface) {
  return state.fetchingLoans;
}

export function getMerchantId(state: ConsumerStateInterface) {
  return state.merchantId;
}

export function getInvitation(state: ConsumerStateInterface) {
  return state.invitation;
}

export function getConsumerApplicationId(state: ConsumerStateInterface) {
  return state.consumerApplicationId;
}

export function getConsumerHumanReadableApplicationId(state: ConsumerStateInterface) {
  return state.consumerHumanReadableApplicationId;
}

export function getFraudCheck(state: ConsumerStateInterface): FraudCheckInterface {
  return state.fraudCheck;
}

export function getMerchantName(state: ConsumerStateInterface) {
  return state.merchantName;
}

export function getLoanApplyWizardData(state: ConsumerStateInterface): LoanApplyDataInterface {
  return state.loanApplyWizardData;
}

export function getApplicationCanceledData(state: ConsumerStateInterface) {
  return state.applicationCanceled;
}

export function getExpiredLink(state: ConsumerStateInterface) {
  return state.expiredLink;
}

export function getCreditEngineCheck(state: ConsumerStateInterface) {
  return state.creditEngineCheck;
}

export function getProcessingCreditEngine(state: ConsumerStateInterface) {
  return state.processingCreditEngine;
}

export function getSelectedLoan(state: ConsumerStateInterface): RateSheetData {
  return state.selectedLoan;
}

export function getExistingApplications(state: ConsumerStateInterface) {
  return state.existingApplications;
}

export function getIsApplicationValid(state: ConsumerStateInterface) {
  return state.applicationValid;
}

export function getLenderName(state: ConsumerStateInterface) {
  return state.lenderName;
}

export function getLenderPrivacyPolicyUrl(state: ConsumerStateInterface) {
  return state.lenderPrivacyPolicyUrl;
}

export function getAreLendersAvailable(state: ConsumerStateInterface) {
  return state.areLendersAvailable;
}

export function getIdTypes(state: ConsumerStateInterface) {
  return state.idTypes;
}

export function getIsApplicationLoading(state: ConsumerStateInterface) {
  return state.isLoading;
}

export function getLenderInfo(state: ConsumerStateInterface) {
  return state.lenderInfo;
}

export function getOnboardingDetails(state: ConsumerStateInterface) {
  return state.portalOnboarding;
}

export function canShowCompleteLater(state: ConsumerStateInterface) {
  return state.applicationValid && !state.applicationCanceled.canceled;
}

export function getCanApplicationBeCancelled(
  state: ConsumerStateInterface,
  getters: any,
  rootState: any,
  rootGetters: any,
): boolean {
  // application cancelled
  if (getters.getApplicationCanceledData.canceled) {
    return false;
  }

  // application expired
  if (getters.getExpiredLink) {
    return false;
  }

  // loaders
  if (
    getters.getIsApplicationLoading
    || getters.getProcessingCreditEngine
    || getters.getFetchingLoans
  ) {
    return false;
  }

  const step = getters.getLoanApplyWizardStep;

  const isSoftDeclineFrozenFile = state.creditEngineCheck[CreditEnginePullTypes.SOFT].decline_type === 'frozen_file';
  const isHardDeclineFrozenFile = state.creditEngineCheck[CreditEnginePullTypes.HARD].decline_type === 'frozen_file';

  const softDecision = state.creditEngineCheck[CreditEnginePullTypes.SOFT].decision;
  const hardDecision = state.creditEngineCheck[CreditEnginePullTypes.HARD].decision;

  switch (step) {
    case 0:
      return false;

    case 1:
      return false;

    case 2:
      return false;

    case 3:
      return false;

    case 5:
      // Consumer has no lender in geographic area
      return getters.getAreLendersAvailable;

    case 9:
      if (rootGetters['Auth/getGucaData'].decline_application
        || (rootGetters['Auth/getGucaData'].positive_hit && rootGetters['Auth/getGucaData'].originating_merchant_hit)) {
        return false;
      }

      if (isSoftDeclineFrozenFile) {
        return true;
      }
      // fraud check fail
      if (getters.getFraudCheck.risk_decision === 'fail' || getters.getFraudCheck.missing_parameter) {
        return false;
      }

      // non decisionable, frozen credit file, hard declined
      if ([
        CreditEngineDecisions.NON_DECISION,
        CreditEngineDecisions.DECLINED,
        CreditEngineDecisions.DECLINED_SSN_MISMATCH,
        CreditEngineDecisions.HARD_DECLINED,
      ].includes(softDecision)) {
        return false;
      }

      // no loan offers or approved for less
      if ((softDecision === CreditEngineDecisions.APPROVED && getters.getLoanOptions.length === 0)
        || softDecision === CreditEngineDecisions.APPROVED_FOR_LESS) {
        return false;
      }
      break;

    case 10:
      if (isHardDeclineFrozenFile) {
        return true;
      }

      if ([
        CreditEngineDecisions.NON_DECISION, // more info: BELLE-4502
        CreditEngineDecisions.DECLINED,
        CreditEngineDecisions.HARD_DECLINED,
      ].includes(hardDecision)) {
        return false;
      }
      break;

    case 13:
      return !getters.isApplicationCompleted;

    case 14:
      // done
      return false;

    default:
      return true;
  }

  return true;
}

export function isApplicationCompleted(state: ConsumerStateInterface): boolean {
  return state.applicationCompleted;
}

export function getCompleteLaterClicked(state: ConsumerStateInterface): boolean {
  return state.completeLaterClicked;
}
