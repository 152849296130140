<template>
  <standard-dialog
    :dialog="show"
    :on-cancel="emitClose">
    <template #title>
      <strong>Example Check</strong>
    </template>
    <template #body>
      <img
        src="@/assets/images/check_example.jpg"
        alt="Example check"
        loading="eager">
    </template>
    <template #actions>
      <custom-button full-width @click="emitClose">
        Close
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardDialog from '@/components/Dialogs/index.vue';

export default defineComponent({
  name: 'ExampleCheckDialog',

  components: {
    CustomButton,
    StandardDialog,
  },

  props: {
    show: { type: Boolean, default: false },
  },

  methods: {
    emitClose() {
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
