import { defineComponent } from 'vue';
import get from 'lodash/get';

export default defineComponent({
  computed: {
    isBrandProFund(): boolean {
      const sponsorType = get(this.$store.getters['Sponsor/getSponsorData'], 's_type');
      const brandName = this.$store.getters['Ui/getBrandingObject'].brand_name;
      return sponsorType === 'IC1' && brandName === 'ProFund';
    },
  },
});
