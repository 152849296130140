import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_standard_dialog = _resolveComponent("standard-dialog")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createBlock(_component_standard_dialog, {
    dialog: _ctx.show,
    "hide-header-close": true,
    "show-cancel-cta": false,
    "show-actions": false,
    "on-cancel": _ctx.emitClose
  }, {
    body: _withCtx(() => [
      _createElementVNode("p", null, [
        _createTextVNode(" Please confirm if the amount entered, "),
        _withDirectives((_openBlock(), _createElementBlock("span", null, [
          _createTextVNode(_toDisplayString(_ctx.formattedValue), 1)
        ])), [
          [_directive_private]
        ]),
        _createTextVNode(" is your Annual Household Gross Income. ")
      ]),
      _createVNode(_component_custom_button, {
        class: "edit-btn",
        "data-test": "editIncomeBtn",
        "full-width": "",
        size: "small",
        variant: "primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Edit Income ")
        ]),
        _: 1
      }),
      _createVNode(_component_custom_button, {
        "data-test": "inputHelperModalCta",
        "full-width": "",
        size: "small",
        variant: "secondary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Confirm Income ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["dialog", "on-cancel"]))
}