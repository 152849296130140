import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_stepper_item = _resolveComponent("v-stepper-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_stepper_header = _resolveComponent("v-stepper-header")!
  const _component_v_stepper = _resolveComponent("v-stepper")!

  return (_openBlock(), _createBlock(_component_v_stepper, {
    modelValue: _ctx.wizardStep,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.wizardStep) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_stepper_header, { class: "standard-wizard__footer-navigation" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_stepper_item, {
            complete: _ctx.wizardStep > 1,
            value: 1,
            color: "var(--secondary-color)"
          }, null, 8, ["complete"]),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_stepper_item, {
            complete: _ctx.wizardStep > 2,
            value: 2,
            color: "var(--secondary-color)"
          }, null, 8, ["complete"]),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_stepper_item, {
            complete: _ctx.wizardStep > 3,
            value: 3,
            color: "var(--secondary-color)"
          }, null, 8, ["complete"]),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_stepper_item, {
            complete: _ctx.wizardStep > 4,
            value: 4,
            color: "var(--secondary-color)"
          }, null, 8, ["complete"]),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_stepper_item, {
            complete: _ctx.wizardStep > 5,
            value: 5,
            color: "var(--secondary-color)"
          }, null, 8, ["complete"]),
          _createVNode(_component_v_divider),
          (_ctx.IC1)
            ? (_openBlock(), _createBlock(_component_v_stepper_item, {
                key: 0,
                complete: _ctx.wizardStep > 6,
                value: 6,
                color: "var(--secondary-color)"
              }, null, 8, ["complete"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}