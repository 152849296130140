<template>
  <ul class="tab-navigation">
    <slot />
  </ul>
</template>

<script lang="ts">
import { defineComponent, provide, ref } from 'vue';

export default defineComponent({
  props: {
    modelValue: { type: String, default: '' },
  },

  setup(props, { emit }) {
    const selectedTab = ref(props.modelValue);

    const updateSelectedTab = (tab: string) => {
      selectedTab.value = tab;
      emit('update:model-value', selectedTab.value);
    };

    provide('selectedTab', selectedTab);
    provide('updateSelectedTab', updateSelectedTab);
  },
});
</script>

<style lang="scss" scoped>
.tab-navigation {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1.5rem;
}
</style>
