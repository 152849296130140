import { Directive, DirectiveBinding } from 'vue';

const fsExclude: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    // This accounts for "v-fs-exclude" and 'v-fs-exclude="true"' in pieces of logic where
    // adding the exclusion is calculated by logic.
    if (binding.value === true || binding.value === undefined) {
      if (el.classList.contains('v-text-field')) {
        el.querySelector('input')?.classList.add('fs-exclude');
      } else {
        el.classList.add('fs-exclude');
      }
    }
  },
};

export default fsExclude;
