<template>
  <standard-wizard-message
    :message="errorMessage"
    :title="title"
    hide-wrapper
    data-test="fraudMsg" />
</template>

<script lang="ts">
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FraudError',
  components: {
    StandardWizardMessage,
  },
  computed: {
    title() {
      return 'Sorry, there appears to be a problem.';
    },
    errorMessage() {
      return 'We have contacted your salesperson to let them know. We will have them reach out to you as soon as possible.';
    },
  },
});
</script>
