<template>
  <section class="merchant__intro">
    <v-row class="merchant__intro__body">
      <v-col class="merchant__intro__body__info">
        <img
          :src="logoUrl"
          alt="Momnt Logo"
          class="merchant__intro__body__logo">

        <h2 class="merchant__intro__body__info__title">
          You Start the Deal.<br>We'll Help You Close It.
        </h2>

        <ul class="merchant__intro__body__info__list">
          <li>Easy to use and mobile-friendly lending</li>
          <li>Closed-loop payments ensure you get paid</li>
          <li>Zero start-up cost and low risk</li>
        </ul>

        <custom-button
          class="merchant__intro__body__info__button"
          @click="showBeginOnboarding = true">
          Begin Onboarding
        </custom-button>
      </v-col>

      <img
        :src="bgImage"
        alt="Merchant Begin Onboarding Graphic"
        class="merchant__intro__bg-img">

      <merchant-begin-onboarding :show-bmob="showBeginOnboarding" @close="closeModal" />
    </v-row>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MerchantBeginOnboarding from '@/components/Merchant/BeginOnboardingWizard/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'MerchantIntro',

  components: {
    MerchantBeginOnboarding,
    CustomButton,
  },

  data: () => ({
    showBeginOnboarding: false,
  }),

  computed: {
    bgImage() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/merchant/momnt-begin-onboarding-graphic.png');
    },

    logoUrl(): string {
      return this.$store.getters['Ui/getBrandingHeaderLogo'];
    },
  },

  methods: {
    closeModal() {
      this.showBeginOnboarding = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/pages/merchant";
@import "@/assets/scss/mixins/media_queries";

.merchant {
  &__intro {
    position: relative;
    text-align: left;
    color: $dark-blue;
    padding: 3rem 0 6rem 0;
    min-height: 55rem;

    &__bg-img {
      width: 50%;
      height: 100%;
    }

    &__body {
      padding: 1rem 2rem 0 2rem;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__logo {
        align-self: flex-start;
        padding: 2.5rem;
        width: 20rem;
        height: auto;
      }

      &__info {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title {
          font-size: 2.375rem;
          font-weight: 800;
          line-height: 1.3;
          margin-bottom: 1.5rem;
          line-height: normal;
        }

        &__button {
          margin: 3rem 0;
          width: 500px;
        }

        &__list {
          margin: 0;
          padding: 0;

          li {
            font-size: 1.4375rem;
            font-weight: normal;
            list-style: none;
            position: relative;
            padding-left: 2rem;
            margin-bottom: .8rem;
            color: var(--secondary-color);

            &:before {
              background-color: var(--secondary-color);
              content: "";
              display: inline-block;
              height: 1rem;
              width: 1rem;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 0;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down-md {
  .merchant {
    &__intro {
      padding: 0;
      display: flex;
      flex-direction: column-reverse;

      &__bg-img {
        width: 100%;
      }

      &__body {
        padding: 0 2.5rem;
        margin: 0 0 3.5rem;
        margin-bottom: 0;

        &__logo {
          display: block;
          width: 100%;
          max-width: 11.625rem;
          padding: 2.5rem 2.5rem 0 0;
        }

        &__info {
          padding: 2.5rem 0 3.5rem;

          &__title {
            margin-top: 0;
            padding-top: 3.5rem;
          }

          &__button {
            width: 100%;
          }
        }
      }
    }
  }
}

@include mobile {
  .merchant {
    &__intro {
      &__body {
        padding: 0 1rem;

        &__logo {
          padding: 1rem 1rem 0 0;
        }

        &__info {
          padding: 1rem 0;
        }
      }
    }
  }
}
</style>
