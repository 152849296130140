import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7307346a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "customer-preference" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rate_sheet_options_data_table_customer_preference_item = _resolveComponent("rate-sheet-options-data-table-customer-preference-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerPreferences, (preference, index) => {
      return (_openBlock(), _createBlock(_component_rate_sheet_options_data_table_customer_preference_item, {
        key: index,
        "customer-preference": preference
      }, null, 8, ["customer-preference"]))
    }), 128))
  ]))
}