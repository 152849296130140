import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f742a3e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-left" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_standard_wizard_message = _resolveComponent("standard-wizard-message")!

  return (_openBlock(), _createBlock(_component_standard_wizard_message, { "data-test": "lockedMsg" }, {
    body: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, [
        _createTextVNode(" For security reasons we have locked your account. Please email "),
        _createElementVNode("a", { href: _ctx.supportMailTo }, _toDisplayString(_ctx.supportEmail), 9, _hoisted_2),
        _createTextVNode(" for assistance. ")
      ])
    ]),
    _: 1
  }))
}