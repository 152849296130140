import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ec01b92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "address-input-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_autocomplete_input = _resolveComponent("autocomplete-input")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_zip_code_input = _resolveComponent("zip-code-input")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combinedErrors, (error) => {
      return (_openBlock(), _createBlock(_component_v_alert, {
        key: error,
        icon: "mdi-alert-circle-outline",
        type: "error",
        color: "var(--error-color)",
        variant: "outlined"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(error), 1)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createVNode(_component_text_input, {
      class: _normalizeClass({ 'has-hint': _ctx.address1Hint }),
      "data-test": _ctx.address1DataTest,
      disabled: _ctx.disabled,
      "error-messages": _ctx.errors.address_1,
      hint: _ctx.address1Hint,
      "in-place": _ctx.inPlace,
      label: _ctx.address1Label,
      "persistent-hint": _ctx.address1HintPersistent,
      required: _ctx.required,
      rules: _ctx.validationRules('Address'),
      "model-value": _ctx.modelValue.address1,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeValue('address1', $event)))
    }, null, 8, ["class", "data-test", "disabled", "error-messages", "hint", "in-place", "label", "persistent-hint", "required", "rules", "model-value"]),
    _createVNode(_component_text_input, {
      "data-test": _ctx.address2DataTest,
      disabled: _ctx.disabled,
      "error-messages": _ctx.errors.address_2,
      "in-place": _ctx.inPlace,
      label: _ctx.address2Label,
      "model-value": _ctx.modelValue.address2,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeValue('address2', $event)))
    }, null, 8, ["data-test", "disabled", "error-messages", "in-place", "label", "model-value"]),
    _createVNode(_component_text_input, {
      "data-test": _ctx.cityDataTest,
      disabled: _ctx.disabled,
      "error-messages": _ctx.errors.city,
      "in-place": _ctx.inPlace,
      label: _ctx.cityLabel,
      required: _ctx.required,
      rules: _ctx.validationRules('City'),
      "model-value": _ctx.modelValue.city,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeValue('city', $event)))
    }, null, 8, ["data-test", "disabled", "error-messages", "in-place", "label", "required", "rules", "model-value"]),
    _createVNode(_component_v_row, { class: "state-zip-row" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_autocomplete_input, {
              "autoselect-match": "",
              class: "state-dropdown",
              disabled: _ctx.disabled,
              "data-test": "stateDrop",
              "custom-filter": _ctx.customStateFilter,
              "hide-dropdown-icon": "",
              "in-place": _ctx.inPlace,
              items: _ctx.USA_STATES,
              "item-text": "title",
              label: "State",
              required: _ctx.required,
              "model-value": _ctx.modelValue.state,
              attach: false,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeValue('state', $event)))
            }, null, 8, ["disabled", "custom-filter", "in-place", "items", "required", "model-value"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_zip_code_input, {
              disabled: _ctx.disabled,
              "error-messages": _ctx.errors.zip,
              "in-place": _ctx.inPlace,
              required: _ctx.required,
              "model-value": _ctx.modelValue.zipCode,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeValue('zipCode', $event)))
            }, null, 8, ["disabled", "error-messages", "in-place", "required", "model-value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.askConfirmation)
      ? (_openBlock(), _createBlock(_component_confirmation_modal, {
          key: _ctx.componentUpdate,
          dialog: _ctx.showConfirmation,
          address: _ctx.validatedAddress,
          "is-fs-excluded": _ctx.isFsExcluded,
          "confirmation-modal-header-text": _ctx.confirmationModalTitle,
          onConfirmed: _ctx.emitConfirmed,
          onDeclined: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('confirmation:declined')))
        }, null, 8, ["dialog", "address", "is-fs-excluded", "confirmation-modal-header-text", "onConfirmed"]))
      : _createCommentVNode("", true)
  ]))
}