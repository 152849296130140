<template>
  <v-text-field
    v-bind="$attrs"
    :data-test="dataTestAttr"
    :disabled="disabled"
    :label="labelAttr"
    :variant="variantAttr"
    :prepend-inner-icon="showIcon ? 'mdi-email' : ''"
    :required="required"
    :rules="[validateEmail]"
    :error-messages="errorMessages"
    type="email"
    validate-on="blur">
    <template #message="{ message }">
      <span>{{ message }}</span>
      <span v-if="externalValidation && externalValidation.suggestion">
        Did you mean
        <button
          style="color: var(--primary-color);"
          tabindex="0"
          @click="acceptSuggestion">
          {{ externalValidation.suggestion }}
        </button>?
      </span>
    </template>
  </v-text-field>
</template>

<script lang="ts">
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import emailValidator from '@/validators/email_address';
import { EmailValidationResult } from '@/interfaces/addressValidation/AddressDataInterface';
import { PropType, defineComponent } from 'vue';

interface Data {
  defaultLabelAttr: string;
  defaultDataTestAttr: string;
  errorMessages: string[];
}

export default defineComponent({
  name: 'EmailInput',
  mixins: [InputFieldMixin],
  props: {
    showIcon: { type: Boolean, default: false },
    isFsExcluded: { type: Boolean, default: true },
    externalValidation: { type: Object as PropType<Record<string, unknown> | null>, default: null },
    emailError: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  data(): Data {
    return {
      defaultLabelAttr: 'Email',
      defaultDataTestAttr: 'emailInput',
      errorMessages: [],
    };
  },
  computed: {
    variantAttr() {
      if (this.inPlace) {
        return this.disabled ? 'plain' : 'underlined';
      }
      return 'outlined';
    },
  },
  watch: {
    externalValidation: {
      handler(value: EmailValidationResult | null) {
        if (value && !value.is_valid) {
          if (value.suggestion) {
            this.errorMessages = ['The email address entered is invalid.'];
          } else {
            this.errorMessages = [
              'You have entered an invalid email address. Please enter a new email address to continue your application.',
            ];
          }
        } else {
          this.errorMessages = [];
        }
      },
      immediate: true,
    },
    emailError(value: string) {
      if (value) {
        this.errorMessages = [value];
      } else {
        this.errorMessages = [];
      }
    },
  },
  mounted() {
    if (!this.isFsExcluded) {
      this.$el.querySelector('input')?.classList.add('fs-exclude');
    } else {
      this.$el.querySelector('input')?.classList.add('fs-unmask');
    }
  },
  methods: {
    validateEmail(value: string | undefined): string | boolean {
      if (!value) return this.isRequired();

      if (!emailValidator(value)) return 'Please enter a valid email address.';

      return true;
    },
    acceptSuggestion() {
      this.$emit('update:model-value', this.externalValidation?.suggestion);
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-messages__message) {
  line-height: 1rem;
}
</style>
