import { toNumber } from 'lodash';

export default (ownerPercentage?: number | string, maxPercentage = 100): boolean => {
  if (ownerPercentage || ownerPercentage === 0) {
    const percentage: number = toNumber(ownerPercentage);

    return percentage === 0 || (percentage >= 10 && percentage <= maxPercentage);
  }
  return false;
};
