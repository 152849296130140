import { MerchantDetailsInterface } from '@/interfaces/merchant/MerchantDetailsInterface';
import { MerchantReEntryDataInterface } from '@/interfaces/merchant/MerchantReEntryDataInterface';
import { OwnerDataInterface } from '@/interfaces/merchantOnboardingWizard/OwnerDataInterface';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { MerchantOnboardingStateInterface } from '@/interfaces/states/MerchantOnboardingStateInterface';
import { OnboardingUserData } from '@/interfaces/merchantOnboardingWizard/OnboardingUserData';

import ResetModuleState from '@/utils/store/ResetModuleState';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const initialState = (): MerchantOnboardingStateInterface => ({
  redirectUrl: null,
  allAttributes: {
    legalBusinessName: '',
    doingBusinessAs: '',
    federalTaxId: '',
    ssn: '',
    websiteUrl: '',
    businessStartDate: '',
    annualSalesRevenue: 0,
    averageCustomerSpend: 0,
    offersFinancing: null,
    financingVolume: 0,
    businessStructure: '',
    paymentTypesAccepted: [],
    program: '',
    merchant_phone: '',
  },
  userVerifyData: {
    userId: 0,
    emailAddress: '',
    businessName: '',
    address1: '',
    address2: '',
    city: '',
    state: {
      title: '',
      value: '',
    },
    zip: '',
    merchantUid: '',
    merchantApplicationId: '',
    userMobile: '',
  },
  onboardingUserData: {} as OnboardingUserData,
  onboardingWizardStepTwoData: {
    password: '',
    passwordCheck: '',
  },
  combinedOwners: [] as Array<OwnerDataInterface>,
  rateSheets: [] as Array<RateSheetData>,
  merchantOnboardingWizardStep: 1,
  merchantOnboardingWizardSubStep: 2,
  processingApplication: false,
  applicationApproved: false, // TODO(vk): Seems to be always false, possibly remove
  applicationWaitingApproval: false,
  consent: false,
  moovUserTermsPage: 'https://moov.io/legal/platform-agreement/',
  programs: [],
  subPrograms: [],
  selectedPrograms: {},
  merchantDetails: {} as MerchantDetailsInterface,
  merchantReEntryData: {} as MerchantReEntryDataInterface,
  verifyFormsOpen: {
    userFormOpen: false,
    openOwnerForms: 0,
    businessFormOpen: false,
  },
  ownerIsSaved: false,
  applicationStatus: '',
  showMobStepper: true,
  passedPhoneVerification: false,
  showBackButtonPreventModal: false,
});

export default {
  namespaced: true,
  state: initialState() as MerchantOnboardingStateInterface,
  getters,
  mutations: {
    resetModuleState(state: any) {
      ResetModuleState(state, initialState());
    },
    ...mutations,
  },
  actions,
};
