import { toNumber } from 'lodash';

const numberToEnglish = (n: number | string) => {
  const string = n.toString();

  /* Array of units as words */
  const units = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ];

  /* Array of tens as words */
  const tens = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ];

  /* Array of scales as words */
  const scales = [
    '',
    'Thousand',
    'Million',
    'Billion',
    'Trillion',
    'Quadrillion',
    'Quintillion',
    'Sextillion',
    'Septillion',
    'Octillion',
    'Nonillion',
    'Decillion',
    'Undecillion',
    'Duodecillion',
    'Tredecillion',
    'Quatttuor-Decillion',
    'Quindecillion',
    'Sexdecillion',
    'Septen-decillion',
    'Octodecillion',
    'Novemdecillion',
    'Vigintillion',
    'Centillion',
  ];

  /* Split user arguemnt into 3 digit chunks from right to left */
  let start = string.length;
  let end;
  const chunks = [];
  let chunk;
  let ints;
  const words = [];

  /* Is number zero? */
  if (toNumber(string) === 0) {
    return 'zero';
  }

  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  const chunksLen = chunks.length;

  if (chunksLen > scales.length) {
    return '';
  }

  /* Stringify each integer in each chunk */
  for (let i = 0; i < chunksLen; i += 1) {
    chunk = toNumber(chunks[i]);

    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i].split('').reverse().map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if (scales[i]) {
        words.push(scales[i]);
      }

      /* Add unit word if array item exists */
      if (units[ints[0]]) {
        words.push(units[ints[0]]);
      }

      /* Add tens word if array item exists */
      if (tens[ints[1]]) {
        words.push(tens[ints[1]]);
      }

      /* Add hundreds word if array item exists */
      if (units[ints[2]]) {
        words.push(`${units[ints[2]]} hundred`);
      }
    }
  }

  return words.reverse().join(' ');
};

export default (value: string | number): string => numberToEnglish(value);
