<template>
  <div class="standard-error-page">
    <v-container class="text-center">
      <h1 class="main-title">
        Sorry!
      </h1>

      <p class="error-text">
        We experienced a technical error. For support please contact
        <a :href="emailLink">
          {{ email }}
        </a>
      </p>

      <custom-button v-if="!currentlyOnboarding" :href="backToPortalLink">
        Take Me Back To Merchant Portal
      </custom-button>
    </v-container>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SupportEmailMixin from '@/mixins/SupportEmailMixin';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import LocalStorageConstants from '@/constants/LocalStorageConstants';

export default defineComponent({
  name: 'StandardErrorPage',

  components: {
    CustomButton,
  },

  mixins: [
    SupportEmailMixin,
  ],

  data() {
    return {
      currentlyOnboarding: null as string | null,
    };
  },

  computed: {
    email(): string {
      return this.supportEmail;
    },

    emailLink(): string {
      return `mailto:${this.email}`;
    },

    backToPortalLink(): string {
      const brandingPortalLoginUrl = this.$store.getters['Ui/getBrandingObject']?.portal_login_url;

      return brandingPortalLoginUrl || this.$router.resolve({ name: 'merchant-login' }).href;
    },
  },

  async mounted() {
    this.currentlyOnboarding = localStorage.getItem(LocalStorageConstants.CURRENTLY_ONBOARDING);

    if (this.currentlyOnboarding === LocalStorageConstants.ONBOARDING.COB) {
      await this.$store.dispatch('Ui/getConsumerBrandingData');
    }
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-error-pages";
.standard-error-page {
  .main-title {
    color: var(--dark-color);
  }

  .error-text {
    margin: 3.5rem 0 4.5rem 0;
    color: var(--dark-color);
  }

  .custom-button {
    padding: 0 2rem !important;
  }
}
</style>
