<template>
  <simple-card class="fs-unmask gray-border merchant-portal-page-recent-activity section-card">
    <template #body>
      <transaction-table
        v-model:page="currentPage"
        title="Recent Transactions"
        :is-mobile="isMobile"
        :items-per-page-options="[5]"
        :items-per-page="5"
        :items-length="recentTransactionsCount"
        :items="recentTransactions"
        :refreshing-from-parent="refresh"
        @refreshTable="refreshTable"
        @updatePage="sortTable"
        @updateSearchPage="updateSearchPage" />
    </template>
  </simple-card>
</template>

<script lang="ts">
import TransactionTable from '@/components/Merchant/Portal/TransactionTable.vue';
import SimpleCard from '@/components/SimpleCard.vue';
import { defineComponent } from 'vue';
import TransactionsDataFetchingMixin from '@/mixins/Merchant/TransactionsDataFetchingMixin';

export default defineComponent({
  name: 'RecentActivity',
  components: {
    SimpleCard,
    TransactionTable,
  },
  mixins: [TransactionsDataFetchingMixin],
  data() {
    return {
      action: 'recent-activity',
    };
  },
  computed: {
    recentTransactions(): any {
      return this.$store.getters['MerchantPortal/getRecentTransactions'];
    },
    recentTransactionsCount(): any {
      return this.$store.getters['MerchantPortal/getRecentTransactionsCount'];
    },
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
  },
  methods: {
    async refreshTable(val: any) {
      this.currentPage = val.page;
      await this.sortTable(val);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/mixins";

.section-card :deep() .v-data-table-footer {
  &__items-per-page {
    display: none;
  }

  &__pagination {
    .v-pagination__first,
    .v-pagination__last {
      display: none;
    }
  }
}

.merchant-portal-recent-activity {
  @include data-table-card-full-size;
  margin-bottom: 2rem;
}

.merchant-portal-page {
  .simple-card__body-text {
    width: 100%;
    padding: 0;
  }
}

@include mobile {
  .merchant-portal-page-recent-activity {
    :deep(.v-item-group .v-expansion-panel) {
      border-radius: 0;
    }
  }
}
</style>
