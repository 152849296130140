<template>
  <div>
    <div
      :class="classList"
      class="standard-wizard__step standard-wizard__step--three"
      data-test="MOB-step3">
      <v-card
        v-if="wizardSubStep !== 8"
        class="mt-4 standard-wizard__step__card standard-wizard__substep__two"
        style="border-radius: 8px;">
        <h3
          v-if="wizardSubStep !== 1"
          class="my-0 px-5 py-4 text-left"
          data-test="businessDetailsHeader"
          style="font-size:1.125rem; background-color: var(--grayscale-color-5);">
          Business Details
        </h3>

        <div
          v-if="wizardSubStep === 2"
          class="mt-4 px-4"
          data-test="enterBusinessDetailsLabel"
          style="text-align: left; font-size: 1rem; color: var(--grayscale-color-1);">
          Please enter your business details.
        </div>

        <v-card-text
          class="pb-0"
          :class="[isMobile ? 'pt-0' : '', isMobile && wizardSubStep === 2 ? 'pl-0' : '']" />

        <transition mode="out-in" name="slide">
          <substep-two v-if="wizardSubStep === 2" :on-next-sub-step="onNextSubStep" />
          <substep-three v-else-if="wizardSubStep === 3" :on-next-sub-step="onNextSubStep" />
          <substep-four v-else-if="wizardSubStep === 4" :on-next-sub-step="onNextSubStep" />
          <substep-five v-else-if="wizardSubStep === 5" :on-next-sub-step="onNextSubStep" />
          <substep-six v-else-if="wizardSubStep === 6" :on-next-sub-step="onNextSubStep" />
          <substep-seven v-else-if="wizardSubStep === 7" :on-next-sub-step="nextStep" />
        </transition>
      </v-card>

      <template v-else>
        <transition mode="out-in" name="slide">
          <substep-eight :on-next-step="nextStep" />
        </transition>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import GetProcessing from '@/mixins/GetProcessing';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import { OwnerDataInterface } from '@/interfaces/merchantOnboardingWizard/OwnerDataInterface';
import SubstepTwo from '@/components/Merchant/OnboardingWizard/StepThree/SubstepTwo.vue';
import SubstepThree from '@/components/Merchant/OnboardingWizard/StepThree/SubstepThree.vue';
import SubstepFour from '@/components/Merchant/OnboardingWizard/StepThree/SubstepFour.vue';
import SubstepFive from '@/components/Merchant/OnboardingWizard/StepThree/SubstepFive.vue';
import SubstepSix from '@/components/Merchant/OnboardingWizard/StepThree/SubstepSix.vue';
import SubstepSeven from '@/components/Merchant/OnboardingWizard/StepThree/SubstepSeven.vue';
import SubstepEight from '@/components/Merchant/OnboardingWizard/StepThree/SubstepEight.vue';
import { defineComponent } from 'vue';
import GetSponsorMixin from '@/mixins/GetSponsor';

export default defineComponent({
  name: 'StepThree',
  components: {
    SubstepTwo,
    SubstepThree,
    SubstepFour,
    SubstepFive,
    SubstepSix,
    SubstepSeven,
    SubstepEight,
  },
  mixins: [
    GetProcessing,
    NavigatesStepsMixin,
    GetSponsorMixin,
  ],
  props: {
    onNextSubStep: { type: Function, default: () => null },
  },
  data() {
    return {
      ownersStep: 1,
      show: true,
      substepsCount: 8,
    };
  },
  computed: {
    ownStep(): number {
      return this.isTypeIC1WithoutMPAccess ? 3 : 4;
    },
    classList() {
      const classList: Array<string> = [];
      if (this.wizardSubStep === 1) {
        classList.push('standard-wizard__step__substep-owners');
      }
      if (this.wizardSubStep === 8) {
        classList.push('standard-wizard__step__substep-combined');
      }
      return classList.join(' ');
    },
    applicationWaitingApproval(): boolean {
      return this.$store.getters['MerchantOnboarding/getApplicationWaitingApproval'];
    },
    consent(): number {
      return this.$store.getters['MerchantOnboarding/getConsent'];
    },
    processingApplication(): boolean {
      return this.$store.getters['MerchantOnboarding/getProcessingApplication'];
    },
    verifyFormOpen(): boolean {
      const {
        userFormOpen, openOwnerForms, businessFormOpen,
      } = this.$store.getters['MerchantOnboarding/getVerifyFormsOpen'];
      return userFormOpen || !!openOwnerForms || businessFormOpen;
    },
    nextStepDisabled(): boolean {
      return this.processing
        || !this.consent
        || this.processingApplication
        || this.applicationWaitingApproval
        || this.verifyFormOpen;
    },
    wizardSubStep(): number {
      return this.$store.getters['MerchantOnboarding/getOnboardingWizardSubStep'];
    },
    wizardStep(): number {
      return this.$store.getters['MerchantOnboarding/getOnboardingWizardStep'];
    },
    allOwnersData(): Array<OwnerDataInterface> {
      return this.$store.getters['MerchantOnboarding/getCombinedOwners'];
    },
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
  },
  mounted() {
    this.showStandardExplainModal();
  },
  methods: {
    async nextStep() {
      this.goToStep(this.ownStep + 1);
    },
    showStandardExplainModal(): void {
      this.show = true;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_custom-variables';
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/vuetify/elevation.scss";

.standard-wizard {
  &__step {
    background-color: #fff;
    margin-top: 0 !important;
    border-radius: 8px;

    .standard-wizard__step__card {
      border: 1px solid var(--grayscale-color-2);
      box-shadow: $v-elevation-4;
    }

    &__substep-owners {
      .standard-wizard__step__card {
        box-shadow: none;
        border: none;
        background: transparent;

        & > .card-body {
          padding: 0;
        }
      }
    }

    .standard-wizard__substep__two {
      @include mobile {
        box-shadow: none;
      }
    }

    &__progress {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      border: var(--progress-bar-border);

      @include mobile {
        margin-top: 0rem;
        margin-bottom: 1rem;
      }
    }

    &__alert {
      font-size: 1rem;
      font-weight: 500;
    }

    &__substep-combined {
      .standard-wizard__step__card {
        :deep(.owners-list) {
          background-color: transparent;
          .owners-list__item {
            background: #FFFFFF;
          }
        }

        :deep(.business-name-and-adddres),
        :deep(.business-details) {
          background: #FFFFFF;
          .card,
          :deep(.card) {
            background: transparent !important;
          }
        }
      }
    }
  }
}

:deep() {
  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      border: 1px solid var(--grayscale-color-1);
      background-color: var(--grayscale-color-1);
    }
  }
}
</style>
