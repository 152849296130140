<template>
  <div class="standard-wizard__step standard-wizard__step--application-approved">
    <p>
      Thanks for completing the application.
    </p>
    <p>
      Someone from our merchant concierge team will reach out to you shortly.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MerchantOnboardingWizardApplicationApproved',
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/merchant-begin-onboarding-wizard";
</style>
