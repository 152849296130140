<template>
  <div
    ref="widgetContentContainer"
    class="standard-wizard standard-wizard--consumer-loan-application">
    <transition mode="out-in" name="slide">
      <standard-wizard-message
        v-if="!merchantStanding"
        message="We are unable to process your request at this time."
        data-test="invalidMsg" />

      <consumer-invalid-link-msg v-else-if="!merchantId" />

      <consumer-loan-application-wizard-step-one
        v-else-if="wizardStep === 1"
        :data="data"
        @nextStep="navigateStep(2)" />

      <consumer-loan-application-wizard-step-two v-else-if="wizardStep === 2" />
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ConsumerInvalidLinkMsg from '@/components/Consumer/ConsumerInvalidLinkMsg.vue';
import ConsumerLoanApplicationWizardStepOne from '@/components/Consumer/LoanApplicationWizard/StepOne.vue';
import ConsumerLoanApplicationWizardStepTwo from '@/components/Consumer/LoanApplicationWizard/StepTwo.vue';
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import WidgetsMixin from '@/mixins/Widgets/WidgetsMixin';

export default defineComponent({
  name: 'ConsumerLoanApplicationWizard',
  components: {
    StandardWizardMessage,
    ConsumerInvalidLinkMsg,
    ConsumerLoanApplicationWizardStepOne,
    ConsumerLoanApplicationWizardStepTwo,
  },
  mixins: [WidgetsMixin],
  data() {
    return {
      data: {},
    };
  },
  computed: {
    wizardStep(): number {
      return this.$store.getters['ConsumerLoanApplication/getLoanApplicationWizardStep'];
    },
    merchantId(): any {
      return this.$store.getters['ConsumerLoanApplication/getMerchantId'];
    },
    merchantStanding(): boolean {
      return this.$store.getters[
        'ConsumerLoanApplication/getLoanApplicationWizardData'
      ].is_valid;
    },
  },
  mounted() {
    this.navigateStep(1);
  },
  methods: {
    setStep(step: number) {
      this.$store.commit('ConsumerLoanApplication/setLoanApplicationWizardStep', step);
    },
    navigateStep(step: number) {
      window.scrollTo(0, 0);

      if (step) {
        this.setStep(step);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/consumer-loan-application-wizard";
@import "@/assets/scss/_custom-transitions";
</style>
