<template>
  <div class="mobile-verification-wait">
    <img
      :src="logoUrl"
      class="mb-8"
      width="200"
      alt="Logo">
    <h4>Verification In Progress</h4>
    <p class="mb-8">
      <strong>Do Not Refresh This Screen.</strong>It will refresh
      automatically once you complete your verification.
    </p>
    <p class="mb-8">
      After 15 minutes your session will expire and you will need to restart
      the process.
    </p>
    <p class="mb-8">
      The verification process will open in a new tab.
      Please go to that tab to complete.
    </p>

    <custom-button
      data-test="resendBtn"
      variant="text"
      class="reopenTabBtn"
      @click="resendActivationLink">
      <span class="no-tab-message">
        If you don't see the new tab please click
        here to reopen.
      </span>
    </custom-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { startDocumentVerification } from '@/api/consumer';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import SocureDocVMixin from '@/mixins/SocureDocVMixin';

export default defineComponent({
  name: 'MobileIdVerificationWait',

  components: { CustomButton },

  mixins: [SocureDocVMixin],

  computed: {
    logoUrl(): string {
      return this.$store.getters['Ui/getBrandingHeaderLogo'];
    },

    applicationId(): string {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },
  },

  mounted() {
    const redirectToStep = 9;
    this.startPolling(redirectToStep);
  },

  methods: {
    async resendActivationLink() {
      const alertMessage = 'The verification process has been opened in a new tab.';
      try {
        const res = await startDocumentVerification(this.applicationId);
        this.$store.dispatch('Ui/addGlobalAlert', {
          text: alertMessage,
          type: GlobalAlertTypes.SUCCESS,
          timed: true,
        });
        window.location.href = res.data.document_verification_url;
      } catch {
        this.$store.dispatch('Ui/addGlobalTimedError');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.mobile-verification-wait {

  img {
    display: block;
    margin: 0 auto;
  }

  h4 {
    color: var(--grayscale-color-1);
    text-align: center;
  }

  p {
    font-size: 1rem;
  }

  .reopenTabBtn {
    padding: 0 !important;
  }

  .no-tab-message {
    text-transform: none;

    span {
      text-align: left;
    }
  }
}
</style>
