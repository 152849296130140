import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GetExistingApplicationsMixin',
  computed: {
    existingApplications(): Array<string> {
      return this.$store.getters['Consumer/getExistingApplications'];
    },
  },
});
