<template>
  <custom-alert
    :type="type"
    :timed="timed"
    :total-show-time="totalShowTime"
    :dismissible="dismissible"
    @dismissed="close">
    <span v-if="htmlText" v-html="text" />
    <span v-else>{{ text }}</span>
  </custom-alert>
</template>

<script lang="ts">
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GlobalAlert',

  components: {
    CustomAlert,
  },

  props: {
    text: { type: String, required: true },
    type: { type: String, required: true },
    id: { type: Number, default: null },
    timed: { type: Boolean },
    dismissible: { type: Boolean },
    htmlText: { type: Boolean },
    totalShowTime: { type: Number, default: 3000 },
  },

  data() {
    return {
      showTime: 0,
    };
  },

  methods: {
    close() {
      this.$emit('close', this.id);
    },
  },
});
</script>
