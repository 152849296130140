<template>
  <standard-dialog
    :dialog="true"
    :show-actions="false"
    width="420"
    title="Please Confirm Amounts"
    @cancel="cancel">
    <template #body>
      <p class="mb-6">
        Deposit Amount:
        <strong v-private>{{ CurrencyFormatLong(depositAmounts.deposit1cents / 100) }}</strong><br>
        Deposit Amount:
        <strong v-private>{{ CurrencyFormatLong(depositAmounts.deposit2cents / 100) }}</strong>
      </p>
      <div class="mb-6">
        <custom-alert type="warning">
          <div v-if="verificationAttempts === 3">
            <strong>WARNING:</strong> if you submit the wrong amounts three times,
            you will have to send new deposits.
          </div>
          <div v-if="verificationAttempts === 2">
            <strong>WARNING:</strong> if you submit the wrong amounts 1 more time
            you will have to send new deposits.
          </div>
          <div v-if="verificationAttempts === 1">
            <strong>WARNING:</strong> if you submit the wrong amounts 2 more times
            you will have to send new deposits.
          </div>
          <div v-if="verificationAttempts <= 0">
            <strong>WARNING:</strong> if you submit the wrong amounts 3 times,
            you will have to send new deposits.
          </div>
        </custom-alert>
      </div>
      <div>
        <custom-button
          full-width
          class="mb-6"
          @click="confirm">
          Confirm Amounts
        </custom-button>
        <custom-button
          full-width
          variant="secondary"
          @click="cancel">
          Cancel
        </custom-button>
      </div>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import StandardDialog from '@/components/Dialogs/index.vue';

export default defineComponent({
  name: 'DepositVerificationConfirmationDialog',

  components: {
    StandardDialog,
    CustomAlert,
    CustomButton,
  },

  props: {
    depositAmounts: { type: Object, required: true },
  },

  computed: {
    verificationAttempts(): number {
      return this.paymentProviders?.moov?.micro_deposit_verification_attempts || 0;
    },

    paymentProviders(): PaymentProvidersInterface {
      return this.$store.getters['MerchantPortal/getPaymentProviders'];
    },
  },

  methods: {
    CurrencyFormatLong,

    cancel() {
      this.$emit('cancel');
    },

    confirm() {
      this.$emit('confirm');
    },
  },
});
</script>
