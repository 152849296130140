import jwtDecode from 'jwt-decode';

export function tokenRemaining(token?: string | null): number {
  if (!token) return 0;

  const decodedToken: any = jwtDecode(token);
  const { exp } = decodedToken;
  return exp - (Date.now() / 1000);
}

/**
* Checks if stored JWT token has expired. If the token does not exist
* or is not stored in the localstorage it is treated as expired as well.
*/
export function hasTokenExpired(token?: string | null): boolean {
  token = token || localStorage.getItem('token');

  if (!token) return true;
  const remaining = tokenRemaining(token);
  return remaining <= 0;
}
