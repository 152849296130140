import { OtpStateInterface } from '@/interfaces/states/OtpStateInterface';

export function setOtpEnabled(state: OtpStateInterface, payload: boolean) {
  state.otpEnabled = payload;
}

export function setPhoneResendEnabled(state: OtpStateInterface, payload: boolean) {
  state.phoneResendEnabled = payload;
}

export function setOtpRegisterSessionToken(state: OtpStateInterface, sessionToken: string) {
  state.otpRegisterSessionToken = sessionToken;
}
