<template>
  <div>
    <div v-if="simple" class="card-text__info-group">
      <v-text-field
        v-model="pin"
        v-fs-exclude
        :variant="!disabled ? 'underlined' : 'plain'"
        :disabled="disabled"
        label="Four-Digit PIN"
        :rules="[pinRequired]"
        validate-on="blur"
        @keydown="onKeyDownSimple"
        @update:model-value="onUpdate" />
    </div>

    <div v-else class="pin-input">
      <p>{{ label }}</p>
      <div class="pin-input--inputs">
        <v-otp-input
          ref="otpInput"
          :key="otpRerender"
          v-model="pin"
          :disabled="disabled"
          :length="inputsNumber"
          type="number"
          @keydown="onKeyDown"
          @input="onUpdate" />
      </div>

      <div class="d-flex justify-end">
        <v-btn
          class="button-secondary custom-button-label pa-0"
          data-test="clearOtpBtn"
          variant="plain"
          color="var(--primary-color)"
          size="large"
          :disabled="pin === ''"
          @click="clearOtp">
          Clear
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

const VALID_NUMBERS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const VALID_KEYS = ['Backspace', 'Tab', 'Delete'];

export default defineComponent({
  name: 'PinInput',

  props: {
    modelValue: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    label: { type: String, required: true },
    inputsNumber: { type: Number, default: 4 },
    simple: { type: Boolean, default: false },
  },

  data() {
    return {
      pin: '',
      otpRerender: 0,
    };
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(value: string) {
        if (this.pin !== value) {
          this.pin = value;
          // Vuetify's OTP input component does not update the internal `otp` value after it
          // has been created so in case when the pin input is to be edited (meaning that
          // there is a pin value provided) we need to re-render the component.
          this.otpRerender += 1;
        }
      },
    },
  },

  mounted() {
    const otp = (this.$refs.otpInput as any)?.$el.querySelectorAll('input') || [];

    for (let i = 0; i < otp.length; i += 1) {
      otp[i].setAttribute('data-test', `pin${i + 1}Input`);
      otp[i].classList.add('fs-exclude');
    }
  },

  methods: {
    clearOtp() {
      this.pin = '';
      this.onUpdate();
    },

    pinRequired() {
      if (this.simple && !this.pin) return 'Four-digit PIN is required';

      return true;
    },

    onUpdate() {
      this.$emit('update:model-value', this.pin);
    },

    /* eslint-disable-next-line class-methods-use-this */
    onKeyDown(event: KeyboardEvent) {
      const { key } = event;

      if (!VALID_NUMBERS.includes(key) && !VALID_KEYS.includes(key)) {
        event.preventDefault();
      }
    },

    onKeyDownSimple(event: KeyboardEvent) {
      const { key } = event;

      if (this.pin.length !== this.inputsNumber) {
        if (!VALID_NUMBERS.includes(key) && !VALID_KEYS.includes(key)) {
          event.preventDefault();
        }
      } else if (!VALID_KEYS.includes(key)) {
        event.preventDefault();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/font-variables.scss';
@import "@/assets/scss/standard-wizard";

.pin-input {
  margin-bottom: 2.125rem;
  border: 1px solid var(--grayscale-color-2);
  border-radius: 5px;
  padding: 1.25rem;
  padding-bottom: 0;

  &--inputs {
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }

  :deep(.v-otp-input) {
    width: 100%;

    .v-otp-input__content {
      width: 100%;
      max-width: 100%;
      padding: 0;
      justify-content: space-between;

      .v-field {
        grid-template-columns: min-content 3.3125rem min-content min-content;
        flex-grow: 0;
        max-height: 3.5rem;
      }
    }

    .v-field {
      flex: 0 1 3.375rem;
      padding: 0;
      font-weight: 900;
      font-family: "Montserrat", sans-serif;

      &__slot {
        width: 3.3rem;
      }

      .v-otp-input__field {
        font-size: 1.625rem;
      }
    }

    input {
      padding: 0;
    }
  }
}
</style>
