import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-459f08d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lightbulb__image" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "lightbulb__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createBlock(_component_v_alert, {
    variant: "outlined",
    class: "lightbulb"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: require('@/assets/images/logos/lightbulb.svg'),
          alt: "Lightbulb"
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ])
    ]),
    _: 3
  }))
}