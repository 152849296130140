import GetMenuItemAccess from '@/helpers/GetMenuItemAccess';
import { hasTokenExpired } from '@/utils/Token';
import { MenuItemInterface } from '@/interfaces/menu/MenuItemInterface';

export default (to: any, _: any, next: Function) => {
  const login = {
    name: 'merchant-login',
    params: {
      redirectTo: to.fullPath,
    },
  };

  const isLoggedIn = localStorage.getItem('isLoggedIn');
  if (!(isLoggedIn && JSON.parse(isLoggedIn))) return next(login);

  if (hasTokenExpired()) return next(login);

  const menuItem: MenuItemInterface = {
    name: to.name,
    to: to.name,
    access: to.meta.userRole,
  };
  const hasGroupAccess: boolean = GetMenuItemAccess(menuItem);
  if (!hasGroupAccess) return next(login);

  return next();
};
