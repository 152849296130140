import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _directive_mask = _resolveDirective("mask")!

  return _withDirectives((_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ ref: "input" }, _ctx.attrs, {
    autocomplete: "off",
    "data-test": _ctx.dataTestAttr,
    "persistent-placeholder": "",
    placeholder: "00",
    hint: _ctx.hint,
    class: "cents-input",
    "persistent-hint": "",
    label: _ctx.labelAttr,
    variant: _ctx.inPlace ? 'plain' : 'outlined',
    "prepend-inner-icon": _ctx.showIcon ? 'mdi-currency-usd' : '',
    "validate-on": "blur",
    rules: _ctx.rules || [],
    maxlength: "2",
    inputmode: "numeric",
    pattern: "[0-9]*",
    prefix: "0."
  }), null, 16, ["data-test", "hint", "label", "variant", "prepend-inner-icon", "rules"])), [
    [_directive_mask, _ctx.inputMask]
  ])
}