import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SelectLoanApplicationMixin',
  methods: {
    selectLoanApplication(value: any) {
      const { consumer_application } = value;

      if (consumer_application) {
        this.$store.dispatch(
          'MerchantPortal/selectLoanApplication',
          consumer_application,
        );
      }
    },
  },
});
