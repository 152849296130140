<template>
  <v-snackbar
    v-model="showSystemErrorNotification"
    :color="notificationColor"
    :multi-line="true"
    location="top">
    {{ systemErrorNotificationMsg }}
  </v-snackbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { SystemNotificationInterface } from '@/interfaces/ui/SystemNotificationInterface';
import { get, head } from 'lodash';

export default defineComponent({
  name: 'SystemErrorNotification',

  data() {
    const timeout: any = null;
    return {
      notificationColor: '#6D7680',
      notification: null as SystemNotificationInterface | null,
      duration: 6000,
      timeout,
      showSystemErrorNotification: false,
    };
  },

  computed: {
    systemErrorNotificationMsg(): string {
      return get(this.notification, 'message', '');
    },

    systemNotifications(): Array<SystemNotificationInterface> {
      return this.$store.getters[
        'Ui/getSystemNotifications'
      ];
    },
  },

  watch: {
    systemNotifications: {
      immediate: true,
      handler(notifications: Array<SystemNotificationInterface>) {
        if (notifications.length) {
          this.showNotification();
        }
      },
    },
  },

  methods: {
    unmounted(): void {
      clearTimeout(this.timeout);
    },

    clearNotification(): void {
      this.showSystemErrorNotification = false;
      this.notification = null;
    },

    removeNotification(id: number): void {
      this.clearNotification();

      this.$store.commit(
        'Ui/setRemoveSystemNotification',
        id,
      );
    },

    showNotification(): void {
      this.clearNotification();

      this.$nextTick(() => {
        const notification = head(this.systemNotifications);

        if (notification) {
          this.notification = notification;
          this.showSystemErrorNotification = true;

          this.timeout = setTimeout(() => this.removeNotification(notification.id), this.duration);
        }
      });
    },
  },
});
</script>
