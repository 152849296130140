import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    label: { type: String, default: '' },
    dataTest: { type: String, default: '' },
    required: { type: Boolean, default: false },
    customErrorLabel: { type: String, default: '' },
    inPlace: { type: Boolean, default: false },
    errorCapitalized: { type: Boolean, default: false },
    pastePrevent: { type: Boolean, default: false },
    verify: { type: String, default: '' },
  },

  data: () => ({
  // To be overwritten if necessary
    defaultLabelAttr: '',
    // To be overwritten if necessary
    defaultDataTestAttr: '',
  }),

  computed: {
    labelAttr(): string {
      return this.label || this.defaultLabelAttr || '';
    },

    dataTestAttr(): string {
      if (this.dataTest) return this.dataTest;

      if (!this.labelAttr) return this.defaultDataTestAttr;

      const type = this.labelAttr.split(' ')[0].toLowerCase();
      return `${type}Input`;
    },

    // Should be used to pass the attributes to the child component
    // via `v-bind`. A replacement for `v-bind="$attrs"`.
    attrs() {
      return { ...this.$attrs, ...this.$props };
    },

    inputElement(): HTMLInputElement | null {
      // @ts-ignore
      const el = (this.$refs.input as any);
      if (!el) return null;

      return el.$el.querySelector('input')!;
    },
  },

  watch: {
    verify(value: string) {
      if (value.length) {
        (this.$refs.input as any).validate();
      }
    },
  },

  methods: {
    beforePaste(value: any) {
      if (this.pastePrevent) return false;
      return value;
    },

    isRequired(value?: string | number): string | boolean {
      if (value || !this.required) return true;

      let errorLabel = '';
      if (this.customErrorLabel) {
        errorLabel = this.customErrorLabel;
      } else {
        const label = this.errorCapitalized ? this.labelAttr : this.labelAttr.toLowerCase();
        errorLabel = label.charAt(0).toUpperCase() + label.slice(1);
      }
      return `${errorLabel} is required`;
    },

    onMaskCleared() {
      if (!this.inputElement) return;
      // The `noValuePatching` propery messes up the value in a way that it sets the
      // value to the empty mask and the value never gets to an empty state.
      // The minor issue here is that a 'blip' happens when:
      // 1. user enters something
      // 2. user deletes all the characters
      // 3. input mask is removed
      // 4. input mask is reapplied when blur+focus on the input again and here's where the
      //   blip happens.
      this.$emit('input', '');
      this.inputElement.blur();
      this.inputElement.focus();
    },

    verifyInput(value: any) {
      if (value && this.verify && this.verify !== value) return 'Values do not match.';

      return true;
    },
  },
});
