export enum PageTypesShorthand {
  COB = 'COB',
  MOB = 'MOB',
  BMOB = 'BMOB',
  MP = 'MP',
}

export enum PageTypes {
  COB = 'Consumer Onboarding',
  MOB = 'Merchant Onboarding',
  BMOB = 'Begin Merchant Onboarding',
  MP = 'Merchant Portal',
}
