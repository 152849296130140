<template>
  <div class="id-verification-complete">
    <img
      :src="logoUrl"
      class="mb-8"
      width="200"
      alt="Momnt Logo">
    <h2>Verification Complete</h2>
    <p>
      Please return to your desktop device to continue your invitation.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IDVerificationComplete',

  computed: {
    logoUrl(): string {
      return this.$store.getters['Ui/getBrandingHeaderLogo'];
    },
  },
});
</script>

<style lang="scss" scoped>
.id-verification-complete {
  h2 {
    color: var(--grayscale-color-1);
  }

  p {
    font-size: 1rem;
  }
}
</style>
