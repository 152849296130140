<template>
  <div class="rate-sheet-options-data-table-description-tooltip">
    <custom-button variant="icon" @click="onClick">
      <v-icon size="20" color="var(--grayscale-color-2)">
        mdi-help-circle-outline
      </v-icon>
    </custom-button>

    <info-dialog
      :dialog="show"
      :on-cta="onCancel"
      :on-cancel="onCancel" />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import InfoDialog from '@/components/RateSheetOptions/InfoDialog.vue';

export default defineComponent({
  name: 'RateSheetOptionsHeaderInfoModal',

  components: {
    InfoDialog,
    CustomButton,
  },

  props: {
    show: { type: Boolean, default: false },
    onCancel: { type: Function as PropType<() => void>, default: () => () => null },
    onClick: { type: Function as PropType<() => void>, default: () => () => null },
  },
});
</script>

<style lang="scss" scoped>
.rate-sheet-options-data-table-description-tooltip {
  margin-left: .25rem;
  display: inline-block;
}
</style>
