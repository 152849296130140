<template>
  <div v-if="combinedErrors.length">
    <custom-alert
      v-for="(error, index) in combinedErrors"
      :key="index"
      type="error">
      {{ error }}
    </custom-alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import { ErrorsInterface } from '@/interfaces/ui/ErrorsInterface';
import GetErrors from '@/mixins/GetErrors';
import SetCustomErrorValueMixin from '@/mixins/SetCustomErrorValueMixin';

export default defineComponent({
  name: 'ErrorList',

  components: {
    CustomAlert,
  },

  mixins: [
    GetErrors,
    SetCustomErrorValueMixin,
  ],

  props: {
    customErrors: { type: Array<ErrorsInterface | string>, default: () => [] },
    showNonFieldErrors: { type: Boolean, default: false },
    showDetailErrors: { type: Boolean, default: false },
    clearNonFieldErrorsOnDestroy: { type: Boolean, default: false },
  },

  computed: {
    combinedErrors(): Array<ErrorsInterface> {
      const errors: any = [];

      const { non_field_errors, detail } = this.errors;
      const {
        showNonFieldErrors,
        showDetailErrors,
        customErrors,
      } = this.$props;

      if (non_field_errors && showNonFieldErrors) {
        errors.push(...non_field_errors);
      }

      if (detail && showDetailErrors) {
        const detailErrors = Array.isArray(detail) ? detail : [detail];
        errors.push(...detailErrors);
      }

      if (customErrors) {
        errors.push(...customErrors);
      }

      return errors;
    },
  },

  methods: {
    unmounted() {
      if (this.clearNonFieldErrorsOnDestroy) {
        this.setCustomErrorValue('non_field_errors', '', true);
      }
    },
  },
});
</script>
