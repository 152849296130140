<template>
  <div
    v-bind="$attrs"
    class="status-chip"
    :style="statusStyles">
    <span>{{ status }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { DisplayStatus, DISPLAY_STATUS_LIST } from '@/interfaces/ConsumerActivity';

export default defineComponent({
  props: {
    status: { type: String as PropType<DisplayStatus>, default: null },
  },

  setup(props) {
    const styleMap = {
      [DISPLAY_STATUS_LIST.SENT]: { backgroundColor: '#FFB14E', color: '#593602' },
      [DISPLAY_STATUS_LIST.IN_PROGRESS]: { backgroundColor: '#C8F2FB', color: '#004655' },
      [DISPLAY_STATUS_LIST.OFFERS]: { backgroundColor: '#EECFF9', color: '#591073' },
      [DISPLAY_STATUS_LIST.NOT_FINALIZED]: { backgroundColor: '#AEFAFF', color: '#0B6D73' },
      [DISPLAY_STATUS_LIST.FINALIZED]: { backgroundColor: '#AEFFE2', color: '#176549' },
      [DISPLAY_STATUS_LIST.CAN_TRANSACT]: { backgroundColor: '#71F96E', color: '#033202' },
      [DISPLAY_STATUS_LIST.NO_OFFERS]: { backgroundColor: '#FFA9A9', color: '#810909' },
      [DISPLAY_STATUS_LIST.CHARGES_LIMITED]: { backgroundColor: '#DCE0E7', color: '#4C5560' },
      [DISPLAY_STATUS_LIST.STUCK]: { backgroundColor: '#98D3FE', color: '#012F51' },
      [DISPLAY_STATUS_LIST.CANCELED]: { backgroundColor: '#FFD600', color: '#5A4C00' },
      [DISPLAY_STATUS_LIST.OFFERS_CANCELED]: { backgroundColor: '#FFF500', color: '#615D02' },
    };

    const statusStyles = computed(() => styleMap[props.status] || {});

    return { statusStyles };
  },
});
</script>

<style lang="scss" scoped>
.status-chip {
  line-height: 100%;
  border-radius: 1.25rem !important;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.5rem;
  width: fit-content;
  max-width: fit-content;
  cursor: pointer;
}
</style>
