<template>
  <standard-dialog
    content-class="dialog-top--mobile"
    button-size="small"
    :dialog="dialog"
    :cta-disabled="ctaDisabled"
    :on-cancel="clearData"
    secondary-cancel-button
    :on-cta="handleCta"
    :show-cancel-cta="!invitationSent"
    :show-title="false"
    :button-label="invitationSent ? 'OK' : 'Send'">
    <template #body>
      <div v-if="invitationSending && !errorShown" class="d-flex justify-center pb-16 pt-8">
        <v-progress-circular
          size="64"
          width="5"
          color="var(--grayscale-color-1)"
          indeterminate />
      </div>
      <div v-if="!invitationSending && errorShown" class="py-8">
        <custom-alert v-if="matchesFound" type="error">
          This {{ enteredPhone ? enteredPhone : enteredEmail }} is associated with
          an active merchant account and is not eligible to
          apply for a loan invitation.
        </custom-alert>

        <custom-alert v-else type="error">
          We’re sorry. Due to a technical error you cannot send
          a loan invitation right now. Please try again later.
        </custom-alert>
      </div>
      <div class="simple-card__body-text" data-test="newModal">
        <v-row v-if="!invitationSent">
          <v-col>
            <email-input
              v-model="email"
              :disabled="emailInputDisabled"
              :error="sameEmailEntered"
              show-icon />

            <div class="text-center vertical-align">
              <span>- or -</span>
            </div>
            <phone-input
              v-model="phone"
              :disabled="phoneInputDisabled"
              :error="samePhoneEntered"
              show-icon />

            <offer-code-select v-model="offerCode" :disabled="offerCodeSelectDisabled" />
          </v-col>
        </v-row>

        <v-row v-else class="mb-2">
          <v-col>
            <span>
              You have sent a loan application
              <span v-if="offerCode.id">with offer code {{ offerCode.name }}</span> to
              <span v-if="isSentToPhoneNumber">
                mobile phone number
                <span v-private>{{ phone }}</span>
              </span>
              <span v-else>email address <span v-private>{{ email }}</span></span>
            </span>
          </v-col>
        </v-row>
      </div>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import EmailInput from '@/components/Inputs/Email.vue';
import { ErrorsInterface } from '@/interfaces/ui/ErrorsInterface';
import GetErrors from '@/mixins/GetErrors';
import PhoneInput from '@/components/Inputs/Phone.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import emailAddress from '@/validators/email_address';
import phoneNumber from '@/validators/phone_number';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { defineComponent } from 'vue';
import { PageTypesShorthand } from '@/enums/PageTypes';
import OfferCodeSelect from './OfferCodeSelect.vue';

export default defineComponent({
  name: 'NewLoanApplicationModal',
  components: {
    EmailInput,
    OfferCodeSelect,
    PhoneInput,
    StandardDialog,
    CustomAlert,
  },
  mixins: [
    GetErrors,
    HoneypotTrackMixin,
  ],
  props: {
    onCta: { type: Function, default: () => null },
    dialog: { type: Boolean, default: false },
  },
  data() {
    const offerCode: any = {};
    return {
      invitationSent: false,
      offerCode,
      email: '',
      phone: '',
      enteredEmail: '',
      enteredPhone: '',
      invitationSending: false,
      errorShown: false,
      hpEventName: 'New Loan Invitation Sent',
    };
  },
  computed: {
    isSentToPhoneNumber(): boolean {
      return phoneNumber(this.phone);
    },
    emailInputDisabled(): boolean {
      return !!this.phone;
    },
    phoneInputDisabled(): boolean {
      return !!this.email;
    },
    sameEmailEntered(): boolean {
      return this.errorShown && this.email ? this.enteredEmail === this.email : false;
    },
    samePhoneEntered(): boolean {
      return this.errorShown && this.phone ? this.enteredPhone === this.phone : false;
    },
    offerCodeSelectDisabled(): boolean {
      return !(emailAddress(this.email) || phoneNumber(this.phone));
    },
    merchantUuid(): ErrorsInterface {
      return this.$store.getters['Auth/getMerchantUuid'];
    },
    ctaDisabled(): boolean {
      return !this.merchantUuid
        || (!emailAddress(this.email) && !phoneNumber(this.phone))
        || this.invitationSending
        || this.sameEmailEntered
        || this.samePhoneEntered;
    },
    errorMessage(): string[] {
      return this.errors?.detail;
    },
    matchesFound(): boolean {
      return this.errorMessage?.includes('Matches found.');
    },
  },
  methods: {
    handleCta() {
      if (this.invitationSent) {
        this.clearData();
        return;
      }
      this.sendInvitation();
    },
    async sendInvitation() {
      this.errorShown = false;
      this.invitationSending = true;

      this.enteredEmail = this.email;
      this.enteredPhone = this.phone;

      const payload = {
        email: this.email,
        phone: this.phone,
        offerCode: this.offerCode.id,
      };

      const responseStatus = await this.$store.dispatch(
        'MerchantPortal/dispatchSendInvitation',
        payload,
      );

      if (responseStatus === 201) {
        this.invitationSent = true;

        this.trackEvent(this.hpEventName, PageTypesShorthand.MP);
      } else {
        this.errorShown = true;
      }
      this.invitationSending = false;
    },
    clearData(): void {
      this.phone = '';
      this.email = '';
      this.invitationSent = false;

      this.onCta();
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.vertical-align {
  margin-bottom: 2rem;
}

.v-application .v-overlay-container {
  .v-input {
    margin-bottom: 0;
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/mixins/media_queries';

@include mobile {
  .dialog-top--mobile {
    top: 48px;
    position: absolute;
    max-width: 87%;
    margin: 0 auto;
  }
}
</style>
