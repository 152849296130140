import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    BUSINESS_STRUCTURE: {
      SOLE_PROPRIETORSHIP: 'Sole Proprietorship',
      PARTNERSHIP: 'Partnership',
      C_CORP: 'C Corp',
      S_CORP: 'S Corp',
      LLC: 'LLC',
    },
  }),

  computed: {
    optionsBusinessStructure() {
      return Object.values(this.BUSINESS_STRUCTURE);
    },
  },

  methods: {
    shouldShowSsnInput(businessStructure?: string | null): boolean {
      return businessStructure === this.BUSINESS_STRUCTURE.SOLE_PROPRIETORSHIP;
    },
  },
});
