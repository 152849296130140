<template>
  <div>
    <v-alert
      class="custom-alert"
      :class="className"
      :type="vAlertType"
      variant="outlined">
      <template v-slot:prepend>
        <div class="prepend">
          <v-icon color="#FFFFFF" :icon="icon" />
        </div>
      </template>
      <div class="custom-alert--content" :data-test="`customAlert${type}`">
        <slot />
      </div>
      <template v-if="dismissible" v-slot:append>
        <div class="append">
          <v-icon
            v-private="'Close alert'"
            icon="mdi-close"
            size="small"
            @click="close" />
        </div>
      </template>
    </v-alert>

    <v-progress-linear
      v-if="timed"
      :color="alertColorVariable"
      height="8"
      :model-value="progressBarPercentage"
      class="progress-bar" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

const DELAY = 100; // in milliseconds

export default defineComponent({
  name: 'CustomAlert',

  props: {
    type: { type: String, required: true },
    timed: { type: Boolean, default: false },
    dismissible: { type: Boolean, default: false },
    totalShowTime: { type: Number, default: 3000 },
  },

  data: () => ({
    showTime: 0,
    interval: null as number | null,
  }),

  computed: {
    className(): string {
      return `${this.type}-alert`;
    },

    icon(): string {
      if (this.type === 'warning') {
        return 'mdi-alert-outline';
      }
      if (this.type === 'error' || this.type === 'input-error') {
        return 'mdi-alert-circle-outline';
      }

      return 'mdi-checkbox-marked-circle';
    },

    vAlertType() {
      if (this.type === 'input-error') {
        return 'error';
      }

      return this.type;
    },

    progressBarPercentage(): number {
      return (100 / this.totalShowTime) * this.showTime;
    },

    alertColorVariable(): string {
      if (this.type === 'input-error') {
        return 'var(--error-color)';
      }
      return `var(--${this.type}-color)`;
    },
  },

  mounted() {
    if (!this.timed) return;

    this.showTime = this.totalShowTime;

    this.interval = setInterval(() => {
      if (this.showTime < 0) {
        this.close();
      } else {
        this.showTime -= DELAY;
      }
    }, DELAY);
  },

  unmounted() {
    this.clearInterval();
  },

  methods: {
    close() {
      this.clearInterval();
      this.$emit('dismissed');
    },

    clearInterval() {
      if (this.interval) clearInterval(this.interval);
    },
  },
});
</script>

<style lang="scss" scoped>
$alert-padding: 1rem;

:deep() .custom-alert {
  padding: 0;
  border-width: 2px !important;
  background-color: #fff;
  margin-bottom: 1rem;

  > .v-alert__prepend {
    grid-row: span 2;
    height: 100%;
    padding: 1rem;
  }

  > div {
    background-color: #fff;
  }

  .append {
    padding-top: calc($alert-padding / 2);
    padding-right: calc($alert-padding / 2);
    color: var(--grayscale-color-1);
  }

  &--content {
    text-align: left;
    color: var(--grayscale-color-1);
    padding: 1rem 1rem 1rem 0;
  }

  &.input-error-alert {
    border-width: 1px !important;
    border-color: var(--error-color) !important;

    .prepend {
      background-color: transparent;

      .v-icon {
        color: var(--error-color);
      }
    }

    .custom-alert--content {
      padding-left: 0;
      color: var(--error-color);
    }
  }

  &.success-alert {
    border-color: var(--success-color) !important;

    .v-alert__prepend {
      background-color: var(--success-color);
    }
  }

  &.warning-alert {
    border-color: var(--warning-color) !important;

    .v-alert__prepend {
      background-color: var(--warning-color);
    }
  }

  &.error-alert {
    border-color: var(--error-color) !important;

    .v-alert__prepend {
      background-color: var(--error-color);
    }
  }

  &:not(:last-child) {
     border-bottom-left-radius: 0;
     border-bottom-right-radius: 0;
     margin-bottom: 0
   }
}

.progress-bar {
  margin-bottom: $alert-padding;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
