import { get } from 'lodash';
import { AuthStateInterface } from '@/interfaces/states/AuthStateInterface';
import { GroupInterface } from '@/interfaces/auth/GroupInterface';
import { NewUserInvitationTokenDataInterface } from '@/interfaces/auth/NewUserInvitationTokenDataInterface';

export function getSessionId(state: AuthStateInterface) {
  return state.sessionId;
}

export function getUser(state: AuthStateInterface) {
  return state.user;
}

export function getUserId(state: AuthStateInterface) {
  return get(state, 'user_id', '');
}

export function getUserIsAdmin(state: AuthStateInterface) {
  return get(state.user, 'is_admin', false);
}

export function getMerchant(state: AuthStateInterface) {
  return state.merchant;
}

export function getGroup(state: AuthStateInterface): GroupInterface[] {
  return state.group;
}

export function getMerchantUuid(state: AuthStateInterface) {
  return state.merchantUuid || state.merchant.uuid;
}

export function getMerchantHumanReadableId(state: AuthStateInterface) {
  return get(state.merchant, 'human_readable_id', '');
}

export function getIsLoggedIn(_: any, getters: any) {
  return !!getters.getUserId;
}

export function getNewUserInviteTokenData(state: AuthStateInterface):
NewUserInvitationTokenDataInterface {
  return state.newUserInviteTokenData;
}

export function isLoggingOut(state: AuthStateInterface) {
  return state.loggingOut;
}

export function getGucaData(state: AuthStateInterface) {
  return state.gucaData;
}
