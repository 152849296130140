<template>
  <div class="address-input-container">
    <v-alert
      v-for="error in combinedErrors"
      :key="error"
      icon="mdi-alert-circle-outline"
      type="error"
      color="var(--error-color)"
      variant="outlined">
      {{ error }}
    </v-alert>

    <text-input
      :class="{ 'has-hint': address1Hint }"
      :data-test="address1DataTest"
      :disabled="disabled"
      :error-messages="errors.address_1"
      :hint="address1Hint"
      :in-place="inPlace"
      :label="address1Label"
      :persistent-hint="address1HintPersistent"
      :required="required"
      :rules="validationRules('Address')"
      :model-value="modelValue.address1"
      @update:model-value="changeValue('address1', $event)" />
    <text-input
      :data-test="address2DataTest"
      :disabled="disabled"
      :error-messages="errors.address_2"
      :in-place="inPlace"
      :label="address2Label"
      :model-value="modelValue.address2"
      @update:model-value="changeValue('address2', $event)" />
    <text-input
      :data-test="cityDataTest"
      :disabled="disabled"
      :error-messages="errors.city"
      :in-place="inPlace"
      :label="cityLabel"
      :required="required"
      :rules="validationRules('City')"
      :model-value="modelValue.city"
      @update:model-value="changeValue('city', $event)" />
    <v-row class="state-zip-row">
      <v-col>
        <autocomplete-input
          autoselect-match
          class="state-dropdown"
          :disabled="disabled"
          data-test="stateDrop"
          :custom-filter="customStateFilter"
          hide-dropdown-icon
          :in-place="inPlace"
          :items="USA_STATES"
          item-text="title"
          label="State"
          :required="required"
          :model-value="modelValue.state"
          :attach="false"
          @update:model-value="changeValue('state', $event)" />
      </v-col>
      <v-col>
        <zip-code-input
          :disabled="disabled"
          :error-messages="errors.zip"
          :in-place="inPlace"
          :required="required"
          :model-value="modelValue.zipCode"
          @update:model-value="changeValue('zipCode', $event)" />
      </v-col>
    </v-row>

    <confirmation-modal
      v-if="askConfirmation"
      :key="componentUpdate"
      :dialog="showConfirmation"
      :address="validatedAddress"
      :is-fs-excluded="isFsExcluded"
      :confirmation-modal-header-text="confirmationModalTitle"
      @confirmed="emitConfirmed"
      @declined="$emit('confirmation:declined')" />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { AddressDataInterface } from '@/interfaces/addressValidation/AddressDataInterface';
import AutocompleteInput from '@/components/Inputs/Autocomplete.vue';
import ConfirmationModal from '@/components/Inputs/Address/ConfirmationModal.vue';
import GetErrors from '@/mixins/GetErrors';
import SetCustomErrorValueMixin from '@/mixins/SetCustomErrorValueMixin';
import USA_STATES from '@/constants/UsaStatesConstants';
import TextInput from '@/components/Inputs/Text.vue';
import ZipCodeInput from '@/components/Inputs/Address/ZipCode.vue';
import { UsaStateInterface } from '@/interfaces/ui/UsaStateInterface';

export default defineComponent({
  name: 'AddressInput',

  components: {
    AutocompleteInput,
    ConfirmationModal,
    TextInput,
    ZipCodeInput,
  },

  mixins: [GetErrors, SetCustomErrorValueMixin],

  props: {
    modelValue: { type: Object as PropType<AddressDataInterface>, required: true },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    inPlace: { type: Boolean, default: false },
    address1Label: { type: String, default: 'Address Line 1' },
    address1Hint: { type: String, default: '' },
    address1HintPersistent: { type: Boolean, default: false },
    address1DataTest: { type: String, default: 'address1Input' },
    address2Label: { type: String, default: 'Address Line 2' },
    address2DataTest: { type: String, default: 'address2Input' },
    cityLabel: { type: String, default: 'City' },
    cityDataTest: { type: String, default: 'cityInput' },
    // If the confirmation is not needed then we don't even render the confirmation modal
    askConfirmation: { type: Boolean, default: false },
    showConfirmation: { type: Boolean, default: false },
    isFsExcluded: { type: Boolean, default: true },
    validatedAddress: { type: Object as PropType<AddressDataInterface>, default: () => ({}) },
    errorMessages: { type: Array as PropType<Array<string>>, default: () => [] },
    confirmationModalTitle: { type: String, default: 'Please Confirm Your Address' },
  },

  data: () => ({
    componentUpdate: 1,
  }),

  computed: {
    USA_STATES(): Array<UsaStateInterface> {
      return USA_STATES;
    },
    combinedErrors(): Array<string> {
      const { non_field_errors } = this.errors;
      const nonFieldErrors = Array.isArray(non_field_errors)
        ? non_field_errors
        : [non_field_errors];
      return [...this.errorMessages.filter(Boolean), ...nonFieldErrors.filter(Boolean)];
    },
  },

  watch: {
    showConfirmation(val: boolean) {
      if (!val) {
        this.componentUpdate += 1;
      }
    },
  },

  mounted() {
    if (this.isFsExcluded) {
      this.$el.querySelectorAll('input').forEach((el: HTMLInputElement) => {
        el.classList.add('fs-exclude');
        el.classList.remove('fs-unmask');
      });
    }
  },

  methods: {
    customStateFilter(_: string, query: string, item: any): any {
      const { title, value } = item;
      const searchTerm = query.toLowerCase();
      return title.toLowerCase().includes(searchTerm) || value.toLowerCase().includes(searchTerm);
    },

    validationRules(label: string) {
      return [
        (value: string): string | boolean => {
          if (this.required && !value) return `${label} is required`;
          return true;
        },
      ];
    },

    changeValue(prop: string, value: string) {
      this.$emit('update:model-value', { ...this.modelValue, [prop]: value });
    },

    emitConfirmed() {
      this.$emit('confirmation:accepted');
    },
  },
});
</script>

<style lang="scss" scoped>
.v-alert {
  margin-bottom: 1rem;
}

.state-zip-row {
  margin-top: 0;
  margin-bottom: 0;

  .v-col {
    padding-top: 0;
    padding-bottom: 0;

    .state-dropdown :deep(input) {
      min-width: 0;
    }
  }
}

:deep() .v-input {
  .v-messages__message {
    color: var(--grayscale-color-1);
  }

  &.v-input--error {
    .v-messages__message {
      color: var(--error-color);
    }
  }

  &.has-hint {
    .v-messages__message {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
