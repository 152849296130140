<template>
  <div>
    <v-menu
      v-model="menuVisible"
      :close-on-content-click="false"
      :offset="menuOffset"
      transition="fade-transition"
      width="13.75rem">
      <template v-slot:activator="{ props }">
        <status-chip v-bind="props" :status="status" />
      </template>

      <v-card width="13.75rem" class="fs-unmask">
        <v-card-title>
          <status-chip :status="status" />
          <v-icon
            tag="button"
            size="small"
            color="var(--grayscale-color-1)"
            @click="close">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>{{ description }}</v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import {
  PropType, computed, defineComponent, ref,
} from 'vue';
import { DisplayStatus } from '@/interfaces/ConsumerActivity';
import { useDisplayStatus } from '@/composables/useConsumerApplicationDisplayStatus';
import { useDisplay } from 'vuetify';
import StatusChip from './StatusChip.vue';

export default defineComponent({
  components: { StatusChip },

  props: {
    status: { type: String as PropType<DisplayStatus>, default: '' },
  },

  setup(props) {
    const menuVisible = ref(false);

    const close = () => {
      menuVisible.value = false;
    };

    const { getDescription } = useDisplayStatus();
    const description = computed(() => getDescription(props.status));
    const { xs } = useDisplay();
    const menuOffset = computed(() => {
      return xs.value ? [-35, 0] : [-35, 10];
    });

    return {
      description,
      menuVisible,
      close,
      menuOffset,
    };
  },
});
</script>

<style lang="scss" scoped>
.status-chip {
  cursor: pointer;
}

:deep() {
  .v-card {
    &-title {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      background-color: var(--grayscale-color-5);
    }

    &-text {
      color: var(--grayscale-color-1) !important;
      padding: 1rem;
      font-size: 1rem;
      text-align: left;
    }
  }
}
</style>
