<template>
  <custom-button
    class="disabled-box-shadow standard-data-table-refresh-button"
    :disabled="buttonDisabled"
    :data-test="dataTestAttr"
    variant="icon"
    @click.stop="$emit('click')">
    <v-icon
      aria-label="Refresh"
      :class="{ 'mdi-spin': active }"
      density="compact"
      size="24">
      mdi-refresh
    </v-icon>
  </custom-button>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import GetProcessing from '@/mixins/GetProcessing';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DataTableRefreshButton',

  components: {
    CustomButton,
  },

  mixins: [
    GetProcessing,
  ],

  props: {
    dataTestAttr: { type: String, default: 'refreshBtn' },
    disabled: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
  },

  computed: {
    buttonDisabled(): boolean {
      return this.active || this.disabled;
    },
  },
});
</script>

<style lang="scss" scoped>
.standard-data-table-refresh-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: unset;
  background-color: transparent;
  color: var(--grayscale-color-1);
  border-radius: 50%;
  font-size: 1rem;
  flex: 0;
}
</style>
