<template>
  <v-otp-input
    v-bind="$attrs"
    ref="vOtpInput"
    :model-value="modelValue"
    min-width="100%"
    :data-test="defaultDataTestAttr"
    :variant="inPlace ? 'plain' : 'outlined'"
    :required="required"
    :label="labelAttr"
    inputmode="numeric"
    inputtype="numeric"
    :rules="[otpValidation]"
    validate-on="blur"
    @update:model-value="update" />
</template>

<script lang="ts">
import {
  defineComponent, nextTick, onMounted, ref,
} from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import isOtp from '@/validators/otp_single_input';

export default defineComponent({
  name: 'OtpInput',

  mixins: [InputFieldMixin],

  props: {
    modelValue: { type: String, default: '' },
  },

  setup(_, { emit }) {
    const vOtpInput = ref<any>(null);

    onMounted(() => {
      if (vOtpInput.value) {
        // Removing maxlength attribute from input fields fixes autofill issue in Chrome Mobile
        // See https://github.com/vuetifyjs/vuetify/issues/18678#issuecomment-2218708663
        const inputElements = vOtpInput.value.$el.querySelectorAll('input');
        inputElements.forEach((input: any) => input.removeAttribute('maxlength'));
      }
    });

    async function update(val: string) {
      const inputElements = vOtpInput.value.$el.querySelectorAll('input');
      await nextTick();
      if (val.length >= 6) {
        inputElements[5].blur();
        emit('update:modelValue', val.substring(0, 6));
      } else {
        emit('update:modelValue', val);
      }
    }

    return { vOtpInput, update };
  },

  data() {
    return {
      defaultDataTestAttr: 'otpSingleInput',
      defaultLabelAttr: 'One-Time Passcode',
    };
  },

  methods: {
    otpValidation(value: string | undefined): string | boolean {
      if (!value) return this.isRequired();

      if (!isOtp(value)) return 'Please correct OTP format';

      return true;
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/font-variables.scss';

:deep(.v-otp-input__content) {
  padding: 0;
  height: 3.25rem;

  input {
    color: var(--grayscale-color-1);
    font-family: $font-family-serif;
    font-weight: 700;
  }
}
</style>
