<template>
  <standard-wizard-message data-test="freezeMsg" hide-wrapper>
    <template #body>
      <div v-if="!showThankYou">
        <p>
          As there is a freeze on your credit we are unable to access your credit information.
          Contact TransUnion at <a href="tel:8889098872">(888)-909-8872</a>
          to request the freeze to be lifted.
        </p>

        <p>
          Once you've lifted the credit freeze, you can continue your invitation
          from the link we will send to your email. The link will expire on
          {{ creditEngineExpirationDate }}.
        </p>

        <custom-button
          class="text-capitalize"
          data-test="submitBtn"
          full-width
          @click="showThankYou = true">
          I understand I need to unfreeze my credit
        </custom-button>
      </div>

      <h5 v-else>
        Thank You!
      </h5>
    </template>
  </standard-wizard-message>
</template>

<script lang="ts">
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CreditEngineSoftPullResultsDataMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineSoftPullResultsDataMixin';
import CreditEngineHardPullResultsDataMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineHardPullResultsDataMixin';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'CreditFreezeError',
  components: {
    StandardWizardMessage,
    CustomButton,
  },
  mixins: [
    CreditEngineSoftPullResultsDataMixin,
    CreditEngineHardPullResultsDataMixin,
  ],
  props: {
    expirationDate: { type: String as PropType<string | null>, default: '' },
  },
  data() {
    return {
      showThankYou: false,
    };
  },
  computed: {
    creditEngineExpirationDate() {
      return this.expirationDate
      || this.creditEngineSoftPullResults.expires_on_date
      || this.creditEngineHardPullResults.expires_on_date;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/merchant-begin-onboarding-wizard";
</style>
