<template>
  <v-app id="app">
    <global-alerts />
    <router-view :class="{ 'd-print-none': print.name }" />

    <standard-system-error-notification />
    <statement-details
      v-if="print.name === PRINT_NAMES.STATEMENT_DETAILS"
      class="d-none"
      print-on-load
      :statement-id="print.data.id"
      @print:close="clearPrint" />
  </v-app>
</template>

<script lang="ts">
import StandardSystemErrorNotification from '@/components/SystemErrorNotification.vue';
import setThemeVariables from '@/helpers/BrandThemeHelper';
import GlobalAlerts from '@/components/Alerts/Global/index.vue';
import StatementDetails from '@/components/Merchant/Portal/Statements/StatementDetails/index.vue';
import PrintConfigNames from '@/enums/PrintConfigNames';
import getTopLevelDomain from '@/helpers/GetTopLevelDomain';
import Logger from '@/logging';
import FullStoryLogging from '@/logging/FullStory';
import { UiStoreBrandingData } from '@/interfaces/states/UiStoreBrandingData';
import { honeypot } from 'honeypot-run';
import { EnvConfig } from '@/interfaces/EnvConfig';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    GlobalAlerts,
    StandardSystemErrorNotification,
    StatementDetails,
  },
  data() {
    return {
      PRINT_NAMES: PrintConfigNames,
    };
  },
  computed: {
    print() {
      return this.$store.getters['Print/getPrintConfig'] || {};
    },
    envVariables(): EnvConfig {
      return this.$store.getters['Config/getEnvVariables'];
    },
    brandingData(): UiStoreBrandingData {
      return this.$store.getters['Ui/getBrandingObject'];
    },
  },
  watch: {
    brandingData: {
      handler(val: any) {
        document.title = val.brand_name ? val.brand_name : '';
      },
      immediate: true,
    },
  },
  async mounted() {
    const { hostname } = window.location;
    const domain = getTopLevelDomain(hostname);

    Logger.startMonitor(this.envVariables);
    FullStoryLogging.startLogging(this.envVariables);

    await this.loadBrandingData();

    const brandName = this.brandingData.brand_name;
    const brand = brandName ? brandName.toLowerCase().replaceAll(' ', '') : 'momnt';

    if (['heyartis.com', 'momnt.com', 'heymanorcredit.com'].includes(domain)) {
      [192, 96, 32, 16].forEach((size: number) => {
        const link = document.createElement('link');
        link.setAttribute('rel', 'icon');
        if (size) link.setAttribute('sizes', `${size}x${size}`);
        link.setAttribute('href', `/static/img/${brand}-${size || '16'}.png`);
        document.head.appendChild(link);
      });
    }

    this.loadHoneypotScript();
  },
  unmounted() {
    Logger.stopSessionRecording();
  },
  methods: {
    clearPrint() {
      this.$store.dispatch('Print/clearPrintConfig');
    },
    async loadBrandingData() {
      const currentRoute: any = window.location.pathname.split('/');
      if (currentRoute.includes('consumer')) {
        await this.$store.dispatch('Ui/getConsumerBrandingData');
      } else {
        await this.$store.dispatch('Ui/getMerchantBrandingData');
      }
      setThemeVariables(this.$store.getters['Ui/getBrandingTheme']);
    },
    loadHoneypotScript() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `${this.envVariables.HONEYPOT_URL}js`;
      document.body.appendChild(script);

      honeypot.load(`${this.envVariables.HONEYPOT_URL}js`);
    },
  },
});
</script>

<style lang="scss">
/* https://materialdesignicons.com/ */
@import '@/assets/scss/main.scss';

</style>
