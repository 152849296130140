import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1f3ba4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_alert = _resolveComponent("custom-alert")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.errorAcceptingLoan)
      ? (_openBlock(), _createBlock(_component_custom_alert, {
          key: 0,
          type: "error"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" There was an error accepting the loan agreement ")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_checkbox_input, {
      modelValue: _ctx.checkLoanDoc,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkLoanDoc) = $event)),
      "data-test": "checkLoanDoc",
      "hide-details": "",
      class: "checkbox"
    }, {
      label: _withCtx(() => [
        _createElementVNode("span", null, [
          _createTextVNode(" I agree to my "),
          _createElementVNode("a", {
            href: _ctx.loanAgreementUrl,
            target: "_blank",
            rel: "noopener noreferrer",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, " Loan Documents ", 8, _hoisted_1),
          _createTextVNode(" and agree to create a payment portal account and add a payment method in order to transact with my loan. ")
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_checkbox_input, {
      modelValue: _ctx.checkESign,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.checkESign) = $event)),
      "data-test": "checkESign",
      "hide-details": "",
      class: "checkbox"
    }, {
      label: _withCtx(() => [
        _createElementVNode("span", null, [
          _createTextVNode(" The originating bank on your loan is " + _toDisplayString(_ctx.lenderName) + ". By checking this box, I accept my loan agreement with " + _toDisplayString(_ctx.lenderName) + " and their ", 1),
          _createElementVNode("a", {
            href: _ctx.lenderPrivacyPolicyUrl,
            target: "_blank",
            rel: "noopener noreferrer",
            onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
          }, " Privacy Notice ", 8, _hoisted_2),
          _createTextVNode(". ")
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("p", null, _toDisplayString(_ctx.selectedLoan.short_description), 1),
    _createVNode(_component_custom_button, {
      disabled: !(_ctx.checkLoanDoc && _ctx.checkESign) || _ctx.loading,
      "full-width": "",
      onClick: _ctx.onAccept
    }, {
      default: _withCtx(() => [
        _createTextVNode(" I Accept This Loan Agreement ")
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}