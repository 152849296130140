enum MerchantApplicationStatus {
  CANCELED = 'Canceled',
  PRE_INTAKE = 'Pre-Intake',
  INTAKE = 'Intake',
  INCOMPLETE = 'Incomplete',
  APPROVED = 'Approved',
  CONDITIONAL_APPROVAL = 'Conditional Approval',
  PENDING = 'Pending',
  DECLINED = 'Declined',
  SUBMITTED = 'Submitted',
}

export default MerchantApplicationStatus;
