<template>
  <standard-wizard-message data-test="noLendersMsg" hide-wrapper>
    <template #body>
      <p>
        We’re sorry. We currently do not offers loans in your area.
        If you think you’ve reached this message in error, please contact
        your merchant for more details.
      </p>

      <p>
        For security reasons we will cancel your application.
      </p>
    </template>
  </standard-wizard-message>
</template>

<script lang="ts">
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NoLendersAvailableMsg',
  components: {
    StandardWizardMessage,
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/merchant-begin-onboarding-wizard";
</style>
