import { AxiosError } from 'axios';
import { ActionContext } from 'vuex';

export function getAxiosError(error: unknown): AxiosError | undefined {
  const err = (error as AxiosError);
  return err.isAxiosError ? err : undefined;
}

export function setStatusAndErrorStateFromAxios(
  status: number,
  error: unknown,
  context: ActionContext<any, any>,
) {
  const axiosError = getAxiosError(error);
  if (axiosError) {
    status = axiosError.response!.status;
    context.commit('Ui/setErrors', axiosError.response, { root: true });
  }
  return status;
}
