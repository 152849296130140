import { CreateMerchantDetailsInterface } from '@/interfaces/merchantBeginOnboardingWizard/CreateMerchantDetailsInterface';
import { CreateUserDetailsInterface } from '@/interfaces/merchantBeginOnboardingWizard/CreateUserDetailsInterface';
import { MerchantBeginOnboardingStateInterface } from '@/interfaces/states/MerchantBeginOnboardingStateInterface';

export function setMerchantApplicationId(
  state: MerchantBeginOnboardingStateInterface,
  merchantApplicationId: string,
) {
  state.merchantApplicationId = merchantApplicationId;
}

export function setMerchantId(state: MerchantBeginOnboardingStateInterface, merchantId: number) {
  state.merchantId = merchantId;
}

export function setProgramUid(state: MerchantBeginOnboardingStateInterface, programUid: string) {
  state.programUid = programUid;
}

export function setUserId(state: MerchantBeginOnboardingStateInterface, userId: string) {
  state.userId = userId;
}

export function setShowBeginOnboardingWizard(state: MerchantBeginOnboardingStateInterface, payload: boolean) {
  state.showBeginOnboardingWizard = payload;
}

export function setUserDetails(
  state: MerchantBeginOnboardingStateInterface,
  userDetails: CreateUserDetailsInterface,
) {
  state.userDetails = {
    ...state.userDetails,
    ...userDetails,
  };
}

export function setMerchantDetails(
  state: MerchantBeginOnboardingStateInterface,
  merchantDetails: CreateMerchantDetailsInterface,
) {
  state.merchantDetails = {
    ...state.merchantDetails,
    ...merchantDetails,
  };
}
