<template>
  <v-stepper v-model="wizardStep">
    <v-stepper-header class="standard-wizard__footer-navigation">
      <v-stepper-item
        :complete="wizardStep > 1"
        :value="1"
        color="var(--secondary-color)" />
      <v-divider />

      <v-stepper-item
        :complete="wizardStep > 2"
        :value="2"
        color="var(--secondary-color)" />
      <v-divider />

      <v-stepper-item
        :complete="wizardStep > 3"
        :value="3"
        color="var(--secondary-color)" />
      <v-divider />

      <v-stepper-item
        :complete="wizardStep > 4"
        :value="4"
        color="var(--secondary-color)" />
      <v-divider />

      <v-stepper-item
        :complete="wizardStep > 5"
        :value="5"
        color="var(--secondary-color)" />
      <v-divider />

      <v-stepper-item
        v-if="IC1"
        :complete="wizardStep > 6"
        :value="6"
        color="var(--secondary-color)" />
    </v-stepper-header>
  </v-stepper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GetSponsorMixin from '@/mixins/GetSponsor';

export default defineComponent({
  name: 'WizardStep',

  mixins: [GetSponsorMixin],

  data() {
    return {
      IC1: false,
    };
  },

  computed: {
    wizardStep(): number {
      return this.$store.getters['MerchantOnboarding/getOnboardingWizardStep'];
    },
  },

  watch: {
    isTypeIC1(val: boolean) {
      if (val) {
        this.IC1 = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.v-divider {
  display: none;
}

:deep(.v-stepper-header) {
  max-height: 3.5rem;

  @include mobile {
    max-height: 2.5rem;
  }
}

.wizard-step {
  display: flex;
  flex: 1;
  position: relative;
  z-index: 1;
  justify-content: flex-end;

  &__step-number {
    font-size: 1.625rem;
    font-weight: 900;
    color: #FFFFFF;
  }

  &:first-child {
    flex: 0 0;
  }

  &:not(:first-child) {
    &:before {
      z-index: 0;
      position: absolute;
      left: 10px;
      top: calc(50% - 2px);
      right: 34px;
      content: '';
      height: 4px;
      background-color: var(--secondary-color);
    }
  }

  .circle {
    border-radius: 50%;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    background-color: var(--secondary-color);
    text-align: center;
    line-height: 0;
    display: flex;
    flex: 0 0;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  &.not-started {
    &:before,
    .circle {
      background-color: var(--grayscale-color-2);
    }
  }
}
</style>
