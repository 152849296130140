<template>
  <div>
    <merchant-onboarding-wizard-header v-if="showHeader" :show-logo="false" />

    <standard-input-otp
      body-text="Please enter the code we just sent to your mobile device."
      otp-message="I didn't receive a text message"
      :is-mob="true"
      :invitation-uuid="invitationUuid"
      :merchant-user-email="merchantUserEmail"
      :on-phone-number-updated="onPhoneNumberUpdated"
      :phone-number="phoneNumber"
      :session-token="sessionToken"
      :single-input="true"
      :verify-code="verifyCode"
      :autofocus-enabled="autoFocusEnabled"
      :disable-btn="disableBtn" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MerchantOnboardingWizardHeader from '@/components/Merchant/OnboardingWizard/Header.vue';
import StandardInputOtp from '@/components/Inputs/Otp/index.vue';

export default defineComponent({
  name: 'MobilePhoneVerification',

  components: {
    StandardInputOtp,
    MerchantOnboardingWizardHeader,
  },

  props: {
    dialog: { type: Boolean, default: false },
    invitationUuid: { type: String, default: '' },
    merchantUserEmail: { type: String, default: '' },
    sessionToken: { type: String, required: true },
    showHeader: { type: Boolean, default: false },
    autoFocusEnabled: { type: Boolean, default: false },
    phoneNumber: { type: String, required: true },
    verifyCode: { type: Function, required: true },
    onPhoneNumberUpdated: { type: Function, required: true },
    disableBtn: { type: Boolean, default: false },
  },

  data: () => ({
    bodyText: 'Please verify the mobile number you provided'
      + ' by entering the 6 digit code we just sent by text.',
  }),
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

</style>
