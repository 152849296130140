import { datadogRum } from '@datadog/browser-rum';

export default class Logger {
  private dataDog: any;

  constructor() {
    this.dataDog = datadogRum;
  }

  startMonitor(data: any) {
    if (data.DATADOG_APP_ID) {
      this.dataDog.init({
        applicationId: data.DATADOG_APP_ID,
        clientToken: data.DATADOG_CLIENT_TOKEN,
        site: data.DATADOG_SITE,
        service: data.DATADOG_SERVICE,
        env: data.DATADOG_ENV,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        telemetrySampleRate: 0,
      });

      this.startSessionRecording();
    }
  }

  private startSessionRecording() {
    this.dataDog.startSessionReplayRecording();
  }

  stopSessionRecording() {
    this.dataDog.stopSessionReplayRecording();
  }

  error(error: Error, context?: any) {
    this.dataDog.addError(error, context);
  }
}
