import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _directive_mask = _resolveDirective("mask")!

  return _withDirectives((_openBlock(), _createBlock(_component_text_input, _mergeProps(_ctx.$attrs, {
    inputmode: "numeric",
    "data-test": _ctx.dataTestAttr,
    "in-place": _ctx.inPlace,
    label: _ctx.labelAttr,
    required: _ctx.required,
    rules: [_ctx.isRequired, _ctx.isValidZipCode]
  }), null, 16, ["data-test", "in-place", "label", "required", "rules"])), [
    [_directive_mask, _ctx.inputMask]
  ])
}