import DATE_CONSTANTS from '@/constants/DateConstants';
import moment from 'moment';

export default (dateTime: string): string => {
  if (Date.parse(dateTime) > 0) {
    const date = moment(dateTime).format(DATE_CONSTANTS.dateFormat);
    const time = moment(dateTime).format(DATE_CONSTANTS.timeFormat);

    return `${date} | ${time}`;
  }

  return dateTime;
};
