import CreditEnginePullTypes from '@/enums/CreditEngine/PullTypes';
import CreditEngineCheckMixin from '@/mixins/Consumer/LoanApply/CreditEngineCheckMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CreditEngineSoftPullResultsDataMixin',
  mixins: [CreditEngineCheckMixin],
  computed: {
    creditEngineSoftPullResults(): any {
      return this.creditEngineCheck[CreditEnginePullTypes.SOFT];
    },
  },
});
