<template>
  <div>
    <standard-dialog
      :dialog="dialog"
      :on-cta="onCta"
      :on-cancel="onDialogCancel"
      title="Please Enter Refund Amount"
      :persistent="true"
      :show-cancel-cta="false"
      :show-clear-cta="false"
      :show-close-confirmation="false"
      :show-actions="false"
      light-title
      data-test="refundAnotherAmountModal">
      <template #body>
        <v-form ref="form">
          <currency-input
            v-model="refundAmount"
            allow-cents
            :disabled="disableInput"
            :error-messages="customErrorMessage.amount"
            :max="maxRefundableAmount"
            :min="0"
            label="Refund Amount"
            show-icon
            @update:model-value="makeInputDirty" />
        </v-form>
        <custom-button
          data-test="sendRefund"
          full-width
          :disabled="disableBtn"
          @click="openConfirmation">
          Send
        </custom-button>
      </template>
    </standard-dialog>

    <confirm-dialog
      :show-confirm-dialog="showConfirmDialog"
      :on-cancel="closeConfirmation"
      :on-confirm="sendRefund"
      :title-text="confirmTitle"
      :disable-confirm-button="disableConfirmButton"
      light-title>
      <template #body>
        <span>
          Refund <strong v-private>{{ CurrencyFormatLong(refundAmount) }}
          </strong> to <strong v-private>{{ refundToName }}</strong>.
        </span>
      </template>
    </confirm-dialog>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import CurrencyInput from '@/components/Inputs/Currency.vue';
import { ErrorsInterface } from '@/interfaces/ui/ErrorsInterface';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import RefundAlerts from '@/components/CustomerSummaryCard/RefundsDialog/Mixins/RefundAlerts';

export default defineComponent({
  name: 'RefundAnotherAmount',

  components: {
    ConfirmDialog,
    CurrencyInput,
    CustomButton,
    StandardDialog,
  },

  mixins: [
    RefundAlerts,
  ],

  props: {
    dialog: { type: Boolean, default: false },
    onCta: { type: Function as PropType<() => void>, default: () => null },
    onCancel: { type: Function as PropType<() => void>, default: () => null },
    transactionData: { type: Object, default: () => ({}) },
    consumerData: { type: Object, default: () => ({}) },
  },

  data() {
    const consumer: any = {};
    return {
      refundAmount: '0.00',
      disableInput: false,
      disableBtn: false,
      showConfirmDialog: false,
      confirmTitle: 'Please Confirm.',
      consumer,
      isInputDirty: false,
      disableConfirmButton: false,
    };
  },

  computed: {
    /* eslint-disable vue/no-side-effects-in-computed-properties */
    customErrorMessage(): ErrorsInterface {
      const errors: ErrorsInterface = {};

      if (Number(this.refundAmount) > this.maxRefundableAmount) {
        errors.amount = [`Refund amount cannot exceed ${CurrencyFormatLong(this.maxRefundableAmount)}`];
        this.disableBtn = true;
      } else if (!this.isInputDirty) {
        this.disableBtn = true;
      } else if (!Number(this.refundAmount)) {
        errors.amount = ['Please enter the refund amount'];
        this.disableBtn = true;
      } else {
        this.disableBtn = false;
      }

      return errors;
    },

    refundToName() {
      const { user } = this.consumer;
      return `${user.first_name} ${user.last_name}`;
    },

    /**
    * transforms a preformatted number string to a number
    */
    maxRefundableAmount(): number {
      if (!this.transactionData.maxRefundableAmount) {
        return 0;
      }

      const max = this.transactionData.maxRefundableAmount.replaceAll(',', '');

      return Number(max);
    },
  },

  watch: {
    consumerData: {
      immediate: true,
      handler(val: any) {
        this.consumer = val;
      },
    },
  },

  methods: {
    CurrencyFormatLong,

    onDialogCancel() {
      this.resetInputs();
      this.onCancel();
    },

    resetInputs() {
      this.refundAmount = '0.00';
      this.isInputDirty = false;
      const form = this.$refs.form as any;
      if (form) form.reset();
    },

    openConfirmation(): any {
      this.showConfirmDialog = true;
      this.onCancel();
    },

    closeConfirmation(): any {
      this.showConfirmDialog = false;
      this.resetInputs();
    },

    async sendRefund() {
      this.disableConfirmButton = true;

      const res = await this.$store.dispatch('MerchantPortal/dispatchRefund', {
        payment_identifier: this.transactionData.paymentIdentifier,
        amount: this.refundAmount,
      });

      this.disableConfirmButton = false;

      this.showConfirmDialog = false;
      this.onCancel();
      this.showAlert(res);
    },

    makeInputDirty(value: any) {
      if (value) {
        this.isInputDirty = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

</style>
