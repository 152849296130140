<template>
  <table>
    <thead>
      <tr colspan="2">
        <th
          id="example-table-head"
          colspan="2"
          class="text-center">
          <span class="example-table-heading">Example Deposits</span>
        </th>
      </tr>
      <tr>
        <th>Transaction</th>
        <th style="text-align: right;">
          Amount
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>ACCTVERIFY</td>
        <td style="text-align: right;">
          $00.XX
        </td>
      </tr>
      <tr>
        <td>ACCTVERIFY</td>
        <td style="text-align: right;">
          $00.XX
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VerifyDepositsExampleTable',
});
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--grayscale-color-3);
  margin-bottom: 2rem;

  thead {
    background: var(--grayscale-color-5);

    tr th {
      font-size: 0.875rem;
      color: var(--grayscale-color-1);
      font-weight: 400;
    }

    .example-table-heading {
      color: var(--grayscale-color-1);
      font-size: 14px;
      font-weight: bold;
    }
  }

  th, td {
    border: none;
    padding: 0 1rem;
  }

  tr {
    border-bottom: 1px solid var(--grayscale-color-3);
    height: 3rem;
  }
}
</style>
