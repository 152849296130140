<template>
  <div class="rate-sheets-options">
    <simple-card class="rate-sheets-options__data-table">
      <template v-if="showHeader" #header>
        <div v-if="simpleHeader" class="rate-sheet-simple-header">
          <h5>{{ headerTitle }}</h5>
        </div>

        <rate-sheet-options-data-table-header
          v-else
          ref="header"
          :header-title="headerTitle" />
      </template>

      <template #body>
        <div class="simple-card__body-text" :class="rateSheetTable ? 'ratesheet-table' : ''">
          <rate-sheet-options-data-table-legend v-if="showLegend" />

          <v-data-table
            :headers="headers"
            :items="rateSheetOptions"
            :loading="processing"
            :mobile="isMobile"
            :data-test="customDataTest"
            :items-per-page="10"
            class="minimal-pagination"
            item-key="rate_sheet_merchant_product_price_id"
            :row-props="toggleRowCustomClass"
            :items-per-page-options="[10]"
            no-data-text="No products available"
          >
            <template v-slot:header.product_name="{ column }">
              <header-with-tooltip
                :title="column.title"
                :align="column.align"
                :tooltip="column.tooltip"
                @click="activeTooltip = column" />
            </template>
            <template v-slot:header.full_term="{ column }">
              <header-with-tooltip
                :title="column.title"
                :align="column.align"
                :tooltip="column.tooltip"
                @click="activeTooltip = column" />
            </template>
            <template v-slot:header.apr="{ column }">
              <header-with-tooltip
                :title="column.title"
                :align="column.align"
                :tooltip="column.tooltip"
                @click="activeTooltip = column" />
            </template>
            <template v-slot:header.merchant_fee="{ column }">
              <header-with-tooltip
                :title="column.title"
                :align="column.align"
                :tooltip="column.tooltip"
                @click="activeTooltip = column" />
            </template>
            <!-- eslint-disable vue/valid-v-slot -->
            <template v-slot:item="{ item }">
              <tr
                v-if="isMobile"
                class="v-data-table__tr--mobile"
                :class="item.has_ripple && !isFromOfferCodes ? 'ripple-animation' : ''">
                <td class="v-data-table__td">
                  <div class="v-data-table__td-title">
                    {{ headers[0].title }}
                  </div>
                  <div class="v-data-table__td-value">
                    {{ item.short_description }}
                  </div>
                </td>
                <td class="v-data-table__td">
                  <div class="d-flex space-between v-data-table__td-title">
                    <header-with-tooltip
                      :title="headers[1].title"
                      :tooltip="headers[1].tooltip"
                      @click="activeTooltip = headers[1]" />
                  </div>
                  <div class="v-data-table__td-value" :class="{ 'has-fallback': item.has_fallback }">
                    {{ item.product_name }}
                    <v-icon
                      v-if="item.has_fallback"
                      size="1.5rem"
                      color="var(--grayscale-color-1)"
                      @click="$emit('show-fallback-modal')">
                      mdi-information-outline
                    </v-icon>
                  </div>
                </td>
                <td class="v-data-table__td">
                  <div class="d-flex space-between v-data-table__td-title">
                    <header-with-tooltip
                      :title="headers[2].title"
                      :tooltip="headers[2].tooltip"
                      @click="activeTooltip = headers[2]" />
                  </div>
                  <div class="v-data-table__td-value">
                    {{ item.full_term }}
                  </div>
                </td>
                <td class="v-data-table__td">
                  <div class="d-flex space-between v-data-table__td-title">
                    <header-with-tooltip
                      :title="headers[3].title"
                      :tooltip="headers[3].tooltip"
                      @click="activeTooltip = headers[3]" />
                  </div>
                  <div class="v-data-table__td-value">
                    {{ item.apr }}
                  </div>
                </td>
                <td class="v-data-table__td">
                  <div class="d-flex space-between v-data-table__td-title">
                    <header-with-tooltip
                      :title="headers[4].title"
                      :tooltip="headers[4].tooltip"
                      @click="activeTooltip = headers[4]" />
                  </div>
                  <div class="v-data-table__td-value">
                    {{ merchantFeeLabel(item.merchant_fee) }}
                  </div>
                </td>
                <td class="v-data-table__td" style="width: 100%;">
                  <div class="v-data-table__td-title">
                    {{ headers[5].title }}
                  </div>
                  <div class="v-data-table__td-value">
                    <preferences :customer-preferences="item.customer_preferences" />
                  </div>
                </td>
                <td
                  v-if="!additionalProductOfferings && !switchToggle"
                  class="d-flex justify-end v-data-table__td"
                  style="width: 100%;">
                  <div class="v-data-table__td-value">
                    <rate-sheet-options-more-data
                      :merchant-onboarding="merchantOnboarding"
                      :rate-sheet-option="item"
                      is-mobile
                      @toggle="removeRipple" />
                  </div>
                </td>
                <td
                  v-if="additionalProductOfferings || switchToggle"
                  class="d-flex justify-end v-data-table__td"
                  style="width: 100%;">
                  <div class="v-data-table__td-value">
                    <rate-sheet-options-more-data
                      :merchant-onboarding="merchantOnboarding"
                      :rate-sheet-option="item"
                      rate-sheet-action="Add To Rate Sheet"
                      @toggle="selectRateSheetOption(item)" />
                  </div>
                </td>
                <td
                  v-if="additionalProductOfferings || switchToggle"
                  class="d-flex justify-end v-data-table__td"
                  style="width: 100%;">
                  <div class="v-data-table__td__cell">
                    <selector
                      class="button-full-width"
                      :button-toggle="buttonToggle"
                      :switch-toggle="switchToggle"
                      :disabled-add="disableAddBtns"
                      :selected="item.is_selected"
                      :additional-product-offerings="additionalProductOfferings"
                      @toggle="selectRateSheetOption(item)" />
                  </div>
                </td>
              </tr>

              <tr v-else :class="item.has_ripple && !isFromOfferCodes ? 'ripple-animation' : ''">
                <td class="text-left">
                  {{ item.short_description }}
                </td>
                <td class="text-left" :class="{ 'has-fallback': item.has_fallback }">
                  {{ item.product_name }}
                  <v-icon
                    v-if="item.has_fallback"
                    size="1.5rem"
                    color="var(--grayscale-color-1)"
                    @click="$emit('show-fallback-modal')">
                    mdi-information-outline
                  </v-icon>
                </td>
                <td class="text-right">
                  {{ item.full_term }}
                </td>
                <td class="text-right">
                  {{ item.apr }}
                </td>
                <td class="text-right">
                  {{ merchantFeeLabel(item.merchant_fee) }}
                </td>
                <td>
                  <preferences :customer-preferences="item.customer_preferences" />
                </td>
                <td v-if="!additionalProductOfferings && !switchToggle">
                  <rate-sheet-options-more-data
                    :merchant-onboarding="merchantOnboarding"
                    :rate-sheet-option="item"
                    @toggle="removeRipple" />
                </td>
                <td v-if="additionalProductOfferings || switchToggle" class="more-data-action">
                  <rate-sheet-options-more-data
                    :merchant-onboarding="merchantOnboarding"
                    :rate-sheet-option="item"
                    :is-additional="true"
                    rate-sheet-action="Add To Rate Sheet"
                    @toggle="selectRateSheetOption(item)" />
                  <selector
                    class="button-full-width"
                    :button-toggle="buttonToggle"
                    :switch-toggle="switchToggle"
                    :disabled-add="disableAddBtns"
                    :selected="item.is_selected"
                    :additional-product-offerings="additionalProductOfferings"
                    :from-rate-sheet="!!isFromOfferCodes"
                    :product-name="item.product_name"
                    data-test="addToRateSheet"
                    @toggle="selectRateSheetOption(item)" />
                </td>
              </tr>
            </template>
            <!-- eslint-enable -->
          </v-data-table>
        </div>
      </template>
    </simple-card>

    <custom-dialog
      v-if="activeTooltip"
      dialog
      :show-cancel-cta="false"
      :title="activeTooltip.title"
      button-label="OK"
      button-size="small"
      width="420"
      :on-cta="closeTooltip"
      @cancel="closeTooltip">
      <template #body>
        {{ activeTooltip.tooltip }}
      </template>
    </custom-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import CustomDialog from '@/components/Dialogs/index.vue';
import Preferences from '@/components/RateSheetOptions/DataTable/CustomerPreferences.vue';
import RateSheetOptionsDataTableHeader from '@/components/RateSheetOptions/DataTable/Header.vue';
import RateSheetOptionsDataTableLegend from '@/components/RateSheetOptions/DataTable/Legend.vue';
import RateSheetOptionsMoreData from '@/components/RateSheetOptions/MoreData.vue';
import SimpleCard from '@/components/SimpleCard.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import GetProcessing from '@/mixins/GetProcessing';
import StandardInputMixin from '@/mixins/StandardInputMixin';
import HeaderWithTooltip from '@/components/Tables/TableHeaderWithTooltip.vue';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import { COMMON_HEADERS } from '@/components/Merchant/Portal/RateSheets/common';
import Selector from './Selector.vue';

export default defineComponent({
  name: 'RateSheetOptionsDataTable',

  components: {
    CustomDialog,
    HeaderWithTooltip,
    RateSheetOptionsDataTableHeader,
    RateSheetOptionsDataTableLegend,
    Preferences,
    RateSheetOptionsMoreData,
    SimpleCard,
    Selector,
  },

  mixins: [GetProcessing, StandardInputMixin],

  props: {
    showLegend: { type: Boolean, default: true },
    showHeader: { type: Boolean, default: true },
    showHeaderInfoOnMount: { type: Boolean, default: false },
    rateSheetOptions: { type: Array, default: () => [] },
    rateSheetTable: { type: Boolean, default: false },
    buttonToggle: { type: Boolean, default: false },
    switchToggle: { type: Boolean, default: false },
    additionalProductOfferings: { type: Boolean, default: false },
    disableAddBtns: { type: Boolean, default: false },
    mobileBreakpoint: { type: Number, default: 600 },
    isFromOfferCodes: { type: Boolean, default: false },
    headerTitle: { type: String, default: '' },
    merchantOnboarding: { type: Boolean, default: false },
    tableDataTest: { type: String, default: '' },
    simpleHeader: { type: Boolean, default: false },
  },

  data: () => ({
    selectedRates: [] as Array<RateSheetData>,
    pagination: {
      descending: true,
      rowsPerPage: 1000,
    } as any,
    activeTooltip: null as TableHeaderInterface | null,
  }),

  computed: {
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },
    headers(): Array<TableHeaderInterface> {
      const headers = [...cloneDeep(COMMON_HEADERS)];
      if (this.switchToggle) {
        headers.push({
          title: 'Active',
          sortable: false,
          align: 'end',
          width: '120px',
          value: 'select',
        });
      } else {
        headers.push({
          title: '',
          sortable: false,
          align: 'end',
          width: '60px',
          value: 'select',
        });
      }

      return headers;
    },

    customDataTest() {
      return this.tableDataTest ? this.tableDataTest : 'mainRateSheetTable';
    },

    rateSheetOptionIds(): Array<number> {
      return (this.rateSheetOptions as RateSheetData[]).map(it => {
        return it.rate_sheet_merchant_product_price_id;
      });
    },
  },

  mounted() {
    if (this.$props.showHeaderInfoOnMount) {
      this.showHeaderInfo();
    }
  },

  methods: {
    selectRateSheetOption(rateSheet: RateSheetData) {
      if (this.processing) return;
      if (!this.isFromOfferCodes) rateSheet.has_ripple = true;

      rateSheet.is_selected = !rateSheet.is_selected;
      this.$emit('toggle', rateSheet);
    },

    removeRipple() {
      (this.rateSheetOptions as RateSheetData[]).forEach(element => {
        if (element.is_selected) element.has_ripple = false;
      });
    },

    toggleRowCustomClass(rateSheet: RateSheetData) {
      return {
        class: {
          'row-not-selected-bg': !rateSheet.is_selected,
        },
      };
    },

    merchantFeeLabel(fee: string): string {
      return `${fee}%`;
    },

    showHeaderInfo() {
      const { header } = this.$refs;

      if (header) {
        (header as any).showModal();
      }
    },

    showModal() {
      (this.$refs.modal as any).showModal();
    },

    closeModal() {
      (this.$refs.modal as any).closeModal();
    },

    closeTooltip() {
      this.activeTooltip = null;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/rate-sheet-options/rate-sheet-options-data-table";
@import '@/assets/scss/mixins/media_queries';
@import "@/assets/scss/variables/_custom-variables";

.simple-card__body-text {
  width: 100%;
  padding: 0 1rem !important;

  @include mobile {
    padding: 1rem 0 !important;
  }
}

.rate-sheet-simple-header {
  width: 100%;
  height: 3rem;
  padding: 1rem;
  background-color: var(--grayscale-color-5);

  h5 {
    margin: 0;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 800;
    color: $momnt-navy;
  }
}

:deep() {
  .v-table {
    &__wrapper {
      overflow-y: hidden;

      table th div {
        display: flex;
        align-items: center;

        @include tablet {
          span {
            line-height: normal;
          }
        }
      }

      @include tablet {
        table > tbody > tr > td,
        table > thead > tr > th {
          margin: 0 .5rem;
        }
      }

      @include media-breakpoint-down-xs {
        table > thead {
          display: none;
        }
      }
    }

    .has-fallback {
      display: flex;
      align-items: center;

      i {
        margin-left: 0.625rem;
      }
    }

    .more-data-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
    }

    .v-data-footer {
      justify-content: end;
    }

    &__wrapper table .v-data-table__tr--mobile {
      padding: 1rem !important;
      box-shadow: inset 0px -1px 0px var(--grayscale-color-3);

      .v-data-table__td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0;
        color: var(--grayscale-color-1);

        .v-data-table__td__title {
          font-weight: 700;
          color: var(--grayscale-color-1);
        }
      }
    }
  }
}

.standard-form-button--link:active {
  background-color: transparent !important;
  color: var(--color-primary) !important;
}

.custom-checkbox {
  width: 1.5rem;
}

:deep(.row-not-selected-bg.v-table__selected) {
  background: var(--grayscale-color-4)!important;
  background-color: var(--grayscale-color-4)!important;
}

:deep(.button-full-width .v-theme--light.v-btn.custom-button.button-small) {
  width: 100%;
}

:deep(.button-full-width) {
  .custom-switch .v-input--is-label-active .v-input__control .v-switch__track {
    color: var(--grayscale-color-1) !important;
    opacity: 1 !important;
  }
}

.ripple-animation {
  animation-name: rippleBg;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.7s;
  background-image: linear-gradient(#eee, #eee);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size .5s, color .5s;
}

@keyframes rippleBg {
  0% {
    background-size: 0 100%;
  }

  100% {
    background-size: 100% 100%;
  }
}

@include mobile {
  :deep() {
    .v-table > .v-table__wrapper .v-data-table__td {
      &:nth-last-child(1),
      &:nth-last-child(2) {
        display: inline-flex;
      }

      &:nth-last-child(1) {
        width: 20%;
        padding-left: 0;
        border-bottom: none !important;
      }

      &:nth-last-child(2) {
        width: 80%;
        padding-right: 0;
      }
    }
  }
}

@include up-to-desktop {
  :deep() {
    .v-theme--light.v-table > .v-table {
      &__wrapper > table > tbody >
        tr:hover:not(.v-table__expanded__content):not(.v-table__empty-wrapper) {
          background: #fff;
        }
    }
  }
}

.ratesheet-table {
  :deep() {
    .v-table-header tr th span {
      color: var(--grayscale-color-1);
    }

    tbody tr td {
      color: var(--grayscale-color-1);
    }

    .v-data-table__tr--mobile .v-data-table__td .v-data-table__td__value {
      color: var(--grayscale-color-1);
    }
  }
}
</style>
