import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    class: "fs-unmask",
    "data-test": "MOB-step1-substep2",
    onSubmit: _withModifiers(_ctx.goToNextSubStep, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          (_ctx.programs.length)
            ? (_openBlock(), _createBlock(_component_v_select, {
                key: 0,
                ref: "program",
                modelValue: _ctx.selectedProgramUuid,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedProgramUuid) = $event)),
                class: "old-v-select",
                variant: "outlined",
                disabled: _ctx.programDisabled,
                items: _ctx.orderedPrograms,
                "data-test": "programInput",
                label: "Industry",
                "item-title": "name",
                "item-value": "program_uuid"
              }, null, 8, ["modelValue", "disabled", "items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_select, {
            ref: "subProgram",
            modelValue: _ctx.selectedSubprogramUuid,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSubprogramUuid) = $event)),
            class: "old-v-select",
            variant: "outlined",
            disabled: _ctx.disabled,
            items: _ctx.orderedSubprograms,
            "data-test": "subInput",
            label: "Subindustry",
            "item-title": "name",
            "item-value": "subprogram_uuid"
          }, null, 8, ["modelValue", "disabled", "items"]),
          _createVNode(_component_custom_button, {
            disabled: _ctx.nextSubstepDisabled,
            "data-test": "nextBtn",
            "full-width": "",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Next ")
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}