import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FeatureFlagsMixin',
  methods: {
    isFeatureEnabled(featureName: string): boolean {
      return this.$store.getters['Config/getFeatureFlags'].includes(featureName);
    },
  },
});
