<template>
  <div class="portal-header__user-info">
    <div class="d-flex flex-grow-1 portal-header__user-info__details-container">
      <div class="portal-header__user-info__details">
        <span class="portal-header__user-info__details__text">Hello</span>
        <span
          v-private="'Username'"
          class="portal-header__user-info__details__name"
          data-test="userNameLabel">
          {{ userNameLabel }}
        </span>
      </div>

      <div class="portal-header__user-info__role">
        <span class="portal-header__user-info__role__text">Role:</span>

        <span class="portal-header__user-info__role__name" data-test="roleLabel">
          {{ userRoleLabel }}
        </span>
      </div>
      <div class="portal-header__user-info__actions">
        <custom-button variant="text" @click="showResetPasswordDialog">
          Update Password
        </custom-button>
        <custom-button
          v-if="multiLocationActive && location"
          variant="text"
          @click="showLocationSettings">
          Location Settings
        </custom-button>
        <custom-button
          variant="text"
          data-test="logOutBtn"
          @click="userLogout">
          Log Out
        </custom-button>
      </div>
    </div>

    <reset-user-password-modal
      :show="isResetPasswordDialogVisible"
      :mobile="true"
      :on-close="hideResetPasswordDialog" />
    <location-settings-modal
      v-if="locationSettingsVisible"
      @close="locationSettingsVisible = false" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ResetUserPasswordModal
  from '@/components/Merchant/Portal/Header/ResetUserPassword/Modal.vue';
import GotoRouteName from '@/mixins/GotoRouteName';
import UserLogOutMixin from '@/mixins/Auth/UserLogOutMixin';
import LocationSettingsModal from '@/components/Merchant/Portal/Header/LocationSettingsModal.vue';
import { MerchantLocation } from '@/interfaces/merchant/MerchantLocation';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import SharedUserInfoMixin from './SharedUserInfoMixin';

export default defineComponent({
  name: 'ActionsMobileFooter',
  components: {
    CustomButton,
    LocationSettingsModal,
    ResetUserPasswordModal,
  },
  mixins: [
    FeatureFlagsMixin,
    GotoRouteName,
    UserLogOutMixin,
    SharedUserInfoMixin,
  ],
  data() {
    return {
      isResetPasswordDialogVisible: false,
      locationSettingsVisible: false,
    };
  },
  computed: {
    location(): MerchantLocation | null {
      return this.$store.getters['MerchantPortal/getMerchantLocation'];
    },
    multiLocationActive(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.MULTI_LOCATION);
    },
  },
  methods: {
    showResetPasswordDialog() {
      this.isResetPasswordDialogVisible = true;
    },
    hideResetPasswordDialog() {
      this.isResetPasswordDialogVisible = false;
    },
    showLocationSettings() {
      this.locationSettingsVisible = true;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.portal-header__user-info {
  flex-wrap: nowrap !important;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: .75rem;
  overflow: hidden;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  background: #F4F5F6;

  &__details-container {
    display: flex;
    flex-direction: column;
  }

  &__details {
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 1rem;
    color: var(--grayscale-color-1);

    &__name {
      width: 16rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__role {
    display: flex;
    align-items: center;
    text-align: left;
    height: 1rem;
    margin-bottom: 1rem;
    color: var(--grayscale-color-1);

    &__name {
      margin-left: .3rem;
    }
  }

  &__actions {
    > button {
      margin-bottom: 1rem;
      height: auto !important;
      padding: 0 !important;
      display: block;
      text-align: left;
      font-size: 0.875rem !important;

      &:last-child {
        margin-bottom: 0;

        :deep(.v-btn__content) {
          justify-content: flex-start;
        }
      }
    }
  }

  &__details {
    &__name {
      margin-left: .3rem;
    }
  }

  &__role {
    position: relative;
  }

  &__cta {
    .btn {
      height: 100%;
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 1.5rem;
      }
    }
  }
}

@include up-to-desktop {
  .portal-header{
    &__user-info {
      background: var(--grayscale-color-5);

      &__details {
        &__text {
          color: var(--grayscale-color-1);
        }
      }

      &__role {
        &__text {
          color: var(--grayscale-color-1);
        }
      }

      &__cta {
        background: var(--grayscale-color-4);

        :deep(.v-theme--light.v-btn.custom-button.button-text) {
          background: var(--grayscale-color-4);
        }

        @media only screen and (max-width: 359px) {
          width: 100%;
        }
      }

    }
  }
}
</style>
