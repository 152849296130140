import { defineComponent } from 'vue';
import MerchantPermissionsMixin from '@/mixins/Auth/MerchantPermissionsMixin';

export default defineComponent({
  name: 'SharedUserInfoMixin',
  mixins: [MerchantPermissionsMixin],
  computed: {
    user(): any {
      return this.$store.getters['Auth/getUser'];
    },
    isUserAdmin(): boolean {
      return this.isAdminOrAbove;
    },
    userNameLabel(): string {
      return `${this.user.first_name} ${this.user.last_name}`;
    },
  },
});
