<template>
  <v-form class="page page--login portal" @submit.prevent="setUserPassword">
    <v-container class="portal__header">
      <v-row justify="center">
        <v-col lg="5" md="6">
          <div class="text-center">
            <img
              class="logo-image"
              :src="logoUrl"
              alt="Logo">
          </div>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col lg="5" md="6">
          <h4>
            Merchant Portal
          </h4>

          <p>
            Welcome! Please create your account.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="portal__container">
      <v-row justify="center">
        <v-col lg="5" md="6">
          <email-input
            :model-value="email"
            disabled
            show-icon />
        </v-col>
      </v-row>

      <v-row v-if="combinedErrors.length" justify="center">
        <v-col lg="5" md="6">
          <standard-error-list :show-detail-errors="true" :show-non-field-errors="true" />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col lg="5" md="6">
          <password-input
            v-model="formData.password"
            data-test="passwordInput"
            :disabled="processing"
            :error-messages="errors.password"
            label="Create Password"
            :password-verify="formData.password_check"
            show-requirements-tooltip
            @blur="resetErrorState" />

          <password-input
            v-model="formData.password_check"
            data-test="passwordCheckInput"
            :disabled="processing"
            :error-messages="errors.password_check"
            label="Verify Password"
            :password-verify="formData.password"
            @blur="resetErrorState" />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col lg="5" md="6">
          <custom-button
            full-width
            :disabled="!formValid || btnDisabled"
            data-test="submitBtn"
            type="submit">
            Submit
          </custom-button>
        </v-col>
      </v-row>
    </v-container>

    <merchant-portal-footer class="portal__footer" />
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { getUserInviteToken, setUserInvitePassword } from '@/api/account';
import EmailInput from '@/components/Inputs/Email.vue';
import PasswordInput from '@/components/Inputs/Password.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import MerchantPortalFooter from '@/components/Merchant/Portal/Footer.vue';
import StandardErrorList from '@/components/ErrorList.vue';
import { CreateUserDataInterface } from '@/interfaces/auth/CreateUserDataInterface';
import { LoginDataInterface } from '@/interfaces/auth/LoginDataInterface';
import { NewUserInvitationTokenDataInterface } from '@/interfaces/auth/NewUserInvitationTokenDataInterface';
import { SetUserInvitePasswordPayloadInterface } from '@/interfaces/auth/SetUserInvitePasswordPayloadInterface';
import { ErrorsInterface } from '@/interfaces/ui/ErrorsInterface';
import UserLogOutMixin from '@/mixins/Auth/UserLogOutMixin';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import GotoRouteName from '@/mixins/GotoRouteName';
import emailAddress from '@/validators/email_address';
import passwordValidator from '@/validators/password';
import uuid from '@/validators/uuid';
import LocalStorageConstants from '@/constants/LocalStorageConstants';

export default defineComponent({
  name: 'CreateUser',

  components: {
    EmailInput,
    PasswordInput,
    CustomButton,
    StandardErrorList,
    MerchantPortalFooter,
  },

  mixins: [
    GotoRouteName,
    GetErrors,
    GetProcessing,
    UserLogOutMixin,
  ],

  beforeRouteEnter({ query }, _, next) {
    const token = query?.token || '';
    if (uuid(token as string)) {
      next();
    } else {
      next({ name: 'merchant-login' });
    }
  },

  data() {
    const formData: CreateUserDataInterface = {
      password: '',
      password_check: '',
    };
    return {
      btnDisabled: false,
      formData,
    };
  },

  computed: {
    ...mapGetters('Ui', {
      logoUrl: 'getBrandingHeaderLogo',
    }),

    newUserInviteTokenData(): NewUserInvitationTokenDataInterface {
      return this.$store.getters['Auth/getNewUserInviteTokenData'];
    },

    token(): string {
      return this.newUserInviteTokenData.token;
    },

    email(): string {
      return this.newUserInviteTokenData.merchant_user_email;
    },

    passwordRequirementsMet(): boolean {
      return this.formData.password === this.formData.password_check
        && passwordValidator(this.formData.password);
    },

    formValid(): boolean {
      return !!this.token
        && emailAddress(this.email)
        && this.passwordRequirementsMet;
    },

    combinedErrors(): Array<ErrorsInterface> {
      const errors: any = [];

      const { non_field_errors, password } = this.errors;

      if (non_field_errors) {
        errors.push(...non_field_errors);
      }

      if (password) {
        errors.push(password);
      }

      return errors;
    },
  },

  async created() {
    const userToken: string = this.$route.query?.token as string || '';
    this.$store.dispatch('Auth/setNewUserInviteTokenData', {});

    try {
      const { data, status } = await getUserInviteToken(userToken);
      this.$store.dispatch('Auth/setNewUserInviteTokenData', data);
      if (status !== 200) this.gotoRouteName('merchant-login');
    } catch (error: any) {
      this.$store.dispatch('Ui/setErrors', error.response);
    }
  },

  methods: {
    async setUserPassword() {
      const { password, password_check } = this.formData;
      const { token } = this;

      this.btnDisabled = true;

      const payload: SetUserInvitePasswordPayloadInterface = {
        token,
        password,
        password_check,
      };

      try {
        await setUserInvitePassword(payload);
        localStorage.setItem('fromCreateUser', 'true');
        localStorage.setItem(LocalStorageConstants.NEW_USER_EMAIL, this.email);
        this.login();
      } catch (error: any) {
        this.$store.dispatch('Ui/setErrors', error.response);
      }

      this.btnDisabled = false;
    },

    resetErrorState() {
      this.$store.dispatch('Ui/resetErrorState');
    },

    async login() {
      const { password } = this.formData;
      const { email } = this;

      const postData: LoginDataInterface = { email, password };
      const { error } = await this.$store.dispatch('Auth/userLogin', postData);

      if (!error) {
        this.gotoRouteName('merchant-activity-center');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";
@import "@/assets/scss/_custom-transitions";

@include portal-page-layout;

h4 {
  font-weight: 900;
  font-size: 1.625rem;
  line-height: 150%;
  text-align: center;
  color: $dark-blue;
  margin-top: 0;
  margin-bottom: 1.625rem;
}

.logo-image {
  max-width: 190px;
  height: auto;
  padding-top: 2rem;
}
</style>
