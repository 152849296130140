import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bb22c88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-header" }
const _hoisted_2 = {
  key: 0,
  class: "additional-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "custom-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "table-title" }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
          (_ctx.failedTransactions)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, " - Transactions displayed here have been reversed due to an ACH return. An email was sent to notify all account admins for any failed transaction(s). "))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_data_table, {
        class: "statements-table",
        headers: _ctx.headers,
        items: _ctx.transactions,
        "items-per-page": -1,
        "hide-default-footer": ""
      }, {
        "item.transaction_date": _withCtx(({ item }) => [
          _createTextVNode(_toDisplayString(_ctx.date(item.transaction_date)), 1)
        ]),
        "item.consumer_name_and_loan_num": _withCtx(({ item }) => [
          _withDirectives((_openBlock(), _createElementBlock("span", null, [
            _createTextVNode(_toDisplayString(item.consumer_name), 1)
          ])), [
            [_directive_private]
          ]),
          _createTextVNode(" " + _toDisplayString(item.consumer_loan_number), 1)
        ]),
        "item.total_transaction_amount": _withCtx(({ item }) => [
          _withDirectives((_openBlock(), _createElementBlock("span", null, [
            _createTextVNode(_toDisplayString(_ctx.CurrencyFormatLong(item.total_transaction_amount)), 1)
          ])), [
            [_directive_private]
          ])
        ]),
        "item.merchant_fee_amount": _withCtx(({ item }) => [
          _withDirectives((_openBlock(), _createElementBlock("span", null, [
            _createTextVNode(_toDisplayString(_ctx.CurrencyFormatLong(item.merchant_fee_amount)), 1)
          ])), [
            [_directive_private]
          ])
        ]),
        "item.net_merchant_receipt": _withCtx(({ item }) => [
          _withDirectives((_openBlock(), _createElementBlock("span", null, [
            _createTextVNode(_toDisplayString(_ctx.CurrencyFormatLong(item.net_merchant_receipt)), 1)
          ])), [
            [_directive_private]
          ])
        ]),
        "item.merchant_fee_percentage": _withCtx(({ item }) => [
          _createTextVNode(_toDisplayString(_ctx.Percentage(item.merchant_fee_percentage)), 1)
        ]),
        _: 1
      }, 8, ["headers", "items"])
    ]),
    _: 1
  }))
}