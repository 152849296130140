import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_transaction_table = _resolveComponent("transaction-table")!
  const _component_simple_card = _resolveComponent("simple-card")!

  return (_openBlock(), _createBlock(_component_simple_card, { class: "fs-unmask gray-border merchant-portal-page-recent-activity section-card" }, {
    body: _withCtx(() => [
      _createVNode(_component_transaction_table, {
        page: _ctx.currentPage,
        "onUpdate:page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
        title: "Recent Transactions",
        "is-mobile": _ctx.isMobile,
        "items-per-page-options": [5],
        "items-per-page": 5,
        "items-length": _ctx.recentTransactionsCount,
        items: _ctx.recentTransactions,
        "refreshing-from-parent": _ctx.refresh,
        onRefreshTable: _ctx.refreshTable,
        onUpdatePage: _ctx.sortTable,
        onUpdateSearchPage: _ctx.updateSearchPage
      }, null, 8, ["page", "is-mobile", "items-length", "items", "refreshing-from-parent", "onRefreshTable", "onUpdatePage", "onUpdateSearchPage"])
    ]),
    _: 1
  }))
}