import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!

  return (_openBlock(), _createBlock(_component_v_checkbox, _mergeProps({
    modelValue: _ctx.internalValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event))
  }, _ctx.$attrs, {
    color: "#4C5560",
    ripple: _ctx.ripple
  }), {
    label: _withCtx(() => [
      _renderSlot(_ctx.$slots, "label", {}, undefined, true)
    ]),
    _: 3
  }, 16, ["modelValue", "ripple"]))
}