<template>
  <main-footer
    :human-readable-id="merchantHumanReadableIdLabel"
    human-readable-id-prefix="Your Merchant ID:" />
</template>

<script lang="ts">
import MainFooter from '@/components/MainFooter.vue';
import MerchantHumanReadableApplicationId
  from '@/mixins/Merchant/HumanReadableApplicationId';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PortalFooter',
  components: { MainFooter },
  mixins: [MerchantHumanReadableApplicationId],
});
</script>
