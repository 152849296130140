import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: _ctx.showSystemErrorNotification,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showSystemErrorNotification) = $event)),
    color: _ctx.notificationColor,
    "multi-line": true,
    location: "top"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.systemErrorNotificationMsg), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "color"]))
}