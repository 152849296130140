import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_otp_input = _resolveComponent("v-otp-input")!

  return (_openBlock(), _createBlock(_component_v_otp_input, _mergeProps(_ctx.$attrs, {
    ref: "vOtpInput",
    "model-value": _ctx.modelValue,
    "min-width": "100%",
    "data-test": _ctx.defaultDataTestAttr,
    variant: _ctx.inPlace ? 'plain' : 'outlined',
    required: _ctx.required,
    label: _ctx.labelAttr,
    inputmode: "numeric",
    inputtype: "numeric",
    rules: [_ctx.otpValidation],
    "validate-on": "blur",
    "onUpdate:modelValue": _ctx.update
  }), null, 16, ["model-value", "data-test", "variant", "required", "label", "rules", "onUpdate:modelValue"]))
}