<template>
  <div class="customer-preference">
    <rate-sheet-options-data-table-customer-preference-item
      v-for="(preference, index) in customerPreferences"
      :key="index"
      :customer-preference="preference" />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import RateSheetOptionsDataTableCustomerPreferenceItem
  from '@/components/RateSheetOptions/DataTable/CustomerPreferenceItem.vue';

export default defineComponent({
  name: 'CustomerPreferences',

  components: {
    RateSheetOptionsDataTableCustomerPreferenceItem,
  },

  props: {
    customerPreferences: { type: Array as PropType<string[]>, required: true },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/rate-sheet-options/rate-sheet-options-customer-preferences";

</style>
