import axios from 'axios';
import { LoginDataInterface } from '@/interfaces/auth/LoginDataInterface';
import { ResetPasswordConfirmInterface } from '@/interfaces/auth/ResetPasswordConfirmInterface';
import { ResetUserPasswordData } from '@/interfaces/merchantPortal/ResetUserPasswordData';
import {
  AXIOS_CONFIG, BASE_PATH, instance,
} from './index';

const USER_PATH = `${BASE_PATH}/account/user`;

export function loginUser(credentials: LoginDataInterface) {
  return axios.post(`${USER_PATH}/token/`, credentials);
}

export function forgotPasswordBegin(email: string) {
  return axios.post(`${USER_PATH}/password-forget-begin/`, { email });
}

export function forgotPasswordConfirm(payload: ResetPasswordConfirmInterface) {
  return axios.post(`${USER_PATH}/password-forget-confirm/`, payload);
}

export function updateSession(payload: any) {
  const sessionId: string | null = localStorage.getItem('sessionId');
  if (!sessionId) return Promise.reject();

  return instance.patch(`${BASE_PATH}/account/session/${sessionId}/`, payload);
}

export function resetPassword(userId: number | string, payload: ResetUserPasswordData) {
  return instance.post(`${USER_PATH}/${userId}/reset_password/`, payload);
}

export function getUserInviteToken(token: string) {
  return axios.get(`${BASE_PATH}/merchant/user-invite-token/${token}/`, AXIOS_CONFIG());
}

export function setUserInvitePassword(payload: ResetPasswordConfirmInterface) {
  return axios.post(`${BASE_PATH}/merchant/user-invite-password/`, payload, AXIOS_CONFIG());
}

export function gucaCheck(payload: any) {
  return instance.post(`${BASE_PATH}/fraud/guca-check/`, payload);
}
