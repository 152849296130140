enum ConsentTypesEnum {
  PRIVACY_POLICY = 12,
  TERMS_OF_USE = 14,
  E_SIGN_DISCLOSURE_AND_CONSENT = 15,
  CREDIT_REPORT_AUTHORIZATION = 9,
  TCPA = 16,
  ELECTRONIC_COMMUNICATION_CONSENT = 60,
  MERCHANT_AGREEMENT = 17,
  OPERATING_INSTRUCTIONS = 18,
  REPAY = 19,
  ATTESTATION_OF_APPLICATION_INFORMATION = 48,
  // Soft pull
  AAN_SOFT_PULL_DECLINE_ONSCREEN = 1,
  AAA_SOFT_PULL_APPROVE_FOR_LESS_ONSCREEN = 2,
  AAA_SOFT_PULL_APPROVE_FOR_LESS_PDF_EMAILED = 8,
  AAA_SOFT_PULL_DECLINED_PDF_EMAILED = 10,
  // Hard Pull
  HARD_PULL_CONSENT = 5,
  AAN_HARD_PULL_DECLINE_ONSCREEN = 3,
  AAA_HARD_PULL_APPROVE_FOR_LESS_ONSCREEN = 4,
  AAA_HARD_PULL_APPROVE_FOR_LESS_PDF_EMAILED = 6,
  AAA_HARD_PULL_DECLINED_PDF_EMAILED = 7,

  WARM_WELCOME = 26,
  BORROWER_AGREEMENT = 35,

  MOOV_MERCHANT = 64,

  PRODUCT_AND_PRICING_JULY = 71,

  PRODUCT_AND_PRICING_SEPTEMBER = 73,

  HEALTH_CARE_OCTOBER = 74,

  HOME_IMPROVEMENT = 75,

  APR_CAP_COMPLIANCE = 77,
}

export default ConsentTypesEnum;
