import { resolveComponent as _resolveComponent, vShow as _vShow, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!

  return _withDirectives((_openBlock(), _createBlock(_component_select_input, _mergeProps(_ctx.$attrs, {
    "blur-on-clear": "",
    class: "offer-code-select",
    clearable: "",
    "clear-icon": "mdi-close",
    disabled: _ctx.disabled,
    "hide-dropdown-icon": "",
    "item-title": "name",
    "item-value": "id",
    "item-color": "var(--primary-color)",
    items: _ctx.offerCodes,
    label: "Select Offer Code",
    "return-object": "",
    "menu-props": _ctx.menuProps
  }), null, 16, ["disabled", "items", "menu-props"])), [
    [_vShow, _ctx.offerCodes.length]
  ])
}