<template>
  <v-container
    fluid
    class="fs-unmask merchant__all-transaction portal__page portal__page--card-full-size">
    <merchant-portal-all-transactions />
  </v-container>
</template>

<script lang="ts">
import MerchantPortalAllTransactions from '@/components/Merchant/Portal/AllTransactions.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AllTransactions',
  components: {
    MerchantPortalAllTransactions,
  },
});
</script>
