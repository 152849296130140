<template>
  <div>
    <div v-if="showAlert" class="d-flex justify-center">
      <custom-alert
        type="success"
        class="mob-alert"
        :timed="true"
        @dismissed="closeAlert">
        <div>
          Thanks for submitting your bank account information!
        </div>
      </custom-alert>
    </div>

    <div
      v-if="!settingsSubmitted"
      class="fs-unmask standard-wizard__step standard-wizard__step--five"
      data-test="MOB-step4">
      <payment-form :show-cancel-button="false" @passTokenSuccess="showPaymentSettingsInfo" />

      <custom-button
        v-if="paymentConfirmed"
        data-test="continueBtn"
        :disabled="!paymentConfirmed"
        @click="storeCard">
        Continue
      </custom-button>
    </div>

    <div v-if="settingsSubmitted && !showAlert" class="v-elevation-6 mx-4 my-4 received-application">
      <h5 class="mt-0 text-h6 received-application__title">
        Your Payment Settings
      </h5>

      <p class="paragraphs">
        Many banks require additional authorization
        for ACH Credits and Debits (Refunds).
      </p>

      <p class="paragraphs">
        To avoid delays in funding, contact your bank
        and authorize the following Company IDs:
      </p>

      <ul class="company-list">
        <li>Company ID: MOMNT</li>
        <li>Company ID: PRIMIS</li>
        <li>Company ID: SEACOASTBK</li>
        <li>Company ID: ARTISTECH</li>
        <li>Company ID: DRBANK</li>
      </ul>

      <p class="paragraphs">
        We have emailed this information to you.
      </p>

      <div class="actions">
        <custom-button
          full-width
          class="mt-4"
          :disabled="buttonProcessing"
          @click="storeCard">
          I Acknowledge This Message
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import PaymentForm from '@/components/PaymentProviders/Form.vue';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import { patchAvailableConsents, postConsentTypes } from '@/utils/Consents';
import { get } from 'lodash';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import GetSponsorMixin from '@/mixins/GetSponsor';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StepFour',
  components: {
    CustomButton,
    PaymentForm,
    CustomAlert,
  },
  mixins: [GetSponsorMixin, NavigatesStepsMixin],
  data() {
    return {
      paymentConfirmed: false,
      paymentData: {
        data: '',
        origin: '',
        lastEventId: '',
      },
      ownStep: 5,
      showAlert: false,
      settingsSubmitted: false,
      buttonProcessing: false,
    };
  },
  computed: {
    merchantUid(): string {
      return this.$store.getters['MerchantOnboarding/getMerchantUid'];
    },
    hasCardPassed(): boolean {
      return this.$store.getters['Repay/getHasCardPassed'];
    },
  },
  async created() {
    await this.$store.dispatch('MerchantPortal/getPaymentProviders');
    await this.presentConsent();
  },
  methods: {
    async nextStep(): Promise<void> {
      const nextStepIncrement = this.hasMPAccess ? 2 : 1;
      this.goToStep(this.ownStep + nextStepIncrement);
    },
    async storeCard(): Promise<void> {
      this.buttonProcessing = true;
      if (this.hasCardPassed) {
        await this.nextStep();
      }
      this.buttonProcessing = false;
    },
    async showPaymentSettingsInfo() {
      await this.updateConsents();
      this.showAlert = true;
      this.settingsSubmitted = true;
    },
    closeAlert() {
      this.showAlert = false;
    },
    async presentConsent(): Promise<void> {
      const consentTypes: Array<number> = [
        ConsentTypesEnum.REPAY,
        ConsentTypesEnum.MOOV_MERCHANT,
      ];

      const merchant_application_uuid = get(
        this.$store.getters['MerchantOnboarding/getUserVerifyData'],
        'merchantApplicationId',
        '',
      );

      await postConsentTypes({
        consentTypes,
        merchant_application_uuid,
        entity: ConsentEntityTypes.MERCHANT,
      });
    },
    async updateConsents(): Promise<number> {
      const consentTypes: Array<number> = [ConsentTypesEnum.REPAY, ConsentTypesEnum.MOOV_MERCHANT];

      const appId = get(
        this.$store.getters['MerchantOnboarding/getUserVerifyData'],
        'merchantApplicationId',
        '',
      );
      return patchAvailableConsents(consentTypes, appId, ConsentEntityTypes.MERCHANT);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/vuetify/elevation.scss";

.mob-alert {
  margin-top: 2rem;
  width: 100%;
}

.info-box {
  margin-bottom: 2rem;
  padding: 1rem;
  text-align: left;
  border: 1px solid $momnt-navy;
  border-radius: 8px;
  box-shadow: $v-elevation-6 !important;

  p {
    font-size: 1rem;
    color: $momnt-navy;
    margin-bottom: 0;
  }

  p:first-of-type {
    margin-bottom: 1rem;
  }
}

.received-application {
  border: 1px solid var(--grayscale-color-3);
  border-radius: 8px;
  text-align: left;
  background-color: #fff;

  &__title {
    padding: 1rem;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    font-weight: 900;
    font-size: 1.125rem !important;
    color: var(--secondary-color);
    background-color: var(--grayscale-color-5);
    margin-top: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .company-list {
    list-style: disc;
    margin-left: 1rem;
    font-size: 1rem;
    padding: 1rem;
    line-height: 1.5rem;
  }

  .paragraphs {
    margin: 0;
    font-size: 1rem;
    padding: 1rem;
  }

  .actions {
    padding: 0 1rem 1rem 1rem;
  }
}

.standard-wizard__step {
  background-color: #fff;
}
</style>
