import AXIOS_CONFIG from '@/constants/AxiosConfigConstants';
import axios, { AxiosRequestConfig } from 'axios';
import { userLogout } from '@/api/index';
import { hasTokenExpired } from '@/utils/Token';
import Logger from '@/logging';

const instance = axios.create(AXIOS_CONFIG());

instance.interceptors.request.use(async (config): Promise<AxiosRequestConfig> => {
  const token = localStorage.getItem('token');

  if (token === null) {
    return Promise.reject();
  }

  if (token && hasTokenExpired()) {
    await userLogout();
    return Promise.reject();
  }

  const { headers } = AXIOS_CONFIG();

  // eslint-disable-next-line no-param-reassign
  config.headers = {
    ...config.headers,
    ...headers,
    Authorization: `JWT ${token}`,
  };

  return config;
});

instance.interceptors.response.use(response => response, async error => {
  if ([401, 403].includes(error.response?.status)) {
    await userLogout();
  }

  Logger.error(error, {
    status: error.response?.status,
    url: error.config.url,
    response: error.response?.data,
  });

  return Promise.reject(error);
});

export default instance;
