enum CreditEngineDecisions {
  APPROVED = 'APPROVED',
  APPROVED_FOR_LESS = 'APPROVED_FOR_LESS',
  DECLINED = 'DECLINED',
  DECLINED_SSN_MISMATCH = 'DECLINED TU ssn mismatch',
  HARD_DECLINED = 'HARD_DECLINED',
  NON_DECISION = 'NOT_DECISIONABLE',
}

export default CreditEngineDecisions;
