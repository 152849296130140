import { DisplayStatus } from '@/interfaces/ConsumerActivity';
import { reactive, ref } from 'vue';

const loading = ref(false);
const search = ref('');
const filters = reactive({
  display_status: [] as DisplayStatus[],
});

// eslint-disable-next-line import/prefer-default-export
export const useConsumerActivity = () => {
  return { loading, search, filters };
};
