import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35f38772"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "offer-code-table--title--actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_switch, {
      modelValue: _ctx.internalValue,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
        _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggle:active')))
      ],
      inset: "",
      "hide-details": "",
      ripple: false
    }, null, 8, ["modelValue"]),
    _createVNode(_component_v_menu, {
      "content-class": "offer-code-table--actions",
      location: "left",
      offset: "-50 0"
    }, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_v_btn, _mergeProps({
          icon: "mdi-dots-vertical",
          variant: "plain",
          "data-test": "openEditMenu"
        }, props), null, 16)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_custom_button, {
                  "data-test": "addRemoveProducts",
                  variant: "text",
                  size: "small",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click:editProducts')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Add or Remove Products ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_custom_button, {
                  "data-test": "editOfferCodeName",
                  variant: "text",
                  size: "small",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('click:editName')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Edit Offer Code Name ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_custom_button, {
                  "data-test": "deleteOfferCode",
                  variant: "text",
                  size: "small",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('click:delete')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Delete This Offer Code ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}