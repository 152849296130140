import UsersRolesEnum from '@/enums/UsersRolesEnum';
import { GroupInterface } from '@/interfaces/auth/GroupInterface';
import { MenuItemInterface } from '@/interfaces/menu/MenuItemInterface';

const GetMenuItemAccess = (item: MenuItemInterface): boolean => {
  const { access } = item;

  const localGroup = localStorage.getItem('group');
  const localGroupData: GroupInterface[] = localGroup ? JSON.parse(localGroup) : {};

  if (!localGroupData.length) {
    return false;
  }

  if (!item.to) return false;

  const isSalesManager = localGroupData[0]?.name === UsersRolesEnum.SALES_MANAGER;
  const hasAccess = (!access)
    || (isSalesManager && item.to === 'merchant-admin')
    || localGroupData[0]?.name === access
    || localGroupData[0]?.name === UsersRolesEnum.CORPORATE_ADMIN;

  return hasAccess;
};

export default GetMenuItemAccess;
