import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03e0cfa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "simple-card" }
const _hoisted_2 = {
  key: 1,
  class: "simple-card__header"
}
const _hoisted_3 = {
  key: 3,
  class: "simple-card__body-text"
}
const _hoisted_4 = {
  key: 4,
  class: "simple-card__actions"
}
const _hoisted_5 = {
  key: 5,
  class: "align-self-end simple-card__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showHeaderSlot)
      ? _renderSlot(_ctx.$slots, "header", { key: 0 }, undefined, true)
      : (_ctx.header)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.header), 1))
        : _createCommentVNode("", true),
    (_ctx.showBodySlot)
      ? _renderSlot(_ctx.$slots, "body", { key: 2 }, undefined, true)
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.bodyText), 1)),
    (_ctx.showActionsSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.footer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.footer), 1))
      : _createCommentVNode("", true),
    (_ctx.showFooterSlot)
      ? _renderSlot(_ctx.$slots, "footer", { key: 6 }, undefined, true)
      : _createCommentVNode("", true)
  ]))
}