import { RepayStateInterface } from '@/interfaces/states/RepayStateInterface';
import { ActionContext } from 'vuex';

// eslint-disable-next-line import/prefer-default-export
export async function setCardPassedState(
  context: ActionContext<RepayStateInterface, any>,
  hasPassed: boolean,
) {
  context.commit('setCardPassedState', hasPassed);
}
