<template>
  <div>
    <div class="additional-info">
      <p>
        All products have a 5 month purchase window starting from approval. You must have at least
        two products active to send loan invitations.
      </p>
    </div>

    <div v-if="lessThanTwoProducts" class="alert-container">
      <custom-alert type="warning">
        <span>
          At least two loan products must be active on your Rate Sheet before you can send an application.
        </span>
      </custom-alert>
    </div>

    <simple-card
      class="elevation-4 fs-unmask standard-wizard__step standard-wizard__step--rate-sheets"
      data-test="MOB-step5">
      <template #body>
        <div class="full-width px-0 simple-card__body-text">
          <rate-sheet-options-data-table
            ref="rateSheetDataTable"
            :rate-sheet-options="rateSheetsBase"
            is-from-offer-codes
            switch-toggle
            simple-header
            :show-legend="true"
            merchant-onboarding
            header-title="Your Rate Sheet" />
        </div>
      </template>

      <template #actions>
        <div class="button-container">
          <custom-button
            full-width
            class="ma-0"
            :disabled="lessThanTwoProducts"
            data-test="saveChangesBtn"
            @click="saveChanges">
            Save Changes To Rate Sheet
          </custom-button>
        </div>
      </template>
    </simple-card>

    <info-dialog
      :dialog="showModal"
      :on-cta="closeModal"
      :on-cancel="closeModal" />
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import SimpleCard from '@/components/SimpleCard.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { RateSheetsPostDataInterface } from '@/interfaces/rates/RateSheetsPostDataInterface';
import GetRateSheetPostData from '@/utils/GetRateSheetPostData';
import GetSponsorMixin from '@/mixins/GetSponsor';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import RateSheetOptionsDataTable from '@/components/RateSheetOptions/DataTable/index.vue';
import InfoDialog from '@/components/RateSheetOptions/InfoDialog.vue';
import { forEach } from 'lodash';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StepFive',

  components: {
    CustomAlert,
    CustomButton,
    SimpleCard,
    RateSheetOptionsDataTable,
    InfoDialog,
  },

  mixins: [GetSponsorMixin],

  data: () => ({
    selectedRates: [] as Array<RateSheetData>,
    showModal: true,
  }),

  computed: {
    rateSheets(): Array<RateSheetData> {
      return this.$store.getters['MerchantOnboarding/getRateSheets'];
    },

    productsSelected(): number {
      return this.selectedRates.filter(it => it?.is_selected).length;
    },

    lessThanTwoProducts(): boolean {
      return this.productsSelected < 2;
    },

    redirectUrlState(): any {
      return this.$store.getters['MerchantOnboarding/getRedirectUrl'];
    },

    portalLoginUrl(): string {
      return this.$store.getters['Ui/getPortalLoginUrl'];
    },

    rateSheetsBase(): Array<RateSheetData> {
      const rateSheetBaseArr: Array<RateSheetData> = [];
      this.rateSheets.forEach(el => {
        el.is_selected = true;
        if (el.is_recommended === true) {
          rateSheetBaseArr.push(el);
        }
        return rateSheetBaseArr;
      });
      return rateSheetBaseArr;
    },
  },

  async created() {
    const status = await this.$store.dispatch('MerchantOnboarding/dispatchFetchRateSheet');

    if (status === 200) {
      if (!this.rateSheets.some(rs => rs.is_recommended)) {
        if (this.portalLoginUrl) {
          window.location.href = this.portalLoginUrl;
        } else {
          this.$router.push({ name: 'merchant-login' });
        }
      }
    }

    this.selectedRates = [...this.rateSheets];

    forEach(
      this.selectedRates,
      (rateSheet: RateSheetData, index: number) => {
        this.selectedRates[index].is_selected = rateSheet.is_recommended;
      },
    );
  },

  methods: {
    closeModal(): void {
      this.showModal = false;
    },
    async saveChanges() {
      const selectedRates: RateSheetsPostDataInterface = GetRateSheetPostData(
        this.selectedRates,
      );

      const responseStatus = await this.$store.dispatch(
        'MerchantOnboarding/dispatchSelectRates',
        selectedRates,
      );

      await this.$store.dispatch(
        'MerchantOnboarding/dispatchPatchApplication',
        'Post Rate Sheet/ Pre-Login',
      );

      if (responseStatus === 201) {
        if (this.redirectUrlState === '' || this.isTypeIC1WithMPAccess) {
          this.$router.push({ name: 'merchant-login' });
        } else {
          window.location = this.redirectUrlState;
        }
      } else {
        this.$router.push({ name: 'merchant-login' });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/vuetify/elevation.scss";

:deep(.simple-card__actions) {
  padding: 0;
}

.alert-container {
  margin: 0 auto;
  max-width: 75rem;
}

.additional-info {
  margin: 1.5rem auto;
  padding: 1rem;
  box-shadow: $v-elevation-4 !important;
  width: 26.25rem;
  background-color: white;
  border-radius: 8px;

  p {
    margin: 0;
    font-size: 1rem;
  }
}

.standard-wizard {
  position: relative;

  &__step {
    margin: 0 auto 1rem auto;
    max-width: 75rem;
    background-color: #fff;
    border-radius: 8px;

    &--rate-sheets {
      @include box-border-default;

      :deep(.rate-sheets-options__data-table) {
        border: none;
        border-radius: 8px;

        .rate-sheet-simple-header {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }

      .button-container {
        padding: 1.125rem 1rem;
        background: var(--grayscale-color-5);
        width: 100%;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .v-btn {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
</style>
