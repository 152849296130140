import { computed } from 'vue';
import { useStore } from 'vuex';
import UsersRolesEnum from '@/enums/UsersRolesEnum';
import { GroupInterface } from '@/interfaces/auth/GroupInterface';
import { highestRole } from '@/utils/MerchantRoleUtils';

export const useMerchantPermissions = () => {
  const store = useStore();

  const userGroup = computed((): GroupInterface[] => store.getters['Auth/getGroup']);

  const findRole = (roles: UsersRolesEnum[]) => {
    return !!userGroup.value.find(it => roles.includes(it.name));
  };

  const isCorporateAdmin = computed(() => findRole([UsersRolesEnum.CORPORATE_ADMIN]));
  const isAdmin = computed(() => findRole([UsersRolesEnum.ADMIN]));
  const isAdminOrAbove = computed(() => findRole([
    UsersRolesEnum.ADMIN,
    UsersRolesEnum.CORPORATE_ADMIN,
  ]));
  const isSalesManager = computed(() => findRole([UsersRolesEnum.SALES_MANAGER]));
  const isSalesRep = computed(() => findRole([UsersRolesEnum.SALES_REP]));

  const userRoleLabel = computed(() => {
    if (isCorporateAdmin.value) return 'Corporate Admin';
    if (isAdmin.value) return 'Admin';
    if (isSalesManager.value) return 'Manager';
    if (isSalesRep.value) return 'Representative';
    return 'Salesperson';
  });

  const userRoles = computed(() => userGroup.value.map(it => it.name));
  const userHighestRole = computed(() => highestRole(userRoles.value));

  return {
    userGroup,
    isCorporateAdmin,
    isAdmin,
    isAdminOrAbove,
    isSalesManager,
    isSalesRep,
    userRoleLabel,
    userRoles,
    userHighestRole,
  };
};
