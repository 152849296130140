<template>
  <div :class="classList">
    <div class="standard-widget__container" @click="closeIframe" />
    <component
      :is="selectedWidget"
      widget
      class="standard-widget__component" />
  </div>
</template>

<script lang="ts">
import AVAILABLE_WIDGETS from '@/constants/WidgetsConstants';
import AppDisplayTypes from '@/enums/AppDisplayTypes';
import { PostMessageInterface } from '@/interfaces/widgets/PostMessageInterface';
import PostMessageMixin from '@/mixins/Widgets/PostMessageMixin';
import uuid from '@/validators/uuid';
import ConsumerLoanApplicationWizard from '@/components/Consumer/LoanApplicationWizard/index.vue';
import ConsumerLoanApplyWizard from '@/components/Consumer/LoanApplyWizard/index.vue';
import MerchantOnboardingWizard from '@/components/Merchant/OnboardingWizard/index.vue';
import MerchantBeginOnboardingWizard from '@/components/Merchant/BeginOnboardingWizard/Wizard.vue';
import WidgetsUnableToProcessMsg from '@/components/Widgets/WidgetsUnableToProcessMsg.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WigetsView',
  components: {
    ConsumerLoanApplicationWizard,
    ConsumerLoanApplyWizard,
    MerchantOnboardingWizard,
    MerchantBeginOnboardingWizard,
    WidgetsUnableToProcessMsg,
  },
  mixins: [PostMessageMixin],
  data() {
    return {
      selectedWidget: '' as any,
      merchantAllowed: false,
      widgetLayout: null as any,
    };
  },
  computed: {
    classList(): string {
      const baseClass = 'standard-widget';
      const classList: Array<string> = [baseClass];

      if (this.widgetLayout) {
        classList.push(`${baseClass}--${this.widgetLayout}`);
      }

      return classList.join(' ');
    },
    widgetLayoutFullscreen(): boolean {
      return this.widgetLayout === 'fullscreen';
    },
  },
  async created(): Promise<void> {
    document.documentElement.classList.add(AppDisplayTypes.WIDGET);

    const {
      widget,
      sponsorId,
      merchantId,
      programId,
      layout,
    } = this.$route.query;

    this.widgetLayout = layout || 'fullscreen';

    if (uuid(`${programId}`)) {
      this.$store.commit('MerchantBeginOnboarding/setProgramUid', programId);
    }

    let responseStatus: number = 0;

    if (uuid(`${sponsorId}`)) {
      const response = await this.$store.dispatch('Sponsor/fetchSponsorData', sponsorId);
      responseStatus = response.status;
    } else if (uuid(`${merchantId}`)) {
      responseStatus = await this.$store.dispatch(
        'ConsumerLoanApplication/validateMerchant',
        merchantId,
      );
    }

    this.merchantAllowed = responseStatus === 200;

    this.selectedWidget = this.merchantAllowed && AVAILABLE_WIDGETS.includes(widget as string)
      ? widget
      : 'WidgetsUnableToProcessMsg';
  },
  methods: {
    closeIframe(): void {
      if (this.widgetLayoutFullscreen) {
        const eventData: PostMessageInterface = {
          closeIframe: true,
        };

        this.postMessage(eventData);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_transition-variables";

.standard-widget {
  &--standalone {
    .standard-widget__component {
      margin: auto;
    }
  }

  &--fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1039;
    transition: $standard-transition;
    overflow-y: scroll;

    .standard-widget__container {
      opacity: .5;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1040;
      width: 100vw;
      height: 100vh;
      background-color: #000;
    }

    .standard-wizard {
      max-width: 28rem;
      z-index: 1041;
    }
  }
}
</style>
