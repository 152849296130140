import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_standard_dialog = _resolveComponent("standard-dialog")!

  return (_openBlock(), _createBlock(_component_standard_dialog, {
    dialog: "",
    width: "420",
    "on-cancel": () => _ctx.$emit('close'),
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    body: _withCtx(() => [
      _createElementVNode("p", null, [
        _createTextVNode(" An email invite has been sent to "),
        _createElementVNode("strong", null, _toDisplayString(_ctx.email), 1),
        _createTextVNode(". Once they set up a password and login, they will be able to work in your portal. ")
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_custom_button, {
        "full-width": "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('ok')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" OK ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["on-cancel"]))
}