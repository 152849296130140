<template>
  <select-input
    v-show="offerCodes.length"
    v-bind="$attrs"
    blur-on-clear
    class="offer-code-select"
    clearable
    clear-icon="mdi-close"
    :disabled="disabled"
    hide-dropdown-icon
    item-title="name"
    item-value="id"
    item-color="var(--primary-color)"
    :items="offerCodes"
    label="Select Offer Code"
    return-object
    :menu-props="menuProps" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getOfferCodes } from '@/api/ratesheets';
import SelectInput from '@/components/Inputs/Select.vue';

export default defineComponent({
  name: 'OfferCodeSelect',

  components: {
    SelectInput,
  },

  props: {
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      offerCodes: [],
      menuMaxWidth: '',
    };
  },

  computed: {
    menuProps() {
      return {
        bottom: true,
        offsetY: true,
        maxWidth: this.menuMaxWidth,
        contentClass: 'offer-code-select--menu',
      };
    },
  },

  async mounted() {
    const merchantUuid = this.$store.getters['Auth/getMerchantUuid'];
    const response = await getOfferCodes(merchantUuid, true);
    this.offerCodes = response.data;

    // We want the dropdown menu to have the same width as the select field.
    // If an item in the menu has a lot of characters we want it to wrap instead of
    // expanding the entire menu.
    // This might be something that we will implement on the SelectInput.vue for all
    // instances to have, but for now it's implemented just for this one.
    this.$nextTick(() => {
      const selectEl = (this.$refs.select as any);
      if (!selectEl) return;

      const selectElWidth = (selectEl.$el as HTMLDivElement).offsetWidth;
      this.menuMaxWidth = `${selectElWidth}px`;
    });
  },
});
</script>

<style lang="scss" scoped>
.offer-code-select :deep() {
  margin-top: 1rem;
}

.offer-code-select:not(.v-input--disabled) :deep() {
  color: var(--primary-color);

  &.v-input--is-focused.primary--text {
    color: var(--primary-color)!important;
    caret-color: var(--primary-color)!important;
  }

  .v-input__control {
    .v-field {
      color: var(--primary-color)!important;
    }
  }

  .v-label,
  .v-label.v-label--active {
    color: var(--primary-color)!important;
  }

  .v-select__selection {
    color: var(--primary-color)!important;
  }

  .v-icon.v-icon.mdi-close {
    color: var(--primary-color)!important;
  }
}

:deep(input) {
  display: none;
}
</style>
