import { MerchantBeginOnboardingStateInterface } from '@/interfaces/states/MerchantBeginOnboardingStateInterface';
import ResetModuleState from '@/utils/store/ResetModuleState';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const initialState = (): MerchantBeginOnboardingStateInterface => ({
  showBeginOnboardingWizard: false,
  userDetails: {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    mobile: '',
  },
  merchantDetails: {
    name: '',
    merchant_phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    address_verification_response: {
      analysis: {},
      components: {},
      metadata: {},
    },
  },
  programUid: '',
  merchantApplicationId: '',
  merchantId: 0,
  userId: '',
});
export default {
  namespaced: true,
  state: initialState() as MerchantBeginOnboardingStateInterface,
  getters,
  mutations: {
    resetModuleState(state: MerchantBeginOnboardingStateInterface) {
      ResetModuleState(state, initialState());
    },
    ...mutations,
  },
  actions,
};
