import { ActionContext } from 'vuex';
import { SponsorStateInterface } from '@/interfaces/states/SponsorStateInterface';
import { getSponsorData } from '@/api/sponsor';
import { StoreActionInterface } from '@/interfaces/StoreActionResponse';
import { getAxiosError } from '@/api/utils';

// eslint-disable-next-line import/prefer-default-export
export async function fetchSponsorData(
  context: ActionContext<SponsorStateInterface, any>,
  sponsorId: string,
): Promise<StoreActionInterface> {
  // TODO: Refactor this part to use/set data from merchant details instead of
  // relying on sponsor standing as we have the same data in merchant details
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('setSponsorId', sponsorId);
  context.commit('setSponsorData', null);

  try {
    const { data, status } = await getSponsorData(sponsorId);
    context.commit('setSponsorData', data);
    return { data, status };
  } catch (error: unknown) {
    const axiosError = getAxiosError(error);
    if (axiosError) {
      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
    return { error };
  }
}
