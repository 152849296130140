import { OtpStateInterface } from '@/interfaces/states/OtpStateInterface';

export function getOtpEnabled(state: OtpStateInterface) {
  return state.otpEnabled;
}

export function getPhoneResendEnabled(state: OtpStateInterface) {
  return state.phoneResendEnabled;
}

export function getOtpRegisterSessionToken(state: OtpStateInterface) {
  return state.otpRegisterSessionToken;
}
