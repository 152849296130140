<template>
  <div>
    <h5 class="text-center">
      Sorry
    </h5>
    <p>
      This link has expired. Please contact your merchant for a new invitation if you or someone
      in your household would like to submit a new application.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConsumerExpiredLinkMsg',
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/base-text-mixins";

div{
  min-height: 31rem;
}

h5 {
  @include headers-default;
}

p {
  font-size: 1rem;
}
</style>
