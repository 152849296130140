import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _directive_mask = _resolveDirective("mask")!
  const _directive_fs_exclude = _resolveDirective("fs-exclude")!

  return _withDirectives((_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ ref: "input" }, _ctx.$attrs, {
    autocomplete: "social-security-number",
    class: "ssn-input",
    "append-inner-icon": _ctx.appendIcon,
    "data-test": _ctx.dataTestAttr,
    disabled: _ctx.disabled,
    label: _ctx.labelAttr,
    variant: _ctx.variantAttr,
    rules: [_ctx.isValidSsn],
    type: "text",
    "validate-on": "blur",
    onContextmenu: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["right","prevent"])),
    onCopy: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
    "onClick:appendInner": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSsn = !_ctx.showSsn)),
    onFocus: _ctx.onInputFocus
  }), null, 16, ["append-inner-icon", "data-test", "disabled", "label", "variant", "rules", "onFocus"])), [
    [_directive_mask, _ctx.inputMask],
    [_directive_fs_exclude]
  ])
}