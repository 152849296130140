import axios, { AxiosRequestConfig } from 'axios';
import { clearAuthLocalStorage } from '@/store/auth/utils';
import { hasTokenExpired } from '@/utils/Token';
import GetCookieFromLocalStorage from '@/utils/GetCookieFromLocalStorage';
import Logger from '@/logging';
import LocalStorageConstants from '@/constants/LocalStorageConstants';

const BASE_PATH = '/api/v1';

let logoutInProgress = false;

const AXIOS_CONFIG = (Authorization = '') => ({
  headers: {
    'X-CSRFToken': GetCookieFromLocalStorage('csrftoken'),
    'Content-Type': 'application/json',
    Authorization,
  },
});

async function userLogout(doPageReload = true) {
  if (logoutInProgress) return Promise.reject();

  const token = localStorage.getItem('token');
  if (!token) return Promise.reject();

  logoutInProgress = true;
  const auth = `JWT ${token}`;
  await axios.post(`${BASE_PATH}/account/user/logout/`, null, AXIOS_CONFIG(auth)).catch(error => {
    // We are ignoring the error here in case the JWT that is already active is invalid.
    if (error.response?.status === 401) {
      Promise.resolve();
    } else {
      throw error;
    }
  });
  await clearAuthLocalStorage();
  localStorage.removeItem(LocalStorageConstants.SHOW_ADMIN_VERIFY_MODAL);
  logoutInProgress = false;
  if (doPageReload) window.location.reload();
  return Promise.resolve();
}

const instance = axios.create(AXIOS_CONFIG());
const rawClient = axios.create(AXIOS_CONFIG());

instance.interceptors.request.use(async (config): Promise<AxiosRequestConfig> => {
  const token = localStorage.getItem('token');

  if (token === null) {
    return Promise.reject();
  }

  config.headers.Authorization = `JWT ${token}`;

  if (token && hasTokenExpired()) {
    await userLogout();
    return Promise.reject();
  }

  const { headers } = AXIOS_CONFIG();

  // eslint-disable-next-line no-param-reassign
  config.headers = {
    ...config.headers,
    ...headers,
    Authorization: `JWT ${token}`,
  };

  return config;
});

instance.interceptors.response.use(response => response, async error => {
  if ([401, 403].includes(error.response?.status)) {
    await userLogout();
  }

  Logger.error(error, {
    status: error.response?.status,
    url: error.config.url,
    response: error.response?.data,
  });

  return Promise.reject(error);
});

function requestErrorHandler(error: any) {
  const { data, status } = error.response || {};
  return { error: data, status };
}

export {
  AXIOS_CONFIG,
  BASE_PATH,
  instance,
  requestErrorHandler,
  rawClient,
  userLogout,
};
