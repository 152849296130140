<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--three" data-test="BMOB-step3">
    <h5 class="card-title mt-0" data-test="nowCheckEmailHeader">
      Now Check Your Email!
    </h5>
    <i
      aria-hidden="true"
      class="mail-icon mdi mdi-email-outline"
      data-test="mailIcon" />
    <p class="mb-6 mt-8" data-test="emailedLinkLabel">
      We’ve emailed you a link to continue your application and it expires in 7 days.
    </p>
    <p data-test="emailSentLabel">
      The email was sent from <strong>{{ footerData.support_email }}</strong>.
      Please add this email to your contacts to receive our communications.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StepThree',

  computed: {
    footerData(): any {
      return this.$store.getters['Ui/getFooterData'] || {};
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/merchant-begin-onboarding-wizard";

.standard-wizard {
  &__step {
    &--three {
      .card-title {
        padding: 1rem;
        padding-left: 0;
        margin-bottom: 0;
        font-size: 1.25rem;
      }

      p {
        font-size: 1rem;
        font-weight: 500;
      }

      .mail-icon {
        color: var(--secondary-color);
      }

      @include mdi-icon;
    }
  }
}
</style>
