<template>
  <standard-wizard-message
    :message="errorMessage"
    hide-wrapper
    data-test="fraudMsg" />
</template>

<script lang="ts">
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FraudDecline',
  components: {
    StandardWizardMessage,
  },
  computed: {
    errorMessage() {
      return 'Momnt uses a third party that uses non-documentary sources to verify your identity.'
        + ' The third party was unable to verify your identity with sufficient confidence using these'
        + ' public and private databases. Consequently, we are unable to proceed with your application for an account.';
    },
  },
});
</script>

<style lang="scss" scoped>

:deep(.v-card .v-card-text) {
  color: var(--grayscale-color-1);
  font-size: 1rem;
}
</style>
