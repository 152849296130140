import { SponsorStateInterface } from '@/interfaces/states/SponsorStateInterface';
import ResetModuleState from '@/utils/store/ResetModuleState';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const initialState = (): SponsorStateInterface => ({
  sponsorId: '',
  sponsorData: null,
});

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations: {
    ...mutations,
    resetModuleState(state: any) {
      ResetModuleState(state, initialState());
    },
  },
  actions,
};
