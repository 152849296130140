<template>
  <transition
    :css="false"
    @before-enter="onBeforeEnter"
    @enter="onEnter"
    @leave="onLeave">
    <div v-if="isSelected" class="tab-navigation-item">
      <slot />
    </div>
  </transition>
</template>

<script lang="ts">
import {
  defineComponent, computed, inject, Ref,
} from 'vue';

export default defineComponent({
  props: {
    tab: { type: String, required: true },
  },

  setup(props) {
    const selectedTab = inject('selectedTab') as Ref<string>;
    const isSelected = computed(() => selectedTab.value === props.tab);

    function onBeforeEnter(el: HTMLElement) {
      el.style.height = '0';
      el.style.opacity = '0';
    }

    function onEnter(el: HTMLElement, done: () => void) {
      setTimeout(() => {
        el.style.height = '100%';
        el.style.opacity = '1';
        done();
      }, 300);
    }

    function onLeave(el: HTMLElement, done: () => void) {
      el.style.opacity = '0';
      setTimeout(done, 300);
    }

    return {
      selectedTab, isSelected, onBeforeEnter, onEnter, onLeave,
    };
  },
});
</script>

<style lang="scss" scoped>
.tab-navigation-item {
  background-color: white;
  transition: opacity 300ms;
  height: 100%;
  border-radius: 0.5rem;
}
</style>
