<template>
  <v-alert variant="outlined" class="lightbulb">
    <div class="lightbulb__image">
      <img :src="require('@/assets/images/logos/lightbulb.svg')" alt="Lightbulb">
    </div>
    <div class="lightbulb__text">
      <slot name="content" />
    </div>
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LightbulbAlert',
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/media_queries";

.lightbulb {
  padding: 0;
  color: var(--secondary-color) !important;
  border: 1.8px solid var(--secondary-color) !important;
  margin-bottom: 1rem;

  &__image {
    background-color: var(--grayscale-color-5);
    padding: 0.5rem;
  }

  &__text {
    padding: 1rem;
    display: flex;
    align-items: center;
  }
}

.lightbulb :deep() .lightbulb__text > * {
  color: var(--secondary-color);
  font-size: 1rem;

  @include mobile {
    font-size: 0.875rem;
  }
}

.lightbulb :deep(.v-alert__content) {
  display: flex;
}
</style>
