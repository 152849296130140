// https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/?tab=maskuserinput#privacy-options

export default {
  created(el: HTMLElement, binding: any) {
    // masks content for session replays
    el.dataset.ddPrivacy = 'mask';

    // for masking buttons with a custom action name
    if (binding.value) {
      el.dataset.ddActionName = binding.value;
    }
  },
};
