<template>
  <v-expansion-panel class="gray-border section-card standard-collapsable-panel">
    <v-expansion-panel-title class="standard-collapsable-panel__title">
      <h3 class="subheader">
        Payment Settings
      </h3>
    </v-expansion-panel-title>

    <v-expansion-panel-text class="standard-collapsable-panel__content">
      <v-card
        elevation="4"
        width="28.825rem"
        class="main-card">
        <div v-if="updatePaymentSettingsForm || noPaymentSettings">
          <div class="split-payment-settings" data-test="paymentSettingsLabel">
            <div class="mb-5">
              Enter the <span class="bank-acc">bank account information</span>
              where you want to transact.
            </div>
            <div class="mb-5">
              We initiate connections with two payment networks because this is a
              <span class="lender-link" @click="multiLenderModal">multi-lender platform</span>.
            </div>
            <div>
              Complete the connection by following steps after the form below.
            </div>
          </div>

          <payment-form-fields
            :block-cols="0"
            :show-cancel-button="updatePaymentSettingsForm"
            :payment-network="true"
            :show-loader="true"
            :stop-loader="stopSpinner"
            :last-four="lastFour"
            :confirm-account-update="confirmAccountUpdate"
            skip-check-same-account
            @cancelForm="updatePaymentSettingsForm = false"
            @payTokenSuccess="bankAccountReplaced" />
        </div>
        <div v-else>
          <v-card-title>
            Account Ending in: {{ lastFour }}
          </v-card-title>
          <v-card-text :class="showTabError ? 'add-bottom-radius' : ''">
            <v-tabs v-model="currentTab" hide-slider>
              <v-tab
                key="primary"
                class="network-button-tab px-0"
                :selected-class="moovStatusConnected ? 'active-class' : 'error-class'">
                <div class="network-buttons">
                  <div
                    class="network-button-tab"
                    :class="!moovStatusConnected ? 'error-color' : ''">
                    Primary network
                  </div>
                  <div>
                    <v-icon v-if="moovStatusConnected" color="var(--success-color)">
                      mdi-check-circle-outline
                    </v-icon>

                    <v-icon v-else color="var(--error-color)">
                      mdi-alert-outline
                    </v-icon>
                  </div>
                </div>
              </v-tab>
              <v-tab
                key="secondary"
                class="network-button-tab px-0"
                :selected-class="repayStatusConnected ? 'active-class' : 'error-class'">
                <div class="network-buttons">
                  <div
                    class="network-button-tab"
                    :class="!repayStatusConnected ? 'error-color' : ''">
                    Secondary network
                  </div>
                  <div>
                    <v-icon v-if="repayStatusConnected" color="var(--success-color)">
                      mdi-check-circle-outline
                    </v-icon>

                    <v-icon v-else color="var(--error-color)">
                      mdi-alert-outline
                    </v-icon>
                  </div>
                </div>
              </v-tab>
            </v-tabs>
            <v-window
              v-model="currentTab"
              class="tab-items"
              :class="showTabError ? 'error-class' : ''">
              <v-window-item key="primary">
                <v-card flat>
                  <div class="payment-networks">
                    <div class="network" :class="!moovStatusConnected ? 'error-color' : ''">
                      Primary Network
                    </div>
                    <div class="status">
                      Status: <span class="text-capitalize"> {{ moovStatus }} </span>
                    </div>
                  </div>

                  <div
                    v-if="moovStatusConnected"
                    class="payment-network-details">
                    <div class="active-loans">
                      Number Of Loans In Purchase Window: {{ moovLoans }}
                    </div>
                    <div>
                      Payment method:
                    </div>
                    <ul class="credit-list">
                      <li class="bullet-type">
                        ACH Credit
                      </li>
                      <li class="bullet-type">
                        ACH Debit
                      </li>
                    </ul>
                  </div>
                  <div v-if="moovStatus === 'disconnected' || moovFormReset">
                    <div class="pt-4 px-4">
                      To connect your bank account, please provide the information below.
                      We will send two deposits to your bank account,
                      and later you will verify the amount of those deposits
                      to complete the connection.
                    </div>

                    <payment-form-fields
                      :block-cols="0"
                      :show-cancel-button="false"
                      :payment-network="true"
                      :moov-payment-network="true"
                      :last-four="lastFour"
                      :confirm-account-update="confirmAccountUpdate"
                      @sameBankAccount="checkBankAccount"
                      @payTokenSuccess="bankAccountReplaced" />
                  </div>

                  <div
                    v-if="moovStatus === 'pending' && !moovFormReset && !canSubmitDeposits"
                    class="pa-4">
                    <div class="mb-2 pending-status">
                      Thanks For Sending Deposits!
                    </div>
                    <div>
                      It normally takes at least 30 minutes (and up to 2 days) for the deposits
                      to arrive in your account.
                      Please come back then and complete the process.
                    </div>
                    <div class="learn-more">
                      <custom-button
                        variant="text"
                        @click="depositsExampleDialog">
                        Learn more
                      </custom-button>
                    </div>
                  </div>

                  <div v-if="moovStatus === 'pending' && !moovFormReset && canSubmitDeposits">
                    <div class="pending-status">
                      <payment-steps
                        :last-four="lastFour"
                        :show-cancel-button="false"
                        :show-deposits-form-split-payment="true"
                        @amountsVerified="onAmountsVerified"
                        @verificationFailed="depositVerFailed" />
                    </div>
                  </div>

                  <div
                    v-if="moovStatus === 'Account Creation Failure' && !moovFormReset"
                    class="pa-4">
                    <div class="mb-8">
                      We’re sorry. It appears your account creation experienced a technical failure.
                      Please click below to restart.
                    </div>
                    <custom-button
                      full-width
                      size="small"
                      @click="restartConnectionProcess">
                      Restart Connection Process
                    </custom-button>
                  </div>

                  <div v-if="moovStatus === 'errored' && !moovFormReset" class="pa-4">
                    <div class="mb-4">
                      It appears you have either entered an incorrect account number,
                      or too many attempts have been made to verify micro-deposits.
                    </div>
                    <div class="merchant-email">
                      Please contact our merchant success team at
                      <span>
                        {{ brandingData.footer_object.support_email }}
                      </span>.
                    </div>
                  </div>

                  <div v-if="moovStatus === 'Verification Failed' && !moovFormReset" class="pa-4">
                    <div class="mb-4">
                      It appears that either your micro-deposits have expired, or you have
                      submitted the wrong amounts too many times.
                    </div>
                    <div class="last-four mb-8">
                      Please click below to send new micro-deposits to the account ending in ...
                      <span>{{ lastFour }}</span>.
                    </div>
                    <custom-button
                      full-width
                      size="small"
                      @click="resendMicroDeposit">
                      Resend Micro-Deposits
                    </custom-button>
                  </div>
                </v-card>
              </v-window-item>

              <v-window-item key="secondary">
                <v-card flat>
                  <div class="payment-networks">
                    <div class="network" :class="!repayStatusConnected ? 'error-color' : ''">
                      Secondary Network
                    </div>
                    <div class="status">
                      Status: <span class="text-capitalize"> {{ repayStatus }} </span>
                    </div>
                  </div>
                  <div
                    v-if="repayStatusConnected"
                    class="payment-network-details">
                    <div class="active-loans">
                      Number Of Loans In Purchase Window: {{ repayLoans }}
                    </div>
                    <div>
                      Payment method:
                    </div>
                    <ul class="credit-list">
                      <li class="bullet-type">
                        ACH Credit
                      </li>
                      <li class="bullet-type">
                        ACH Debit
                      </li>
                    </ul>
                  </div>

                  <div
                    v-if="!repayStatusConnected"
                    class="payment-network-details">
                    <div>
                      <div class="mb-5">
                        Please re-enter your bank account information.
                      </div>
                    </div>

                    <payment-form-fields
                      class="repay-payment-form"
                      :block-cols="0"
                      :show-cancel-button="false"
                      :payment-network="true"
                      :repay-payment-network="true"
                      :last-four="lastFour"
                      :confirm-account-update="confirmAccountUpdate"
                      @sameBankAccount="checkBankAccount"
                      @payTokenSuccess="bankAccountReplaced" />
                  </div>
                </v-card>
              </v-window-item>
            </v-window>
          </v-card-text>
        </div>

        <custom-button
          v-if="(activeTab === 'primary' && moovStatusConnected && !updatePaymentSettingsForm)
            || (activeTab === 'secondary' && repayStatusConnected && !updatePaymentSettingsForm)"
          full-width
          variant="secondary"
          class="main-button"
          @click="showPaymentSettingsDialog">
          Replace Bank Account
        </custom-button>
      </v-card>
    </v-expansion-panel-text>

    <payment-settings-dialog
      :show-dialog="showDialog"
      :deposit-verify-text="depositVerifyText"
      :moov-connected-text="moovConnectedText"
      @cancel="closePaymentSettingsDialog"
      @proceed="updateBankAccount" />

    <verify-replace-bank-account-dialog
      v-if="lastFour"
      :show-dialog="checkAccountDialog"
      :last-four="lastFour"
      @cancel="closeCheckAccountDialog"
      @proceed="proceedWithUpdateAccount" />

    <verify-deposits-example-dialog
      :show-dialog="showVerifyDepositsDialog"
      @cancel="closeShowVerifyDepositsDialog" />

    <multi-lender-dialog
      :show-dialog="showMultiLenderDialog"
      @cancel="closeMultiLenderDialog" />
  </v-expansion-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { RepayICardTokenDataInterface } from '@/interfaces/repay/RepayICardTokenDataInterface';
import PaymentSteps from '@/components/PaymentProviders/PaymentSteps.vue';
import { postConsentTypes, patchAvailableConsents } from '@/utils/Consents';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import PaymentSettingsDialog
  from '@/components/Merchant/Portal/Admin/PaymentSettingsDialog.vue';
import PaymentFormFields from '@/components/PaymentProviders/FormFields.vue';
import VerifyReplaceBankAccountDialog
  from '@/components/Merchant/Portal/Admin/VerifyReplaceBankAccountDialog.vue';
import UsersRolesEnum from '@/enums/UsersRolesEnum';
import VerifyDepositsExampleDialog from '@/components/PaymentProviders/VerifyDepositsExampleDialog.vue';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import reSendMicroDeposit from '@/api/moov';
import MultiLenderDialog
  from '@/components/Merchant/Portal/Admin/MultiLenderDialog.vue';

export default defineComponent({
  name: 'PaymentSettings',
  components: {
    CustomButton,
    PaymentSteps,
    PaymentSettingsDialog,
    PaymentFormFields,
    VerifyReplaceBankAccountDialog,
    VerifyDepositsExampleDialog,
    MultiLenderDialog,
  },
  data() {
    return {
      cardUpdated: false,
      showPaymentForm: false,
      showStatusErrorAlert: false,
      showDialog: false,
      checkAccountDialog: false,
      currentTab: null,
      updatePaymentSettingsForm: false,
      depositVerifyText: false,
      moovConnectedText: false,
      moovFormReset: false,
      confirmAccountUpdate: false,
      showVerifyDepositsDialog: false,
      showMultiLenderDialog: false,
      stopSpinner: false,
      cardTokenData: {
        has_card_token_data: false,
        last_four: '',
        payment_method: '',
        cardId: '',
        merchantId: '',
      } as RepayICardTokenDataInterface,
    };
  },
  computed: {
    hasCardPassed(): boolean {
      return this.$store.getters['Repay/getHasCardPassed'];
    },
    canTransact(): boolean {
      return this.paymentProviders?.moov?.can_transact
          || this.paymentProviders?.repay?.can_transact;
    },
    noPaymentSettings(): boolean {
      return this.paymentProviders?.moov?.status === 'disconnected'
      && this.paymentProviders?.repay?.status === 'disconnected';
    },
    displayPaymentForm(): boolean {
      if (this.showStatusErrorAlert) {
        return false;
      }

      return !this.showPaymentForm && !this.cardUpdated;
    },
    brandingData(): any {
      return this.$store.getters['Ui/getBrandingObject'];
    },
    footerData(): any {
      return this.$store.getters['Ui/getFooterData'] || {};
    },
    repayStatus() {
      return this.paymentProviders?.repay?.status;
    },
    lastFour(): string {
      return this.paymentProviders?.last_four;
    },
    repayStatusConnected() {
      return this.repayStatus === 'connected';
    },
    repayLoans() {
      return this.paymentProviders?.repay?.number_of_active_loans;
    },
    moovStatus() {
      const status = this.paymentProviders?.moov?.status;
      if (status === 'verificationFailed') {
        return 'Verification Failed';
      }
      return status;
    },
    hasRepayAccount() {
      // data for this should come from backend
      return UsersRolesEnum.ADMIN
      && this.paymentProviders?.repay?.can_transact
      && this.merchantData?.merchant?.has_two_or_more_products_active;
    },
    moovStatusConnected() {
      return this.moovStatus === 'connected';
    },
    moovLoans() {
      return this.paymentProviders?.moov?.number_of_active_loans;
    },
    merchantData(): any {
      return this.$store.getters['MerchantPortal/getMerchantData'];
    },
    activeTab() {
      if (this.currentTab === 0) {
        return 'primary';
      }
      if (this.currentTab === 1) {
        return 'secondary';
      }
      return null;
    },
    showTabError() {
      if (this.activeTab === 'primary' && !this.moovStatusConnected) {
        return true;
      }

      if (this.activeTab === 'secondary' && !this.repayStatusConnected) {
        return true;
      }
      return false;
    },
    paymentProviders(): PaymentProvidersInterface {
      return this.$store.getters['MerchantPortal/getPaymentProviders'];
    },
    canSubmitDeposits(): boolean {
      return this.paymentProviders?.moov?.eligible_for_next_verification_attempt;
    },
  },
  mounted() {
    this.setPaymentData();
  },
  methods: {
    async onPaymentSuccess() {
      if (this.hasCardPassed) {
        this.storeConsent();

        try {
          await this.$store.dispatch('MerchantPortal/getPaymentProviders');
          await this.setPaymentData();
        } catch (err) {
          this.showPaymentForm = false;
          this.showStatusErrorAlert = true;
        }
      }
    },
    async bankAccountReplaced() {
      this.confirmAccountUpdate = false;

      if (this.hasCardPassed) {
        this.storeConsent();
        await this.$store.dispatch('MerchantPortal/getPaymentProviders');
        this.stopSpinner = true;
        this.updatePaymentSettingsForm = false;
      }
    },
    editPaymentData() {
      this.showDialog = false;
      this.showPaymentForm = true;
      this.cardUpdated = false;
    },
    onAmountsVerified(verified: any) {
      this.showPaymentForm = false;
      if (verified) {
        this.$store.dispatch('MerchantPortal/dispatchGetMerchantData');
        this.cardUpdated = true;
      }
      this.$store.dispatch('MerchantPortal/getPaymentProviders');
    },
    showPaymentSettingsDialog() {
      this.depositVerifyText = false;
      this.moovConnectedText = false;
      if (this.activeTab === 'primary') {
        this.moovConnectedText = true;
      }
      this.showDialog = true;
    },
    proceedWithPayment() {
      if (!this.canTransact) {
        this.editPaymentData();
      } else {
        this.showDialog = true;
      }
    },
    proceedWithUpdateAccount() {
      this.checkAccountDialog = false;
      this.moovConnectedText = false;
      this.depositVerifyText = true;
      this.showDialog = true;
    },
    closePaymentSettingsDialog() {
      this.showDialog = false;
    },
    hidePaymentForm() {
      this.showPaymentForm = false;
    },
    storeConsent() {
      const mAppUuid = this.$store.getters['MerchantPortal/getMerchantApplicationUuid'];
      patchAvailableConsents([ConsentTypesEnum.REPAY], mAppUuid, ConsentEntityTypes.MERCHANT);
      postConsentTypes({
        consentTypes: [ConsentTypesEnum.MOOV_MERCHANT],
        consumer_application_uuid: mAppUuid,
        entity: ConsentEntityTypes.MERCHANT,
        consentedOn: true,
      });
    },
    async setPaymentData() {
      const resp = await this.$store.dispatch('MerchantPortal/dispatchGetMerchantDetails');

      if (resp.card_token_data.payment_method !== null) {
        this.cardTokenData = resp.card_token_data;
      }
    },
    setDepositVerificationFailed() {
      this.showPaymentForm = true;
    },
    depositVerFailed() {
      this.$store.dispatch('MerchantPortal/getPaymentProviders');
    },
    closeCheckAccountDialog() {
      this.checkAccountDialog = false;
    },
    updateBankAccount() {
      if (this.depositVerifyText) {
        this.showDialog = false;
        this.confirmAccountUpdate = true;
      } else {
        this.showDialog = false;
        this.updatePaymentSettingsForm = true;
      }
    },
    checkBankAccount() {
      this.checkAccountDialog = true;
    },
    restartConnectionProcess() {
      this.moovFormReset = true;
    },
    async resendMicroDeposit() {
      const merchantUuid = this.$store.getters['Auth/getMerchantUuid'];
      const resp = await reSendMicroDeposit(merchantUuid);

      if (resp === 200) {
        this.$store.dispatch('Repay/setCardPassedState', resp === 200);
        this.$store.dispatch('MerchantPortal/dispatchGetMerchantData');
        this.$store.dispatch('MerchantPortal/getPaymentProviders');
      }
    },
    depositsExampleDialog() {
      this.showVerifyDepositsDialog = true;
    },
    closeShowVerifyDepositsDialog() {
      this.showVerifyDepositsDialog = false;
    },
    multiLenderModal() {
      this.showMultiLenderDialog = true;
    },
    closeMultiLenderDialog() {
      this.showMultiLenderDialog = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/media_queries";

:deep(.v-expansion-panel-text__wrapper) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:deep() .v-expansion-panel-title:hover > .v-expansion-panel-title__overlay {
  opacity: 0;
}

.form-button {
  line-height: 1.5;
  display: inline;
  white-space: normal;
}

.payment-container {
  max-width: 28.75rem;

  &__info {
    margin-bottom: 1rem !important;
  }
}

.text-input-error {
  color: var(--error-color);
}

.main-card {
  margin-top:1.5rem;
  margin-bottom: 2rem;
  border-top: 1px solid var(--grayscale-color-2);
  border-left: 1px solid var(--grayscale-color-2);
  border-right: 1px solid var(--grayscale-color-2);
  border-radius: 8px;
  max-width: 100%;

  .split-payment-settings {
    padding: 1rem;
    border-bottom: 1px solid var(--grayscale-color-3);

    div {
      color: var(--grayscale-color-1) !important;

      .bank-acc {
        font-weight: 700;
      }

      .lender-link {
        color: #0B9AEB;
      }

      .lender-link:hover {
        cursor: pointer;
      }
    }
  }

  .v-card-title {
    padding: 1rem;
    font-weight: 700;
    font-size: 1rem;
    color: var(--grayscale-color-1);
    background-color: #fff;
    border-bottom: 1px solid var(--grayscale-color-3);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .v-card-text {
    background-color: var(--grayscale-color-4);

    @media(max-width: 440px) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .v-tabs :deep() {
      padding-top: 1rem;
      height: auto;

      .v-slide-group__container .v-slide-group__content {
        justify-content: space-between;

        @media(max-width: 440px) {
          width: 100%;
        }

        .v-tab {
          width: 11.75rem;
          border-radius: 8px;
          border: 2px solid transparent;
          background-color: #FFFFFF;

          @include mobile {
            width: 10.75rem;
          }

          &::before {
            background-color: transparent;
          }
        }

        .v-tab--active {
          border: 2px solid var(--grayscale-color-1);
        }
      }

      .network-button-tab {
        grid-template-columns: 0 1fr 0;
      }

      .network-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-weight: 400;
        font-size: 1rem;
        color: var(--grayscale-color-1);
        border-style: none;
        border-radius: 8px;
        letter-spacing: 0;
        padding: 0 0.5rem;

        .network-button-tab {
          color: var(--grayscale-color-1);
          text-transform: capitalize;

          @include mobile {
            font-size: 0.875rem;
          }
        }
      }

      .v-theme--light.v-btn.custom-button {
        &:hover:not(&.button-text):not(&.button-link):not(.v-btn--icon) {
          box-shadow: none !important;
        }
      }
    }

    .tab-items {
      margin-top: 1rem;
      border-radius: 8px;
      border: 2px solid var(--grayscale-color-1);

      .payment-networks {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem;
        background-color: var(--grayscale-color-5);
        box-shadow: inset 0px -1px 0px var(--grayscale-color-3);

        .network {
          font-weight: 700;
          font-size: 1rem;
          color: var(--grayscale-color-1);
          padding: 0.5rem;
        }

        .status {
          font-weight: 400;
          font-size: 0.75rem;
          color: var(--grayscale-color-1);
          background-color: #fff;
          padding: 0.5rem;
          border-radius: 18px;

          span {
            font-weight: 700;
          }
        }
      }

      .payment-network-details {
        padding: 1rem;
        font-weight: 400;
        font-size: 0.875rem;
        color: var(--grayscale-color-1);

        .active-loans {
          margin-bottom: 1rem;
        }

        .credit-list {
          padding-left: 1rem;
          .bullet-type {
            list-style-type: disc;
          }
        }

        .repay-payment-form {
          padding: 0 !important;

          :deep(.v-card-text) {
            padding: 0 !important;
          }
        }
      }

      .pending-status {
        font-weight: 700;
        color: var(--grayscale-color-1);
      }

      .learn-more {
        display: flex;
        justify-content: flex-end;

        .custom-button {
          font-size: 0.875rem;
        }
      }

      .merchant-email span {
        color: #0B9AEB;
      }

      .last-four span {
        font-weight: 700;
        color: var(--grayscale-color-1);
      }
    }
  }

  .add-bottom-radius {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .main-button {
    border-style: none !important;
    border-radius: 0 0 4px 4px;
    box-shadow: inset 0px 1px 0px var(--grayscale-color-3);
  }

  .active-class {
    border: 2px solid var(--grayscale-color-1) !important;
  }

  .error-class {
    border: 2px solid var(--error-color) !important;
  }

  .error-color {
    color: var(--error-color) !important;
  }
}
</style>
