<template>
  <v-text-field
    ref="inputField"
    v-mask="inputMask"
    v-fs-exclude
    v-bind="$attrs"
    autocomplete="off"
    :append-inner-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
    :data-test="dataTestAttr"
    :label="labelAttr"
    :variant="inPlace ? '' : 'outlined'"
    :required="required"
    :rules="[
      isRequired,
      inputValidation(modelValue, errorMessages),
      validateLength,
    ]"
    :type="show ? 'text' : 'password'"
    :model-value="modelValue"
    validate-on="blur"
    @click:appendInner="show = !show"
    @copy.prevent />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import { INPUT_MASK_DEFAULTS } from '@/components/Inputs/defaults';
import VerifyInputMixin from '@/components/Inputs/VerifyInputMixin';
import validateBankAccountNumber from '@/validators/bankAccountNumber';

export default defineComponent({
  name: 'BankAccountNumberInput',

  mixins: [InputFieldMixin, VerifyInputMixin],

  props: {
    visible: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    errorMessages: { type: String, default: '' },
  },

  data: () => ({
    defaultLabelAttr: 'Bank Account Number',
    defaultDataTestAttr: 'banInput',
    show: false,
  }),

  computed: {
    inputMask() {
      return {
        ...INPUT_MASK_DEFAULTS,
        alias: 'bankAccountNumber',
        mask: '99999999999999999',
        jitMasking: true,
        autoUnmask: true,
      };
    },
  },

  mounted() {
    this.show = this.visible;
  },

  methods: {
    validateLength(value: string | null) {
      return validateBankAccountNumber(value) || 'Must be minimum 5 digits and maximum 17 digits.';
    },
  },
});
</script>
