import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["align-center d-flex", `justify-${_ctx.align}`])
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
    (_ctx.tooltip)
      ? (_openBlock(), _createBlock(_component_v_icon, {
          key: 0,
          size: "1rem"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" mdi-help-circle-outline ")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}