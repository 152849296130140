<template>
  <div class="payment-steps">
    <verify-deposit
      :last-four="lastFour"
      :payment-data="formDetails"
      :show-deposits-form-split-payment="showDepositsFormSplitPayment"
      :show-loader="true"
      @amountsVerified="passAmounts"
      @activateDialog="activateIncorrectAmountDialog"
      @verificationFailed="$emit('verificationFailed')" />

    <incorrect-amount-dialog
      :show-dialog="showIncorrectAmountDialog"
      :last-four="lastFour"
      @closeDialog="closeIncorrectAmountDialog" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VerifyDeposit from '@/components/PaymentProviders/VerifyDeposit.vue';
import IncorrectAmountDialog
  from '@/components/Merchant/Portal/Admin/IncorrectAmountDialog.vue';

export default defineComponent({
  name: 'PaymentSteps',

  components: {
    VerifyDeposit,
    IncorrectAmountDialog,
  },

  props: {
    lastFour: { type: String, default: null },
    showDepositsFormSplitPayment: { type: Boolean, default: false },
  },

  data() {
    const formDetails: any = {};
    return {
      formDetails,
      showIncorrectAmountDialog: false,
    };
  },

  methods: {
    passAmounts(verified: any) {
      this.$emit('amountsVerified', verified);
    },

    closeIncorrectAmountDialog() {
      this.showIncorrectAmountDialog = false;
    },

    activateIncorrectAmountDialog() {
      this.showIncorrectAmountDialog = true;
    },
  },
});
</script>
