<template>
  <div>
    <div class="fs-unmask merchant__activity-center__new-loan-application">
      <custom-button
        :disabled="!buttonEnabled"
        data-test="newButton"
        full-width
        @click="showModal = true">
        <v-icon>mdi-account-plus</v-icon>
        New Loan Invitation
      </custom-button>
    </div>

    <new-loan-application-modal
      v-if="showModal"
      :dialog="true"
      :on-cta="closeNewLoanApplicationModal" />
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import NewLoanApplicationModal
  from '@/components/CustomerSummaryCard/Modals/NewLoanApplicationModal.vue';
import GetProcessing from '@/mixins/GetProcessing';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NewLoanApplication',

  components: {
    CustomButton,
    NewLoanApplicationModal,
  },

  mixins: [
    GetProcessing,
  ],

  data() {
    return {
      showModal: false,
      VERIFICATION_HOLD_STATUS: 'Verification Hold',
      HOLD_PAUSE_APPLICATIONS: 'Hold - Pause Applications',
    };
  },

  computed: {
    merchantData() {
      return this.$store.getters['MerchantPortal/getMerchantData'];
    },

    buttonEnabled(): boolean {
      return this.merchantData?.merchant?.has_two_or_more_products_active
      && this.merchantData?.merchant?.status_string !== this.VERIFICATION_HOLD_STATUS
      && this.merchantData?.merchant?.status_string !== this.HOLD_PAUSE_APPLICATIONS
      && this.merchantData?.can_send_consumer_invitation;
    },
  },

  methods: {
    closeNewLoanApplicationModal() {
      this.showModal = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.merchant {
  &__activity-center {
    margin-bottom: 0.75rem;
  }
}

button {
  margin-bottom: 1rem;

  @include mobile {
    margin: 0.5rem 0;
  }

  .mdi {
    position: absolute;
    left: 0.875rem;
    font-size: 1.09rem !important;
    width: 1.5rem;
  }
}
</style>
