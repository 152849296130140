export default function GetHumanReadableIdLabel(hrid: string, prefix = true) {
  if (!hrid || hrid.length !== 10) {
    return '';
  }

  const hridLabel = [...hrid];

  hridLabel.splice(3, 0, '-');
  hridLabel.splice(7, 0, '-');

  if (prefix) {
    hridLabel.splice(0, 0, '');
  }

  return hridLabel.join('');
}
