import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e9c0e78"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-text__info-group"
}
const _hoisted_2 = {
  key: 1,
  class: "pin-input"
}
const _hoisted_3 = { class: "pin-input--inputs" }
const _hoisted_4 = { class: "d-flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_otp_input = _resolveComponent("v-otp-input")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _directive_fs_exclude = _resolveDirective("fs-exclude")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.simple)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createVNode(_component_v_text_field, {
            modelValue: _ctx.pin,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pin) = $event)),
              _ctx.onUpdate
            ],
            variant: !_ctx.disabled ? 'underlined' : 'plain',
            disabled: _ctx.disabled,
            label: "Four-Digit PIN",
            rules: [_ctx.pinRequired],
            "validate-on": "blur",
            onKeydown: _ctx.onKeyDownSimple
          }, null, 8, ["modelValue", "variant", "disabled", "rules", "onKeydown", "onUpdate:modelValue"]), [
            [_directive_fs_exclude]
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.label), 1),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(_component_v_otp_input, {
              ref: "otpInput",
              key: _ctx.otpRerender,
              modelValue: _ctx.pin,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pin) = $event)),
              disabled: _ctx.disabled,
              length: _ctx.inputsNumber,
              type: "number",
              onKeydown: _ctx.onKeyDown,
              onInput: _ctx.onUpdate
            }, null, 8, ["modelValue", "disabled", "length", "onKeydown", "onInput"]))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_v_btn, {
              class: "button-secondary custom-button-label pa-0",
              "data-test": "clearOtpBtn",
              variant: "plain",
              color: "var(--primary-color)",
              size: "large",
              disabled: _ctx.pin === '',
              onClick: _ctx.clearOtp
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Clear ")
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ]))
  ]))
}