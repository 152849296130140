<template>
  <div>
    <standard-dialog
      dialog
      :on-cta="closeModal"
      :on-cancel="closeModal"
      :show-cancel-cta="false"
      button-label="OK"
      data-test="reinviteModal">
      <template #body>
        An email invite has been sent to <span v-private>{{ email }}</span>.
        Once they set up a password and login, they will be able to work in your portal.
      </template>
    </standard-dialog>

    <alert-modal
      :dialog="showAlert"
      :on-close="closeAlertDialog"
      type="error">
      <span>
        We're sorry. An error occurred while trying to perform this function. Please try again later.
      </span>
    </alert-modal>
  </div>
</template>

<script lang="ts">
import StandardDialog from '@/components/Dialogs/index.vue';
import AlertModal from '@/components/Dialogs/AlertModal.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ReInviteUserDialog',
  components: {
    StandardDialog,
    AlertModal,
  },
  props: {
    email: { type: String, required: true },
    showAlert: { type: Boolean },
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
    closeAlertDialog() {
      this.$emit('close:alert');
    },
  },
});
</script>
