<template>
  <div>
    <standard-dialog
      :dialog="dialog"
      :on-cta="onCta"
      :on-cancel="onCancel"
      :title="!isSettled ? '' : 'What would you like to do?'"
      :persistent="true"
      :show-cancel-cta="false"
      :show-clear-cta="false"
      :show-actions="false"
      light-title
      data-test="refundsAmountsModal">
      <template #body>
        <div v-if="isSettled">
          <custom-button
            data-test="refundAmount"
            full-width
            class="refund-amount-btn"
            @click="openConfirmDialog">
            <span>
              Refund <span v-private>{{ maxRefundableAmount }}</span>
              to <span v-private>{{ consumer.user.first_name }} {{ consumer.user.last_name }}</span>
            </span>
          </custom-button>
          <custom-button
            data-test="refundAnother"
            variant="secondary"
            full-width
            @click="openAnotherAmountDialog"
          >
            Refund Another Amount
          </custom-button>
        </div>
        <div v-else>
          <p class="mb-8">
            This transaction has not settled. For that reason, you can only void
            the entire amount of the transaction. What would you like to do?
          </p>
          <custom-button
            data-test="refundVoid"
            full-width
            class="refund-amount-btn"
            @click="openConfirmDialog"
          >
            Void the <span v-private>{{ maxRefundableAmount }}</span> Charge
          </custom-button>
          <custom-button
            data-test="refundAnother"
            variant="secondary"
            full-width
            @click="onCancel"
          >
            Cancel
          </custom-button>
        </div>
      </template>
    </standard-dialog>
    <confirm-dialog
      :show-confirm-dialog="showConfirmDialog"
      :on-cancel="closeConfirmDialog"
      :on-confirm="sendRefund"
      :title-text="confirmTitle"
      :disable-confirm-button="disableConfirmButton"
      light-title>
      <template #body>
        <span>
          Refund <strong v-private>{{ maxRefundableAmount }}
          </strong> to<strong v-private> {{ refundToName }}</strong>.
        </span>
      </template>
    </confirm-dialog>
    <refund-another-amount
      :dialog="showAnotherAmountDialog"
      :on-cancel="closeAnotherAmountDialog"
      :transaction-data="transactionData"
      :consumer-data="consumerData"
      @refund="$emit('refund', $event)" />
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog.vue';
import RefundAnotherAmount from '@/components/Dialogs/RefundAnotherAmount.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import RefundAlerts from '@/components/CustomerSummaryCard/RefundsDialog/Mixins/RefundAlerts';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'RefundAmounts',

  components: {
    ConfirmDialog,
    CustomButton,
    RefundAnotherAmount,
    StandardDialog,
  },

  mixins: [
    RefundAlerts,
    HoneypotTrackMixin,
  ],

  props: {
    dialog: { type: Boolean, default: false },
    onCta: { type: Function as PropType<() => void>, default: () => null },
    onCancel: { type: Function as PropType<() => void>, default: () => null },
    transactionData: { type: Object, default: null },
    isSettled: { type: Boolean, default: false },
    consumerData: { type: Object, default: null },
  },

  data() {
    const refundData: any = {};
    const consumer: any = {};
    return {
      showConfirmDialog: false,
      confirmTitle: 'Please Confirm.',
      showAnotherAmountDialog: false,
      refundData,
      consumer,
      disableConfirmButton: false,
      hpEventName: 'Refund Request Sent',
    };
  },

  computed: {
    maxRefundableAmount() {
      return `$${this.refundData.maxRefundableAmount}`;
    },

    amountNumber() {
      const num = this.refundData.maxRefundableAmount.replace(/,/g, '');
      return Number(num);
    },

    refundToName() {
      const { user } = this.consumer;
      const name = `${user.first_name} ${user.last_name}`;

      return name;
    },
  },

  watch: {
    transactionData: {
      immediate: true,
      handler(val: any) {
        this.refundData = val;
      },
    },

    consumerData: {
      immediate: true,
      handler(val: any) {
        this.consumer = val;
      },
    },
  },

  methods: {
    openConfirmDialog(): any {
      this.showConfirmDialog = true;
      this.onCancel();
    },

    closeConfirmDialog(): any {
      this.showConfirmDialog = false;
    },

    openAnotherAmountDialog(): any {
      this.showAnotherAmountDialog = true;
      this.onCancel();
    },

    closeAnotherAmountDialog(): any {
      this.showAnotherAmountDialog = false;
    },

    async sendRefund() {
      this.disableConfirmButton = true;

      const res = await this.$store.dispatch('MerchantPortal/dispatchRefund', {
        payment_identifier: this.refundData.paymentIdentifier,
        amount: this.amountNumber,
      });

      this.disableConfirmButton = false;
      this.closeConfirmDialog();
      this.showAlert(res);
      this.trackEvent(this.hpEventName, PageTypesShorthand.MP);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.refund-amount-btn {
  margin-bottom: 2rem;
}
</style>
