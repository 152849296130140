<template>
  <standard-dialog
    :dialog="dialog"
    :persistent="true"
    :on-cancel="onCancel"
    title="Please confirm">
    <template #body>
      <div class="simple-card__body-text">
        <div class="body-text-data">
          <p class="mb-2">
            You would like to delete Owner {{ ownerPosition }}:
          </p>
          <p class="font-weight-bold">
            {{ ownerName }} - {{ ownerPercentage }}% Owner
          </p>
        </div>
      </div>
    </template>
    <template #actions>
      <div class="button-wrapper">
        <custom-button
          full-width
          data-test="confirmBtn"
          :disabled="disableButtonsInModal"
          @click="onCta">
          Delete
        </custom-button>
        <custom-button
          full-width
          variant="secondary"
          class="cancel-btn"
          data-test="cancelBtn"
          :disabled="disableButtonsInModal"
          @click="onCancel">
          Cancel
        </custom-button>
      </div>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'DeleteOwnerModal',

  components: {
    StandardDialog,
    CustomButton,
  },

  props: {
    dialog: { type: Boolean, default: false },
    ownerName: { type: String, default: '' },
    ownerPercentage: { type: Number, default: null },
    ownerPosition: { type: String, default: '' },
    disableButtonsInModal: { type: Boolean, default: false },
  },

  methods: {
    onCta() {
      this.$emit('confirmed');
    },

    onCancel() {
      this.$emit('declined');
    },
  },
});
</script>

<style lang="scss" scoped>
.button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.button-wrapper button.cancel-btn {
  margin-top: 2rem;
  background-color: #fff !important;
}

:deep(.dialog-wrap.dialog-body-wrap) {
  padding-top: 0 !important;
}

.body-text-data {
  color: var(--grayscale-color-1);
  p {
    font-size: 1rem;
  }
}
</style>
