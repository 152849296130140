<template>
  <div>
    <v-switch
      v-if="switchToggle"
      class="old-v-switch"
      :model-value="selected"
      inset
      hide-details
      :ripple="false"
      color="white"
      @update:model-value="$emit('toggle')" />
    <div v-if="additionalProductOfferings">
      <custom-button
        :class="{ selected }"
        :disabled="btnDisabled"
        size="small"
        variant="secondary"
        :product-id-data-test="!fromRateSheet ? productName : ''"
        :custom-data-test="!fromRateSheet ? dataTest : ''"
        data-test="switchBtn"
        @click="$emit('toggle')">
        {{ labelText }}
      </custom-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'RateSheetSelector',

  components: {
    CustomButton,
  },

  props: {
    buttonToggle: { type: Boolean, default: false },
    switchToggle: { type: Boolean, default: false },
    additionalProductOfferings: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    disabledAdd: { type: Boolean, default: false },
    fromRateSheet: { type: Boolean, default: false },
    productName: { type: String, default: '' },
    dataTest: { type: String, default: '' },
  },

  computed: {
    btnDisabled(): boolean {
      return this.disabledAdd && !this.selected;
    },

    labelText() {
      if (this.buttonToggle) {
        return this.selected ? 'Remove' : 'Add to offer';
      }
      return 'Add To Rate Sheet';
    },
  },
});
</script>

<style lang="scss" scoped>
.v-input.v-switch.old-v-switch {
  margin-bottom: 0;
}

.custom-button {
  width: 7rem;

  &.selected {
    color: black!important;
  }
}
</style>
