import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserIsLoggedInMixin',
  computed: {
    isLoggedIn(): number {
      return this.$store.getters['Auth/getIsLoggedIn'];
    },
  },
});
