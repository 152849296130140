import { getAxiosError } from '@/api/utils';
import { instance } from './index';

export default async function reSendMicroDeposit(merchantUuid: string) {
  let responseStatus: number | undefined = 0;
  let response: any = {};

  try {
    response = await instance.post(`/api/v1/merchant/${merchantUuid}/init-micro-deposits/`);
  } catch (err: unknown) {
    const axiosError = getAxiosError(err);
    if (axiosError) {
      responseStatus = axiosError.response?.status;
    }
  } finally {
    const status = response.status || response.response?.status;
    if (status) responseStatus = status;
  }
  return responseStatus;
}
