<template>
  <div
    class="standard-wizard__step standard-wizard__step--invalid-link"
    data-test="invalidCard">
    <v-card>
      <v-card-text>
        We're sorry but it appears you have an invalid link. Please go back to the invitation and
        try again. If this problem persists please contact
        <a :href="supportMailTo">{{ supportEmail }}</a>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SupportEmailMixin from '@/mixins/SupportEmailMixin';

export default defineComponent({
  name: 'InvalidLink',

  mixins: [SupportEmailMixin],

  data: () => ({
    defaultSupportEmail: 'sales@momnt.com',
  }),
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/merchant-begin-onboarding-wizard";

.standard-wizard__step {
  background-color: #fff;
}
</style>
