import { GlobalAlertIface } from '@/interfaces/GlobalAlertInterface';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AlertMixin',
  methods: {
    addGlobalAlert(success: boolean, action: string, name: string) {
      const text = success
        ? `You have successfully ${action}d offer code ${name}.`
        : `Offer code ${action} failed. Please try again later.`;
      const type = success ? GlobalAlertTypes.SUCCESS : GlobalAlertTypes.ERROR;
      const payload: GlobalAlertIface = { text, type, timed: true };
      this.$store.dispatch('Ui/addGlobalAlert', payload);
    },
  },
});
