import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    initialCharge: { type: Boolean, default: false },
    paymentConfirmation: { type: Boolean, default: false },
    transactionPending: { type: Boolean, default: false },
    transactionStatusErrored: { type: Boolean, default: false },
    secondConfirmation: { type: Boolean, default: false },
    completedTransaction: { type: Boolean, default: false },
    finalChargeQuestion: { type: Boolean, default: false },
    chargeName: { type: String, default: '' },
    transactionStatus: { type: String, default: '' },
    maxChargeValue: { type: Number, default: null },
  },
});
