<template>
  <v-text-field
    ref="input"
    v-mask="inputMask"
    v-bind="attrs"
    autocomplete="off"
    :data-test="dataTestAttr"
    persistent-placeholder
    placeholder="00"
    :hint="hint"
    class="cents-input"
    persistent-hint
    :label="labelAttr"
    :variant="inPlace ? 'plain' : 'outlined'"
    :prepend-inner-icon="showIcon ? 'mdi-currency-usd' : ''"
    validate-on="blur"
    :rules="rules || []"
    maxlength="2"
    inputmode="numeric"
    pattern="[0-9]*"
    prefix="0." />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';

export default defineComponent({
  name: 'CentsInput',

  mixins: [
    InputFieldMixin,
  ],

  props: {
    showIcon: { type: Boolean },
    hint: { type: String, default: '' },
    rules: { type: Array as PropType<Array<any>> | null, default: null },
  },

  computed: {
    inputMask() {
      return {
        mask: '99',
        placeholder: '00',
        showMaskOnHover: false,
        autoUnmask: true,
        allowMinus: false,
        allowPlus: false,
        greedy: true,
        importDataAttributes: false,
        positionCaretOnClick: 'lvp',
        inputmode: 'numeric',
        clearIncomplete: false,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.cents-input {

  &.v-input--error {
    :deep() {
      .v-text-field__prefix, .v-messages__message, input::placeholder {
        color: var(--error-color) !important;
      }
    }
  }

  :deep() {
    .v-text-field__prefix {
      padding-right: 0;
      color: rgba(0, 0, 0, 0.87);
    }

    input {
      padding-left: 0;

      &::placeholder {
        color: rgba(0, 0, 0, 0.87);
        opacity: 1;
      }
    }

    .v-input__prepend-inner {
      padding-right: 0;
      color: rgba(0, 0, 0, 0.87);
    }

    .v-text-field__details {
      padding: 0;

      .v-messages .v-messages__message {
        line-height: 1.15rem;
        color: var(--grayscale-color-1);
        padding: 0 0.75rem;
      }
    }
  }
}
</style>
