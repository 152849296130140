<template>
  <main-footer
    class="mt-16"
    :address="address"
    :client-name="clientName"
    :human-readable-id="consumerHumanReadableApplicationIdLabel"
    :human-readable-id-prefix="'ID: '"
    :show-client-name="false"
    :support-links="supportLinks" />
</template>

<script lang="ts">
import MainFooter from '@/components/MainFooter.vue';
import ConsumerHumanReadableApplicationId
  from '@/mixins/Consumer/LoanApply/ConsumerHumanReadableApplicationId';
import { get } from 'lodash';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VFooter',
  components: { MainFooter },
  mixins: [ConsumerHumanReadableApplicationId],
  computed: {
    footerContent(): any {
      return this.$store.getters['Ui/getFooterData'];
    },
    supportLinks(): any {
      return get(this.footerContent, 'supportLinks', []);
    },
    complianceLinks(): any {
      return get(this.footerContent, 'complianceLinks', []);
    },
    clientName(): any {
      return get(this.footerContent, 'clientName');
    },
    address(): any {
      return get(this.footerContent, 'address');
    },
    loanApplyWizardStep(): number {
      return this.$store.getters['Consumer/getLoanApplyWizardStep'];
    },
  },
});
</script>
