<template>
  <standard-dialog
    :dialog="true"
    :on-cta="toggleUser"
    :on-cancel="closeModal"
    :cta-disabled="processing"
    :title="title"
    button-label="Yes"
    data-test="deactivateModal">
    <template #body>
      <span v-if="active">
        <span v-private>{{ userNameLabel }}</span> will no longer be able to login to
        your merchant portal.
      </span>
      <span v-else>
        <span v-private>{{ userNameLabel }}</span> will be able to login to your merchant portal
        with the previously assigned permissions.
      </span>
    </template>
  </standard-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import { UpdateMerchantUserPayloadInterface } from '@/interfaces/merchantPortal/UpdateMerchantUserPayloadInterface';
import { useStore } from 'vuex';
import { PatchUserPayload } from '@/interfaces/merchantPortal/UserInterface';
import { patchUserAtAllLocations, patchUserAtThisLocation } from '@/api/merchant';

export interface DeactivateUserProps {
  userId: number;
  status: string;
  firstName: string;
  lastName: string;
  multiLocation: boolean;
  allUsersTable: boolean;
}

const props = defineProps<DeactivateUserProps>();
const emit = defineEmits(['save', 'close']);

const store = useStore();
const merchantUuid = store.getters['MerchantPortal/getMerchantUuid'];

const processing = ref(false);
const active = computed(() => props.status === 'Active');
const title = computed(() => `${active.value ? 'Deactivate' : 'Reactivate'} this user?`);
const userNameLabel = computed(() => `${props.firstName} ${props.lastName}`);

const toggleUser = async () => {
  processing.value = true;
  const successMessage = `User ${active.value ? 'deactivated' : 'reactivated'}.`;

  try {
    if (props.multiLocation) {
      const payload: PatchUserPayload = { status: active.value ? 'Inactive' : 'Active' };
      const patchFn = props.allUsersTable ? patchUserAtAllLocations : patchUserAtThisLocation;
      await patchFn(merchantUuid, props.userId, payload);
    } else {
      const payload: UpdateMerchantUserPayloadInterface = {
        userId: props.userId,
        data: {
          is_active: !active.value,
        },
      };
      await store.dispatch('MerchantPortal/dispatchUpdateMerchantUser', payload);
    }

    store.dispatch('Ui/addGlobalTimedSuccess', successMessage);
    emit('save');
  } catch (error) {
    store.dispatch('Ui/addGlobalTimedError');
  } finally {
    processing.value = false;
  }
};

const closeModal = () => emit('close');
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
@import "@/assets/scss/mixins/mixins";

.standard-data-table__actions__activate-modal {
  &--inactive {
    .btn {
      color: #000000;
      border-color: #000000;
    }
  }
}

.custom-button {
  @include user-management-custom-button;
}

</style>
