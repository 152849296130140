export default class ActivityTracker {
  LAST_ACTIVITY_KEY: string;

  readonly EVENTS: Array<keyof HTMLElementEventMap> = ['click', 'mousemove', 'wheel', 'keydown'];

  HANDLER: (this: EventTarget, event: Event) => void;

  TIMEOUT_ID?: number;

  readonly LISTENER_OPTIONS: AddEventListenerOptions = { once: true, passive: true };

  constructor(activityStorageKey: string) {
    this.LAST_ACTIVITY_KEY = activityStorageKey;
    this.HANDLER = this.recordActivity.bind(this);
    this.clearData();
    this.recordActivity();
  }

  getMinutesInactive(): number {
    const now = new Date();
    const lastActivityAtValue = localStorage.getItem(this.LAST_ACTIVITY_KEY);
    if (!lastActivityAtValue) return 0;

    const lastActivityAt = new Date(lastActivityAtValue);
    const passedMs = now.getTime() - lastActivityAt.getTime();
    return passedMs / 1000 / 60;
  }

  recordActivity() {
    const now = new Date();
    localStorage.setItem(this.LAST_ACTIVITY_KEY, now.toISOString());
    this.removeEventListeners();
    clearTimeout(this.TIMEOUT_ID);
    this.TIMEOUT_ID = window.setTimeout(this.addEventListeners.bind(this), 1000 * 10);
  }

  clearData() {
    localStorage.removeItem(this.LAST_ACTIVITY_KEY);
    this.removeEventListeners();
  }

  private addEventListeners() {
    this.EVENTS.forEach((event: string) => {
      document.body.addEventListener(event, this.HANDLER, this.LISTENER_OPTIONS);
    });
  }

  private removeEventListeners() {
    this.EVENTS.forEach((event: string) => {
      document.body.removeEventListener(event, this.HANDLER, this.LISTENER_OPTIONS);
    });
  }
}
