<template>
  <v-text-field
    v-mask="inputMask"
    v-fs-exclude
    v-bind="$attrs"
    autocomplete="off"
    inputmode="numeric"
    :data-test="dataTestAttr"
    :label="labelAttr"
    :variant="inPlace ? 'plain' : 'outlined'"
    :rules="[isRequired, isValidRoutingNumber]"
    :error-messages="checkReturnFailed ? failedCheckError : ''"
    type="text"
    validate-on="blur" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import { INPUT_MASK_DEFAULTS } from '@/components/Inputs/defaults';
import validate from '@/validators/ssn';

export default defineComponent({
  name: 'RoutingNumberInput',

  mixins: [InputFieldMixin],

  props: {
    checkReturnFailed: { type: Boolean, default: false },
  },

  data: () => ({
    defaultLabelAttr: 'Routing Number',
    defaultDataTestAttr: 'routingNumber',
    failedCheckError: 'The routing number entered is not valid. '
        + 'Please enter the 9 digit routing number used for electronic transfers.',
  }),

  computed: {
    inputMask() {
      return {
        ...INPUT_MASK_DEFAULTS,
        alias: 'routingNumber',
        mask: '999999999',
        jitMasking: true,
      };
    },
  },

  methods: {
    isValidRoutingNumber(routingNumber: string) {
      return validate(routingNumber) || 'Please correct routing number format';
    },
  },
});
</script>
