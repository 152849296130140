import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    setCustomErrorValue(key: string, value: string, clearError = true): void {
      this.$store.commit(
        'Ui/setCustomError',
        {
          key,
          value: clearError ? '' : value,
        },
      );
    },
  },
});
