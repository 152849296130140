import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_merchant_onboarding_wizard_payments_types_financing_options = _resolveComponent("merchant-onboarding-wizard-payments-types-financing-options")!

  return (_openBlock(), _createBlock(_component_merchant_onboarding_wizard_payments_types_financing_options, {
    "data-test": "MOB-step3-substep7",
    "in-place-input": false,
    "on-next-sub-step": _ctx.goToNextSubStep
  }, null, 8, ["on-next-sub-step"]))
}