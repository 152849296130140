import GetMenuItemAccess from '@/helpers/GetMenuItemAccess';
import { MenuItemInterface } from '@/interfaces/menu/MenuItemInterface';
import BANK_ACCOUNT_STATUSES from '@/constants/PaymentConstants';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    allMerchantHeaderItems(): Array<MenuItemInterface> {
      return this.$store.getters['MerchantPortal/getMenuItems'];
    },
    isSalesManager() {
      const type = this.$store.getters['MerchantPortal/getMerchantData']?.user_type.name;
      return type === 'merchant-sales-manager' || false;
    },
    merchantHeaderItems(): Array<MenuItemInterface> {
      return this.allMerchantHeaderItems.filter((item: MenuItemInterface) => {
        return GetMenuItemAccess(item);
      });
    },
    cannotTransact(): boolean {
      return this.paymentProviders?.moov?.status === BANK_ACCOUNT_STATUSES.PENDING
      || this.paymentProviders?.moov?.status === BANK_ACCOUNT_STATUSES.VERIFICATION_FAILED
      || this.paymentProviders?.moov?.status === BANK_ACCOUNT_STATUSES.ERRORED
      || !this.paymentProviders?.repay?.can_transact;
    },
    paymentProviders(): PaymentProvidersInterface {
      return this.$store.getters['MerchantPortal/getPaymentProviders'];
    },
  },
  methods: {
    getDataTestAttribute(name?: string): string {
      if (!name) return '';

      const splitName: string[] = name.split('-');
      return `${splitName[splitName.length - 1]}PageBtn`;
    },
    getRouteData(name?: string): any {
      return { name };
    },
  },
});
