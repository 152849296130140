<template>
  <standard-wizard-message
    :message="errorMessage"
    data-test="invalidMsg" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardWizardMessage from '@/components/Wizard/Message.vue';

export default defineComponent({
  name: 'WidgetsUnableToProcessMsg',

  components: {
    StandardWizardMessage,
  },

  data() {
    return {
      errorMessage: 'We are unable to process your request at this time.',
    };
  },
});
</script>
