<template>
  <div :class="{ 'd-none d-print-block': printOnLoad }">
    <export
      class="d-print-none fs-unmask mb-4"
      :loading="loading"
      :statement-id="statementId"
      @export:pdf="printPdf" />
    <div ref="printDiv">
      <div class="statement-container">
        <v-card>
          <skeleton-loader v-if="loading" />
          <template v-else>
            <v-card-title class="header">
              <span>Statement</span>
              <span>{{ brandObject.brand_name }}</span>
            </v-card-title>

            <v-card-subtitle class="header">
              <div>
                <div>
                  <span>Submitted on {{ statement.statement_summary.statement_date }}</span>
                </div>
                <div>
                  <span>
                    For the Period of {{ statement.statement_summary.statement_begin_date }}
                    to {{ statement.statement_summary.statement_end_date }}
                  </span>
                </div>
                <div>
                  <span>Statement #{{ statement.statement_summary.statement_number }}</span>
                </div>
              </div>
              <div v-if="brandObject.footer_object">
                <div v-for="(address, i) in serviceAddress" :key="i">
                  <span>{{ address }}</span>
                </div>
                <div>
                  <span>{{ brandObject.footer_object.support_number }}</span>
                </div>
              </div>
            </v-card-subtitle>

            <v-card-text>
              <v-row>
                <merchant-info :merchant-info="statement.merchant_info" />
                <statement-summary :summary="statement.statement_summary" />
              </v-row>
              <transactions :transactions="statement.success_statement_details" />
              <transactions :transactions="statement.fail_statement_details" failed-transactions />
            </v-card-text>
          </template>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Export from '@/components/Merchant/Portal/Statements/Export.vue';
import MerchantInfo from '@/components/Merchant/Portal/Statements/MerchantInfo.vue';
import { StatementDetailsInterface } from '@/interfaces/statements/StatementDetailsInterface';
import StatementSummary from '@/components/Merchant/Portal/Statements/StatementSummary.vue';
import Transactions from '@/components/Merchant/Portal/Statements/Transactions.vue';
import SkeletonLoader from './SkeletonLoader.vue';

export default defineComponent({
  name: 'StatementDetails',
  components: {
    Export,
    MerchantInfo,
    SkeletonLoader,
    StatementSummary,
    Transactions,
  },
  props: {
    statementId: { type: [Number, String], default: '' },
    printOnLoad: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
      documentTitle: '',
    };
  },
  computed: {
    brandObject(): any {
      return this.$store.getters['Ui/getBrandingObject'];
    },
    statement(): StatementDetailsInterface {
      return this.$store.getters['MerchantPortal/getStatementDetails'];
    },
    serviceAddress() {
      return this.brandObject?.footer_object?.footer_address.split(',');
    },
  },
  async created() {
    this.documentTitle = document.title;

    if (this.printOnLoad) {
      window.addEventListener('beforeprint', this.onBeforePrint);
      window.addEventListener('afterprint', this.onAfterPrint);
      this.loading = false;
    }

    await this.$store.dispatch('MerchantPortal/fetchStatementDetails', this.statementId);
    this.loading = false;

    if (this.printOnLoad) {
      this.$nextTick(() => this.printPdf());
    }
  },
  destroy() {
    if (this.printOnLoad) {
      window.removeEventListener('beforeprint', this.onBeforePrint);
      window.removeEventListener('afterprint', this.onAfterPrint);
    }
  },
  methods: {
    onBeforePrint() {
      document.title = 'Merchant Statement';
    },
    onAfterPrint() {
      document.title = this.documentTitle;
      this.$emit('print:close');
    },
    printPdf() {
      window.print();
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';
@import "@/assets/scss/statement-details.scss";

.export-wrapper {
  width: 100%;
  text-align: right;
}

:deep(.v-card-subtitle) {
  opacity: 1;
}

@media print {
  @page {
    size: landscape;
    margin: 0px auto;
  }

  .statement-container {
    > .v-card {
      border: none!important;
    }

    .v-card {
      border-width: 2px;
    }
  }
}
</style>
