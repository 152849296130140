<template>
  <div>
    <v-row justify="center">
      <v-col
        lg="10"
        md="12"
        sm="24"
        class="text-center">
        <custom-button
          data-test="resetBtn"
          variant="text"
          @click="showModal">
          Reset Password
        </custom-button>
      </v-col>
    </v-row>

    <standard-dialog
      v-if="show"
      dialog
      :cta-disabled="disableCta"
      :on-cta="passwordForgetBegin"
      :on-cancel="closeModal"
      :show-actions="!requestComplete"
      :show-cancel-cta="false"
      :show-progress-indicator="processing"
      :show-title="false"
      button-label="Submit">
      <template #body>
        <transition mode="out-in" name="slide">
          <div v-if="requestComplete">
            <p>
              If there is a merchant account associated with your email address,
              you will receive an email to reset your password shortly.
            </p>
          </div>

          <div v-else>
            <p>
              Enter the email address associated with your merchant account and we will send you
              a link to reset your password.
            </p>

            <email-input
              v-model="email"
              :error-messages="errors.email"
              label="Email Address"
              required
              show-icon />

            <standard-error-list
              :show-non-field-errors="showNonFieldErrors"
              :show-detail-errors="true"
              :clear-non-field-errors-on-destroy="true" />
          </div>
        </transition>
      </template>
    </standard-dialog>

    <standard-dialog
      ref="modalNotAssociated"
      :show-actions="false"
      :show-title="false"
      modal-size="sm">
      <template #body>
        <div class="simple-card__body-text">
          <v-row>
            <v-col>
              <p>
                If there is a merchant account associated with your email address,
                you will receive an email to reset your password shortly.
              </p>
            </v-col>
          </v-row>
        </div>
      </template>
    </standard-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import emailAddress from '@/validators/email_address';
import EmailInput from '@/components/Inputs/Email.vue';
import { forgotPasswordBegin } from '@/api/account';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import StandardDialog from '@/components/Dialogs/index.vue';
import StandardErrorList from '@/components/ErrorList.vue';
import SponsorPortalRedirectMixin from '@/mixins/SponsorPortalRedirectMixin';

export default defineComponent({
  name: 'RequestResetPasswordResetModal',
  components: {
    CustomButton,
    EmailInput,
    StandardDialog,
    StandardErrorList,
  },
  mixins: [
    GetErrors,
    GetProcessing,
    SponsorPortalRedirectMixin,
  ],
  data() {
    return {
      email: '',
      showNonFieldErrors: false,
      requestComplete: false,
      show: false,
      showNotAssociated: false,
      disableCta: false,
    };
  },
  watch: {
    email(value: string) {
      this.disableCta = !emailAddress(value);
    },
  },
  methods: {
    async passwordForgetBegin(): Promise<void> {
      this.disableCta = true;
      try {
        await forgotPasswordBegin(this.email);
        this.disableCta = false;
      } catch (error: any) {
        if (this.shouldRedirectToSponsorPortal(error)) {
          this.redirectToSponsorPortal(error.response.data.details.redirect_url);
          return;
        }
        this.$store.dispatch('Ui/setErrors', error.response);
      }
      this.requestComplete = true;
    },
    showModal(): void {
      this.requestComplete = false;

      this.show = true;
    },
    closeModal(): void {
      this.requestComplete = false;

      this.show = false;
      this.email = '';
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
</style>
