const RATE_SHEETS_BASE = [
  'PD-NN-06-04',
  'PD-NN-12-04',
  'FR-0299-03',
  'FR-0599-03',
  'FR-0899-05',
  'PMT-200',
  'PMT-300',
  'FR-RBP-05',
];

const RATE_SHEET_CATEGORIES = {
  deferredInterest: {
    categoryId: 'deferredInterest',
    label: 'Deferred Interest',
    ids: [
      'PD',
      'PZ',
      'Def',
    ],
    dataTest: 'rateTable-deferredInterest',
    plus: false,
    has_fallback: true,
  },
  fixedPayment: {
    categoryId: 'fixedPayment',
    label: 'Fixed Payment',
    ids: [
      'PMT',
    ],
    dataTest: 'rateTable-FixedPayment',
    plus: false,
  },
  fixedRate: {
    categoryId: 'fixedRate',
    label: 'Fixed Rate',
    ids: [
      'FR',
      'TRD',
    ],
    dataTest: 'rateTable-FixedRate',
    plus: false,
  },
  zeroInterest: {
    categoryId: 'zeroInterest',
    label: 'Zero Interest',
    ids: [
      'ZIL',
    ],
    dataTest: 'rateTable-ZeroInterest',
    plus: false,
  },
  brandingPlus: {
    categoryId: 'brandingPlus',
    label: '',
    ids: [
      '',
    ],
    dataTest: 'rateTable-BrandingPlus',
    plus: true,
  },
};

export {
  RATE_SHEETS_BASE,
  RATE_SHEET_CATEGORIES,
};
