<template>
  <div class="v-elevation-1 card-details">
    <div class="card-details__details-rows">
      <div class="card-details__promo">
        <div class="card-details__promo--elements">
          <span>{{ card.promoPayment.promo_header }}</span>
          <span>First {{ card.promoPayment.term }}</span>
        </div>

        <div class="card-details__promo--payments">
          <span>{{ card.promoPayment.payment_header }}: </span>
          <span>{{ card.promoPayment.payment_value }}</span>
        </div>
      </div>

      <div class="card-details__standard">
        <div class="card-details__standard--elements">
          <span>{{ card.standardPayment.header }}</span>
          <span>{{ card.standardPayment.term }}</span>
        </div>

        <div class="card-details__standard--payments">
          <span>{{ card.standardPayment.payment_header }}: </span>
          <span>{{ card.standardPayment.payment_value }}</span>
        </div>
      </div>
    </div>

    <div
      v-private
      class="card-details__apr"
      :class="[{ 'deferred-overwrite': isDeferredOffer }]">
      <span>{{ card.promoApr.header }}: </span>
      <span>{{ card.promoApr.value }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardsCardDetails',

  props: {
    card: { type: Object, default: () => ({}) },
    isDeferredOffer: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/vuetify/elevation.scss";

.card-details {
  display: flex;
  align-items: center;
  width: 100%;
  color: $momnt-navy;

  &__promo, &__standard {
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 0.5rem;

    &--elements {
      text-align: left;
      line-height: 1rem;
      font-size: 0.75rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;

      span:first-child {
        font-weight: 700;
      }
    }

    &--payments {
      display: flex;
      background-color: white;
      border-radius: 8px;
      padding: 0.5rem;

      span:nth-child(2) {
        font-weight: 700;
        margin-left: 0.2rem;
      }
    }
  }

  &__promo {
    background-color: var(--grayscale-color-4);
    border-bottom: 1px solid var(--grayscale-color-3);
  }

  &__standard {
    background-color: var(--grayscale-color-5);
  }

  &__apr {
    background-color: rgba($momnt-soft-teal, 0.5);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0.5rem;
    width: clamp(3.875rem, 16%, 16%);

    span:nth-child(2) {
      font-weight: 700;
    }
  }

  &__details-rows {
    width: 85%;
  }
}

.deferred-overwrite {
  background-color: rgba($momnt-soft-navy, 0.5) !important;
}
</style>
