<template>
  <v-card class="app-status-filter-dialog">
    <v-card-title class="align-center d-flex justify-space-between">
      <strong>Filter By Status</strong>
      <v-icon
        tag="button"
        color="var(--grayscale-color-1)"
        @click="$emit('close')">
        mdi-close
      </v-icon>
    </v-card-title>

    <v-card-text class="pb-0 px-0">
      <v-list density="compact">
        <v-list-item
          v-for="status in statuses"
          :key="status.name"
          density="compact"
          @click="toggleSelected(status.name)">
          <template v-slot:prepend>
            <v-list-item-action start>
              <v-checkbox
                class="no-bg-focus-checkbox"
                color="var(--grayscale-color-1)"
                hide-details
                density="compact"
                focused
                :model-value="isSelected(status.name)" />
            </v-list-item-action>
          </template>

          <v-list-item-title>
            <status-chip :status="status.name" />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <custom-button
        full-width
        size="small"
        :disabled="!hasChanges"
        @click="apply">
        Apply
      </custom-button>

      <custom-button
        full-width
        class="ml-0 mt-6"
        variant="link"
        size="small"
        :disabled="!internalSelected.length"
        @click="clearSelection">
        Clear Selection
      </custom-button>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue';
import { useDisplayStatus } from '@/composables/useConsumerApplicationDisplayStatus';
import { useConsumerActivity } from '@/composables/useConsumerActivity';
import { DisplayStatus } from '@/interfaces/ConsumerActivity';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StatusChip from './StatusChip.vue';

const emit = defineEmits(['close']);

const { filters } = useConsumerActivity();
const { statuses } = useDisplayStatus();

const internalSelected = ref<DisplayStatus[]>([]);

const hasChanges = computed(() => {
  return internalSelected.value.length !== filters.display_status.length
        || internalSelected.value.some(it => !filters.display_status.includes(it));
});

const isSelected = (item: DisplayStatus) => internalSelected.value.indexOf(item) !== -1;

const toggleSelected = (item: DisplayStatus) => {
  const selected = [...internalSelected.value];

  if (selected.includes(item)) {
    selected.splice(selected.indexOf(item), 1);
  } else {
    selected.push(item);
  }

  internalSelected.value = selected;
};

const clearSelection = () => {
  internalSelected.value = [];
};

onBeforeMount(() => {
  internalSelected.value = [...filters.display_status];
});

const apply = () => {
  filters.display_status = [...internalSelected.value];
  emit('close');
};
</script>

<style lang="scss" scoped>
:deep() {
  .v-card {
    &-title {
      background-color: var(--grayscale-color-5);
      color: var(--grayscale-color-1);
      font-size: 0.875rem !important;
      padding: 1rem !important;
      line-height: 100%;
      justify-content: space-between;
    }

    &-actions {
      background-color: var(--grayscale-color-5);
      flex-direction: column;
      padding: 1rem;
    }
  }

  .v-list-item {
    &-title {
      padding: 0.4rem 0;
    }

    .v-input {
      margin-bottom: 0;
    }

    .v-selection-control__input > .v-icon {
      opacity: 1;
    }
  }
}
</style>
