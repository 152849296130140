<template>
  <li
    :class="{ 'tab-navigation-btn--active': isActive() }"
    class="tab-navigation-btn"
    @click="update">
    <slot />
  </li>
</template>

<script lang="ts">
import { defineComponent, inject, Ref } from 'vue';

export default defineComponent({
  props: {
    value: { type: String, required: true },
  },

  setup(props) {
    const selectedTab = inject('selectedTab') as Ref<string>;
    const updateSelectedTab = inject('updateSelectedTab') as (tab: string) => void;

    const update = () => {
      updateSelectedTab(props.value);
    };

    const isActive = () => {
      return selectedTab.value === props.value;
    };

    return { update, isActive };
  },
});
</script>

<style lang="scss">
.tab-navigation-btn {
  background-color: transparent;
  border: 2px solid transparent; // To avoid warping 2px when active
  color: var(--primary-color);
  cursor: pointer;
  padding: 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  border-radius: 1rem;
  transition: all 0.2s;
  line-height: 100%;
  user-select: none;

  &:hover {
    background-color: white;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.20);
  }

  &--active {
    background-color: white;
    color: var(--grayscale-color-1);
    border: 2px solid var(--grayscale-color-1);
  }
}
</style>
