<template>
  <div class="fs-unmask">
    <div
      v-for="lender in lenders"
      :key="lender.name"
      class="mb-3">
      <p v-fs-exclude class="mb-1">
        {{ lender.name }}
      </p>
      <p v-fs-exclude class="mb-1">
        {{ lender.address.address_1 }}
      </p>
      <p v-fs-exclude>
        {{ lender.address.city }}, {{ lender.address.state }} {{ lender.address.zip_code }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { LenderAddressInterface } from '@/interfaces/consumer/LenderAddressInterface';

export default defineComponent({
  name: 'LenderList',
  props: {
    lenders: { default: [], type: Array<LenderAddressInterface> },
  },
});
</script>
