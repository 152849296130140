<template>
  <standard-wizard-message data-test="lockedMsg">
    <template #body>
      <p class="text-left">
        For security reasons we have locked your account. Please email
        <a :href="supportMailTo">{{ supportEmail }}</a> for assistance.
      </p>
    </template>
  </standard-wizard-message>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import SupportEmailMixin from '@/mixins/SupportEmailMixin';

export default defineComponent({
  name: 'LockedAccount',
  components: {
    StandardWizardMessage,
  },
  mixins: [
    SupportEmailMixin,
  ],
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_custom-transitions";
</style>
