import { defineComponent } from 'vue';
import { getDocumentVerificationStatusDetail } from '@/api/consumer';
import SocureDocVStatuses from '@/enums/SocureDocVStatuses';

export default defineComponent({
  name: 'SocureDocVMixin',

  data() {
    return {
      nextStep: 0,
      interval: 5 * 1000, // Initial interval to be 5 seconds
      intervalId: null as ReturnType<typeof setInterval> | null,
      timeoutId: null as ReturnType<typeof setTimeout> | null,
    };
  },

  computed: {
    applicationId(): string {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },
  },

  beforeUnmount() {
    this.stopPolling();
  },

  methods: {
    async getStatus() {
      try {
        const response: any = await getDocumentVerificationStatusDetail(this.applicationId);

        switch (response.data.status_detail) {
          case SocureDocVStatuses.VERIFICATION_COMPLETE:
          case SocureDocVStatuses.SESSION_COMPLETE:
            this.stopPolling();
            this.$store.commit('Consumer/setLoanApplyWizardStep', this.nextStep);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    },

    startPolling(nextStep: number) {
      this.nextStep = nextStep;
      this.intervalId = setInterval(this.getStatus, this.interval);

      this.timeoutId = setTimeout(() => {
        this.stopPolling();
        this.$router.push({ name: 'error-page' });
      }, 15 * 60 * 1000);
    },

    stopPolling() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    },
  },
});
