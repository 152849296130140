<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    z-index="201"
    max-width="15.3125rem">
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
        class="filter-activator"
        :class="{ 'filter-activator--filtered': hasStatusFilter }">
        <span>Status</span>
        <v-icon>mdi-filter-variant</v-icon>
      </div>
    </template>

    <status-filter-body @close="showMenu = false" />
  </v-menu>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useConsumerActivity } from '@/composables/useConsumerActivity';
import StatusFilterBody from './StatusFilterBody.vue';

const showMenu = ref(false);

const { filters } = useConsumerActivity();
const hasStatusFilter = computed(() => !!filters.display_status.length);
</script>

<style lang="scss" scoped>
.filter-activator {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem 0.5rem;
  // Negative margin to offset padding added
  // So it aligns with the other headers
  margin-left: -0.5rem;
  width: fit-content;
  cursor: pointer;

  &--filtered {
    background-color: var(--grayscale-color-4);
    border-radius: 0.25rem;
  }
}
</style>
