<template>
  <div class="merchant-portal-page--shadow merchant-portal-page--statements`">
    <v-card class="section-card">
      <v-card-title class="section-header">
        <h3 class="subheader">
          Statements
        </h3>
      </v-card-title>
      <v-data-table
        class="minimal-pagination"
        :headers="tableHeaders"
        :items="statementSummaries"
        :items-per-page="25"
        item-value="statement_number"
        :single-expand="true"
        item-key="id"
        show-expand>
        <!-- Data rows -->
        <template v-slot:item.statement_number="{ item }">
          {{ item.statement_number }}
        </template>
        <template v-slot:item.total_credit_limit_approved="{ item }">
          <span v-private>{{ CurrencyFormatLong(item.total_credit_limit_approved) }}</span>
        </template>
        <template v-slot:item.net_purchases="{ item }">
          <span v-private>{{ CurrencyFormatLong(item.net_purchases) }}</span>
        </template>
        <template v-slot:item.net_merchant_fees="{ item }">
          <span v-private>{{ CurrencyFormatLong(item.net_merchant_fees) }}</span>
        </template>
        <template v-slot:item.total_net_merchant_receipt="{ item }">
          <span v-private>{{ CurrencyFormatLong(item.total_net_merchant_receipt) }}</span>
        </template>
        <!-- Expand row -->
        <template v-slot:expanded-row="{ columns, item }">
          <tr class="expanded-row">
            <td :colspan="columns.length">
              <export :statement-id="item.id" class="mb-4 mr-4" />
              <custom-button
                size="small"
                variant="secondary"
                data-test="openStatementsBtn"
                target="_blank"
                rel="noopener noreferrer"
                :to="getDetailsPath(item.id)">
                Open Statement Detail
              </custom-button>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { StatementSummaryInterface } from '@/interfaces/statements/StatementSummaryInterface';
import Export from '@/components/Merchant/Portal/Statements/Export.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import getHeaders from '@/utils/GetTableHeaders';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConsumerStatements',
  components: {
    Export,
    CustomButton,
  },
  data() {
    const tableHeaders: Array<TableHeaderInterface> = getHeaders(
      [
        { title: 'Statement Number', value: 'statement_number' },
        { title: 'Applications Submitted', value: 'applications_submitted', align: 'end' },
        { title: 'Applications Submitted: Approved', value: 'applications_approved_accepted', align: 'end' },
        { title: 'Total Credit Limit Approved', value: 'total_credit_limit_approved', align: 'end' },
        { title: 'Net Purchases', value: 'net_purchases', align: 'end' },
        { title: 'Merchant Fees', value: 'net_merchant_fees', align: 'end' },
        { title: 'Amount Paid to You', value: 'total_net_merchant_receipt', align: 'end' },
        { title: '', value: 'expanded-item', align: 'center' },
      ],
    );

    return {
      selectedStatementId: '',
      addStatementDetailsToDOM: false,
      loadingPdfId: 0,
      loadingExcelId: 0,
      loadingCsvId: 0,
      tableHeaders,
    };
  },
  computed: {
    statementSummaries(): Array<StatementSummaryInterface> {
      return this.$store.getters['MerchantPortal/getStatementSummary'];
    },
  },
  created() {
    this.fetchStatementSummary();
  },
  methods: {
    CurrencyFormatLong,
    async fetchStatementSummary() {
      await this.$store.dispatch('MerchantPortal/fetchStatementSummary');
    },
    getDetailsPath(id: number) {
      const config: any = {
        name: 'merchant-statement-details',
        params: { id },
      };
      return this.$router.resolve(config).href;
    },
    expandAndSetId(expand: any, item: any) {
      expand(item);
      this.selectedStatementId = item.id;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";
@import '@/assets/scss/portal-section';

.merchant-portal--statements {
  @include data-table-card-full-size;
  margin-bottom: 2rem;
}

.merchant-portal-page {
  &--statements {
    @include box-shadow-default;
  }
}

:deep(.v-table tr.expanded-row:hover) {
  background: var(--grayscale-color-5) !important;
}

:deep(.v-theme--light.v-table > .v-table__wrapper > table > thead > tr > th) {
  color: var(--grayscale-color-1);
  font-weight: 700;
}

:deep() {
  .v-table > .v-table__wrapper > table > tbody > tr {
    > td {
      color: var(--grayscale-color-1);
    }

    &:has(+ .expanded-row) {
      background: var(--grayscale-color-5);

      > td {
        border-top: 1px solid var(--grayscale-color-1);
        border-bottom: none;
      }
    }

    &.expanded-row {
      text-align: right;
      background-color: var(--grayscale-color-5);

      > td {
        padding-top: 1rem;
        border-bottom: 1px solid var(--grayscale-color-1);
      }
    }
  }
}

:deep(.v-data-footer__select) {
  visibility: hidden;
}

.custom-button.v-btn.v-btn--outlined {
  text-decoration: none !important;
}

.section-header .section-header__title {
  color: var(--grayscale-color-1);
}
</style>
