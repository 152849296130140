import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, _mergeProps(_ctx.$attrs, {
    disabled: _ctx.disabled,
    autocomplete: "disabled",
    "data-test": _ctx.dataTestAttr,
    label: _ctx.label,
    variant: _ctx.variantAttr,
    required: _ctx.required,
    rules: _ctx.rules || _ctx.validationRules,
    "validate-on": "blur"
  }), null, 16, ["disabled", "data-test", "label", "variant", "required", "rules"]))
}