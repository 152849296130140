import { OtpRegisterRequestDataInterface } from '@/interfaces/otp/OtpRegisterRequestDataInterface';
import { BASE_PATH, instance, rawClient } from './index';

// eslint-disable-next-line import/prefer-default-export
export function register(payload: OtpRegisterRequestDataInterface) {
  const { noTokenRegister } = payload;

  if (noTokenRegister) {
    return rawClient.post(`${BASE_PATH}/otp/register/`, payload);
  }

  return instance.post(`${BASE_PATH}/otp/register/`, payload);
}
