import { OwnerDataInterface } from '@/interfaces/merchantOnboardingWizard/OwnerDataInterface';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { MerchantOnboardingStateInterface } from '@/interfaces/states/MerchantOnboardingStateInterface';
import { OnboardingUserData } from '@/interfaces/merchantOnboardingWizard/OnboardingUserData';
import filter from 'lodash/filter';

export function setConsent(state: MerchantOnboardingStateInterface, payload: boolean) {
  state.consent = payload;
}

export function setProcessingApplication(state: MerchantOnboardingStateInterface, payload: boolean) {
  state.processingApplication = payload;
}

export function setApplicationApproved(state: MerchantOnboardingStateInterface, payload: boolean) {
  state.applicationApproved = payload;
}

export function setApplicationStatus(state: MerchantOnboardingStateInterface, payload: string) {
  state.applicationStatus = payload;
}

export function setApplicationWaitingApproval(state: MerchantOnboardingStateInterface, payload: boolean) {
  state.applicationWaitingApproval = payload;
}

export function setPrograms(state: MerchantOnboardingStateInterface, programs: any) {
  state.programs = programs;
}

export function setSubPrograms(state: MerchantOnboardingStateInterface, subPrograms: any) {
  state.subPrograms = subPrograms;
}

export function setSelectedPrograms(state: MerchantOnboardingStateInterface, selectedPrograms: any) {
  state.selectedPrograms = selectedPrograms;
}

export function setCombinedOwners(state: MerchantOnboardingStateInterface, payload: Array<OwnerDataInterface>) {
  state.combinedOwners = payload;
}

export function setSingleOwner(state: MerchantOnboardingStateInterface, payload: any) {
  const { owner, index } = payload;

  const ownerIndex: number = index && index > -1 ? index : 0;

  state.combinedOwners[ownerIndex] = owner;
}

export function setDeleteOwner(state: MerchantOnboardingStateInterface, index: number) {
  state.combinedOwners = filter(
    state.combinedOwners,
    (_: OwnerDataInterface, ownersIndex: number) => ownersIndex !== index,
  );
}

export function setRateSheets(state: MerchantOnboardingStateInterface, payload: Array<RateSheetData>) {
  state.rateSheets = payload;
}

export function setUserVerifyData(state: MerchantOnboardingStateInterface, payload: OnboardingUserData) {
  state.onboardingUserData = { ...payload };
}

export function setMerchantOnboardingWizardStep(state: MerchantOnboardingStateInterface, step: number) {
  state.merchantOnboardingWizardStep = step;
}

export function setMerchantOnboardingWizardSubStep(state: MerchantOnboardingStateInterface, step: number) {
  state.merchantOnboardingWizardSubStep = step;
}

export function setAllAttributes(state: MerchantOnboardingStateInterface, payload: any) {
  state.allAttributes = {
    ...state.allAttributes,
    ...payload,
  };
}

export function setMerchantReEntryData(state: MerchantOnboardingStateInterface, payload: any) {
  state.merchantReEntryData = payload;
  if (!Object.keys(payload).length) return;

  state.allAttributes = {
    legalBusinessName: payload.merchant?.name,
    doingBusinessAs: payload.merchant?.dba,
    federalTaxId: payload.sensitive?.federal_tax_id,
    ssn: payload.sensitive?.ssn,
    websiteUrl: payload.merchant?.merchant_attributes?.website_url,
    businessStartDate: payload.merchant?.merchant_attributes?.business_start_date,
    annualSalesRevenue: payload.merchant?.merchant_attributes?.annual_sales_revenue,
    averageCustomerSpend: payload.merchant?.merchant_attributes?.average_customer_spend,
    offersFinancing: payload.merchant?.merchant_attributes?.offers_financing,
    financingVolume: payload.merchant?.merchant_attributes?.financing_volume,
    businessStructure: payload.merchant?.merchant_attributes?.business_structure,
    paymentTypesAccepted: payload.merchant?.merchant_attributes?.payment_types_accepted,
    program: payload.merchant?.program,
    merchant_phone: payload.merchant?.business_phone_number,
  };

  if (payload.merchantowner_set?.length) {
    state.combinedOwners = payload.merchantowner_set.map((owner: any) => ({
      isAddressValidated: true,
      firstName: owner.first_name,
      middleName: owner.middle_name,
      lastName: owner.last_name,
      address1: owner.address?.address_1,
      address2: owner.address?.address_2,
      city: owner.address?.city,
      state: owner.address?.state,
      zipCode: owner.address?.zip_code,
      email: owner.email,
      ssn: owner.sensitive_set?.ssn,
      ownerPercentage: owner.owner_percentage,
      phoneNumber: owner.phone_number,
      uid: owner.uid,
      dob: owner.sensitive_set?.dob,
      security_pin: owner.sensitive_set?.security_pin,
      id: owner.id,
    }));
  }
}

export function clearOwners(state: MerchantOnboardingStateInterface) {
  state.combinedOwners = [];
}

export function setRedirectUrl(state: MerchantOnboardingStateInterface, payload: any) {
  state.redirectUrl = payload;
}

export function setVerifyFormsOpen(state: MerchantOnboardingStateInterface, payload: object) {
  state.verifyFormsOpen = payload;
}

export function setOwnerSaved(state: MerchantOnboardingStateInterface, payload: boolean) {
  state.ownerIsSaved = payload;
}

export function setShowMobStepper(state: MerchantOnboardingStateInterface, payload: boolean) {
  state.showMobStepper = payload;
}

export function setPassedPhoneVerification(state: MerchantOnboardingStateInterface, payload: boolean) {
  state.passedPhoneVerification = payload;
}

export function setShowBackButtonPreventModal(state: MerchantOnboardingStateInterface, payload: boolean) {
  state.showBackButtonPreventModal = payload;
}
