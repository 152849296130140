<template>
  <div class="aan-reasons fs-unmask">
    <p>Key factors that adversely affected your credit score:</p>

    <ul class="aan-reasons__list">
      <li
        v-for="(reason, index) in reasons"
        :key="index">
        {{ reason }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'AANReasons',
  props: {
    reasons: { default: () => [], type: Array as PropType<string[]> },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/standard-aan";
</style>
