import { LoanApplicationStateInterface } from '@/interfaces/states/LoanApplicationStateInterface';
import ResetModuleState from '@/utils/store/ResetModuleState';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const initialState = (): LoanApplicationStateInterface => ({
  email: '',
  phone: '',
  merchantId: '',
  loanApplicationWizardData: {},
  loanApplicationWizardStep: 1,
});
export default {
  namespaced: true as true,
  state: initialState() as LoanApplicationStateInterface,
  getters,
  mutations: {
    ...mutations,
    resetModuleState(state: LoanApplicationStateInterface) {
      ResetModuleState(state, initialState());
    },
  },
  actions,
};
