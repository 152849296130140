import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "fs-unmask" }
const _hoisted_2 = { class: "mb-1" }
const _hoisted_3 = { class: "mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_fs_exclude = _resolveDirective("fs-exclude")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lenders, (lender) => {
      return (_openBlock(), _createElementBlock("div", {
        key: lender.name,
        class: "mb-3"
      }, [
        _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(lender.name), 1)
        ])), [
          [_directive_fs_exclude]
        ]),
        _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_3, [
          _createTextVNode(_toDisplayString(lender.address.address_1), 1)
        ])), [
          [_directive_fs_exclude]
        ]),
        _withDirectives((_openBlock(), _createElementBlock("p", null, [
          _createTextVNode(_toDisplayString(lender.address.city) + ", " + _toDisplayString(lender.address.state) + " " + _toDisplayString(lender.address.zip_code), 1)
        ])), [
          [_directive_fs_exclude]
        ])
      ]))
    }), 128))
  ]))
}