<template>
  <div
    ref="widgetContentContainer"
    class="standard-wizard standard-wizard--merchant-begin-onboarding"
    data-test="wizardModal">
    <v-stepper
      v-model="wizardStep"
      flat
      :class="{ 'stepper-class': wizardStep === 3 }"
      outlined>
      <div :class="{ 'close-btn-wrap': true, 'icon-order': wizardStep === 3 }">
        <v-btn
          class="icon-button rounded-circle"
          data-test="closeBtn"
          aria-label="Close"
          icon
          size="x-small"
          variant="text"
          @click="onCancelAction">
          <v-icon size="1.5rem">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-stepper-window :model-value="wizardStep" :class="{ step3: wizardStep === 3 }">
        <v-stepper-window-item :value="1">
          <merchant-begin-onboarding-wizard-step-one :widget="widget" @nextStep="wizardStep += 1" />
        </v-stepper-window-item>

        <v-stepper-window-item :value="2">
          <merchant-begin-onboarding-wizard-step-two @nextStep="wizardStep += 1" />
        </v-stepper-window-item>

        <v-stepper-window-item :value="3">
          <merchant-begin-onboarding-wizard-step-three />
        </v-stepper-window-item>
      </v-stepper-window>
    </v-stepper>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import WidgetsMixin from '@/mixins/Widgets/WidgetsMixin';
import MerchantBeginOnboardingWizardStepOne from '@/components/Merchant/BeginOnboardingWizard/StepOne.vue';
import MerchantBeginOnboardingWizardStepTwo from '@/components/Merchant/BeginOnboardingWizard/StepTwo.vue';
import MerchantBeginOnboardingWizardStepThree from '@/components/Merchant/BeginOnboardingWizard/StepThree.vue';

export default defineComponent({
  name: 'BMOBWizard',

  components: {
    MerchantBeginOnboardingWizardStepOne,
    MerchantBeginOnboardingWizardStepTwo,
    MerchantBeginOnboardingWizardStepThree,
  },

  mixins: [WidgetsMixin],

  props: {
    onClose: { type: Function, default: () => () => null },
    widget: { type: Boolean, default: false },
  },

  data() {
    return {
      wizardStep: 1,
    };
  },

  async created() {
    await this.$store.dispatch('resetState');
  },

  methods: {
    onCancelAction() {
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_custom-transitions";
@import "@/assets/scss/merchant-begin-onboarding-wizard";

.v-window {
  margin: 0 1rem 1rem 1rem;

  &.step3 {
    margin-right: 0;
  }
}

.close-btn-wrap {
  display: flex;
  justify-content: flex-end;

  .icon-button {
    margin: 0.5rem;
    background-color: #fff;
    color: var(--grayscale-color-1);
  }

  .icon-button::before {
    background-color: transparent;
  }
}

.stepper-class {
  display: flex;
}

.icon-order {
  order: 1;
  height: 3.125rem;
  margin-top: 6px;
}
</style>
