<template>
  <standard-dialog
    :dialog="dialog"
    :show-progress-indicator="processing"
    :hide-header="true"
    :persistent="true">
    <template #body>
      <div class="simple-card__body-text">
        <v-row>
          <v-col>
            <div>
              <div class="body-header">
                <span
                  class="text-capitalize"
                  data-test="confModalHeaderText">
                  {{ confirmationModalHeaderText }}
                </span>
              </div>
              <div
                id="modalAddressBody"
                class="body-text-data"
                :class="{
                  'fs-exclude': isFsExcluded,
                  'fs-unmask': !isFsExcluded,
                }"
                data-test="modalAddressBodyLabel">
                <span v-if="address.address1" v-private>
                  {{ address.address1 }}
                  <br>
                </span>

                <span v-if="address.address2" v-private>
                  {{ address.address2 }}
                  <br>
                </span>

                <span v-if="address.city" v-private>
                  {{ address.city }},
                </span>

                <span v-if="address.state" v-private>
                  {{ address.state }},
                </span>

                <span v-if="address.zipCode" v-private>
                  {{ address.zipCode }}
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
    <template #actions>
      <div class="button-wrapper">
        <custom-button
          full-width
          data-test="confirmBtn"
          :disabled="processing || disabled"
          @click="onCta">
          Yes, this is correct
        </custom-button>
        <custom-button
          full-width
          variant="secondary"
          class="cancel-btn"
          data-test="cancelBtn"
          :disabled="processing || disabled"
          @click="onCancel">
          No, I need to edit
        </custom-button>
      </div>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { AddressDataInterface } from '@/interfaces/addressValidation/AddressDataInterface';
import GetProcessing from '@/mixins/GetProcessing';

export default defineComponent({
  name: 'ConfirmationModal',

  components: {
    StandardDialog,
    CustomButton,
  },

  mixins: [GetProcessing],

  props: {
    dialog: { type: Boolean, default: false },
    confirmationModalHeaderText: { type: String, default: 'Please Confirm Your Address' },
    address: { type: Object as PropType<AddressDataInterface>, required: true },
    isFsExcluded: { type: Boolean, default: true },
  },

  data: () => ({
    disabled: false,
  }),

  methods: {
    onCta() {
      this.disabled = true;
      this.$emit('confirmed');
    },

    onCancel() {
      this.$emit('declined');
    },
  },
});
</script>

<style lang="scss" scoped>
.button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.button-wrapper button.cancel-btn {
  margin-inline-start: 0 !important; // Vuetify override
  margin-top: 2rem;
  background-color: #fff !important;
}

:deep(.dialog-wrap.dialog-body-wrap) {
  padding-top: 0 !important;
}

.body-header {
  font-size: 1.125rem;
  font-weight: bold;
}

.body-text-data {
  margin: 2rem 0 1rem;
  color: var(--grayscale-color-1);
  font-size: 1rem !important;
}
</style>
