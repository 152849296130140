import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15f76edd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fs-unmask" }
const _hoisted_2 = { class: "otp-message" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_phone_input = _resolveComponent("phone-input")!
  const _component_standard_dialog = _resolveComponent("standard-dialog")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, { justify: "center" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_custom_button, {
              disabled: _ctx.disableResend,
              "data-test": "resendBtn",
              "full-width": "",
              variant: "text",
              onClick: _ctx.showModal
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.otpMessage), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_standard_dialog, {
      dialog: _ctx.show,
      "cta-disabled": _ctx.disableCta,
      "on-cta": _ctx.isReEntry ? _ctx.closeModal : _ctx.resendOtp,
      "on-cancel": _ctx.closeModal,
      "show-actions": !_ctx.requestSuccessful,
      "show-cancel-cta": false,
      "show-progress-indicator": !_ctx.isReEntry ? _ctx.processing : false,
      "show-title": false,
      "button-label": _ctx.isReEntry ? 'OK' : 'Send',
      "consumer-re-entry": _ctx.isReEntry,
      "data-test": "resendOtpModal"
    }, {
      body: _withCtx(() => [
        (_ctx.isReEntry)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
              _createTextVNode(" We resent the code to "),
              _withDirectives((_openBlock(), _createElementBlock("span", null, [
                _createTextVNode(_toDisplayString(_ctx.phone_number), 1)
              ])), [
                [_directive_private]
              ]),
              _createTextVNode(". ")
            ]))
          : (_openBlock(), _createElementBlock("p", _hoisted_4, " This is the mobile phone number you entered earlier. If this is correct press send. If it is not correct please make any necessary changes and press send. ")),
        (!_ctx.isReEntry)
          ? (_openBlock(), _createBlock(_component_phone_input, {
              key: 2,
              modelValue: _ctx.phone_number,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone_number) = $event)),
              disabled: _ctx.processing,
              "error-messages": _ctx.errors.mobile,
              required: "",
              "data-test": "mobileInput",
              label: "Mobile Number"
            }, null, 8, ["modelValue", "disabled", "error-messages"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["dialog", "cta-disabled", "on-cta", "on-cancel", "show-actions", "show-progress-indicator", "button-label", "consumer-re-entry"])
  ]))
}