import { useStore } from 'vuex';
import { computed } from 'vue';
import { MerchantPortalAccessEnum } from '@/enums/Merchant/PortalEnums';

export default function useMPAccess() {
  const store = useStore();

  const merchantPortalAccess = computed(() => store.getters['MerchantPortal/getMerchantPortalAccess']);

  function hasAccess(access: MerchantPortalAccessEnum) {
    if (merchantPortalAccess.value === null) return false;
    return !merchantPortalAccess.value.includes(access);
  }

  const hideNewConsumerInvitation = computed(() => hasAccess(MerchantPortalAccessEnum.NEW_CONSUMER_INVITATION));
  const hideConsumerChargeRequest = computed(() => hasAccess(MerchantPortalAccessEnum.CONSUMER_CHARGE_REQUESTS));
  const hideConsumerRefunds = computed(() => hasAccess(MerchantPortalAccessEnum.CONSUMER_REFUNDS));
  const hideAddNewUser = computed(() => hasAccess(MerchantPortalAccessEnum.ADD_NEW_USER));
  const hideUserTableActions = computed(() => hasAccess(MerchantPortalAccessEnum.USER_TABLE_ACTIONS));

  return {
    merchantPortalAccess,
    hideNewConsumerInvitation,
    hideConsumerChargeRequest,
    hideConsumerRefunds,
    hideAddNewUser,
    hideUserTableActions,
  };
}
