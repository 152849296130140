<template>
  <div>
    <standard-dialog
      :dialog="showFormDialog"
      :on-cta="onSave"
      :cta-disabled="buttonDisabled"
      :on-cancel="() => $emit('close')"
      :width="formModalWidth"
      button-label="Save">
      <template #title>
        <h2>{{ internalUser.id ? 'Edit' : 'Add' }} User</h2>
      </template>

      <template #body>
        <div class="user-locations-form">
          <div :class="{ 'corporate-view': isCorporateAdmin }">
            <text-input
              v-model="formData.first_name"
              :disabled="processing"
              required
              :error-messages="errors.first_name"
              label="First Name" />

            <text-input
              v-model="formData.last_name"
              :disabled="processing"
              required
              :error-messages="errors.last_name"
              label="Last Name" />

            <phone-input
              v-model="formData.phone_number"
              data-test="phoneInput"
              :disabled="processing"
              required
              :error-messages="errors.phone_number"
              label="Mobile Phone" />

            <email-input
              v-model="formData.email"
              :class="{ 'mb-0': isSalesManager }"
              :disabled="processing"
              required
              :email-error="errors.email"
              label="Email Address" />

            <template v-if="!isSalesManager">
              <select-input
                v-model="formData.role"
                class="mb-0"
                :disabled="userTypeInputDisabled"
                :items="rolesDropdownOptions"
                :error-messages="errors.role"
                item-title="name"
                item-value="value"
                label="User Role" />
            </template>

            <template v-else>
              <strong class="static-label">User role:</strong>
              <p>Representative</p>
            </template>

            <template v-if="!isCorporateAdmin && formData.locations.length">
              <strong class="static-label">Location:</strong>
              <p>{{ getLocationName(formData.locations[0].name) }}</p>
            </template>
          </div>

          <div v-if="isCorporateAdmin" class="location-form">
            <autocomplete-input
              v-model="locationValue"
              :attach="false"
              :items="availableLocations"
              item-title="name"
              return-object
              hide-details
              :menu-props="{ maxWidth: 500 }"
              placeholder="Assign Locations"
              @update:model-value="onLocationInput" />

            <v-chip
              v-for="location in formData.locations"
              :key="location.id"
              :closable="formData.locations.length > 1"
              close-icon="mdi-close"
              @click:close="removeLocation(location)">
              {{ getLocationName(location.name) }}
            </v-chip>
          </div>
        </div>
      </template>
    </standard-dialog>

    <existing-higher-role-modal
      v-if="!!existingHigherRole"
      :email="formData.email"
      :existing-higher-role="existingHigherRole"
      @close="$emit('close')" />

    <invite-existing-user-modal
      v-if="invitingExistingUserModal"
      :email="formData.email"
      :processing="processing"
      @continue="saveUser(true)"
      @close="$emit('close')" />

    <post-create-modal
      v-if="postCreateModal"
      :email="formData.email"
      @ok="$emit('save')" />
  </div>
</template>

<script lang="ts">
import { AxiosError } from 'axios';
import { defineComponent } from 'vue';
import {
  createUserAtThisLocation,
  createUserAtAllLocations,
  getUserAtThisLocation,
  patchUserAtThisLocation,
  patchUserAtAllLocations,
  userLocationCheck,
} from '@/api/merchant';
import StandardDialog from '@/components/Dialogs/index.vue';
import AutocompleteInput from '@/components/Inputs/Autocomplete.vue';
import MerchantPermissionsMixin from '@/mixins/Auth/MerchantPermissionsMixin';
import TextInput from '@/components/Inputs/Text.vue';
import PhoneInput from '@/components/Inputs/Phone.vue';
import EmailInput from '@/components/Inputs/Email.vue';
import SelectInput from '@/components/Inputs/Select.vue';
import MerchantUserRoles from '@/constants/MerchantUserRoles';
import UsersRolesEnum from '@/enums/UsersRolesEnum';
import {
  CreateUserPayload,
  PatchUserPayload,
  UserAtThisLocationDetail,
  UserLocation,
} from '@/interfaces/merchantPortal/UserInterface';
import { isHigherRole } from '@/utils/MerchantRoleUtils';
import ExistingHigherRoleModal
  from '@/components/Merchant/Portal/Admin/UserManagement/MultiLocation/ExistingHigherRoleModal.vue';
import InviteExistingUserModal
  from '@/components/Merchant/Portal/Admin/UserManagement/MultiLocation/InviteExistingUserModal.vue';
import PostCreateModal
  from '@/components/Merchant/Portal/Admin/UserManagement/MultiLocation/PostCreateModal.vue';
import isValidEmailAddress from '@/validators/email_address';

const LOCATION_NAME_LIMIT = 30;

interface FormData {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  role: string | null;
  locations: UserLocation[];
}

export default defineComponent({
  name: 'UserForm',

  components: {
    AutocompleteInput,
    EmailInput,
    StandardDialog,
    TextInput,
    SelectInput,
    PhoneInput,
    ExistingHigherRoleModal,
    InviteExistingUserModal,
    PostCreateModal,
  },

  mixins: [MerchantPermissionsMixin],

  props: {
    userId: { type: Number, default: 0 },
    emailError: { type: String, default: '' },
    allUsersTable: { type: Boolean, default: false },
  },

  data() {
    return {
      locationValue: null,
      internalUser: {} as UserAtThisLocationDetail,
      formData: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        role: null,
        locations: [],
      } as FormData,
      errors: {} as { [key: string]: string },
      processing: false,
      rolesDropdownOptions: [
        {
          value: UsersRolesEnum.SALES_REP,
          name: MerchantUserRoles[UsersRolesEnum.SALES_REP],
        },
        {
          value: UsersRolesEnum.SALES_MANAGER,
          name: MerchantUserRoles[UsersRolesEnum.SALES_MANAGER],
        },
        {
          value: UsersRolesEnum.ADMIN,
          name: MerchantUserRoles[UsersRolesEnum.ADMIN],
        },
      ] as { name: string; value: string }[],
      existingHigherRole: '',
      invitingExistingUserModal: false,
      postCreateModal: false,
    };
  },

  computed: {
    formModalWidth() {
      if (window.innerWidth > 720) return 'fit-content';
      return this.isCorporateAdmin ? '90%' : '420px';
    },
    showFormDialog() {
      return !(this.existingHigherRole || this.invitingExistingUserModal || this.postCreateModal);
    },
    merchantUuid(): string {
      return this.$store.getters['MerchantPortal/getMerchantUuid'];
    },
    loggedInUserId(): number {
      return this.$store.getters['Auth/getUserId'];
    },
    buttonDisabled() {
      return !!(this.processing || !this.formValid || !Object.keys(this.formDiffs).length);
    },
    userTypeInputDisabled() {
      return this.processing || !!(
        this.internalUser.user_id && this.internalUser.user_id === this.loggedInUserId
      );
    },
    userLocationIds() {
      if (!this.internalUser.locations) return [];

      return this.internalUser.locations.map((it: UserLocation) => it.id);
    },
    formValid() {
      let valid = !!(this.formData.first_name
        && this.formData.last_name
        && this.formData.phone_number
        && this.formData.email && isValidEmailAddress(this.formData.email)
        && this.formData.role
      );
      if (this.isCorporateAdmin) {
        valid = valid && this.formData.locations.length > 0;
      }
      return valid;
    },
    formDiffs() {
      const diffs: Record<string, string | null> = {};

      if (this.formData.first_name !== this.internalUser.first_name) {
        diffs.first_name = this.formData.first_name;
      }
      if (this.formData.last_name !== this.internalUser.last_name) {
        diffs.last_name = this.formData.last_name;
      }
      if (this.formData.phone_number !== this.internalUser.phone_number?.phone_number) {
        diffs.phone_number = this.formData.phone_number;
      }
      if (this.formData.email !== this.internalUser.email) {
        diffs.email = this.formData.email;
      }
      if (this.formData.role !== this.internalUser.role?.name) {
        diffs.role = this.formData.role;
      }

      // Only corporate admin roles can edit locations
      if (this.isCorporateAdmin) {
        if (!this.internalUser.locations) return diffs;

        const initialLocationIds = this.internalUser.locations.map((it: UserLocation) => it.id);
        const formLocationIds = this.formData.locations.map((it: UserLocation) => it.id);
        const diffsLocations: UserLocation[] = [];

        this.formData.locations.forEach((location: UserLocation) => {
          if (!initialLocationIds.includes(location.id)) {
            diffsLocations.push(location);
          }
        });
        this.internalUser.locations.forEach((location: UserLocation) => {
          if (!formLocationIds.includes(location.id)) {
            diffsLocations.push(location);
          }
        });

        if (diffsLocations.length) {
          diffs.locations = diffsLocations.map(it => it.id).join(',');
        }
      }

      return diffs;
    },
    availableLocations() {
      if (!this.isCorporateAdmin) return [];

      const locations: UserLocation[] = this.$store.getters['MerchantPortal/getMerchantLocations'];
      const assignedLocationIds = this.formData.locations.map(it => it.id);
      return locations.filter(it => !assignedLocationIds.includes(it.id));
    },
  },

  watch: {
    emailError: {
      immediate: true,
      handler(value: string) {
        this.errors.email = value;
      },
    },
  },

  async created() {
    this.processing = true;

    // Adding a new user
    if (!this.userId) {
      this.formData.locations = [this.$store.getters['MerchantPortal/getMerchantLocation']];

      if (this.isSalesManager) {
        this.formData.role = UsersRolesEnum.SALES_REP;
      }

      this.processing = false;
      return;
    }

    // Editing an existing user
    const { data } = await getUserAtThisLocation(this.merchantUuid, this.userId);
    this.internalUser = data as UserAtThisLocationDetail;

    this.formData = {
      first_name: this.internalUser.first_name,
      last_name: this.internalUser.last_name,
      phone_number: this.internalUser.phone_number.phone_number,
      email: this.internalUser.email,
      role: this.internalUser.role.name,
      locations: [],
    };
    if (this.internalUser.locations?.length) {
      this.formData.locations = [...this.internalUser.locations];
    }

    const currentRole = this.rolesDropdownOptions.find(role => {
      return role.value === this.internalUser.role.name;
    });
    if (currentRole) currentRole.name = `${currentRole.name} - Current Role`;

    this.processing = false;
  },

  methods: {
    getLocationName(name: string) {
      if (name.length <= LOCATION_NAME_LIMIT) return name;
      return `...${name.slice(-LOCATION_NAME_LIMIT)}`;
    },
    onLocationInput(location: UserLocation) {
      this.formData.locations.push(location);
    },
    removeLocation(location: UserLocation) {
      this.formData.locations = this.formData.locations.filter(loc => loc.id !== location.id);
      // If a value is selected we remove that item from the autocomplete items
      // but it stays as an internalValue in the component.
      // If the removed value is added back it will be autoselected so we need to clear it.
      this.locationValue = null;
    },
    editUser(payload: PatchUserPayload) {
      const editFn = this.allUsersTable ? patchUserAtAllLocations : patchUserAtThisLocation;
      return editFn(this.merchantUuid, this.internalUser.id, payload);
    },
    createUser(payload: CreateUserPayload) {
      const createFn = this.allUsersTable ? createUserAtAllLocations : createUserAtThisLocation;
      return createFn(this.merchantUuid, payload);
    },
    async saveUser(inviteExisting = false) {
      this.processing = true;

      const payload: PatchUserPayload | CreateUserPayload = {
        ...this.formDiffs,
        role: this.formData.role!,
        locations: this.formData.locations.map(it => it.id),
      };
      let successMessage = '';

      try {
        if (this.internalUser.id) {
          await this.editUser(payload);
          successMessage = 'Your changes have been saved.';
        } else {
          await this.createUser(payload as CreateUserPayload);

          if (inviteExisting) {
            successMessage = `User ${payload.email} will now have access to this location.`;
          } else {
            this.postCreateModal = true;
          }
        }

        if (successMessage) {
          this.$store.dispatch('Ui/addGlobalTimedSuccess', successMessage);
          this.$emit('save');
        }
      } catch (error: unknown) {
        const err = error as AxiosError;
        this.$store.dispatch('Ui/addGlobalTimedError');

        const { data } = err.response!;
        this.errors = data || {}; // For showing errors on the form fields
        this.processing = false;
      }
    },
    async userExistsInHierarchy() {
      const emailInUseMsg = 'This email address is already in use.';

      try {
        const { data } = await userLocationCheck(this.merchantUuid, this.formData.email);
        if (data.at_location) {
          this.errors = { ...this.errors, email: emailInUseMsg };
          return true;
        }

        if (isHigherRole(data.role, this.userHighestRole)) {
          this.existingHigherRole = MerchantUserRoles[data.role as UsersRolesEnum];
        } else {
          this.invitingExistingUserModal = true;
        }
        return true;
      } catch (error: unknown) {
        if ((error as AxiosError).response?.status === 404) return false;
        if ((error as AxiosError).response?.status === 400) {
          this.errors = { ...this.errors, email: emailInUseMsg };
          return true;
        }
      }
      return false;
    },
    async onSave() {
      this.errors = {};

      if (this.formDiffs.email) {
        const newEmailExistsInHierarchy = await this.userExistsInHierarchy();
        if (newEmailExistsInHierarchy) return;
      }
      this.saveUser();
    },
  },
});
</script>

<style lang="scss" scoped>
h2 {
  margin: 0;
  padding-bottom: 2rem;
  font: inherit;
  color: inherit;
}

.user-locations-form {
  display: flex;

  > div {
    width: 26rem;

    &:first-child {
      &.corporate-view {
        padding-right: 1.5rem;
        border-right: 1px solid #e0e0e0;
      }
    }
  }

  .static-label {
    font-size: 0.75rem;
  }

  .location-form {
    padding-left: 1.5rem;

    .v-chip {
      margin-top: 1rem;
      margin-right: 0.5rem;
      height: 2.125rem;
      background-color: var(--grayscale-color-4);

      :deep(.v-icon) {
        font-size: 1.2rem !important;

        &:before {
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .user-locations-form {
    flex-direction: column;

    > div {
      width: 100%;

      &:first-child {
        &.corporate-view {
          padding-right: 0;
          border-right: none;
        }
      }
    }

    .location-form {
      width: 100%;
      margin-bottom: 1rem;
      padding-left: 0;

      .v-input {
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
