<template>
  <standard-dialog
    :dialog="show"
    :hide-header-close="true"
    :show-cancel-cta="false"
    :show-actions="false"
    :on-cancel="emitClose">
    <template #body>
      <p>
        Please confirm if the amount entered,
        <span v-private>{{ formattedValue }}</span>
        is your Annual Household Gross Income.
      </p>

      <custom-button
        class="edit-btn"
        data-test="editIncomeBtn"
        full-width
        size="small"
        variant="primary"
        @click="$emit('edit')">
        Edit Income
      </custom-button>

      <custom-button
        data-test="inputHelperModalCta"
        full-width
        size="small"
        variant="secondary"
        @click="$emit('confirm')">
        Confirm Income
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import StandardDialog from '@/components/Dialogs/index.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConfirmationDialog',
  components: {
    CustomButton,
    StandardDialog,
  },
  props: {
    value: { required: true, type: [String, Number] },
    show: { type: Boolean, default: false },
  },
  computed: {
    formattedValue() {
      if (!this.value) return null;
      return CurrencyFormatLong(this.value);
    },
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" scoped>
.edit-btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>
