<template>
  <div ref="container" class="merchant-location-info">
    <span class="mr-1">
      Location:
    </span>

    <span v-if="singleLocation">
      {{ locationName }}
    </span>

    <v-menu
      v-else
      v-model="showLocationSelect"
      location="bottom"
      :offset="[12, 95]"
      :close-on-content-click="false"
      :content-class="mobile ? 'mobile-location-selector' : 'location-selector'">
      <template v-slot:activator="{ props }">
        <div
          v-if="locationName"
          class="merchant-location-info__name"
          data-test="locationLabel"
          v-bind="props">
          <span class="location-name">
            {{ locationName }}
          </span>
          <v-icon size="2rem">
            {{ showLocationSelect ? 'mdi-menu-up' : 'mdi-menu-down' }}
          </v-icon>
        </div>
      </template>

      <v-card border max-width="25rem">
        <v-card-text>
          <div class="current-location">
            <span>
              Current Location:
            </span>
            <p>
              {{ location.name }}
            </p>
          </div>
          <v-text-field
            v-model="searchTerm"
            label="Search"
            variant="underlined"
            density="compact"
            hide-details
            single-line
            append-inner-icon="mdi-magnify"
            data-test="locationSearch" />
          <v-list density="compact">
            <v-list-item
              v-for="filteredLocation in filteredChildLocations"
              :key="filteredLocation.name"
              @click="changeLocation(filteredLocation.name)">
              <v-list-item-title>
                {{ filteredLocation.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { MerchantLocation } from '@/interfaces/merchant/MerchantLocation';

export default defineComponent({
  props: {
    mobile: { type: Boolean, default: false },
  },
  data() {
    return {
      locationName: '',
      showLocationSelect: false,
      searchTerm: '',
    };
  },
  computed: {
    location(): MerchantLocation | null | false {
      return this.$store.getters['MerchantPortal/getMerchantLocation'];
    },
    merchantLocations(): MerchantLocation[] {
      return this.$store.getters['MerchantPortal/getMerchantLocations'] || [];
    },
    singleLocation() {
      return this.merchantLocations.length < 2;
    },
    filteredChildLocations() {
      if (!this.location) return [];

      const term = this.searchTerm.toLowerCase();
      return this.merchantLocations.filter(location => {
        return location.name !== (this.location as MerchantLocation).name
          && location.name.toLowerCase().includes(term);
      });
    },
    menuProps() {
      return {
        maxWidth: 300,
        offsetY: true,
        bottom: true,
      };
    },
  },
  watch: {
    location: {
      immediate: true,
      handler(value) {
        if (!value?.name) return;
        this.locationName = value.name;
      },
    },
  },
  mounted() {
    this.trimLocationName();
    window.addEventListener('resize', this.trimLocationName);
  },
  unmounted() {
    window.removeEventListener('resize', this.trimLocationName);
  },
  methods: {
    changeLocation(locationName: string) {
      this.showLocationSelect = false;

      const currentRoute = this.$route;
      const queryParameters: Record<string, string> = {};

      for (const [key, value] of Object.entries(currentRoute.query)) {
        if (value) queryParameters[key] = value.toString();
      }
      const location = this.merchantLocations.find(loc => loc.name === locationName);
      if (!location || !location.merchant_uuid) {
        return;
      }

      queryParameters.location = location.merchant_uuid;

      const queryString = new URLSearchParams(queryParameters).toString();
      const newURL = `${currentRoute.path}?${queryString}`;

      window.open(newURL, '_blank');
    },
    trimLocationName() {
      // Need to be careful with the calculations in this method as it can
      // be called multiple times because it is called on window resize event.
      if (!window.innerWidth || !this.location || !this.location?.name) return;

      const containerWidth = (this.$refs.container as any).clientWidth;
      if (containerWidth < 1) return; // Sometimes the container width is 0 on the first tick

      let nameContainerWidth = 0;
      const labelWidth = 50; // The width of the label "Location: "

      if (window.innerWidth > 1024) {
        // For larger than 1024px screens, we show the UserInfo component
        nameContainerWidth = containerWidth - labelWidth;
      } else {
        // For smaller screens, we show the burger menu icon next to the location name
        const burgerMenuWidth = 50;
        const paddingWidth = 80;
        // On tablet-like widths the name container spans all the way to the logo on the left.
        // So here we limit the container to up to max 320px width, which is approximately the
        // same width as the name container in the UserInfo component.
        nameContainerWidth = Math.min(
          window.innerWidth - burgerMenuWidth - labelWidth - paddingWidth,
          320,
        );
      }

      if (nameContainerWidth < 1) return;

      // 6.67px is the approximate width of a character for the font size 16px and our font family
      const approxCharWidth = 6.67;
      const nameLengthPx = this.location.name.length * approxCharWidth;

      const nameContainerOverflowWidth = nameLengthPx - nameContainerWidth;

      if (nameContainerOverflowWidth > 0) {
        const trimCharNum = Math.ceil(nameContainerOverflowWidth / approxCharWidth);

        if (trimCharNum > 0) {
          const slice = this.location.name.length - trimCharNum;
          this.locationName = `...${this.location.name.slice(-slice)}`;
        }
      } else {
        this.locationName = this.location.name;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
$selector-font-size: 0.875rem;

.merchant-location-info {
  display: flex;
  align-items: flex-start;
  height: 1.125rem;

  &__name {
    display: inline-flex;
    flex-wrap: nowrap;

    &:hover {
      cursor: pointer;
    }

    .v-icon {
      height: 1.125rem !important;
      line-height: 1.125rem;
      vertical-align: top;

      &::after {
        background-color: transparent;
      }
    }

    .location-name {
      display: inline-flex;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.mobile-location-selector,
.location-selector {
  border-radius: 10px;

  .v-card {
    min-width: 21.875rem; // 350px

    .v-card-text {
      padding: 0 0 0.5rem 0;
    }

    .current-location {
      padding: 0.5rem 1rem;
      background-color: var(--grayscale-color-5);
      border-bottom: 1px solid var(--grayscale-color-2);
      font-size: 0.8rem;
      text-align: left;

      span {
        font-weight: 700;
      }

      p {
        margin-bottom: 0;
      }
    }

    :deep(.v-input) {
      padding: 0.5rem 1rem 0 1rem;
      font-size: $selector-font-size;

      span {
        font-size: $selector-font-size;
      }
    }

    .v-list {
      padding: 0;
      max-height: 12rem;
      overflow-y: auto;
      text-align: left;

      .v-list-item-title {
        font-size: $selector-font-size;
      }
    }
  }
}
</style>
