import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classList)
  }, [
    _createElementVNode("div", {
      class: "standard-widget__container",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeIframe && _ctx.closeIframe(...args)))
    }),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectedWidget), {
      widget: "",
      class: "standard-widget__component"
    }))
  ], 2))
}