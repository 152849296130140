/* eslint-disable symbol-description */
import { UserAllLocations, UserAtThisLocation } from '@/interfaces/merchantPortal/UserInterface';
import { InjectionKey, type Ref } from 'vue';

export const merchantUserManagementKey = Symbol() as InjectionKey<{
  refreshUsers: () => void;
  userCount: Ref<number>;
  page: Ref<number>;
  pageSize: number;
  users: Ref<UserAtThisLocation[] | UserAllLocations[]>;
  loading: Ref<boolean>;
  search: Ref<string>;
  selectedTable: Ref<number>;
  tableFilters: {
    role: string | undefined
    status: string | undefined
    locations: string | undefined
  }
}>;
