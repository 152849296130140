<template>
  <standard-dialog
    :dialog="showDialog"
    :show-actions="false"
    :persistent="true"
    :on-cancel="emitClose">
    <template #body>
      <div>
        <p>
          The amounts you entered were incorrect.
        </p>
        <p>
          Please check your bank account ending in ...<strong>{{ lastFour }}</strong>
          and enter the two deposit amounts sent from ACCTVERIFY. It can take up to 24 hours,
          and the deposits will look something like this.
        </p>

        <verify-deposits-example-table />
      </div>

      <custom-button
        class="mb-5 mt-5"
        full-width
        @click="emitClose">
        Close
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import VerifyDepositsExampleTable from '@/components/PaymentProviders/VerifyDepositsExampleTable.vue';

export default defineComponent({
  name: 'IncorrectAmountDialog',
  components: {
    StandardDialog,
    CustomButton,
    VerifyDepositsExampleTable,
  },
  props: {
    showDialog: { type: Boolean, default: false },
    lastFour: { type: String, required: true },
  },
  methods: {
    emitClose() {
      this.$emit('closeDialog');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
</style>
