import axios from 'axios';
import { BASE_PATH } from './index';

export function getMerchantBranding(domain?: string) {
  return getBranding('merchant', domain);
}

export function getConsumerBranding(domain?: string) {
  return getBranding('consumer', domain);
}

/*
  chunks = splitting the domain into chunks on every . or - character.
  Added support for dot and hyphen as we can have domains that can look like: app-stg.domain.com,
  or app.stg.domain.com. - plus excludes the additions such as qa, uat,
  stg as defined in specialKeywords

  4 or more elements in chunks array indicates there is a subdomain in the URL,
  ex: app-stg.sub.domain.com - in this case, extract the last 3 elements (sub.domain.com)
  ex: app.sub.domain.com - in this case, also extract the last 3 elements (sub.domain.com)

  Anything below 4 elements indicate normal URL as we had before,
  ex: app-stg.subdomain.com - in this case, extract the last 2 elements (domain.com).
*/
function getTopLevelDomain(domain: string): string {
  const specialKeywords = ['qa', 'uat', 'stg'];
  const chunks = domain.split(/[.-]/).filter(chunk => !specialKeywords.includes(chunk));

  const topLevelDomain = chunks.length >= 4 ? chunks.slice(-3).join('.') : chunks.slice(-2).join('.');

  return topLevelDomain.split(':')[0];
}

function getBranding(role: string, domain?: string) {
  const brandingPath = getTopLevelDomain(domain || window.location.origin);
  const url = `${BASE_PATH}/meta/${role}-branding/`;
  return axios.get(url, { params: { domain: brandingPath } });
}
