<template>
  <merchant-onboarding-wizard-payments-types-financing-options
    data-test="MOB-step3-substep7"
    :in-place-input="false"
    :on-next-sub-step="goToNextSubStep" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MerchantOnboardingWizardPaymentsTypesFinancingOptions
  from '@/components/Merchant/OnboardingWizard/PaymentsTypesFinancingOptions.vue';

export default defineComponent({
  name: 'SubstepSeven',

  components: {
    MerchantOnboardingWizardPaymentsTypesFinancingOptions,
  },

  props: {
    onNextSubStep: { type: Function, default: () => () => null },
  },

  data: () => ({
    ownSubStep: 7,
  }),

  methods: {
    async goToNextSubStep(stepData: any) {
      const responseStatus = await this.$store.dispatch(
        'MerchantOnboarding/dispatchAllAttributes',
        stepData,
      );

      await this.$store.dispatch(
        'MerchantOnboarding/dispatchPatchApplication',
        'Post Payments Accepted YFV / Pre Confirm All',
      );

      if (responseStatus === 201) {
        this.onNextSubStep(this.ownSubStep + 1);
      }
    },
  },
});
</script>
