import LocalStorageConstants from '@/constants/LocalStorageConstants';

// eslint-disable-next-line import/prefer-default-export
export async function clearAuthLocalStorage(): Promise<any> {
  localStorage.removeItem('group');
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('userId');
  localStorage.removeItem('sessionId');
  localStorage.removeItem('merchant');
  localStorage.removeItem('merchantUuid');
  localStorage.removeItem('isLoggedIn');
  sessionStorage.removeItem(LocalStorageConstants.LOCATION_UUID);

  return Promise.resolve;
}
