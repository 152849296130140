import { getStatuses } from '@/api/consumerActivity';
import { DisplayStatus, HumanFriendlyStatus } from '@/interfaces/ConsumerActivity';
import { ref } from 'vue';

const statuses = ref<HumanFriendlyStatus[]>([]);

export const useDisplayStatus = () => {
  const getDescription = (status: DisplayStatus) => {
    return statuses.value.find(s => s.name === status)?.description;
  };

  const fetchStatuses = async () => {
    if (!statuses.value.length) {
      const { data } = await getStatuses();
      statuses.value = data;
    }
  };

  return {
    getDescription,
    fetchStatuses,
    statuses,
  };
};
