import axios from '@/Axios';
import {
  StoreMerchantRequestPayload,
} from '@/interfaces/merchantBeginOnboardingWizard/CreateMerchantDetailsInterface';
import { BASE_PATH, instance } from '@/api';
import {
  PaginationOptions, PaginatedResponse, Response, RequestOptions,
} from '@/api/types';
import {
  CreateUserPayload,
  UserLocationCheck,
  UserAtThisLocation,
  UserAllLocations,
  UserAtThisLocationDetail,
  UserRole,
  UserLocation,
  PatchUserPayload,
} from '@/interfaces/merchantPortal/UserInterface';

export function createMerchant(payload: StoreMerchantRequestPayload) {
  return axios.post('/api/v1/merchant/', payload);
}

export function getChildLocations() {
  return instance.get(`${BASE_PATH}/merchant/merchant-child-locations/`);
}

export function getAllLocations(params: PaginationOptions): PaginatedResponse<UserLocation[]> {
  return instance.get(`${BASE_PATH}/merchant/all-locations/`, { params });
}

export function updateMerchantPrimaryLocation(merchant_uuid: string) {
  const payload = { merchant_uuid };
  return instance.put(`${BASE_PATH}/merchant/user/primary-location/`, payload);
}

export function getUsersAtThisLocation(
  merchantUuid: string,
  options: RequestOptions,
): PaginatedResponse<UserAtThisLocation[]> {
  const path = `${BASE_PATH}/merchant/${merchantUuid}/users-at-this-location/`;
  return instance.get(path, {
    params: {
      ...options.filters,
      ...options.params,
    },
  });
}

export function getUserAtThisLocation(
  merchantUuid: string,
  userId: string | number,
): Response<UserAtThisLocationDetail> {
  const path = `${BASE_PATH}/merchant/${merchantUuid}/users-at-this-location/${userId}/`;
  return instance.get(path);
}

export function getUsersAllLocations(
  merchantUuid: string,
  options: RequestOptions,
): PaginatedResponse<UserAllLocations[]> {
  const path = `${BASE_PATH}/merchant/${merchantUuid}/multi-location-users/`;
  return instance.get(path, {
    params: {
      ...options.filters,
      ...options.params,
    },
  });
}

export function patchUserAtThisLocation(
  merchantUuid: string,
  userId: string | number,
  payload: PatchUserPayload,
): Response<UserAtThisLocationDetail> {
  const path = `${BASE_PATH}/merchant/${merchantUuid}/users-at-this-location/${userId}/`;
  return instance.patch(path, payload);
}

export function patchUserAtAllLocations(
  merchantUuid: string,
  userId: string | number,
  payload: PatchUserPayload,
): Response<UserAllLocations> {
  const path = `${BASE_PATH}/merchant/${merchantUuid}/multi-location-users/${userId}/`;
  return instance.patch(path, payload);
}

export function createUserAtThisLocation(
  merchantUuid: string,
  payload: CreateUserPayload,
): Response<UserAtThisLocationDetail> {
  const path = `${BASE_PATH}/merchant/${merchantUuid}/users-at-this-location/`;
  return instance.post(path, payload);
}

export function createUserAtAllLocations(
  merchantUuid: string,
  payload: CreateUserPayload,
): Response<UserAllLocations> {
  const path = `${BASE_PATH}/merchant/${merchantUuid}/multi-location-users/`;
  return instance.post(path, payload);
}

export function userLocationCheck(
  merchantUuid: string,
  email: string,
): Response<UserLocationCheck> {
  return instance.post(`${BASE_PATH}/merchant/${merchantUuid}/users-location-check/`, { email });
}

export function getAllRoles(): PaginatedResponse<UserRole[]> {
  return instance.get(`${BASE_PATH}/merchant/all-user-roles/`);
}

export function getAllStatuses(): Response<string[]> {
  return instance.get(`${BASE_PATH}/merchant/merchant-user-status-list/`);
}

export function resendUserInvite(merchantUuid: string, email: string) {
  return instance.post(`${BASE_PATH}/merchant/${merchantUuid}/re-invite/`, { email });
}
