import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "payment-steps" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_verify_deposit = _resolveComponent("verify-deposit")!
  const _component_incorrect_amount_dialog = _resolveComponent("incorrect-amount-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_verify_deposit, {
      "last-four": _ctx.lastFour,
      "payment-data": _ctx.formDetails,
      "show-deposits-form-split-payment": _ctx.showDepositsFormSplitPayment,
      "show-loader": true,
      onAmountsVerified: _ctx.passAmounts,
      onActivateDialog: _ctx.activateIncorrectAmountDialog,
      onVerificationFailed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('verificationFailed')))
    }, null, 8, ["last-four", "payment-data", "show-deposits-form-split-payment", "onAmountsVerified", "onActivateDialog"]),
    _createVNode(_component_incorrect_amount_dialog, {
      "show-dialog": _ctx.showIncorrectAmountDialog,
      "last-four": _ctx.lastFour,
      onCloseDialog: _ctx.closeIncorrectAmountDialog
    }, null, 8, ["show-dialog", "last-four", "onCloseDialog"])
  ]))
}