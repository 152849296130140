<template>
  <div data-test="declineMsg">
    Thank you for applying through Momnt.
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SoftDeclineConsentConfirmation',
});
</script>

<style lang="scss" scoped>
div {
  padding: 1rem 0;
}
</style>
