import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    targetOrigin: '*',
  }),

  methods: {
    postMessage(eventData: any): void {
      // eslint-disable-next-line no-restricted-globals
      parent.postMessage(eventData, this.targetOrigin);
    },
  },
});
