<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--two" data-test="BMOB-step2">
    <lightbulb>
      <template v-slot:content>
        <p class="ma-0" data-test="pleaseEnsureLabel">
          Please ensure you provide the <strong>Business Name, </strong>
          <strong>Business Address,</strong> and <strong>Business Phone Number</strong>
          below.
        </p>
      </template>
    </lightbulb>
    <v-form ref="beginOnboardingWizardStepTwoForm" v-model="formValid">
      <text-input
        v-model="name"
        :disabled="processing"
        required
        autocomplete="organization"
        data-test="businessInput"
        label="Business Name"
        class="input-field-padding" />

      <address-input
        v-model="autocompleteLocation"
        ask-confirmation
        :disabled="processing"
        address1-label="Business Address Line 1"
        address2-label="Business Suite/Room/Floor"
        confirmation-modal-title="Please Verify Your Business Address"
        :is-fs-excluded="false"
        required
        :show-confirmation="showAddressConfirmation"
        :validated-address="validatedAddress"
        :error-messages="[addressValidationError]"
        @confirmation:accepted="onAddressConfirmed"
        @confirmation:declined="closeConfirmation" />

      <phone-input
        v-model="merchant_phone"
        :disabled="processing"
        required
        autocomplete="tel"
        data-test="phoneInput"
        label="Business Phone Number" />
    </v-form>

    <custom-button
      :disabled="nextStepDisabled || processing"
      data-test="nextBtn"
      full-width
      @click="openAddressValidation">
      Next
    </custom-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  ValidatedAddressDataInterface,
} from '@/interfaces/addressValidation/AddressDataInterface';
import AddressInput from '@/components/Inputs/Address/index.vue';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import { CreateMerchantDetailsInterface } from '@/interfaces/merchantBeginOnboardingWizard/CreateMerchantDetailsInterface';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import GetErrors from '@/mixins/GetErrors';
import { postConsentTypes } from '@/utils/Consents';
import PhoneInput from '@/components/Inputs/Phone.vue';
import SetCustomErrorValueMixin from '@/mixins/SetCustomErrorValueMixin';
import TextInput from '@/components/Inputs/Text.vue';
import ValidateAddress from '@/mixins/ValidateAddress';
import phoneNumber from '@/validators/phone_number';
import zipCode from '@/validators/zip_code';
import Lightbulb from '@/components/Alerts/Lightbulb.vue';
import { CreateUserDetailsInterface } from '@/interfaces/merchantBeginOnboardingWizard/CreateUserDetailsInterface';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'StepTwo',

  components: {
    AddressInput,
    CustomButton,
    PhoneInput,
    TextInput,
    Lightbulb,
  },

  mixins: [
    GetErrors,
    SetCustomErrorValueMixin,
    ValidateAddress,
    HoneypotTrackMixin,
  ],

  data() {
    const autocompleteLocation: ValidatedAddressDataInterface = {
      isAddressValidated: false,
      address1: '',
      address2: '',
      city: '',
      state: null,
      zipCode: '',
      address_verification_response: {
        analysis: {},
        metadata: {},
        components: {},
      },
    };
    return {
      autocompleteLocation,
      formValid: false,
      name: '',
      merchant_phone: '',
      processing: false,
      hpEventName: 'Business Information',
      hpNextStep: 3,
    };
  },

  computed: {
    isValidName(): boolean {
      return !!this.name;
    },

    isValidAddress1(): boolean {
      return !!this.autocompleteLocation.address1;
    },

    isValidCity(): boolean {
      return !!this.autocompleteLocation.city;
    },

    isValidState(): boolean {
      return !!this.autocompleteLocation.state;
    },

    isValidZipCode(): boolean {
      return zipCode(this.autocompleteLocation.zipCode || '');
    },

    isValidMerchantPhone(): boolean {
      return phoneNumber(this.merchant_phone);
    },

    nextStepDisabled(): boolean {
      return this.processing
        || !this.isValidName
        || !this.isValidAddress1
        || !this.isValidCity
        || !this.isValidState
        || !this.isValidZipCode
        || !this.isValidMerchantPhone;
    },

    userDetails(): CreateUserDetailsInterface {
      return this.$store.getters['MerchantBeginOnboarding/getUserDetails'];
    },
  },

  mounted() {
    this.$el.querySelector('input[type=tel]')?.classList.remove('fs-exclude');
    this.$el.querySelector('input[type=tel]')?.classList.add('fs-unmask');
  },

  methods: {
    async openAddressValidation() {
      const isValid = await this.validateAddress(this.autocompleteLocation);
      if (!isValid) return;

      this.showAddressConfirmation = true;
    },

    async onAddressConfirmed() {
      this.processing = true;
      this.closeConfirmation();
      await this.createMerchant();
      this.processing = false;
    },

    closeConfirmation(): void {
      this.showAddressConfirmation = false;
    },

    async createMerchant(): Promise<void> {
      this.autocompleteLocation = this.validatedAddress;

      const form = (this.$refs.beginOnboardingWizardStepTwoForm as any);
      const validationResults = await form.validate();
      this.formValid = validationResults.valid;

      if (!this.formValid) return;

      const merchantDetails: CreateMerchantDetailsInterface = {
        name: this.name,
        merchant_phone: this.merchant_phone,
        ...this.autocompleteLocation,
      };

      const gucaPayload: any = {
        merchant_invitation: true,
        email: this.userDetails.email,
        phone_number: this.merchant_phone,
        check_against_consumer: true,
        check_against_merchant: true,
      };

      const responseStatus = await this.$store.dispatch(
        'MerchantBeginOnboarding/dispatchCreateMerchant',
        merchantDetails,
      );

      if (responseStatus === 201) {
        await this.$store.dispatch('Auth/gucaCheckApi', gucaPayload);
        await this.verifyAddress();
      }
    },

    async verifyAddress(): Promise<void> {
      const responseStatus = await this.$store.dispatch('MerchantBeginOnboarding/dispatchVerify', true);

      if (responseStatus === 201) {
        const consentTypes: Array<number> = [
          ConsentTypesEnum.TERMS_OF_USE,
          ConsentTypesEnum.PRIVACY_POLICY,
          ConsentTypesEnum.TCPA,
        ];
        const merchant_application_uuid = this.$store.getters['MerchantBeginOnboarding/getMerchantApplicationId'];

        const responseStatusConsent = await postConsentTypes({
          consentTypes,
          merchant_application_uuid,
          consentedOn: true,
          entity: ConsentEntityTypes.MERCHANT,
        });

        if (responseStatusConsent === 201) {
          this.$emit('nextStep');
          this.trackEvent(this.hpEventName, PageTypesShorthand.BMOB, this.hpNextStep);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/merchant-begin-onboarding-wizard";

.standard-wizard {
  &__step {
    &--two {
      @include standard-wizard-form-inline-split;
    }
  }

  .v-form .input-field-padding {
    padding-top: 0.3em;
  }
}
</style>
