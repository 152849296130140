import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_alert = _resolveComponent("custom-alert")!

  return (_ctx.combinedErrors.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combinedErrors, (error, index) => {
          return (_openBlock(), _createBlock(_component_custom_alert, {
            key: index,
            type: "error"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(error), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}