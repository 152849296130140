<template>
  <v-form
    class="fs-unmask"
    data-test="MOB-step3-substep3"
    @submit.prevent="goToNextSubStep">
    <v-card-text>
      <text-input
        v-model="stepData.legalBusinessName"
        autocomplete="organization"
        data-test="legalInput"
        required
        label="Legal Business Name"
        max="250" />

      <text-input
        v-model="stepData.doingBusinessAs"
        :enable-state="false"
        data-test="doingInput"
        label="Name Doing Business As - Optional"
        max="250" />

      <custom-button
        :disabled="nextSubstepDisabled"
        data-test="nextBtn"
        full-width
        type="submit">
        Next
      </custom-button>
    </v-card-text>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import get from 'lodash/get';
import TextInput from '@/components/Inputs/Text.vue';
import validateString from '@/validators/string';

export default defineComponent({
  name: 'SubstepThree',

  components: {
    CustomButton,
    TextInput,
  },

  props: {
    onNextSubStep: { type: Function, default: () => () => null },
  },

  data: () => ({
    ownSubStep: 3,
    stepData: {
      legalBusinessName: '',
      doingBusinessAs: '',
    } as any,
    continueButtonDisabled: false,
  }),

  computed: {
    reEntryData(): any {
      return this.$store.getters['MerchantOnboarding/getMerchantReEntryData'];
    },

    reEntryDataAllAttributes(): any {
      return get(this.reEntryData, 'all_attributes', {});
    },

    nextSubstepDisabled(): boolean {
      return !(
        validateString(this.stepData.legalBusinessName, 1, 250)
        && validateString(this.stepData.doingBusinessAs, 0, 250)
      ) || this.continueButtonDisabled;
    },
  },

  mounted() {
    const { legalBusinessName, doingBusinessAs } = this.$store.getters[
      'MerchantOnboarding/getAllAttributes'
    ];

    const reEntryLegalBusinessName = this.reEntryDataAllAttributes.legalBusinessName;
    const reEntryDoingBusinessAs = this.reEntryDataAllAttributes.doingBusinessAs;

    this.stepData.legalBusinessName = legalBusinessName || reEntryLegalBusinessName || '';
    this.stepData.doingBusinessAs = doingBusinessAs || reEntryDoingBusinessAs || '';
  },

  methods: {
    async goToNextSubStep() {
      this.continueButtonDisabled = true;

      const responseStatus = await this.$store.dispatch(
        'MerchantOnboarding/dispatchAllAttributes',
        this.stepData,
      );

      await this.$store.dispatch(
        'MerchantOnboarding/dispatchPatchApplication',
        'Post Business Name/ Pre Business Structure',
      );

      if (responseStatus === 201) {
        this.onNextSubStep(this.ownSubStep + 1);
      }

      this.continueButtonDisabled = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";

</style>
