import { defineComponent } from 'vue';
import { ErrorsInterface } from '@/interfaces/ui/ErrorsInterface';

export default defineComponent({
  computed: {
    errors(): ErrorsInterface {
      return this.$store.getters['Ui/getErrors'];
    },

    showErrorAlertModal(): boolean {
      return !!this.errorResponseAlertModal;
    },

    errorResponseAlertModal(): string {
      return this.$store.getters['Ui/getErrorResponse'];
    },
  },
});
