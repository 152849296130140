<template>
  <standard-wizard-message
    :message="errorMessage"
    :title="title"
    hide-wrapper
    data-test="canceledMsg" />
</template>

<script lang="ts">
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ApplicationCanceledMsg',
  components: {
    StandardWizardMessage,
  },
  data() {
    return {
      title: 'Sorry to see you go.',
    };
  },
  computed: {
    errorMessage(): string {
      return `We have successfully canceled your loan application.
      If you choose to apply again at any time please contact ${this.merchantName}.`;
    },
    merchantName(): string {
      const { merchantName } = this.$store.getters['Consumer/getApplicationCanceledData'];
      return merchantName || 'your merchant';
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.v-card .v-card-text) {
  color: var(--grayscale-color-1);
}
</style>
