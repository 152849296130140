import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56a018ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-header" }
const _hoisted_2 = { class: "search-refresh-wrapper section-header__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_standard_data_table_refresh_button = _resolveComponent("standard-data-table-refresh-button")!
  const _component_table_small = _resolveComponent("table-small")!
  const _component_custom_expansion_panel = _resolveComponent("custom-expansion-panel")!

  return (_openBlock(), _createBlock(_component_custom_expansion_panel, {
    expanded: !_ctx.isMobile,
    "can-collapse": !_ctx.isMobile,
    class: "fs-unmask full-width gray-border section-card"
  }, {
    title: _withCtx(({ isExpanded }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_select, {
          modelValue: _ctx.selected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
          class: "old-v-select section-header__title",
          items: _ctx.items,
          readonly: !isExpanded,
          "menu-icon": isExpanded ? '$dropdown' : '',
          "hide-details": "",
          flat: "",
          variant: "solo",
          "bg-color": "transparent",
          "data-test": "recentLoanDropdown",
          "menu-props": { bottom: true, offsetY: true },
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, {
          item: _withCtx(({ props }) => [
            _createVNode(_component_v_list_item, _mergeProps(props, { class: "text-left" }), null, 16)
          ]),
          _: 2
        }, 1032, ["modelValue", "items", "readonly", "menu-icon"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
            "append-inner-icon": "mdi-magnify",
            label: "Search",
            class: "table-search-bar",
            variant: "underlined",
            width: "14rem",
            "single-line": "",
            "hide-details": "",
            "data-test": "portalTableSearch",
            onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", null, [
            (!_ctx.isMobile)
              ? (_openBlock(), _createBlock(_component_standard_data_table_refresh_button, {
                  key: 0,
                  active: _ctx.refreshing,
                  onClick: _ctx.refreshTable
                }, null, 8, ["active", "onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    text: _withCtx(() => [
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.search,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.search) = $event)),
        "append-inner-icon": "mdi-magnify",
        label: "Search",
        flat: "",
        "bg-color": "transparent",
        variant: "underlined",
        class: "table-search-bar--mobile",
        "single-line": "",
        "hide-details": "",
        "data-test": "portalTableSearch"
      }, null, 8, ["modelValue"]),
      (_ctx.selected === _ctx.TableTypesEnum.LOAN_APPLICATIONS)
        ? (_openBlock(), _createBlock(_component_table_small, {
            key: 0,
            page: _ctx.currentPage,
            "onUpdate:page": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.currentPage) = $event)),
            headers: _ctx.headers,
            applications: _ctx.applications,
            "get-human-readable": _ctx.getHumanReadableIdLabel,
            "get-fee-label": _ctx.getMerchantFeeLabel,
            "app-statuses": _ctx.consumerApplicationStatuses,
            "get-name-label": _ctx.getProductNameLabel,
            "search-input": _ctx.search,
            "select-loan-app": _ctx.selectLoanApp,
            "items-length": _ctx.applicationsCount,
            "is-mobile": _ctx.isMobile,
            onUpdatePage: _ctx.updateApplicationsPage
          }, null, 8, ["page", "headers", "applications", "get-human-readable", "get-fee-label", "app-statuses", "get-name-label", "search-input", "select-loan-app", "items-length", "is-mobile", "onUpdatePage"]))
        : _createCommentVNode("", true),
      (_ctx.selected === _ctx.TableTypesEnum.LOAN_INVITATIONS)
        ? (_openBlock(), _createBlock(_component_table_small, {
            key: 1,
            page: _ctx.currentPage,
            "onUpdate:page": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentPage) = $event)),
            headers: _ctx.headersInvitations,
            applications: _ctx.invitations,
            "get-human-readable": _ctx.getHumanReadableIdLabel,
            "get-fee-label": _ctx.getMerchantFeeLabel,
            "app-statuses": _ctx.consumerApplicationStatuses,
            "get-name-label": _ctx.getProductNameLabel,
            "search-input": _ctx.search,
            "select-loan-app": _ctx.selectLoanApp,
            "items-length": _ctx.invitationsCount,
            "is-mobile": _ctx.isMobile,
            onUpdatePage: _ctx.updateInvitationsPage
          }, null, 8, ["page", "headers", "applications", "get-human-readable", "get-fee-label", "app-statuses", "get-name-label", "search-input", "select-loan-app", "items-length", "is-mobile", "onUpdatePage"]))
        : _createCommentVNode("", true),
      (_ctx.selected === _ctx.TableTypesEnum.AVAILABLE_TRANSACTIONS)
        ? (_openBlock(), _createBlock(_component_table_small, {
            key: 2,
            page: _ctx.currentPage,
            "onUpdate:page": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.currentPage) = $event)),
            headers: _ctx.headersTransactions,
            applications: _ctx.transactions,
            "get-human-readable": _ctx.getHumanReadableIdLabel,
            "get-amount-label": _ctx.getMerchantAmountLabel,
            "get-spend-label": _ctx.getMerchantAvaliableSpendLabel,
            "app-statuses": _ctx.consumerApplicationStatuses,
            "get-name-label": _ctx.getProductNameLabel,
            "search-input": _ctx.search,
            "select-loan-app": _ctx.selectLoanApp,
            "items-length": _ctx.transactionsCount,
            "is-mobile": _ctx.isMobile,
            onUpdatePage: _ctx.updateTransactionsPage
          }, null, 8, ["page", "headers", "applications", "get-human-readable", "get-amount-label", "get-spend-label", "app-statuses", "get-name-label", "search-input", "select-loan-app", "items-length", "is-mobile", "onUpdatePage"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["expanded", "can-collapse"]))
}