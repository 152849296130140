<template>
  <div>
    <div v-if="initialCharge" class="customer-summary-charge-title">
      <div class="charge-title">
        <span>{{ chargeName }}:</span>
        You can request up to {{ formattedMaxCharge }}
        <span v-if="showPercentages" class="font-weight-regular percentages">
          ({{ maxChargePercentage }})
        </span>
      </div>

      <div class="help-icon" @click="$emit('helpIcon')">
        <v-icon
          size="1.2rem"
          color="var(--primary-color)">
          mdi-help
        </v-icon>
      </div>
    </div>

    <div v-if="paymentConfirmation">
      <div class="back-icon" @click="$emit('goBack')">
        <v-icon
          color="var(--grayscale-color-1)"
          size="1.5rem">
          mdi-arrow-left
        </v-icon>
      </div>
    </div>

    <div
      v-if="transactionPending"
      class="customer-summary-charge-title"
      :class="[transactionStatusErrored ? 'status-errored' : 'status-pending']">
      <div
        :class="[transactionStatusErrored ? 'charge-errored' : 'charge-pending']">
        Payment Request Status: {{ transactionStatus }}
      </div>

      <div
        v-if="transactionStatus === 'Expired'"
        class="help-icon-expired"
        @click="$emit('helpIconExpired')">
        <v-icon
          style="padding: 0.2rem;"
          size="1rem"
          color="var(--primary-color)">
          mdi-help
        </v-icon>
      </div>
    </div>

    <div
      v-if="secondConfirmation"
      class="customer-summary-charge-title mb-4 py-3">
      <div class="charge-title">
        <span>Please Confirm</span>
      </div>
    </div>

    <div
      v-if="completedTransaction && showPendingDate"
      class="completed-transaction-message">
      <div>
        You may send another payment request after
        {{ formattedNextChargeTime }} on {{ formattedNextChargeDate }}.
      </div>
    </div>

    <div
      v-if="finalChargeQuestion"
      class="customer-summary-charge-title mb-4">
      <div class="charge-title">
        <span>{{ chargeName }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StagedFundingMixin from '@/mixins/StagedFundingMixin';
import DATE_CONSTANTS from '@/constants/DateConstants';
import moment from 'moment';

export default defineComponent({
  name: 'ChargeHeader',
  mixins: [StagedFundingMixin],
  props: {
    showPendingDate: { type: Boolean, default: false },
    showPercentages: { type: Boolean, default: false },
    maxChargePercentage: { type: String, default: '' },
    nextChargeDate: { type: String, default: '' },
    formattedMaxCharge: { type: String, default: '' },
  },
  computed: {
    formattedNextChargeDate() {
      return moment(this.nextChargeDate).format(DATE_CONSTANTS.dateFormatSlashes);
    },
    formattedNextChargeTime() {
      return moment(this.nextChargeDate).format(DATE_CONSTANTS.shortTimeFormat);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.v-icon:hover {
  cursor: pointer;
}

.help-icon-expired {
  background-color: #fff;
  border-radius: 50%;
}

.customer-summary-charge-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--grayscale-color-5);
  color: var(--grayscale-color-1);
  text-align: left;
  padding: 0.5rem 1rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid var(--grayscale-color-3);
  margin-bottom: 2rem;

  .charge-title {
    span {
      font-size: 1rem;
      font-weight: bold;
    }

    .percentages {
      font-size: 0.875rem;
    }
  }

  .help-icon {
    background-color: #fff;
    border-radius: 50%;
    padding: 0.2rem;
  }

  .charge-pending {
    color: var(--success-color);
    font-weight: 700;
    font-size: 1rem;
  }

  .charge-errored {
    color: var(--error-color);
    font-weight: 700;
    padding: 0 0.5rem;
    font-size: 1rem;
  }
}

.status-pending {
  background-color: rgba(76, 175, 80, 0.1);
  border-bottom: none;
  margin-bottom: 1rem;
}

.status-errored {
  background-color: rgba(166, 64, 64, 0.1);
  border-bottom: none;
  margin-bottom: 1rem;
}

.back-icon {
  text-align: left;
  margin: 1rem;

  .v-icon {
    background-color: var(--grayscale-color-3);
    border-radius: 50%;
    padding: 0.25rem;
  }
}

.completed-transaction-message {
  background-color: #F7F9FB;
  font-weight: 600;
  font-size: 1rem;
  color: #4C5560;
  padding: 1rem;
  text-align: left;
  border-radius: 8px
}
</style>
