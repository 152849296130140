import { isEmpty, toLower } from 'lodash';
import { ActionContext } from 'vuex';
import axios from 'axios';
import {
  CreateMerchantDetailsInterface,
  StoreMerchantRequestPayload,
} from '@/interfaces/merchantBeginOnboardingWizard/CreateMerchantDetailsInterface';
import { CreateUserDetailsInterface } from '@/interfaces/merchantBeginOnboardingWizard/CreateUserDetailsInterface';
import instance from '@/Axios';
import { MerchantBeginOnboardingStateInterface } from '@/interfaces/states/MerchantBeginOnboardingStateInterface';
import { getAxiosError } from '@/api/utils';
import { createMerchant } from '@/api/merchant';

export async function dispatchShowBeginOnboardingWizard(context: any, payload: boolean) {
  context.commit('setShowBeginOnboardingWizard', payload);
}

export async function dispatchCreateUser(
  context: ActionContext<MerchantBeginOnboardingStateInterface, any>,
  payload: CreateUserDetailsInterface,
) {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('setUserDetails', payload);

  const {
    firstName,
    middleName,
    lastName,
    mobile,
    email,
  } = payload;

  let response: any = {};
  let responseStatus: number = 0;

  const postData: any = {
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    mobile,
    email: toLower(email),
  };

  try {
    // API query without token
    response = await axios.post('/api/v1/account/user/start_merchant_onboarding/', postData);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;

      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (response.status === 201) {
      const {
        id,
        token,
      } = response.data;

      context.commit('setUserId', id);

      if (token) {
        const user: any = { id };

        await context.dispatch('Auth/setUserData', { token, user }, { root: true });
      }
    }
  }

  return responseStatus;
}

export async function dispatchCreateMerchant(
  context: ActionContext<MerchantBeginOnboardingStateInterface, any>,
  createMerchantDetails: CreateMerchantDetailsInterface,
) {
  let response: any = {};
  let responseStatus: number = 0;

  const sponsorId: any = context.rootGetters['Sponsor/getSponsorId'];
  const programUid: any = context.getters.getProgramUid;

  context.commit('Ui/setErrors', {}, { root: true });
  context.commit(
    'setMerchantDetails',
    createMerchantDetails,
  );
  context.commit('setMerchantId', '');
  context.commit('setMerchantApplicationId', '');

  const postData: StoreMerchantRequestPayload = {
    name: createMerchantDetails.name,
    create_address: true,
    address_1: createMerchantDetails.address1,
    city: createMerchantDetails.city,
    state: createMerchantDetails.state,
    zip: createMerchantDetails.zipCode,
    merchant_phone: createMerchantDetails.merchant_phone,
    address_verification_response: createMerchantDetails.address_verification_response,
  };

  if (programUid) {
    postData.programUid = programUid;
  }

  if (sponsorId) {
    postData.sponsor = sponsorId;
  }

  if (!isEmpty(createMerchantDetails.address2)) {
    postData.address_2 = createMerchantDetails.address2;
  }

  try {
    response = await createMerchant(postData);
  } catch (e: unknown) {
    const axiosError = getAxiosError(e);
    if (axiosError) {
      responseStatus = axiosError.response!.status;

      context.commit('Ui/setErrors', axiosError.response, { root: true });
    }
  } finally {
    if (response.status) {
      responseStatus = response.status;
    }

    if (response.status === 201) {
      const {
        merchant_application_uuid,
        uuid,
      } = response.data;

      context.commit('setMerchantId', uuid);
      context.commit('setMerchantApplicationId', merchant_application_uuid);
    }
  }

  return responseStatus;
}

export async function dispatchVerify(
  context: ActionContext<MerchantBeginOnboardingStateInterface, any>,
) {
  const userId = context.getters.getUserId;
  const merchantId = context.getters.getMerchantId;

  let response: any = {};
  let responseStatus: number = 0;

  if (merchantId && userId) {
    context.commit('Ui/setErrors', {}, { root: true });

    const postData: object = {
      user: userId,
      is_primary: true,
    };

    try {
      response = await instance.post(
        `/api/v1/merchant/${merchantId}/users/`,
        postData,
      );
    } catch (e: unknown) {
      const axiosError = getAxiosError(e);
      if (axiosError) {
        responseStatus = axiosError.response!.status;

        context.commit('Ui/setErrors', axiosError.response, { root: true });
      }
    } finally {
      if (response.status) {
        responseStatus = response.status;
      }

      if (response.status === 201) {
        const responseData = response.data;

        context.commit('setUserId', responseData.user);
      }
    }
  }

  return responseStatus;
}
