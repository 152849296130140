import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-433ff129"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.switchToggle)
      ? (_openBlock(), _createBlock(_component_v_switch, {
          key: 0,
          class: "old-v-switch",
          "model-value": _ctx.selected,
          inset: "",
          "hide-details": "",
          ripple: false,
          color: "white",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggle')))
        }, null, 8, ["model-value"]))
      : _createCommentVNode("", true),
    (_ctx.additionalProductOfferings)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_custom_button, {
            class: _normalizeClass({ selected: _ctx.selected }),
            disabled: _ctx.btnDisabled,
            size: "small",
            variant: "secondary",
            "product-id-data-test": !_ctx.fromRateSheet ? _ctx.productName : '',
            "custom-data-test": !_ctx.fromRateSheet ? _ctx.dataTest : '',
            "data-test": "switchBtn",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggle')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.labelText), 1)
            ]),
            _: 1
          }, 8, ["class", "disabled", "product-id-data-test", "custom-data-test"])
        ]))
      : _createCommentVNode("", true)
  ]))
}