import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classListContainer)
  }, [
    (_ctx.showCircular)
      ? (_openBlock(), _createBlock(_component_v_progress_circular, {
          key: 0,
          size: 50,
          class: "progress-indicator progress-indicator--circular",
          "data-test": "progressCircular",
          indeterminate: ""
        }))
      : (_ctx.showLinear)
        ? (_openBlock(), _createBlock(_component_v_progress_linear, {
            key: 1,
            active: true,
            "bg-opacity": 0.3,
            "buffer-value": 100,
            height: 4,
            indeterminate: true,
            rounded: false,
            stream: false,
            striped: false,
            "model-value": 0,
            class: "progress-indicator progress-indicator--linear",
            "data-test": "progressLinear"
          }))
        : _createCommentVNode("", true)
  ], 2))
}