import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29c9fa12"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "rate-sheet-options-expandable-categories",
  "data-test": "rate-sheet-options"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_tables = _resolveComponent("tables")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_cards = _resolveComponent("cards")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.expandableCategories.length > 0 && _ctx.showTables)
      ? (_openBlock(), _createBlock(_component_v_expansion_panels, {
          key: 0,
          "model-value": _ctx.toggledExpansionPanel,
          variant: "accordion",
          multiple: !_ctx.isFromOfferCodes,
          "onUpdate:modelValue": _ctx.toggleExpansionPanel
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expandableCategories, (category, categoryIndex) => {
              return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                key: categoryIndex,
                class: "standard-collapsable-panel",
                "data-test": `expansionPanels${categoryIndex}`
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel_title, {
                    class: "standard-collapsable-panel__title",
                    "data-test": `productExpandButton${categoryIndex}`
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(category.label || category) + " ", 1),
                      (category.has_fallback)
                        ? (_openBlock(), _createBlock(_component_v_icon, {
                            key: 0,
                            size: "1.5rem",
                            color: "var(--grayscale-color-1)",
                            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('show-fallback-modal')), ["stop"]))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" mdi-information-outline ")
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["data-test"]),
                  _createVNode(_component_v_expansion_panel_text, null, {
                    default: _withCtx(() => [
                      (_ctx.showTables)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_tables, {
                              modelValue: _ctx.inputVal,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputVal) = $event)),
                              "rate-sheet-options": _ctx.getCategoryRateSheetsTables(category),
                              disabled: _ctx.disabled,
                              "button-toggle": _ctx.buttonToggle,
                              "disable-add-btns": _ctx.disableAddBtns,
                              "mobile-breakpoint": _ctx.mobileBreakpoint,
                              "is-from-offer-codes": _ctx.isFromOfferCodes,
                              "data-test-attr": category.dataTest,
                              onToggle: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle', $event)))
                            }, null, 8, ["modelValue", "rate-sheet-options", "disabled", "button-toggle", "disable-add-btns", "mobile-breakpoint", "is-from-offer-codes", "data-test-attr"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["data-test"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["model-value", "multiple", "onUpdate:modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.showCards)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expandableCategories, (category, categoryIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: categoryIndex,
              class: "card-offers-wrap"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCategoryRateSheetsCards(category), (rateSheet) => {
                return (_openBlock(), _createBlock(_component_cards, {
                  key: rateSheet.uuid,
                  class: _normalizeClass(categoryIndex === 0 ? 'box-shadow-card' : 'no-shadow-card'),
                  value: _ctx.value,
                  "rate-sheet": rateSheet,
                  disabled: _ctx.disabled,
                  "option-selection": _ctx.optionSelection,
                  "is-buffer-amount": _ctx.isBufferAmount,
                  "approved-amount": _ctx.approvedAmount,
                  "requested-amount": _ctx.requestedAmount,
                  "selected-ratesheet": _ctx.selectedRatesheet,
                  onSelect: _ctx.onToggle
                }, null, 8, ["class", "value", "rate-sheet", "disabled", "option-selection", "is-buffer-amount", "approved-amount", "requested-amount", "selected-ratesheet", "onSelect"]))
              }), 128))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}