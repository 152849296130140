import {
  HumanFriendlyStatus, InvitationDetails, RecentApplication, Opportunity,
} from '@/interfaces/ConsumerActivity';
import { PaginatedResponse, RequestOptions, Response } from './types';
import { BASE_PATH, instance } from './index';

export function getRecentApplications(
  merchantUuid: string,
  options: RequestOptions,
): PaginatedResponse<RecentApplication> {
  const params = new URLSearchParams();

  Object.entries(options.params ?? {}).forEach(([key, value]) => {
    if (value) params.append(key, value.toString());
  });

  Object.entries(options.filters ?? {}).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v: string) => {
        if (v) params.append(key, v);
      });
    } else if (value) {
      params.append(key, value);
    }
  });

  return instance.get(`${BASE_PATH}/merchant/${merchantUuid}/recent-applications/`, { params });
}

export function getInvitationDetails(
  merchantUuid: string,
  invitationUuid: string,
): Response<InvitationDetails> {
  return instance.get(
    `${BASE_PATH}/merchant/${merchantUuid}/recent-applications/${invitationUuid}/consumer-invitation-detail/`,
  );
}

export function getStatuses(): Response<HumanFriendlyStatus[]> {
  return instance.get(`${BASE_PATH}/consumer/human-friendly-application-statuses/`);
}

export function getOpportunities(
  merchantUuid: string,
  options: RequestOptions,
): PaginatedResponse<Opportunity> {
  return instance.get(`${BASE_PATH}/merchant/${merchantUuid}/opportunities/`, { params: options.params });
}
