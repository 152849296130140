import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66b46ca9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inline-inputs owners-list owners-list--inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_merchant_onboarding_wizard_owner_card = _resolveComponent("merchant-onboarding-wizard-owner-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ownersData, (owner, index) => {
      return (_openBlock(), _createBlock(_component_merchant_onboarding_wizard_owner_card, {
        key: index,
        "active-owner": _ctx.activeOwner,
        "in-place-input": true,
        index: index,
        "on-save-owner": _ctx.saveOwner,
        owner: owner,
        "from-verify-details": _ctx.fromVerifyDetails,
        "otp-simple-design": _ctx.otpSimpleDesign,
        "enable-ownership-text": _ctx.enableOwnershipText,
        "sub-step": true
      }, null, 8, ["active-owner", "index", "on-save-owner", "owner", "from-verify-details", "otp-simple-design", "enable-ownership-text"]))
    }), 128))
  ]))
}