<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--one" data-test="BMOB-step1">
    <lightbulb>
      <template v-slot:content>
        <p class="ma-0" data-test="toStartLabel">
          To start, please enter information about yourself below.
        </p>
      </template>
    </lightbulb>
    <v-form ref="beginOnboardingWizardStepOneForm" v-model="formValid">
      <text-input
        v-model="firstName"
        autofocus
        :disabled="processing"
        required
        autocomplete="given-name"
        data-test="firstInput"
        label="Your First Name"
        class="input-field-padding" />

      <text-input
        v-model="middleName"
        :disabled="processing"
        autocomplete="additional-name"
        data-test="middleInput"
        label="Your Middle Name" />

      <text-input
        v-model="lastName"
        :disabled="processing"
        required
        autocomplete="family-name"
        data-test="lastInput"
        label="Your Last Name" />

      <phone-input
        v-model="mobile"
        :disabled="processing"
        :error-messages="errors.mobile"
        hint="We will send a one time passcode to this phone number"
        persistent-hint
        required
        autocomplete="tel"
        data-test="mobileInput"
        label="Your Mobile Phone Number" />

      <email-input
        v-model="email"
        class="email-input"
        :disabled="processing"
        :email-error="errors.email ? errors.email[0] : ''"
        :is-fs-excluded="false"
        hint="This email address will become your username"
        persistent-hint
        required
        autocomplete="email"
        data-test="emailInput"
        label="Your Business Email Address" />
    </v-form>

    <div class="standard-wizard__step__confirm-msg" data-test="byClickingNextLabel">
      <template v-if="widget">
        Our financing platform is Powered by Momnt.
      </template>
      By clicking next, I verify I have read the Momnt

      <a
        data-test="termsBtn"
        :href="termsOfUseUrl"
        target="_blank"
        rel="noopener noreferrer">
        Terms of Use
      </a>,

      <a
        data-test="tcpaBtn"
        :href="tcpaUrl"
        target="_blank"
        rel="noopener noreferrer">
        TCPA
      </a> and

      <a
        data-test="privacyBtn"
        :href="privacyPolicyUrl"
        target="_blank"
        rel="noopener noreferrer">
        Privacy Policy
      </a>
    </div>

    <custom-button
      :disabled="nextStepDisabled"
      data-test="nextBtn"
      full-width
      @click="createUser">
      Next
    </custom-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { CreateUserDetailsInterface } from '@/interfaces/merchantBeginOnboardingWizard/CreateUserDetailsInterface';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import EmailInput from '@/components/Inputs/Email.vue';
import GetErrors from '@/mixins/GetErrors';
import PhoneInput from '@/components/Inputs/Phone.vue';
import TextInput from '@/components/Inputs/Text.vue';
import emailAddress from '@/validators/email_address';
import phoneNumber from '@/validators/phone_number';
import Lightbulb from '@/components/Alerts/Lightbulb.vue';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'StepOne',

  components: {
    CustomButton,
    EmailInput,
    PhoneInput,
    TextInput,
    Lightbulb,
  },

  mixins: [GetErrors, HoneypotTrackMixin],

  props: {
    widget: { type: Boolean, default: false },
  },

  data() {
    return {
      formValid: false,
      processing: false,
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      mobile: '',
      hpEventName: 'Personal Information',
      hpStep: 1,
      hpNextStep: 2,
    };
  },

  computed: {
    nextStepDisabled(): boolean {
      return this.processing
        || !this.firstName.length
        || !this.lastName.length
        || !!this.errors.email?.length
        || !phoneNumber(this.mobile)
        || !emailAddress(this.email);
    },

    privacyPolicyUrl(): string {
      return this.$store.getters['Ui/getBrandingPrivacyPolicyURL'];
    },

    termsOfUseUrl(): string {
      return this.$store.getters['Ui/getBrandingTermsOfUseURL'];
    },

    tcpaUrl(): string {
      return this.$store.getters['Ui/getBrandingTcpaURL'];
    },
  },

  watch: {
    email() {
      if (this.errors.email) {
        this.resetErrors();
      }
    },
  },

  mounted() {
    this.trackEvent(this.hpEventName, PageTypesShorthand.BMOB, this.hpStep);
  },

  unmounted() {
    this.resetErrors();
  },

  methods: {
    async createUser(): Promise<void> {
      const form = (this.$refs.beginOnboardingWizardStepOneForm as any);
      const validationResults = await form.validate();
      this.formValid = validationResults.valid;

      const payload: CreateUserDetailsInterface = {
        firstName: this.firstName,
        middleName: this.middleName,
        lastName: this.lastName,
        email: this.email,
        mobile: this.mobile,
      };

      this.processing = true;
      const responseStatus = await this.$store.dispatch('MerchantBeginOnboarding/dispatchCreateUser', payload);
      this.processing = false;

      if (responseStatus === 201) {
        this.trackEvent(this.hpEventName, PageTypesShorthand.BMOB, this.hpNextStep);
        this.$emit('nextStep');
      }
    },

    resetErrors() {
      this.$store.commit('Ui/resetErrors');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/merchant-begin-onboarding-wizard";
@import "@/assets/scss/_custom-transitions";

.v-form .input-field-padding {
  padding-top: 0.3em;
}

.email-input {
  margin-top: 2rem;
  margin-bottom: 2rem !important;
}
</style>
