<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--four" data-test="COB-step5">
    <bubble-card>
      <template #card-title>
        <h5 data-test="consumerAddressHeaderLabel">
          Address
        </h5>
      </template>

      <transition mode="out-in" name="slide">
        <consumer-loan-apply-no-lenders-available-msg v-if="noLendersAvailable" />

        <div v-else>
          <v-form @submit.prevent="openAddressValidation">
            <address-input
              ref="inputLocation"
              v-model="autocompleteLocation"
              address1-hint="Use the address where you reside
              (no PO Boxes or commercial addresses)"
              address1-hint-persistent
              address1-label="Street Address"
              address2-label="Apt / Unit"
              ask-confirmation
              :disabled="processing"
              :error-messages="[addressValidationError]"
              required
              :show-confirmation="showAddressConfirmation"
              :validated-address="validatedAddress"
              @confirmation:accepted="checkAvailableLenders"
              @confirmation:declined="hideDialog" />

            <custom-button
              :disabled="isDisabled"
              full-width
              data-test="continueBtn"
              type="submit">
              Continue
            </custom-button>
          </v-form>
        </div>
      </transition>
    </bubble-card>

    <complete-later-button
      v-if="!noLendersAvailable" />
  </div>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import {
  AddressDataInterface,
  ValidatedAddressDataInterface,
} from '@/interfaces/addressValidation/AddressDataInterface';
import AddressInput from '@/components/Inputs/Address/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ConsumerLoanApplyNoLendersAvailableMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/NoLendersAvailableMsg.vue';
import zipCodeValidator from '@/validators/zip_code';
import { isEmpty } from 'lodash';
import AddressTypes from '@/enums/AddressTypes';
import { saveAddress } from '@/api/consumer';
import FullStoryLogging from '@/logging/FullStory';
import ConsumerHumanReadableApplicationId
  from '@/mixins/Consumer/LoanApply/ConsumerHumanReadableApplicationId';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';
import { defineComponent } from 'vue';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import ValidateAddress from '@/mixins/ValidateAddress';
import GetProcessing from '@/mixins/GetProcessing';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'StepFive',
  components: {
    BubbleCard,
    AddressInput,
    CustomButton,
    ConsumerLoanApplyNoLendersAvailableMsg,
    CompleteLaterButton,
  },
  mixins: [
    GetProcessing,
    ValidateAddress,
    NavigatesStepsMixin,
    ConsumerHumanReadableApplicationId,
    IdleTimeoutMixin,
    HoneypotTrackMixin,
  ],
  data() {
    const autocompleteLocation: ValidatedAddressDataInterface = {
      isAddressValidated: false,
      address1: '',
      address2: '',
      city: '',
      state: null,
      zipCode: '',
      address_verification_response: {
        analysis: {},
        components: {},
        metadata: {},
      },
    };
    return {
      autocompleteLocation,
      disabled: false,
      ownStep: 5,
      noLendersAvailable: false,
      hpEventName: 'Address Page Visit',
      hpStep: 5,
    };
  },
  computed: {
    isDisabled(): boolean {
      return this.disabled
        || isEmpty(this.autocompleteLocation.address1)
        || isEmpty(this.autocompleteLocation.city)
        || isEmpty(this.autocompleteLocation.state)
        || !zipCodeValidator(this.autocompleteLocation.zipCode ?? '');
    },
  },
  mounted() {
    const {
      address_1, address_2, zip_code, city, state,
    } = this.$store.getters['Consumer/getInvitation'];

    if (address_1) {
      this.autocompleteLocation.address1 = address_1;
    }
    if (address_2) {
      this.autocompleteLocation.address2 = address_2;
    }
    if (city) {
      this.autocompleteLocation.city = city;
    }
    if (state) {
      this.autocompleteLocation.state = state;
    }
    if (zip_code) {
      this.autocompleteLocation.zipCode = zip_code;
    }

    const firstName = this.$store.getters['Consumer/getLoanApplyWizardData'].first_name;
    const lastName = this.$store.getters['Consumer/getLoanApplyWizardData'].last_name;
    FullStoryLogging.setUserVars({
      displayName: firstName ? `${firstName} ${lastName}` : undefined,
      invitationId: this.consumerHumanReadableApplicationIdLabel,
    });

    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },
  methods: {
    async checkAvailableLenders(): Promise<void> {
      this.autocompleteLocation = Object.assign(this.autocompleteLocation, this.validatedAddress);
      const address = this.autocompleteLocation;

      let addressSaved = true;
      const appId = this.$store.getters['Consumer/getConsumerApplicationId'];
      const addressPayload = {
        address_type: AddressTypes.HOME,
        address_1: address.address1,
        address_2: address.address2,
        city: address.city,
        state: address.state,
        zip_code: address.zipCode,
        address_verification_response: this.validatedAddress.address_verification_response,
      };
      await saveAddress(appId, addressPayload).catch((error: any) => {
        addressSaved = false;
        this.$store.dispatch('Ui/setErrors', error.response);
      });

      const { countyName, zipShort, state } = address;
      const payload: any = {
        state_abbreviation: state,
        county_name: countyName,
        zipcode: zipShort,
      };

      const { data, status } = await this.$store.dispatch('Consumer/getAvailableLenders', payload);
      const lendersAvailable = data?.detail === 'Lenders available.';
      if (status === 200 && lendersAvailable) {
        this.$store.commit('Consumer/setLoanApplyWizardData', addressPayload);

        if (addressSaved) {
          this.goToStep(this.ownStep + 1);
        }
      } else {
        this.noLendersAvailable = true;
      }

      this.disabled = false;
    },
    async openAddressValidation() {
      this.disabled = true;

      const address: AddressDataInterface = {
        address1: this.autocompleteLocation.address1,
        address2: this.autocompleteLocation.address2,
        city: this.autocompleteLocation.city,
        state: this.autocompleteLocation.state,
        zipCode: this.autocompleteLocation.zipCode,
      };
      const isValid = await this.validateAddress(address);
      if (!isValid) {
        this.disabled = false;
        return;
      }

      this.showAddressConfirmation = true;
    },
    hideDialog() {
      this.showAddressConfirmation = false;
      this.disabled = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";

.standard-wizard {
  &__step {
    :deep(.card .card-body) {
      .card-text__info-group:first-child {
        margin-bottom: 0;

        .standard-input-field {
          margin-bottom: .5rem;
        }
      }
    }

    &--three {
      @include standard-wizard-form-inline-split;
    }
  }
}
</style>
