import { defineComponent } from 'vue';
import { OnboardingUserData } from '@/interfaces/merchantOnboardingWizard/OnboardingUserData';

export default defineComponent({
  name: 'MerchantDataMixin',

  computed: {
    getUserVerifyData(): OnboardingUserData {
      return this.$store.getters['MerchantOnboarding/getUserVerifyData'];
    },
  },
});
