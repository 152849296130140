import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2210ca66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "prepend" }
const _hoisted_2 = ["data-test"]
const _hoisted_3 = { class: "append" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_alert, {
      class: _normalizeClass(["custom-alert", _ctx.className]),
      type: _ctx.vAlertType,
      variant: "outlined"
    }, _createSlots({
      prepend: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_icon, {
            color: "#FFFFFF",
            icon: _ctx.icon
          }, null, 8, ["icon"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "custom-alert--content",
          "data-test": `customAlert${_ctx.type}`
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 8, _hoisted_2)
      ]),
      _: 2
    }, [
      (_ctx.dismissible)
        ? {
            name: "append",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _withDirectives(_createVNode(_component_v_icon, {
                  icon: "mdi-close",
                  size: "small",
                  onClick: _ctx.close
                }, null, 8, ["onClick"]), [
                  [_directive_private, 'Close alert']
                ])
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["class", "type"]),
    (_ctx.timed)
      ? (_openBlock(), _createBlock(_component_v_progress_linear, {
          key: 0,
          color: _ctx.alertColorVariable,
          height: "8",
          "model-value": _ctx.progressBarPercentage,
          class: "progress-bar"
        }, null, 8, ["color", "model-value"]))
      : _createCommentVNode("", true)
  ]))
}