<template>
  <div class="simple-card">
    <slot
      v-if="showHeaderSlot"
      name="header" />

    <div
      v-else-if="header"
      class="simple-card__header">
      {{ header }}
    </div>

    <slot
      v-if="showBodySlot"
      name="body" />

    <div
      v-else
      class="simple-card__body-text">
      {{ bodyText }}
    </div>

    <div
      v-if="showActionsSlot"
      class="simple-card__actions">
      <slot name="actions" />
    </div>

    <div
      v-if="footer"
      class="align-self-end simple-card__footer">
      {{ footer }}
    </div>

    <slot
      v-if="showFooterSlot"
      name="footer" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SimpleCard',

  props: {
    bodyText: { type: String, default: '' },
    header: { type: String, default: '' },
    footer: { type: String, default: '' },
  },

  computed: {
    showHeaderSlot(): boolean {
      return !!this.$slots.header;
    },

    showBodySlot(): boolean {
      return !!this.$slots.body;
    },

    showActionsSlot(): boolean {
      return !!this.$slots.actions;
    },

    showFooterSlot(): boolean {
      return !!this.$slots.footer;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/simple-card';

.simple-card {
  border: none;
}

</style>
