/**
 * Validates a string
 *
 * @param value
 * @param isRequired
 * @param min
 * @param max
 */
export default (
  value: string,
  min = 0,
  max = 250,
): boolean => {
  if (value.length < min) return false;

  return value.length <= max;
};
