import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68a8f296"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 1,
  class: "pa-2"
}
const _hoisted_3 = {
  key: 0,
  class: "font-weight-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_filter_popup = _resolveComponent("status-filter-popup")!
  const _component_application_summary_popup = _resolveComponent("application-summary-popup")!
  const _component_status_chip_with_description = _resolveComponent("status-chip-with-description")!
  const _component_product_description_popup = _resolveComponent("product-description-popup")!
  const _component_table_skeleton_loader = _resolveComponent("table-skeleton-loader")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createBlock(_component_v_data_table_server, {
    page: _ctx.page,
    "onUpdate:page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
    "disable-sort": "",
    class: "fs-unmask minimal-pagination",
    mobile: _ctx.isMobile,
    headers: _ctx.headers,
    items: _ctx.results,
    "items-per-page": 5,
    loading: _ctx.loading,
    "items-length": _ctx.count
  }, _createSlots({
    "item.sent_to": _withCtx(({ item }) => [
      _createElementVNode("div", {
        class: _normalizeClass({ 'fs-exclude': _ctx.isPhoneNumber(item.sent_to) })
      }, [
        (_ctx.hasSummary(item))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "consumer-activity-interactable",
              onClick: ($event: any) => (_ctx.seeConsumerSummary(item.consumer_application_uuid))
            }, _toDisplayString(item.sent_to), 9, _hoisted_1))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.sent_to), 1))
      ], 2)
    ]),
    "item.id_number": _withCtx(({ item }) => [
      _createVNode(_component_application_summary_popup, {
        "id-number": item.id_number,
        "invitation-id": item.consumer_invitation_uuid
      }, null, 8, ["id-number", "invitation-id"])
    ]),
    "item.display_status": _withCtx(({ item }) => [
      (item.display_status)
        ? (_openBlock(), _createBlock(_component_status_chip_with_description, {
            key: 0,
            status: item.display_status
          }, null, 8, ["status"]))
        : _createCommentVNode("", true)
    ]),
    "item.amount": _withCtx(({ item }) => [
      (item.amount)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.CurrencyFormatLong(item.amount)), 1))
        : _createCommentVNode("", true)
    ]),
    "item.product_id": _withCtx(({ item }) => [
      _createVNode(_component_product_description_popup, {
        "product-id": item.product_id,
        "product-description": item.product_description_parts
      }, null, 8, ["product-id", "product-description"])
    ]),
    loading: _withCtx(() => [
      _createVNode(_component_table_skeleton_loader, {
        rows: 5,
        cols: _ctx.colWidths
      }, null, 8, ["cols"])
    ]),
    _: 2
  }, [
    (!_ctx.isMobile)
      ? {
          name: "header.display_status",
          fn: _withCtx(() => [
            _createVNode(_component_status_filter_popup)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["page", "mobile", "headers", "items", "loading", "items-length"]))
}