<template>
  <div v-if="!isMobile">
    <div
      v-for="row in rows"
      :key="row"
      style="border-bottom: 1px solid var(--grayscale-color-3);">
      <div class="table-skeleton-loader">
        <v-skeleton-loader
          v-for="(colWidth, i) in cols"
          :key="i"
          type="text"
          :width="colWidth || 'auto'"
          :style="getFlex(colWidth)" />
      </div>
    </div>
  </div>
  <div v-else class="table-skeleton-loader--mobile">
    <div
      v-for="(col, i) in cols"
      :key="i"
      class="table-skeleton-loader--mobile__row">
      <v-skeleton-loader width="6rem" type="text" />
      <v-skeleton-loader width="6rem" type="text" />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { useDisplay } from 'vuetify';

export default defineComponent({
  props: {
    rows: { type: Number, default: 1 },
    cols: { type: Array as PropType<Array<string | undefined>>, default: () => ['auto'] },
  },

  setup() {
    const { xs } = useDisplay();

    const isMobile = xs;
    const getFlex = (colWidth?: string) => {
      return !colWidth || colWidth === 'auto' ? { 'flex-grow': 1 } : {};
    };

    return { getFlex, isMobile };
  },
});
</script>

<style scoped lang="scss">
.table-skeleton-loader {
  display: flex;
  gap: 1.5rem;

  &--mobile {
    width: 83vw;

    &__row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 3rem;
      padding-bottom: 1rem;
    }
  }
}

:deep() {
  .v-skeleton-loader {
    height: calc(3rem - 1px);
    display: flex;
    align-items: center;

    &__text {
      height: 1.75rem;
      background: var(--grayscale-color-4) !important;
      border-radius: 1rem;
      margin: 0;
    }
  }
}
</style>
