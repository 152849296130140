<template>
  <standard-dialog
    dialog
    width="420"
    :on-cancel="() => $emit('close')"
    @close="$emit('close')">
    <template #body>
      <p>
        User with <strong>{{ email }}</strong> already exists as an
        <strong>{{ existingHigherRole }}</strong>.
        Contact your Admin to add this user to this location.
      </p>
    </template>

    <template #actions>
      <custom-button full-width @click="$emit('close')">
        Close
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardDialog from '@/components/Dialogs/index.vue';

export default defineComponent({
  name: 'ExistingHigherRoleModal',
  components: {
    CustomButton,
    StandardDialog,
  },
  props: {
    email: { type: String, required: true },
    existingHigherRole: { type: String, required: true },
  },
});
</script>
