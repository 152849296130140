import UsersRolesEnum from '@/enums/UsersRolesEnum';

export const ROLES_RANK = [
  UsersRolesEnum.CORPORATE_ADMIN,
  UsersRolesEnum.ADMIN,
  UsersRolesEnum.SALES_MANAGER,
  UsersRolesEnum.SALES_REP,
  UsersRolesEnum.SALES,
];

export function isHigherRole(role: UsersRolesEnum, comparedRole: UsersRolesEnum): boolean {
  return ROLES_RANK.indexOf(role) < ROLES_RANK.indexOf(comparedRole);
}

export function highestRole(roles: UsersRolesEnum[]): UsersRolesEnum {
  return roles.reduce((prev, current) => {
    return isHigherRole(prev, current) ? prev : current;
  }, UsersRolesEnum.SALES);
}
