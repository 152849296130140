import { ConfigStateInterface } from '@/interfaces/states/ConfigStateInterface';
import { ActionContext } from 'vuex';
import { EnvConfig } from '@/interfaces/EnvConfig';
import { environmentVariables, featureFlags } from '@/api/config';

/* eslint-disable-next-line import/prefer-default-export */
export async function getConfig(
  context: ActionContext<ConfigStateInterface, any>,
): Promise<ConfigStateInterface> {
  const [envVarsResponse, featureFlagsResponse] = await Promise.allSettled([
    environmentVariables(),
    featureFlags(),
  ]);

  if (envVarsResponse.status === 'fulfilled') {
    context.commit('setEnvVariables', envVarsResponse.value.data as EnvConfig);
  }

  if (featureFlagsResponse.status === 'fulfilled') {
    context.commit('setFeatureFlags', featureFlagsResponse.value.data);
  }

  return context.getters.getConfig;
}
