<template>
  <div>
    <checkbox-input
      v-for="(paymentType, index) in paymentTypes"
      :key="index"
      :data-test="paymentType"
      density="compact"
      hide-details
      :disabled="disabled"
      class="checkbox--reverse-layout"
      :model-value="selected.includes(paymentType)"
      @update:model-value="checkboxInput(paymentType, $event)">
      <template #label>
        <span>{{ paymentType }}</span>
      </template>
    </checkbox-input>

    <p v-if="showError" class="payment-type-error">
      At least one Payment Type is required.
    </p>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import CheckboxInput from '@/components/Inputs/Checkbox.vue';

export default defineComponent({
  name: 'MerchantOnboardingWizardPaymentTypes',

  components: {
    CheckboxInput,
  },

  props: {
    modelValue: { type: Array as PropType<Array<string>>, default: () => [] },
    disabled: { type: Boolean, default: false },
    fromBusinessDetails: { type: Boolean, default: false },
  },

  data: () => ({
    paymentTypes: [
      'Visa/ Mastercard',
      'Amex',
      'Cash',
      'Check',
    ],
    selected: [] as Array<string>,
  }),

  computed: {
    showError() {
      const error = this.selected.length === 0 && this.fromBusinessDetails;
      this.$emit('error', error);
      return error;
    },
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(val: Array<string>) {
        this.selected = val;
      },
    },

    selected(val: Array<string>) {
      this.$emit('update:model-value', val);
    },
  },

  methods: {
    checkboxInput(type: string, value: boolean) {
      if (value && !this.selected.includes(type)) {
        this.selected.push(type);
      } else if (!value && this.selected.includes(type)) {
        this.selected.splice(this.selected.indexOf(type), 1);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/inputs/checkbox";

.payment-type-error {
  color: var(--error-color);
  text-align: right;
  padding: 0;
  margin: 0;
}

.checkbox {
  &--reverse-layout {
    .v-label {
      font-size: 1rem;
    }
  }
}
</style>
