import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';

// eslint-disable-next-line import/prefer-default-export
export const COMMON_HEADERS: Array<TableHeaderInterface> = [
  {
    title: 'Description',
    value: 'short_description',
    sortable: false,
  }, {
    title: 'Product ID',
    value: 'product_name',
    sortable: false,
    tooltip: `Use this unique identifier when discussing product
      options with merchant support or team members at your company.`,
  }, {
    title: 'Full Term',
    value: 'full_term',
    sortable: false,
    align: 'end',
    tooltip: `Simply stated, the full term is the purchase window period plus
      payback period of the loan product represented in a total term length.`,
  }, {
    title: 'APR',
    value: 'apr',
    sortable: false,
    align: 'end',
    tooltip: `Annual percentage rate of interest applied to a loan product.
      APRs may vary depending on consumer credit score.`,
  }, {
    title: 'Merchant Fee',
    value: 'merchant_fee',
    sortable: false,
    align: 'end',
    tooltip: `This is your fee for offering the selected products below.
      Each product may carry a different fee.`,
  }, {
    title: 'Customer Preferences',
    value: 'customer_preferences',
    sortable: false,
    align: 'center',
  },
];
