import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d73ba90a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mt-2 pa-4 text-grayscale-1 text-left"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_ctx.productId)
    ? (_openBlock(), _createBlock(_component_v_menu, {
        key: 0,
        modelValue: _ctx.showMenu,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showMenu) = $event)),
        "close-on-content-click": false,
        width: _ctx.width,
        "z-index": "201"
      }, {
        activator: _withCtx(({ props }) => [
          _createElementVNode("button", _mergeProps({ class: "consumer-activity-interactable" }, props), _toDisplayString(_ctx.productId), 17)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_card, { class: "fs-unmask product-description-popup" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "dialog-popup-title" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.productId), 1),
                  _createVNode(_component_v_icon, {
                    tag: "button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMenu = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-close ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, { class: "pb-0" }, {
                default: _withCtx(() => [
                  (_ctx.productDescription)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createElementVNode("ul", null, [
                          (_ctx.productDescription.top_left_content)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_2, _toDisplayString(_ctx.productDescription.top_left_title) + ": " + _toDisplayString(_ctx.productDescription.top_left_content), 1))
                            : _createCommentVNode("", true),
                          (_ctx.productDescription.top_center_content)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_3, _toDisplayString(_ctx.productDescription.top_center_title) + ": " + _toDisplayString(_ctx.productDescription.top_center_content), 1))
                            : _createCommentVNode("", true),
                          (_ctx.productDescription.top_right_content)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_4, _toDisplayString(_ctx.productDescription.top_right_title) + ": " + _toDisplayString(_ctx.productDescription.top_right_content), 1))
                            : _createCommentVNode("", true),
                          (_ctx.productDescription.mid_left_content)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_5, _toDisplayString(_ctx.productDescription.mid_left_title) + ": " + _toDisplayString(_ctx.productDescription.mid_left_content), 1))
                            : _createCommentVNode("", true),
                          (_ctx.productDescription.mid_center_content)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_6, _toDisplayString(_ctx.productDescription.mid_center_title) + ": " + _toDisplayString(_ctx.productDescription.mid_center_content), 1))
                            : _createCommentVNode("", true),
                          (_ctx.productDescription.mid_right_content)
                            ? (_openBlock(), _createElementBlock("li", _hoisted_7, _toDisplayString(_ctx.productDescription.mid_right_title) + ": " + _toDisplayString(_ctx.productDescription.mid_right_content), 1))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, { class: "bg-white pa-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_custom_button, {
                    size: "small",
                    "full-width": "",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMenu = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Close ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "width"]))
    : _createCommentVNode("", true)
}