import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_footer = _resolveComponent("main-footer")!

  return (_openBlock(), _createBlock(_component_main_footer, {
    class: "mt-16",
    address: _ctx.address,
    "client-name": _ctx.clientName,
    "human-readable-id": _ctx.consumerHumanReadableApplicationIdLabel,
    "human-readable-id-prefix": 'ID: ',
    "show-client-name": false,
    "support-links": _ctx.supportLinks
  }, null, 8, ["address", "client-name", "human-readable-id", "support-links"]))
}