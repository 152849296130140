<template>
  <custom-button
    v-if="isVisible"
    variant="link"
    @click="onClick">
    LearnPro
  </custom-button>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import getLearnProUrl from '@/api/learnPro';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ThinkificLink',
  components: { CustomButton },
  computed: {
    isVisible(): boolean {
      return this.$store.getters['Ui/getBrandingObject'].show_thinkific_button;
    },
  },
  methods: {
    async onClick() {
      try {
        const { data: { url } } = await getLearnProUrl();
        window.open(url, '_blank');
      } catch {
        this.$store.dispatch('Ui/addGlobalAlert', {
          text: 'Something went wrong when redirecting to LearnPro',
          type: GlobalAlertTypes.ERROR,
          timed: true,
        });
      }
    },
  },
});
</script>
