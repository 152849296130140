<template>
  <v-form
    class="fs-unmask owners-list__item__data"
    :data-test="`ownerForm${index}`"
    @submit.prevent="openAddressValidation">
    <custom-alert
      v-for="error in errors.non_field_errors"
      :key="error"
      type="error">
      {{ error }}
    </custom-alert>

    <div class="card-text__info-group">
      <text-input
        v-model="ownerData.firstName"
        :disabled="disableInputFields"
        :error-messages="errors.first_name"
        :in-place="inPlaceInput"
        :required="true"
        autocomplete="given-name"
        data-test="firstInput"
        :label="`Owner ${ownerNumber} First Name`" />
    </div>

    <div class="card-text__info-group">
      <text-input
        v-model="ownerData.middleName"
        :disabled="disableInputFields"
        :error-messagess="errors.middle_name"
        :in-place="inPlaceInput"
        autocomplete="additional-name"
        data-test="middleInput"
        :label="`Owner ${ownerNumber} Middle Name`" />
    </div>

    <div class="card-text__info-group">
      <text-input
        v-model="ownerData.lastName"
        :disabled="disableInputFields"
        :error-messages="errors.last_name"
        :in-place="inPlaceInput"
        :required="true"
        autocomplete="family-name"
        data-test="lastInput"
        :label="`Owner ${ownerNumber} Last Name`" />
    </div>

    <div class="card-text__info-group">
      <phone-input
        v-model="ownerData.phoneNumber"
        :disabled="disableInputFields"
        :error-messages="phoneErrorMessage"
        :in-place="inPlaceInput"
        :required="true"
        data-test="phoneInput"
        :label="`Owner ${ownerNumber} Mobile Phone Number`"
        @update:model-value="phoneErrorMessage = ''" />
    </div>

    <div class="card-text__info-group">
      <email-input
        v-model="ownerData.email"
        :disabled="disableInputFields"
        :error-messages="errors.email"
        :in-place="inPlaceInput"
        :required="true"
        :class="{ 'is-invalid standard-input-field--error warning-border': isEmailValid === null }"
        autocomplete="email"
        data-test="emailInput"
        :label="`Owner ${ownerNumber} Personal Email Address`"
        @update:model-value="onEmailUpdate"
        @blur="onEmailBlur" />

      <template v-if="isEmailUsed">
        <v-container
          v-if="isEmailValid === null"
          :state="isEmailValid"
          class="mt-n5 pa-0">
          <p class="text-left warning-msg" data-test="itAppearsEmailLabel">
            It appears you’ve used this email address as your business address.
            Is this both your personal and business email address?
          </p>
          <v-row align="end">
            <v-col
              md="2"
              sm="3"
              cols="2">
              <custom-button
                :variant="useSameEmail ? 'primary' : 'secondary'"
                data-test="useSameEmailDeclineBtn"
                class="yn-radio-btn"
                @click="useSameEmailDecline">
                No
              </custom-button>
            </v-col>
            <v-col
              md="2"
              sm="3"
              cols="2">
              <custom-button
                :variant="useSameEmail ? 'primary' : 'secondary'"
                data-test="useSameEmailAcceptBtn"
                class="ml-4 yn-radio-btn"
                @click="useSameEmailAccept">
                Yes
              </custom-button>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </div>

    <address-input
      ref="inputLocation"
      v-model="ownerAddressData"
      :address1-label="`Owner ${ownerNumber} Residential Street Address`"
      :address2-label="`Owner ${ownerNumber} Unit / Apt`"
      :confirmation-modal-title="`Owner ${ownerNumber} Address`"
      ask-confirmation
      :disabled="disableInputFields"
      :error-messages="[addressValidationError]"
      :in-place="inPlaceInput"
      required
      :show-confirmation="showAddressConfirmation"
      :validated-address="validatedAddress"
      @confirmation:accepted="saveOwner"
      @confirmation:declined="closeConfirmation" />

    <div class="card-text__info-group">
      <ssn-input
        v-model="ownerData.ssn"
        data-test="ssnInput"
        :disabled="disableInputFields"
        :error-messages="errors.ssn"
        :in-place="inPlaceInput"
        :label="`Owner ${ownerNumber} Social Security Number`"
        required
        :paste-prevent="true" />
    </div>

    <div v-if="!fromVerifyDetails" class="card-text__info-group">
      <ssn-input
        v-model="ssnCheck"
        data-test="verifySsnInput"
        :disabled="disableInputFields"
        :error-messages="ssnErrorMessage"
        :in-place="inPlaceInput"
        :label="`Verify Owner ${ownerNumber} Social Security Number`"
        :input-verify="ownerData.ssn"
        required
        :paste-prevent="true"
        @blur="checkSsnDuplicate" />
    </div>

    <div class="card-text__info-group">
      <date-input
        v-model="ownerData.dob"
        :disabled="disableInputFields"
        enforce-dob-check
        :error-messages="errors.dob"
        :in-place="inPlaceInput"
        :max="now"
        :range="false"
        :required="true"
        data-test="dobInput"
        :label="`Owner ${ownerNumber} Date of Birth`"
        name="DOB"
        :paste-prevent="true" />
    </div>

    <div class="card-text__info-group">
      <percentage-input
        v-model.number="ownerData.ownerPercentage"
        class="percentage-input"
        data-test="ownershipInput"
        :disabled="disableInputFields || inPlaceInput"
        :in-place="inPlaceInput"
        :label="`Owner ${ownerNumber} Ownership %`"
        :error-messages="smallPercentageNumber ? percentageErrorMsg : errors.owner_percentage"
        :max="maxRemainingPercentage"
        min="0"
        required />
    </div>

    <pin-input
      v-model="ownerData.security_pin"
      :disabled="disableInputFields"
      :label="pinInputLabel"
      :simple="otpSimpleDesign"
      data-test="pinInput"
      class="text-left" />

    <custom-button
      v-if="showSaveButton"
      :disabled="saveDisabled"
      class="save-btn"
      data-test="saveBtn"
      full-width
      type="submit">
      Save
    </custom-button>
  </v-form>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import Inputmask from 'inputmask';
import { AddressDataInterface } from '@/interfaces/addressValidation/AddressDataInterface';
import { OwnerDataInterface } from '@/interfaces/merchantOnboardingWizard/OwnerDataInterface';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import SsnInput from '@/components/Inputs/Ssn.vue';
import PercentageInput from '@/components/Inputs/Percentage.vue';
import PinInput from '@/components/Inputs/Pin.vue';
import DateInput from '@/components/Inputs/Date.vue';
import AddressInput from '@/components/Inputs/Address/index.vue';
import TextInput from '@/components/Inputs/Text.vue';
import PhoneInput from '@/components/Inputs/Phone.vue';
import EmailInput from '@/components/Inputs/Email.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import NumberToEnglish from '@/filters/NumberToEnglish';
import GetErrors from '@/mixins/GetErrors';
import cloneDeep from 'lodash/cloneDeep';
import ValidateAddress from '@/mixins/ValidateAddress';
import isEmailAddress from '@/validators/email_address';
import validateDate from '@/validators/date';
import ownershipPercentage from '@/validators/ownership_percentage';
import phoneNumber from '@/validators/phone_number';
import securityPin from '@/validators/securityPin';
import ssn from '@/validators/ssn';
import GetProcessing from '@/mixins/GetProcessing';
import moment from 'moment';
import DATE_CONSTANTS from '@/constants/DateConstants';
import validateZipCode from '@/validators/zip_code';
import { OnboardingUserData } from '@/interfaces/merchantOnboardingWizard/OnboardingUserData';
import { addOrUpdateOwner } from '@/api/owners';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import { getAxiosError } from '@/api/utils';

export default defineComponent({
  name: 'MerchantOnboardingWizardOwnerForm',

  components: {
    AddressInput,
    CustomButton,
    DateInput,
    EmailInput,
    CustomAlert,
    PercentageInput,
    PhoneInput,
    PinInput,
    SsnInput,
    TextInput,
  },

  mixins: [GetErrors, GetProcessing, ValidateAddress],

  props: {
    owner: { type: Object as PropType<OwnerDataInterface>, required: true },
    disabledInputs: { type: Boolean, default: false },
    showSaveButton: { type: Boolean, default: true },
    inPlaceInput: { type: Boolean, default: false },
    index: { type: Number, required: true },
    onSave: { type: Function, default: () => () => null },
    fromVerifyDetails: { type: Boolean, default: false },
    editing: { type: Boolean, default: false },
    otpSimpleDesign: { type: Boolean, default: false },
    enableOwnershipText: { type: Boolean, default: true },
    autoFillData: { type: Boolean, default: false },
  },

  data() {
    const ownerData: OwnerDataInterface = {
      uid: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      ssn: '',
      dob: '',
      phoneNumber: '',
      ownerPercentage: undefined,
      isAddressValidated: false,
      address1: '',
      address2: '',
      city: '',
      state: {
        title: '',
        value: '',
      },
      zipCode: '',
      security_pin: '',
      address_verification_response: {
        analysis: {},
        components: {},
        metadata: {},
      },
      id: 0,
    };

    const ownerAddressData: AddressDataInterface = {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
    };

    const useSameEmail: any = null;

    return {
      pinInputLabel: 'Please create a Four-digit PIN and remember this number. '
        + 'To protect your data, we will use this PIN to verify your identity when you '
        + 'call with questions about your account.',
      isEmailUsed: false,
      useSameEmail,
      ownerData,
      ownerAddressData,
      ssnCheck: '',
      ssnErrorMessage: '',
      phoneErrorMessage: '',
      percentageErrorMsg: 'Ownership percentage must be 10% or more',
      disableSave: false,
    };
  },

  computed: {
    smallPercentageNumber(): boolean {
      if (this.ownerData.ownerPercentage && this.ownerData.ownerPercentage < 10) {
        return true;
      }

      return false;
    },

    now() {
      return moment().format(DATE_CONSTANTS.dateFormat);
    },

    userVerifyDataEmail(): string {
      return this.getUserVerifyData?.emailAddress;
    },

    isEmailValid(): boolean {
      return this.isEmailUsed && this.useSameEmail;
    },

    ownerNumber(): string {
      return NumberToEnglish(this.index + 1);
    },

    allOwnersData(): Array<OwnerDataInterface> {
      return this.$store.getters['MerchantOnboarding/getCombinedOwners'];
    },

    getUserVerifyData(): OnboardingUserData {
      return this.$store.getters['MerchantOnboarding/getUserVerifyData'];
    },

    reEntryData(): any {
      return this.$store.getters['MerchantOnboarding/getMerchantReEntryData'];
    },

    maxRemainingPercentage(): number {
      let restOfPercentage = 0;
      this.allOwnersData.forEach((owner, index) => {
        if (index === this.index) return;
        restOfPercentage += (Number(owner.ownerPercentage) || 0);
      });
      return 100 - restOfPercentage;
    },

    saveDisabled(): boolean {
      return this.processing
          || !this.ownerData.firstName
          || !this.ownerData.lastName
          || !isEmailAddress(this.ownerData.email)
          || (this.isEmailUsed && this.isEmailValid === null)
          || !ssn(this.ownerData.ssn)
          || this.ownerData.ssn !== this.ssnCheck
          || !phoneNumber(this.ownerData.phoneNumber)
          || !!this.phoneErrorMessage
          || !ownershipPercentage(this.ownerData.ownerPercentage, this.maxRemainingPercentage)
          || !this.ownerAddressData.address1
          || !this.ownerAddressData.city
          || !this.ownerAddressData.state
          || !this.ownerAddressData.zipCode
          || !validateZipCode(this.ownerAddressData.zipCode)
          || !validateDate(this.ownerData.dob, { maxDate: this.now, dob: true }).isValid
          || !securityPin(this.ownerData.security_pin)
          || this.smallPercentageNumber
          || this.disableSave;
    },

    disableInputFields(): boolean {
      return this.disabledInputs || this.processing;
    },

    merchantUuid(): string {
      return this.getUserVerifyData?.merchantUid;
    },
  },

  watch: {
    saveDisabled(val: boolean) {
      this.$emit('saveDisabled', val);
    },

    editing(val: boolean) {
      if (val) {
        this.ownerData.middleName = this.ownerData.middleName === 'N/A' ? '' : this.ownerData.middleName;
        this.ownerAddressData.address2 = this.ownerAddressData.address2 === 'N/A' ? '' : this.ownerAddressData.address2;
      }
    },

    autoFillData(val: boolean) {
      if (val) {
        this.ownerData.firstName = this.getUserVerifyData?.firstName;
        this.ownerData.lastName = this.getUserVerifyData?.lastName;
        this.ownerData.middleName = this.getUserVerifyData?.middleName;
        this.ownerData.phoneNumber = Inputmask.format(this.getUserVerifyData?.userMobile, {
          mask: '+1 (999) 999-9999',
        });
      } else {
        this.ownerData.firstName = '';
        this.ownerData.lastName = '';
        this.ownerData.middleName = '';
        this.ownerData.phoneNumber = '';
      }
    },
  },

  mounted() {
    this.ownerData = cloneDeep(this.owner);
    this.ssnCheck = this.ownerData.ssn;
    this.setOwnerAddressData();
    if (this.fromVerifyDetails) {
      this.ownerData.middleName = this.ownerData.middleName || 'N/A';
    }
  },

  methods: {
    onSecurityPinUpdated(val: any): void {
      this.ownerData.security_pin = val;
    },

    onEmailBlur() {
      if (typeof this.useSameEmail === 'boolean') return;

      this.isEmailUsed = this.userVerifyDataEmail === this.ownerData.email;
      this.useSameEmail = null;
    },

    onEmailUpdate() {
      this.isEmailUsed = false;
      this.useSameEmail = null;
    },

    useSameEmailAccept(): void {
      this.useSameEmail = true;
    },

    useSameEmailDecline(): void {
      this.useSameEmail = false;
      this.ownerData.email = '';
    },

    checkSsnDuplicate() {
      if (this.ssnCheck !== this.ownerData.ssn) {
        this.ssnErrorMessage = 'Social Security Numbers do not match.';
      } else {
        this.ssnErrorMessage = '';
      }
    },

    async openAddressValidation() {
      this.disableSave = true;

      const isValid = await this.validateAddress(this.ownerAddressData);

      if (isValid) {
        this.showAddressConfirmation = true;
      }

      this.disableSave = false;

      return isValid;
    },

    closeConfirmation() {
      const notEmpty = !!Object
        .keys(this.ownerAddressData)
        .find((key: string) => {
          const prop = (this.ownerData as any)[key];
          if (typeof prop === 'object') return prop.value;
          return prop;
        });

      if (notEmpty) {
        this.setOwnerAddressData();
      }

      this.showAddressConfirmation = false;
    },

    async saveOwner(): Promise<void> {
      this.showAddressConfirmation = false;

      this.ownerData = {
        ...this.ownerData,
        middleName: this.fromVerifyDetails ? this.ownerData.middleName || 'N/A' : this.ownerData.middleName,
        ...this.validatedAddress,
      };

      this.setOwnerAddressData();

      try {
        const response = await addOrUpdateOwner(this.ownerData, this.merchantUuid);

        if (response.status > 299) {
          this.$store.dispatch('Ui/addGlobalAlert', {
            text: 'Something went wrong when trying to add or update owner',
            type: GlobalAlertTypes.ERROR,
            timed: true,
          });
          return;
        }

        this.ownerData.id = response.data.id;

        this.$store.commit(
          'MerchantOnboarding/setSingleOwner',
          {
            owner: this.ownerData,
            index: this.index,
          },
        );

        this.$store.dispatch('MerchantOnboarding/setOwnerSaved', true);

        this.onSave({
          ...this.ownerData,
          middleName: this.ownerData.middleName === 'N/A' ? '' : this.ownerData.middleName,
        }, this.index);

        this.$emit('ownerAdded');
      } catch (error) {
        this.$store.dispatch('Ui/addGlobalAlert', {
          text: 'Something went wrong when trying to add or update owner',
          type: GlobalAlertTypes.ERROR,
          timed: true,
        });

        const axiosError = getAxiosError(error);
        if (axiosError) {
          // The phone number error is returned from the server in the form of:
          // {"phone_number":{"phone_number":["The phone number entered is not valid."]}}
          // which is different from the usual error format.
          const phoneError = axiosError.response?.data.phone_number;
          if (phoneError) {
            this.phoneErrorMessage = phoneError.phone_number[0];
          }
        }
      }
    },

    setOwnerAddressData() {
      const { state } = this.ownerData;
      this.ownerAddressData = {
        address1: this.ownerData.address1,
        address2: this.fromVerifyDetails ? this.ownerData.address2 || 'N/A' : this.ownerData.address2,
        city: this.ownerData.city,
        state: typeof state === 'string' ? state : state?.value || null,
        zipCode: this.ownerData.zipCode,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";

.percentage-input {
  margin-bottom: .5rem;
}

.warning-msg {
  color: var(--grayscale-color-1) !important;
}

:deep() {
  .warning-border .bv-no-focus-ring .standard-input-field__legend .standard-input-field__label {
    color: var(--grayscale-color-1) !important;
  }

  .warning-border .bv-no-focus-ring .standard-input-field__input-group {
    border-color: var(--grayscale-color-1) !important;
  }
}
</style>
