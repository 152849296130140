<template>
  <div :class="classList" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GetProcessing from '@/mixins/GetProcessing';

export default defineComponent({
  name: 'CustomerPreferenceItem',

  mixins: [
    GetProcessing,
  ],

  props: {
    customerPreference: { type: String, default: '' },
  },

  computed: {
    classList(): string {
      const baseClass: string = 'customer-preference__item';

      const classList: Array<string> = [baseClass];

      if (this.customerPreference) {
        classList.push(`${baseClass}--${this.$props.customerPreference}`);
      }

      return classList.join(' ');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/rate-sheet-options/rate-sheet-options-customer-preferences-item";

</style>
