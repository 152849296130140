import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createBlock(_component_custom_button, {
    class: "disabled-box-shadow standard-data-table-refresh-button",
    disabled: _ctx.buttonDisabled,
    "data-test": _ctx.dataTestAttr,
    variant: "icon",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('click')), ["stop"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_icon, {
        "aria-label": "Refresh",
        class: _normalizeClass({ 'mdi-spin': _ctx.active }),
        density: "compact",
        size: "24"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" mdi-refresh ")
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["disabled", "data-test"]))
}