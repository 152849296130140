<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--one" data-test="MOB-step1">
    <v-card v-if="userDataVerified">
      <v-card-title>
        <h3 class="my-0 step-header">
          Mobile Phone Verification
        </h3>
      </v-card-title>

      <v-card-text v-if="!showOtpInput" data-test="bodyText">
        <p v-if="!isReEntry" class="card-text-paragraph">
          Thanks
          <span
            v-private
            data-test="businessInput">
            {{ businessName }}!
          </span>
          Click the button below to receive a One-Time Passcode by text message.
        </p>

        <p v-else class="card-text-paragraph">
          Welcome back
          <span v-private data-test="businessInput">
            {{ businessName }}!
          </span>
          Click below to receive a One-Time Passcode and re-enter your application.
        </p>

        <div class="card-text__info-group ml-2">
          <div class="card-text__info-group__title">
            <span data-test="mobilePhoneNumberLabel"> Your Mobile Phone Number</span>
          </div>
          <div>
            <span
              v-private
              class="value"
              data-test="mobilePhoneNumberValue">
              {{ formatPhoneNumber(phoneNumber) }}
            </span>
          </div>
        </div>

        <custom-button
          v-if="userId"
          :disabled="nextBtnDisabled"
          full-width
          label="Send Pass Code to My Mobile Phone"
          @click="nextStep" />
      </v-card-text>

      <v-card-text v-else class="mt-4">
        <mobile-phone-verification
          :dialog="show"
          :show-header="false"
          :merchant-user-email="emailAddress"
          :on-phone-number-updated="updatePhoneNumberInLoanApplyWizardData"
          :phone-number="phoneNumber"
          :session-token="otpRegisterSessionToken"
          :verify-code="verifyCode"
          :autofocus-enabled="true"
          :disable-btn="disableOtpSubmitButton" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import OtpMixin from '@/mixins/OtpMixin';
import MobilePhoneVerification from '@/components/Inputs/Otp/MobilePhoneVerification.vue';
import GetSponsorMixin from '@/mixins/GetSponsor';
import honeypot from 'honeypot-run';
import { PageTypesShorthand } from '@/enums/PageTypes';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import MerchantStatusReasons from '@/enums/Merchant/MerchantStatusReasons';

export default defineComponent({
  name: 'StepOne',

  components: {
    CustomButton,
    MobilePhoneVerification,
  },

  mixins: [
    NavigatesStepsMixin,
    HoneypotTrackMixin,
    GetSponsorMixin,
    OtpMixin,
    FeatureFlagsMixin,
  ],

  data() {
    return {
      ownStep: 1,
      show: true,
      showOtpInput: false,
      disableNextButton: false,
      disableOtpSubmitButton: false,
      hpEventName: 'Email Page Visit',
      otpHpEventName: 'OTP Page Visit',
    };
  },

  computed: {
    userId(): number {
      return this.getUserVerifyData?.userId;
    },

    isReEntry(): boolean {
      return this.appStatusReason !== MerchantStatusReasons.POST_EMAIL_PRE_OTP
      && this.appStatusReason !== MerchantStatusReasons.POST_OTP_PRE_PASSWORD
      && this.isReEntryEnabled;
    },

    isReEntryEnabled(): boolean {
      return this.isFeatureEnabled(FeatureFlagsConstants.RE_ENTRY);
    },

    appStatusReason(): string {
      return this.getUserVerifyData.appStatusReason;
    },

    phoneNumber(): string {
      return this.getUserVerifyData?.userMobile;
    },

    merchantUuid(): string {
      return this.getUserVerifyData?.merchantUid;
    },

    businessName(): string {
      return this.getUserVerifyData?.businessName;
    },

    emailAddress(): string {
      return this.getUserVerifyData?.emailAddress;
    },

    userDataVerified(): boolean {
      return !!this.getUserVerifyData?.userId;
    },

    nextBtnDisabled(): boolean {
      return !this.phoneNumber
      || (this.isBrandProFund && !this.sponsorId)
      || this.disableNextButton;
    },
  },

  watch: {
    merchantUuid: {
      immediate: true,
      handler(val: string) {
        if (val && window.honeypot?.didInit) {
          honeypot.identify(val);
          this.trackEvent(this.hpEventName, PageTypesShorthand.MOB);
        }
      },
    },
  },

  methods: {
    formatPhoneNumber(val: string) {
      const cleanedNumber = val.replace(/\D/g, '');
      const countryNum = `+${cleanedNumber.slice(0, 1)}`;
      const stateNum = `${cleanedNumber.slice(1, 4)}`;
      const formattedNumber = `${countryNum}(${stateNum})${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(7, 11)}`;
      return formattedNumber;
    },

    async nextStep() {
      this.disableNextButton = true;

      const { error } = await this.$store.dispatch(
        'MerchantOnboarding/dispatchOtpRegister',
        this.phoneNumber,
      );

      this.disableNextButton = false;

      if (error) return;

      this.showOtpInput = true;
      this.trackEvent(this.otpHpEventName, PageTypesShorthand.MOB);
    },

    updatePhoneNumberInLoanApplyWizardData(userMobile: string): void {
      const payload = { ...this.getUserVerifyData, userMobile };
      this.$store.commit('MerchantOnboarding/setUserVerifyData', payload);
    },

    async verifyCode(securityCode: string): Promise<void> {
      this.disableOtpSubmitButton = true;

      const responseStatus = await this.$store.dispatch(
        'MerchantOnboarding/dispatchOtpVerify',
        {
          phoneNumber: this.phoneNumber,
          sessionToken: this.otpRegisterSessionToken,
          securityCode,
        },
      );

      if (responseStatus === 200) {
        this.closeStandardInputOtpModal();
        this.$store.dispatch('MerchantOnboarding/setPassedPhoneVerification', true);
        this.$store.commit('MerchantOnboarding/setShowMobStepper', true);

        if (this.isReEntry) {
          this.$emit('navigateToReEntry');
        } else {
          this.goToNextStep();
        }
      }

      this.disableOtpSubmitButton = false;
    },

    closeStandardInputOtpModal(): void {
      this.show = false;
    },

    goToNextStep(): void {
      this.goToStep(this.ownStep + 1);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/vuetify/elevation.scss";

.step-header {
  font-size: 1.125rem;
}
.value {
  font-size: 1rem;
  font-weight: bold;
  color: var(--grayscale-color-1);

  @include mobile {
    font-size: 0.875rem;
  }
}

.standard-wizard__step {
  background-color: #fff;
  @include mobile {
    margin-bottom: 3.125rem;
  }

  .card-text__info-group {
    &__title {
      margin-bottom: 0.5rem !important;
    }

    @include mobile {
      margin-bottom: 1.5rem !important;
    }
  }

  :deep(.v-card) {
    text-align: left;
    box-shadow: $v-elevation-4;
    border: 1px solid var(--grayscale-color-2);
    border-radius: 8px;
    .v-card-title {
      padding: 1rem;
      background-color: var(--grayscale-color-5);
    }
    .card-text-paragraph {
      font-size: 1rem;
      margin: 1rem 0 2rem 0;
    }
    .card-text__info-group__inline {
      flex: 1;
    }
  }
}
</style>
