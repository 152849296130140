<template>
  <div>
    <v-dialog
      v-model="showDialog"
      :width="width"
      :persistent="true"
      :content-class="dialogClass"
      @click:outside="onClose"
      @keydown.esc="onClose">
      <v-card>
        <div class="dialog-title-wrap">
          <v-card-title class="section-header">
            <div class="d-flex">
              <span v-private class="mr-2 section-header__title">{{ title }}</span>
              <standard-data-table-refresh-button
                class="refresh-button"
                data-test="recentRefreshBtn"
                :active="refreshing"
                @click="refundableTransactions" />
            </div>
            <div class="section-header__actions">
              <v-btn
                class="close-icon"
                icon="mdi-close"
                variant="plain"
                size="small"
                rounded
                aria-label="Close"
                @click="onClose" />
            </div>
          </v-card-title>
        </div>

        <v-card-text>
          <span v-if="!refundTransactions.length && !refreshing" class="no-transactions">
            There are no available transactions to refund.
          </span>

          <v-data-table
            v-else
            :headers="headers"
            :items="refundTransactions"
            item-key="created"
            :mobile="isMobile"
            :sort-by="[{ key: 'created' }]"
            :items-per-page="5"
            :hide-default-header="refreshing"
            :loading="refreshing"
            :disable-sort="$vuetify.display.xs"
            :hide-default-footer="refundTransactions.length < 6"
            class="merchant-portal-recent-activity__data-table">
            <template #body="{ items }">
              <!-- eslint-disable-next-line vue/no-v-for-template-key -->
              <template v-for="(item, itemKey) in items" :key="itemKey">
                <tr
                  :class="{ 'v-data-table__tr--mobile': isMobile, 'expandable-row': item.refunds }"
                  @click="openDialog(item)">
                  <table-mobile-cell
                    v-if="isMobile"
                    :header="headers[0].title"
                    :value="item.description" />
                  <td v-else>
                    {{ item.description }}
                  </td>

                  <table-mobile-cell
                    v-if="isMobile"
                    :header="headers[1].title"
                    :value="item.amount"
                    private-value />
                  <td v-else v-private>
                    {{ item.amount }}
                  </td>

                  <table-mobile-cell
                    v-if="isMobile"
                    :header="headers[2].title"
                    :value="item.date" />
                  <td v-else>
                    {{ item.date }}
                  </td>

                  <table-mobile-cell
                    v-if="isMobile"
                    :header="headers[3].title"
                    :value="item.submittedBy"
                    private-value />
                  <td v-else v-private>
                    {{ item.submittedBy }}
                  </td>

                  <td
                    v-if="item.refunds"
                    class="justify-end text-right"
                    :class="{ 'v-table__mobile-row': isMobile }">
                    <v-btn
                      :icon="item.hidden ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                      class="expand-icon"
                      variant="plain"
                      aria-label="Expand"
                      @click.stop="item.hidden = !item.hidden" />
                  </td>
                </tr>

                <table-refunds
                  v-if="item.refunds"
                  :key="`${itemKey}-refunds`"
                  :expanded="!item.hidden"
                  :refunds="item.refunds"
                  :headers="headers" />
              </template>
            </template>
          </v-data-table>

          <refund-amounts
            :dialog="showAmountDialog"
            :on-cancel="closeDialog"
            :transaction-data="selectedItem"
            :consumer-data="consumer"
            :is-settled="settled"
            @refund="onRefund" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <alert-modal
      :dialog="showAlert"
      :on-close="closeAlertDialog"
      :type="alertType">
      {{ alertText }}
    </alert-modal>
    <standard-dialog
      title="Refund Action Not Available"
      :dialog="showRefundNotAvailableDialog"
      :show-cancel-cta="false"
      :show-clear-cta="false"
      :on-cta="() => showRefundNotAvailableDialog = false"
      button-label="Close"
      @cancel="showRefundNotAvailableDialog = false">
      <template #body>
        <p>
          This particular transaction has not settled yet, and therefore is not refundable.
          Please check back later.
        </p>
      </template>
    </standard-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDataTableRefreshButton
  from '@/components/Buttons/DataTableRefreshButton.vue';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import DateTimeFormatLong from '@/filters/DateTimeFormatLong';
import RefundAmounts from '@/components/Dialogs/RefundAmounts.vue';
import AlertModal from '@/components/Dialogs/AlertModal.vue';
import {
  AlertMessageInterface,
  RowDataInterface,
} from '@/components/CustomerSummaryCard/RefundsDialog/types';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';
import StandardDialog from '@/components/Dialogs/index.vue';
import TableMobileCell from '@/components/CustomerSummaryCard/RefundsDialog/TableMobileCell.vue';
import TableRefunds from '@/components/CustomerSummaryCard/RefundsDialog/TableRefunds.vue';

function formatTransactionRow(data: any): RowDataInterface {
  return {
    description: data.transaction_description,
    amount: CurrencyFormatLong(data.transaction_amount),
    maxRefundableAmount: CurrencyFormatLong(data.max_refundable_amount),
    date: DateTimeFormatLong(data.created),
    submittedBy: data.submitted_by,
    paymentIdentifier: data.payment_identifier,
  };
}

export default defineComponent({
  name: 'RefundsDialog',

  components: {
    StandardDataTableRefreshButton,
    RefundAmounts,
    AlertModal,
    TableMobileCell,
    TableRefunds,
    StandardDialog,
  },

  props: {
    dialog: { type: Boolean, default: false },
    width: { type: String, default: '500' },
    title: { type: String, default: '' },
    onClose: { type: Function, default: () => null },
    consumerData: { type: Object, default: () => ({}) },
  },

  data() {
    const selectedItem: Object = {};
    const consumer: any = {};
    const headers: Array<TableHeaderInterface> = [
      {
        title: 'Transaction Description',
        align: 'left',
        sortable: true,
        value: 'description',
        width: 300,
      },
      {
        title: 'Amount',
        align: 'left',
        sortable: true,
        value: 'amount',
        width: 130,
      },
      {
        title: 'Date/Time',
        align: 'left',
        sortable: true,
        value: 'date',
        width: 250,
      },
      {
        title: 'Submitted By',
        align: 'left',
        sortable: true,
        value: 'submittedBy',
        width: 230,
      },
      {
        title: '',
        align: 'left',
        sortable: false,
        value: 'expandBtn',
      },
    ];
    return {
      showDialog: false,
      consumer,
      showAmountDialog: false,
      selectedItem,
      showAlert: false,
      settled: false,
      alertText: '',
      alertType: 'error',
      refreshing: false,
      showRefundNotAvailableDialog: false,
      headers,
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.display.xs;
    },

    refundTransactions(): Array<RowDataInterface> {
      const transactions = this.$store.getters['MerchantPortal/getRefundableTransactions'];
      return transactions.map((transaction: any) => {
        const row = formatTransactionRow(transaction);
        if (transaction.refunded_transactions?.length) {
          row.hidden = true;
          row.paymentIdentifier = transaction.payment_identifier;
          row.refunds = transaction.refunded_transactions
            .map((it: any) => formatTransactionRow(it));
        }
        return row;
      });
    },

    dialogClass(): string {
      return this.$vuetify.display.xs ? 'mx-0' : '';
    },

    merchantSupportPhone(): string {
      return this.$store.getters['Ui/getFooterData'].support_number;
    },
  },

  watch: {
    dialog: {
      immediate: true,
      handler(val: any) {
        this.showDialog = val;
      },
    },
    consumerData: {
      immediate: true,
      handler(val: any) {
        this.consumer = val;
      },
    },
  },

  async created() {
    await this.refundableTransactions();
  },

  methods: {
    async refundableTransactions(): Promise<void> {
      this.refreshing = true;
      await this.$store.dispatch('MerchantPortal/dispatchGetRefundTransactions', this.consumer.consumer_account.uuid);
      this.refreshing = false;
    },

    async openDialog(item: any): Promise<void> {
      const amount = item.amount.replace('$', '');
      const maxRefundableAmount = item.maxRefundableAmount.replace('$', '');
      const res = await this.$store.dispatch('MerchantPortal/checkTransactionSettled', item.paymentIdentifier);
      if (res.data) {
        if (res.data.settled === false && res.data.payment_provider === 'moov') {
          this.showRefundNotAvailableDialog = true;
          return;
        }

        this.settled = res.data.settled;
        this.selectedItem = {
          ...item,
          amount,
          maxRefundableAmount,
        };
        this.showAmountDialog = true;
      } else {
        this.showDialog = false;
        this.alertType = 'error';
        this.alertText = 'We cannot retrieve data to process a refund right now. Please try again later.';
        this.showAlert = true;
      }
    },

    closeDialog() {
      this.showAmountDialog = false;
    },

    closeAlertDialog() {
      this.showAlert = false;
      this.onClose();
    },

    onRefund(event: AlertMessageInterface) {
      this.showDialog = false;
      this.alertType = event.type;
      this.alertText = event.message;
      this.showAlert = true;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';
@import '@/assets/scss/portal-section';
@import '@/assets/scss/mixins/media_queries';

.section-header {
  padding-right: 0;
}

.merchant-portal-recent-activity {
  &__data-table {
    cursor: pointer;

    :deep() {
      td, th {
        border-bottom: none !important;
      }

      @include mobile {
        tr:not(:last-child) {
          border-bottom: 1px solid var(--grayscale-color-3);
        }
      }
    }

    .section-header {
      &__title {
        text-align: left;
      }
    }
  }
}

:deep(.v-card-text) {
  padding: 0 !important;
  min-height: 13.5rem;
}

:deep(.v-data-footer__select) {
  display: none;
}

:deep(.v-data-footer) {
  justify-content: flex-end;
}

.dialog-title-wrap {
  .v-card-title.font-weight-bold.lighten-2.table-title {
    word-break: break-word;
  }
}

.no-transactions {
  display: block;
  padding: 6rem 0 !important;
  text-align: center;
}

.expand-icon {
  color: var(--grayscale-color-1) !important;
  transition: transform .3s linear;
}

.expandable-row {
  font-weight: bold;
  font-style: italic;
}

.v-table__mobile-table-row {
  width: 100%;
}

@media screen and (max-width: $tablet-width-min) {
  .section-header {
    div.d-flex {
      width: 80%;
      text-wrap: wrap;
    }
  }
  .section-header .refresh-button {
    display: none;
  }
}
</style>
