import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_merchant_onboarding_wizard_application_waiting_approval = _resolveComponent("merchant-onboarding-wizard-application-waiting-approval")!
  const _component_standard_dialog = _resolveComponent("standard-dialog")!

  return (_openBlock(), _createBlock(_component_standard_dialog, {
    dialog: _ctx.dialog,
    "hide-header-close": true,
    persistent: true,
    "show-actions": false,
    "data-test": "waitingModal"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_merchant_onboarding_wizard_application_waiting_approval)
    ]),
    _: 1
  }, 8, ["dialog"]))
}