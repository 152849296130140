import * as FullStory from '@fullstory/browser';
import {
  ConsumerOnboardingIdentity,
  MerchantOnboardingIdentity,
  MerchantUserIdentity,
} from '@/logging/identityInterfaces';

export default class FullStoryLogger {
  private fs: any;

  private liveSessionReplayEnabled = false;

  constructor() {
    this.fs = FullStory;
  }

  startLogging(data: any) {
    if (data.FULLSTORY_ORG_ID) {
      this.fs.init({
        orgId: data.FULLSTORY_ORG_ID,
      });
    }

    if (this.liveSessionReplayEnabled) {
      this.startLiveSessionReplay();
    }
  }

  startLiveSessionReplay() {
    this.fs.getCurrentSessionURL(true);
  }

  identify(
    sessionId: string,
    data: MerchantUserIdentity | MerchantOnboardingIdentity | ConsumerOnboardingIdentity,
  ) {
    if (this.fs.isInitialized()) {
      this.fs.identify(sessionId, data);
    }
  }

  setUserVars(data: unknown) {
    if (this.fs.isInitialized()) {
      this.fs.setUserVars(data);
    }
  }
}
