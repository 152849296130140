<template>
  <v-form
    class="fs-unmask"
    data-test="MOB-step3-substep4"
    @submit.prevent="goToNextSubStep">
    <v-card-text>
      <select-input
        ref="businessStructure"
        v-model="stepData.businessStructure"
        :items="optionsBusinessStructure"
        data-test="businessInput"
        label="Business Structure" />

      <tax-id-input v-model="stepData.federalTaxId" required />

      <ssn-input
        v-if="showSsnInput"
        v-model="stepData.ssn"
        required
        label="Social Security Number" />

      <custom-button
        :disabled="nextSubstepDisabled"
        data-test="nextBtn"
        full-width
        type="submit">
        Next
      </custom-button>
    </v-card-text>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SelectInput from '@/components/Inputs/Select.vue';
import BusinessStructureMixin from '@/mixins/Merchant/BusinessStructureMixin';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import get from 'lodash/get';
import ssn from '@/validators/ssn';
import SsnInput from '@/components/Inputs/Ssn.vue';
import taxId from '@/validators/tax_id';
import TaxIdInput from '@/components/Inputs/TaxId.vue';

export default defineComponent({
  name: 'SubstepFour',

  components: {
    CustomButton,
    SsnInput,
    TaxIdInput,
    SelectInput,
  },

  mixins: [BusinessStructureMixin],

  props: {
    onNextSubStep: { type: Function, default: () => () => null },
  },

  data: () => ({
    ownSubStep: 4,
    continueButtonDisabled: false,
    stepData: {
      businessStructure: null,
      federalTaxId: '',
      ssn: '',
    },
  }),

  computed: {
    reEntryData(): any {
      return this.$store.getters['MerchantOnboarding/getMerchantReEntryData'];
    },

    reEntryDataAllAttributes(): any {
      return get(this.reEntryData, 'all_attributes', {});
    },

    nextSubstepDisabled(): boolean {
      return !this.stepData.businessStructure
          || (!this.showSsnInput && !taxId(this.stepData.federalTaxId))
          || (this.showSsnInput && !(taxId(this.stepData.federalTaxId) && ssn(this.stepData.ssn)))
          || this.continueButtonDisabled;
    },

    showSsnInput(): boolean {
      return this.shouldShowSsnInput(this.stepData.businessStructure);
    },
  },

  watch: {
    'stepData.businessStructure': {
      handler() {
        if (!this.showSsnInput) {
          this.stepData.ssn = '';
        }
      },
      deep: true,
    },
  },

  mounted() {
    const { businessStructure, federalTaxId } = this.$store.getters[
      'MerchantOnboarding/getAllAttributes'
    ];

    const reEntryFederalTaxId = this.reEntryDataAllAttributes.federalTaxId;
    const reEntryBusinessStructure = this.reEntryDataAllAttributes.businessStructure;
    const structure = businessStructure || reEntryBusinessStructure;

    this.stepData.federalTaxId = federalTaxId || reEntryFederalTaxId || '';

    if (structure) {
      this.stepData.businessStructure = structure;
    }
  },

  methods: {
    async goToNextSubStep() {
      this.continueButtonDisabled = true;

      const responseStatus = await this.$store.dispatch(
        'MerchantOnboarding/dispatchAllAttributes',
        this.stepData,
      );

      await this.$store.dispatch(
        'MerchantOnboarding/dispatchPatchApplication',
        'Post Business Structure / Pre DBS',
      );

      if (responseStatus === 201) {
        this.onNextSubStep(this.ownSubStep + 1);
      }

      this.continueButtonDisabled = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
</style>
