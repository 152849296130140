import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_transaction_table = _resolveComponent("transaction-table")!
  const _component_simple_card = _resolveComponent("simple-card")!

  return (_openBlock(), _createBlock(_component_simple_card, { class: "section-card" }, {
    body: _withCtx(() => [
      _createVNode(_component_transaction_table, {
        page: _ctx.currentPage,
        "onUpdate:page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
        "only-on-activity-center": true,
        "is-mobile": _ctx.isMobile,
        "items-per-page": 15,
        "items-per-page-options": _ctx.rowsDisplayed,
        "items-length": _ctx.transactionsRequestsCount,
        items: _ctx.transactionsRequests,
        "refreshing-from-parent": _ctx.refresh,
        expanded: "",
        onRefreshTable: _ctx.refreshTable,
        onUpdatePage: _ctx.sortTable,
        onUpdateSearchPage: _ctx.updateSearchPage
      }, null, 8, ["page", "is-mobile", "items-per-page-options", "items-length", "items", "refreshing-from-parent", "onRefreshTable", "onUpdatePage", "onUpdateSearchPage"])
    ]),
    _: 1
  }))
}