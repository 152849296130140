<template>
  <div class="rate-sheets-options-data-table__more-data-modal">
    <custom-button
      :data-test="dataTestAttr"
      :product-id-data-test="testAttribute"
      custom-data-test="btnMore"
      :disabled="disabled"
      variant="icon"
      @click="showMore">
      <v-icon>mdi-dots-vertical</v-icon>
    </custom-button>

    <standard-dialog
      :dialog="show"
      :on-cancel="closeMore"
      content-class="rate-sheet-product-more-info"
      :title="rateSheetOption.product_name">
      <template #body>
        <ul
          class="rate-sheets-options-data-table__more-data-modal__details-list"
          data-test="detailsList">
          <li v-if="rateSheetOption.top_left_content">
            <strong>{{ rateSheetOption.top_left_title }}:</strong>
            {{ rateSheetOption.top_left_content }}
          </li>

          <li v-if="rateSheetOption.top_center_content">
            <strong>{{ rateSheetOption.top_center_title }}:</strong>
            {{ rateSheetOption.top_center_content }}
          </li>

          <li v-if="rateSheetOption.top_right_content">
            <strong>{{ rateSheetOption.top_right_title }}:</strong>
            {{ rateSheetOption.top_right_content }}
          </li>

          <li v-if="rateSheetOption.mid_left_content">
            <strong>{{ rateSheetOption.mid_left_title }}:</strong>
            {{ rateSheetOption.mid_left_content }}
          </li>

          <li v-if="rateSheetOption.mid_center_content">
            <strong>{{ rateSheetOption.mid_center_title }}:</strong>
            {{ rateSheetOption.mid_center_content }}
          </li>

          <li v-if="rateSheetOption.mid_right_content">
            <strong>{{ rateSheetOption.mid_right_title }}:</strong>
            {{ rateSheetOption.mid_right_content }}
          </li>
        </ul>

        <div
          v-if="description"
          class="rate-sheets-options-data-table__more-data-modal__long-description"
          v-html="description" />
      </template>
      <template #actions>
        <custom-button
          v-if="merchantOnboarding"
          full-width
          @click="closeMore">
          Close
        </custom-button>

        <custom-button
          v-if="!merchantOnboarding && !optionSelection"
          full-width
          :variant="isMobile ? 'primary' : 'secondary'"
          :class="isMobile ? '' : 'custom-button-bg'"
          :data-test="dataTestAttr"
          :product-id-data-test="rateSheetOption.product_name"
          :custom-data-test="buttonTestAttribute"
          @click="showConfirmRemovalDialog">
          {{ rateSheetAction }}
        </custom-button>

        <custom-button
          v-if="!merchantOnboarding && optionSelection"
          full-width
          size="small"
          @click="closeMore">
          Close
        </custom-button>
      </template>
    </standard-dialog>
    <confirm-dialog
      :on-cancel="hideConfirmRemovalDialog"
      :show-confirm-dialog="confirmDialog"
      :show-actions="false"
      title-text="Remove from rate sheet">
      <template #body>
        <span>
          Are you sure you want to remove this product?
        </span>
      </template>
      <template #actions>
        <custom-button
          size="small"
          :data-test="dataTestAttr"
          :product-id-data-test="rateSheetOption.product_name"
          custom-data-test="cancelBtn"
          @click="hideConfirmRemovalDialog">
          Cancel
        </custom-button>
        <custom-button
          size="small"
          :data-test="dataTestAttr"
          :product-id-data-test="rateSheetOption.product_name"
          custom-data-test="confirmBtn"
          @click="selectRateSheetOption">
          Confirm
        </custom-button>
      </template>
    </confirm-dialog>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { get } from 'lodash';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog.vue';

export default defineComponent({
  name: 'RateSheetOptionsMoreData',

  components: {
    ConfirmDialog,
    CustomButton,
    StandardDialog,
  },

  props: {
    disabled: { type: Boolean, default: false },
    dataTestAttr: { type: String, default: '' },
    rateSheetAction: { type: String, default: 'Remove From Rate Sheet' },
    rateSheetOption: { type: Object as PropType<RateSheetData>, default: null },
    onCta: { type: Function, default: () => () => null },
    merchantOnboarding: { type: Boolean, default: false },
    optionSelection: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    isAdditional: { type: Boolean, default: false },
  },

  data: () => ({
    show: false,
    confirmDialog: false,
  }),

  computed: {
    description(): string {
      return get(this.rateSheetOption, 'long_description', '');
    },

    testAttribute(): string {
      return this.isAdditional
        ? `${this.rateSheetOption.product_name}-additionalProduct`
        : this.rateSheetOption.product_name;
    },

    buttonTestAttribute(): string {
      return this.rateSheetAction === 'Remove From Rate Sheet'
        ? 'btnRemoveFromRateSheet'
        : 'btnAddToRateSheet-additionalProduct-';
    },
  },

  methods: {
    showConfirmRemovalDialog() {
      if (this.rateSheetAction === 'Remove From Rate Sheet') {
        this.confirmDialog = true;
      } else {
        this.$emit('toggle', this.rateSheetOption);
        this.show = false;
      }
    },

    hideConfirmRemovalDialog() {
      this.confirmDialog = false;
    },

    selectRateSheetOption() {
      // eslint-disable-next-line vue/no-mutating-props
      this.rateSheetOption.is_selected = !this.rateSheetOption.is_selected;
      this.hideConfirmRemovalDialog();
      this.closeMore();
      this.$emit('toggle', this.rateSheetOption);
    },

    showMore(): void {
      this.show = true;
    },

    closeMore(): void {
      this.show = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/rate-sheet-options/rate-sheet-options-more-data";
@import "@/assets/scss/components/dialogs";

ul {
  margin-bottom: 1rem;
}

.dialog-wrap button.custom-button-bg {
  background-color: #fff !important;
}
</style>
