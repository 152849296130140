import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CreditEngineCheckMixin',

  computed: {
    creditEngineCheck(): any {
      return this.$store.getters['Consumer/getCreditEngineCheck'];
    },
  },
});
