import { PaginatedResponse, RequestOptions } from '@/api/types';
import {
  Ref, ref, watch, reactive,
  WatchStopHandle,
} from 'vue';
import { useDebounce } from './useDebounce';

type Request<T> = (options: RequestOptions) => PaginatedResponse<T>

interface UseTableRequestControls {
  loading?: Ref<boolean>;
  search?: Ref<string>;
  orderBy?: Ref<string> | string;
  orderDesc?: Ref<boolean>;
  pageSize?: Ref<number> | number;
  filters?: Record<string, string | string[] | undefined>;
}

// eslint-disable-next-line import/prefer-default-export
export const useTableRequests = <T>(request: Request<T>, controls?: UseTableRequestControls) => {
  const count = ref(0);
  const page = ref(1);
  const pageSize = ref(controls?.pageSize ?? 5);
  const results = ref<T[]>([]);
  const loading = ref(controls?.loading ?? false);
  const search = ref(controls?.search ?? '');
  const orderBy = ref(controls?.orderBy);
  const orderDesc = ref(controls?.orderDesc);
  const filters = reactive(controls?.filters ?? {});

  const fetchData = async () => {
    loading.value = true;
    const ordering = orderBy?.value ? `${orderDesc?.value ? '-' : ''}${orderBy.value}` : undefined;

    try {
      const { data } = await request({
        filters,
        params: {
          page: page.value,
          page_size: pageSize.value,
          search: search.value || undefined,
          ordering,
        },
      });

      count.value = data.count;
      results.value = data.results;
    } finally {
      loading.value = false;
    }
  };

  const sendToFirstPage = () => {
    if (page.value === 1) {
      fetchData();
      return;
    }

    page.value = 1;
  };

  const { debounce } = useDebounce();
  const debouncedSearch = debounce(() => sendToFirstPage());

  let searchWatcher: WatchStopHandle;
  let pageWatcher: WatchStopHandle;
  let filtersWatcher: WatchStopHandle;

  function setWatchers() {
    pageWatcher = watch(page, fetchData);
    filtersWatcher = watch(filters, sendToFirstPage, { deep: true });
    searchWatcher = watch(search, debouncedSearch);
  }

  function fetchDataResetWatchers() {
    debouncedSearch.cancel();
    pageWatcher();
    filtersWatcher();
    searchWatcher();

    return fetchData().finally(setWatchers);
  }

  setWatchers();

  return {
    fetchData, count, page, results, loading, search, fetchDataResetWatchers,
  };
};
