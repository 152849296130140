<template>
  <v-checkbox
    v-model="internalValue"
    v-bind="$attrs"
    color="#4C5560"
    :ripple="ripple">
    <template #label>
      <slot name="label" />
    </template>
  </v-checkbox>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CheckboxInput',

  props: {
    inputValue: { type: [String, Number], default: null },
    ripple: { type: Boolean, default: false },
  },

  data() {
    return {
      internalValue: false,
    };
  },

  watch: {
    inputValue: {
      immediate: true,
      handler(value: any) {
        this.internalValue = value;
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.v-checkbox {
  margin-bottom: 0 !important;
  color: var(--grayscale-color-1) !important;
}

:deep(.v-input__control) {
  .v-selection-control {
    display: flex;
    align-items: flex-start;
    margin: 0 !important;

    .v-label {
      margin-bottom: 0;
      height: 100%;
      box-sizing: border-box;

      p {
        margin: 0;
      }
    }

    &__input {
      &:hover::before {
        opacity: 0;
      }

      .v-icon {
        opacity: 1;
      }
    }
  }

  .v-messages {
    display: none;
  }
}
</style>
