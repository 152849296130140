import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    defaultSupportEmail: 'merchants@momnt.com',
  }),

  computed: {
    supportEmail(): string {
      const brandingData = this.$store.getters['Ui/getFooterData'] || {};
      return brandingData.support_email || this.defaultSupportEmail;
    },

    supportMailTo(): string {
      return `mailto:${this.supportEmail}`;
    },
  },
});
