<template>
  <div>
    <v-col
      v-if="!isVerificationFailedStatus"
      lg="12"
      class="payment-options-select__msg pb-0 pt-4 px-0">
      <div v-if="merchantData" data-test="gladAlert">
        <div v-if="hasRepayAccount" class="px-4">
          To get started, please confirm your bank account ending in
          <strong>{{ lastFour }}</strong>.
          We will send two deposits to your bank account,
          and later you will verify the amount of those deposits to complete the connection.
        </div>
        <div v-else class="px-4">
          To connect your bank account, please provide the information below.
          We will send two deposits to your bank account,
          and later you will verify the amount of those deposits
          to complete the connection.
        </div>
      </div>
    </v-col>

    <custom-button
      v-if="isVerificationFailedStatus"
      class="authorize-button"
      full-width
      @click="resendDeposits">
      Send New Deposits
    </custom-button>
    <payment-form-fields
      v-else
      :block-cols="0"
      :last-four="lastFour"
      :has-repay-acc="hasRepayAccount"
      :show-cancel-button="showCancelButton"
      :is-onboarding="isOnboarding"
      @payTokenSuccess="$emit('passTokenSuccess')"
      @sendFormDetails="sendFormDetails"
      @cancelForm="$emit('cancelForm')" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PaymentFormFields from '@/components/PaymentProviders/FormFields.vue';
import UsersRolesEnum from '@/enums/UsersRolesEnum';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import BANK_ACCOUNT_STATUSES from '@/constants/PaymentConstants';
import { PaymentProvidersInterface } from '@/interfaces/merchantPortal/PaymentProvidersInterface';

export default defineComponent({
  name: 'PaymentForm',

  components: {
    CustomButton,
    PaymentFormFields,
  },

  props: {
    lastFour: { type: String, default: null },
    showCancelButton: { type: Boolean, default: true },
  },

  computed: {
    merchantData(): any {
      return this.$store.getters['MerchantPortal/getMerchantData'];
    },

    paymentProviders(): PaymentProvidersInterface {
      return this.$store.getters['MerchantPortal/getPaymentProviders'];
    },

    isOnboarding(): boolean {
      return this.$route.name === 'merchant-onboarding';
    },

    isVerificationFailedStatus(): boolean {
      return this.$store.getters['MerchantPortal/getPaymentProviders']
        .status === BANK_ACCOUNT_STATUSES.VERIFICATION_FAILED;
    },

    hasRepayAccount() {
    // data for this should come from backend
      return UsersRolesEnum.ADMIN
    && this.paymentProviders?.repay?.can_transact
    && this.merchantData?.merchant?.has_two_or_more_products_active;
    },
  },

  methods: {
    sendFormDetails(data: any) {
      this.$emit('sendFormDetails', data);
    },

    async resendDeposits() {
      await this.$store.dispatch('MerchantPortal/storeTokenPayment', {
        ach_account_number: null,
        ach_routing_number: null,
      });
      this.$store.dispatch('Repay/setCardPassedState', true);

      this.$emit('passTokenSuccess');
    },
  },

});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/mixins";

.payment-options-select__msg {
  max-width: 100%;
  background-color: var(--grayscale-color-4);
}
</style>
