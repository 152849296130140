import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80d74f2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_currency_input = _resolveComponent("currency-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_customer_summary_send_request_modal = _resolveComponent("customer-summary-send-request-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_form, {
      class: "customer-summary-card-send",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_currency_input, {
          modelValue: _ctx.amount,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.amount) = $event)),
          "allow-cents": "",
          "data-test": "inputAmount",
          disabled: _ctx.disableInputs,
          class: "amount-input py-3",
          "error-messages": _ctx.customErrorMessage.amount,
          label: "Pay Request Amount",
          max: _ctx.creditBalance,
          min: 0,
          "show-icon": ""
        }, null, 8, ["modelValue", "disabled", "error-messages", "max"]),
        _createVNode(_component_select_input, {
          modelValue: _ctx.transactionCode,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.transactionCode) = $event)),
          items: _ctx.transactionDescriptions,
          disabled: _ctx.disableInputs,
          "data-test": "inputTransactionDesc",
          class: "transaction-description-input old-v-select py-3",
          "hide-dropdown-icon": "",
          "item-title": "name",
          "item-value": "transaction_code",
          label: "Transaction Description"
        }, null, 8, ["modelValue", "items", "disabled"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_custom_button, {
            "full-width": "",
            disabled: _ctx.sendDisabled,
            class: "customer-summary-card-send__cta",
            "data-test": "sendButton",
            type: "submit",
            onClick: _ctx.showCustomerSummarySendRequestModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Send ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ]),
      _: 1
    }),
    (_ctx.consumerApplication && _ctx.requestData)
      ? (_openBlock(), _createBlock(_component_customer_summary_send_request_modal, {
          key: 0,
          dialog: _ctx.showSendRequest,
          "on-cta": _ctx.closeCustomerSummarySendRequestModal,
          "on-cancel": _ctx.closeCustomerSummarySendRequestModal,
          "request-data": _ctx.requestData,
          "selected-loan-application": _ctx.selectedLoanApplication
        }, null, 8, ["dialog", "on-cta", "on-cancel", "request-data", "selected-loan-application"]))
      : _createCommentVNode("", true)
  ]))
}