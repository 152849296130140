import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_standard_dialog = _resolveComponent("standard-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_standard_dialog, {
      dialog: _ctx.showConfirmDialog,
      width: "400",
      "data-test": "confirmModal",
      "content-class": "confirmDialog",
      "show-cancel-cta": _ctx.showActions,
      persistent: true,
      "on-cancel": _ctx.onCancel,
      "on-cta": _ctx.onConfirm,
      "button-label": _ctx.buttonLabel,
      "button-cancel-label": _ctx.buttonCancelLabel,
      title: _ctx.titleText,
      "light-title": _ctx.lightTitle,
      "cta-disabled": _ctx.disableConfirmButton
    }, {
      body: _withCtx(() => [
        _renderSlot(_ctx.$slots, "body", {}, undefined, true)
      ]),
      actions: _withCtx(() => [
        _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["dialog", "show-cancel-cta", "on-cancel", "on-cta", "button-label", "button-cancel-label", "title", "light-title", "cta-disabled"])
  ]))
}