<template>
  <span>
    {{ start }}<strong>{{ match }}</strong>{{ end }}
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AutocompleteItem',

  props: {
    start: { type: String, default: null },
    match: { type: String, default: null },
    end: { type: String, default: null },
  },
});
</script>
