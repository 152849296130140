<template>
  <text-input
    v-bind="attrs"
    :data-test="dataTestAttr"
    :label="labelAttr"
    :required="required"
    :rules="[validateUrl]" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import TextInput from '@/components/Inputs/Text.vue';
import isUrl from '@/validators/url';

export default defineComponent({
  name: 'UrlInput',

  components: { TextInput },

  mixins: [InputFieldMixin],

  data: () => ({
    defaultLabelAttr: 'URL',
    defaultDataTestAttr: 'urlInput',
  }),

  methods: {
    validateUrl(value: string | undefined): string | boolean {
      if (!value) return this.isRequired();

      return isUrl(value) || 'Please correct URL format';
    },
  },
});
</script>
