import GetCookieFromLocalStorage from '@/utils/GetCookieFromLocalStorage';

const AXIOS_CONFIG = () => ({
  headers: {
    'X-CSRFToken': GetCookieFromLocalStorage('csrftoken'),
    'Content-Type': 'application/json',
    Authorization: '',
  },
});

export default AXIOS_CONFIG;
