<template>
  <standard-dialog
    :dialog="true"
    :show-actions="true"
    width="440px"
    hide-header-close
    gray-background-title
    :title="title">
    <template #body>
      <p>
        To comply with states with a maximum APR cap, consumers in the following
        states who are eligible for a Deferred Interest product with a 7-year term
        will instead be offered a Deferred Interest product with a 4-year term.
        <strong>Both products have the same merchant fee.</strong>
      </p>

      <ul>
        <li>Iowa</li>
        <li>Maryland</li>
        <li>Washington D.C.</li>
      </ul>
    </template>

    <template #actions>
      <custom-button
        v-if="inRateSheetTable"
        full-width
        @click="$emit('close')">
        Close
      </custom-button>
      <custom-button
        v-else
        :disabled="disableButton"
        :loading="disableButton"
        full-width
        @click="$emit('acknowledge')">
        I Acknowledge These Changes
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { postConsentTypes } from '@/utils/Consents';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import StandardDialog from '@/components/Dialogs/index.vue';

const store = useStore();

const props = defineProps({
  merchantAppUuid: { type: String, default: null },
  inRateSheetTable: { type: Boolean, default: false },
  title: { type: String, default: 'Important Update' },
  disableButton: { type: Boolean, default: false },
});

async function popupSeenConsent() {
  const status = await postConsentTypes({
    consentTypes: [ConsentTypesEnum.APR_CAP_COMPLIANCE],
    merchant_application_uuid: props.merchantAppUuid,
    entity: ConsentEntityTypes.MERCHANT,
  });

  if (status === 201) {
    store.dispatch('MerchantPortal/setShowOfImportantChangesDialog', false);
  } else if (status < 200 || status > 299) {
    store.dispatch('Ui/addGlobalAlert', {
      text: 'Something went wrong',
      type: GlobalAlertTypes.ERROR,
      timed: true,
    });
  }
}

onMounted(() => {
  if (!props.inRateSheetTable && props.merchantAppUuid) popupSeenConsent();
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.dialog-title-wrap {
  background-color: var(--grayscale-color-4);
}

ul {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem !important;
  list-style-type: disc;
  list-style-position: inside;
}
</style>
