<template>
  <div class="section-header simple-card__header">
    <div class="section-header__title simple-card__header-text-wrapper">
      <div>
        <strong>
          {{ headerTitle }}
        </strong>
      </div>

      <div class="simple-card__header__info">
        <span data-test="allProductsLabel">
          *All products have a 5 month purchase window starting from approval
        </span>
      </div>
    </div>

    <rate-sheet-options-header-info-modal
      :show="show"
      :on-click="showModal"
      :on-cancel="closeModal"
      class="simple-card__header__button" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RateSheetOptionsHeaderInfoModal from '@/components/RateSheetOptions/HeaderInfoModal.vue';

export default defineComponent({
  name: 'RateSheetOptionsHeader',

  components: { RateSheetOptionsHeaderInfoModal },

  props: {
    headerTitle: { type: String, default: '' },
  },

  data: () => ({
    show: false,
  }),

  methods: {
    showModal(): void {
      this.show = true;
    },

    closeModal(): void {
      this.show = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/rate-sheet-options/rate-sheet-options-data-table";
@import "@/assets/scss/portal-section";

.simple-card__header {
  white-space: normal !important;
  background: var(--grayscale-color-5) !important;

  .simple-card__header-text-wrapper {
    width: 97%;

    .simple-card__header__info {
     margin: auto;
    }
  }
}

@include mobile {
  .section-header__title {
    align-items: flex-start;

    .simple-card__header__info {
      margin: 0 !important;
    }
  }
}
</style>
