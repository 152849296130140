import { updateSession } from '@/api/account';
import { userLogout } from '@/api/index';
import GotoRouteName from '@/mixins/GotoRouteName';
import UserIsLoggedInMixin from '@/mixins/Auth/UserIsLoggedInMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserLogOutMixin',
  mixins: [GotoRouteName, UserIsLoggedInMixin],
  computed: {
    loggingOut(): boolean {
      return this.$store.getters['Auth/isLoggingOut'];
    },
  },
  methods: {
    async userLogout() {
      if (this.isLoggedIn && !this.loggingOut) {
        await updateSession({ logged_out_at: new Date() });
        await userLogout();
      }
    },
  },
});
