<template>
  <div ref="widgetContentContainer" :class="classList">
    <v-row justify="center" class="fs-unmask">
      <v-col
        md="12"
        sm="10"
        class="cob-container">
        <transition mode="out-in" name="slide">
          <consumer-loan-apply-wizard-complete-application-later-msg
            v-if="isCompleteLaterClicked"
            :email="email" />

          <consumer-loan-apply-wizard-application-canceled-msg v-else-if="applicationCanceled" />

          <consumer-invalid-link-msg v-else-if="!merchantId" />

          <consumer-expired-link-msg v-else-if="expiredLink" />

          <consumer-loan-apply-wizard-reentry-step
            v-else-if="reEntryActivated"
            @codeSent="navigateToStepThree" />

          <consumer-loan-apply-wizard-step-one
            v-else-if="wizardStep === 1"
            :data="data"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-two
            v-else-if="wizardStep === 2"
            :data="data"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-three
            v-else-if="wizardStep === 3"
            :data="data"
            :show-header="showHeader"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-four
            v-else-if="wizardStep === 4"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-five
            v-else-if="wizardStep === 5"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-six
            v-else-if="wizardStep === 6"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-seven
            v-else-if="wizardStep === 7"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-eight
            v-else-if="wizardStep === 8"
            :data="data"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-nine
            v-else-if="wizardStep === 9"
            :data="data"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-ten
            v-else-if="wizardStep === 10"
            :data="data"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-eleven
            v-else-if="wizardStep === 11"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-twelve
            v-else-if="wizardStep === 12"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-thirteen
            v-else-if="wizardStep === 13"
            @nextStep="navigateStep" />

          <consumer-loan-apply-wizard-step-fourteen
            v-else-if="wizardStep === 14" />
        </transition>
      </v-col>
    </v-row>

    <alert-modal
      :dialog="showErrorAlertModal"
      :hide-close="true"
      type="error">
      {{ errorResponseAlertModal }}
    </alert-modal>
  </div>
</template>

<script lang="ts">
import WidgetsMixin from '@/mixins/Widgets/WidgetsMixin';
import { get } from 'lodash';
import AlertModal from '@/components/Dialogs/AlertModal.vue';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import { honeypot } from 'honeypot-run';
import SocureIDMixin from '@/mixins/SocureIDMixin';
import { defineComponent } from 'vue';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';
import ReEntryMixin from '@/mixins/Consumer/LoanApply/ReEntryMixin';
import GetErrors from '@/mixins/GetErrors';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import ConsumerLoanApplyWizardApplicationCanceledMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/ApplicationCanceledMsg.vue';
import ConsumerLoanApplyWizardCompleteApplicationLaterMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/CompleteApplicationLaterMsg.vue';
import ConsumerInvalidLinkMsg from '@/components/Consumer/ConsumerInvalidLinkMsg.vue';
import ConsumerExpiredLinkMsg from '@/components/Consumer/ConsumerExpiredLinkMsg.vue';
import ConsumerLoanApplyWizardStepOne from '@/components/Consumer/LoanApplyWizard/StepOne.vue';
import ConsumerLoanApplyWizardStepTwo from '@/components/Consumer/LoanApplyWizard/StepTwo.vue';
import ConsumerLoanApplyWizardStepThree from '@/components/Consumer/LoanApplyWizard/StepThree.vue';
import ConsumerLoanApplyWizardStepFour from '@/components/Consumer/LoanApplyWizard/StepFour.vue';
import ConsumerLoanApplyWizardStepFive from '@/components/Consumer/LoanApplyWizard/StepFive.vue';
import ConsumerLoanApplyWizardStepSix from '@/components/Consumer/LoanApplyWizard/StepSix.vue';
import ConsumerLoanApplyWizardStepSeven from '@/components/Consumer/LoanApplyWizard/StepSeven.vue';
import ConsumerLoanApplyWizardStepEight from '@/components/Consumer/LoanApplyWizard/StepEight.vue';
import ConsumerLoanApplyWizardStepNine from '@/components/Consumer/LoanApplyWizard/StepNine.vue';
import ConsumerLoanApplyWizardStepTen from '@/components/Consumer/LoanApplyWizard/StepTen.vue';
import ConsumerLoanApplyWizardStepEleven from '@/components/Consumer/LoanApplyWizard/StepEleven.vue';
import ConsumerLoanApplyWizardStepTwelve from '@/components/Consumer/LoanApplyWizard/StepTwelve.vue';
import ConsumerLoanApplyWizardStepThirteen from '@/components/Consumer/LoanApplyWizard/StepThirteen.vue';
import ConsumerLoanApplyWizardStepFourteen from '@/components/Consumer/LoanApplyWizard/StepFourteen.vue';
import ConsumerLoanApplyWizardReentryStep from '@/components/Consumer/LoanApplyWizard/ReEntryFirstStep.vue';

export default defineComponent({
  name: 'ConsumerLoanApplyWizard',
  components: {
    ConsumerLoanApplyWizardApplicationCanceledMsg,
    ConsumerLoanApplyWizardCompleteApplicationLaterMsg,
    ConsumerInvalidLinkMsg,
    ConsumerExpiredLinkMsg,
    ConsumerLoanApplyWizardStepOne,
    ConsumerLoanApplyWizardStepTwo,
    ConsumerLoanApplyWizardStepThree,
    ConsumerLoanApplyWizardStepFour,
    ConsumerLoanApplyWizardStepFive,
    ConsumerLoanApplyWizardStepSix,
    ConsumerLoanApplyWizardStepSeven,
    ConsumerLoanApplyWizardStepEight,
    ConsumerLoanApplyWizardStepNine,
    ConsumerLoanApplyWizardStepTen,
    ConsumerLoanApplyWizardStepEleven,
    ConsumerLoanApplyWizardStepTwelve,
    ConsumerLoanApplyWizardStepThirteen,
    ConsumerLoanApplyWizardStepFourteen,
    ConsumerLoanApplyWizardReentryStep,
    AlertModal,
  },
  mixins: [
    WidgetsMixin,
    IdleTimeoutMixin,
    ReEntryMixin,
    GetErrors,
    FeatureFlagsMixin,
    SocureIDMixin,
  ],
  data() {
    return {
      baseClass: 'standard-wizard',
      show: false,
      showHeader: false,
      reEntryActivated: false,
      data: {},
    };
  },
  computed: {
    isCompleteLaterClicked(): boolean {
      return this.$store.getters['Consumer/getCompleteLaterClicked'];
    },
    applicationCanceledData(): any {
      return this.$store.getters['Consumer/getApplicationCanceledData'];
    },
    applicationCanceled(): any {
      return this.applicationCanceledData.canceled;
    },
    classList(): string {
      const classList: Array<string> = [this.baseClass];
      classList.push(`${this.baseClass}--consumer-loan-apply`);
      return classList.join(' ');
    },
    wizardStep(): number {
      return this.$store.getters['Consumer/getLoanApplyWizardStep'];
    },
    expiredLink(): boolean {
      return this.$store.getters['Consumer/getExpiredLink'] ?? false;
    },
    merchantId(): any {
      return get(this.$store.getters['Consumer/getInvitation'], 'merchant');
    },
    email(): string {
      return this.$store.getters['Consumer/getLoanApplyWizardData'].email || '';
    },
  },
  watch: {
    applicationCanceled: {
      handler(newVal: any) {
        if (newVal) {
          this.$emit('hideDividerOnHeader', newVal);
        }
      },
      immediate: true,
    },
  },
  async created() {
    await this.$store.dispatch('Ui/getConsumerBrandingData');
  },
  mounted() {
    this.setup();

    // Honeypot identification setup
    if (window.honeypot?.didInit) {
      honeypot.identify(this.merchantId);
    }

    this.mountDeviceSessionScript();
  },
  methods: {
    setup() {
      if (this.isReEntry) {
        this.reEntryActivated = true;
      } else {
        this.navigateStep(1);
      }
    },
    setStep(step: number): void {
      this.$store.commit('Consumer/setLoanApplyWizardStep', step);
    },
    navigateStep(step: number): void {
      window.scrollTo(0, 0);

      if (step) {
        if (step === 11 && !this.isFeatureEnabled(FeatureFlagsConstants.CAPTURE_ID)) {
          step += 2;
        }

        this.setStep(step);
      }
    },
    goToPreviousStep(): void {
      this.navigateStep(this.wizardStep - 1);
    },
    navigateToStepThree() {
      this.showHeader = true;
      this.navigateStep(3);
      this.reEntryActivated = false;
    },
    hideDivider(val: boolean) {
      this.$emit('hideDividerOnHeader', val);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/_custom-transitions";

.card {
  min-height: 300px;
  background-color: #FFF;
}

.cob-container {
  max-width: 28.25rem;
  padding: 0 1rem;
}

@include mobile {
  .complete-this-later {
    margin-bottom: 4rem;
  }
}

// This is a hacky way to do it before we implement vuetify buttons.
.standard-form-button {
  height: 1.875rem;
  font-size: 0.75rem;
}

:deep() .v-input {
  .v-input__details {
    margin-bottom: 0.5rem;
  }
}
</style>
