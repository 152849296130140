<template>
  <tr class="table-refunds" :class="{ expanded }">
    <td :colspan="headers.length">
      <v-expand-transition>
        <v-data-table
          v-show="expanded"
          :headers="headers"
          :items="refunds"
          :mobile="isMobile"
          item-key="created"
          :sort-by="[{ key: 'created', order: 'desc' }]"
          disable-pagination
          hide-default-footer
          hide-default-header>
          <template #item="{ item }">
            <tr class="refund-row" :class="refundRowClasses">
              <table-mobile-cell
                v-if="isMobile"
                :header="headers[0].title"
                :value="item.description" />
              <td v-else :style="columnStyles(0)">
                {{ item.description }}
              </td>

              <table-mobile-cell
                v-if="isMobile"
                :header="headers[1].title"
                :value="item.amount"
                private-value />
              <td
                v-else
                v-private
                :style="columnStyles(1)">
                {{ item.amount }}
              </td>

              <table-mobile-cell
                v-if="isMobile"
                :header="headers[2].title"
                :value="item.date" />
              <td v-else :style="columnStyles(2)">
                {{ item.date }}
              </td>

              <table-mobile-cell
                v-if="isMobile"
                :header="headers[3].title"
                :value="item.submittedBy"
                private-value />
              <td v-else v-private>
                {{ item.submittedBy }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-expand-transition>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TableMobileCell from '@/components/CustomerSummaryCard/RefundsDialog/TableMobileCell.vue';
import { RowDataInterface } from '@/components/CustomerSummaryCard/RefundsDialog/types';
import { TableHeaderInterface } from '@/interfaces/TableHeaderInterface';

export default defineComponent({
  name: 'TableRefunds',

  components: {
    TableMobileCell,
  },

  props: {
    refunds: { type: Array<RowDataInterface>, required: true },
    expanded: { type: Boolean, required: true },
    headers: { type: Array<TableHeaderInterface>, required: true },
  },

  computed: {
    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },

    refundRowClasses(): Array<string> {
      if (this.isMobile) {
        return ['v-data-table__tr--mobile'];
      }
      return [];
    },
  },

  methods: {
    columnStyles(columnIndex: number): Record<string, string> {
      return {
        width: `${this.headers[columnIndex].width}px`,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.table-refunds {
  &:not(.expanded) {
    & > td {
      height: 0 !important;
      border: none !important;
    }
  }

  :deep(.v-table__wrapper) {
    overflow-y: hidden;
  }

  & > td {
    padding: 0 !important;
  }

  .refund-row {
    background-color: var(--grayscale-color-5);

    &:hover {
      background-color: var(--grayscale-color-5) !important;
      cursor: default;
    }
  }
}
</style>
