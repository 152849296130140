import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_global_alerts = _resolveComponent("global-alerts")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_standard_system_error_notification = _resolveComponent("standard-system-error-notification")!
  const _component_statement_details = _resolveComponent("statement-details")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { id: "app" }, {
    default: _withCtx(() => [
      _createVNode(_component_global_alerts),
      _createVNode(_component_router_view, {
        class: _normalizeClass({ 'd-print-none': _ctx.print.name })
      }, null, 8, ["class"]),
      _createVNode(_component_standard_system_error_notification),
      (_ctx.print.name === _ctx.PRINT_NAMES.STATEMENT_DETAILS)
        ? (_openBlock(), _createBlock(_component_statement_details, {
            key: 0,
            class: "d-none",
            "print-on-load": "",
            "statement-id": _ctx.print.data.id,
            "onPrint:close": _ctx.clearPrint
          }, null, 8, ["statement-id", "onPrint:close"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}