import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40ab609d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tab-navigation-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    css: false,
    onBeforeEnter: _ctx.onBeforeEnter,
    onEnter: _ctx.onEnter,
    onLeave: _ctx.onLeave
  }, {
    default: _withCtx(() => [
      (_ctx.isSelected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["onBeforeEnter", "onEnter", "onLeave"]))
}