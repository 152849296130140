import Auth from '@/store/auth';
import { clearAuthLocalStorage } from '@/store/auth/utils';
import Consumer from '@/store/consumer';
import ConsumerLoanApplication from '@/store/consumerLoanApplication';
import MerchantBeginOnboarding from '@/store/merchantBeginOnboarding';
import MerchantOnboarding from '@/store/merchantOnboarding';
import MerchantPortal from '@/store/merchantPortal';
import Otp from '@/store/otp';
import Print from '@/store/print';
import Config from '@/store/config';
import Repay from '@/store/repay';
import Sponsor from '@/store/sponsor';
// eslint-disable-next-line import/no-cycle
import Ui from '@/store/ui';
import ResetState from '@/utils/store/ResetState';
import { createStore } from 'vuex';

const modules = {
  Print,
  Config,
  Repay,
  Consumer,
  ConsumerLoanApplication,
  MerchantPortal,
  MerchantBeginOnboarding,
  MerchantOnboarding,
  Ui,
  Auth,
  Sponsor,
  Otp,
};

const plugins: any = [];

const store = createStore({
  modules,
  state: {},
  mutations: {},
  actions: {
    async resetState(context: any) {
      const sponsorId: any = context.rootGetters['Sponsor/getSponsorId'];

      await clearAuthLocalStorage();

      ResetState(modules);

      context.commit('Sponsor/setSponsorId', sponsorId, { root: true });
    },
  },
  plugins,
});

store.subscribeAction({
  before: () => {
    store.commit('Ui/setProcessing', true);
  },
  error: (action: any) => {
    if (action.type === 'MerchantPortal/getPaymentProviders') {
      store.commit('Ui/setProcessing', false);
    }
  },
  after: () => {
    store.commit('Ui/setProcessing', false);
  },
});

export default store;
