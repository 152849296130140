import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_custom_alert = _resolveComponent("custom-alert")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, _mergeProps({
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event))
  }, _ctx.$attrs, {
    "max-width": _ctx.maxWidth,
    persistent: ""
  }), {
    default: _withCtx(() => [
      _createVNode(_component_custom_alert, {
        type: _ctx.type,
        dismissible: !_ctx.hideClose,
        onDismissed: _ctx.onClose
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          (_ctx.showCancelButton)
            ? (_openBlock(), _createBlock(_component_custom_button, {
                key: 0,
                "full-width": "",
                class: "mb-2",
                onClick: _ctx.onClose
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Close ")
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["type", "dismissible", "onDismissed"])
    ]),
    _: 3
  }, 16, ["modelValue", "max-width"]))
}