<template>
  <div class="standard-wizard__step">
    <h5>Mobile Verification</h5>

    <p>
      To continue your invitation, we will send you a
      <span> six digit security code </span>
      on your mobile device. Click below to send the one time code.
    </p>

    <custom-button
      :disabled="disableButton"
      full-width
      data-test="continueBtn"
      @click="nextStep">
      Click to receive text
    </custom-button>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ReEntryMixin from '@/mixins/Consumer/LoanApply/ReEntryMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ReEntryFirstStep',
  components: { CustomButton },
  mixins: [ReEntryMixin],
  data() {
    return {
      disableButton: false,
    };
  },
  methods: {
    async nextStep(): Promise<void> {
      this.disableButton = true;

      const { error } = await this.$store.dispatch('Consumer/otpRegister', this.invitation.phone);

      if (!error) {
        this.$emit('codeSent');
      }

      this.disableButton = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";

.standard-wizard__step {
  h5 {
    color: #183057;
    margin-bottom: 2rem;
  }

  p {
    border-bottom: 1px solid var(--grayscale-color-3);
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    span {
      font-weight: 700;
    }
  }
}
</style>
