import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26fd3ff9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fs-unmask merchant__activity-center__new-loan-application" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_new_loan_application_modal = _resolveComponent("new-loan-application-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_custom_button, {
        disabled: !_ctx.buttonEnabled,
        "data-test": "newButton",
        "full-width": "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode("mdi-account-plus")
            ]),
            _: 1
          }),
          _createTextVNode(" New Loan Invitation ")
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_new_loan_application_modal, {
          key: 0,
          dialog: true,
          "on-cta": _ctx.closeNewLoanApplicationModal
        }, null, 8, ["on-cta"]))
      : _createCommentVNode("", true)
  ]))
}