import { RepayStateInterface } from '@/interfaces/states/RepayStateInterface';
import ResetModuleState from '@/utils/store/ResetModuleState';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const initialState = (): RepayStateInterface => ({ hasCardPassed: false });

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations: {
    ...mutations,
    resetModuleState(state: any) {
      ResetModuleState(state, initialState());
    },
  },
  actions,
};
