import { loginUser, gucaCheck } from '@/api/account';
import { ActionContext } from 'vuex';
import { AuthStateInterface } from '@/interfaces/states/AuthStateInterface';
import { LoginDataInterface } from '@/interfaces/auth/LoginDataInterface';
import { clearAuthLocalStorage } from '@/store/auth/utils';
import { StoreActionInterface } from '@/interfaces/StoreActionResponse';
import { AxiosError } from 'axios';

export async function userLogin(
  context: ActionContext<AuthStateInterface, any>,
  loginData: LoginDataInterface,
): Promise<StoreActionInterface> {
  context.commit('Ui/setErrors', {}, { root: true });
  const response: StoreActionInterface = {};

  await clearAuthLocalStorage();

  try {
    const { data } = await loginUser(loginData);
    await context.dispatch('setUserData', data);
    context.commit('setUserId', data.user_id);
    localStorage.setItem('userId', data.user_id);
  } catch (error: unknown) {
    context.commit('Ui/setErrors', (error as AxiosError).response, { root: true });
    response.error = error;
  }

  return response;
}

export async function setUserData(
  context: ActionContext<AuthStateInterface, any>,
  userData: any,
) {
  return new Promise<void>(resolve => {
    const {
      token,
      session_id,
      merchant,
      merchantData,
    } = userData;

    if (merchant) {
      context.commit('setMerchant', merchantData || { uuid: merchant });
    }

    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('isLoggedIn', 'true');
    }

    if (session_id) {
      localStorage.setItem('sessionId', session_id);
      context.commit('setSessionId', session_id);
    }
    resolve();
  });
}

export function setNewUserInviteTokenData(
  context: ActionContext<AuthStateInterface, any>,
  payload: any,
) {
  context.commit('setNewUserInviteTokenData', payload);
}

export async function gucaCheckApi(
  context: ActionContext<AuthStateInterface, any>,
  payload: any,
): Promise<StoreActionInterface> {
  context.commit('Ui/setErrors', {}, { root: true });
  context.commit('Ui/clearUserFriendlyError', {}, { root: true });

  try {
    const { data, status } = await gucaCheck(payload);
    context.commit('setGucaData', data);
    return { data, status };
  } catch (error: unknown) {
    const status = (error as AxiosError).response?.status;
    return { error, status };
  }
}
