<template>
  <div>
    <standard-dialog
      :dialog="showConfirmDialog"
      width="400"
      data-test="confirmModal"
      content-class="confirmDialog"
      :show-cancel-cta="showActions"
      :persistent="true"
      :on-cancel="onCancel"
      :on-cta="onConfirm"
      :button-label="buttonLabel"
      :button-cancel-label="buttonCancelLabel"
      :title="titleText"
      :light-title="lightTitle"
      :cta-disabled="disableConfirmButton">
      <template #body>
        <slot name="body" />
      </template>
      <template #actions>
        <slot name="actions" />
      </template>
    </standard-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';

export default defineComponent({
  name: 'ConfirmDialog',

  components: {
    StandardDialog,
  },

  props: {
    buttonLabel: { type: String, default: 'Confirm' },
    buttonCancelLabel: { type: String, default: 'Cancel' },
    titleText: { type: String, default: '' },
    showConfirmDialog: { type: Boolean, default: false },
    onCancel: { type: Function, default: null },
    onConfirm: { type: Function, default: null },
    showActions: { type: Boolean, default: true },
    lightTitle: { type: Boolean, default: false },
    disableConfirmButton: { type: Boolean, default: false },
  },

  data: () => ({
    openConfirmDialog: false,
  }),

  watch: {
    showConfirmDialog: {
      immediate: true,
      handler(val: any) {
        this.openConfirmDialog = val;
      },
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

</style>
