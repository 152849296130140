<template>
  <v-container class="d-flex justify-space-between main-container">
    <div class="account-type">
      Select Account Type
    </div>
    <v-radio-group
      inline
      class="radio-group-buttons"
      :required="required"
      @update:model-value="$emit('update:model-value', $event)">
      <v-radio
        :label="firstLabel"
        :value="firstValue"
        class="radio-button"
        data-test="businessRadioBtn" />
      <v-radio
        :label="secondLabel"
        :value="secondValue"
        class="radio-button"
        data-test="personalRadioBtn" />
    </v-radio-group>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';

export default defineComponent({
  name: 'AccountTypeRadio',

  mixins: [InputFieldMixin],

  props: {
    firstLabel: { type: String, default: 'Business' },
    firstValue: { type: String, default: 'business' },
    secondLabel: { type: String, default: 'Personal' },
    secondValue: { type: String, default: 'individual' },
  },
});
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 0 0;

  .account-type {
    padding-top: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    color: var(--grayscale-color-1);
  }

  .radio-group-buttons {
    grid-template-columns: max-content max-content max-content;
    justify-content: end;
    margin: 0 1rem 0 0;
    padding: 0;

    :deep(.radio-button .v-label) {
      color: var(--grayscale-color-1) !important;
    }
  }
}
</style>
