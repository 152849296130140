import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c5534a7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["colspan"]
const _hoisted_2 = { key: 7 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_mobile_cell = _resolveComponent("table-mobile-cell")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(["table-refunds", { expanded: _ctx.expanded }])
  }, [
    _createElementVNode("td", {
      colspan: _ctx.headers.length
    }, [
      _createVNode(_component_v_expand_transition, null, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_v_data_table, {
            headers: _ctx.headers,
            items: _ctx.refunds,
            mobile: _ctx.isMobile,
            "item-key": "created",
            "sort-by": [{ key: 'created', order: 'desc' }],
            "disable-pagination": "",
            "hide-default-footer": "",
            "hide-default-header": ""
          }, {
            item: _withCtx(({ item }) => [
              _createElementVNode("tr", {
                class: _normalizeClass(["refund-row", _ctx.refundRowClasses])
              }, [
                (_ctx.isMobile)
                  ? (_openBlock(), _createBlock(_component_table_mobile_cell, {
                      key: 0,
                      header: _ctx.headers[0].title,
                      value: item.description
                    }, null, 8, ["header", "value"]))
                  : (_openBlock(), _createElementBlock("td", {
                      key: 1,
                      style: _normalizeStyle(_ctx.columnStyles(0))
                    }, _toDisplayString(item.description), 5)),
                (_ctx.isMobile)
                  ? (_openBlock(), _createBlock(_component_table_mobile_cell, {
                      key: 2,
                      header: _ctx.headers[1].title,
                      value: item.amount,
                      "private-value": ""
                    }, null, 8, ["header", "value"]))
                  : _withDirectives((_openBlock(), _createElementBlock("td", {
                      key: 3,
                      style: _normalizeStyle(_ctx.columnStyles(1))
                    }, [
                      _createTextVNode(_toDisplayString(item.amount), 1)
                    ], 4)), [
                      [_directive_private]
                    ]),
                (_ctx.isMobile)
                  ? (_openBlock(), _createBlock(_component_table_mobile_cell, {
                      key: 4,
                      header: _ctx.headers[2].title,
                      value: item.date
                    }, null, 8, ["header", "value"]))
                  : (_openBlock(), _createElementBlock("td", {
                      key: 5,
                      style: _normalizeStyle(_ctx.columnStyles(2))
                    }, _toDisplayString(item.date), 5)),
                (_ctx.isMobile)
                  ? (_openBlock(), _createBlock(_component_table_mobile_cell, {
                      key: 6,
                      header: _ctx.headers[3].title,
                      value: item.submittedBy,
                      "private-value": ""
                    }, null, 8, ["header", "value"]))
                  : _withDirectives((_openBlock(), _createElementBlock("td", _hoisted_2, [
                      _createTextVNode(_toDisplayString(item.submittedBy), 1)
                    ])), [
                      [_directive_private]
                    ])
              ], 2)
            ]),
            _: 1
          }, 8, ["headers", "items", "mobile"]), [
            [_vShow, _ctx.expanded]
          ])
        ]),
        _: 1
      })
    ], 8, _hoisted_1)
  ], 2))
}