import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6593b3ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createBlock(_component_v_data_table_server, {
    page: _ctx.page,
    headers: _ctx.headers,
    items: _ctx.fetchedApplications,
    "items-per-page": 5,
    search: _ctx.searchInput,
    "items-per-page-options": [5],
    "items-length": _ctx.itemsLength,
    mobile: _ctx.isMobile,
    "disable-sort": _ctx.isMobile,
    class: "merchant-portal-recent-loan-applications__data-table minimal-pagination",
    "onUpdate:page": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:page', $event))),
    "onUpdate:options": _ctx.updateResults
  }, {
    "item.user.full_name": _withCtx(({ item }) => [
      (_ctx.isValidForTransaction(item.consumer_account) || _ctx.statusApproved(item.status))
        ? (_openBlock(), _createBlock(_component_custom_button, {
            key: 0,
            size: "small",
            variant: "text",
            "data-test": "loanAppSelectable",
            class: _normalizeClass([{ 'text-artis-dark-gray': !_ctx.isValidForTransaction(item.consumer_account) }, "btn-compact"]),
            onClick: ($event: any) => (_ctx.selectLoanApp(item))
          }, {
            default: _withCtx(() => [
              _withDirectives((_openBlock(), _createElementBlock("span", null, [
                _createTextVNode(_toDisplayString(_ctx.getUserName(item.user)), 1)
              ])), [
                [_directive_private, 'Consumer name']
              ])
            ]),
            _: 2
          }, 1032, ["class", "onClick"]))
        : (item.is_valid_for_transaction)
          ? (_openBlock(), _createBlock(_component_custom_button, {
              key: 1,
              size: "small",
              variant: "text",
              class: _normalizeClass([{ 'text-artis-dark-gray': !item.is_valid_for_transaction }, "btn-compact"]),
              onClick: ($event: any) => (_ctx.selectLoanApp(item))
            }, {
              default: _withCtx(() => [
                _withDirectives((_openBlock(), _createElementBlock("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.getUserName(item.user)), 1)
                ])), [
                  [_directive_private, 'Consumer name']
                ])
              ]),
              _: 2
            }, 1032, ["class", "onClick"]))
          : _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createTextVNode(_toDisplayString(_ctx.getUserName(item.user)), 1)
            ])), [
              [_directive_private]
            ])
    ]),
    "item.human_readable_id": _withCtx(({ item }) => [
      (_ctx.isValidForTransaction(item.consumer_account) || _ctx.statusApproved(item.status))
        ? (_openBlock(), _createBlock(_component_custom_button, {
            key: 0,
            size: "small",
            variant: "text",
            onClick: ($event: any) => (_ctx.selectLoanApp(item))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getHumanReadable(item.human_readable_id)), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]))
        : (item.is_valid_for_transaction)
          ? (_openBlock(), _createBlock(_component_custom_button, {
              key: 1,
              size: "small",
              variant: "text",
              onClick: ($event: any) => (_ctx.selectLoanApp(item))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getHumanReadable(item.human_readable_id)), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.getHumanReadable(item.human_readable_id)), 1))
    ]),
    "item.credit_limit": _withCtx(({ item }) => [
      (_ctx.statusApproved(item.status))
        ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.getFeeLabel(item)), 1)
          ])), [
            [_directive_private]
          ])
        : _createCommentVNode("", true)
    ]),
    "item.amount_spent": _withCtx(({ item }) => [
      _withDirectives((_openBlock(), _createElementBlock("span", null, [
        _createTextVNode(_toDisplayString(_ctx.getAmountLabel(item)), 1)
      ])), [
        [_directive_private]
      ])
    ]),
    "item.available_spend": _withCtx(({ item }) => [
      _withDirectives((_openBlock(), _createElementBlock("span", null, [
        _createTextVNode(_toDisplayString(_ctx.getSpendLabel(item)), 1)
      ])), [
        [_directive_private]
      ])
    ]),
    "item.product_name": _withCtx(({ item }) => [
      _createTextVNode(_toDisplayString(_ctx.getNameLabel(item)), 1)
    ]),
    "item.activate_date": _withCtx(({ item }) => [
      _createTextVNode(_toDisplayString(_ctx.DateTimeFormatShort(item.activate_date)), 1)
    ]),
    "item.purchase_window_end_date": _withCtx(({ item }) => [
      _createTextVNode(_toDisplayString(_ctx.DateTimeFormatShort(item.purchase_window_end_date)), 1)
    ]),
    "item.sentTo": _withCtx(({ item }) => [
      _withDirectives((_openBlock(), _createElementBlock("span", {
        class: _normalizeClass({ 'fs-exclude': _ctx.isPhoneNumber(item.sentTo) })
      }, [
        _createTextVNode(_toDisplayString(item.sentTo), 1)
      ], 2)), [
        [_directive_private]
      ])
    ]),
    "item.invited_by_merchant_user": _withCtx(({ item }) => [
      _withDirectives((_openBlock(), _createElementBlock("span", null, [
        _createTextVNode(_toDisplayString(item.invited_by_merchant_user), 1)
      ])), [
        [_directive_private]
      ])
    ]),
    _: 1
  }, 8, ["page", "headers", "items", "search", "items-length", "mobile", "disable-sort", "onUpdate:options"]))
}