<template>
  <v-container fluid class="merchant__activity-center portal__page">
    <merchant-portal-activity-center />
  </v-container>
</template>

<script lang="ts">
import MerchantPortalActivityCenter from '@/components/Merchant/Portal/ActivityCenter.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ActivityCenter',
  components: {
    MerchantPortalActivityCenter,
  },
});
</script>
