import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import CreditEngineHardPullResultsDataMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineHardPullResultsDataMixin';
import { get } from 'lodash';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CreditEngineHardPullApprovedAmountMixin',
  mixins: [CreditEngineHardPullResultsDataMixin],
  computed: {
    approvedAmountHardPullLabel(): string {
      return CurrencyFormatLong(this.approvedAmountHardPull);
    },
    approvedAmountHardPull(): number {
      return get(this.creditEngineHardPullResults, 'credit_line', 0);
    },
  },
});
