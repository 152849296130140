import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60b26430"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "standard-wizard__step standard-wizard__step--invalid-link",
  "data-test": "invalidCard"
}
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createTextVNode(" We're sorry but it appears you have an invalid link. Please go back to the invitation and try again. If this problem persists please contact "),
            _createElementVNode("a", { href: _ctx.supportMailTo }, _toDisplayString(_ctx.supportEmail), 9, _hoisted_2)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}