<template>
  <v-card class="custom-card">
    <v-card-title>
      <span>Statement for</span>
    </v-card-title>
    <v-card-subtitle>
      <div>
        <span v-private>{{ merchantInfo.merchant_name }}</span>
      </div>
      <div>
        <span v-private>{{ merchantInfo.merchant_address_line_1 }}</span>
      </div>
      <div>
        <span v-private>
          {{ merchantInfo.merchant_city }},
          {{ merchantInfo.merchant_state }},
          {{ merchantInfo.merchant_zip_code }}
        </span>
      </div>
      <div class="bottom-div">
        <div>
          <span><strong>Payable to</strong></span>
        </div>
        <div>
          <span v-private>{{ merchantInfo.merchant_name }}</span>
        </div>
      </div>
    </v-card-subtitle>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { MerchantInfoInterface } from '@/interfaces/statements/StatementDetailsInterface';

export default defineComponent({
  name: 'MerchantInfo',
  props: {
    merchantInfo: { type: Object as PropType<MerchantInfoInterface>, required: true },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';
@import "@/assets/scss/statement-details.scss";

.bottom-div {
  position: absolute;
  bottom: 1rem;
}

</style>
