<template>
  <v-data-table-server
    :page="page"
    :headers="headers"
    :items="fetchedApplications"
    :items-per-page="5"
    :search="searchInput"
    :items-per-page-options="[5]"
    :items-length="itemsLength"
    :mobile="isMobile"
    :disable-sort="isMobile"
    class="merchant-portal-recent-loan-applications__data-table minimal-pagination"
    @update:page="$emit('update:page', $event)"
    @update:options="updateResults">
    <template v-slot:item.user.full_name="{ item }">
      <custom-button
        v-if="isValidForTransaction(item.consumer_account) || statusApproved(item.status)"
        size="small"
        variant="text"
        data-test="loanAppSelectable"
        :class="{ 'text-artis-dark-gray': !isValidForTransaction(item.consumer_account) }"
        class="btn-compact"
        @click="selectLoanApp(item)">
        <span v-private="'Consumer name'">
          {{ getUserName(item.user) }}
        </span>
      </custom-button>
      <custom-button
        v-else-if="item.is_valid_for_transaction"
        size="small"
        variant="text"
        :class="{ 'text-artis-dark-gray': !item.is_valid_for_transaction }"
        class="btn-compact"
        @click="selectLoanApp(item)">
        <span v-private="'Consumer name'">
          {{ getUserName(item.user) }}
        </span>
      </custom-button>
      <span v-else v-private>
        {{ getUserName(item.user) }}
      </span>
    </template>
    <template v-slot:item.human_readable_id="{ item }">
      <custom-button
        v-if="isValidForTransaction(item.consumer_account) || statusApproved(item.status)"
        size="small"
        variant="text"
        @click="selectLoanApp(item)">
        {{ getHumanReadable(item.human_readable_id) }}
      </custom-button>
      <custom-button
        v-else-if="item.is_valid_for_transaction"
        size="small"
        variant="text"
        @click="selectLoanApp(item)">
        {{ getHumanReadable(item.human_readable_id) }}
      </custom-button>
      <span v-else>
        {{ getHumanReadable(item.human_readable_id) }}
      </span>
    </template>
    <template v-slot:item.credit_limit="{ item }">
      <span v-if="statusApproved(item.status)" v-private>
        {{ getFeeLabel(item) }}
      </span>
    </template>
    <template v-slot:item.amount_spent="{ item }">
      <span v-private>{{ getAmountLabel(item) }}</span>
    </template>
    <template v-slot:item.available_spend="{ item }">
      <span v-private>{{ getSpendLabel(item) }}</span>
    </template>
    <template v-slot:item.product_name="{ item }">
      {{ getNameLabel(item) }}
    </template>
    <template v-slot:item.activate_date="{ item }">
      {{ DateTimeFormatShort(item.activate_date) }}
    </template>
    <template v-slot:item.purchase_window_end_date="{ item }">
      {{ DateTimeFormatShort(item.purchase_window_end_date) }}
    </template>

    <template v-slot:item.sentTo="{ item }">
      <span v-private :class="{ 'fs-exclude': isPhoneNumber(item.sentTo) }">
        {{ item.sentTo }}
      </span>
    </template>
    <template v-slot:item.invited_by_merchant_user="{ item }">
      <span v-private>{{ item.invited_by_merchant_user }}</span>
    </template>
  </v-data-table-server>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { get } from 'lodash';
import DateTimeFormatShort from '@/filters/DateTimeFormatShort';
import isPhoneNumber from '@/validators/phone_number';

export default defineComponent({
  name: 'TableSmall',

  components: {
    CustomButton,
  },

  props: {
    headers: { type: Array, default: () => null },
    applications: { type: Array, default: () => null },
    getHumanReadable: { type: Function, default: () => null },
    getNameLabel: { type: Function, default: () => null },
    getFeeLabel: { type: Function, default: () => null },
    getAmountLabel: { type: Function, default: () => null },
    getSpendLabel: { type: Function, default: () => null },
    appStatuses: { type: Object, default: () => null },
    searchInput: { type: String, default: () => null },
    selectLoanApp: { type: Function, default: () => null },
    itemsLength: { type: Number, default: null },
    page: { type: Number, default: null },
    isMobile: { type: Boolean, default: false },
  },

  data() {
    const fetchedApplications: Array<any> = [];
    return {
      fetchedApplications,
      search: '',
    };
  },

  watch: {
    applications: {
      immediate: true,
      handler(newVal: any) {
        this.fetchedApplications = newVal;
      },
    },
  },

  methods: {
    DateTimeFormatShort,
    isPhoneNumber,

    isValidForTransaction(application: any): boolean {
      return !!get(application, 'is_valid_for_transaction');
    },

    getUserName(user: { first_name: string, last_name: string }) {
      return `${user.first_name} ${user.last_name}`;
    },

    statusApproved(status: any): boolean {
      return status === this.appStatuses.APPROVED_ACCEPTED;
    },

    updateResults(val: any) {
      const options: any = {
        pageNumber: val.page,
      };

      if (this.searchInput.length) {
        options.searchString = this.searchInput;
      }

      if (val.sortBy.length > 0) {
        options.sortHeader = val.sortBy[0].key;
        options.isDesc = val.sortBy[0].order === 'desc';
      }
      this.$emit('updatePage', options);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table-default";

:deep(.v-data-table-headers--mobile) {
  display: none;
}

:deep() {
  .v-data-table-footer {
    justify-content: end;
    height: 3.5rem;
  }

  .v-table-header tr th span {
    color: var(--grayscale-color-1);
  }

  .v-table__mobile-row__header {
    color: var(--grayscale-color-1);
  }

  .v-table__wrapper tbody tr td {
    color: var(--grayscale-color-1);
  }
}
</style>
