import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ab78e08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "merchant__begin-onboarding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_merchant_intro = _resolveComponent("merchant-intro")!
  const _component_main_footer = _resolveComponent("main-footer")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_container, { class: "merchant__begin-onboarding__container" }, {
      default: _withCtx(() => [
        _createVNode(_component_merchant_intro),
        _createVNode(_component_main_footer, { "enable-human-readable-id": false })
      ]),
      _: 1
    })
  ]))
}