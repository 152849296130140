import GetHumanReadableIdLabel from '@/utils/GetHumanReadableIdLabel';
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    getHumanReadableIdLabel(hrid: string, prefix = true): string {
      return GetHumanReadableIdLabel(hrid, prefix);
    },
    /**
     * Adds hyphens to a numeric string, equivalent to above method but does not cause problems
     * @param humanReadableId
     * @param prefix
     */
    formatHRID(humanReadableId: string, prefix = true): string {
      return GetHumanReadableIdLabel(humanReadableId, prefix);
    },
  },
});
