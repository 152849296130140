import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4eafc9c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "portal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_consumer_header = _resolveComponent("consumer-header")!
  const _component_consumer_loan_apply_wizard = _resolveComponent("consumer-loan-apply-wizard")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_consumer_footer = _resolveComponent("consumer-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_consumer_header, { class: "portal__header" }),
    _createVNode(_component_v_container, { class: "portal__container" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { justify: "center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              lg: "5",
              md: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_consumer_loan_apply_wizard)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_consumer_footer)
  ]))
}