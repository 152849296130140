<template>
  <standard-wizard-message
    :message="errorMessage"
    data-test="invalidInfoMsg" />
</template>

<script lang="ts">
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConsumerInvalidInfo',
  components: {
    StandardWizardMessage,
  },
  data() {
    return {
      errorMessage: 'We are unable to move forward with your application based on the information provided.',
    };
  },
});
</script>
