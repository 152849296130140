import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!

  return (_openBlock(), _createBlock(_component_v_card_text, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { justify: "space-between" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "5",
            sm: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_skeleton_loader, {
                class: "summary",
                type: "article"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "5",
            sm: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_skeleton_loader, {
                class: "address",
                type: "article"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_skeleton_loader, {
                height: "170",
                type: "image"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_skeleton_loader, {
                height: "170",
                type: "image"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_skeleton_loader, {
                class: "table",
                type: "table-row, table-tbody"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_skeleton_loader, {
                class: "table",
                type: "table-row, table-tbody"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}