import Inputmask from 'inputmask';
import { Directive, DirectiveBinding } from 'vue';

const directive: Directive = {
  created: (el: HTMLElement, binding: DirectiveBinding) => {
    const input: any = el.getElementsByTagName('INPUT')[0];

    // When pasting into an input field that has an input mask attached
    // the `input` event is never triggered which causes all sorts of trouble.
    // This event handler makes sure that the `input` event is triggered.
    if (!binding.value.onBeforePaste) {
      binding.value.onBeforePaste = function _(value: any) {
        setTimeout(() => this.$el.trigger('input'), 0);
        return value;
      };
    }
    Inputmask(binding.value).mask(input);
  },
};

export default directive;
