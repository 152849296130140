<template>
  <standard-dialog
    v-bind="$attrs"
    class="user-details-modal"
    :on-cancel="onClose"
    :show-actions="false"
    :title="userName"
    :width="isMobile ? '382' : '420'">
    <template #title>
      <span v-private>{{ userName }}</span>
    </template>
    <template #body>
      <div class="d-block my-2">
        <div class="fs-unmask">
          <strong class="body-text-large" data-test="contactInformationHeader">
            Contact Information
          </strong>
          <ul>
            <li>
              <strong>Address: </strong>
              <span
                v-private
                v-fs-exclude
                data-test="userAddressLabel">
                {{ addressLabel }}
              </span>
            </li>
            <li>
              <strong>Phone: </strong>
              <a
                v-private="'Customer phone number'"
                v-fs-exclude
                :href="`tel:${selectedLoanApplication.phone_number}`"
                data-test="userPhoneNumberLabel">
                {{ formattedPhoneNumber }}
              </a>
            </li>

            <li>
              <strong>Email: </strong>
              <a
                v-private="'Customer email'"
                :href="`mailto:${selectedLoanApplication.user.email}`"
                data-test="userEmailLabel">
                {{ selectedLoanApplication.user.email }}
              </a>
            </li>
          </ul>
        </div>

        <v-divider />

        <div>
          <strong class="body-text-large" data-test="loanInformationHeader">
            Loan Information
          </strong>
          <ul>
            <li>
              <strong>Product ID: </strong>
              <span
                v-private
                data-test="productIdLabel">
                {{ selectedLoanApplication.consumer_account.product_name }}
              </span>
            </li>
            <li>
              <strong>Available Spend: </strong>
              <span
                v-private
                data-test="availableSpendLabel">
                {{ creditBalance }}
              </span>
            </li>
            <li>
              <strong>Amount Spent: </strong>
              <span
                v-private
                data-test="amountSpentLabel">
                {{ amountSpent }}
              </span>
            </li>
            <li>
              <strong>Origination Date: </strong>
              <span
                v-private
                data-test="originationDateLabel">
                {{ originationDate }}
              </span>
            </li>
            <li>
              <strong>Purchase Window End Date: </strong>
              <span
                v-private
                data-test="purchaseWindowEndDateLabel">
                {{ purchaseWindowEndDate }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </standard-dialog>
</template>

<script setup lang="ts">
import StandardDialog from '@/components/Dialogs/index.vue';
import moment from 'moment';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import formatPhoneNumber from '@/utils/FormatPhoneNumber';
import DATE_CONSTANTS from '@/constants/DateConstants';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useDisplay } from 'vuetify';

const emit = defineEmits(['close']);

const store = useStore();
const { xs: isMobile } = useDisplay();

const selectedLoanApplication = computed(() => store.getters['MerchantPortal/getSelectedLoanApplication']);

const userName = computed(() => {
  return `${selectedLoanApplication.value.user?.first_name} ${selectedLoanApplication.value.user?.last_name}`;
});

const formattedPhoneNumber = computed(() => formatPhoneNumber(selectedLoanApplication.value.phone_number));

const creditBalance = computed(() => {
  return CurrencyFormatLong(selectedLoanApplication.value.consumer_account?.available_spend) ?? null;
});

const amountSpent = computed(() => {
  return CurrencyFormatLong(selectedLoanApplication.value.consumer_account?.amount_spent) ?? null;
});

const originationDate = computed(() => {
  const getDate = new Date(selectedLoanApplication.value.origination_date);
  return moment(getDate).format(DATE_CONSTANTS.dateFormatSlashes);
});

const purchaseWindowEndDate = computed(() => {
  const getDate = new Date(selectedLoanApplication.value.purchase_window_end_date);
  return moment(getDate).format(DATE_CONSTANTS.dateFormat);
});

const addressLabel = computed(() => {
  const address = selectedLoanApplication.value.user.address;
  if (!address) return '';
  const addressLabel: string[] = [];

  if (address.address_1) {
    addressLabel.push(address.address_1);
  }

  if (address.address_2) {
    addressLabel.push(address.address_2);
  }

  if (address.city) {
    addressLabel.push(address.city);
  }

  if (address.state) {
    addressLabel.push(address.state);
  }

  if (address.zip_code) {
    addressLabel.push(address.zip_code);
  }

  return addressLabel.join(', ');
});

const onClose = () => emit('close');
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

ul {
  list-style-type: disc;
  margin-top: 0.625rem;

  li {
    font-size: 0.875rem;
    margin-left: 1.5rem;
    line-height: 180%;
  }
}

:deep(.v-divider) {
  margin: 1rem 0;
  border-color: var(--grayscale-color-3) !important;
  opacity: 1;
}
</style>

<style lang="scss">
.user-details-modal {
  .dialog-title-wrap {
    background-color: var(--grayscale-color-5);

    .close-btn-wrap {
      padding: 0 !important;
    }
  }

  .standard-title {
    padding: 0.75rem 1rem !important;
  }
}
</style>
