<template>
  <v-dialog
    v-model="showDialog"
    v-bind="$attrs"
    :max-width="maxWidth"
    persistent>
    <custom-alert
      :type="type"
      :dismissible="!hideClose"
      @dismissed="onClose">
      <slot />
      <custom-button
        v-if="showCancelButton"
        full-width
        class="mb-2"
        @click="onClose">
        Close
      </custom-button>
    </custom-alert>
  </v-dialog>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'AlertModal',

  components: {
    CustomAlert,
    CustomButton,
  },

  props: {
    dialog: { type: Boolean, default: false },
    onClose: { type: Function as PropType<() => void>, default: () => null },
    type: { type: String, required: true },
    hideClose: { type: Boolean, default: false },
    showCancelButton: { type: Boolean, default: false },
    maxWidth: { type: [String, Number], default: '500' },
  },

  data() {
    return {
      showDialog: this.dialog,
    };
  },

  watch: {
    dialog(newValue) {
      this.showDialog = newValue;
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.custom-alert) {
  margin: 0;
}
</style>
