<template>
  <div class="expansion-panel">
    <v-expansion-panels v-model="expandedPanel" v-bind="$attrs">
      <v-expansion-panel>
        <v-expansion-panel-title
          :disabled="canCollapse"
          :hide-actions="canCollapse"
          :class="showOverflow ? 'show-overflow' : ''">
          <slot name="title" :is-expanded="expandedPanel === 0" />
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <slot name="text" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CustomExpansionPanel',

  props: {
    canCollapse: { type: Boolean, default: false },
    showOverflow: { type: Boolean, default: false },
    expanded: { type: Boolean, default: false },
  },

  data() {
    const expandedPanel: any = null;
    return {
      expandedPanel,
    };
  },

  watch: {
    expanded: {
      handler(value: boolean) {
        this.expandedPanel = value ? 0 : null;
      },
    },
  },

  created() {
    this.expandedPanel = this.expanded ? 0 : null;
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';

.expansion-panel {
  width: 100%;
  display: flex;

  .v-expansion-panel-title {
    background-color: var(--grayscale-color-5);
    overflow: hidden;
    cursor: default;
    padding: 0;
    border-bottom: 1px solid var(--darkreader-border--grayscale-color-2);
    min-height: 3rem !important;

    &:hover > :deep(.v-expansion-panel-title__overlay) {
      opacity: 0;
    }

    @include mobile {
      padding: 0 1rem !important;
    }

    :deep(.section-header) {
      width: 100%;

      @include mobile {
        padding: 0 !important;
      }
    }

    @include mobile-up {
      border-bottom: 1px solid var(--grayscale-color-2);
    }

    :deep(&__icon) {
      margin-right: 1rem;
    }
  }

  :deep() {
    .v-expansion-panel-text {
      &__wrapper {
        padding: 0;
      }
    }
  }

  .show-overflow {
    overflow: visible !important;
  }
}
</style>
