import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75aa0080"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "widgetContentContainer",
  class: "standard-wizard standard-wizard--consumer-loan-application"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_standard_wizard_message = _resolveComponent("standard-wizard-message")!
  const _component_consumer_invalid_link_msg = _resolveComponent("consumer-invalid-link-msg")!
  const _component_consumer_loan_application_wizard_step_one = _resolveComponent("consumer-loan-application-wizard-step-one")!
  const _component_consumer_loan_application_wizard_step_two = _resolveComponent("consumer-loan-application-wizard-step-two")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      mode: "out-in",
      name: "slide"
    }, {
      default: _withCtx(() => [
        (!_ctx.merchantStanding)
          ? (_openBlock(), _createBlock(_component_standard_wizard_message, {
              key: 0,
              message: "We are unable to process your request at this time.",
              "data-test": "invalidMsg"
            }))
          : (!_ctx.merchantId)
            ? (_openBlock(), _createBlock(_component_consumer_invalid_link_msg, { key: 1 }))
            : (_ctx.wizardStep === 1)
              ? (_openBlock(), _createBlock(_component_consumer_loan_application_wizard_step_one, {
                  key: 2,
                  data: _ctx.data,
                  onNextStep: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateStep(2)))
                }, null, 8, ["data"]))
              : (_ctx.wizardStep === 2)
                ? (_openBlock(), _createBlock(_component_consumer_loan_application_wizard_step_two, { key: 3 }))
                : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 512))
}