<template>
  <standard-dialog
    :dialog="dialog"
    :hide-header-close="true"
    :persistent="true"
    :show-actions="false"
    data-test="waitingModal">
    <template #body>
      <merchant-onboarding-wizard-application-waiting-approval />
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MerchantOnboardingWizardApplicationWaitingApproval
  from '@/components/Merchant/OnboardingWizard/ApplicationWaitingApproval.vue';
import StandardDialog from '@/components/Dialogs/index.vue';

export default defineComponent({
  name: 'ApplicationWaitingApprovalModal',

  components: {
    MerchantOnboardingWizardApplicationWaitingApproval,
    StandardDialog,
  },

  props: {
    dialog: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

</style>
