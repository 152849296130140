import vuetify from '@/plugins/vuetify';
import router from '@/router';
import store from '@/store';
import smoothscroll from 'smoothscroll-polyfill';
import mask from '@/directives/mask';
import fsExclude from '@/logging/fullstory.directive';
import { createApp } from 'vue';
import 'vuetify/styles'; // Ensure you import Vuetify styles
import privateDirective from '@/logging/private.directive';
import App from './App.vue';

smoothscroll.polyfill();

(async () => {
  await store.dispatch('Config/getConfig');
  const app = createApp(App);

  app.use(router);
  app.use(store);
  app.use(vuetify);

  app.directive('mask', mask);
  app.directive('fs-exclude', fsExclude);
  app.directive('private', privateDirective);

  // Mount the app
  app.mount('#app');
})();
