import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_consumer_loan_apply_wizard_complete_application_later_msg = _resolveComponent("consumer-loan-apply-wizard-complete-application-later-msg")!
  const _component_consumer_loan_apply_wizard_application_canceled_msg = _resolveComponent("consumer-loan-apply-wizard-application-canceled-msg")!
  const _component_consumer_invalid_link_msg = _resolveComponent("consumer-invalid-link-msg")!
  const _component_consumer_expired_link_msg = _resolveComponent("consumer-expired-link-msg")!
  const _component_consumer_loan_apply_wizard_reentry_step = _resolveComponent("consumer-loan-apply-wizard-reentry-step")!
  const _component_consumer_loan_apply_wizard_step_one = _resolveComponent("consumer-loan-apply-wizard-step-one")!
  const _component_consumer_loan_apply_wizard_step_two = _resolveComponent("consumer-loan-apply-wizard-step-two")!
  const _component_consumer_loan_apply_wizard_step_three = _resolveComponent("consumer-loan-apply-wizard-step-three")!
  const _component_consumer_loan_apply_wizard_step_four = _resolveComponent("consumer-loan-apply-wizard-step-four")!
  const _component_consumer_loan_apply_wizard_step_five = _resolveComponent("consumer-loan-apply-wizard-step-five")!
  const _component_consumer_loan_apply_wizard_step_six = _resolveComponent("consumer-loan-apply-wizard-step-six")!
  const _component_consumer_loan_apply_wizard_step_seven = _resolveComponent("consumer-loan-apply-wizard-step-seven")!
  const _component_consumer_loan_apply_wizard_step_eight = _resolveComponent("consumer-loan-apply-wizard-step-eight")!
  const _component_consumer_loan_apply_wizard_step_nine = _resolveComponent("consumer-loan-apply-wizard-step-nine")!
  const _component_consumer_loan_apply_wizard_step_ten = _resolveComponent("consumer-loan-apply-wizard-step-ten")!
  const _component_consumer_loan_apply_wizard_step_eleven = _resolveComponent("consumer-loan-apply-wizard-step-eleven")!
  const _component_consumer_loan_apply_wizard_step_twelve = _resolveComponent("consumer-loan-apply-wizard-step-twelve")!
  const _component_consumer_loan_apply_wizard_step_thirteen = _resolveComponent("consumer-loan-apply-wizard-step-thirteen")!
  const _component_consumer_loan_apply_wizard_step_fourteen = _resolveComponent("consumer-loan-apply-wizard-step-fourteen")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_alert_modal = _resolveComponent("alert-modal")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "widgetContentContainer",
    class: _normalizeClass(_ctx.classList)
  }, [
    _createVNode(_component_v_row, {
      justify: "center",
      class: "fs-unmask"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          md: "12",
          sm: "10",
          class: "cob-container"
        }, {
          default: _withCtx(() => [
            _createVNode(_Transition, {
              mode: "out-in",
              name: "slide"
            }, {
              default: _withCtx(() => [
                (_ctx.isCompleteLaterClicked)
                  ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_complete_application_later_msg, {
                      key: 0,
                      email: _ctx.email
                    }, null, 8, ["email"]))
                  : (_ctx.applicationCanceled)
                    ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_application_canceled_msg, { key: 1 }))
                    : (!_ctx.merchantId)
                      ? (_openBlock(), _createBlock(_component_consumer_invalid_link_msg, { key: 2 }))
                      : (_ctx.expiredLink)
                        ? (_openBlock(), _createBlock(_component_consumer_expired_link_msg, { key: 3 }))
                        : (_ctx.reEntryActivated)
                          ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_reentry_step, {
                              key: 4,
                              onCodeSent: _ctx.navigateToStepThree
                            }, null, 8, ["onCodeSent"]))
                          : (_ctx.wizardStep === 1)
                            ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_one, {
                                key: 5,
                                data: _ctx.data,
                                onNextStep: _ctx.navigateStep
                              }, null, 8, ["data", "onNextStep"]))
                            : (_ctx.wizardStep === 2)
                              ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_two, {
                                  key: 6,
                                  data: _ctx.data,
                                  onNextStep: _ctx.navigateStep
                                }, null, 8, ["data", "onNextStep"]))
                              : (_ctx.wizardStep === 3)
                                ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_three, {
                                    key: 7,
                                    data: _ctx.data,
                                    "show-header": _ctx.showHeader,
                                    onNextStep: _ctx.navigateStep
                                  }, null, 8, ["data", "show-header", "onNextStep"]))
                                : (_ctx.wizardStep === 4)
                                  ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_four, {
                                      key: 8,
                                      onNextStep: _ctx.navigateStep
                                    }, null, 8, ["onNextStep"]))
                                  : (_ctx.wizardStep === 5)
                                    ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_five, {
                                        key: 9,
                                        onNextStep: _ctx.navigateStep
                                      }, null, 8, ["onNextStep"]))
                                    : (_ctx.wizardStep === 6)
                                      ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_six, {
                                          key: 10,
                                          onNextStep: _ctx.navigateStep
                                        }, null, 8, ["onNextStep"]))
                                      : (_ctx.wizardStep === 7)
                                        ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_seven, {
                                            key: 11,
                                            onNextStep: _ctx.navigateStep
                                          }, null, 8, ["onNextStep"]))
                                        : (_ctx.wizardStep === 8)
                                          ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_eight, {
                                              key: 12,
                                              data: _ctx.data,
                                              onNextStep: _ctx.navigateStep
                                            }, null, 8, ["data", "onNextStep"]))
                                          : (_ctx.wizardStep === 9)
                                            ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_nine, {
                                                key: 13,
                                                data: _ctx.data,
                                                onNextStep: _ctx.navigateStep
                                              }, null, 8, ["data", "onNextStep"]))
                                            : (_ctx.wizardStep === 10)
                                              ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_ten, {
                                                  key: 14,
                                                  data: _ctx.data,
                                                  onNextStep: _ctx.navigateStep
                                                }, null, 8, ["data", "onNextStep"]))
                                              : (_ctx.wizardStep === 11)
                                                ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_eleven, {
                                                    key: 15,
                                                    onNextStep: _ctx.navigateStep
                                                  }, null, 8, ["onNextStep"]))
                                                : (_ctx.wizardStep === 12)
                                                  ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_twelve, {
                                                      key: 16,
                                                      onNextStep: _ctx.navigateStep
                                                    }, null, 8, ["onNextStep"]))
                                                  : (_ctx.wizardStep === 13)
                                                    ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_thirteen, {
                                                        key: 17,
                                                        onNextStep: _ctx.navigateStep
                                                      }, null, 8, ["onNextStep"]))
                                                    : (_ctx.wizardStep === 14)
                                                      ? (_openBlock(), _createBlock(_component_consumer_loan_apply_wizard_step_fourteen, { key: 18 }))
                                                      : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_alert_modal, {
      dialog: _ctx.showErrorAlertModal,
      "hide-close": true,
      type: "error"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.errorResponseAlertModal), 1)
      ]),
      _: 1
    }, 8, ["dialog"])
  ], 2))
}