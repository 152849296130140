enum ConsumerApplicationStatuses {
  SUBMITTED = 'Submitted',
  DECLINE = 'Decline',
  CANCEL = 'Cancel',
  PEND = 'Pend',
  APPROVE = 'Approve',
  APPROVED_ACCEPTED = 'Approved: Accepted',
  INCOMPLETE = 'Incomplete',
}

export default ConsumerApplicationStatuses;
