import { NewUserInvitationTokenDataInterface } from '@/interfaces/auth/NewUserInvitationTokenDataInterface';
import { AuthStateInterface } from '@/interfaces/states/AuthStateInterface';
import ResetModuleState from '@/utils/store/ResetModuleState';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

function getObjectFromLocalStorage(key: string, defaultValue: any) {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : defaultValue;
}

const initialState = (): AuthStateInterface => ({
  sessionId: localStorage.getItem('sessionId'),
  user: {},
  group: [],
  merchant: {},
  newUserInviteTokenData: {} as NewUserInvitationTokenDataInterface,
  merchantUuid: localStorage.getItem('merchantUuid'),
  loggingOut: false,
  user_id: getObjectFromLocalStorage('userId', null),
  gucaData: {},
});

export default {
  namespaced: true as true,
  state: initialState() as AuthStateInterface,
  getters,
  mutations: {
    resetModuleState(state: any) {
      ResetModuleState(state, initialState());
    },
    ...mutations,
  },
  actions,
};
