<template>
  <v-container
    fluid
    class="fs-unmask merchant__statements portal__page portal__page--card-full-size">
    <merchant-portal-statements />
  </v-container>
</template>

<script lang="ts">
import MerchantPortalStatements from '@/components/Merchant/Portal/Statements/Statements.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PortalStatements',
  components: {
    MerchantPortalStatements,
  },
});
</script>
