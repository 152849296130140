enum MerchantStatusReasons {
  POST_EMAIL_PRE_OTP = 'Post Email / Pre-OTP',
  POST_OTP_PRE_PASSWORD = 'Post-OTP, Pre-Password',
  POST_OTP_TOKEN_CREATED = 'Post-OTP, Token Created',
  POST_PASSWORD_PRE_OWNERS = 'Post-Password, Pre-Owners',
  POST_OWNERS_PRE_INDUSTRY = 'Post-Owners / Pre-Industry',
  POST_INDUSTRY_PRE_BUSINESS_NAME = 'Post-Industry / Pre-Business Name',
  POST_BUSINESS_NAME_PRE_BUSINESS_STRUCTURE = 'Post Business Name/ Pre Business Structure',
  POST_BUSINESS_STRUCTURE_PRE_DBS = 'Post Business Structure / Pre DBS',
  POST_DBS_PRE_ASR_ACS = 'Post DBS / Pre ASR ACS',
  POST_ASR_ACS_PRE_PAYMENTS_ACCEPTED_YFV = 'Post ASR ACS / Pre Payments Accepted YFV',
  POST_PAYMENTS_ACCEPTED_YFV_PRE_CONFIRM_ALL = 'Post Payments Accepted YFV / Pre Confirm All',
  POST_SUBMIT_APP_PRE_PAYMENT_PROVIDER = 'Post Submit App / Pre-Payment Provider',
  POST_PAYMENT_PROVIDER_PRE_RATE_SHEET = 'Post Payment Provider / Pre-Rate Sheet',
}

export default MerchantStatusReasons;
