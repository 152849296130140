import { EnvConfig } from '@/interfaces/EnvConfig';
import { ConfigStateInterface } from '@/interfaces/states/ConfigStateInterface';

export function getConfig(state: ConfigStateInterface): ConfigStateInterface {
  return state;
}

export function getEnvVariables(state: ConfigStateInterface): EnvConfig {
  return state.env;
}

export function getFeatureFlags(state: ConfigStateInterface): Array<string> {
  return state.featureFlags;
}
