import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { RateSheetsPostDataInterface } from '@/interfaces/rates/RateSheetsPostDataInterface';
import { map } from 'lodash';

export default function GetRateSheetPostData(
  rateSheets: Array<RateSheetData>,
): RateSheetsPostDataInterface {
  const rateSheetsPostData: RateSheetsPostDataInterface = {
    rate_sheet_merchant_product_price_ids: map(rateSheets, rate => ({
      rate_sheet_merchant_product_price_id: rate.rate_sheet_merchant_product_price_id,
      is_selected: rate.is_selected,
    })),
  };

  return rateSheetsPostData;
}
