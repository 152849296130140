<template>
  <v-text-field
    v-bind="$attrs"
    :disabled="disabled"
    autocomplete="disabled"
    :data-test="dataTestAttr"
    :label="label"
    :variant="variantAttr"
    :required="required"
    :rules="rules || validationRules"
    validate-on="blur" />
</template>

<script lang="ts">
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'TextInput',

  mixins: [InputFieldMixin],

  props: {
    variant: { type: String, default: null },
    rules: { type: Array as PropType<Array<Function> | null>, default: null },
    max: { type: [Number, String], default: null },
    disabled: { type: Boolean, default: false },
  },

  data: () => ({
    defaultLabelAttr: 'text',
  }),

  computed: {
    variantAttr() {
      if (this.variant) return this.variant;
      if (this.inPlace) {
        return this.disabled ? 'plain' : 'underlined';
      }
      return 'outlined';
    },
    validationRules(): Array<Function> {
      if (this.max) {
        return [
          this.isRequired,
          this.maxLengthValidation,
        ];
      }

      return [this.isRequired];
    },
  },

  methods: {
    maxLengthValidation(value: string) {
      if (value.length > Number(this.max)) {
        return `${this.label} must be less than ${this.max} characters`;
      }

      return true;
    },
  },
});
</script>

<style lang="scss" scoped>
:deep() input::placeholder {
  font-size: var(--momnt-input-placeholder-font-size);
}
</style>
