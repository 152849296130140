import { MerchantBeginOnboardingStateInterface } from '@/interfaces/states/MerchantBeginOnboardingStateInterface';

export function getShowBeginOnboardingWizard(state: MerchantBeginOnboardingStateInterface) {
  return state.showBeginOnboardingWizard;
}

export function getUserDetails(state: MerchantBeginOnboardingStateInterface) {
  return state.userDetails;
}

export function getMerchantDetails(state: MerchantBeginOnboardingStateInterface) {
  return state.merchantDetails;
}

export function getMerchantApplicationId(state: MerchantBeginOnboardingStateInterface) {
  return state.merchantApplicationId;
}

export function getMerchantId(state: MerchantBeginOnboardingStateInterface) {
  return state.merchantId;
}

export function getProgramUid(state: MerchantBeginOnboardingStateInterface) {
  return state.programUid;
}

export function getUserId(state: MerchantBeginOnboardingStateInterface) {
  return state.userId;
}
