import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _directive_mask = _resolveDirective("mask")!

  return _withDirectives((_openBlock(), _createBlock(_component_v_text_field, _mergeProps({ ref: "input" }, _ctx.attrs, {
    autocomplete: "off",
    "data-test": _ctx.dataTestAttr,
    disabled: _ctx.disabled,
    hint: _ctx.hint,
    class: "currency-input",
    "persistent-hint": "",
    label: _ctx.labelAttr,
    variant: _ctx.variantAttr,
    "prepend-inner-icon": _ctx.showIcon ? 'mdi-currency-usd' : '',
    rules: _ctx.validationRules,
    "validate-on": "blur",
    "model-value": _ctx.modelValue,
    onClick: _ctx.onClick,
    onKeydown: _ctx.onKeyDown
  }), _createSlots({ _: 2 }, [
    (_ctx.showLearnMore)
      ? {
          name: "message",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          key: "0"
        }
      : undefined
  ]), 1040, ["data-test", "disabled", "hint", "label", "variant", "prepend-inner-icon", "rules", "model-value", "onClick", "onKeydown"])), [
    [_directive_mask, _ctx.inputMask]
  ])
}