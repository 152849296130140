import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import { get } from 'lodash';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [DateTimeMixin],
  computed: {
    loanApplyWizardData(): any {
      return this.$store.getters['Consumer/getLoanApplyWizardData'];
    },
    requestedLoanAmount(): string {
      return get(this.loanApplyWizardData, 'requested_loan_amount', 0);
    },
    requestedLoanAmountLabel(): string {
      return CurrencyFormatLong(this.requestedLoanAmount);
    },
  },
});
