<template>
  <standard-dialog
    :dialog="showDialog"
    :hide-header-close="true"
    :show-actions="false"
    :persistent="true">
    <template #body>
      <div>
        <p class="mb-4">
          Your deposits will take at least 30 minutes but up
          to 24 hours to arrive in your account.
          The deposits will come from ACCTVERIFY and look something like this.
        </p>
        <verify-deposits-example-table />
      </div>

      <custom-button
        full-width
        @click="$emit('cancel')">
        Close
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import VerifyDepositsExampleTable from '@/components/PaymentProviders/VerifyDepositsExampleTable.vue';

export default defineComponent({
  name: 'VerifyDepositsExampleDialog',

  components: {
    StandardDialog,
    CustomButton,
    VerifyDepositsExampleTable,
  },

  props: {
    showDialog: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
</style>
