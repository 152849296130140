import { ActionContext } from 'vuex';
import { PrintConfigIface } from '@/interfaces/PrintConfigInterface';

interface StateIface {
  currentPrintConfig: any | null;
}

export default {
  namespaced: true,
  state: {
    currentPrintConfig: null,
  },
  getters: {
    getPrintConfig(state: StateIface) {
      return state.currentPrintConfig;
    },
  },
  mutations: {
    setPrintConfig(state: StateIface, config: PrintConfigIface) {
      state.currentPrintConfig = config;
    },
  },
  actions: {
    setPrintConfig(context: ActionContext<StateIface, any>, payload: PrintConfigIface) {
      context.commit('setPrintConfig', payload);
    },
    clearPrintConfig(context: ActionContext<StateIface, any>) {
      context.commit('setPrintConfig', null);
    },
  },
};
