<template>
  <text-input
    v-mask="inputMask"
    v-bind="$attrs"
    inputmode="numeric"
    :data-test="dataTestAttr"
    :in-place="inPlace"
    :label="labelAttr"
    :required="required"
    :rules="[isRequired, isValidZipCode]" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputFieldMixin from '@/components/Inputs/InputFieldMixin';
import { INPUT_MASK_DEFAULTS } from '@/components/Inputs/defaults';
import TextInput from '@/components/Inputs/Text.vue';
import validateZipCode from '@/validators/zip_code';

export default defineComponent({
  name: 'ZipCodeInput',

  components: { TextInput },

  mixins: [InputFieldMixin],

  data: () => ({
    defaultLabelAttr: 'Zip Code',
    defaultDataTestAttr: 'zipCode',
    inputMask: {
      ...INPUT_MASK_DEFAULTS,
      mask: '99999[-9999]',
      greedy: false,
    },
  }),

  methods: {
    isValidZipCode(zipCode: string) {
      return validateZipCode(zipCode) || 'Please insert a valid zip code';
    },
  },
});
</script>
