import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_combobox = _resolveComponent("v-combobox")!

  return (_openBlock(), _createBlock(_component_v_combobox, _mergeProps({ ref: "select" }, _ctx.$attrs, {
    "menu-props": { attach: _ctx.attach },
    autocomplete: "off",
    "auto-select-first": "",
    "append-inner-icon": _ctx.appendIcon,
    "data-test": _ctx.dataTestAttr,
    items: _ctx.items,
    "item-title": _ctx.itemText,
    "item-value": _ctx.itemValue,
    label: _ctx.labelAttr,
    variant: _ctx.inPlace ? 'plain' : 'outlined',
    required: _ctx.required,
    rules: [_ctx.isRequired],
    "validate-on": "blur",
    "onUpdate:modelValue": _ctx.returnSelectedUser,
    "onUpdate:search": _ctx.inputUpdate
  }), _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope)), undefined, true)
        ])
      }
    })
  ]), 1040, ["menu-props", "append-inner-icon", "data-test", "items", "item-title", "item-value", "label", "variant", "required", "rules", "onUpdate:modelValue", "onUpdate:search"]))
}