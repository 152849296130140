<template>
  <footer class="main-footer" data-test="mainFooter">
    <v-container class="pa-4">
      <v-row class="main-footer__row">
        <v-col class="main-footer__item main-footer__item--logo">
          <div class="flex-column main-footer__logo">
            <span data-test="poweredByLabel">
              Powered by
            </span>
            <img
              :src="footerLogo"
              height="32px"
              alt="Logo"
              class="logo"
              data-test="footerLogoImg">
          </div>
          <span class="pr-1" data-test="copyrightLabel">© {{ currentYear }}</span>
          <span v-if="footerData.legal_business_name" data-test="businessNameLabel">
            {{ footerData.legal_business_name }}
          </span>
          <br>
          <span data-test="rightsReservedLabel">All Rights Reserved</span>
        </v-col>

        <v-col class="main-footer__item main-footer__item--address">
          <ul>
            <li data-test="addressLabel">
              {{ footerAddress[0] || '' }}
              <br>
              {{ footerAddress[1] || '' }}
              <br>
              {{ footerAddress[2] || '' }}{{ footerAddress[3] ? `, ${footerAddress[3]}` : '' }}
            </li>
          </ul>
        </v-col>

        <v-col class="main-footer__item main-footer__item">
          <div v-if="showHumanReadableId" data-test="humanReadableId">
            <span data-test="humanReadableIdSpan">
              {{ humanReadableIdPrefix }} {{ humanReadableId }}
            </span>
          </div>
          <div>
            <span data-test="suppInfoLbl"><strong>Support Hours and Contact Info</strong></span>
          </div>
          <div>
            <ul v-if="!footerData.support_days_info" class="nested-ul">
              <li v-if="footerData.support_days && footerData.support_hours" data-test="suppDayLbl">
                {{ footerData.support_days }}: {{ footerData.support_hours }}
              </li>
            </ul>

            <ul v-else class="nested-ul">
              <li v-for="(el, i) in footerData.support_days_info" :key="i">
                {{ el.support_days }}: {{ el.support_hours }}
              </li>
            </ul>

            <ul class="nested-ul" data-test="contactInfoUl">
              <li v-if="footerData.support_email">
                <a :href="`mailto:${footerData.support_email}`" data-test="supportEmailLink">
                  {{ footerData.support_email }}
                </a>
              </li>
              <li v-if="footerData.support_number">
                <a :href="`tel:${footerData.support_number}`" data-test="supportNumberLink">
                  {{ footerData.support_number }}
                </a>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { UiStoreBrandingData } from '@/interfaces/states/UiStoreBrandingData';

export default defineComponent({
  props: {
    enableHumanReadableId: {
      type: Boolean,
      default: true,
    },
    humanReadableId: {
      type: String,
      default: '',
    },
    humanReadableIdPrefix: {
      type: String,
      default: 'Your ID:',
    },
  },

  computed: {
    footerAddress(): Array<string> {
      const { footer_address } = this.footerData;
      return footer_address ? footer_address.split(',') : [];
    },

    brandData(): UiStoreBrandingData {
      return this.$store.getters['Ui/getBrandingObject'];
    },

    footerLogo(): string | undefined {
      return this.brandData.footer_logo_url;
    },

    footerData(): any {
      return this.$store.getters['Ui/getFooterData'] || {};
    },

    showHumanReadableId(): boolean {
      return this.enableHumanReadableId && !!this.humanReadableId;
    },

    currentYear(): number {
      return new Date().getFullYear();
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main-footer";
@import "@/assets/scss/mixins/mixins";

.normal-ul {
  list-style: none;
  padding-left: 0;
}

.nested-ul {
  @include list-style-bullet;
}

.logo {
  max-width: 13.125rem;
}
</style>
