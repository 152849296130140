<template>
  <div class="standard-wizard__step standard-wizard__step--one">
    <v-card>
      <v-card-title>Get Financing Today</v-card-title>
      <v-card-text class="mt-5">
        <p>
          <span class="d-block">No Worry Shopping</span>
          Shop pre-qualified offers without affecting your credit score.
        </p>

        <p>
          <span class="d-block">Competitive Offerings</span>
          100% financing, no money down, receive instant approval.
        </p>
      </v-card-text>

      <email-input
        v-model="stepData.email"
        :disabled="emailInputDisabled"
        :error-messages="errors.email"
        required
        show-icon
        label="Email Address" />

      <div class="mb-6">
        <span>- or -</span>
      </div>

      <phone-input
        v-model="stepData.phone_number"
        :disabled="phoneInputDisabled"
        :error-messages="errors.phone_number"
        required
        show-icon
        label="Mobile Phone Number" />

      <div class="standard-consent-and-input-notation-text">
        <span>
          By clicking below, you authorize Momnt to contact you via email
          or text message with a link to a loan application.
        </span>
      </div>

      <custom-button :disabled="isDisabled" @click="nextStep">
        Get Started Now
      </custom-button>
    </v-card>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import PhoneInput from '@/components/Inputs/Phone.vue';
import EmailInput from '@/components/Inputs/Email.vue';
import GetErrors from '@/mixins/GetErrors';
import emailAddressValidator from '@/validators/email_address';
import phoneNumberValidator from '@/validators/phone_number';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    CustomButton,
    PhoneInput,
    EmailInput,
  },
  mixins: [GetErrors],
  data() {
    return {
      stepData: {
        phone_number: '',
        email: '',
      },
      processing: false,
    };
  },
  computed: {
    emailInputDisabled(): boolean {
      return !!this.stepData.phone_number || this.processing;
    },
    phoneInputDisabled(): boolean {
      return !!this.stepData.email || this.processing;
    },
    isDisabled(): boolean {
      const { phone_number = '', email = '' } = this.stepData;
      return (!phoneNumberValidator(phone_number) && !emailAddressValidator(email))
        || this.processing;
    },
  },
  mounted() {
    const { email, phone_number } = this.$store.getters[
      'ConsumerLoanApplication/getLoanApplicationWizardData'
    ];
    this.stepData = { email, phone_number };
  },
  methods: {
    async nextStep() {
      this.processing = true;
      const responseStatus = await this.$store.dispatch(
        'ConsumerLoanApplication/dispatchSendInvitation',
        this.stepData,
      );

      this.processing = false;

      if (responseStatus === 201) {
        this.$emit('nextStep');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/consumer-loan-application-wizard";

.standard-wizard {
  &__step {
    &--one {
      text-align: center;
      width: 22.5rem;
      margin-bottom: 0;

      p {
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 25px;
        text-align: left;
      }

      .standard-input-field:first-of-type {
        margin-top: 1.25rem;
        margin-bottom: 1.45rem;
      }

      .standard-input-field:last-of-type {
        margin-bottom: 0;
        margin-top: 0.75rem;
      }

      .standard-consent-and-input-notation-text {
        margin-top: 1.125rem;
        margin-bottom: 1.125rem;
        padding: 0 15px;
        text-align: left;
      }

      .v-input {
        margin-bottom: 0;
        padding: 0 15px;
      }

      .custom-button {
        margin-bottom: 1.562rem;
      }
    }
  }
}
</style>
