import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ec391036"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  "data-test": "gladAlert"
}
const _hoisted_2 = {
  key: 0,
  class: "px-4"
}
const _hoisted_3 = {
  key: 1,
  class: "px-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_payment_form_fields = _resolveComponent("payment-form-fields")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isVerificationFailedStatus)
      ? (_openBlock(), _createBlock(_component_v_col, {
          key: 0,
          lg: "12",
          class: "payment-options-select__msg pb-0 pt-4 px-0"
        }, {
          default: _withCtx(() => [
            (_ctx.merchantData)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_ctx.hasRepayAccount)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createTextVNode(" To get started, please confirm your bank account ending in "),
                        _createElementVNode("strong", null, _toDisplayString(_ctx.lastFour), 1),
                        _createTextVNode(". We will send two deposits to your bank account, and later you will verify the amount of those deposits to complete the connection. ")
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, " To connect your bank account, please provide the information below. We will send two deposits to your bank account, and later you will verify the amount of those deposits to complete the connection. "))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isVerificationFailedStatus)
      ? (_openBlock(), _createBlock(_component_custom_button, {
          key: 1,
          class: "authorize-button",
          "full-width": "",
          onClick: _ctx.resendDeposits
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Send New Deposits ")
          ]),
          _: 1
        }, 8, ["onClick"]))
      : (_openBlock(), _createBlock(_component_payment_form_fields, {
          key: 2,
          "block-cols": 0,
          "last-four": _ctx.lastFour,
          "has-repay-acc": _ctx.hasRepayAccount,
          "show-cancel-button": _ctx.showCancelButton,
          "is-onboarding": _ctx.isOnboarding,
          onPayTokenSuccess: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('passTokenSuccess'))),
          onSendFormDetails: _ctx.sendFormDetails,
          onCancelForm: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancelForm')))
        }, null, 8, ["last-four", "has-repay-acc", "show-cancel-button", "is-onboarding", "onSendFormDetails"]))
  ]))
}