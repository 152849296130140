import lodashDebounce from 'lodash/debounce';

// eslint-disable-next-line import/prefer-default-export
export function useDebounce(delay = 500) {
  function debounce(fn: () => void) {
    return lodashDebounce(() => {
      fn();
    }, delay);
  }

  return { debounce };
}
