import { EnvConfig } from '@/interfaces/EnvConfig';
import { ConfigStateInterface } from '@/interfaces/states/ConfigStateInterface';

export function setEnvVariables(state: ConfigStateInterface, variables: EnvConfig) {
  Object.entries(variables).forEach(([key, value]: [string, string]) => {
    state.env[key as keyof EnvConfig] = value;
  });
}

export function setFeatureFlags(state: ConfigStateInterface, flags: Array<string>) {
  state.featureFlags = flags;
}
