<template>
  <bubble-card
    :class="[
      { 'select-offer-selected': selected },
      'card',
    ]"
    :card-color="blueThemeCategories ? 'navy' : 'teal'">
    <template #card-title>
      <div class="card__header">
        <h5>{{ rateSheet.json_description.title }}</h5>

        <div class="card__header__more">
          <rate-sheet-options-more-data
            data-test="moreBtn"
            :disabled="disabled"
            :on-cta="selectItem"
            :rate-sheet-option="rateSheet"
            :option-selection="optionSelection" />
        </div>
      </div>
    </template>

    <div>
      <div class="card__body">
        <div
          class="card__body--during">
          <div class="card__body__description">
            {{ rateSheet.json_description.note }}
            {{ rateSheet.json_description.total_term_header }} {{ rateSheet.json_description.total_term }}.
          </div>

          <div class="card__body__cards">
            <card-details :card="cardsDuring" :is-deferred-offer="blueThemeCategories" />
          </div>
        </div>
      </div>
    </div>

    <template #card-actions>
      <div v-if="isBufferAmount" class="compare-payments-wrap">
        <v-expansion-panels variant="accordion">
          <v-expansion-panel class="compare-panel standard-collapsable-panel">
            <v-expansion-panel-title class="card__header compare-payments">
              <span class="compare-payments__title">
                Compare Payments at
                <span v-private>{{ CurrencyFormatLong(requestedAmount) }}</span> and
                <span v-private>{{ CurrencyFormatLong(approvedAmount) }}</span>
              </span>
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <span class="compare-panel__cont-text">
                APR and Term remain the same regardless of spend amount.
              </span>

              <div class="compare-panel__table">
                <loan-compare-table :items="comparisonArray" />
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="button-wrap">
        <custom-button
          variant="primary"
          class="select-offer"
          size="medium"
          full-width
          @click="selectItem">
          {{ selectionButtonText }}
        </custom-button>
      </div>
    </template>
  </bubble-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import CardDetails from '@/components/RateSheetOptions/ExpandableCategories/CardsCardDetails.vue';
import RateSheetOptionsMoreData from '@/components/RateSheetOptions/MoreData.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import LoanCompareTable from '@/components/RateSheetOptions/ExpandableCategories/LoanCompareTable.vue';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import CreditEngineSoftPullApprovedAmountMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineSoftPullApprovedAmountMixin';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import BubbleCard from '@/components/Cards/BubbleCard.vue';

export default defineComponent({
  name: 'RateSheetCards',

  components: {
    CardDetails,
    RateSheetOptionsMoreData,
    LoanCompareTable,
    CustomButton,
    BubbleCard,
  },

  mixins: [CreditEngineSoftPullApprovedAmountMixin],

  props: {
    disabled: { type: Boolean, default: false },
    rateSheet: { type: Object as PropType<RateSheetData>, default: () => ({}) },
    optionSelection: { type: Boolean, default: false },
    isBufferAmount: { type: Boolean, default: false },
    requestedAmount: { type: [Number, String], default: 0 },
    approvedAmount: { type: Number, default: 0 },
    selectedRatesheet: { type: String, default: '' },
  },

  data() {
    const comparisonArray: Array<any> = [];
    return {
      comparisonArray,
      selected: false,
    };
  },

  computed: {
    cardsDuring(): any {
      const {
        promo_header,
        promo_payment_header,
        promo_payment,
        promo_apr_header,
        promo_apr,
        promo_term,
        standard_header,
        standard_term_length,
        standard_payment_header,
        standard_payment,
      } = this.rateSheet.json_description || {};

      return {
        promoPayment: {
          promo_header: promo_header || '',
          payment_header: promo_payment_header,
          payment_value: promo_payment,
          term: promo_term,
        },
        promoApr: {
          header: promo_apr_header,
          value: promo_apr,
        },
        standardPayment: {
          header: standard_header,
          term: standard_term_length,
          payment_header: standard_payment_header,
          payment_value: standard_payment,
        },
      };
    },

    loanApplyWizardData(): LoanApplyDataInterface {
      return this.$store.getters['Consumer/getLoanApplyWizardData'];
    },

    requestedLoanAmount(): string | number {
      return this.loanApplyWizardData?.requested_loan_amount || '';
    },

    selectionButtonText() {
      return this.selected ? 'Selected' : 'Select This Offer';
    },

    blueThemeCategories() {
      const cat = this.rateSheet.json_description.category;
      return cat === 'Deferred Interest' || cat === 'Same-As-Cash' || cat === 'Zero Interest Loan';
    },
  },

  watch: {
    rateSheet: {
      immediate: true,
      handler(val: any) {
        const desc = val.json_description;
        if (desc.comparison_standard_payment) {
          this.comparisonArray.push({
            spend: this.requestedLoanAmount,
            promo: desc.comparison_promo_payment,
            standard: desc.comparison_standard_payment,
          });
        }
        this.comparisonArray.push({
          spend: this.approvedAmountSoftPull,
          promo: desc.promo_payment,
          standard: desc.standard_payment,
        });
      },
    },
    selectedRatesheet: {
      immediate: true,
      handler(val: string) {
        if (val !== this.rateSheet.product_name) {
          this.selected = false;
        }
      },
    },
  },

  methods: {
    CurrencyFormatLong,

    selectItem() {
      this.selected = !this.selected;

      if (this.selected) {
        this.$emit('select', this.rateSheet);
      } else {
        this.$emit('select', {});
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/variables/font-variables";
@import "@/assets/scss/vuetify/elevation.scss";

$padding: 1rem;

:deep(.bubble-card__actions) {
  display: flex;
  flex-direction: column;
  position: relative !important;
  padding: 0;

  .button-wrap {
    padding: 1rem;
    width: 100%;
  }
}

.select-offer-selected {
  border: 4px solid $momnt-turquoise !important;
}

.box-shadow-card {
  box-shadow: $v-elevation-4 !important;
}

.no-shadow-card {
  border: 1px solid var(--grayscale-color-2);
  box-shadow: none !important;
}

.card {
  margin-bottom: 2rem !important;
  color: var(--grayscale-color-1);

  .select-offer {
    background-color: $momnt-turquoise !important;
    color: white !important;
    font-size: 0.875rem !important;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h5 {
      color: $momnt-navy;
      font-size: 0.875rem;
    }
  }

  &__body {
    padding: 0;

    &__description {
      font-size: 0.875rem;
      line-height: 150%;
      text-align: left;
      color: inherit;
      padding: 0.5rem 1rem;
    }

    &__cards {
      display: flex;
      margin-top: 0.5rem;
    }

    &--during,
    &--after {
      text-align: center;
      min-height: 6rem;
    }

    &--during {
      background-color: #ffffff;
    }

    &--after {
      background-color: var(--grayscale-color-5);
    }
  }
}

.compare-payments-wrap {
  width: 100%;

  .compare-panel {
    border: none !important;
    min-height: 3rem !important;

    &:before {
      box-shadow: none;
    }

    &__cont-text {
      font-size: 0.875rem;
      color: var(--grayscale-color-1);
      display: block;
      text-align: center;
      padding: 0.5rem 0;
    }
  }

  :deep(.v-table) .v-table__wrapper > table > tbody > tr > td {
    border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
  }
}

.standard-collapsable-panel {
  &__title {
    border-radius: 0 !important;
  }

  &__title--2 {
    font-size: 0.875rem !important;
    padding: 0.875rem 1rem !important;
    background-color: #ffffff !important;
  }
}

.compare-payments {
  color: $momnt-turquoise;
  font-size: 1rem;
  padding: 1rem;

  :deep(.v-icon) {
    color: $momnt-turquoise !important;
  }

  &__title {
    font-size: 0.875rem;
  }
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}
</style>
