<template>
  <v-form class="page page--reset-password portal" @submit.prevent="resetPasswordConfirm">
    <v-container class="portal__header">
      <v-row justify="center">
        <v-col
          lg="5"
          md="6"
          class="pa-0">
          <div>
            <img
              :src="logoUrl"
              alt="Logo"
              class="standard-logo">
          </div>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-0">
        <v-col
          lg="5"
          md="6"
          class="pa-0">
          <h4 class="mb-0 mt-0">
            Merchant Portal
          </h4>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="portal__container">
      <v-row justify="center">
        <v-col lg="5" md="6">
          <password-input
            v-model="passwordData.password"
            data-test="passwordInput"
            :disabled="processing"
            :error-messages="errors.password"
            label="New Password"
            :password-verify="passwordData.password_check"
            :show-password-while-typing="true"
            show-requirements-tooltip
            @blur="resetErrorState" />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col lg="5" md="6">
          <password-input
            v-model="passwordData.password_check"
            data-test="passwordCheckInput"
            :disabled="processing"
            :error-messages="errors.password_check"
            label="Verify New Password"
            :password-verify="passwordData.password"
            :show-password-while-typing="true"
            @blur="resetErrorState" />
        </v-col>
      </v-row>

      <v-row v-if="!!errors.non_field_errors" justify="center">
        <v-col lg="5" md="6">
          <custom-alert
            v-for="(error, index) in errors.non_field_errors"
            :key="index"
            type="error">
            {{ error }}
          </custom-alert>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col lg="5" md="6">
          <custom-button
            :disabled="!passwordRequirementsMet || btnDisabled"
            data-test="submitBtn"
            full-width
            type="submit">
            Submit
          </custom-button>
        </v-col>
      </v-row>
    </v-container>

    <main-footer class="portal__footer" />
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import PasswordInput from '@/components/Inputs/Password.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { forgotPasswordConfirm } from '@/api/account';
import { mapGetters } from 'vuex';
import MainFooter from '@/components/MainFooter.vue';
import { ResetPasswordConfirmInterface } from '@/interfaces/auth/ResetPasswordConfirmInterface';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import GotoRouteName from '@/mixins/GotoRouteName';
import password from '@/validators/password';
import uuid from '@/validators/uuid';
import { isEmpty } from 'lodash';
import UserLogOutMixin from '@/mixins/Auth/UserLogOutMixin';

export default defineComponent({
  name: 'ResetPassword',

  components: {
    CustomButton,
    CustomAlert,
    PasswordInput,
    MainFooter,
  },

  mixins: [
    GotoRouteName,
    GetErrors,
    GetProcessing,
    UserLogOutMixin,
  ],

  beforeRouteEnter(to, _, next) {
    if (uuid(to.query?.token as string || '')) {
      next();
    } else {
      next({ name: 'merchant-login' });
    }
  },

  data() {
    const passwordData: ResetPasswordConfirmInterface = {
      password: '',
      password_check: '',
      token: '',
    };
    return {
      btnDisabled: false,
      passwordData,
    };
  },

  computed: {
    ...mapGetters('Ui', {
      logoUrl: 'getBrandingHeaderLogo',
    }),

    passwordRequirementsMet(): boolean {
      return this.passwordData.password === this.passwordData.password_check
        && password(this.passwordData.password)
        && !isEmpty(this.passwordData.token);
    },
  },

  created() {
    this.passwordData.token = this.$route.query?.token as string || '';
  },

  methods: {
    async resetPasswordConfirm(): Promise<void> {
      this.btnDisabled = true;
      try {
        await forgotPasswordConfirm(this.passwordData);
        this.$store.dispatch('MerchantPortal/showResetPwSuccessMsg', true);
        this.gotoRouteName('merchant-login');
      } catch (error: any) {
        this.$store.dispatch('Ui/setErrors', error.response);
      }
      this.btnDisabled = false;
    },

    resetErrorState() {
      this.$store.dispatch('Ui/resetErrorState');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/mixins";

@include portal-page-layout;

.portal {
  &__header {
    text-align: center;
    padding-bottom: 0;
    margin-bottom: 5rem;
  }

  &__container {
    padding-bottom: 1rem;
  }
}
</style>
