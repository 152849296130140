import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_currency_input = _resolveComponent("currency-input")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_standard_dialog = _resolveComponent("standard-dialog")!
  const _component_confirm_dialog = _resolveComponent("confirm-dialog")!
  const _directive_private = _resolveDirective("private")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_standard_dialog, {
      dialog: _ctx.dialog,
      "on-cta": _ctx.onCta,
      "on-cancel": _ctx.onDialogCancel,
      title: "Please Enter Refund Amount",
      persistent: true,
      "show-cancel-cta": false,
      "show-clear-cta": false,
      "show-close-confirmation": false,
      "show-actions": false,
      "light-title": "",
      "data-test": "refundAnotherAmountModal"
    }, {
      body: _withCtx(() => [
        _createVNode(_component_v_form, { ref: "form" }, {
          default: _withCtx(() => [
            _createVNode(_component_currency_input, {
              modelValue: _ctx.refundAmount,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.refundAmount) = $event)),
                _ctx.makeInputDirty
              ],
              "allow-cents": "",
              disabled: _ctx.disableInput,
              "error-messages": _ctx.customErrorMessage.amount,
              max: _ctx.maxRefundableAmount,
              min: 0,
              label: "Refund Amount",
              "show-icon": ""
            }, null, 8, ["modelValue", "disabled", "error-messages", "max", "onUpdate:modelValue"])
          ]),
          _: 1
        }, 512),
        _createVNode(_component_custom_button, {
          "data-test": "sendRefund",
          "full-width": "",
          disabled: _ctx.disableBtn,
          onClick: _ctx.openConfirmation
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Send ")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      _: 1
    }, 8, ["dialog", "on-cta", "on-cancel"]),
    _createVNode(_component_confirm_dialog, {
      "show-confirm-dialog": _ctx.showConfirmDialog,
      "on-cancel": _ctx.closeConfirmation,
      "on-confirm": _ctx.sendRefund,
      "title-text": _ctx.confirmTitle,
      "disable-confirm-button": _ctx.disableConfirmButton,
      "light-title": ""
    }, {
      body: _withCtx(() => [
        _createElementVNode("span", null, [
          _createTextVNode(" Refund "),
          _withDirectives((_openBlock(), _createElementBlock("strong", null, [
            _createTextVNode(_toDisplayString(_ctx.CurrencyFormatLong(_ctx.refundAmount)), 1)
          ])), [
            [_directive_private]
          ]),
          _createTextVNode(" to "),
          _withDirectives((_openBlock(), _createElementBlock("strong", null, [
            _createTextVNode(_toDisplayString(_ctx.refundToName), 1)
          ])), [
            [_directive_private]
          ]),
          _createTextVNode(". ")
        ])
      ]),
      _: 1
    }, 8, ["show-confirm-dialog", "on-cancel", "on-confirm", "title-text", "disable-confirm-button"])
  ]))
}