<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--processing-application">
    <p data-test="nowProcessingLabel">
      Now processing your application!
    </p>

    <p data-test="thisMayTakeLabel">
      This may take a few moments. Please do not press back or close your browser window.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProccessingApplication',
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/merchant-begin-onboarding-wizard";
</style>
