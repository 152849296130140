<template>
  <v-expansion-panel class="v-elevation-4 standard-collapsable-panel">
    <v-expansion-panel-title class="standard-collapsable-panel__title">
      <template #default="{ expanded }">
        <div class="d-flex align-center justify-space-between full-width">
          <h3 class="subheader">
            User Management
          </h3>

          <div class="align-center d-flex justify-end">
            <template v-if="isMultilocation">
              <custom-button
                v-if="!hideAddNewUser"
                :disabled="processing"
                class="expansion-panel-button"
                size="small"
                variant="text"
                data-test="addNewUserBtn"
                @click.stop="multilocationUserForm = true">
                <v-icon>mdi-plus</v-icon>
                Add New User
              </custom-button>
            </template>

            <template v-else>
              <text-input
                v-if="expanded && !isMobile"
                v-model="search"
                class="search-wrapper"
                append-inner-icon="mdi-magnify"
                dense
                hide-details
                in-place
                placeholder="Name, Email Address"
                @click.stop
                @keydown="handleSearchKeyDown" />

              <add-new-user v-if="!hideAddNewUser" @click.stop />
            </template>
          </div>
        </div>
      </template>
    </v-expansion-panel-title>

    <v-expansion-panel-text
      class="standard-collapsable-panel__content"
      :class="{ multilocation: isMultilocation }">
      <users-table
        :key="multilocationComponentReloadKey"
        v-model:selected-table="selectedTable"
        :multi-location="isMultilocation" />
    </v-expansion-panel-text>

    <user-form
      v-if="multilocationUserForm"
      @close="multilocationUserForm = false"
      @save="onUserSave" />
  </v-expansion-panel>
</template>

<script setup lang="ts">
import {
  computed, provide, reactive, ref,
  watch,
} from 'vue';
import AddNewUser from '@/components/Merchant/Portal/Admin/UserManagement/AddNewUser.vue';
import UserForm from '@/components/Merchant/Portal/Admin/UserManagement/MultiLocation/UserForm.vue';
import UsersTable from '@/components/Merchant/Portal/Admin/UserManagement/UsersTable.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import TextInput from '@/components/Inputs/Text.vue';
import { useStore } from 'vuex';
import { PaginatedResponse, RequestOptions } from '@/api/types';
import { merchantUserManagementKey } from '@/constants/ProvideKeys';
import { useTableRequests } from '@/composables/useTableRequests';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { getUsersAllLocations, getUsersAtThisLocation } from '@/api/merchant';
import useMPAccess from '@/components/Merchant/composables/useMPAccess';
import { UserAtThisLocation } from '@/interfaces/merchantPortal/UserInterface';
import { useDisplay } from 'vuetify';

const multilocationUserForm = ref(false);
const multilocationComponentReloadKey = ref(0);
const onUserSave = () => {
  multilocationComponentReloadKey.value += 1;
  multilocationUserForm.value = false;
};

const { hideAddNewUser } = useMPAccess();

const store = useStore();
const { xs: isMobile } = useDisplay();
const { isFeatureEnabled } = useFeatureFlag();

const isMultilocation = computed(() => {
  return isFeatureEnabled(FeatureFlagsConstants.MULTI_LOCATION)
        && !!store.getters['MerchantPortal/getMerchantLocation'];
});

const pageSize = 20;
const search = ref('');
const selectedTable = ref(0);
const filters = reactive({
  role: undefined as string | undefined,
  status: undefined as string | undefined,
  locations: undefined as string | undefined,
});

// As the search input is inside an expansion panel title, we need to handle the space key
// press to prevent the expansion panel from closing.
const handleSearchKeyDown = (e: KeyboardEvent) => {
  if (e.code === 'Space') {
    e.preventDefault();
    search.value += ' ';
  }
};

/**
 * Depending on which table is selected, we get the users to fill the table from the
 * appropriate endpoint. For `All Users` table we adjust the data to match the table
 * headers better.
 */
const request = (options: RequestOptions) => {
  if (isMultilocation.value) {
    const merchantUuid = store.getters['MerchantPortal/getMerchantUuid'];
    const getFn = selectedTable.value === 0
      ? getUsersAtThisLocation
      : getUsersAllLocations;

    return getFn(merchantUuid, options);
  }
  return store.dispatch(
    'MerchantPortal/dispatchFetchMerchantUsers',
    options,
  ) as PaginatedResponse<UserAtThisLocation[]>;
};

const {
  fetchData: refreshUsers,
  count: userCount,
  page,
  results: users,
  loading,
  fetchDataResetWatchers,
} = useTableRequests(request, { pageSize, search, filters });

const processing = computed(() => store.getters['Ui/getProcessing']);

provide(merchantUserManagementKey, {
  refreshUsers,
  userCount,
  page,
  pageSize,
  users,
  loading,
  search,
  selectedTable,
  tableFilters: filters,
});

watch(selectedTable, () => {
  users.value = [];
  search.value = '';
  filters.locations = undefined;
  filters.role = undefined;
  filters.status = undefined;
  fetchDataResetWatchers();
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins/media_queries';
@import '@/assets/scss/components/buttons';

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0 !important;
}

.standard-collapsable-panel {
  &__title {
    height: 3.25rem;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    :deep(.v-expansion-panel-title__icon) {
      align-items: center;
      margin: 0;
      padding-left: 1.25rem;
    }

    :deep(.v-expansion-panel-title__overlay) {
      opacity: 0;
    }
  }
}

.multilocation {
  border-top: none !important;

  :deep(.v-expansion-panel-text__wrapper) {
    padding: 0 !important;
  }
}

.search-wrapper {
  width: 14rem;

  :deep() {
    .v-field {
      font-size: 0.875rem !important;

      input::placeholder {
        color: var(--grayscale-color-1) !important;
        opacity: 1;
      }
    }

    .v-text-field__slot {
      font-weight: 400 !important;
      letter-spacing: 0 !important;
    }
  }
}

@include vuetify-xs-width-max {
  :deep() {
    .v-expansion-panel-text {
      &__wrapper {
        padding: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  :deep() {
    .v-expansion-panel-text {
      &__wrapper {
        padding: 0 !important;
      }
    }
  }
}
</style>
