import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_standard_wizard_message = _resolveComponent("standard-wizard-message")!

  return (_openBlock(), _createBlock(_component_standard_wizard_message, {
    message: _ctx.errorMessage,
    "hide-wrapper": "",
    "data-test": "fraudMsg"
  }, null, 8, ["message"]))
}