import { PropType, defineComponent } from 'vue';

function contains(value: string | number, term: string): boolean {
  return `${value}`.toLowerCase().includes(term);
}

export default defineComponent({
  props: {
    attach: { type: Boolean, default: true },
    items: { type: Array as PropType<Array<string | number | object>>, required: true },
    itemText: { type: String, default: 'label' },
    itemValue: { type: String, default: 'value' },
    hideDropdownIcon: { type: Boolean, default: false },
    autoselectMatch: { type: Boolean, default: false },
  },

  emits: ['update:search', 'update:model-value'],

  data: () => ({
    defaultDataTestAttr: 'dropdown',
  }),

  computed: {
    appendIcon(): string {
      return this.hideDropdownIcon ? '' : '$dropdown';
    },
  },

  methods: {
    onSearchInputUpdate(value: string) {
      this.$emit('update:search', value);
      if (!value) return;
      if (this.autoselectMatch) this.autoselect(value);
    },

    // The autoselect feature is case-insensitive, so `New York` === `new york`.
    autoselect(searchLabel: string) {
      const term = searchLabel.toLowerCase();
      let filteredItems: Array<any>;
      let itemLabel = '';

      if (['number', 'string'].includes(typeof this.items[0])) {
        filteredItems = this.items.filter((it: any) => contains(it, term));
        if (filteredItems.length !== 1) return;

        itemLabel = `${filteredItems[0]}`;
      } else {
        filteredItems = this.items.filter((it: any) => contains(it[this.itemText], term));
        if (filteredItems.length !== 1) return;

        itemLabel = `${filteredItems[0][this.itemText]}`;
      }
      if (itemLabel.toLowerCase() !== term) return;

      const select = (this.$refs.select as any);
      const item: any = this.items.find((it: any) => {
        if (typeof it === 'object') return it[this.itemText].toLowerCase() === term;
        return `${it}`.toLowerCase() === term;
      });

      this.$emit('update:model-value', item[this.itemValue] ?? item);
      select.$emit('update:search', '');
      select.$el.querySelector('input')?.blur();
    },
  },
});
