export const DISPLAY_STATUS_LIST = {
  SENT: 'Sent',
  IN_PROGRESS: 'In Progress',
  OFFERS: 'Offers',
  NOT_FINALIZED: 'Not Finalized',
  FINALIZED: 'Finalized',
  CAN_TRANSACT: 'Can Transact',
  NO_OFFERS: 'No Offers',
  CHARGES_LIMITED: 'Charges Limited',
  STUCK: 'Stuck',
  CANCELED: 'Canceled',
  OFFERS_CANCELED: 'Offers, Canceled',
} as const;

export type DisplayStatus = typeof DISPLAY_STATUS_LIST[keyof typeof DISPLAY_STATUS_LIST];

export interface HumanFriendlyStatus {
  name: DisplayStatus;
  description: string;
}

export interface RecentApplication {
  consumer_invitation_uuid: string;
  sent_to: string;
  id_number: string | null;
  amount: string | null;
  product_id: string | null;
  product_description: string | null;
  display_status: DisplayStatus;
}

export interface InvitationDetails {
  application_status: DisplayStatus;
  sent_to: string;
  sent_on: string;
  sent_by: string;
  offer_code: string | null;
}

export interface Opportunity {
  consumer_application_uuid: string;
  consumer_invitation_uuid: string;
  sent_to: string;
  id_number: string;
  available_spend: string;
  amount_spent: string;
  purchase_window_end_date: string;
}
