<template>
  <v-form
    class="fs-unmask"
    data-test="MOB-step3-substep6"
    @submit.prevent="goToNextSubStep">
    <v-card-text>
      <merchant-onboarding-wizard-annual-sales-revenue
        v-model="stepData.annualSalesRevenue"
        :max-value="annualSalesRevenueMaxValue" />

      <merchant-onboarding-wizard-average-customer-spend
        v-model="stepData.averageCustomerSpend"
        :max-value="consumerSpendMaxValue" />

      <custom-button
        :disabled="nextSubstepDisabled"
        full-width
        data-test="nextBtn"
        type="submit">
        Next
      </custom-button>
    </v-card-text>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import MerchantOnboardingWizardAnnualSalesRevenue from '@/components/Merchant/OnboardingWizard/AnnualSalesRevenue.vue';
import MerchantOnboardingWizardAverageCustomerSpend from '@/components/Merchant/OnboardingWizard/AverageCustomerSpend.vue';
import get from 'lodash/get';
import {
  ANNUAL_SALES_REVENUE_MAX,
  AVERAGE_CUSTOMER_SPEND_MAX,
} from '@/constants/MerchantOnboarding';

export default defineComponent({
  name: 'SubstepSix',

  components: {
    CustomButton,
    MerchantOnboardingWizardAnnualSalesRevenue,
    MerchantOnboardingWizardAverageCustomerSpend,
  },

  props: {
    onNextSubStep: { type: Function, default: () => () => null },
  },

  data: () => ({
    ownSubStep: 6,
    continueButtonDisabled: false,
    stepData: {
      annualSalesRevenue: '',
      averageCustomerSpend: '',
    },
    consumerSpendMaxValue: AVERAGE_CUSTOMER_SPEND_MAX,
    annualSalesRevenueMaxValue: ANNUAL_SALES_REVENUE_MAX,
  }),

  computed: {
    reEntryData(): any {
      return this.$store.getters['MerchantOnboarding/getMerchantReEntryData'];
    },

    reEntryDataAllAttributes(): any {
      return get(this.reEntryData, 'all_attributes', {});
    },

    nextSubstepDisabled(): boolean {
      return this.stepData.annualSalesRevenue === ''
        || this.stepData.averageCustomerSpend === ''
        || this.continueButtonDisabled
        || Number(this.stepData.annualSalesRevenue) > this.annualSalesRevenueMaxValue
        || Number(this.stepData.averageCustomerSpend) > this.consumerSpendMaxValue;
    },
  },

  mounted() {
    const { annualSalesRevenue, averageCustomerSpend } = this.$store.getters[
      'MerchantOnboarding/getAllAttributes'
    ];

    const reEntryAnnualSalesRevenue = this.reEntryDataAllAttributes.annualSalesRevenue;
    const reEntryAverageCustomerSpend = this.reEntryDataAllAttributes.averageCustomerSpend;

    this.stepData.annualSalesRevenue = annualSalesRevenue || reEntryAnnualSalesRevenue || '';
    this.stepData.averageCustomerSpend = averageCustomerSpend || reEntryAverageCustomerSpend || '';
  },

  methods: {
    async goToNextSubStep() {
      this.continueButtonDisabled = true;

      const responseStatus = await this.$store.dispatch(
        'MerchantOnboarding/dispatchAllAttributes',
        this.stepData,
      );

      await this.$store.dispatch(
        'MerchantOnboarding/dispatchPatchApplication',
        'Post ASR ACS / Pre Payments Accepted YFV',
      );

      if (responseStatus === 201) {
        this.onNextSubStep(this.ownSubStep + 1);
      }

      this.continueButtonDisabled = false;
    },
  },
});
</script>
