<template>
  <div>
    <name-form-dialog
      v-if="showNameDialog"
      @close="$emit('close')"
      @save="saveName" />

    <products-form-dialog
      v-if="showCreateDialog"
      :name="name"
      :rate-sheets="availableRateSheets"
      @close="$emit('close')"
      @save="save" />
    <alert-modal
      type="error"
      :dialog="showErrorAlert"
      :on-close="hideErrorAlert">
      {{ errorAlertMessage }}
    </alert-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import cloneDeep from 'lodash/cloneDeep';
import { createOfferCode } from '@/api/ratesheets';
import AlertModal from '@/components/Dialogs/AlertModal.vue';
import { getAxiosError } from '@/api/utils';
import AlertMixin from './AlertMixin';
import ProductsFormDialog from './Forms/ProductsDialog.vue';
import NameFormDialog from './Forms/NameDialog.vue';

export default defineComponent({
  name: 'CreateOfferCode',
  components: {
    AlertModal,
    NameFormDialog,
    ProductsFormDialog,
  },
  mixins: [AlertMixin],
  data() {
    return {
      showNameDialog: true,
      showCreateDialog: false,
      name: '',
      availableRateSheets: [] as Array<RateSheetData>,
      showErrorAlert: false,
      errorAlertMessage: '',
    };
  },
  mounted() {
    const rateSheets = this.$store.getters['MerchantPortal/getRateSheets'];
    this.availableRateSheets = cloneDeep(rateSheets).map((it: RateSheetData) => {
      it.is_selected = false;
      return it;
    });
  },
  methods: {
    saveName(name: string) {
      this.name = name;
      this.showNameDialog = false;
      this.showCreateDialog = true;
    },
    async save() {
      const rate_sheet_merchant_product_prices: Array<number> = [];
      this.availableRateSheets.forEach((it: RateSheetData) => {
        if (it.is_selected) {
          rate_sheet_merchant_product_prices.push(it.rate_sheet_merchant_product_price_id);
        }
      });
      const merchantUuid = this.$store.getters['Auth/getMerchantUuid'];
      const payload = {
        name: this.name,
        is_active: true,
        rate_sheet_merchant_product_prices,
      };

      try {
        await createOfferCode(merchantUuid, payload);
        this.$emit('created');
        this.addGlobalAlert(true, 'create', this.name);
        this.$emit('close');
      } catch (e: unknown) {
        const axiosError = getAxiosError(e);
        if (axiosError) {
          this.errorAlertMessage = axiosError.response?.data?.detail
            || 'We’re sorry. An error occurred while trying to perform this function';
          this.showErrorAlert = true;
        }
      }
    },
    hideErrorAlert() {
      this.showErrorAlert = false;
    },
  },
});
</script>
