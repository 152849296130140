import { DefaultThemeVariablesInterface } from '@/interfaces/whitelabelThemes/DefaultThemeVariablesInterface';

const primaryColor = '#0B9AEB';
const secondaryColor = '#183057';
const grayscaleColor1 = '#4C5560';
const grayscaleColor2 = '#C4CBD2';
const grayscaleColor3 = '#DCE0E7';
const grayscaleColor4 = '#EDF1F5';
const grayscaleColor5 = '#F7F9FB';
const alertColor = '#A64040';
const rateSheetColor1 = '#1CE4F9';
const rateSheetColor2 = '#047FB5';
const rateSheetColor3 = '#0C2F58';
const warningColor = '#FB8C00';
const successColor = '#4CAF50';
const errorColor = '#A64040';
const darkColor = '#333333';

const DEFAULT_THEME_VARIABLE: DefaultThemeVariablesInterface = {
  primaryColor,
  secondaryColor,
  grayscaleColor1,
  grayscaleColor2,
  grayscaleColor3,
  grayscaleColor4,
  grayscaleColor5,
  alertColor,
  rateSheetColor1,
  rateSheetColor2,
  rateSheetColor3,
  warningColor,
  successColor,
  errorColor,
  darkColor,
};

export default DEFAULT_THEME_VARIABLE;
