import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import CreditEngineSoftPullResultsDataMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineSoftPullResultsDataMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CreditEngineSoftPullApprovedAmountMixin',
  mixins: [CreditEngineSoftPullResultsDataMixin],
  computed: {
    approvedAmountSoftPullLabel(): string {
      return CurrencyFormatLong(this.approvedAmountSoftPull);
    },
    approvedAmountSoftPull(): number {
      return this.creditEngineSoftPullResults.credit_line
        || this.$store.getters['Consumer/getLoanApplyWizardData'].credit_line
        || 0;
    },
  },
});
