<template>
  <div class="current-status">
    <h4 class="mb-5 mt-0" data-test="thankYouHeader">
      Thank You!
    </h4>

    <div>
      <div :class="currentAppStatus === 'Approved' ? 'approved-text' : 'pending-text'">
        <p data-test="forSubmittingLabel">
          ...for submitting your application.
        </p>

        <p data-test="oneRepresentativeLabel">
          One of our Representatives will review your
          application and contact you in the next few business days.
        </p>

        <p :class="currentAppStatus === 'Pending' ? 'mb-0' : ''" data-test="pleaseCheckLabel">
          <span v-if="ownerEmail">Please check for emails sent to {{ ownerEmail }}.</span>
          We will email you regarding your application status.
        </p>
      </div>

      <div v-if="currentAppStatus === 'Approved'">
        <p class="mb-6" data-test="whileInFinalLabel">
          While in final review, you may want take a tour of the merchant portal.
          Some functionality will not be available until you are fully approved.
        </p>

        <custom-button full-width :href="backToPortalLink">
          Take me to Merchant Portal
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ThankYouMessage',

  components: {
    CustomButton,
  },

  computed: {
    currentAppStatus(): string {
      return this.$store.getters['MerchantOnboarding/getApplicationStatus'];
    },
    ownerEmail(): string {
      const allOwners = this.$store.getters['MerchantOnboarding/getCombinedOwners'];
      if (!allOwners.length) return '';
      return allOwners[0].email;
    },
    backToPortalLink(): string {
      const brandingPortalLoginUrl = this.$store.getters['Ui/getBrandingObject']?.portal_login_url;
      return brandingPortalLoginUrl || this.$router.resolve({ name: 'merchant-login' }).href;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuetify/elevation.scss";

.current-status {
  margin-top: 3.5rem;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  text-align: left;
  border: 1px solid var(--grayscale-color-3);
  background-color: #FFFFFF;
  box-shadow: $v-elevation-4;

  div, p {
    font-size: 1rem;
  }

  .approved-text {
    border-bottom: 1px solid var(--grayscale-color-3);
    margin-bottom: 1.5rem;

    p {
      margin-bottom: 1.5rem;
    }
  }

  .pending-text p {
    margin-bottom: 1.5rem;
  }
}
</style>
