import { UiStateInterface } from '@/interfaces/states/UiStateInterface';

export function getProcessing(state: UiStateInterface) {
  return state.processing > 0;
}

export function getErrors(state: UiStateInterface) {
  return state.errors;
}

export function getSystemNotifications(state: UiStateInterface) {
  return state.systemNotifications;
}

export function getFooterData(state: UiStateInterface) {
  return state.brandingData.footer_object;
}

export function getShowIdleTimeoutModal(state: UiStateInterface) {
  return state.showIdleTimeoutModal;
}

export function getBrandingTheme(state: UiStateInterface) {
  return state.brandingData.theme_css;
}

export function getBrandingObject(state: UiStateInterface) {
  return state.brandingData;
}

export function getBrandingTermsOfUseURL(state: UiStateInterface) {
  return state.brandingData.terms_of_use_url;
}

export function getBrandingPrivacyPolicyURL(state: UiStateInterface) {
  return state.brandingData.privacy_policy_url;
}

export function getBrandingHeaderLogo(state: UiStateInterface) {
  return state.brandingData.header_logo_url;
}

export function getBrandingTcpaURL(state: UiStateInterface) {
  return state.brandingData.tcpa_url;
}

export function getBrandingESignDisclosureUrl(state: UiStateInterface) {
  return state.brandingData.esign_disclosure_url;
}

export function getErrorResponse(state: UiStateInterface) {
  return state.userFriendlyErrorResponse;
}

export function getGlobalAlerts(state: UiStateInterface) {
  return state.globalAlerts;
}

export function getPortalLoginUrl(state: UiStateInterface): string {
  return state.brandingData?.portal_login_url;
}
