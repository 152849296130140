<template>
  <standard-dialog
    :dialog="dialog"
    :on-cancel="closeModal"
    :show-actions="false"
    class="fs-unmask">
    <template #title>
      <div class="mb-4">
        Staged Funding
      </div>
    </template>
    <template #body>
      <div class="mb-8">
        <p class="mb-6">
          A percentage of the credit limit has been reserved for the Final Charge.
        </p>

        <p>
          You can request the remaining available balance by clicking Send Final Charge.
        </p>
      </div>

      <div class="d-flex justify-space-between">
        <custom-button
          class="px-10"
          size="small"
          variant="secondary"
          @click="closeModal">
          Cancel
        </custom-button>

        <custom-button size="small" @click="$emit('sendFinalCharge')">
          Send Final Charge
        </custom-button>
      </div>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LearnMoreDialog',
  components: {
    StandardDialog,
    CustomButton,
  },
  props: {
    dialog: { type: Boolean, defautl: false },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
</style>
