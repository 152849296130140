<template>
  <td class="v-data-table__td">
    <div class="v-data-table__td-title">
      <span>{{ header }}</span>
    </div>
    <div class="v-data-table__td-value">
      <span v-if="privateValue" v-private>{{ value }}</span>
      <span v-else>{{ value }}</span>
    </div>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TableMobileCell',

  props: {
    header: { type: String, default: '' },
    value: { type: String, required: true },
    privateValue: { type: Boolean, default: false },
  },
});
</script>
