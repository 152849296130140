<template>
  <div class="offer-codes" data-test="offerCodes">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-title>
          <h3 class="subheader">
            Offer Codes
          </h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <custom-button
            variant="secondary"
            class="offer-codes--create-btn"
            data-test="createOfferCodeBtn"
            @click="showCreateDialog = true">
            Create New Offer Code
          </custom-button>

          <offer-code-table
            v-for="offerCode in offerCodes"
            :key="offerCode.id"
            v-bind="offerCode"
            class="offer-codes--table"
            @updated="fetchOfferCodes"
            @deleted="removeOfferCode" />
          <div
            v-if="!offerCodes.length"
            class="offer-codes-text text-center">
            <span class="font-italic" data-test="noOfferCodeLabel">You have no offer codes.</span>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <create-offer-code
      v-if="showCreateDialog"
      @created="fetchOfferCodes"
      @close="showCreateDialog = false" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { getOfferCodes } from '@/api/ratesheets';
import CreateOfferCode from './CreateOfferCode.vue';
import OfferCodeTable from './OfferCodeTable/index.vue';

export default defineComponent({
  name: 'OfferCodes',
  components: {
    CustomButton,
    CreateOfferCode,
    OfferCodeTable,
  },
  data() {
    return {
      showCreateDialog: false,
      offerCodes: [] as Array<any>,
    };
  },
  async mounted() {
    await this.fetchOfferCodes();
  },
  methods: {
    async fetchOfferCodes() {
      const merchantUuid = this.$store.getters['Auth/getMerchantUuid'];
      const { data } = await getOfferCodes(merchantUuid);
      this.offerCodes = data;
    },
    removeOfferCode(id: number) {
      this.offerCodes = this.offerCodes.filter((it: any) => it.id !== id);
    },
  },
});
</script>

<style lang="scss" scoped>
.offer-codes {
  width: 100%;

  &--create-btn {
    margin: 1.5rem 0;
  }

  :deep(.v-expansion-panel-text__wrapper) {
    text-align: right;

    .offer-code-table {
      text-align: left;
    }
  }

  &--table {
    margin-bottom: 2rem;
  }

  .v-expansion-panel-title {
    height: 3rem;
    min-height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--grayscale-color-1);

    &--active {
      background-color: var(--grayscale-color-5);
    }
  }

  .offer-codes-text {
    color: var(--grayscale-color-1);
  }
}
</style>
