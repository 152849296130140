import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_url_input = _resolveComponent("url-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    class: "fs-unmask",
    "data-test": "MOB-step3-substep5",
    onSubmit: _withModifiers(_ctx.goToNextSubStep, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_url_input, {
            modelValue: _ctx.stepData.websiteUrl,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stepData.websiteUrl) = $event)),
            autocomplete: "url",
            "data-test": "websiteInput",
            label: "Website URL - Optional"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_date_input, {
            ref: "datePicker",
            modelValue: _ctx.stepData.businessStartDate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.stepData.businessStartDate) = $event)),
            max: _ctx.maxDate,
            range: false,
            "is-fs-excluded": false,
            required: "",
            label: "Date Business Started"
          }, null, 8, ["modelValue", "max"]),
          _createVNode(_component_custom_button, {
            disabled: _ctx.nextSubstepDisabled,
            "data-test": "nextBtn",
            "full-width": "",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Next ")
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}