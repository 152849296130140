<template>
  <div class="standard-wizard__step standard-wizard__step--three">
    <div v-if="applicationInProgress" :class="[ownStep === 5 ? 'apply-width' : '']">
      <div class="fs-unmask standard-wizard__step standard-wizard__step--three" data-test="MOB-step3-substep8">
        <proccessing-application-modal :dialog="showProcessingApplication" />

        <application-waiting-approval-modal :dialog="showAppWaiting" />

        <v-card class="mt-4 verify-info">
          <v-card-title class="verify-info-title">
            <h3 class="my-0" data-test="verifyInformationHeader">
              Verify Information and Submit
            </h3>
          </v-card-title>

          <div class="pa-2">
            <inline-edit-forms-verify-data />

            <inline-edit-forms-owners-list
              from-verify-details
              otp-simple-design
              :enable-ownership-text="enableOwnershipText" />

            <inline-edit-forms-business-details />
          </div>
        </v-card>

        <div class="v-elevation-4 pa-4 standard-consent-and-input-notation-text submit-app text-left">
          <p data-test="clickingSubmitLabel">
            By clicking submit on your application,
            you are certifying that you have read and agree to
            the Disclosures and Program Agreement.
          </p>

          <p data-test="weMayCheckLabel">
            We may check and verify credit history and secure credit reports of the
            Applicant and any named principals, owners, or partners.
            The named principals, owners,
            and partners instruct any third party including but not limited to any consumer
            reporting agency, partner, and vendor to provide such report.
          </p>

          <checkbox-input
            id="accept"
            v-model="consent"
            class="consent-checkbox standard-input-field standard-input-field--checkbox"
            data-test="acceptCheckbox"
            name="accept">
            <template #label>
              <div class="standard-consent-and-input-notation-text">
                <p data-test="iCertifyLabel">
                  I certify that the information submitted is true, accurate, and complete.
                  By submitting this Application, I certify that I have read and agree to the
                  <a
                    data-test="merchantBtn"
                    :href="merchantAgrUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    @click.stop>
                    Merchant Agreement
                  </a>,

                  <a
                    data-test="esignBtn"
                    :href="eSignUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    @click.stop>
                    E-Sign Disclosure and Consent
                  </a> and

                  <a
                    data-test="operatingBtn"
                    :href="operatingInstUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    @click.stop>
                    Operating Instructions
                  </a>
                  .
                </p>
              </div>
            </template>
          </checkbox-input>

          <custom-button
            :disabled="nextStepDisabled"
            class="mt-4"
            full-width
            label="Submit My Application"
            @click="dispatchSubmitApplication" />
        </div>
      </div>
    </div>

    <div data-test="MOB-step3" class="merchant-statuses">
      <thank-you-message v-if="currentAppStatus === 'Approved' || currentAppStatus === 'Pending'" />
      <div
        v-else-if="currentAppStatus === 'Approved-External-Hold-False'"
        class="elevation-4 mx-4 my-4 received-application">
        <h5 class="received-application__title" data-test="weReceivedApplicationLabel">
          We’ve Received Your Application
        </h5>

        <p class="paragraphs" data-test="applicationPendingLabel">
          Your application is pending final review. Please proceed to
          the next steps to get a head start on your Payment and Rate Sheet settings.
        </p>

        <p class="paragraphs" data-test="theseStepsLabel">
          These steps must be taken in order to offer financing.
        </p>

        <div class="actions">
          <custom-button
            full-width
            class="mt-4"
            @click="nextStep">
            Continue To Next Step
          </custom-button>
        </div>
      </div>

      <div
        v-else-if="currentAppStatus === 'Approved-External-Hold-True'"
        class="elevation-4 mt-8 mx-1 received-application">
        <h5 class="received-application__title">
          You’ve Been Approved!
        </h5>

        <p class="paragraphs">
          Please proceed to Payment Settings.
        </p>

        <p class="paragraphs">
          These next few steps must be taken in order to offer financing.
        </p>

        <div class="actions">
          <custom-button
            full-width
            class="mt-4"
            @click="nextStep">
            Continue To Next Step
          </custom-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CheckboxInput from '@/components/Inputs/Checkbox.vue';
import ApplicationWaitingApprovalModal
  from '@/components/Merchant/OnboardingWizard/Modals/ApplicationWaitingApprovalModal.vue';
import ProccessingApplicationModal
  from '@/components/Merchant/OnboardingWizard/Modals/ProccessingApplicationModal.vue';
import InlineEditFormsBusinessDetails
  from '@/components/Merchant/OnboardingWizard/StepThree/InlineEditForms/BusinessDetails.vue';
import InlineEditFormsOwnersList
  from '@/components/Merchant/OnboardingWizard/StepThree/InlineEditForms/OwnersList.vue';
import InlineEditFormsVerifyData
  from '@/components/Merchant/OnboardingWizard/StepThree/InlineEditForms/VerifyData.vue';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import { OwnerDataInterface } from '@/interfaces/merchantOnboardingWizard/OwnerDataInterface';
import GetProcessing from '@/mixins/GetProcessing';
import ValidationTypesMixin from '@/mixins/ValidationTypesMixin';
import { postConsentTypes, patchAvailableConsents } from '@/utils/Consents';
import { get } from 'lodash';
import { defineComponent } from 'vue';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import GetSponsorMixin from '@/mixins/GetSponsor';
import { MerchantReEntryDataInterface } from '@/interfaces/merchant/MerchantReEntryDataInterface';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { PageTypesShorthand } from '@/enums/PageTypes';
import ThankYouMessage from '@/components/Merchant/OnboardingWizard/ThankYouMessage.vue';

export default defineComponent({
  name: 'SubstepEight',
  components: {
    CheckboxInput,
    ApplicationWaitingApprovalModal,
    ProccessingApplicationModal,
    InlineEditFormsOwnersList,
    InlineEditFormsBusinessDetails,
    InlineEditFormsVerifyData,
    CustomButton,
    ThankYouMessage,
  },
  mixins: [
    GetProcessing,
    ValidationTypesMixin,
    NavigatesStepsMixin,
    GetSponsorMixin,
    HoneypotTrackMixin,
  ],
  props: {
    onNextStep: { type: Function, default: () => null },
  },
  data() {
    return {
      ownersData: [] as Array<OwnerDataInterface>,
      merchantAgrUrl: '',
      eSignUrl: '',
      operatingInstUrl: '',
      consent: false,
      showProcessingApplication: false,
      showAppWaiting: false,
      enableOwnershipText: false,
      hpEventName: 'Merchant Submit Application Page Visit',
    };
  },
  computed: {
    reEntryData(): MerchantReEntryDataInterface {
      return this.$store.getters['MerchantOnboarding/getMerchantReEntryData'];
    },
    processingApplication(): boolean {
      return this.$store.getters['MerchantOnboarding/getProcessingApplication'];
    },
    applicationApproved(): boolean {
      return this.$store.getters['MerchantOnboarding/getApplicationApproved'];
    },
    applicationWaitingApproval(): boolean {
      return this.$store.getters['MerchantOnboarding/getApplicationWaitingApproval'];
    },
    applicationInProgress(): boolean {
      return this.$store.getters['MerchantOnboarding/isApplicationInProgress'];
    },
    merchantBrand(): any {
      return this.$store.getters['Ui/getBrandingObject'];
    },
    currentAppStatus(): string {
      return this.$store.getters['MerchantOnboarding/getApplicationStatus'];
    },
    allOwnersData(): Array<OwnerDataInterface> {
      return this.$store.getters['MerchantOnboarding/getCombinedOwners'];
    },
    verifyFormOpen(): boolean {
      const {
        userFormOpen, openOwnerForms, businessFormOpen,
      } = this.$store.getters['MerchantOnboarding/getVerifyFormsOpen'];
      return userFormOpen || !!openOwnerForms || businessFormOpen;
    },
    nextStepDisabled(): boolean {
      return this.processing
        || !this.consent
        || this.processingApplication
        || this.applicationWaitingApproval
        || this.verifyFormOpen;
    },
    ownStep(): number {
      return this.isTypeIC1WithoutMPAccess ? 4 : 5;
    },
    phoneNumber(): string {
      return this.getUserVerifyData?.userMobile;
    },
  },
  watch: {
    consent(val: boolean) {
      this.$store.commit('MerchantOnboarding/setConsent', val);
    },
    processingApplication(processing: boolean) {
      if (processing) {
        this.showProccessingApplicationModal();
      } else {
        this.closeProccessingApplicationModal();
      }
    },
    applicationWaitingApproval(waiting: boolean) {
      if (waiting) {
        this.showApplicationWaitingApproval();
      } else {
        this.closeApplicationWaitingApproval();
      }
    },
    merchantBrand: {
      handler(newVal: any, oldVal: any) {
        this.merchantAgrUrl = newVal.agreement_url;
        this.eSignUrl = newVal.esign_disclosure_url;
        this.operatingInstUrl = newVal.operating_instructions_url;
        if (!newVal) {
          this.merchantAgrUrl = oldVal.agreement_url;
          this.eSignUrl = oldVal.esign_disclosure_url;
          this.operatingInstUrl = oldVal.operating_instructions_url;
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.presentConsent();
    this.ownersData.push(...this.$store.getters['MerchantOnboarding/getCombinedOwners']);
  },
  methods: {
    saveOwner(owner: OwnerDataInterface, index: number): void {
      this.ownersData[index] = owner;
      this.$store.commit('MerchantOnboarding/setCombinedOwners', this.ownersData);
    },
    nextStep(): void {
      this.goToStep(this.ownStep + 1);
    },
    showProccessingApplicationModal(): void {
      this.showProcessingApplication = true;
    },
    closeProccessingApplicationModal(): void {
      this.showProcessingApplication = false;
    },
    showApplicationWaitingApproval(): void {
      this.showAppWaiting = true;
    },
    closeApplicationWaitingApproval(): void {
      this.showAppWaiting = false;
    },
    async presentConsent(): Promise<void> {
      const consentTypes: Array<number> = [
        ConsentTypesEnum.OPERATING_INSTRUCTIONS,
        ConsentTypesEnum.MERCHANT_AGREEMENT,
        ConsentTypesEnum.E_SIGN_DISCLOSURE_AND_CONSENT,
      ];

      const merchant_application_uuid = get(
        this.$store.getters['MerchantOnboarding/getUserVerifyData'],
        'merchantApplicationId',
        '',
      );

      await postConsentTypes({
        consentTypes,
        merchant_application_uuid,
        entity: ConsentEntityTypes.MERCHANT,
      });
    },
    validateMerchantData(): Promise<number> {
      return this.$store.dispatch('MerchantOnboarding/dispatchValidateMerchantData');
    },
    async updateConsents(): Promise<number> {
      const consentTypes: Array<number> = [
        ConsentTypesEnum.OPERATING_INSTRUCTIONS,
        ConsentTypesEnum.MERCHANT_AGREEMENT,
        ConsentTypesEnum.E_SIGN_DISCLOSURE_AND_CONSENT,
      ];
      const appId = get(
        this.$store.getters['MerchantOnboarding/getUserVerifyData'],
        'merchantApplicationId',
        '',
      );
      return patchAvailableConsents(consentTypes, appId, ConsentEntityTypes.MERCHANT);
    },
    async dispatchSubmitApplication() {
      const validateResponse: number = await this.validateMerchantData();
      const appId = get(
        this.$store.getters['MerchantOnboarding/getUserVerifyData'],
        'merchantApplicationId',
        '',
      );
      const gucaPayload = {
        merchant_application: appId,
        phone_number: this.phoneNumber,
        email: this.allOwnersData[0].email,
        address: this.reEntryData?.merchant.address_set,
        ssn: this.allOwnersData[0].ssn,
        check_against_consumer: true,
        check_against_merchant: true,
      };

      // Get Socure SessionID token
      const socureSessionIDToken = sessionStorage.getItem('socureDeviceSessionToken');

      if (validateResponse === 200) {
        const responseStatus = await this.updateConsents();
        if (responseStatus === 201) {
          await this.$store.dispatch('MerchantOnboarding/dispatchSubmitApplication', socureSessionIDToken);
          await this.$store.dispatch('Auth/gucaCheckApi', gucaPayload);
          await this.$store.dispatch(
            'MerchantOnboarding/dispatchPatchApplication',
            'Post Submit App / Pre-Payment Provider',
          );

          this.trackEvent(this.hpEventName, PageTypesShorthand.MOB);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-input-field";
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/inline-inputs";
@import '@/assets/scss/variables/_custom-variables';
@import "@/assets/scss/vuetify/elevation.scss";

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.consent-checkbox {
  margin-bottom: 0;

  :deep(.v-label) {
    padding: 0 !important;

    .standard-consent-and-input-notation-text {
      margin-top: 0;
    }
  }
}

:deep() {
  h5,
  .card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
  }
}

:deep(h5) {
  margin-bottom: 0;
}

:deep(.inline-inputs) {
  .card-text__info-group,
  .address-input-container {
    .v-input--disabled {
      .v-field__field .v-field__input {
        opacity: 1;
        font-weight: 700;
      }

      &.v-input--dirty .v-label--disabled,
      .v-label,
      input {
        color: var(--grayscale-color-1);
      }

      .v-selection-control--disabled {
        opacity: 1;
      }

      .v-field__append-inner {
        display: none;
      }
    }
  }

  .standard-input-field__input-group {
    .form-control {
      color: var(--grayscale-color-1);
    }
  }

  .v-input--dirty {
    .v-selection-control__input {
      width: 20px;
      height: 20px;

      input {
        opacity: 0;
      }
    }
  }
}

.standard-wizard {
  &__step {
    @include standard-wizard-form-inline-split;
    background-color: var(--grayscale-color-4);
    margin-top: 0 !important;
    border-radius: 8px;

    .submit-app {
      border-radius: 8px;
      border: 1px solid var(--grayscale-color-2);
      background-color: #fff !important;

      :deep(.v-selection-control__wrapper) {
        margin-right: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .merchant-statuses {
      border-radius: 8px;
    }

    &__substep-combined {
      .standard-wizard__step__card {
        :deep(.owners-list) {
          background-color: transparent;

          .owners-list__item {
            background: #FFFFFF;
          }
        }

        :deep(.business-name-and-adddress),
        :deep(.business-details) {
          background: #FFFFFF;

          :deep(.card) {
            background: transparent !important;
          }
        }
      }
    }

    &__card {
      background: transparent;
    }
  }
}

.received-application {
  border: 1px solid var(--grayscale-color-3);
  border-radius: 8px;
  background-color: #FFFFFF;
  text-align: left;

  &__title {
    padding: 1rem;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif !important;
    font-weight: 900;
    font-size: 1.125rem !important;
    color: var(--secondary-color);
    background-color: var(--grayscale-color-5);
    margin-top: 0;
  }

  .paragraphs {
    padding: 1rem;
    margin: 0;
    font-size: 1rem;
  }

  .actions {
    padding: 0 1rem 1rem 1rem;
  }
}

.verify-info {
  border-radius: 8px;
  border: 1px solid var(--grayscale-color-2);
  background-color: #fff !important;

  .verify-info-title {
    padding: 1rem;
    background-color: var(--grayscale-color-5);

    h3 {
      font-size: 1.125rem;
    }
  }
}
</style>
