import { ConfigStateInterface } from '@/interfaces/states/ConfigStateInterface';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const initialState = (): ConfigStateInterface => ({
  env: {
    DATADOG_APP_ID: '',
    DATADOG_CLIENT_TOKEN: '',
    DATADOG_SITE: '',
    DATADOG_SERVICE: '',
    DATADOG_ENV: '',
    FULLSTORY_ORG_ID: '',
    HONEYPOT_URL: '',
  },
  featureFlags: [],
});

export default {
  namespaced: true as true,
  state: initialState(),
  actions,
  getters,
  mutations,
};
