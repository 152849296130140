const VALID_EIN_PREFIXES = [
  '10', '12', // Andover
  '60', '67', // Atlanta
  '50', '53', // Austin
  '01', '02', '03', '04', '05', '06', '11', '13', '14', '16', '21', '22', // Brookhaven
  '23', '25', '34', '51', '52', '54', '55', '56', '57', '58', '59', '65', // Brookhaven
  '30', '32', '35', '36', '37', '38', '61', // Cincinnati
  '15', '24', // Fresno
  '40', '44', // Kansas City
  '94', '95', // Memphis
  '80', '90', // Ogden
  '33', '39', '41', '42', '43', '46', '48', '62', '63', '64', '66', '68', '71', '72', // Philadelphia
  '73', '74', '75', '76', '77', '85', '86', '87', '88', '91', '92', '93', '98', '99', // Philadelphia
  '20', '26', '27', '45', '46', '47', '81', '82', '83', '84', '85', '86', '87', '88', '92', '93', '99', // Internet
  '31', // Small Business Administration (SBA)
];

const INVALID_SUFFIXES = [
  '0000000',
];

export default (value: string): boolean => {
  const re = new RegExp('^[0-9]{2}-[0-9]{7}$');
  if (!re.test(value)) return false;

  const [prefix, suffix] = value.split('-');
  if (!VALID_EIN_PREFIXES.includes(prefix)) return false;
  if (INVALID_SUFFIXES.includes(suffix)) return false;

  return true;
};
