<template>
  <v-form
    class="fs-unmask"
    data-test="MOB-step3-substep5"
    @submit.prevent="goToNextSubStep">
    <v-card-text>
      <url-input
        v-model="stepData.websiteUrl"
        autocomplete="url"
        data-test="websiteInput"
        label="Website URL - Optional" />

      <date-input
        ref="datePicker"
        v-model="stepData.businessStartDate"
        :max="maxDate"
        :range="false"
        :is-fs-excluded="false"
        required
        label="Date Business Started" />

      <custom-button
        :disabled="nextSubstepDisabled"
        data-test="nextBtn"
        full-width
        type="submit">
        Next
      </custom-button>
    </v-card-text>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DateInput from '@/components/Inputs/Date.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import isDate from '@/validators/date';
import isUrl from '@/validators/url';
import { get } from 'lodash';
import moment from 'moment';
import UrlInput from '@/components/Inputs/Url.vue';
import DATE_CONSTANTS from '@/constants/DateConstants';

export default defineComponent({
  name: 'SubstepFive',

  components: {
    CustomButton,
    DateInput,
    UrlInput,
  },

  props: {
    onNextSubStep: { type: Function, default: () => () => null },
  },

  data: () => ({
    ownSubStep: 5,
    continueButtonDisabled: false,
    stepData: {
      websiteUrl: '',
      businessStartDate: '',
    },
  }),

  computed: {
    maxDate(): string {
      return moment().format(DATE_CONSTANTS.dateFormat);
    },

    reEntryData(): any {
      return this.$store.getters[
        'MerchantOnboarding/getMerchantReEntryData'
      ];
    },

    reEntryDataAllAttributes(): any {
      return get(this.reEntryData, 'all_attributes', {});
    },

    nextSubstepDisabled(): boolean {
      const { businessStartDate, websiteUrl } = this.stepData;
      if (websiteUrl && !isUrl(websiteUrl)) return true;

      return !businessStartDate
      || !isDate(businessStartDate).isValid
      || this.continueButtonDisabled;
    },
  },

  mounted() {
    const { websiteUrl, businessStartDate } = this.$store.getters[
      'MerchantOnboarding/getAllAttributes'
    ];

    const reEntryWebsiteUrl = this.reEntryDataAllAttributes.websiteUrl;
    const reEntryBusinessStartDate = this.reEntryDataAllAttributes.businessStartDate;
    const startDate = businessStartDate || reEntryBusinessStartDate;

    this.stepData.websiteUrl = websiteUrl || reEntryWebsiteUrl || '';
    this.stepData.businessStartDate = startDate || '';

    if (startDate) {
      (this.$refs.datePicker as any).setInputValue(startDate);
    }
  },

  methods: {
    async goToNextSubStep() {
      this.continueButtonDisabled = true;

      const responseStatus = await this.$store.dispatch(
        'MerchantOnboarding/dispatchAllAttributes',
        this.stepData,
      );

      await this.$store.dispatch(
        'MerchantOnboarding/dispatchPatchApplication',
        'Post DBS / Pre ASR ACS',
      );

      if (responseStatus === 201) {
        this.onNextSubStep(this.ownSubStep + 1);
      }

      this.continueButtonDisabled = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";

</style>
