<template>
  <div class="merchant__onboarding portal">
    <merchant-onboarding-wizard-header class="portal__header" @reEntrySent="successMessage" />

    <div class="portal-wrapper">
      <v-container class="pa-2 portal__container">
        <v-row justify="center" no-gutters>
          <v-col
            class="main-column"
            :lg="wizardStep === lastWizardStep ? 12 : 5"
            :sm="wizardStep === lastWizardStep ? 12 : 6">
            <merchant-onboarding-wizard
              class="mx-auto"
              :class="[wizardStep < lastWizardStep ? 'wizard-step-max' : '']"
              :onboarding-code="onboardingCode"
              :show-success-box="showSuccessMessage" />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <main-footer
      class="mt-0 portal__footer"
      human-readable-id-prefix="Your Merchant ID:"
      :human-readable-id="merchantHumanReadableIdLabel" />

    <alert-modal
      :dialog="showBackButtonPreventModal"
      type="warning"
      max-width="420"
      :on-close="hideBackButtonPreventModal">
      <p>That function is disabled to prevent errors.</p>
      <p>Don't worry, you can review and edit your information before submitting.</p>
    </alert-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainFooter from '@/components/MainFooter.vue';
import MerchantOnboardingWizard from '@/components/Merchant/OnboardingWizard/index.vue';
import MerchantOnboardingWizardHeader from '@/components/Merchant/OnboardingWizard/Header.vue';
import GetHumanReadableIdLabelMixin from '@/mixins/GetHumanReadableIdLabelMixin';
import AlertModal from '@/components/Dialogs/AlertModal.vue';
import GetSponsorMixin from '@/mixins/GetSponsor';

export default defineComponent({
  name: 'MerchantOnboarding',

  components: {
    AlertModal,
    MainFooter,
    MerchantOnboardingWizardHeader,
    MerchantOnboardingWizard,
  },

  mixins: [GetHumanReadableIdLabelMixin, GetSponsorMixin],

  data: () => ({
    onboardingCode: '',
    showSuccessMessage: false,
  }),

  computed: {
    wizardStep() {
      return this.$store.getters['MerchantOnboarding/getOnboardingWizardStep'];
    },

    lastWizardStep() {
      return this.isTypeIC1WithMPAccess ? 7 : 6;
    },

    merchantHumanReadableIdLabel(): string {
      return this.getHumanReadableIdLabel(
        this.$store.getters['MerchantOnboarding/getMerchantHumanReadableId'],
        false,
      );
    },

    showBackButtonPreventModal(): boolean {
      return this.$store.getters['MerchantOnboarding/getShowBackButtonPreventModal'];
    },
  },

  created() {
    this.onboardingCode = this.$route.query.code as string;
  },

  methods: {
    successMessage() {
      this.showSuccessMessage = true;
    },

    hideBackButtonPreventModal() {
      this.$store.dispatch('MerchantOnboarding/setShowBackButtonPreventModal', false);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/mixins";

@include portal-page-layout;

.merchant__onboarding {
  background-color: var(--grayscale-color-4);
}

.portal-wrapper, .portal-container {
  .wizard-step-max {
    max-width: 27.5rem;
  }
}
</style>
