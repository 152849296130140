<template>
  <div>
    <standard-dialog
      :dialog="dialog"
      :cancel-disabled="processing"
      :cta-disabled="processing"
      :on-cancel="onCancel"
      :on-cta="sendRequest"
      :show-cancel-cta="!requestSent"
      :show-progress-indicator="processing"
      :button-label="ctaLabel">
      <template #title>
        Please Confirm
      </template>
      <template #body>
        <div data-test="sendModal">
          <div v-if="!requestSent">
            <p>
              You are requesting <strong v-private>{{ amount }}</strong> from
              <strong v-private>{{ fullName }}</strong>
            </p>
            <div>
              <span>Transaction Description:</span><br>
              <strong>{{ transactionDescription }}</strong>
            </div>
          </div>
          <div v-else>
            Your request has been sent.
          </div>
        </div>
      </template>
    </standard-dialog>
    <alert-modal
      :dialog="showErrorAlert"
      type="error"
      :on-close="onErrorAlertClose">
      {{ errorMessage }}
    </alert-modal>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import { PaymentRequestData } from '@/interfaces/merchantPortal/PaymentRequestData';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import AlertModal from '@/components/Dialogs/AlertModal.vue';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { PageTypesShorthand } from '@/enums/PageTypes';

export default defineComponent({
  name: 'SendRequestModal',

  components: {
    AlertModal,
    StandardDialog,
  },

  mixins: [
    GetErrors,
    GetProcessing,
    HoneypotTrackMixin,
  ],

  props: {
    dialog: { type: Boolean, default: false },
    onCta: { type: Function as PropType<() => void>, default: () => null },
    onCancel: { type: Function as PropType<() => void>, default: () => null },
    selectedLoanApplication: { type: Object, required: true, default: () => ({}) },
    requestData: { type: Object as PropType<PaymentRequestData>, required: true },
  },

  data() {
    return {
      requestSent: false,
      showErrorAlert: false,
      hpEventName: 'Payment Request Sent',
    };
  },

  computed: {
    amount(): string {
      return CurrencyFormatLong(this.requestData.amount);
    },

    fullName(): string {
      const firstName = this.selectedLoanApplication?.user?.first_name || '';
      const lastName = this.selectedLoanApplication?.user?.last_name || '';
      return `${firstName} ${lastName}`;
    },

    ctaLabel(): string {
      return this.requestSent ? 'OK' : 'Proceed';
    },

    errorMessage(): string[] {
      return this.errors.detail;
    },

    transactionDescriptions() {
      return this.$store.getters['MerchantPortal/getTransactionsDescriptions'];
    },

    transactionDescription(): string {
      const transCode = this.requestData?.transaction_type;
      const transDescription = this.transactionDescriptions
        .find((it: any) => it.transaction_code === transCode);
      return transDescription?.description;
    },
  },

  methods: {
    async sendRequest() {
      if (this.requestSent) {
        this.requestSent = false;
        this.onCta();
      } else {
        const responseStatus = await this.$store.dispatch('MerchantPortal/dispatchPaymentRequest', this.requestData);
        if (responseStatus === 201) {
          this.requestSent = true;

          this.trackEvent(this.hpEventName, PageTypesShorthand.MP);
        } else {
          this.showErrorAlert = true;
          this.onCancel();
        }
      }
    },

    onErrorAlertClose() {
      this.showErrorAlert = false;
      this.$store.dispatch('Ui/resetErrorState');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
</style>
