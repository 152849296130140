<template>
  <standard-dialog
    :dialog="show"
    :on-cancel="closeModal"
    :show-cancel-cta="false"
    :show-progress-indicator="processing"
    :show-actions="false"
    data-test="cancelModal"
    title="Please confirm">
    <template #body>
      <p class="mb-12">
        Do you wish to cancel your invitation?
      </p>
      <custom-button
        class="mb-8"
        full-width
        @click="onAccept">
        Yes
      </custom-button>
      <custom-button
        variant="secondary"
        full-width
        @click="closeModal">
        No, return to invitation
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import StandardDialog from '@/components/Dialogs/index.vue';
import GetProcessing from '@/mixins/GetProcessing';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CancelApplicationModal',
  components: {
    CustomButton,
    StandardDialog,
  },
  mixins: [GetProcessing],
  props: {
    onCta: { type: Function, default: () => null },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onAccept() {
      this.closeModal();
      this.onCta();
    },
    showModal(): void {
      this.show = true;
    },
    closeModal(): void {
      this.show = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_custom-variables";
@import "@/assets/scss/mixins/mixins";
@import "@/assets/scss/components/dialogs";

</style>
