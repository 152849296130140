import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TransactionsDataFetchingMixin',
  data() {
    return {
      currentPage: 1,
      refresh: false,
      action: '',
    };
  },
  methods: {
    async updateSearchData(val: any, action: string) {
      this.refresh = true;

      const { pageNumber, searchString, pageSize } = val;

      const payload = {
        pageNumber, searchString, pageSize, action,
      };

      this.currentPage = pageNumber;

      payload.action = action;

      if (action === 'recent-activity') {
        payload.pageSize = 5;
        await this.$store.dispatch('MerchantPortal/dispatchFetchRecentTransactions', payload);
      } else if (action === 'all-transactions') {
        payload.pageSize = pageSize;
        await this.$store.dispatch('MerchantPortal/dispatchFetchRecentTransactions', payload);
      }

      this.refresh = false;
    },
    async updateSortData(val: any, action: string) {
      const options: any = {
        action,
        pageNumber: val.page,
        pageSize: val.itemsPerPage,
      };
      if (val.sortBy.length > 0) {
        options.sortHeader = val.sortBy[0].key;
        options.isDesc = val.sortBy[0].order === 'desc';
      }

      this.refresh = true;

      if (options.isDesc) {
        this.currentPage = val.page;
        await this.$store.dispatch('MerchantPortal/dispatchFetchRecentTransactionsSort', options);
      } else {
        this.currentPage = val.page;
        await this.$store.dispatch('MerchantPortal/dispatchFetchRecentTransactions', options);
      }
      this.refresh = false;
    },
    sortTable(val: any) {
      return this.updateSortData(val, this.action);
    },
    updateSearchPage(val: any) {
      return this.updateSearchData(val, this.action);
    },
  },
});
