<template>
  <standard-wizard-message
    :message="errorMessage"
    :title="title"
    data-test="applicationMsg" />
</template>

<script lang="ts">
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ApplicationNotAccepted',
  components: {
    StandardWizardMessage,
  },
  data() {
    return {
      title: 'Sorry, there appears to be a problem.',
      errorMessage: 'Application not approved.',
    };
  },
});
</script>
