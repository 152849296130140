<template>
  <standard-dialog
    :dialog="dialog"
    :persistent="true"
    :on-cancel="closeModal">
    <template #body>
      <div class="mb-8">
        <p>
          Your Rate Sheet has unsaved changes.
        </p>
      </div>
    </template>

    <template #actions>
      <custom-button variant="secondary" @click="closeModal">
        Keep Editing
      </custom-button>

      <custom-button @click="$emit('saveChanges')">
        Save My Changes
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'RateSheetSave',

  components: {
    StandardDialog,
    CustomButton,
  },

  props: {
    dialog: { type: Boolean, default: false },
  },

  methods: {
    closeModal() {
      this.$emit('keepEditing');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";

.v-theme--light.v-btn.custom-button.button-secondary {
  background-color: #fff !important;
}
</style>
