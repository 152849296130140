<template>
  <standard-dialog
    :dialog="show"
    :show-cancel-cta="false"
    :show-actions="false"
    :on-cancel="emitClose">
    <template #title>
      <div v-if="modalType === 'info-modal'" class="mb-4">
        Staged Funding
      </div>
    </template>
    <template #body>
      <div
        v-if="modalType === 'info-modal'"
        class="modal-body">
        <div>
          <p>
            To improve the experience for our consumers, loans will now be funded in stages.
          </p>
        </div>

        <div>
          <h6>Why?</h6>
          <p>
            Funding stages lowers the risk for you and the consumer in the event of disputes.
          </p>
        </div>

        <div>
          <h6>How does it work?</h6>
          <p>
            Each charge on a loan amount will be limited to a fixed percentage of that loan,
            with subsequent charges that are limited based on the amount of previous charges.
          </p>
        </div>

        <div class="mb-8">
          <h6>Can a consumer still spend their entire loan?</h6>
          <p>
            Yes, but you won’t be able to request it all in one charge.
          </p>
        </div>
      </div>

      <div
        v-if="modalType === 'expired-modal'"
        class="mb-5 modal-body">
        A Payment Request expires after 48 hours if the consumer takes no action to approve it.
      </div>

      <custom-button
        data-test="inputHelperModalCta"
        size="small"
        full-width
        @click="emitClose">
        {{ modalType === 'info-modal' ? 'Yes' : 'Close' }}
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardDialog from '@/components/Dialogs/index.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ChargeTransactionInfoModal',
  components: {
    CustomButton,
    StandardDialog,
  },
  props: {
    show: { type: Boolean, default: false },
    modalType: { type: String, default: '' },
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
  },
});
</script>

<style lang="scss" scoped>
.modal-body h6, p {
  font-size: 1rem;
}

h6 {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: var(--grayscale-color-1);
  margin: 0;
}
</style>
