import { defineComponent } from 'vue';
import { AlertMessageInterface } from '@/components/CustomerSummaryCard/RefundsDialog/types';

export default defineComponent({
  name: 'RefundAlertsMixin',

  computed: {
    merchantData(): any {
      return this.$store.getters['Ui/getFooterData'];
    },
  },

  methods: {
    dispatchAlert(alert: AlertMessageInterface) {
      this.$emit('refund', alert);
    },

    dispatchError(message: string) {
      this.dispatchAlert({
        type: 'error',
        message,
      });
    },

    dispatchSuccess(message: string) {
      this.dispatchAlert({
        type: 'success',
        message,
      });
    },

    showAlert(responseStatus: number) {
      if ([400, 406, 424].includes(responseStatus)) {
        this.dispatchError(`Your Payment Request was unable to be sent at this time.
          Please try again later or contact ${this.merchantData.support_email} for additional questions`);
      } else if (responseStatus !== 201) {
        this.dispatchError('We cannot retrieve data to process a refund right now. Please try again later.');
      } else {
        this.dispatchSuccess('Thank you. Your refund will be processed.');
      }
    },
  },
});
