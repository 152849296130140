<template>
  <div>
    <div v-if="showSuccessBox" class="mt-6">
      <custom-alert type="success">
        <p>
          You have successfully saved your application and can close your browser.
        </p>
        <p>
          We have sent a re-entry email to
          {{ getUserVerifyData.emailAddress }} so you can complete this later.
        </p>
      </custom-alert>
    </div>

    <div v-else-if="showInactivityWarning" class="mt-6">
      <custom-alert type="warning">
        <p>It appears you've been inactive for 30 minutes or more.</p>
        <p>
          For security purposes, we've sent you an email to re-enter this application
          and complete it later. You can close your browser.
        </p>
      </custom-alert>
    </div>

    <thank-you-message v-else-if="showThankYouMessage" />
    <invalid-link v-else-if="showInvalidLinkMsg" />
    <resend-invitation v-else-if="showExpiredInvitationMsg" :onboarding-code="onboardingCode" />
    <resend-invitation v-else-if="maxResendAttempts" :onboarding-code="onboardingCode" />

    <v-stepper
      v-else
      :model-value="wizardStep"
      flat
      outlined>
      <v-stepper-header>
        <v-stepper-item
          v-for="val in 7"
          :key="val"
          :value="val" />
      </v-stepper-header>

      <v-stepper-window>
        <v-stepper-window-item :value="1">
          <v-lazy>
            <step-one @nextStep="navigateStep" @navigateToReEntry="reEntrySetup" />
          </v-lazy>
        </v-stepper-window-item>

        <v-stepper-window-item :value="2">
          <v-lazy>
            <step-two v-if="!isTypeIC1WithoutMPAccess" @nextStep="navigateStep" />
            <step-three-substep-one v-else @nextStep="navigateStep" />
          </v-lazy>
        </v-stepper-window-item>

        <v-stepper-window-item :value="3">
          <v-lazy>
            <step-three-substep-one v-if="!isTypeIC1WithoutMPAccess" @nextStep="navigateStep" />

            <step-three
              v-else
              :on-next-sub-step="navigateSubStep"
              @nextStep="navigateStep" />
          </v-lazy>
        </v-stepper-window-item>

        <v-stepper-window-item :value="4">
          <v-lazy>
            <step-three
              v-if="!isTypeIC1WithoutMPAccess"
              :on-next-sub-step="navigateSubStep"
              @nextStep="navigateStep" />

            <step-three-substep-eight v-else @nextStep="navigateStep" />
          </v-lazy>
        </v-stepper-window-item>

        <v-stepper-window-item :value="5">
          <v-lazy>
            <step-three-substep-eight v-if="!isTypeIC1WithoutMPAccess" @nextStep="navigateStep" />

            <step-four v-else @nextStep="navigateStep" />
          </v-lazy>
        </v-stepper-window-item>

        <v-stepper-window-item v-if="isTypeIC1" :value="6">
          <v-lazy>
            <step-four v-if="hasMPAccess" @nextStep="navigateStep" />

            <step-five v-else />
          </v-lazy>
        </v-stepper-window-item>

        <v-stepper-window-item v-if="isTypeIC1 && hasMPAccess" :value="7">
          <v-lazy>
            <step-five />
          </v-lazy>
        </v-stepper-window-item>
      </v-stepper-window>
    </v-stepper>

    <complete-this-later-dialog
      :dialog="showInactivityModal"
      :on-cancel="hideInactivityWarning"
      :on-cta="sendReEntry" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import WidgetsMixin from '@/mixins/Widgets/WidgetsMixin';
import get from 'lodash/get';
import LocalStorageConstants from '@/constants/LocalStorageConstants';
import FullStoryLogging from '@/logging/FullStory';
import GetHumanReadableIdLabelMixin from '@/mixins/GetHumanReadableIdLabelMixin';
import InvalidLink from '@/components/Merchant/OnboardingWizard/InvalidLink.vue';
import ResendInvitation from '@/components/Merchant/OnboardingWizard/MaxResendAttemptsMsg.vue';
import StepOne from '@/components/Merchant/OnboardingWizard/StepOne.vue';
import StepTwo from '@/components/Merchant/OnboardingWizard/StepTwo.vue';
import StepThree from '@/components/Merchant/OnboardingWizard/StepThree.vue';
import StepFour from '@/components/Merchant/OnboardingWizard/StepFour.vue';
import StepFive from '@/components/Merchant/OnboardingWizard/StepFive.vue';
import StepThreeSubstepOne from '@/components/Merchant/OnboardingWizard/StepThree/SubstepOne.vue';
import StepThreeSubstepEight from '@/components/Merchant/OnboardingWizard/StepThree/SubstepEight.vue';
import SocureIDMixin from '@/mixins/SocureIDMixin';
import { OnboardingUserData } from '@/interfaces/merchantOnboardingWizard/OnboardingUserData';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import MobReEntryMixin from '@/mixins/Merchant/MobReEntryMixin';
import ActivityTracker from '@/utils/ActivityTracker';
import CompleteThisLaterDialog from '@/components/Dialogs/CompleteThisLaterDialog.vue';
import { completeLaterMob } from '@/api/reentry';
import { subscribe, unsubscribe } from '@/composables/eventBus';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import ThankYouMessage from '@/components/Merchant/OnboardingWizard/ThankYouMessage.vue';
import MerchantApplicationStatus from '@/enums/Merchant/ApplicationStatusEnum';

export default defineComponent({
  name: 'MerchantOnboardingWizard',

  components: {
    CustomAlert,
    CompleteThisLaterDialog,
    InvalidLink,
    ResendInvitation,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepThreeSubstepOne,
    StepThreeSubstepEight,
    ThankYouMessage,
  },

  mixins: [
    WidgetsMixin,
    GetHumanReadableIdLabelMixin,
    SocureIDMixin,
    MobReEntryMixin,
    FeatureFlagsMixin,
  ],

  props: {
    showSuccessBox: { type: Boolean, default: false },
  },

  data: () => ({
    responseStatus: 0,
    onboardingCode: '',
    activityTracker: null as ActivityTracker | null,
    activityTrackerInterval: 0,
    showInactivityModal: false,
    showInactivityWarning: false,
    showSuccessSavedApp: false,
    showThankYouMessage: false,
  }),

  computed: {
    userId(): number {
      return this.$store.getters['MerchantOnboarding/getUserId'];
    },

    maxResendAttempts(): boolean {
      return !this.$store.getters['Otp/getPhoneResendEnabled'];
    },

    userDataVerified(): boolean {
      return !!this.getUserVerifyData.userId;
    },

    getUserVerifyData(): OnboardingUserData {
      return this.$store.getters['MerchantOnboarding/getUserVerifyData'];
    },

    showInvalidLinkMsg(): boolean {
      return this.responseStatus === 400 || this.responseStatus === 404;
    },

    showExpiredInvitationMsg(): boolean {
      return this.responseStatus === 410;
    },

    merchantHumanReadableIdLabel() {
      return this.getHumanReadableIdLabel(this.getUserVerifyData.humanReadableId, false);
    },

    showSuccessMessage(): boolean {
      return this.showSuccessSavedApp || this.showSuccessBox;
    },

    sponsorPortalLoginUrl(): string {
      return this.$store.getters['Ui/getPortalLoginUrl'];
    },
  },

  watch: {
    $route(to: any) {
      const toStep = parseInt(get(to, 'query.step'), 10);
      const toSubStep = parseInt(get(to, 'query.subStep'), 10);

      if (toSubStep && toSubStep !== this.wizardSubStep) {
        this.setSubStep(toSubStep);
      } else if (toStep && toStep !== this.wizardStep) {
        this.setStep(toStep);
      }
    },

    appInProgress: {
      immediate: true,
      handler(inIntake: boolean) {
        if (!inIntake) {
          this.clearActivityCheckData();
        }
      },
    },
  },

  async created() {
    localStorage.setItem(
      LocalStorageConstants.CURRENTLY_ONBOARDING,
      LocalStorageConstants.ONBOARDING.MOB,
    );

    await this.$store.dispatch('resetState');

    this.onboardingCode = this.getOnboardingCode();

    if (this.onboardingCode) {
      sessionStorage.setItem(
        LocalStorageConstants.MOB_ONBOARDING_CODE,
        this.onboardingCode.toString(),
      );

      const { status, data } = await this.$store.dispatch(
        'MerchantOnboarding/dispatchUserVerify',
        this.onboardingCode,
      );

      if (status === 400 && data?.error === 'wrong_application_status') {
        const approvedStatuses = [
          MerchantApplicationStatus.APPROVED,
          MerchantApplicationStatus.CONDITIONAL_APPROVAL,
        ];

        if (approvedStatuses.includes(data.details.status)) {
          const redirectUrl = data.details.redirect_url || this.sponsorPortalLoginUrl;
          if (redirectUrl) {
            window.location.href = redirectUrl;
            return;
          }

          this.$router.push({ name: 'merchant-login' });
          return;
        }

        if (data.details.status === MerchantApplicationStatus.PENDING) {
          this.$store.dispatch('MerchantOnboarding/setShowMobStepper', false);
          this.showThankYouMessage = true;
        }
      }

      this.responseStatus = status;
      if (this.responseStatus !== 200) return;

      if (this.isReEntry) {
        this.$store.dispatch('MerchantOnboarding/setShowMobStepper', false);
      }

      this.navigateStep(1);

      FullStoryLogging.identify(
        this.$store.getters['MerchantOnboarding/getUserVerifyData'].userId,
        {
          email: this.$store.getters['MerchantOnboarding/getUserVerifyData'].emailAddress,
          merchantUuid: this.$store.getters['MerchantOnboarding/getUserVerifyData'].merchantUid,
          merchantName: this.$store.getters['MerchantOnboarding/getUserVerifyData'].businessName,
          merchantId: this.merchantHumanReadableIdLabel,
        },
      );
    }
  },

  mounted() {
    this.mountDeviceSessionScript();
    if (this.isFeatureEnabled(FeatureFlagsConstants.RE_ENTRY)) {
      this.activityTracker = new ActivityTracker(LocalStorageConstants.MOB_LAST_ACTIVITY);
      this.setInactiveTimeCheck();
    }
  },

  unmounted() {
    this.clearActivityCheckData();
  },

  methods: {
    getOnboardingCode(): string {
      let onboardingCode = `${this.$route.query?.code || ''}`;
      if (!onboardingCode) {
        onboardingCode = sessionStorage.getItem(LocalStorageConstants.MOB_ONBOARDING_CODE) || '';
      }
      return onboardingCode;
    },
    activityCheck() {
      if (!this.activityTracker || !this.appInProgress) return;

      const step = this.$store.getters['MerchantOnboarding/getOnboardingWizardStep'] || 0;
      // Skip the inactivity checks for OTP step because at this step
      // we don't have a JWT token for a 'save for later' request.
      if (step < 2) return;

      const MAX_INACTIVITY_MINS = 30;
      const INACTIVITY_MODAL_ALERT_THRESHOLD = 10;

      const minutesInactive = this.activityTracker.getMinutesInactive();
      if (minutesInactive > MAX_INACTIVITY_MINS) {
        this.showInactivityModal = false;
        this.showInactivityWarning = true;
        this.$store.dispatch('MerchantOnboarding/setShowMobStepper', false);
        this.sendReEntry(true);
      } else if (minutesInactive >= INACTIVITY_MODAL_ALERT_THRESHOLD) {
        this.showInactivityModal = true;
      }
    },
    setInactiveTimeCheck() {
      const CHECK_INTERVAL = 1000 * 20; // 20 seconds
      this.activityTrackerInterval = window.setInterval(
        this.activityCheck.bind(this),
        CHECK_INTERVAL,
      );
      subscribe('reEntrySent', this.clearActivityCheckData);
    },
    clearActivityCheckData() {
      clearInterval(this.activityTrackerInterval);
      this.activityTracker?.clearData();
      unsubscribe('reEntrySent', this.clearActivityCheckData);
    },
    hideInactivityWarning() {
      this.showInactivityModal = false;
    },
    async sendReEntry(warning: boolean) {
      this.showInactivityModal = false;
      try {
        const response = await completeLaterMob(this.getUserVerifyData?.merchantApplicationId);
        if (response.status === 200) {
          if (!warning) this.showSuccessSavedApp = true;
          this.clearActivityCheckData();
        }
      } catch (error) {
        this.$store.dispatch('Ui/addGlobalTimedError');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/_custom-transitions";

.complete-this-later {
  display: flex;
  justify-content: flex-end;

  .mdi {
    margin-right: .5rem;
  }
}

:deep(.v-sheet.v-stepper) {
  border: none;
  background-color: var(--grayscale-color-4);
  box-shadow: none;

  .v-stepper-header {
    display: none;
  }

  .v-stepper-window {
    margin: 0;
    padding: 0;

    .v-stepper__wrapper {
      padding-bottom: 3rem;
    }
  }
}

.standard-wizard__step {
  border-radius: 8px;
}
</style>
