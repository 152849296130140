<template>
  <custom-button
    :disabled="isDisabled"
    aria-label="Close"
    variant="icon"
    @click="cta">
    <v-icon>mdi-close-circle</v-icon>
  </custom-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GetProcessing from '@/mixins/GetProcessing';
import CustomButton from '@/components/Buttons/CustomButton.vue';

export default defineComponent({
  name: 'CloseButtonX',

  components: {
    CustomButton,
  },

  mixins: [
    GetProcessing,
  ],

  props: {
    onClick: { type: Function, default: () => null },
  },

  computed: {
    isDisabled() : boolean {
      return this.processing;
    },
  },

  methods: {
    cta(): void {
      if (!this.processing) {
        this.onClick();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
</style>
