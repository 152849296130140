import { getAxiosError } from '@/api/utils';
import { ActionContext } from 'vuex';

export default function SetAxiosErrorToState(
  error: unknown,
  context: ActionContext<any, any>,
): void {
  const axiosError = getAxiosError(error);
  if (axiosError) {
    context.commit('Ui/setErrors', axiosError.response, { root: true });
  }
}
