import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_standard_progress_indicator = _resolveComponent("standard-progress-indicator")!
  const _component_verification_card = _resolveComponent("verification-card")!
  const _component_start_verification_desktop = _resolveComponent("start-verification-desktop")!
  const _component_mobile_id_verification_wait = _resolveComponent("mobile-id-verification-wait")!
  const _component_complete_later_button = _resolveComponent("complete-later-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showSpinner)
      ? (_openBlock(), _createBlock(_component_standard_progress_indicator, {
          key: 0,
          type: "circular"
        }))
      : _createCommentVNode("", true),
    (_ctx.showVerificationElements)
      ? (_openBlock(), _createBlock(_component_verification_card, {
          key: 1,
          "is-disabled": _ctx.continueBtnDisabled,
          onInitiateVerification: _ctx.startVerification
        }, null, 8, ["is-disabled", "onInitiateVerification"]))
      : _createCommentVNode("", true),
    (_ctx.showDesktopVerification)
      ? (_openBlock(), _createBlock(_component_start_verification_desktop, {
          key: 2,
          "qr-code-image": _ctx.qrCodeImage
        }, null, 8, ["qr-code-image"]))
      : _createCommentVNode("", true),
    (_ctx.showMobileWait)
      ? (_openBlock(), _createBlock(_component_mobile_id_verification_wait, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.showVerificationElements)
      ? (_openBlock(), _createBlock(_component_complete_later_button, { key: 4 }))
      : _createCommentVNode("", true)
  ]))
}