<template>
  <v-card-text>
    <v-row justify="space-between">
      <v-col cols="5" sm="3">
        <v-skeleton-loader class="summary" type="article" />
      </v-col>
      <v-col cols="5" sm="3">
        <v-skeleton-loader class="address" type="article" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-skeleton-loader height="170" type="image" />
      </v-col>
      <v-col cols="12" sm="3">
        <v-skeleton-loader height="170" type="image" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader class="table" type="table-row, table-tbody" />
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader class="table" type="table-row, table-tbody" />
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StatementDetailsLoader',
});
</script>

<style lang="scss" scoped>
.summary {
  padding-bottom: 0;

  :deep() {
    .v-skeleton-loader {
      &__heading {
        margin-top: 0 !important;
        margin-left: 0 !important;
      }

      &__paragraph {
        padding: 0;
      }
    }
  }
}

.address {
  :deep(div) {
    margin-top: 0 !important;
    margin-left: auto !important;
    padding: 0;
  }
}

:deep() {
  .table {
    border: 1px solid var(--grayscale-color-3);
    margin-bottom: 1rem;

    > div {
      &:first-child {
        width: 15rem;
        padding-left: 1rem;
      }

      &:last-child {
        padding-top: 0;
      }
    }

    .v-skeleton-loader__table-row-divider:last-child {
      .v-skeleton-loader__divider {
        display: none;
      }
    }
  }
}
</style>
