<template>
  <v-card class="business-details fs-unmask inline-inputs" :elevation="cardElevation">
    <v-card-title class="py-3" data-test="businessDetailsSubHeader">
      <h6 v-if="isMobile" class="mobile-title">
        Business Details
      </h6>

      <h5 v-else>
        Business Details
      </h5>

      <custom-button
        class="edit-save-button"
        :disabled="saveDisabled"
        variant="text"
        size="small"
        @click="toggleEdit">
        {{ editing ? 'Save' : 'Edit' }}
      </custom-button>
    </v-card-title>

    <v-card-text class="pa-4">
      <div class="card-text__info-group">
        <v-select
          ref="program"
          :model-value="programsData.program"
          :disabled="!editing || isBrandProFund"
          class="old-v-select"
          :variant="editing ? 'underlined' : 'plain'"
          :items="programs"
          data-test="programSelect"
          label="Industry"
          item-title="name"
          item-value="program_uuid"
          @update:model-value="selectProgram" />

        <v-select
          ref="subProgram"
          :model-value="programsData.subProgram"
          :disabled="!editing"
          class="old-v-select"
          :variant="editing ? 'underlined' : 'plain'"
          :items="subProgramsList"
          data-test="subSelect"
          label="Select"
          item-title="name"
          item-value="subprogram_uuid"
          @update:model-value="selectSubprogram" />
      </div>

      <div class="card-text__info-group">
        <text-input
          v-model="allAttributes.legalBusinessName"
          :disabled="!editing"
          max="250"
          in-place
          required
          data-test="legalInput"
          label="Legal Business Name" />
      </div>

      <div class="card-text__info-group">
        <text-input
          v-model="allAttributes.doingBusinessAs"
          :disabled="!editing"
          max="250"
          in-place
          data-test="doingInput"
          label="Name Doing Business As" />
      </div>

      <div class="card-text__info-group">
        <select-input
          v-model="allAttributes.businessStructure"
          class="old-v-select"
          :disabled="!editing"
          in-place
          :items="optionsBusinessStructure"
          data-test="businessInput"
          :is-mob="true"
          label="Business Structure" />
      </div>

      <div class="card-text__info-group">
        <tax-id-input
          v-model="allAttributes.federalTaxId"
          :disabled="formInputsDisabled"
          in-place
          required
          data-test="taxInput" />
      </div>

      <div v-if="showSsnInput" class="card-text__info-group">
        <ssn-input
          v-model="allAttributes.ssn"
          :disabled="formInputsDisabled"
          in-place
          required />
      </div>

      <div class="card-text__info-group">
        <url-input
          v-model="allAttributes.websiteUrl"
          :disabled="formInputsDisabled"
          in-place
          data-test="websiteInput"
          label="Website URL" />
      </div>

      <div class="card-text__info-group">
        <date-input
          ref="datePicker"
          v-model="allAttributes.businessStartDate"
          :disabled="formInputsDisabled"
          in-place
          :max="maxDate"
          :range="false"
          :is-fs-excluded="false"
          required
          label="Date Business Started" />
      </div>

      <div class="card-text__info-group">
        <merchant-onboarding-wizard-annual-sales-revenue
          v-model="allAttributes.annualSalesRevenue"
          class="bussines-details"
          :disabled="formInputsDisabled"
          in-place-input
          data-test="annualInput" />
      </div>

      <div class="card-text__info-group">
        <merchant-onboarding-wizard-average-customer-spend
          v-model="allAttributes.averageCustomerSpend"
          class="bussines-details"
          :disabled="formInputsDisabled"
          in-place-input
          data-test="averageInput" />
      </div>

      <merchant-onboarding-wizard-payments-types-financing-options
        class="bussines-details substep-eight-financing-options"
        :disabled-inputs="formInputsDisabled"
        in-place-input
        from-business-details
        :show-next-button="false"
        @financingOptionsUpdated="onUpdateFinancingOptions" />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SelectInput from '@/components/Inputs/Select.vue';
import DateInput from '@/components/Inputs/Date.vue';
import UrlInput from '@/components/Inputs/Url.vue';
import TaxIdInput from '@/components/Inputs/TaxId.vue';
import TextInput from '@/components/Inputs/Text.vue';
import SsnInput from '@/components/Inputs/Ssn.vue';
import BusinessStructureMixin from '@/mixins/Merchant/BusinessStructureMixin';
import MerchantOnboardingWizardAnnualSalesRevenue
  from '@/components/Merchant/OnboardingWizard/AnnualSalesRevenue.vue';
import MerchantOnboardingWizardAverageCustomerSpend
  from '@/components/Merchant/OnboardingWizard/AverageCustomerSpend.vue';
import MerchantOnboardingWizardPaymentsTypesFinancingOptions
  from '@/components/Merchant/OnboardingWizard/PaymentsTypesFinancingOptions.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { AllAttributes } from '@/interfaces/merchantOnboardingWizard/AllAttributes';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import ProfundMixin from '@/mixins/ProfundMixin';
import SetCustomErrorValueMixin from '@/mixins/SetCustomErrorValueMixin';
import ValidationTypesMixin from '@/mixins/ValidationTypesMixin';
import { SubProgramsInterface } from '@/interfaces/merchant/SubProgramsInterface';
import { ProgramsInterface } from '@/interfaces/merchant/ProgramsInterface';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import validateDate from '@/validators/date';
import validateSsn from '@/validators/ssn';
import validateTaxId from '@/validators/tax_id';
import DATE_CONSTANTS from '@/constants/DateConstants';
import isUrl from '@/validators/url';
import validateString from '@/validators/string';
import {
  ANNUAL_SALES_REVENUE_MAX,
  AVERAGE_CUSTOMER_SPEND_MAX,
  YEARLY_FINANCING_VOLUME_MAX,
} from '@/constants/MerchantOnboarding';

export default defineComponent({
  name: 'BusinessDetails',

  components: {
    SelectInput,
    UrlInput,
    DateInput,
    TaxIdInput,
    TextInput,
    SsnInput,
    CustomButton,
    MerchantOnboardingWizardAnnualSalesRevenue,
    MerchantOnboardingWizardAverageCustomerSpend,
    MerchantOnboardingWizardPaymentsTypesFinancingOptions,
  },

  mixins: [
    BusinessStructureMixin,
    GetErrors,
    GetProcessing,
    ProfundMixin,
    SetCustomErrorValueMixin,
    ValidationTypesMixin,
  ],

  data: () => ({
    programsData: {
      program: {},
      subProgram: {} as SubProgramsInterface,
    } as any,
    editing: false,
    allAttributes: {
      legalBusinessName: '',
      doingBusinessAs: '',
      businessStructure: null,
      federalTaxId: '',
      ssn: '',
      websiteUrl: '',
      businessStartDate: '',
      annualSalesRevenue: 0,
      averageCustomerSpend: 0,
      paymentTypesAccepted: [],
      offersFinancing: false,
      financingVolume: 0,
    } as AllAttributes,
    programDataDirty: false,
    initialProgramDataSet: false,
  }),

  computed: {
    maxDate(): string {
      return moment().format(DATE_CONSTANTS.dateFormat);
    },

    processingApplication(): boolean {
      return this.$store.getters['MerchantOnboarding/getProcessingApplication'];
    },

    applicationApproved(): boolean {
      return this.$store.getters['MerchantOnboarding/getApplicationApproved'];
    },

    applicationWaitingApproval(): boolean {
      return this.$store.getters['MerchantOnboarding/getApplicationWaitingApproval'];
    },

    formInputsDisabled(): boolean {
      return !this.editing
          || this.processingApplication
          || this.applicationApproved
          || this.applicationWaitingApproval;
    },

    programs(): Array<ProgramsInterface> {
      return this.$store.getters['MerchantOnboarding/getPrograms'];
    },

    subPrograms(): SubProgramsInterface[] {
      return this.$store.getters['MerchantOnboarding/getSubPrograms'];
    },

    subProgramsList(): SubProgramsInterface[] {
      return sortBy(this.subPrograms, ['name']);
    },

    showSsnInput(): boolean {
      return this.shouldShowSsnInput(this.allAttributes.businessStructure);
    },

    storeAllAttributes(): AllAttributes {
      return this.$store.getters['MerchantOnboarding/getAllAttributes'];
    },

    saveDisabled(): boolean {
      if (!this.editing) return false;

      const { ssn } = this.allAttributes;
      if (this.showSsnInput && !(ssn && validateSsn(ssn))) {
        return true;
      }

      const { websiteUrl, offersFinancing, financingVolume } = this.allAttributes;

      const isValidUrl = websiteUrl ? isUrl(websiteUrl) : true;
      const financingValidation = (
        offersFinancing && financingVolume && Number(financingVolume) <= YEARLY_FINANCING_VOLUME_MAX
      ) || !offersFinancing;

      return !(
        this.programsData.program
          && this.programsData.subProgram
          && validateString(this.allAttributes.legalBusinessName || '', 1, 250)
          && validateString(this.allAttributes.doingBusinessAs || '', 0, 250)
          && this.allAttributes.businessStructure
          && this.allAttributes.federalTaxId
          && validateTaxId(this.allAttributes.federalTaxId)
          && this.allAttributes.businessStartDate
          && validateDate(this.allAttributes.businessStartDate).isValid
          && this.allAttributes.paymentTypesAccepted?.length
          && financingValidation
          && this.allAttributes.annualSalesRevenue
          && this.allAttributes.annualSalesRevenue <= ANNUAL_SALES_REVENUE_MAX
          && this.allAttributes.averageCustomerSpend
          && this.allAttributes.averageCustomerSpend <= AVERAGE_CUSTOMER_SPEND_MAX
          && isValidUrl
      );
    },

    isMobile(): boolean {
      return this.$vuetify.display.xs;
    },

    cardElevation() {
      return this.editing ? 10 : undefined;
    },

    selectedProgramSubProgram() {
      return this.$store.getters['MerchantOnboarding/getSelectedPrograms'];
    },
  },

  watch: {
    programsData: {
      deep: true,
      handler() {
        // To avoid setting dirty data to true on the initial
        // program/subprogram setup.
        if (!this.initialProgramDataSet) {
          this.initialProgramDataSet = true;
        } else {
          this.programDataDirty = true;
        }
      },
    },

    storeAllAttributes() {
      this.setAllAttributes();
    },

    selectedProgramSubProgram() {
      this.setProgramsData();
    },
  },

  mounted() {
    this.setAllAttributes();
  },

  methods: {
    setAllAttributes() {
      const {
        legalBusinessName,
        doingBusinessAs,
        federalTaxId,
        ssn,
        websiteUrl,
        businessStartDate,
        annualSalesRevenue,
        averageCustomerSpend,
        offersFinancing,
        financingVolume,
        businessStructure,
        paymentTypesAccepted,
      } = this.storeAllAttributes;

      this.allAttributes = {
        ...this.allAttributes,
        legalBusinessName,
        doingBusinessAs: doingBusinessAs || 'N/A',
        federalTaxId,
        ssn,
        websiteUrl: websiteUrl || 'N/A',
        businessStartDate,
        annualSalesRevenue,
        averageCustomerSpend,
        offersFinancing,
        financingVolume,
        businessStructure,
        paymentTypesAccepted,
      };

      this.setProgramsData();
    },

    setProgramsData() {
      const { program, subProgram } = this.selectedProgramSubProgram;
      this.programsData = {
        program,
        subProgram,
      };
    },

    selectProgram(programUuid: string) {
      this.$store.dispatch('MerchantOnboarding/dispatchFetchSubprograms', programUuid);
      const program = this.programs.find(it => it.program_uuid === programUuid);
      this.programsData = {
        program,
        subProgram: {},
      };
    },

    selectSubprogram(subprogramUuid: string) {
      const subprogram = this.subPrograms.find(it => it.subprogram_uuid === subprogramUuid);
      this.programsData.subProgram = subprogram;
    },

    async toggleEdit() {
      if (!this.editing) {
        const { doingBusinessAs, websiteUrl } = this.allAttributes;
        this.allAttributes.doingBusinessAs = doingBusinessAs === 'N/A' ? '' : doingBusinessAs;
        this.allAttributes.websiteUrl = websiteUrl === 'N/A' ? '' : websiteUrl;
      }

      if (this.editing) {
        await this.$store.dispatch('MerchantOnboarding/dispatchAllAttributes', this.allAttributes);

        this.allAttributes.doingBusinessAs = this.allAttributes.doingBusinessAs || 'N/A';
        this.allAttributes.websiteUrl = this.allAttributes.websiteUrl || 'N/A';

        if (this.programDataDirty) {
          const responseStatus = await this.$store.dispatch(
            'MerchantOnboarding/patchMerchantSelectedPrograms',
            this.programsData,
          );
          this.programDataDirty = responseStatus !== 200;
        }
      }

      this.editing = !this.editing;
      this.$store.dispatch('MerchantOnboarding/setVerifyFormUserDetailsStatus', this.editing);
    },

    onUpdateFinancingOptions(financingOptionsUpdated: any) {
      this.allAttributes = {
        ...this.allAttributes,
        ...financingOptionsUpdated,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/financing-options";
@import "@/assets/scss/vuetify/elevation.scss";

.business-details {
  border: 1px solid var(--grayscale-color-2);
  box-shadow: $v-elevation-4;
  border-radius: 8px;

  .edit-save-button {
    font-size: 0.875rem !important;
    background-color: #fff;
    border-radius: 8px;
    min-width: 2rem;
  }

  :deep() .v-theme--light.v-btn.edit-save-button.button-small:hover {
      box-shadow: none;
      background-color: #fff;
  }

  :deep(.v-card-text) {
    .card-text__info-group {
      margin-bottom: 1.5rem !important;
    }

    @include mobile {
      .card-text__info-group {
        margin-bottom: 0 !important;

        .v-text-field__slot label, input {
          font-size: 0.875rem;
        }

        .state-zip-row {
          .v-select__control label {
            font-size: 0.875rem;
          }

          .v-select__selections span {
            font-size: 0.875rem;
          }
        }

        .v-select__control {
          label {
            font-size: 0.875rem;
          }

          .v-select__selections span {
            font-size: 0.875rem;
          }
        }

        .card-text__info-group__title span {
          font-size: 0.875rem;
        }

        .v-input--checkbox .v-input__control label {
          font-size: 0.875rem;
        }

        .financing-btn span {
          font-size: 0.875rem;
        }
      }

      .address-input-container {
        .v-text-field__slot label, input {
          font-size: 0.875rem;
        }
      }
    }
  }

  .mobile-title {
    margin-top: 1rem;
  }
}

:deep(.v-card-title) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--grayscale-color-5);

  h5 {
    font-size: 1rem;
    color: var(--grayscale-color-1);
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  }

  .custom-button span {
    @include mobile {
      font-size: 0.875rem;
    }
  }
}

.standard-wizard {
  &__step {
    @include standard-wizard-form-inline-split;
  }
}

.substep-eight-financing-options {
  :deep(.v-card-text) {
    padding: 0;
  }
}
</style>
