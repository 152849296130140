<template>
  <div
    :class="classList"
    :data-test="dataTestAttr">
    <v-card :flat="hideWrapper">
      <v-card-text>
        <h6 v-if="title">
          {{ title }}
        </h6>

        <div v-if="message">
          {{ message }}
        </div>

        <slot
          v-if="showBodySlot"
          name="body" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WizardMessage',

  props: {
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    isErrorMsg: { type: Boolean, default: true },
    noBorder: { type: Boolean, default: false },
    dataTestAttr: { type: String, default: '' },
    hideWrapper: { type: Boolean, default: false },
  },

  data() {
    return {
      baseClass: 'standard-wizard__message',
    };
  },

  computed: {
    showBodySlot(): boolean {
      return !!this.$slots.body;
    },

    classList(): string {
      const classList: Array<string> = [
        this.baseClass,
      ];

      if (this.$props.isErrorMsg) {
        classList.push(`${this.baseClass}--error-msg`);
      }

      if (this.$props.noBorder) {
        classList.push(`${this.baseClass}--no-border`);
      }

      return classList.join(' ');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins/mixins";

.standard-wizard {
  &__message {
    &--no-border {
      :deep(.card) {
        border: none;

        .card-body {
          border: none;
          padding: 0;

          .card-text {
            @include body-text-secondary;
          }
        }
      }
    }
  }
}

</style>
