import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_chip = _resolveComponent("status-chip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_menu, {
      modelValue: _ctx.menuVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuVisible) = $event)),
      "close-on-content-click": false,
      offset: _ctx.menuOffset,
      transition: "fade-transition",
      width: "13.75rem"
    }, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_status_chip, _mergeProps(props, { status: _ctx.status }), null, 16, ["status"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          width: "13.75rem",
          class: "fs-unmask"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_status_chip, { status: _ctx.status }, null, 8, ["status"]),
                _createVNode(_component_v_icon, {
                  tag: "button",
                  size: "small",
                  color: "var(--grayscale-color-1)",
                  onClick: _ctx.close
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-close ")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.description), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "offset"])
  ]))
}