const DATE_CONSTANTS = {
  dateFormatIso: 'YYYY-MM-DD',
  dateFormatIsoSlashes: 'YYYY/MM/DD',
  dateFormatIsoRegex: new RegExp(/^\d{4}-\d{2}-\d{2}$/),
  dateFormat: 'MM-DD-YYYY',
  dateFormatSlashes: 'MM/DD/YYYY',
  dateFormatRegex: new RegExp(/^\d{2}-\d{2}-\d{4}$/),
  timeFormat: 'h:mm:ss a',
  shortTimeFormat: 'h:mm a',
};

export default DATE_CONSTANTS;
