import DEFAULT_THEME_VARIABLE from '@/constants/Whitelabel/DefaultThemeVariables';

const VARIABLE_MAPPINGS: Object = {
  '--primary-color': { overwrite: 'primaryColor' },
  '--secondary-color': { overwrite: 'secondaryColor' },
  '--grayscale-color-1': { overwrite: 'grayscaleColor1' },
  '--grayscale-color-2': { overwrite: 'grayscaleColor2' },
  '--grayscale-color-3': { overwrite: 'grayscaleColor3' },
  '--grayscale-color-4': { overwrite: 'grayscaleColor4' },
  '--grayscale-color-5': { overwrite: 'grayscaleColor5' },
  '--alert-color': { overwrite: 'alertColor' },
  '--rate-sheet-color-1': { overwrite: 'rateSheetColor1' },
  '--rate-sheet-color-2': { overwrite: 'rateSheetColor2' },
  '--rate-sheet-color-3': { overwrite: 'rateSheetColor3' },
  '--warning-color': { overwrite: 'warningColor' },
  '--success-color': { overwrite: 'successColor' },
  '--error-color': { overwrite: 'errorColor' },
  '--dark-color': { overwrite: 'darkColor' },
};

function setThemeVariables(brandCss: Object = {}): void {
  const rootHtmlElement: HTMLElement = document.documentElement;

  for (const [cssVar, varNames] of Object.entries(VARIABLE_MAPPINGS)) {
    const { overwrite, fallback }: { overwrite: string; fallback: string } = varNames;
    // @ts-ignore
    const value = brandCss[overwrite] || brandCss[fallback] || DEFAULT_THEME_VARIABLE[overwrite];
    rootHtmlElement.style.setProperty(cssVar, value);
  }
}

export default setThemeVariables;
