import NotificationsDefaultMsgEnum from '@/enums/Notifications/DefaultMsgEnum';
import NotificationsTypesEnum from '@/enums/Notifications/TypesEnum';
import { GlobalAlertIface } from '@/interfaces/GlobalAlertInterface';
import { UiStateInterface } from '@/interfaces/states/UiStateInterface';
import { ErrorsInterface } from '@/interfaces/ui/ErrorsInterface';
import { SetErrorsInterface } from '@/interfaces/ui/SetErrorsInterface';
import { SystemNotificationInterface } from '@/interfaces/ui/SystemNotificationInterface';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import { hasTokenExpired } from '@/utils/Token';

let nextNotificationId = 0;

export function setProcessing(state: UiStateInterface, processing: boolean) {
  if (processing) {
    state.processing += 1;
  } else if (state.processing > 0) {
    state.processing -= 1;
  }
}

export function setErrors(state: UiStateInterface, payload: SetErrorsInterface) {
  const { data, status, non_field_errors } = payload;

  const errors: ErrorsInterface = data || {};

  if (non_field_errors) {
    errors.non_field_errors = non_field_errors;
  }

  state.errors = errors;

  if (status && ([401, 402, 403, 405].includes(status) || status >= 500)) {
    const notification: SystemNotificationInterface = {
      type: NotificationsTypesEnum.ERROR,
      message: NotificationsDefaultMsgEnum.ERROR,
      id: nextNotificationId,
    };

    state.systemNotifications.push(notification);

    nextNotificationId += 1;

    if (hasTokenExpired()) return; // Do not route expired token errors to the error-page.

    router.push({ name: 'error-page' });
  }
}

export function resetErrors(state: UiStateInterface) {
  state.errors = {};
}

export function setCustomError(state: UiStateInterface, error: any) {
  state.errors[error.key] = error.value;
}

export function setRemoveSystemNotification(state: UiStateInterface, id: number) {
  state.systemNotifications = state.systemNotifications
    .filter((it: SystemNotificationInterface) => it.id !== id);
}

export function setShowIdleTimeoutModal(state: UiStateInterface, show: boolean) {
  state.showIdleTimeoutModal = show;
}

export function setBrandingData(state: UiStateInterface, payload: any) {
  state.brandingData = payload;
}

export function setUserFriendlyError(state: UiStateInterface, payload: SetErrorsInterface) {
  const { data, status } = payload;
  let errorMsg = '';

  if (data?.detail && status && status < 500) {
    errorMsg = Array.isArray(data.detail) ? data.detail[0] : data.detail;
  } else {
    errorMsg = 'We are currently experiencing technical difficulties. Please try again later.';
  }

  state.userFriendlyErrorResponse = errorMsg;
}

export function clearUserFriendlyError(state: UiStateInterface) {
  state.userFriendlyErrorResponse = '';
}

export function addGlobalAlert(state: UiStateInterface, alert: GlobalAlertIface) {
  state.globalAlerts.push(alert);
}

export function removeGlobalAlert(state: UiStateInterface, alertId: number) {
  state.globalAlerts = state.globalAlerts.filter((it: any) => it.id !== alertId);
}
