import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_name_form_dialog = _resolveComponent("name-form-dialog")!
  const _component_products_form_dialog = _resolveComponent("products-form-dialog")!
  const _component_alert_modal = _resolveComponent("alert-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showNameDialog)
      ? (_openBlock(), _createBlock(_component_name_form_dialog, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
          onSave: _ctx.saveName
        }, null, 8, ["onSave"]))
      : _createCommentVNode("", true),
    (_ctx.showCreateDialog)
      ? (_openBlock(), _createBlock(_component_products_form_dialog, {
          key: 1,
          name: _ctx.name,
          "rate-sheets": _ctx.availableRateSheets,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
          onSave: _ctx.save
        }, null, 8, ["name", "rate-sheets", "onSave"]))
      : _createCommentVNode("", true),
    _createVNode(_component_alert_modal, {
      type: "error",
      dialog: _ctx.showErrorAlert,
      "on-close": _ctx.hideErrorAlert
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.errorAlertMessage), 1)
      ]),
      _: 1
    }, 8, ["dialog", "on-close"])
  ]))
}