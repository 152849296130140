<template>
  <standard-dialog
    :dialog="showDialog"
    :show-actions="false"
    :persistent="true"
    :on-cancel="emitClose">
    <template #title>
      <span>Multi-Lender Platform</span>
    </template>

    <template #body>
      <p>
        Having multiple lenders increases accommodation rates for consumers applying for loans,
        but different lenders prefer different payment networks.
      </p>

      <p class="mb-8">
        To maximize your lending potential,
        keep your payment settings up to date and connected at all times.
      </p>

      <custom-button
        full-width
        size="small"
        @click="emitClose">
        Close
      </custom-button>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import StandardDialog from '@/components/Dialogs/index.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IncorrectAmountDialog',
  components: {
    StandardDialog,
    CustomButton,
  },
  props: {
    showDialog: { type: Boolean, default: false },
  },
  methods: {
    emitClose() {
      this.$emit('cancel');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
</style>
