<template>
  <div class="export-wrapper">
    <v-menu
      content-class="d-print-none"
      :offset="[0, -16]"
      :close-on-content-click="false">
      <template v-slot:activator="{ props }">
        <custom-button
          :disabled="buttonsDisabled"
          :loading="loadingPdf"
          size="small"
          variant="secondary"
          v-bind="props">
          Export Statement
        </custom-button>
      </template>
      <v-list density="compact">
        <v-list-item :disabled="loadingPdf" @click="printPdf">
          <v-list-item-title class="text-left">
            PDF
            <v-progress-circular
              v-if="loadingPdf"
              size="20"
              width="2"
              indeterminate />
          </v-list-item-title>
        </v-list-item>
        <v-list-item :disabled="loadingExcel" @click="saveExcel">
          <v-list-item-title class="text-left">
            EXCEL
            <v-progress-circular
              v-if="loadingExcel"
              size="20"
              width="2"
              indeterminate />
          </v-list-item-title>
        </v-list-item>
        <v-list-item :disabled="loadingCsv" @click="saveCsv">
          <v-list-item-title class="text-left">
            CSV
            <v-progress-circular
              v-if="loadingCsv"
              size="20"
              width="2"
              indeterminate />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import CurrencyFormatLong from '@/filters/CurrencyFormatLong';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import { StatementDetailsInterface, TransactionDetailsInterface } from '@/interfaces/statements/StatementDetailsInterface';
import PrintConfigNames from '@/enums/PrintConfigNames';
import { PrintConfigIface } from '@/interfaces/PrintConfigInterface';
import { saveAs } from 'file-saver';
import { defineComponent } from 'vue';

// Need to import ExcelJS this way because otherwise Typescript will complain about missing types
// and there will be collisions between NodeJS types and Browser types.
// @ts-ignore
import * as ExcelJS from 'exceljs/dist/exceljs.min';

const RIGHT_ALIGN = { style: { alignment: { horizontal: 'right' } } };

const HEADERS_SUMMARY = [
  {
    header: 'Total Charges',
    key: 'totalCharges',
    width: 15,
    ...RIGHT_ALIGN,
  }, {
    header: 'Refunds',
    key: 'refunds',
    width: 15,
    ...RIGHT_ALIGN,
  }, {
    header: 'Net purchases',
    key: 'netPurchases',
    width: 15,
    ...RIGHT_ALIGN,
  }, {
    header: 'Fees',
    key: 'fee',
    width: 15,
    ...RIGHT_ALIGN,
  }, {
    header: 'Net Merchant Fees',
    key: 'netFee',
    width: 20,
    ...RIGHT_ALIGN,
  }, {
    header: 'Net Merchant Receipt',
    key: 'netReceipt',
    width: 20,
    ...RIGHT_ALIGN,
  },
];

const HEADERS_DETAILS = [
  { header: 'Date', key: 'date', width: 12 },
  { header: 'Consumer Name & Loan Number', key: 'name', width: 30 },
  { header: 'Loan Product', key: 'product', width: 15 },
  { header: 'Transaction Description', key: 'desc', width: 30 },
  {
    header: 'Transaction Amount',
    key: 'amount',
    width: 20,
    ...RIGHT_ALIGN,
  }, {
    header: 'Fee %',
    key: 'feePercent',
    width: 10,
    ...RIGHT_ALIGN,
  }, {
    header: 'Fee Amount',
    key: 'feeAmount',
    width: 15,
    ...RIGHT_ALIGN,
  }, {
    header: 'Net Merchant Receipt',
    key: 'netReceipt',
    width: 20,
    ...RIGHT_ALIGN,
  },
];

function formatDetailsRow(td: TransactionDetailsInterface): any {
  return {
    date: td.transaction_date,
    name: `${td.consumer_name} ${td.consumer_loan_number}`,
    product: td.consumer_loan_product,
    desc: td.transaction_description,
    amount: CurrencyFormatLong(td.total_transaction_amount),
    feePercent: `${(Number(td.merchant_fee_percentage) * 100).toFixed(2)}%`,
    feeAmount: CurrencyFormatLong(td.merchant_fee_amount),
    netReceipt: CurrencyFormatLong(td.net_merchant_receipt),
  };
}

function addSheet(workbook: any, name: string, headers: any, rows: any) {
  const sheet = workbook.addWorksheet(name);
  sheet.columns = headers;

  if (rows.length) sheet.addRows(rows);

  sheet.getRow(1).eachCell((cell: any) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFE4E5E4' },
    };
  });
}

export default defineComponent({
  name: 'StatementsExport',
  components: { CustomButton },
  props: {
    statementId: { type: [Number, String], default: null },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      loadingPdf: false,
      loadingExcel: false,
      loadingCsv: false,
    };
  },
  computed: {
    buttonsDisabled(): boolean {
      return this.loading || this.loadingPdf || this.loadingExcel || this.loadingCsv;
    },
    statement(): StatementDetailsInterface {
      return this.$store.getters['MerchantPortal/getStatementDetails'];
    },
    filename(): string {
      const number = this.statement.statement_summary.statement_number;
      return `merchant_statement_${number}`;
    },
  },
  methods: {
    async printPdf() {
      this.loadingPdf = true;
      await this.loadStatementDetails();
      this.loadingPdf = false;
      const printConfig: PrintConfigIface = {
        name: PrintConfigNames.STATEMENT_DETAILS,
        data: { id: this.statementId },
      };
      this.$store.dispatch('Print/setPrintConfig', printConfig);
    },
    loadStatementDetails() {
      return this.$store.dispatch('MerchantPortal/fetchStatementDetails', this.statementId);
    },
    async saveCsv() {
      this.loadingCsv = true;
      await this.loadStatementDetails();
      this.generateCsv();
      this.loadingCsv = false;
    },
    async generateCsv() {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet();
      const summaryRow = this.getSummary();
      const detailsRows = this.getStatementDetails();
      const failedTxnRows = this.getFailedTransactions();

      const fillerColumns = ['', '', '', '', '', '', ''];
      const dividerRow = [''];

      const rows = [['Statement Summary', ...fillerColumns]];
      rows.push(HEADERS_SUMMARY.map(it => it.header));
      rows.push(Object.values(summaryRow));

      rows.push(dividerRow);
      rows.push(['Statement Details']);
      rows.push(HEADERS_DETAILS.map((row: any) => row.header));
      detailsRows.forEach((row: any) => rows.push(Object.values(row)));

      rows.push(dividerRow);
      rows.push(['Failed Transactions']);
      rows.push(HEADERS_DETAILS.map((row: any) => row.header));
      failedTxnRows.forEach((row: any) => rows.push(Object.values(row)));
      sheet.addRows(rows);

      const fileBuffer = await workbook.csv.writeBuffer();
      const blob = new Blob([fileBuffer]);
      return saveAs(blob, `${this.filename}.csv`);
    },
    async saveExcel() {
      this.loadingExcel = true;
      await this.loadStatementDetails();
      this.generateExcel();
      this.loadingExcel = false;
    },
    async generateExcel() {
      const workbook = new ExcelJS.Workbook();
      const summaryRow = this.getSummary();
      addSheet(workbook, 'Statement Summary', HEADERS_SUMMARY, [summaryRow]);

      const statementDetailsRows = this.getStatementDetails();
      addSheet(workbook, 'Statement Details', HEADERS_DETAILS, statementDetailsRows);

      const failedTransactionsRows = this.getFailedTransactions();
      addSheet(workbook, 'Failed Transactions', HEADERS_DETAILS, failedTransactionsRows);

      const fileBuffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([fileBuffer]);
      return saveAs(blob, `${this.filename}.xlsx`);
    },
    getSummary(): any {
      const data = this.statement.statement_summary;

      return {
        totalCharges: CurrencyFormatLong(data.total_charges),
        refunds: CurrencyFormatLong(data.total_refunds),
        netPurchases: CurrencyFormatLong(data.net_purchases),
        fee: CurrencyFormatLong(data.total_merchant_fees || 0),
        netFee: CurrencyFormatLong(data.net_merchant_fees),
        netReceipt: CurrencyFormatLong(data.total_net_merchant_receipt),
      };
    },
    getStatementDetails(): any {
      return this.getDetails(false);
    },
    getFailedTransactions(): any {
      return this.getDetails(true);
    },
    getDetails(failed: boolean): any {
      const sd = failed
        ? this.statement.fail_statement_details
        : this.statement.success_statement_details;

      return sd.map(it => formatDetailsRow(it));
    },
  },
});
</script>

<style lang="scss" scoped>
.export-wrapper {
  display: inline-block;
}
</style>
