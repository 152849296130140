<template>
  <standard-dialog
    :dialog="true"
    :on-cta="closeModal"
    :on-cancel="closeModal"
    :show-cancel-cta="false"
    button-size="small"
    title="Rate Sheet Selections"
    button-label="OK">
    <template #body>
      <p>
        The rate sheet is where you select the loan products you would like to offer.
        Your customers will receive a range of products based on their eligibility.
      </p>
      <p>
        We recommend and have auto-selected products for you.
        Our experts optimized this package to cover a wide range of customer preferences.
        It is designed to increase your sales and serve more of your customers.
        You can change at any time.
        Please save and activate your Rate Sheet selections.
      </p>
    </template>
  </standard-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StandardDialog from '@/components/Dialogs/index.vue';

export default defineComponent({
  components: {
    StandardDialog,
  },
  methods: {
    closeModal() {
      this.$emit('cancel');
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/dialogs";
</style>
